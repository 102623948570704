<template>
  <div class="voice-assistant">
    <TextToSpeech :content="answer" :key="key.speech" />
    <v-container>
      <div
        class="question"
        :style="[message != '' ? {} : {}]"
        v-if="message != ''"
      >
        <div class="output ql-snow">
          <div class="ql-editor" v-html="message"></div>
        </div>
      </div>
      <div class="answer" :style="[answer != '' ? {} : {}]" v-if="answer != ''">
        <div class="output ql-snow">
          <div class="ql-editor" v-html="answer"></div>
        </div>
      </div>
    </v-container>
    <v-container :fill-height="message == ''">
      <v-layout row justify-center align-center>
        <VoiceRecognition
          :record="record"
          :size="size"
          :scale="scale"
          :lang="lang"
          :color="color"
          class="voice-recognition"
          :id="
            isRecording || message != ''
              ? 'voice-recognition-active'
              : 'voice-recognition'
          "
          @update-voice-recognition="updateVoiceRecognition"
          :key="key.voice"
          v-if="ready && !loading"
        />
        <v-progress-circular
          v-if="ready && loading"
          indeterminate
          :size="56"
          :width="6"
          color="#65a000"
          class="voice-recognition"
          style="transform: translate(0px, -20px)"
          :id="
            isRecording || message != ''
              ? 'voice-recognition-active'
              : 'voice-recognition'
          "
        ></v-progress-circular>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../config";
import VoiceRecognition from "../components/VoiceRecognition.vue";
import TextToSpeech from "../components/TextToSpeech.vue";
import ChatLoading from "../components/ChatLoading.vue";
import { Others } from "../function/Others";

export default {
  data() {
    return {
      user: null,
      message: "",
      answer: "",
      isRecording: false,
      record: false,
      size: 56,
      scale: 2,
      lang: this.$route.query.lang || "en",
      color: "#65a000",
      key: {
        voice: null,
        speech: null,
      },
      loading: false,
      ready: false,
    };
  },
  components: {
    VoiceRecognition,
    TextToSpeech,
    ChatLoading,
  },
  methods: {
    generateKey() {
      this.key.voice = Others.GenerateKeyID(10);
      this.key.speech = Others.GenerateKeyID(10);
    },
    updateVoiceRecognition(val, isRecording) {
      console.log("val: ", val);
      console.log("isRecording: ", isRecording);
      this.isRecording = isRecording;
      if (isRecording) {
        this.message = val;
        this.answer = "";
      } else {
        this.sendMessage();
      }
    },
    sendMessage() {
      let message = this.message.trim();
      if (message != "" && !this.loading) {
        this.loading = true;
        let chat_id = "";
        let obj = {
          role: "user",
          content: message,
          files: [],
        };
        const formData = new FormData();
        formData.append("message", message);
        formData.append("chat_id", chat_id);
        formData.append("owner", this.user.EmployeeEmail);

        axios({
          method: "POST",
          url: `${IP}/chat/send-message`,
          headers: {
            Authorization: AuthKey,
          },
          data: formData,
        }).then((response) => {
          this.loading = false;
          console.log("res: ", response.data);
          if (response.data.content != undefined) {
            this.answer = response.data.content;
            this.generateKey();
          }
        });
      }
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    let lang = null;
    let record = false;
    let reload = false;
    if (this.$route.query.lang == undefined) {
      lang = "en";
      reload = true;
    }
    if (this.$route.query.record == undefined) {
      record = false;
      reload = true;
    }
    if (reload) {
      this.$router.push({
        path: "/voice-assistant",
        query: {
          lang: lang,
          record: record,
        },
      });
    } else {
      this.ready = true;
      if (this.$route.query.record === "false") {
        this.record = false;
      }
      if (this.$route.query.record === "true") {
        this.record = true;
      }
      this.generateKey();
    }
  },
};
</script>

<style scoped>
.question {
  width: 100%;
  background: rgb(230, 230, 230);
  padding: 10px;
  border-radius: 10px;
}

.answer {
  width: 100%;
  color: #fff;
  background: #65a000;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
}

.voice-assistant {
  width: 95%;
  height: calc(100vh - 150px);
  margin: 0 auto;
  margin-top: 65px;
}

.voice-recognition {
  transition: 0.2s all;
}

#voice-recognition {
  transition: 0.2s all;
}

#voice-recognition-active {
  transform: scale(0.6);
  transition: 0.2s all;
  position: fixed;
  bottom: 0;
  margin-bottom: 50px;
  border-radius: 1000px;
  /* box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.5); */
}
</style>