<template>
  <div id="app-calendar">
    <v-app id="inspire">
      <v-row class="fill-height">
        <v-col id="calender-col">
          <v-sheet height="64">
            <v-toolbar flat color="white">
              <v-btn
                outlined
                class="mr-1"
                color="grey darken-2"
                @click="setToday"
                >Today</v-btn
              >
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">{{
                $refs.calendar.title
              }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-btn
                class="mr-2"
                dark
                color="blue darken-10"
                @click="addReservation()"
              >
                <div class="mr-1" style="font-size: 25px">+</div>
                Reserve
              </v-btn>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="padding: 1px"
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span style="padding-left: 1px">{{
                      typeToLabel[type]
                    }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>

          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-dialog
                v-model="dialog"
                fullscreen
                persistent
                max-width="700px"
              >
                <v-card style="display: grid">
                  <v-card-title
                    v-model="selectedEvent.operation"
                    :style="
                      selectedEvent.operation == 'Add'
                        ? 'margin-top:50px; text-align-last: center'
                        : 'margin-top:50px'
                    "
                  >
                    <p style="text-align: left; width: 100%">
                      {{ selectedEvent.operation }} reservation
                      <span
                        v-show="selectedEvent.operation != 'Add'"
                        style="float: right"
                        >Reserved by [{{ selectedEvent.made_by }}]</span
                      >
                    </p>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          v-if="selectedEvent.operation != 'Add'"
                          cols="12"
                          xs="12"
                          sm="12"
                          md="3"
                        >
                          <v-select
                            outlined
                            v-model="selectedEvent.operation"
                            :items="['Edit', 'Delete']"
                            @change="resetSelectedData(selectedEvent.operation)"
                            label="Operation"
                          ></v-select>
                        </v-col>

                        <v-col
                          :class="
                            selectedEvent.operation != 'Add'
                              ? 'xs-12 sm-12 md-4'
                              : 'xs-12 sm-12 md-6'
                          "
                        >
                          <v-text-field
                            outlined
                            label="Reservation topic*"
                            v-model="selectedEvent.name"
                            :readonly="
                              selectedEvent.operation == '' ||
                              selectedEvent.operation == 'Delete'
                            "
                            v-validate="'required'"
                            name="Reservation topic"
                          ></v-text-field>
                          <transition name="slide-fade">
                            <v-alert
                              type="error"
                              :color="error_color"
                              v-show="errors.has('Reservation topic')"
                              >{{ errors.first("Reservation topic") }}</v-alert
                            >
                          </transition>
                        </v-col>

                        <!-- Start date picker -->
                        <v-col
                          :class="
                            selectedEvent.operation != 'Add'
                              ? 'xs-12 sm-12 md-4'
                              : 'xs-12 sm-12 md-6'
                          "
                        >
                          <v-dialog
                            ref="startDateDialog"
                            v-model="startDateDialog"
                            :return-value.sync="selectedEvent.start"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="selectedEvent.start"
                                label="Reservation Date"
                                prepend-icon="event"
                                v-validate="'required'"
                                name="Reservation Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                              <transition name="slide-fade">
                                <v-alert
                                  type="error"
                                  :color="error_color"
                                  v-show="errors.has('Reservation Date')"
                                  >{{
                                    errors.first("Reservation Date")
                                  }}</v-alert
                                >
                              </transition>
                            </template>
                            <v-date-picker
                              :readonly="
                                selectedEvent.operation == '' ||
                                selectedEvent.operation == 'Delete'
                              "
                              v-model="selectedEvent.start"
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="startDateDialog = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.startDateDialog.save(
                                    selectedEvent.start
                                  )
                                "
                                >OK</v-btn
                              >
                            </v-date-picker>
                          </v-dialog>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="12">
                          <v-textarea
                            outlined
                            :readonly="
                              selectedEvent.operation == '' ||
                              selectedEvent.operation == 'Delete'
                            "
                            label="Description"
                            rows="2"
                            v-model="selectedEvent.details"
                          ></v-textarea>
                        </v-col>

                        <!-- Start time field -->
                        <v-col cols="6" sm="6">
                          <v-dialog
                            ref="startTimeDialog"
                            v-model="startTimeDialog"
                            :return-value.sync="selectedEvent.startTime"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="selectedEvent.startTime"
                                label="Start Time"
                                prepend-icon="access_time"
                                v-validate="'required'"
                                name="Start Time"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                              <!-- Simple empty field -->
                              <transition name="slide-fade">
                                <v-alert
                                  type="error"
                                  :color="error_color"
                                  v-show="errors.has('Start Time')"
                                  >{{ errors.first("Start Time") }}</v-alert
                                >
                              </transition>
                            </template>
                            <v-time-picker
                              v-if="startTimeDialog"
                              :readonly="
                                selectedEvent.operation == '' ||
                                selectedEvent.operation == 'Delete'
                              "
                              v-model="selectedEvent.startTime"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="startTimeDialog = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.startTimeDialog.save(
                                    selectedEvent.startTime
                                  )
                                "
                                >OK</v-btn
                              >
                            </v-time-picker>
                          </v-dialog>
                        </v-col>

                        <!-- End time field -->
                        <v-col cols="6" sm="6">
                          <v-dialog
                            ref="endTimeDialog"
                            v-model="endTimeDialog"
                            :return-value.sync="selectedEvent.endTime"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                outlined
                                v-model="selectedEvent.endTime"
                                label="End Time"
                                prepend-icon="access_time"
                                v-validate="'required'"
                                name="End Time"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                              <transition name="slide-fade">
                                <v-alert
                                  type="error"
                                  :color="error_color"
                                  v-show="errors.has('End Time')"
                                  >{{ errors.first("End Time") }}</v-alert
                                >
                              </transition>
                              <transition name="slide-fade">
                                <v-alert
                                  type="error"
                                  :color="error_color"
                                  v-show="timeError != null"
                                  >{{ timeError }}</v-alert
                                >
                              </transition>
                            </template>
                            <v-time-picker
                              v-if="endTimeDialog"
                              :readonly="
                                selectedEvent.operation == '' ||
                                selectedEvent.operation == 'Delete'
                              "
                              v-model="selectedEvent.endTime"
                              full-width
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="endTimeDialog = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.endTimeDialog.save(
                                    selectedEvent.endTime
                                  )
                                "
                                >OK</v-btn
                              >
                            </v-time-picker>
                          </v-dialog>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn color="success darken-1" @click="saveReservation()"
                      >Save</v-btn
                    >
                    <v-btn color="error darken-1" @click="resetAllErrorAlert()"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogChooseAction" max-width="400">
                <v-card>
                  <v-card-title class="headline justify-center">{{
                    selectedEvent.name
                  }}</v-card-title>

                  <v-card-text
                    >Description: {{ selectedEvent.details }}</v-card-text
                  >
                  <v-card-text>Date: {{ selectedEvent.start }}</v-card-text>
                  <v-card-text
                    >Start Time: {{ selectedEvent.startTime }}</v-card-text
                  >
                  <v-card-text
                    >End Time: {{ selectedEvent.endTime }}</v-card-text
                  >
                  <v-card-text
                    >Reserved by: {{ selectedEvent.made_by }}</v-card-text
                  >

                  <v-card-actions class="justify-center">
                    <!--             <v-spacer></v-spacer> -->
                    <v-btn
                      v-show="user.EmployeeName == selectedEvent.made_by"
                      color="green darken-1"
                      dark
                      @click="
                        dialog = !dialog;
                        dialogChooseAction = !dialogChooseAction;
                        selectedEvent.operation = 'Edit';
                      "
                      >Edit</v-btn
                    >
                    <v-btn
                      v-show="user.EmployeeName == selectedEvent.made_by"
                      color="error"
                      dark
                      @click="
                        dialog = !dialog;
                        dialogChooseAction = !dialogChooseAction;
                        selectedEvent.operation = 'Delete';
                        deleteReservation(selectedEvent);
                      "
                      >Delete</v-btn
                    >
                    <v-spacer
                      v-show="user.EmployeeName == selectedEvent.made_by"
                    ></v-spacer>
                    <v-btn color="blue" dark @click="dialogChooseAction = false"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { IP, AuthKey } from "../../config";
import Swal from "sweetalert2";
import moment from "moment";
import { extendMoment } from "moment-range";
import axios from "axios";

const Moment = extendMoment(moment);
export default {
  name: "RoomReservation",
  data: () => ({
    dialog: false,
    dialogChooseAction: false,
    startDateDialog: false,
    startDate: "",
    endDateDialog: false,
    endDate: "",
    startTimeDialog: false,
    endTimeDialog: false,
    user: null,
    error_color: "pink",
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {
      name: "",
      details: "",
      start: "",
      end: "",
      startTime: "",
      endTime: "",
      color: "",
      timed: "",
      operation: "",
    },
    tempSelectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "green",
      "orange",
      "grey darken-1",
    ],
    timeError: null,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
      // console.log(date);
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getSplitDate(dateTime) {
      return dateTime.split("T")[0];
    },
    getSplitTime(dateTime) {
      return String(dateTime).slice(0, 16).split("T")[1];
    },
    resetSelectedData(operation) {
      this.selectedEvent = JSON.parse(JSON.stringify(this.tempSelectedEvent));
      this.selectedEvent.operation = operation;
    },
    assignSelectedEvent(event) {
      this.selectedEvent.name = event.name;
      this.selectedEvent.details = event.details;
      this.selectedEvent.start = this.getSplitDate(event.start);
      this.selectedEvent.startTime = this.getSplitTime(event.start);
      this.selectedEvent.endTime = this.getSplitTime(event.end);
      // this.selectedEvent.operation = "Edit";
      this.selectedEvent.id = event.id;
      this.selectedEvent.made_by = event.made_by;
      this.tempSelectedEvent = JSON.parse(JSON.stringify(this.selectedEvent));
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.assignSelectedEvent(event);
        // this.selectedElement = nativeEvent.target;
        this.dialogChooseAction = true;
        setTimeout(() => (this.selectedOpen = true), 10);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      // nativeEvent.stopPropagation();
    },
    addReservation() {
      this.selectedEvent.operation = "Add";
      let events = {
        name: "",
        details: "",
        start: "",
        end: "",
        color: "",
        timed: "",
        operation: "Add",
      };
      const open = () => {
        this.resetAllErrorAlert();
        this.selectedEvent = events;
        this.dialog = true;
        setTimeout(() => (this.selectedOpen = true), 10);
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
    },
    createDateTimeObj(date, time) {
      return `${date}T${time}:00Z`;
    },
    isTimeOverlap(reservedStart, reservedEnd, checkStart, checkEnd) {
      var range = Moment.range(new Date(reservedStart), new Date(reservedEnd));
      var range2 = Moment.range(new Date(checkStart), new Date(checkEnd));
      return range.overlaps(range2);
    },
    getDateDiff(date1, date2) {
      return moment(date1, "DD-MM-YYYY").diff(
        moment(date2, "DD-MM-YYYY"),
        "days"
      );
    },
    createDateTimeWithTFormat(date, time) {
      // Create for format date = {10-05-2020} and time 11:22
      return `${date}T${time}`;
    },

    checkIsReserved(reservedDate, reservedStartTime, reservedEndTime) {
      for (const key in this.events) {
        const element = this.events[key];
        const elementDate = this.getSplitDate(element["start"]);
        if (this.getDateDiff(reservedDate, elementDate) == 0) {
          // console.log(reservedDate, "Same date as", elementDate);
          const checkOverlapTime = this.isTimeOverlap(
            this.createDateTimeWithTFormat(reservedDate, reservedStartTime),
            this.createDateTimeWithTFormat(reservedDate, reservedEndTime),
            element["start"],
            element["end"]
          );
          // console.log(
          //   `Check is reserved time = ${reservedStartTime} - ${reservedEndTime} with element time = ${this.getSplitTime(
          //     element["start"]
          //   )} - ${this.getSplitTime(element["end"])} overlap = `,
          //   checkOverlapTime
          // );
          if (checkOverlapTime == true) {
            return element;
          }
        }
      }
      return false;
    },

    getEventExplainHtmlString(events) {
      const event = events;
      return `<div><b>Topic: </b> ${event["name"]}</div> 
      <div><b>Description: </b> ${event["details"]} </div>
      <div><b>Reserved by: </b> ${event["made_by"]}</div>
      <div><b>Time: </b> [${this.getSplitTime(
        event["start"]
      )} - ${this.getSplitTime(event["end"])}]</div>`;
    },

    customAlertWarningValidateTime() {
      // Check start greater than end time is 1st layer.
      if (this.selectedEvent.endTime < this.selectedEvent.startTime) {
        // console.log(this.selectedEvent.startTime, this.selectedEvent.endTime);
        this.timeError = "End time should greater than Start time";
      } else {
        // Check is time is overlap other reserved or not
        const checkStatus = this.checkIsReserved(
          this.selectedEvent.start,
          this.selectedEvent.startTime,
          this.selectedEvent.endTime
        );
        if (checkStatus == false) {
          return true;
        } else {
          Swal.fire({
            title:
              "Time you choosed has been already reserved, please select new reserve time!",
            html: this.getEventExplainHtmlString(checkStatus),
          });
        }
      }
    },

    resetAllErrorAlert() {
      this.dialog = false;
      this.$validator.reset();
      this.timeError = null;
    },

    loopDeleteJsonOnEditEvent(selectedId) {
      for (const key in this.events) {
        const element = this.events[key];
        if (element["id"] == selectedId) {
          delete this.events[key];
        }
      }
      // console.log("All events", this.events);
    },

    isOwnerOfEvent(made_by) {
      const ownerStatus = this.user.EmployeeName == made_by ? true : false;
      if (ownerStatus) {
        return true;
      } else {
        Swal.fire(
          "You not the owner of this reservation!",
          `Owner is ${made_by}`,
          "error"
        );
        return false;
      }
    },
    saveReservation() {
      this.$validator.validateAll().then(async (check) => {
        if (check) {
          if (this.selectedEvent.operation == "Add") {
            if (this.customAlertWarningValidateTime())
              this.insertReservation(this.selectedEvent);
          } else if (this.selectedEvent.operation == "Edit") {
            if (this.isOwnerOfEvent(this.selectedEvent.made_by)) {
              this.loopDeleteJsonOnEditEvent(this.selectedEvent.id);
              if (this.customAlertWarningValidateTime())
                this.updateReservation(this.selectedEvent);
            }
          } else {
            if (this.isOwnerOfEvent(this.selectedEvent.made_by))
              this.deleteReservation(this.selectedEvent);
          }
          // this.resetAllErrorAlert();
        }
      });
      // console.log("After do action event", this.events);
    },
    createSendingData(event) {
      // console.log("Createobj", event);
      const start = this.createDateTimeObj(event["start"], event["startTime"]);
      const end = this.createDateTimeObj(event["start"], event["endTime"]);
      const data = {
        reservation_topic: event["name"],
        descriptions: event["details"],
        datetime_start: start,
        datetime_end: end,
        made_by: this.user.EmployeeName,
        room_id: parseInt(this.$route.params.roomID),
        id: event["id"],
      };
      return data;
    },
    insertReservation(event) {
      // console.log("Event =", event);
      axios({
        method: "post",
        url: `${IP}/api/addNewReservation`,
        headers: {
          Authorization: AuthKey,
        },
        data: this.createSendingData(event),
      }).then((response) => {
        Swal.fire("Reserved!", "Your reservation has been saved", "success");
        this.initialize();
        // this.dialog = false;
        this.resetAllErrorAlert();
        // console.log(response);
      });
    },
    updateReservation(event) {
      // console.log("Event =", event);
      axios({
        method: "put",
        url: `${IP}/api/editReservation`,
        headers: {
          Authorization: AuthKey,
        },
        data: this.createSendingData(event),
      }).then((response) => {
        Swal.fire(
          "Updated!",
          "Your update reservation has been saved",
          "success"
        );
        this.initialize();
        // this.dialog = false;
        this.resetAllErrorAlert();
        // console.log(response);
      });
    },
    deleteReservation(event) {
      axios
        .delete(`${IP}/api/deleteReservation`, {
          data: this.createSendingData(event),
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((response) => {
          Swal.fire("Deleted!", "Your reservation has been deleted", "success");
          this.initialize();
          // this.dialog = false;
          this.resetAllErrorAlert();
          // console.log(response);
        });
    },
    updateRange({ start, end }) {
      // console.log("Click change range of view start =", start, " end=", end);
    },
    initialize() {
      this.events = [];
      this.getAllReservation();
    },

    async getAllReservation() {
      const api_url = `${IP}/api/getReservationDetail/${this.$route.params.roomID}`;
      axios
        .get(api_url, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((response) => {
          for (let index = 0; index < response.data.length; index++) {
            const jsonObj = response.data[index];
            // console.log("Data = ", jsonObj);
            const data = {
              id: jsonObj["id"],
              name: jsonObj["reservation_topic"],
              details: jsonObj["descriptions"],
              start: jsonObj["datetime_start"].replace(".000Z", ""),
              end: jsonObj["datetime_end"].replace(".000Z", ""),
              made_by: jsonObj["made_by"],
              color: this.colors[this.randomColorIndex()],
              timed: true,
            };
            this.events.push(data);
          }
          // console.log("All event =", this.events);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    randomColorIndex() {
      return Math.floor(Math.random() * this.colors.length);
    },
    randomColorHSL() {
      return `hsla(${~~(360 * Math.random())},70%,70%,0.8)`;
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    this.initialize();
  },
};
</script>

<style scoped>
#v-app {
  margin-top: 70px;
}
#calender-col {
  margin-top: 5%;
  align-self: center;
}
</style>