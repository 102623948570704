var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile"},[_c('div',{staticClass:"content-header"},[_c('div',{staticClass:"banner",style:(`height: ${_vm.height / 3}px`)},[(_vm.user.EmployeeEmail == _vm.profile.EmployeeEmail)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('input',{attrs:{"id":"fileUploadBanner","type":"file","accept":"image/*","hidden":""},on:{"change":_vm.handleFileUploadBanner}}),_c('v-btn',_vm._g({staticClass:"btn-change-banner",attrs:{"icon":""},on:{"click":function($event){return _vm.chooseFiles('banner')}}},on),[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-camera")])],1)]}}],null,false,1215007986)},[_c('span',[_vm._v("Update Banner Image")])]):_vm._e(),(_vm.banner_preview != '')?_c('v-img',{staticClass:"banner-img",style:(`height: ${_vm.height / 3}px`),attrs:{"src":_vm.banner_preview,"lazy-src":_vm.banner_preview}}):_vm._e(),(_vm.banner_preview == '' && _vm.profile.BannerImage != null)?_c('v-img',{staticClass:"banner-img",style:(`height: ${_vm.height / 3}px`),attrs:{"src":`${_vm.IP}/banner_img/${_vm.profile.BannerImage}`,"lazy-src":`${_vm.IP}/banner_img/${_vm.profile.BannerImage}`}}):_vm._e()],1)]),_c('div',{staticClass:"content-inside"},[_c('div',{staticClass:"content-inside-header"},[_c('v-tabs',{staticClass:"content-inside-tab",attrs:{"background-color":"white","show-arrows":"","centered":"","grow":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{on:{"click":function($event){_vm.tabs = 1}}},[_vm._v("Introduce")]),_c('v-tab',{on:{"click":function($event){_vm.tabs = 2}}},[_vm._v("Blogs")])],1),_c('v-avatar',{staticClass:"profile-avatar",attrs:{"size":"150","color":"white"}},[(_vm.profile_preview != '')?_c('v-img',{attrs:{"src":_vm.profile_preview,"lazy-src":_vm.profile_preview}}):_vm._e(),(
            _vm.profile_preview == '' &&
            _vm.profile.ProfileImage != null &&
            _vm.profile.ProfileImage != 'null'
          )?_c('v-img',{attrs:{"src":`${_vm.IP}/profile_img/${_vm.profile.ProfileImage}`,"lazy-src":`${_vm.IP}/profile_img/${_vm.profile.ProfileImage}`}}):_vm._e(),(
            _vm.profile_preview == '' &&
            (_vm.profile.ProfileImage == null || _vm.profile.ProfileImage == 'null')
          )?_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-account-circle")]):_vm._e()],1),_c('div',{staticClass:"content-introduce"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.profile.EmployeeName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.profile.EmployeeEmail))])],1),_c('div',{staticStyle:{"border-left":"1px solid rgb(224, 224, 224)"}},[(_vm.user.EmployeeEmail == _vm.profile.EmployeeEmail)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('input',{attrs:{"id":"fileUploadProfile","type":"file","accept":"image/*","hidden":""},on:{"change":_vm.handleFileUploadProfile}}),_c('v-btn',_vm._g({staticStyle:{"margin-left":"10px"},attrs:{"icon":""},on:{"click":function($event){return _vm.chooseFiles('profile')}}},on),[_c('v-icon',[_vm._v("mdi-account-box")])],1)]}}],null,false,725464123)},[_c('span',[_vm._v("Update Profile Image")])]):_vm._e()],1)],1)],1),_c('div',{staticStyle:{"border-bottom":"1px solid rgb(224, 224, 224)"}})],1)]),_c('div',{staticClass:"content-area"},[(_vm.tabs == 1)?_c('Introduce',{attrs:{"email":_vm.profile.EmployeeEmail}}):_vm._e(),(_vm.tabs == 2)?_c('Blogs',{attrs:{"email":_vm.profile.EmployeeEmail}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }