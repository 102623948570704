<template>
  <div class="slide-notification">
    <v-container style="height: inherit">
      <template>
        <v-carousel
          height="500"
          hide-delimiter-background
          hide-delimiters
          :show-arrows="false"
          v-if="items.length == 0"
        >
          <v-carousel-item>
            <v-sheet color="#9ACA3B" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <div class="none-news">ยังไม่มีประกาศข่าวในขณะนี้</div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>

        <vueper-slides
          v-else
          :touchable="false"
          :autoplay="true"
          fixed-height="500px"
          prevent-y-scroll
          fade
        >
          <vueper-slide
            v-for="(item, i) in items"
            :key="i"
            :image="`${IP}/news_file/${item.NewsBackgroundImage}`"
            pauseOnHover="true"
            lazy
            lazy-load-on-drag
          >
            <template v-slot:content>
              <v-container style="height: inherit">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-container style="height: inherit">
                      <div class="content-in-slide">
                        <div class="content-area">
                          <v-card class="transparent">
                            <v-container
                              style="
                                height: inherit;
                                text-align: center;
                                display: inline;
                                grid-template-rows: min-content;
                              "
                            >
                              <h1 id="content-title">
                                {{ Decode(item.NewsTitle) }}
                              </h1>
                              <br />
                              <p id="content-description">
                                {{ Decode(item.NewsDescription) }}
                              </p>
                              <div style="width: 100%" class="card-actions">
                                <v-btn
                                  id="content-button-action"
                                  class="hvr-grow transition"
                                  color="red"
                                  @click="readBlog(item.NewsPath)"
                                  >read more</v-btn
                                >
                              </div>
                            </v-container>
                          </v-card>
                        </div>
                      </div>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </template>
          </vueper-slide>
        </vueper-slides>
      </template>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  data() {
    return {
      IP: IP,
      items: [],
    };
  },
  components: { VueperSlides, VueperSlide },
  methods: {
    GetNewsForSlides() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/select-news-for-slides`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            // console.log(response.data)
            if (response.data.length > 0) {
              this.items = response.data;
            }
            // console.log('slide notification: ', this.items);
            // this.items = [];
          });
      });
    },
    readBlog(id) {
      this.$router.push({
        name: "read-blog",
        params: {
          id: id,
        },
      });
    },
    Decode(data) {
      var result = Buffer.from(data, "base64").toString();
      return result;
    },
    start() {
      this.GetNewsForSlides();
    },
  },
  created() {
    this.start();
  },
};
</script>

<style scoped>
.slide-notification {
  width: 93%;
  margin: 0 auto;
}

.content-in-slide {
  width: 100%;
  height: 100%;
}

.content-area {
  width: 100%;
  height: 410px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.content-footer {
  position: absolute;
  right: 0;
  bottom: 0;
}

.transparent {
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  border-color: transparent !important;
}

.card-actions {
  position: absolute;
  padding: 40px;
  bottom: 0;
  right: 0;
}

#content-title {
  width: 100%;
  font-size: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  padding-top: 90px;
  color: white;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#content-description {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  color: white;
  font-size: 20px;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-top: 25px;
  text-overflow: ellipsis;
}

#content-button-action {
  color: white;
}

.vueperslides__arrow {
  color: rgb(255, 0, 0);
}

.none-news {
  font-size: 20px;
  color: white;
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

a {
  text-decoration: none !important;
}

.transition {
  background: #151515 none repeat scroll 0 0;
  color: #fff;
  cursor: pointer;
  margin: 10px 5px;
  padding: 10px 20px;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  text-transform: uppercase;
  border: 1px solid red;
}
/*==== grow and shrink ====*/
.hvr-grow,
.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.hvr-shrink:hover,
.hvr-shrink:focus,
.hvr-shrink:active {
  webkit-transform: scale(0.1);
  transform: scale(0.9);
}
</style>