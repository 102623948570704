<template>
  <div id="app">
    <v-app id="v-app">
      <v-card-title></v-card-title>
      <v-data-table
        :headers="headers"
        :items="teamListData"
        sort-by="teamname"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:item.role="{ item }">
          <v-chip :color="getUserColor(item.role)" dark>
            <v-avatar left>
              <v-icon>{{ getUserIcon(item.role) }}</v-icon>
            </v-avatar>
            {{ item.role }}
          </v-chip>
        </template>

        <template v-slot:top>
          <v-toolbar flat color="grey lighten-4">
            <v-toolbar-title v-if="screenWidth >= 600">
              <v-icon>mdi-account-multiple-plus-outline</v-icon>Team List
            </v-toolbar-title>
            <v-divider
              v-if="screenWidth >= 600"
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-text-field
              class="shrink"
              rounded
              dense
              filled
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>

            <!-- Add team dialog -->
            <v-dialog
              v-model="addTeamDialog"
              activator="addTeamDialog"
              max-width="50%"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green darken-3"
                  dark
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-2">mdi-plus-minus-box</v-icon>Team
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">Manage Team</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="4" sm="12" md="12">
                        <v-select
                          :items="teamOption"
                          label="Operation"
                          v-model="teamOptionSelect"
                          :rules="required"
                          required
                          @input="changedLabel"
                        ></v-select>
                      </v-col>

                      <v-col
                        v-if="teamOptionSelect === 'Add'"
                        cols="12"
                        lg="8"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="newTeamNameText"
                          placeholder="Team name"
                          clearable
                          counter="0"
                          :rules="required"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col
                        v-if="teamOptionSelect === 'Delete'"
                        cols="12"
                        lg="8"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          :items="teamName"
                          label="Team name"
                          v-model="newTeamNameText"
                          :search-input.sync="searchInput"
                          autocomplete
                          :rules="required"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="addTeamDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="saveNewTeam"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add employee team Dialog go here -->
            <v-dialog v-model="dialog" max-width="50%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green darken-3"
                  dark
                  class="ml-3"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon class="mr-2">mdi-account-multiple-plus</v-icon>New
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <!-- All field in dialog -->
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="employeeName"
                          label="Employee name"
                          v-model="editedItem.name"
                          :search-input.sync="searchInput"
                          autocomplete
                          :rules="required"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="teamName"
                          label="Team name"
                          v-model="editedItem.teamname"
                          :search-input.sync="searchInput"
                          autocomplete
                          :rules="required"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="role"
                          label="Role"
                          v-model="editedItem.role"
                          :search-input.sync="searchInput"
                          autocomplete
                          :rules="required"
                          required
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <!--ALl Action button in each row -->
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-1" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Refresh</v-btn>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import Swal from "sweetalert2";

export default {
  name: "TeamManagement",
  data: () => ({
    dialog: false,
    addTeamDialog: false,
    headers: [
      {
        text: "Employee Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Teamname", value: "teamname" },
      { text: "Role", value: "role" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    teamListData: [],
    role: ["Member", "Manager"],
    teamName: [],
    employeeName: [],
    employeeEmailAndName: [],
    searchInput: "",
    search: "",
    editedIndex: -1,
    editedItem: {
      name: "",
      teamname: "",
      role: "",
    },
    defaultItem: {
      name: "",
      teamname: "",
      role: "",
    },
    newTeamNameText: "",
    required: [(value) => !!value || "Required"],
    teamOption: ["Add", "Delete"],
    teamOptionSelect: "Add",
    OperationSelected: null,
    screenWidth: window.screen.width,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add Employee Team"
        : "Edit Employee Team";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.employeeName = [];
      await axios
        .get(`${IP}/getAllEmployeeName`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((res) => {
          for (let index in res.data) {
            this.employeeName.push(res.data[index].EmployeeName);
          }
        });
      await this.getAllTeamName();
      await axios
        .get(`${IP}/getAllUserEmailAndName`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((res) => {
          for (const index in res.data) {
            this.employeeEmailAndName.push(res.data[index]);
          }
        });
      await this.getAllInformationMappingTeam();
    },
    async getAllInformationMappingTeam() {
      this.teamListData = [];
      await axios
        .get(`${IP}/getAllInformationMappingTeam`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((res) => {
          for (const index in res.data) {
            let objs = res.data[index];
            let employeeName = this.getEmployeeName(objs.EmployeeEmail);
            let data = {
              id: objs.RowID,
              name: employeeName[0]["EmployeeName"],
              email: objs.EmployeeEmail,
              teamname: objs.TeamName,
              role: objs.EmployeeRole,
            };
            this.teamListData.push(data);
          }
        });
    },

    async getAllTeamName() {
      this.teamName = [];
      await axios
        .get(`${IP}/getAllTeamName`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((res) => {
          for (const index in res.data) {
            this.teamName.push(res.data[index].TeamName);
          }
        });
    },
    getEmployeeName(email) {
      return this.employeeEmailAndName.filter(function (data) {
        return data.EmployeeEmail == email;
      });
    },
    getEmployeeEmail(name) {
      return this.employeeEmailAndName.filter(function (data) {
        return data.EmployeeName == name;
      });
    },
    editItem(item) {
      this.editedIndex = this.teamListData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    async deleteItem(item) {
      const index = this.teamListData.indexOf(item);
      await axios({
        url: `${IP}/deleteEmployeeFromTeam`,
        headers: {
          Authorization: AuthKey,
        },
        method: "post",
        data: {
          RowID: this.teamListData[index].id,
        },
      }).then((res) => {
        this.sweetAlertResponse(res, "Success Delete Employee From Team");
      });
      this.teamListData.splice(index, 1);
      this.initialize();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      // Cases update
      if (this.editedIndex > -1) {
        Object.assign(this.teamListData[this.editedIndex], this.editedItem);
        await axios({
          url: `${IP}/addNewEmployeeToTeam`,
          method: "post",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            Operation: "Update",
            RowID: this.teamListData[this.editedIndex].id,
            Teamname: this.editedItem.teamname,
            EmployeeEmail: this.teamListData[this.editedIndex].email,
            EmployeeRole: this.editedItem.role,
          },
        }).then((res) => {
          this.initialize();
          this.sweetAlertResponse(
            res,
            "Success Edit Employee Team Information"
          );
          this.close();
        });
      } else {
        // Case new record
        if (
          this.editedItem.teamname != "" &&
          this.editedItem.name != "" &&
          this.editedItem.role != ""
        ) {
          let employeeEmail = await this.getEmployeeEmail(this.editedItem.name);
          employeeEmail = employeeEmail[0]["EmployeeEmail"];
          this.teamListData.push(this.editedItem);
          await axios({
            url: `${IP}/addNewEmployeeToTeam`,
            headers: {
              Authorization: AuthKey,
            },
            method: "post",
            data: {
              Operation: "New",
              RowID: "",
              Teamname: this.editedItem.teamname,
              EmployeeEmail: employeeEmail,
              EmployeeRole: this.editedItem.role,
            },
          }).then((res) => {
            this.initialize();
            this.sweetAlertResponse(res, "Success Add Employee To Team");
          });
          this.close();
        } else {
          Swal.fire("Please Fill Information", "", "warning");
        }
      }
    },
    async saveNewTeam() {
      if (this.newTeamNameText == "") {
        Swal.fire("Please Fill Information", "", "warning");
      } else {
        if (this.teamOptionSelect == "Add") {
          await axios({
            url: `${IP}/addNewTeam`,
            headers: {
              Authorization: AuthKey,
            },
            method: "post",
            data: {
              Teamname: this.newTeamNameText,
            },
          }).then(async (res) => {
            if (res.status == 200) await this.getAllTeamName();
            this.sweetAlertResponse(res, "Success Add New Team");
            this.addTeamDialog = false;
            this.initialize();
          });
        } else {
          //Delete cases
          await axios({
            url: `${IP}/deleteTeam`,
            headers: {
              Authorization: AuthKey,
            },
            method: "post",
            data: {
              Teamname: this.newTeamNameText,
            },
          }).then(async (res) => {
            if (res.status == 200) {
              await this.getAllTeamName();
              await this.getAllInformationMappingTeam();
              this.sweetAlertResponse(res, "Success Delete Team");
              this.addTeamDialog = false;
              this.initialize();
            }
          });
        }
      }
    },
    changedLabel(event) {
      if (this.OperationSelected != event) {
        this.newTeamNameText = "";
        this.OperationSelected = event;
      }
    },
    sweetAlertResponse(res, titles) {
      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          title: titles,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Please try again",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    getUserColor(role) {
      return role == "Manager" ? "#311B92" : "#5C6BC0";
    },
    getUserIcon(role) {
      return role == "Manager" ? "mdi-account-tie" : "mdi-account-circle";
    },
  },
};
</script>

<style scoped>
#v-app {
  margin-top: 80px;
}
</style>