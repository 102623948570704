<template>
  <div>
    <!-- external -->
    <v-container
      fill-height
      fluid
      style="margin: 0px 40px 40px 0px; max-width: 860px; margin: 0 auto"
    >
      <v-row align="center" justify="center">
        <v-img
          :src="require('../../public/STelligence-logo-full-size.png')"
          style="
            display: block;
            margin-top: 35px;
            margin-left: auto;
            margin-right: auto;
            max-width: 350px;
            width: 80%;
          "
        ></v-img>
      </v-row>
      <v-row align="center" justify="center" style="margin-bottom: 20px">
        <h2>STelligence Support Ticket System</h2>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="10" xs="10" sm="10" md="10" lg="10" xl="10">
          <v-text-field
            id="tCustomer"
            value
            name="Customer"
            v-model="newCase.Customer"
            label="Customer"
            placeholder="Please input your company name."
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="10" xs="10" sm="10" md="10" lg="10" xl="10">
          <v-text-field
            value
            name="Subject"
            v-model="newCase.Subject"
            label="Subject"
            outlined
            placeholder="please input case information"
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="10" xs="10" sm="10" md="10" lg="10" xl="10">
          <v-text-field
            value
            name="Product"
            v-model="newCase.Product"
            label="Product"
            outlined
            placeholder="please input product that you want to open a new support case."
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="10" xs="10" sm="10" md="10" lg="10" xl="10">
          <v-text-field
            name="email"
            v-model="newCase.ContactPoint"
            :rules="[rules.required, rules.email]"
            label="Contact Point"
            outlined
            placeholder="please input your contact email."
            v-validate="'required|email'"
            dense
            hide-details
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              type="error"
              dense
              style="margin-top: 10px; margin-bottom: -10px"
              v-show="errors.has('email')"
              >{{ errors.first("email") }}</v-alert
            >
          </transition>
        </v-col>
        <v-col cols="10" xs="10" sm="10" md="10" lg="10" xl="10">
          <v-textarea
            outlined
            v-model="newCase.CaseDetail"
            name="CaseDetail"
            label="Case Description"
            placeholder="Please input case description.
our team will contact you back and ask for more information after this case has been open."
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-btn
          id="submitBtn"
          depressed
          color="primary"
          @click="CreateNewCase(newCase)"
          >Open New Case</v-btn
        >
      </v-row>
    </v-container>
    <!-- internal -->
    <!-- <InternalSupport v-if="user != null" /> -->
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../config";
// components
import InternalSupport from "../components/Internal_Support.vue";

export default {
  data() {
    return {
      user: null,
      IP: IP,
      listItems: [],
      newCase: {
        Customer: "",
        Product: "",
        ContactPoint: "",
        CaseDetail: "",
        Subject: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  components: {
    InternalSupport,
  },
  methods: {
    CreateNewCase(param) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          Swal.fire({
            title: "Are you sure ?",
            text: "Do you want to open support ticket ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.CreateNewCaseProcess(param).then(async (response) => {
                if (response == "add success") {
                  Swal.fire("Open support ticket success!", "", "success");
                  document.getElementById("submitBtn").inneTML = "Case opened";
                  document.getElementById("submitBtn").disabled = true;
                }
              });
            }
          });
        } else {
          Swal.fire({
            title: "Please check Contact Email",
            text: "is you email correct ?",
            icon: "warning",
          });
        }
      });
    },
    CreateNewCaseProcess(param) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/addNewSupportCase",
          headers: {
            Authorization: AuthKey,
          },
          data: param,
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
  },
};
</script>