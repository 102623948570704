import { getLocalUser } from "./helpers/auth";
import moment from 'moment';
import * as Msal from 'msal';
import { CLIENDID } from './config'

const msalConfig = {
  auth: {
    clientId: CLIENDID,
    // authority: "https://login.microsoftonline.com/stelligence365.onmicrosoft.com"
    authority: "https://login.microsoftonline.com/common"
  }
}

const msalInstance = new Msal.UserAgentApplication(msalConfig);

msalInstance.handleRedirectCallback((error, response) => {
  //
});

const user = getLocalUser();

export default {
  state: {
    currentUser: user,
    drawer: false,
    readingEngFull: false,
    readingEngShort: false,
    writingEngFull: false,
    writingEngShort: false,
    logical: false,
    chatDrawerMini: false,
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
  },
  mutations: {
    loginSuccess(state, payload) {
      state.currentUser = payload;
      localStorage.setItem("user", JSON.stringify(state.currentUser));
      localStorage.setItem("schedule_time", moment().add(30, 'm').toDate())
    },
    updateProfile(state, payload) {
      state.currentUser = payload;
      localStorage.setItem("user", JSON.stringify(state.currentUser));
    },
    logout(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("schedule_time")
      state.currentUser = null;
      console.log('logout microsoft: ', JSON.parse(localStorage.getItem('microsoft')))
      if(JSON.parse(localStorage.getItem('microsoft')) != null){
        console.log('microsoft logout!')
        localStorage.removeItem('microsoft')
        msalInstance.logout()
      }else{
        location.reload()
      }
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_READINGENGFULL(state, payload){
        state.readingEngFull = payload
    },
    SET_WRITINGENGFULL(state, payload){
        state.writingEngFull = payload
    },
    SET_READINGENGSHORT(state, payload){
        state.readingEngShort = payload
    },
    SET_WRITINGENGSHORT(state, payload){
        state.writingEngShort = payload
    },
    SET_LOGICAL(state, payload){
        state.logical = payload
    },
  },
  actions: {
    login(context) {
      context.commit("login");
    },
  },
};
