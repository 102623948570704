import Axios from "axios";

export function initialize(store, router) {
  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const currentUser = store.state.currentUser;
    if (currentUser != null) {
      const card = JSON.parse(currentUser.AccessRightsJSON);
      const array = card[0].Card;
      let state = false;

      if (requiresAuth) {
        // console.log('requiresAuth', requiresAuth)
        // console.log('AdminAuth', to.meta.AdminAuth)

        if (to.meta.AdminAuth) {
          if (currentUser.Role == "Admin") {
            next();
          } else {
            next("/");
          }
        } else {
        //   console.log("per: ", to.meta.Permission);
          if (to.meta.Permission != undefined) {
            for (var i = 0; i < array.length; i++) {
              if (to.meta.Permission === array[i]) {
                state = true;
              }
            }
          } else {
            state = true;
          }
          if (state) {
            next();
          } else {
            next("/");
          }
        }
      } else {
        next();
      }
    } else {
      if (!requiresAuth) {
        next();
      } else {
        next("/");
      }
    }
  });
}
