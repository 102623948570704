var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"voice-recognition"},[(!_vm.isRecording)?_c('v-icon',{staticClass:"custom-btn",style:([
      _vm.mic_hover
        ? { background: this.c, color: '#FFF' }
        : { background: '#e4e4e4', color: '#616161' },
    ]),attrs:{"size":_vm.s},on:{"click":_vm.startVoiceRecognition,"mouseenter":function($event){_vm.mic_hover = true},"mouseleave":function($event){_vm.mic_hover = false}}},[_vm._v("mdi-microphone")]):_c('div',{staticClass:"custom-btn",style:([
      _vm.voice_hover
        ? {
            width: `${_vm.s + 40}px`,
            height: `${_vm.s + 40}px`,
            background: '#FFF',
            opacity: 0.6,
          }
        : {
            width: `${_vm.s + 40}px`,
            height: `${_vm.s + 40}px`,
            background: '#FFF',
          },
    ]),on:{"click":_vm.stopVoiceRecognition,"mouseenter":function($event){_vm.voice_hover = true},"mouseleave":function($event){_vm.voice_hover = false}}},[_c('VoiceAnimation',{attrs:{"scale":_vm.sc,"background":_vm.c}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }