<template>
  <div class="main-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <AlertMessage :data="message" :key="AlertMessageKey" />
    <div
      style="border-bottom: 1px solid rgb(216, 216, 216); margin-bottom: 20px"
    >
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Sale Order</v-col>
      </v-row>
    </div>
    <div>
      <GeneralInformation
        :ro="true"
        :dt="data"
        :ep="EmployeeListItems"
        :pd="ProductItems"
        :key="GeneralInformationKey"
        page="read"
        v-if="permission.general_information.value"
      />
      <br />
      <FinancialInformation
        :ro="true"
        :dt="data"
        :key="FinancialInformationKey"
        v-if="permission.financial_information.value"
      />
      <br />
      <!-- <ProjectManagement :ro="true" :dt="data" :key="ProjectManagementKey" />
      <br />
      <CustomerInformation :ro="true" :dt="data" :key="CustomerInformationKey" />
      <br />
      <RelatedPersonInformation :ro="true" :dt="data" :key="RelatedPersonInformationKey" />
      <br /> -->
    </div>
    <v-divider></v-divider>
  </div>
</template>

<script>
import { TpAxios } from "../../helpers/tp_axios";
import { Others } from "../../function/Others";
import { Permissions } from "../../function/Permissions";
// components
import GeneralInformation from "../../components/SaleOrder/GeneralInformation.vue";
import FinancialInformation from "../../components/SaleOrder/FinancialInformation.vue";
import ProjectManagement from "../../components/SaleOrder/ProjectManagement.vue";
import CustomerInformation from "../../components/SaleOrder/CustomerInformation.vue";
import RelatedPersonInformation from "../../components/SaleOrder/RelatedPersonInformation.vue";

export default {
  data() {
    return {
      user: null,
      loading: false,
      raw_data: null,
      data: null,
      EmployeeListItems: [],
      ProductItems: [],
      message: [],
      AlertMessageKey: null,
      // components key
      GeneralInformationKey: null,
      FinancialInformationKey: null,
      ProjectManagementKey: null,
      CustomerInformationKey: null,
      RelatedPersonInformationKey: null,
      // permissions
      user_permission: null,
      permission: {
        general_information: {
          id: [3],
          value: false,
        },
        financial_information: {
          id: [5],
          value: false,
        },
      },
    };
  },
  components: {
    GeneralInformation,
    FinancialInformation,
    ProjectManagement,
    CustomerInformation,
    RelatedPersonInformation,
  },
  methods: {
    GetAllProductType() {
      return new Promise(async (resolve, reject) => {
        this.ProductItems = [];
        TpAxios.Get(`/GetProductInfomation`).then((response) => {
          this.ProductItems = response;
          resolve(true);
        });
      });
    },
    GetAllEmployee() {
      return new Promise(async (resolve, reject) => {
        this.EmployeeListItems = [];
        TpAxios.Get(`/employee-select-all`).then((response) => {
          this.EmployeeListItems = response.recordset;
          resolve(true);
        });
      });
    },
    GetSaleOrdersBySOID() {
      return new Promise(async (resolve, reject) => {
        TpAxios.Get(`/sale-orders/get/${this.$route.params.so_number}`).then(
          (response) => {
            if (response.message != undefined) {
              this.AlertMessage("error", response.message);
            } else {
              this.raw_data = JSON.parse(JSON.stringify(response.data));
              this.data = JSON.parse(JSON.stringify(response.data));
            }
            resolve(true);
          }
        );
      });
    },
    AlertMessage(type, text) {
      this.message.push({
        type: type,
        text: text,
      });
      this.AlertMessageKey = Others.GenerateKeyID(10);
    },
    async SetComponentsKey() {
      this.GeneralInformationKey = await Others.GenerateKeyID(10);
      this.FinancialInformationKey = await Others.GenerateKeyID(10);
      this.ProjectManagementKey = await Others.GenerateKeyID(10);
      this.CustomerInformationKey = await Others.GenerateKeyID(10);
      this.RelatedPersonInformationKey = await Others.GenerateKeyID(10);
      return true;
    },
    async initialize() {
      this.loading = true;
      await this.GetAllEmployee();
      await this.GetAllProductType();
      await this.GetSaleOrdersBySOID();
      await this.SetComponentsKey();
      this.loading = false;
    },
  },
  async created() {
    this.user = this.$store.getters.currentUser;
    this.user_permission = await Permissions.GetRoleAndPermissions(
      "SaleOrders"
    );
    this.permission = await Permissions.SetAccess(
      this.user_permission,
      this.permission
    );
    let access = false;
    for (var key in this.permission) {
      if (this.permission[key].value) {
        access = true;
      }
    }
    if (access) {
      await this.initialize();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.main-area {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 50px;
}

.loading-content {
  padding: 30px;
  font-size: 18px;
  text-align: center;
}
</style>