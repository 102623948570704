<template>
  <div class="animation-area" :style="`transform: scale(${sc});`">
    <div class="moon"></div>
    <div class="kiki bounce"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="star"></div>
    <div class="small-star"></div>
    <div class="small-star"></div>
    <div class="small-star"></div>
    <div class="small-star"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sc: this.scale || 1,
    };
  },
  props: ["scale"],
};
</script>

<style scoped>
.animation-area {
  width: 100%;
  height: 100%;
  background: #1a313f;
}

.moon {
  width: 8px;
  height: 8px;
  box-shadow: 160px 8px 0 #ddeff0, 168px 8px 0 #ddeff0, 176px 8px 0 #ddeff0,
    184px 8px 0 #ddeff0, 192px 8px 0 #ddeff0, 200px 8px 0 #ddeff0,
    208px 8px 0 #ddeff0, 216px 8px 0 #ddeff0, 224px 8px 0 #ddeff0,
    232px 8px 0 #ddeff0, 136px 16px 0 #ddeff0, 144px 16px 0 #ddeff0,
    152px 16px 0 #ddeff0, 160px 16px 0 #bce1e2, 168px 16px 0 #bce1e2,
    176px 16px 0 #bce1e2, 184px 16px 0 #bce1e2, 192px 16px 0 #bce1e2,
    200px 16px 0 #bce1e2, 208px 16px 0 #a8d8d8, 216px 16px 0 #a8d8d8,
    224px 16px 0 #a8d8d8, 232px 16px 0 #a8d8d8, 240px 16px 0 #ddeff0,
    248px 16px 0 #ddeff0, 256px 16px 0 #ddeff0, 120px 24px 0 #ddeff0,
    128px 24px 0 #ddeff0, 136px 24px 0 #bce1e2, 144px 24px 0 #bce1e2,
    152px 24px 0 #bce1e2, 160px 24px 0 #bce1e2, 168px 24px 0 #bce1e2,
    176px 24px 0 #bce1e2, 184px 24px 0 #bce1e2, 192px 24px 0 #bce1e2,
    200px 24px 0 #bce1e2, 208px 24px 0 #a8d8d8, 216px 24px 0 #a8d8d8,
    224px 24px 0 #a8d8d8, 232px 24px 0 #a8d8d8, 240px 24px 0 #c7e6e7,
    248px 24px 0 #c7e6e7, 256px 24px 0 #a8d8d8, 264px 24px 0 #ddeff0,
    272px 24px 0 #ddeff0, 104px 32px 0 #ddeff0, 112px 32px 0 #ddeff0,
    120px 32px 0 #bce1e2, 128px 32px 0 #bce1e2, 136px 32px 0 #bce1e2,
    144px 32px 0 #bce1e2, 152px 32px 0 #bce1e2, 160px 32px 0 #a8d8d8,
    168px 32px 0 #bce1e2, 176px 32px 0 #bce1e2, 184px 32px 0 #bce1e2,
    192px 32px 0 #bce1e2, 200px 32px 0 #bce1e2, 208px 32px 0 #bce1e2,
    216px 32px 0 #bce1e2, 224px 32px 0 #bce1e2, 232px 32px 0 #bce1e2,
    240px 32px 0 #bce1e2, 248px 32px 0 #bce1e2, 256px 32px 0 #c7e6e7,
    264px 32px 0 #c7e6e7, 272px 32px 0 #a8d8d8, 280px 32px 0 #ddeff0,
    288px 32px 0 #ddeff0, 88px 40px 0 #ddeff0, 96px 40px 0 #ddeff0,
    104px 40px 0 #bce1e2, 112px 40px 0 #bce1e2, 120px 40px 0 #bce1e2,
    128px 40px 0 #bce1e2, 136px 40px 0 #a8d8d8, 144px 40px 0 #a8d8d8,
    152px 40px 0 #a8d8d8, 160px 40px 0 #a8d8d8, 168px 40px 0 #a8d8d8,
    176px 40px 0 #bce1e2, 184px 40px 0 #bce1e2, 192px 40px 0 #bce1e2,
    200px 40px 0 #bce1e2, 208px 40px 0 #bce1e2, 216px 40px 0 #bce1e2,
    224px 40px 0 #bce1e2, 232px 40px 0 #bce1e2, 240px 40px 0 #bce1e2,
    248px 40px 0 #c7e6e7, 256px 40px 0 #c7e6e7, 264px 40px 0 #c7e6e7,
    272px 40px 0 #a8d8d8, 280px 40px 0 #a8d8d8, 288px 40px 0 #a8d8d8,
    296px 40px 0 #ddeff0, 304px 40px 0 #ddeff0, 80px 48px 0 #ddeff0,
    88px 48px 0 #a8d8d8, 96px 48px 0 #a8d8d8, 104px 48px 0 #a8d8d8,
    112px 48px 0 #a8d8d8, 120px 48px 0 #a8d8d8, 128px 48px 0 #a8d8d8,
    136px 48px 0 #a8d8d8, 144px 48px 0 #a8d8d8, 152px 48px 0 #a8d8d8,
    160px 48px 0 #a8d8d8, 168px 48px 0 #a8d8d8, 176px 48px 0 #a8d8d8,
    184px 48px 0 #a8d8d8, 192px 48px 0 #a8d8d8, 200px 48px 0 #a8d8d8,
    208px 48px 0 #a8d8d8, 216px 48px 0 #c7e6e7, 224px 48px 0 #c7e6e7,
    232px 48px 0 #c7e6e7, 240px 48px 0 #c7e6e7, 248px 48px 0 #c7e6e7,
    256px 48px 0 #c7e6e7, 264px 48px 0 #c7e6e7, 272px 48px 0 #c7e6e7,
    280px 48px 0 #a8d8d8, 288px 48px 0 #a8d8d8, 296px 48px 0 #a8d8d8,
    304px 48px 0 #a8d8d8, 312px 48px 0 #ddeff0, 72px 56px 0 #ddeff0,
    80px 56px 0 #a8d8d8, 88px 56px 0 #a8d8d8, 96px 56px 0 #a8d8d8,
    104px 56px 0 #a8d8d8, 112px 56px 0 #a8d8d8, 120px 56px 0 #a8d8d8,
    128px 56px 0 #a8d8d8, 136px 56px 0 #a8d8d8, 144px 56px 0 #a8d8d8,
    152px 56px 0 #a8d8d8, 160px 56px 0 #c7e6e7, 168px 56px 0 #c7e6e7,
    176px 56px 0 #c7e6e7, 184px 56px 0 #c7e6e7, 192px 56px 0 #a8d8d8,
    200px 56px 0 #a8d8d8, 208px 56px 0 #a8d8d8, 216px 56px 0 #c7e6e7,
    224px 56px 0 #c7e6e7, 232px 56px 0 #c7e6e7, 240px 56px 0 #c7e6e7,
    248px 56px 0 #c7e6e7, 256px 56px 0 #c7e6e7, 264px 56px 0 #c7e6e7,
    272px 56px 0 #c7e6e7, 280px 56px 0 #a8d8d8, 288px 56px 0 #a8d8d8,
    296px 56px 0 #a8d8d8, 304px 56px 0 #a8d8d8, 312px 56px 0 #a8d8d8,
    320px 56px 0 #ddeff0, 64px 64px 0 #ddeff0, 72px 64px 0 #a8d8d8,
    80px 64px 0 #a8d8d8, 88px 64px 0 #a8d8d8, 96px 64px 0 #a8d8d8,
    104px 64px 0 #a8d8d8, 112px 64px 0 #a8d8d8, 120px 64px 0 #a8d8d8,
    128px 64px 0 #a8d8d8, 136px 64px 0 #a8d8d8, 144px 64px 0 #a8d8d8,
    152px 64px 0 #c7e6e7, 160px 64px 0 #c7e6e7, 168px 64px 0 #c7e6e7,
    176px 64px 0 #c7e6e7, 184px 64px 0 #c7e6e7, 192px 64px 0 #c7e6e7,
    200px 64px 0 #a8d8d8, 208px 64px 0 #a8d8d8, 216px 64px 0 #a8d8d8,
    224px 64px 0 #c7e6e7, 232px 64px 0 #c7e6e7, 240px 64px 0 #c7e6e7,
    248px 64px 0 #c7e6e7, 256px 64px 0 #c7e6e7, 264px 64px 0 #c7e6e7,
    272px 64px 0 #a8d8d8, 280px 64px 0 #a8d8d8, 288px 64px 0 #a8d8d8,
    296px 64px 0 #a8d8d8, 304px 64px 0 #a8d8d8, 312px 64px 0 #a8d8d8,
    320px 64px 0 #a8d8d8, 328px 64px 0 #ddeff0, 56px 72px 0 #ddeff0,
    64px 72px 0 #a8d8d8, 72px 72px 0 #a8d8d8, 80px 72px 0 #a8d8d8,
    88px 72px 0 #a8d8d8, 96px 72px 0 #a8d8d8, 104px 72px 0 #a8d8d8,
    112px 72px 0 #a8d8d8, 120px 72px 0 #a8d8d8, 128px 72px 0 #a8d8d8,
    136px 72px 0 #a8d8d8, 144px 72px 0 #c7e6e7, 152px 72px 0 #c7e6e7,
    160px 72px 0 #c7e6e7, 168px 72px 0 #c7e6e7, 176px 72px 0 #c7e6e7,
    184px 72px 0 #c7e6e7, 192px 72px 0 #c7e6e7, 200px 72px 0 #c7e6e7,
    208px 72px 0 #a8d8d8, 216px 72px 0 #c7e6e7, 224px 72px 0 #c7e6e7,
    232px 72px 0 #c7e6e7, 240px 72px 0 #c7e6e7, 248px 72px 0 #c7e6e7,
    256px 72px 0 #c7e6e7, 264px 72px 0 #c7e6e7, 272px 72px 0 #a8d8d8,
    280px 72px 0 #a8d8d8, 288px 72px 0 #a8d8d8, 296px 72px 0 #a8d8d8,
    304px 72px 0 #a8d8d8, 312px 72px 0 #a8d8d8, 320px 72px 0 #a8d8d8,
    328px 72px 0 #a8d8d8, 336px 72px 0 #ddeff0, 48px 80px 0 #ddeff0,
    56px 80px 0 #a8d8d8, 64px 80px 0 #a8d8d8, 72px 80px 0 #a8d8d8,
    80px 80px 0 #c7e6e7, 88px 80px 0 #c7e6e7, 96px 80px 0 #a8d8d8,
    104px 80px 0 #a8d8d8, 112px 80px 0 #a8d8d8, 120px 80px 0 #a8d8d8,
    128px 80px 0 #c7e6e7, 136px 80px 0 #c7e6e7, 144px 80px 0 #c7e6e7,
    152px 80px 0 #c7e6e7, 160px 80px 0 #c7e6e7, 168px 80px 0 #c7e6e7,
    176px 80px 0 #c7e6e7, 184px 80px 0 #c7e6e7, 192px 80px 0 #c7e6e7,
    200px 80px 0 #c7e6e7, 208px 80px 0 #c7e6e7, 216px 80px 0 #c7e6e7,
    224px 80px 0 #c7e6e7, 232px 80px 0 #c7e6e7, 240px 80px 0 #c7e6e7,
    248px 80px 0 #c7e6e7, 256px 80px 0 #c7e6e7, 264px 80px 0 #c7e6e7,
    272px 80px 0 #bce1e2, 280px 80px 0 #bce1e2, 288px 80px 0 #bce1e2,
    296px 80px 0 #bce1e2, 304px 80px 0 #a8d8d8, 312px 80px 0 #a8d8d8,
    320px 80px 0 #a8d8d8, 328px 80px 0 #a8d8d8, 336px 80px 0 #a8d8d8,
    344px 80px 0 #ddeff0, 40px 88px 0 #ddeff0, 48px 88px 0 #a8d8d8,
    56px 88px 0 #a8d8d8, 64px 88px 0 #a8d8d8, 72px 88px 0 #c7e6e7,
    80px 88px 0 #c7e6e7, 88px 88px 0 #c7e6e7, 96px 88px 0 #c7e6e7,
    104px 88px 0 #a8d8d8, 112px 88px 0 #c7e6e7, 120px 88px 0 #c7e6e7,
    128px 88px 0 #c7e6e7, 136px 88px 0 #c7e6e7, 144px 88px 0 #c7e6e7,
    152px 88px 0 #c7e6e7, 160px 88px 0 #c7e6e7, 168px 88px 0 #c7e6e7,
    176px 88px 0 #c7e6e7, 184px 88px 0 #c7e6e7, 192px 88px 0 #c7e6e7,
    200px 88px 0 #c7e6e7, 208px 88px 0 #c7e6e7, 216px 88px 0 #c7e6e7,
    224px 88px 0 #c7e6e7, 232px 88px 0 #c7e6e7, 240px 88px 0 #c7e6e7,
    248px 88px 0 #c7e6e7, 256px 88px 0 #c7e6e7, 264px 88px 0 #c7e6e7,
    272px 88px 0 #c7e6e7, 280px 88px 0 #bce1e2, 288px 88px 0 #bce1e2,
    296px 88px 0 #bce1e2, 304px 88px 0 #bce1e2, 312px 88px 0 #a8d8d8,
    320px 88px 0 #a8d8d8, 328px 88px 0 #a8d8d8, 336px 88px 0 #a8d8d8,
    344px 88px 0 #a8d8d8, 352px 88px 0 #ddeff0, 40px 96px 0 #ddeff0,
    48px 96px 0 #a8d8d8, 56px 96px 0 #a8d8d8, 64px 96px 0 #a8d8d8,
    72px 96px 0 #c7e6e7, 80px 96px 0 #c7e6e7, 88px 96px 0 #c7e6e7,
    96px 96px 0 #c7e6e7, 104px 96px 0 #c7e6e7, 112px 96px 0 #c7e6e7,
    120px 96px 0 #c7e6e7, 128px 96px 0 #c7e6e7, 136px 96px 0 #c7e6e7,
    144px 96px 0 #c7e6e7, 152px 96px 0 #c7e6e7, 160px 96px 0 #c7e6e7,
    168px 96px 0 #c7e6e7, 176px 96px 0 #c7e6e7, 184px 96px 0 #c7e6e7,
    192px 96px 0 #a8d8d8, 200px 96px 0 #c7e6e7, 208px 96px 0 #c7e6e7,
    216px 96px 0 #c7e6e7, 224px 96px 0 #c7e6e7, 232px 96px 0 #c7e6e7,
    240px 96px 0 #c7e6e7, 248px 96px 0 #c7e6e7, 256px 96px 0 #c7e6e7,
    264px 96px 0 #c7e6e7, 272px 96px 0 #a8d8d8, 280px 96px 0 #bce1e2,
    288px 96px 0 #a8d8d8, 296px 96px 0 #bce1e2, 304px 96px 0 #bce1e2,
    312px 96px 0 #a8d8d8, 320px 96px 0 #a8d8d8, 328px 96px 0 #a8d8d8,
    336px 96px 0 #a8d8d8, 344px 96px 0 #a8d8d8, 352px 96px 0 #ddeff0,
    32px 104px 0 #ddeff0, 40px 104px 0 #a8d8d8, 48px 104px 0 #a8d8d8,
    56px 104px 0 #a8d8d8, 64px 104px 0 #c7e6e7, 72px 104px 0 #c7e6e7,
    80px 104px 0 #c7e6e7, 88px 104px 0 #c7e6e7, 96px 104px 0 #c7e6e7,
    104px 104px 0 #c7e6e7, 112px 104px 0 #c7e6e7, 120px 104px 0 #c7e6e7,
    128px 104px 0 #c7e6e7, 136px 104px 0 #c7e6e7, 144px 104px 0 #c7e6e7,
    152px 104px 0 #c7e6e7, 160px 104px 0 #c7e6e7, 168px 104px 0 #c7e6e7,
    176px 104px 0 #a8d8d8, 184px 104px 0 #a8d8d8, 192px 104px 0 #a8d8d8,
    200px 104px 0 #a8d8d8, 208px 104px 0 #a8d8d8, 216px 104px 0 #c7e6e7,
    224px 104px 0 #c7e6e7, 232px 104px 0 #c7e6e7, 240px 104px 0 #c7e6e7,
    248px 104px 0 #c7e6e7, 256px 104px 0 #c7e6e7, 264px 104px 0 #c7e6e7,
    272px 104px 0 #c7e6e7, 280px 104px 0 #c7e6e7, 288px 104px 0 #c7e6e7,
    296px 104px 0 #c7e6e7, 304px 104px 0 #a8d8d8, 312px 104px 0 #a8d8d8,
    320px 104px 0 #a8d8d8, 328px 104px 0 #a8d8d8, 336px 104px 0 #a8d8d8,
    344px 104px 0 #a8d8d8, 352px 104px 0 #a8d8d8, 360px 104px 0 #ddeff0,
    32px 112px 0 #ddeff0, 40px 112px 0 #a8d8d8, 48px 112px 0 #a8d8d8,
    56px 112px 0 #a8d8d8, 64px 112px 0 #c7e6e7, 72px 112px 0 #c7e6e7,
    80px 112px 0 #c7e6e7, 88px 112px 0 #c7e6e7, 96px 112px 0 #c7e6e7,
    104px 112px 0 #c7e6e7, 112px 112px 0 #c7e6e7, 120px 112px 0 #bce1e2,
    128px 112px 0 #bce1e2, 136px 112px 0 #c7e6e7, 144px 112px 0 #c7e6e7,
    152px 112px 0 #c7e6e7, 160px 112px 0 #c7e6e7, 168px 112px 0 #a8d8d8,
    176px 112px 0 #a8d8d8, 184px 112px 0 #a8d8d8, 192px 112px 0 #a8d8d8,
    200px 112px 0 #a8d8d8, 208px 112px 0 #a8d8d8, 216px 112px 0 #a8d8d8,
    224px 112px 0 #bce1e2, 232px 112px 0 #bce1e2, 240px 112px 0 #bce1e2,
    248px 112px 0 #bce1e2, 256px 112px 0 #bce1e2, 264px 112px 0 #bce1e2,
    272px 112px 0 #c7e6e7, 280px 112px 0 #c7e6e7, 288px 112px 0 #c7e6e7,
    296px 112px 0 #c7e6e7, 304px 112px 0 #c7e6e7, 312px 112px 0 #c7e6e7,
    320px 112px 0 #a8d8d8, 328px 112px 0 #a8d8d8, 336px 112px 0 #a8d8d8,
    344px 112px 0 #a8d8d8, 352px 112px 0 #a8d8d8, 360px 112px 0 #ddeff0,
    24px 120px 0 #ddeff0, 32px 120px 0 #a8d8d8, 40px 120px 0 #a8d8d8,
    48px 120px 0 #a8d8d8, 56px 120px 0 #a8d8d8, 64px 120px 0 #c7e6e7,
    72px 120px 0 #c7e6e7, 80px 120px 0 #c7e6e7, 88px 120px 0 #c7e6e7,
    96px 120px 0 #c7e6e7, 104px 120px 0 #c7e6e7, 112px 120px 0 #bce1e2,
    120px 120px 0 #bce1e2, 128px 120px 0 #bce1e2, 136px 120px 0 #bce1e2,
    144px 120px 0 #c7e6e7, 152px 120px 0 #a8d8d8, 160px 120px 0 #a8d8d8,
    168px 120px 0 #a8d8d8, 176px 120px 0 #a8d8d8, 184px 120px 0 #a8d8d8,
    192px 120px 0 #a8d8d8, 200px 120px 0 #a8d8d8, 208px 120px 0 #a8d8d8,
    216px 120px 0 #bce1e2, 224px 120px 0 #bce1e2, 232px 120px 0 #bce1e2,
    240px 120px 0 #bce1e2, 248px 120px 0 #bce1e2, 256px 120px 0 #bce1e2,
    264px 120px 0 #bce1e2, 272px 120px 0 #bce1e2, 280px 120px 0 #c7e6e7,
    288px 120px 0 #c7e6e7, 296px 120px 0 #c7e6e7, 304px 120px 0 #c7e6e7,
    312px 120px 0 #c7e6e7, 320px 120px 0 #c7e6e7, 328px 120px 0 #c7e6e7,
    336px 120px 0 #a8d8d8, 344px 120px 0 #a8d8d8, 352px 120px 0 #a8d8d8,
    360px 120px 0 #a8d8d8, 368px 120px 0 #ddeff0, 24px 128px 0 #ddeff0,
    32px 128px 0 #a8d8d8, 40px 128px 0 #a8d8d8, 48px 128px 0 #a8d8d8,
    56px 128px 0 #a8d8d8, 64px 128px 0 #bce1e2, 72px 128px 0 #bce1e2,
    80px 128px 0 #bce1e2, 88px 128px 0 #c7e6e7, 96px 128px 0 #c7e6e7,
    104px 128px 0 #c7e6e7, 112px 128px 0 #bce1e2, 120px 128px 0 #bce1e2,
    128px 128px 0 #bce1e2, 136px 128px 0 #bce1e2, 144px 128px 0 #a8d8d8,
    152px 128px 0 #a8d8d8, 160px 128px 0 #a8d8d8, 168px 128px 0 #a8d8d8,
    176px 128px 0 #a8d8d8, 184px 128px 0 #a8d8d8, 192px 128px 0 #a8d8d8,
    200px 128px 0 #a8d8d8, 208px 128px 0 #a8d8d8, 216px 128px 0 #bce1e2,
    224px 128px 0 #bce1e2, 232px 128px 0 #bce1e2, 240px 128px 0 #bce1e2,
    248px 128px 0 #bce1e2, 256px 128px 0 #bce1e2, 264px 128px 0 #bce1e2,
    272px 128px 0 #bce1e2, 280px 128px 0 #c7e6e7, 288px 128px 0 #c7e6e7,
    296px 128px 0 #c7e6e7, 304px 128px 0 #c7e6e7, 312px 128px 0 #c7e6e7,
    320px 128px 0 #c7e6e7, 328px 128px 0 #c7e6e7, 336px 128px 0 #c7e6e7,
    344px 128px 0 #c7e6e7, 352px 128px 0 #c7e6e7, 360px 128px 0 #a8d8d8,
    368px 128px 0 #ddeff0, 16px 136px 0 #ddeff0, 24px 136px 0 #a8d8d8,
    32px 136px 0 #a8d8d8, 40px 136px 0 #a8d8d8, 48px 136px 0 #bce1e2,
    56px 136px 0 #bce1e2, 64px 136px 0 #bce1e2, 72px 136px 0 #bce1e2,
    80px 136px 0 #bce1e2, 88px 136px 0 #bce1e2, 96px 136px 0 #c7e6e7,
    104px 136px 0 #c7e6e7, 112px 136px 0 #bce1e2, 120px 136px 0 #bce1e2,
    128px 136px 0 #bce1e2, 136px 136px 0 #bce1e2, 144px 136px 0 #a8d8d8,
    152px 136px 0 #a8d8d8, 160px 136px 0 #a8d8d8, 168px 136px 0 #a8d8d8,
    176px 136px 0 #a8d8d8, 184px 136px 0 #a8d8d8, 192px 136px 0 #a8d8d8,
    200px 136px 0 #a8d8d8, 208px 136px 0 #a8d8d8, 216px 136px 0 #a8d8d8,
    224px 136px 0 #bce1e2, 232px 136px 0 #bce1e2, 240px 136px 0 #bce1e2,
    248px 136px 0 #bce1e2, 256px 136px 0 #bce1e2, 264px 136px 0 #bce1e2,
    272px 136px 0 #c7e6e7, 280px 136px 0 #c7e6e7, 288px 136px 0 #c7e6e7,
    296px 136px 0 #c7e6e7, 304px 136px 0 #c7e6e7, 312px 136px 0 #c7e6e7,
    320px 136px 0 #c7e6e7, 328px 136px 0 #c7e6e7, 336px 136px 0 #c7e6e7,
    344px 136px 0 #c7e6e7, 352px 136px 0 #c7e6e7, 360px 136px 0 #c7e6e7,
    368px 136px 0 #a8d8d8, 376px 136px 0 #ddeff0, 16px 144px 0 #ddeff0,
    24px 144px 0 #a8d8d8, 32px 144px 0 #a8d8d8, 40px 144px 0 #a8d8d8,
    48px 144px 0 #bce1e2, 56px 144px 0 #bce1e2, 64px 144px 0 #bce1e2,
    72px 144px 0 #bce1e2, 80px 144px 0 #bce1e2, 88px 144px 0 #bce1e2,
    96px 144px 0 #c7e6e7, 104px 144px 0 #c7e6e7, 112px 144px 0 #bce1e2,
    120px 144px 0 #bce1e2, 128px 144px 0 #bce1e2, 136px 144px 0 #bce1e2,
    144px 144px 0 #a8d8d8, 152px 144px 0 #a8d8d8, 160px 144px 0 #a8d8d8,
    168px 144px 0 #a8d8d8, 176px 144px 0 #a8d8d8, 184px 144px 0 #a8d8d8,
    192px 144px 0 #a8d8d8, 200px 144px 0 #a8d8d8, 208px 144px 0 #a8d8d8,
    216px 144px 0 #a8d8d8, 224px 144px 0 #a8d8d8, 232px 144px 0 #a8d8d8,
    240px 144px 0 #bce1e2, 248px 144px 0 #bce1e2, 256px 144px 0 #bce1e2,
    264px 144px 0 #bce1e2, 272px 144px 0 #bce1e2, 280px 144px 0 #c7e6e7,
    288px 144px 0 #c7e6e7, 296px 144px 0 #c7e6e7, 304px 144px 0 #c7e6e7,
    312px 144px 0 #c7e6e7, 320px 144px 0 #c7e6e7, 328px 144px 0 #c7e6e7,
    336px 144px 0 #c7e6e7, 344px 144px 0 #c7e6e7, 352px 144px 0 #c7e6e7,
    360px 144px 0 #c7e6e7, 368px 144px 0 #a8d8d8, 376px 144px 0 #ddeff0,
    8px 152px 0 #ddeff0, 16px 152px 0 #a8d8d8, 24px 152px 0 #a8d8d8,
    32px 152px 0 #a8d8d8, 40px 152px 0 #bce1e2, 48px 152px 0 #bce1e2,
    56px 152px 0 #bce1e2, 64px 152px 0 #bce1e2, 72px 152px 0 #bce1e2,
    80px 152px 0 #bce1e2, 88px 152px 0 #bce1e2, 96px 152px 0 #bce1e2,
    104px 152px 0 #a8d8d8, 112px 152px 0 #bce1e2, 120px 152px 0 #bce1e2,
    128px 152px 0 #bce1e2, 136px 152px 0 #bce1e2, 144px 152px 0 #a8d8d8,
    152px 152px 0 #a8d8d8, 160px 152px 0 #a8d8d8, 168px 152px 0 #a8d8d8,
    176px 152px 0 #a8d8d8, 184px 152px 0 #a8d8d8, 192px 152px 0 #a8d8d8,
    200px 152px 0 #a8d8d8, 208px 152px 0 #a8d8d8, 216px 152px 0 #a8d8d8,
    224px 152px 0 #a8d8d8, 232px 152px 0 #a8d8d8, 240px 152px 0 #bce1e2,
    248px 152px 0 #bce1e2, 256px 152px 0 #bce1e2, 264px 152px 0 #bce1e2,
    272px 152px 0 #bce1e2, 280px 152px 0 #c7e6e7, 288px 152px 0 #c7e6e7,
    296px 152px 0 #c7e6e7, 304px 152px 0 #c7e6e7, 312px 152px 0 #c7e6e7,
    320px 152px 0 #c7e6e7, 328px 152px 0 #c7e6e7, 336px 152px 0 #c7e6e7,
    344px 152px 0 #c7e6e7, 352px 152px 0 #c7e6e7, 360px 152px 0 #c7e6e7,
    368px 152px 0 #c7e6e7, 376px 152px 0 #a8d8d8, 384px 152px 0 #ddeff0,
    8px 160px 0 #ddeff0, 16px 160px 0 #a8d8d8, 24px 160px 0 #a8d8d8,
    32px 160px 0 #bce1e2, 40px 160px 0 #bce1e2, 48px 160px 0 #bce1e2,
    56px 160px 0 #bce1e2, 64px 160px 0 #bce1e2, 72px 160px 0 #bce1e2,
    80px 160px 0 #bce1e2, 88px 160px 0 #bce1e2, 96px 160px 0 #c7e6e7,
    104px 160px 0 #a8d8d8, 112px 160px 0 #c7e6e7, 120px 160px 0 #bce1e2,
    128px 160px 0 #bce1e2, 136px 160px 0 #a8d8d8, 144px 160px 0 #a8d8d8,
    152px 160px 0 #a8d8d8, 160px 160px 0 #a8d8d8, 168px 160px 0 #a8d8d8,
    176px 160px 0 #a8d8d8, 184px 160px 0 #a8d8d8, 192px 160px 0 #a8d8d8,
    200px 160px 0 #a8d8d8, 208px 160px 0 #a8d8d8, 216px 160px 0 #a8d8d8,
    224px 160px 0 #a8d8d8, 232px 160px 0 #bce1e2, 240px 160px 0 #bce1e2,
    248px 160px 0 #bce1e2, 256px 160px 0 #bce1e2, 264px 160px 0 #bce1e2,
    272px 160px 0 #bce1e2, 280px 160px 0 #bce1e2, 288px 160px 0 #c7e6e7,
    296px 160px 0 #c7e6e7, 304px 160px 0 #c7e6e7, 312px 160px 0 #c7e6e7,
    320px 160px 0 #c7e6e7, 328px 160px 0 #c7e6e7, 336px 160px 0 #c7e6e7,
    344px 160px 0 #c7e6e7, 352px 160px 0 #c7e6e7, 360px 160px 0 #c7e6e7,
    368px 160px 0 #c7e6e7, 376px 160px 0 #a8d8d8, 384px 160px 0 #ddeff0,
    8px 168px 0 #ddeff0, 16px 168px 0 #a8d8d8, 24px 168px 0 #bce1e2,
    32px 168px 0 #bce1e2, 40px 168px 0 #bce1e2, 48px 168px 0 #bce1e2,
    56px 168px 0 #bce1e2, 64px 168px 0 #bce1e2, 72px 168px 0 #bce1e2,
    80px 168px 0 #bce1e2, 88px 168px 0 #c7e6e7, 96px 168px 0 #c7e6e7,
    104px 168px 0 #c7e6e7, 112px 168px 0 #c7e6e7, 120px 168px 0 #c7e6e7,
    128px 168px 0 #c7e6e7, 136px 168px 0 #a8d8d8, 144px 168px 0 #a8d8d8,
    152px 168px 0 #a8d8d8, 160px 168px 0 #a8d8d8, 168px 168px 0 #a8d8d8,
    176px 168px 0 #a8d8d8, 184px 168px 0 #a8d8d8, 192px 168px 0 #a8d8d8,
    200px 168px 0 #a8d8d8, 208px 168px 0 #a8d8d8, 216px 168px 0 #a8d8d8,
    224px 168px 0 #a8d8d8, 232px 168px 0 #bce1e2, 240px 168px 0 #bce1e2,
    248px 168px 0 #bce1e2, 256px 168px 0 #bce1e2, 264px 168px 0 #bce1e2,
    272px 168px 0 #bce1e2, 280px 168px 0 #bce1e2, 288px 168px 0 #a8d8d8,
    296px 168px 0 #a8d8d8, 304px 168px 0 #a8d8d8, 312px 168px 0 #a8d8d8,
    320px 168px 0 #a8d8d8, 328px 168px 0 #c7e6e7, 336px 168px 0 #c7e6e7,
    344px 168px 0 #c7e6e7, 352px 168px 0 #c7e6e7, 360px 168px 0 #c7e6e7,
    368px 168px 0 #c7e6e7, 376px 168px 0 #a8d8d8, 384px 168px 0 #ddeff0,
    8px 176px 0 #ddeff0, 16px 176px 0 #a8d8d8, 24px 176px 0 #bce1e2,
    32px 176px 0 #bce1e2, 40px 176px 0 #bce1e2, 48px 176px 0 #bce1e2,
    56px 176px 0 #bce1e2, 64px 176px 0 #a8d8d8, 72px 176px 0 #a8d8d8,
    80px 176px 0 #a8d8d8, 88px 176px 0 #a8d8d8, 96px 176px 0 #c7e6e7,
    104px 176px 0 #c7e6e7, 112px 176px 0 #c7e6e7, 120px 176px 0 #c7e6e7,
    128px 176px 0 #c7e6e7, 136px 176px 0 #a8d8d8, 144px 176px 0 #a8d8d8,
    152px 176px 0 #a8d8d8, 160px 176px 0 #a8d8d8, 168px 176px 0 #a8d8d8,
    176px 176px 0 #a8d8d8, 184px 176px 0 #a8d8d8, 192px 176px 0 #a8d8d8,
    200px 176px 0 #a8d8d8, 208px 176px 0 #a8d8d8, 216px 176px 0 #a8d8d8,
    224px 176px 0 #a8d8d8, 232px 176px 0 #a8d8d8, 240px 176px 0 #bce1e2,
    248px 176px 0 #bce1e2, 256px 176px 0 #bce1e2, 264px 176px 0 #bce1e2,
    272px 176px 0 #bce1e2, 280px 176px 0 #a8d8d8, 288px 176px 0 #a8d8d8,
    296px 176px 0 #a8d8d8, 304px 176px 0 #a8d8d8, 312px 176px 0 #a8d8d8,
    320px 176px 0 #a8d8d8, 328px 176px 0 #a8d8d8, 336px 176px 0 #c7e6e7,
    344px 176px 0 #c7e6e7, 352px 176px 0 #c7e6e7, 360px 176px 0 #c7e6e7,
    368px 176px 0 #a8d8d8, 376px 176px 0 #a8d8d8, 384px 176px 0 #ddeff0,
    8px 184px 0 #ddeff0, 16px 184px 0 #a8d8d8, 24px 184px 0 #bce1e2,
    32px 184px 0 #bce1e2, 40px 184px 0 #bce1e2, 48px 184px 0 #bce1e2,
    56px 184px 0 #bce1e2, 64px 184px 0 #a8d8d8, 72px 184px 0 #a8d8d8,
    80px 184px 0 #a8d8d8, 88px 184px 0 #a8d8d8, 96px 184px 0 #c7e6e7,
    104px 184px 0 #c7e6e7, 112px 184px 0 #c7e6e7, 120px 184px 0 #c7e6e7,
    128px 184px 0 #c7e6e7, 136px 184px 0 #a8d8d8, 144px 184px 0 #a8d8d8,
    152px 184px 0 #a8d8d8, 160px 184px 0 #a8d8d8, 168px 184px 0 #a8d8d8,
    176px 184px 0 #a8d8d8, 184px 184px 0 #a8d8d8, 192px 184px 0 #a8d8d8,
    200px 184px 0 #a8d8d8, 208px 184px 0 #a8d8d8, 216px 184px 0 #a8d8d8,
    224px 184px 0 #a8d8d8, 232px 184px 0 #a8d8d8, 240px 184px 0 #bce1e2,
    248px 184px 0 #bce1e2, 256px 184px 0 #bce1e2, 264px 184px 0 #bce1e2,
    272px 184px 0 #bce1e2, 280px 184px 0 #a8d8d8, 288px 184px 0 #a8d8d8,
    296px 184px 0 #a8d8d8, 304px 184px 0 #a8d8d8, 312px 184px 0 #a8d8d8,
    320px 184px 0 #a8d8d8, 328px 184px 0 #a8d8d8, 336px 184px 0 #a8d8d8,
    344px 184px 0 #c7e6e7, 352px 184px 0 #c7e6e7, 360px 184px 0 #a8d8d8,
    368px 184px 0 #a8d8d8, 376px 184px 0 #a8d8d8, 384px 184px 0 #ddeff0,
    8px 192px 0 #ddeff0, 16px 192px 0 #a8d8d8, 24px 192px 0 #a8d8d8,
    32px 192px 0 #bce1e2, 40px 192px 0 #bce1e2, 48px 192px 0 #bce1e2,
    56px 192px 0 #a8d8d8, 64px 192px 0 #a8d8d8, 72px 192px 0 #a8d8d8,
    80px 192px 0 #a8d8d8, 88px 192px 0 #a8d8d8, 96px 192px 0 #c7e6e7,
    104px 192px 0 #c7e6e7, 112px 192px 0 #c7e6e7, 120px 192px 0 #c7e6e7,
    128px 192px 0 #c7e6e7, 136px 192px 0 #a8d8d8, 144px 192px 0 #a8d8d8,
    152px 192px 0 #a8d8d8, 160px 192px 0 #a8d8d8, 168px 192px 0 #a8d8d8,
    176px 192px 0 #a8d8d8, 184px 192px 0 #a8d8d8, 192px 192px 0 #a8d8d8,
    200px 192px 0 #a8d8d8, 208px 192px 0 #a8d8d8, 216px 192px 0 #a8d8d8,
    224px 192px 0 #a8d8d8, 232px 192px 0 #a8d8d8, 240px 192px 0 #a8d8d8,
    248px 192px 0 #a8d8d8, 256px 192px 0 #bce1e2, 264px 192px 0 #a8d8d8,
    272px 192px 0 #a8d8d8, 280px 192px 0 #a8d8d8, 288px 192px 0 #a8d8d8,
    296px 192px 0 #a8d8d8, 304px 192px 0 #a8d8d8, 312px 192px 0 #a8d8d8,
    320px 192px 0 #a8d8d8, 328px 192px 0 #a8d8d8, 336px 192px 0 #a8d8d8,
    344px 192px 0 #a8d8d8, 352px 192px 0 #a8d8d8, 360px 192px 0 #a8d8d8,
    368px 192px 0 #a8d8d8, 376px 192px 0 #c7e6e7, 384px 192px 0 #ddeff0,
    8px 200px 0 #ddeff0, 16px 200px 0 #a8d8d8, 24px 200px 0 #bce1e2,
    32px 200px 0 #bce1e2, 40px 200px 0 #bce1e2, 48px 200px 0 #a8d8d8,
    56px 200px 0 #a8d8d8, 64px 200px 0 #a8d8d8, 72px 200px 0 #a8d8d8,
    80px 200px 0 #a8d8d8, 88px 200px 0 #a8d8d8, 96px 200px 0 #a8d8d8,
    104px 200px 0 #a8d8d8, 112px 200px 0 #c7e6e7, 120px 200px 0 #a8d8d8,
    128px 200px 0 #a8d8d8, 136px 200px 0 #a8d8d8, 144px 200px 0 #a8d8d8,
    152px 200px 0 #a8d8d8, 160px 200px 0 #a8d8d8, 168px 200px 0 #a8d8d8,
    176px 200px 0 #a8d8d8, 184px 200px 0 #a8d8d8, 192px 200px 0 #a8d8d8,
    200px 200px 0 #a8d8d8, 208px 200px 0 #a8d8d8, 216px 200px 0 #a8d8d8,
    224px 200px 0 #a8d8d8, 232px 200px 0 #a8d8d8, 240px 200px 0 #a8d8d8,
    248px 200px 0 #a8d8d8, 256px 200px 0 #a8d8d8, 264px 200px 0 #a8d8d8,
    272px 200px 0 #a8d8d8, 280px 200px 0 #a8d8d8, 288px 200px 0 #a8d8d8,
    296px 200px 0 #a8d8d8, 304px 200px 0 #a8d8d8, 312px 200px 0 #a8d8d8,
    320px 200px 0 #a8d8d8, 328px 200px 0 #a8d8d8, 336px 200px 0 #a8d8d8,
    344px 200px 0 #a8d8d8, 352px 200px 0 #a8d8d8, 360px 200px 0 #a8d8d8,
    368px 200px 0 #c7e6e7, 376px 200px 0 #a8d8d8, 384px 200px 0 #ddeff0,
    8px 208px 0 #ddeff0, 16px 208px 0 #bce1e2, 24px 208px 0 #bce1e2,
    32px 208px 0 #bce1e2, 40px 208px 0 #a8d8d8, 48px 208px 0 #a8d8d8,
    56px 208px 0 #a8d8d8, 64px 208px 0 #a8d8d8, 72px 208px 0 #a8d8d8,
    80px 208px 0 #a8d8d8, 88px 208px 0 #a8d8d8, 96px 208px 0 #a8d8d8,
    104px 208px 0 #a8d8d8, 112px 208px 0 #a8d8d8, 120px 208px 0 #a8d8d8,
    128px 208px 0 #a8d8d8, 136px 208px 0 #a8d8d8, 144px 208px 0 #a8d8d8,
    152px 208px 0 #a8d8d8, 160px 208px 0 #a8d8d8, 168px 208px 0 #a8d8d8,
    176px 208px 0 #a8d8d8, 184px 208px 0 #a8d8d8, 192px 208px 0 #a8d8d8,
    200px 208px 0 #a8d8d8, 208px 208px 0 #a8d8d8, 216px 208px 0 #a8d8d8,
    224px 208px 0 #a8d8d8, 232px 208px 0 #a8d8d8, 240px 208px 0 #a8d8d8,
    248px 208px 0 #a8d8d8, 256px 208px 0 #a8d8d8, 264px 208px 0 #a8d8d8,
    272px 208px 0 #a8d8d8, 280px 208px 0 #a8d8d8, 288px 208px 0 #a8d8d8,
    296px 208px 0 #a8d8d8, 304px 208px 0 #a8d8d8, 312px 208px 0 #a8d8d8,
    320px 208px 0 #a8d8d8, 328px 208px 0 #a8d8d8, 336px 208px 0 #a8d8d8,
    344px 208px 0 #a8d8d8, 352px 208px 0 #a8d8d8, 360px 208px 0 #c7e6e7,
    368px 208px 0 #a8d8d8, 376px 208px 0 #c7e6e7, 384px 208px 0 #ddeff0,
    8px 216px 0 #ddeff0, 16px 216px 0 #bce1e2, 24px 216px 0 #bce1e2,
    32px 216px 0 #bce1e2, 40px 216px 0 #a8d8d8, 48px 216px 0 #a8d8d8,
    56px 216px 0 #a8d8d8, 64px 216px 0 #a8d8d8, 72px 216px 0 #a8d8d8,
    80px 216px 0 #a8d8d8, 88px 216px 0 #a8d8d8, 96px 216px 0 #a8d8d8,
    104px 216px 0 #a8d8d8, 112px 216px 0 #a8d8d8, 120px 216px 0 #a8d8d8,
    128px 216px 0 #a8d8d8, 136px 216px 0 #a8d8d8, 144px 216px 0 #a8d8d8,
    152px 216px 0 #a8d8d8, 160px 216px 0 #a8d8d8, 168px 216px 0 #a8d8d8,
    176px 216px 0 #a8d8d8, 184px 216px 0 #a8d8d8, 192px 216px 0 #a8d8d8,
    200px 216px 0 #a8d8d8, 208px 216px 0 #a8d8d8, 216px 216px 0 #a8d8d8,
    224px 216px 0 #a8d8d8, 232px 216px 0 #a8d8d8, 240px 216px 0 #a8d8d8,
    248px 216px 0 #a8d8d8, 256px 216px 0 #a8d8d8, 264px 216px 0 #a8d8d8,
    272px 216px 0 #a8d8d8, 280px 216px 0 #a8d8d8, 288px 216px 0 #a8d8d8,
    296px 216px 0 #a8d8d8, 304px 216px 0 #a8d8d8, 312px 216px 0 #a8d8d8,
    320px 216px 0 #a8d8d8, 328px 216px 0 #a8d8d8, 336px 216px 0 #a8d8d8,
    344px 216px 0 #a8d8d8, 352px 216px 0 #c7e6e7, 360px 216px 0 #a8d8d8,
    368px 216px 0 #c7e6e7, 376px 216px 0 #a8d8d8, 384px 216px 0 #ddeff0,
    8px 224px 0 #ddeff0, 16px 224px 0 #bce1e2, 24px 224px 0 #bce1e2,
    32px 224px 0 #bce1e2, 40px 224px 0 #a8d8d8, 48px 224px 0 #a8d8d8,
    56px 224px 0 #a8d8d8, 64px 224px 0 #a8d8d8, 72px 224px 0 #a8d8d8,
    80px 224px 0 #a8d8d8, 88px 224px 0 #a8d8d8, 96px 224px 0 #a8d8d8,
    104px 224px 0 #a8d8d8, 112px 224px 0 #a8d8d8, 120px 224px 0 #a8d8d8,
    128px 224px 0 #a8d8d8, 136px 224px 0 #a8d8d8, 144px 224px 0 #a8d8d8,
    152px 224px 0 #a8d8d8, 160px 224px 0 #a8d8d8, 168px 224px 0 #a8d8d8,
    176px 224px 0 #a8d8d8, 184px 224px 0 #a8d8d8, 192px 224px 0 #a8d8d8,
    200px 224px 0 #a8d8d8, 208px 224px 0 #a8d8d8, 216px 224px 0 #a8d8d8,
    224px 224px 0 #a8d8d8, 232px 224px 0 #a8d8d8, 240px 224px 0 #a8d8d8,
    248px 224px 0 #a8d8d8, 256px 224px 0 #a8d8d8, 264px 224px 0 #a8d8d8,
    272px 224px 0 #a8d8d8, 280px 224px 0 #a8d8d8, 288px 224px 0 #a8d8d8,
    296px 224px 0 #a8d8d8, 304px 224px 0 #a8d8d8, 312px 224px 0 #a8d8d8,
    320px 224px 0 #a8d8d8, 328px 224px 0 #a8d8d8, 336px 224px 0 #a8d8d8,
    344px 224px 0 #c7e6e7, 352px 224px 0 #a8d8d8, 360px 224px 0 #c7e6e7,
    368px 224px 0 #a8d8d8, 376px 224px 0 #c7e6e7, 384px 224px 0 #ddeff0,
    8px 232px 0 #ddeff0, 16px 232px 0 #bce1e2, 24px 232px 0 #a8d8d8,
    32px 232px 0 #bce1e2, 40px 232px 0 #a8d8d8, 48px 232px 0 #a8d8d8,
    56px 232px 0 #a8d8d8, 64px 232px 0 #a8d8d8, 72px 232px 0 #a8d8d8,
    80px 232px 0 #a8d8d8, 88px 232px 0 #a8d8d8, 96px 232px 0 #a8d8d8,
    104px 232px 0 #a8d8d8, 112px 232px 0 #a8d8d8, 120px 232px 0 #a8d8d8,
    128px 232px 0 #a8d8d8, 136px 232px 0 #a8d8d8, 144px 232px 0 #a8d8d8,
    152px 232px 0 #a8d8d8, 160px 232px 0 #a8d8d8, 168px 232px 0 #a8d8d8,
    176px 232px 0 #a8d8d8, 184px 232px 0 #a8d8d8, 192px 232px 0 #a8d8d8,
    200px 232px 0 #a8d8d8, 208px 232px 0 #a8d8d8, 216px 232px 0 #a8d8d8,
    224px 232px 0 #a8d8d8, 232px 232px 0 #a8d8d8, 240px 232px 0 #a8d8d8,
    248px 232px 0 #a8d8d8, 256px 232px 0 #a8d8d8, 264px 232px 0 #a8d8d8,
    272px 232px 0 #a8d8d8, 280px 232px 0 #a8d8d8, 288px 232px 0 #a8d8d8,
    296px 232px 0 #a8d8d8, 304px 232px 0 #a8d8d8, 312px 232px 0 #a8d8d8,
    320px 232px 0 #a8d8d8, 328px 232px 0 #a8d8d8, 336px 232px 0 #c7e6e7,
    344px 232px 0 #a8d8d8, 352px 232px 0 #c7e6e7, 360px 232px 0 #a8d8d8,
    368px 232px 0 #c7e6e7, 376px 232px 0 #a8d8d8, 384px 232px 0 #ddeff0,
    8px 240px 0 #ddeff0, 16px 240px 0 #bce1e2, 24px 240px 0 #bce1e2,
    32px 240px 0 #bce1e2, 40px 240px 0 #a8d8d8, 48px 240px 0 #a8d8d8,
    56px 240px 0 #a8d8d8, 64px 240px 0 #c7e6e7, 72px 240px 0 #a8d8d8,
    80px 240px 0 #a8d8d8, 88px 240px 0 #a8d8d8, 96px 240px 0 #a8d8d8,
    104px 240px 0 #a8d8d8, 112px 240px 0 #a8d8d8, 120px 240px 0 #a8d8d8,
    128px 240px 0 #a8d8d8, 136px 240px 0 #a8d8d8, 144px 240px 0 #a8d8d8,
    152px 240px 0 #a8d8d8, 160px 240px 0 #a8d8d8, 168px 240px 0 #a8d8d8,
    176px 240px 0 #a8d8d8, 184px 240px 0 #a8d8d8, 192px 240px 0 #a8d8d8,
    200px 240px 0 #a8d8d8, 208px 240px 0 #a8d8d8, 216px 240px 0 #a8d8d8,
    224px 240px 0 #a8d8d8, 232px 240px 0 #a8d8d8, 240px 240px 0 #a8d8d8,
    248px 240px 0 #a8d8d8, 256px 240px 0 #a8d8d8, 264px 240px 0 #a8d8d8,
    272px 240px 0 #a8d8d8, 280px 240px 0 #c7e6e7, 288px 240px 0 #c7e6e7,
    296px 240px 0 #c7e6e7, 304px 240px 0 #a8d8d8, 312px 240px 0 #a8d8d8,
    320px 240px 0 #a8d8d8, 328px 240px 0 #c7e6e7, 336px 240px 0 #a8d8d8,
    344px 240px 0 #c7e6e7, 352px 240px 0 #a8d8d8, 360px 240px 0 #c7e6e7,
    368px 240px 0 #a8d8d8, 376px 240px 0 #c7e6e7, 384px 240px 0 #ddeff0,
    16px 248px 0 #ddeff0, 24px 248px 0 #bce1e2, 32px 248px 0 #bce1e2,
    40px 248px 0 #a8d8d8, 48px 248px 0 #a8d8d8, 56px 248px 0 #a8d8d8,
    64px 248px 0 #c7e6e7, 72px 248px 0 #c7e6e7, 80px 248px 0 #a8d8d8,
    88px 248px 0 #a8d8d8, 96px 248px 0 #a8d8d8, 104px 248px 0 #a8d8d8,
    112px 248px 0 #a8d8d8, 120px 248px 0 #a8d8d8, 128px 248px 0 #a8d8d8,
    136px 248px 0 #a8d8d8, 144px 248px 0 #a8d8d8, 152px 248px 0 #a8d8d8,
    160px 248px 0 #a8d8d8, 168px 248px 0 #a8d8d8, 176px 248px 0 #a8d8d8,
    184px 248px 0 #a8d8d8, 192px 248px 0 #a8d8d8, 200px 248px 0 #a8d8d8,
    208px 248px 0 #a8d8d8, 216px 248px 0 #a8d8d8, 224px 248px 0 #a8d8d8,
    232px 248px 0 #a8d8d8, 240px 248px 0 #a8d8d8, 248px 248px 0 #a8d8d8,
    256px 248px 0 #a8d8d8, 264px 248px 0 #c7e6e7, 272px 248px 0 #c7e6e7,
    280px 248px 0 #c7e6e7, 288px 248px 0 #c7e6e7, 296px 248px 0 #a8d8d8,
    304px 248px 0 #c7e6e7, 312px 248px 0 #a8d8d8, 320px 248px 0 #c7e6e7,
    328px 248px 0 #a8d8d8, 336px 248px 0 #c7e6e7, 344px 248px 0 #a8d8d8,
    352px 248px 0 #c7e6e7, 360px 248px 0 #a8d8d8, 368px 248px 0 #c7e6e7,
    376px 248px 0 #ddeff0, 16px 256px 0 #ddeff0, 24px 256px 0 #a8d8d8,
    32px 256px 0 #bce1e2, 40px 256px 0 #bce1e2, 48px 256px 0 #a8d8d8,
    56px 256px 0 #c7e6e7, 64px 256px 0 #c7e6e7, 72px 256px 0 #c7e6e7,
    80px 256px 0 #c7e6e7, 88px 256px 0 #c7e6e7, 96px 256px 0 #c7e6e7,
    104px 256px 0 #c7e6e7, 112px 256px 0 #c7e6e7, 120px 256px 0 #c7e6e7,
    128px 256px 0 #c7e6e7, 136px 256px 0 #c7e6e7, 144px 256px 0 #a8d8d8,
    152px 256px 0 #a8d8d8, 160px 256px 0 #a8d8d8, 168px 256px 0 #a8d8d8,
    176px 256px 0 #a8d8d8, 184px 256px 0 #a8d8d8, 192px 256px 0 #a8d8d8,
    200px 256px 0 #a8d8d8, 208px 256px 0 #a8d8d8, 216px 256px 0 #a8d8d8,
    224px 256px 0 #a8d8d8, 232px 256px 0 #a8d8d8, 240px 256px 0 #c7e6e7,
    248px 256px 0 #c7e6e7, 256px 256px 0 #c7e6e7, 264px 256px 0 #c7e6e7,
    272px 256px 0 #c7e6e7, 280px 256px 0 #c7e6e7, 288px 256px 0 #a8d8d8,
    296px 256px 0 #c7e6e7, 304px 256px 0 #a8d8d8, 312px 256px 0 #c7e6e7,
    320px 256px 0 #a8d8d8, 328px 256px 0 #c7e6e7, 336px 256px 0 #a8d8d8,
    344px 256px 0 #c7e6e7, 352px 256px 0 #a8d8d8, 360px 256px 0 #c7e6e7,
    368px 256px 0 #a8d8d8, 376px 256px 0 #ddeff0, 24px 264px 0 #ddeff0,
    32px 264px 0 #bce1e2, 40px 264px 0 #bce1e2, 48px 264px 0 #a8d8d8,
    56px 264px 0 #c7e6e7, 64px 264px 0 #c7e6e7, 72px 264px 0 #c7e6e7,
    80px 264px 0 #c7e6e7, 88px 264px 0 #c7e6e7, 96px 264px 0 #c7e6e7,
    104px 264px 0 #c7e6e7, 112px 264px 0 #c7e6e7, 120px 264px 0 #c7e6e7,
    128px 264px 0 #c7e6e7, 136px 264px 0 #c7e6e7, 144px 264px 0 #c7e6e7,
    152px 264px 0 #a8d8d8, 160px 264px 0 #a8d8d8, 168px 264px 0 #a8d8d8,
    176px 264px 0 #a8d8d8, 184px 264px 0 #a8d8d8, 192px 264px 0 #a8d8d8,
    200px 264px 0 #a8d8d8, 208px 264px 0 #a8d8d8, 216px 264px 0 #a8d8d8,
    224px 264px 0 #a8d8d8, 232px 264px 0 #a8d8d8, 240px 264px 0 #a8d8d8,
    248px 264px 0 #c7e6e7, 256px 264px 0 #c7e6e7, 264px 264px 0 #a8d8d8,
    272px 264px 0 #c7e6e7, 280px 264px 0 #a8d8d8, 288px 264px 0 #c7e6e7,
    296px 264px 0 #a8d8d8, 304px 264px 0 #c7e6e7, 312px 264px 0 #bce1e2,
    320px 264px 0 #c7e6e7, 328px 264px 0 #a8d8d8, 336px 264px 0 #c7e6e7,
    344px 264px 0 #a8d8d8, 352px 264px 0 #c7e6e7, 360px 264px 0 #a8d8d8,
    368px 264px 0 #ddeff0, 24px 272px 0 #ddeff0, 32px 272px 0 #bce1e2,
    40px 272px 0 #bce1e2, 48px 272px 0 #a8d8d8, 56px 272px 0 #a8d8d8,
    64px 272px 0 #c7e6e7, 72px 272px 0 #c7e6e7, 80px 272px 0 #c7e6e7,
    88px 272px 0 #c7e6e7, 96px 272px 0 #c7e6e7, 104px 272px 0 #c7e6e7,
    112px 272px 0 #c7e6e7, 120px 272px 0 #c7e6e7, 128px 272px 0 #c7e6e7,
    136px 272px 0 #c7e6e7, 144px 272px 0 #c7e6e7, 152px 272px 0 #a8d8d8,
    160px 272px 0 #a8d8d8, 168px 272px 0 #a8d8d8, 176px 272px 0 #a8d8d8,
    184px 272px 0 #a8d8d8, 192px 272px 0 #a8d8d8, 200px 272px 0 #a8d8d8,
    208px 272px 0 #a8d8d8, 216px 272px 0 #a8d8d8, 224px 272px 0 #a8d8d8,
    232px 272px 0 #a8d8d8, 240px 272px 0 #a8d8d8, 248px 272px 0 #a8d8d8,
    256px 272px 0 #c7e6e7, 264px 272px 0 #c7e6e7, 272px 272px 0 #a8d8d8,
    280px 272px 0 #c7e6e7, 288px 272px 0 #a8d8d8, 296px 272px 0 #a8d8d8,
    304px 272px 0 #a8d8d8, 312px 272px 0 #bce1e2, 320px 272px 0 #a8d8d8,
    328px 272px 0 #c7e6e7, 336px 272px 0 #a8d8d8, 344px 272px 0 #c7e6e7,
    352px 272px 0 #a8d8d8, 360px 272px 0 #c7e6e7, 368px 272px 0 #ddeff0,
    32px 280px 0 #ddeff0, 40px 280px 0 #bce1e2, 48px 280px 0 #bce1e2,
    56px 280px 0 #a8d8d8, 64px 280px 0 #c7e6e7, 72px 280px 0 #c7e6e7,
    80px 280px 0 #c7e6e7, 88px 280px 0 #c7e6e7, 96px 280px 0 #c7e6e7,
    104px 280px 0 #c7e6e7, 112px 280px 0 #c7e6e7, 120px 280px 0 #c7e6e7,
    128px 280px 0 #c7e6e7, 136px 280px 0 #c7e6e7, 144px 280px 0 #a8d8d8,
    152px 280px 0 #a8d8d8, 160px 280px 0 #a8d8d8, 168px 280px 0 #a8d8d8,
    176px 280px 0 #a8d8d8, 184px 280px 0 #a8d8d8, 192px 280px 0 #a8d8d8,
    200px 280px 0 #a8d8d8, 208px 280px 0 #a8d8d8, 216px 280px 0 #a8d8d8,
    224px 280px 0 #a8d8d8, 232px 280px 0 #a8d8d8, 240px 280px 0 #a8d8d8,
    248px 280px 0 #a8d8d8, 256px 280px 0 #a8d8d8, 264px 280px 0 #a8d8d8,
    272px 280px 0 #a8d8d8, 280px 280px 0 #a8d8d8, 288px 280px 0 #a8d8d8,
    296px 280px 0 #c7e6e7, 304px 280px 0 #a8d8d8, 312px 280px 0 #bce1e2,
    320px 280px 0 #a8d8d8, 328px 280px 0 #c7e6e7, 336px 280px 0 #c7e6e7,
    344px 280px 0 #a8d8d8, 352px 280px 0 #c7e6e7, 360px 280px 0 #ddeff0,
    32px 288px 0 #ddeff0, 40px 288px 0 #bce1e2, 48px 288px 0 #bce1e2,
    56px 288px 0 #a8d8d8, 64px 288px 0 #c7e6e7, 72px 288px 0 #c7e6e7,
    80px 288px 0 #c7e6e7, 88px 288px 0 #c7e6e7, 96px 288px 0 #a8d8d8,
    104px 288px 0 #a8d8d8, 112px 288px 0 #a8d8d8, 120px 288px 0 #a8d8d8,
    128px 288px 0 #a8d8d8, 136px 288px 0 #a8d8d8, 144px 288px 0 #a8d8d8,
    152px 288px 0 #a8d8d8, 160px 288px 0 #a8d8d8, 168px 288px 0 #a8d8d8,
    176px 288px 0 #a8d8d8, 184px 288px 0 #a8d8d8, 192px 288px 0 #a8d8d8,
    200px 288px 0 #a8d8d8, 208px 288px 0 #a8d8d8, 216px 288px 0 #a8d8d8,
    224px 288px 0 #a8d8d8, 232px 288px 0 #a8d8d8, 240px 288px 0 #a8d8d8,
    248px 288px 0 #a8d8d8, 256px 288px 0 #a8d8d8, 264px 288px 0 #a8d8d8,
    272px 288px 0 #c7e6e7, 280px 288px 0 #a8d8d8, 288px 288px 0 #c7e6e7,
    296px 288px 0 #a8d8d8, 304px 288px 0 #c7e6e7, 312px 288px 0 #a8d8d8,
    320px 288px 0 #c7e6e7, 328px 288px 0 #a8d8d8, 336px 288px 0 #c7e6e7,
    344px 288px 0 #c7e6e7, 352px 288px 0 #c7e6e7, 360px 288px 0 #ddeff0,
    40px 296px 0 #ddeff0, 48px 296px 0 #bce1e2, 56px 296px 0 #a8d8d8,
    64px 296px 0 #a8d8d8, 72px 296px 0 #c7e6e7, 80px 296px 0 #c7e6e7,
    88px 296px 0 #a8d8d8, 96px 296px 0 #a8d8d8, 104px 296px 0 #a8d8d8,
    112px 296px 0 #a8d8d8, 120px 296px 0 #a8d8d8, 128px 296px 0 #a8d8d8,
    136px 296px 0 #a8d8d8, 144px 296px 0 #a8d8d8, 152px 296px 0 #a8d8d8,
    160px 296px 0 #a8d8d8, 168px 296px 0 #a8d8d8, 176px 296px 0 #a8d8d8,
    184px 296px 0 #a8d8d8, 192px 296px 0 #a8d8d8, 200px 296px 0 #a8d8d8,
    208px 296px 0 #a8d8d8, 216px 296px 0 #a8d8d8, 224px 296px 0 #a8d8d8,
    232px 296px 0 #a8d8d8, 240px 296px 0 #a8d8d8, 248px 296px 0 #a8d8d8,
    256px 296px 0 #a8d8d8, 264px 296px 0 #c7e6e7, 272px 296px 0 #a8d8d8,
    280px 296px 0 #c7e6e7, 288px 296px 0 #a8d8d8, 296px 296px 0 #c7e6e7,
    304px 296px 0 #a8d8d8, 312px 296px 0 #c7e6e7, 320px 296px 0 #a8d8d8,
    328px 296px 0 #c7e6e7, 336px 296px 0 #c7e6e7, 344px 296px 0 #c7e6e7,
    352px 296px 0 #ddeff0, 40px 304px 0 #ddeff0, 48px 304px 0 #bce1e2,
    56px 304px 0 #bce1e2, 64px 304px 0 #a8d8d8, 72px 304px 0 #c7e6e7,
    80px 304px 0 #a8d8d8, 88px 304px 0 #a8d8d8, 96px 304px 0 #a8d8d8,
    104px 304px 0 #a8d8d8, 112px 304px 0 #a8d8d8, 120px 304px 0 #a8d8d8,
    128px 304px 0 #a8d8d8, 136px 304px 0 #a8d8d8, 144px 304px 0 #a8d8d8,
    152px 304px 0 #bce1e2, 160px 304px 0 #bce1e2, 168px 304px 0 #bce1e2,
    176px 304px 0 #a8d8d8, 184px 304px 0 #a8d8d8, 192px 304px 0 #a8d8d8,
    200px 304px 0 #a8d8d8, 208px 304px 0 #a8d8d8, 216px 304px 0 #a8d8d8,
    224px 304px 0 #a8d8d8, 232px 304px 0 #bce1e2, 240px 304px 0 #bce1e2,
    248px 304px 0 #bce1e2, 256px 304px 0 #c7e6e7, 264px 304px 0 #a8d8d8,
    272px 304px 0 #c7e6e7, 280px 304px 0 #a8d8d8, 288px 304px 0 #c7e6e7,
    296px 304px 0 #a8d8d8, 304px 304px 0 #c7e6e7, 312px 304px 0 #a8d8d8,
    320px 304px 0 #c7e6e7, 328px 304px 0 #c7e6e7, 336px 304px 0 #c7e6e7,
    344px 304px 0 #c7e6e7, 352px 304px 0 #ddeff0, 48px 312px 0 #ddeff0,
    56px 312px 0 #a8d8d8, 64px 312px 0 #a8d8d8, 72px 312px 0 #a8d8d8,
    80px 312px 0 #a8d8d8, 88px 312px 0 #a8d8d8, 96px 312px 0 #a8d8d8,
    104px 312px 0 #a8d8d8, 112px 312px 0 #a8d8d8, 120px 312px 0 #a8d8d8,
    128px 312px 0 #a8d8d8, 136px 312px 0 #a8d8d8, 144px 312px 0 #a8d8d8,
    152px 312px 0 #bce1e2, 160px 312px 0 #bce1e2, 168px 312px 0 #bce1e2,
    176px 312px 0 #bce1e2, 184px 312px 0 #bce1e2, 192px 312px 0 #bce1e2,
    200px 312px 0 #bce1e2, 208px 312px 0 #bce1e2, 216px 312px 0 #bce1e2,
    224px 312px 0 #bce1e2, 232px 312px 0 #bce1e2, 240px 312px 0 #bce1e2,
    248px 312px 0 #c7e6e7, 256px 312px 0 #a8d8d8, 264px 312px 0 #c7e6e7,
    272px 312px 0 #a8d8d8, 280px 312px 0 #c7e6e7, 288px 312px 0 #a8d8d8,
    296px 312px 0 #c7e6e7, 304px 312px 0 #a8d8d8, 312px 312px 0 #c7e6e7,
    320px 312px 0 #c7e6e7, 328px 312px 0 #c7e6e7, 336px 312px 0 #c7e6e7,
    344px 312px 0 #ddeff0, 56px 320px 0 #ddeff0, 64px 320px 0 #a8d8d8,
    72px 320px 0 #a8d8d8, 80px 320px 0 #a8d8d8, 88px 320px 0 #a8d8d8,
    96px 320px 0 #a8d8d8, 104px 320px 0 #a8d8d8, 112px 320px 0 #a8d8d8,
    120px 320px 0 #a8d8d8, 128px 320px 0 #a8d8d8, 136px 320px 0 #a8d8d8,
    144px 320px 0 #a8d8d8, 152px 320px 0 #a8d8d8, 160px 320px 0 #bce1e2,
    168px 320px 0 #bce1e2, 176px 320px 0 #bce1e2, 184px 320px 0 #bce1e2,
    192px 320px 0 #bce1e2, 200px 320px 0 #bce1e2, 208px 320px 0 #bce1e2,
    216px 320px 0 #a8d8d8, 224px 320px 0 #bce1e2, 232px 320px 0 #bce1e2,
    240px 320px 0 #c7e6e7, 248px 320px 0 #bce1e2, 256px 320px 0 #c7e6e7,
    264px 320px 0 #bce1e2, 272px 320px 0 #c7e6e7, 280px 320px 0 #a8d8d8,
    288px 320px 0 #c7e6e7, 296px 320px 0 #a8d8d8, 304px 320px 0 #c7e6e7,
    312px 320px 0 #c7e6e7, 320px 320px 0 #c7e6e7, 328px 320px 0 #c7e6e7,
    336px 320px 0 #ddeff0, 64px 328px 0 #ddeff0, 72px 328px 0 #a8d8d8,
    80px 328px 0 #a8d8d8, 88px 328px 0 #a8d8d8, 96px 328px 0 #a8d8d8,
    104px 328px 0 #a8d8d8, 112px 328px 0 #a8d8d8, 120px 328px 0 #a8d8d8,
    128px 328px 0 #a8d8d8, 136px 328px 0 #a8d8d8, 144px 328px 0 #a8d8d8,
    152px 328px 0 #a8d8d8, 160px 328px 0 #a8d8d8, 168px 328px 0 #a8d8d8,
    176px 328px 0 #a8d8d8, 184px 328px 0 #a8d8d8, 192px 328px 0 #a8d8d8,
    200px 328px 0 #a8d8d8, 208px 328px 0 #bce1e2, 216px 328px 0 #c7e6e7,
    224px 328px 0 #c7e6e7, 232px 328px 0 #c7e6e7, 240px 328px 0 #bce1e2,
    248px 328px 0 #c7e6e7, 256px 328px 0 #bce1e2, 264px 328px 0 #c7e6e7,
    272px 328px 0 #bce1e2, 280px 328px 0 #c7e6e7, 288px 328px 0 #bce1e2,
    296px 328px 0 #c7e6e7, 304px 328px 0 #c7e6e7, 312px 328px 0 #c7e6e7,
    320px 328px 0 #c7e6e7, 328px 328px 0 #ddeff0, 72px 336px 0 #ddeff0,
    80px 336px 0 #a8d8d8, 88px 336px 0 #a8d8d8, 96px 336px 0 #a8d8d8,
    104px 336px 0 #a8d8d8, 112px 336px 0 #a8d8d8, 120px 336px 0 #a8d8d8,
    128px 336px 0 #a8d8d8, 136px 336px 0 #a8d8d8, 144px 336px 0 #a8d8d8,
    152px 336px 0 #a8d8d8, 160px 336px 0 #a8d8d8, 168px 336px 0 #a8d8d8,
    176px 336px 0 #a8d8d8, 184px 336px 0 #a8d8d8, 192px 336px 0 #a8d8d8,
    200px 336px 0 #c7e6e7, 208px 336px 0 #a8d8d8, 216px 336px 0 #c7e6e7,
    224px 336px 0 #a8d8d8, 232px 336px 0 #c7e6e7, 240px 336px 0 #a8d8d8,
    248px 336px 0 #c7e6e7, 256px 336px 0 #a8d8d8, 264px 336px 0 #c7e6e7,
    272px 336px 0 #a8d8d8, 280px 336px 0 #c7e6e7, 288px 336px 0 #a8d8d8,
    296px 336px 0 #c7e6e7, 304px 336px 0 #c7e6e7, 312px 336px 0 #c7e6e7,
    320px 336px 0 #ddeff0, 80px 344px 0 #ddeff0, 88px 344px 0 #a8d8d8,
    96px 344px 0 #a8d8d8, 104px 344px 0 #a8d8d8, 112px 344px 0 #a8d8d8,
    120px 344px 0 #a8d8d8, 128px 344px 0 #a8d8d8, 136px 344px 0 #a8d8d8,
    144px 344px 0 #bce1e2, 152px 344px 0 #bce1e2, 160px 344px 0 #a8d8d8,
    168px 344px 0 #a8d8d8, 176px 344px 0 #bce1e2, 184px 344px 0 #bce1e2,
    192px 344px 0 #bce1e2, 200px 344px 0 #a8d8d8, 208px 344px 0 #c7e6e7,
    216px 344px 0 #a8d8d8, 224px 344px 0 #c7e6e7, 232px 344px 0 #a8d8d8,
    240px 344px 0 #c7e6e7, 248px 344px 0 #a8d8d8, 256px 344px 0 #c7e6e7,
    264px 344px 0 #a8d8d8, 272px 344px 0 #c7e6e7, 280px 344px 0 #a8d8d8,
    288px 344px 0 #c7e6e7, 296px 344px 0 #c7e6e7, 304px 344px 0 #c7e6e7,
    312px 344px 0 #ddeff0, 88px 352px 0 #ddeff0, 96px 352px 0 #ddeff0,
    104px 352px 0 #a8d8d8, 112px 352px 0 #a8d8d8, 120px 352px 0 #a8d8d8,
    128px 352px 0 #bce1e2, 136px 352px 0 #bce1e2, 144px 352px 0 #bce1e2,
    152px 352px 0 #bce1e2, 160px 352px 0 #bce1e2, 168px 352px 0 #c7e6e7,
    176px 352px 0 #bce1e2, 184px 352px 0 #bce1e2, 192px 352px 0 #a8d8d8,
    200px 352px 0 #c7e6e7, 208px 352px 0 #a8d8d8, 216px 352px 0 #c7e6e7,
    224px 352px 0 #a8d8d8, 232px 352px 0 #c7e6e7, 240px 352px 0 #a8d8d8,
    248px 352px 0 #c7e6e7, 256px 352px 0 #a8d8d8, 264px 352px 0 #c7e6e7,
    272px 352px 0 #c7e6e7, 280px 352px 0 #c7e6e7, 288px 352px 0 #c7e6e7,
    296px 352px 0 #ddeff0, 304px 352px 0 #ddeff0, 104px 360px 0 #ddeff0,
    112px 360px 0 #ddeff0, 120px 360px 0 #a8d8d8, 128px 360px 0 #bce1e2,
    136px 360px 0 #bce1e2, 144px 360px 0 #bce1e2, 152px 360px 0 #bce1e2,
    160px 360px 0 #bce1e2, 168px 360px 0 #bce1e2, 176px 360px 0 #bce1e2,
    184px 360px 0 #a8d8d8, 192px 360px 0 #c7e6e7, 200px 360px 0 #a8d8d8,
    208px 360px 0 #c7e6e7, 216px 360px 0 #a8d8d8, 224px 360px 0 #c7e6e7,
    232px 360px 0 #a8d8d8, 240px 360px 0 #c7e6e7, 248px 360px 0 #a8d8d8,
    256px 360px 0 #c7e6e7, 264px 360px 0 #c7e6e7, 272px 360px 0 #c7e6e7,
    280px 360px 0 #ddeff0, 288px 360px 0 #ddeff0, 120px 368px 0 #ddeff0,
    128px 368px 0 #ddeff0, 136px 368px 0 #c7e6e7, 144px 368px 0 #a8d8d8,
    152px 368px 0 #c7e6e7, 160px 368px 0 #a8d8d8, 168px 368px 0 #c7e6e7,
    176px 368px 0 #a8d8d8, 184px 368px 0 #c7e6e7, 192px 368px 0 #a8d8d8,
    200px 368px 0 #c7e6e7, 208px 368px 0 #a8d8d8, 216px 368px 0 #c7e6e7,
    224px 368px 0 #a8d8d8, 232px 368px 0 #c7e6e7, 240px 368px 0 #c7e6e7,
    248px 368px 0 #c7e6e7, 256px 368px 0 #c7e6e7, 264px 368px 0 #ddeff0,
    272px 368px 0 #ddeff0, 136px 376px 0 #ddeff0, 144px 376px 0 #ddeff0,
    152px 376px 0 #ddeff0, 160px 376px 0 #d2eaeb, 168px 376px 0 #d2eaeb,
    176px 376px 0 #d2eaeb, 184px 376px 0 #d2eaeb, 192px 376px 0 #d2eaeb,
    200px 376px 0 #d2eaeb, 208px 376px 0 #d2eaeb, 216px 376px 0 #d2eaeb,
    224px 376px 0 #d2eaeb, 232px 376px 0 #d2eaeb, 240px 376px 0 #ddeff0,
    248px 376px 0 #ddeff0, 256px 376px 0 #ddeff0, 160px 384px 0 #ddeff0,
    168px 384px 0 #ddeff0, 176px 384px 0 #ddeff0, 184px 384px 0 #ddeff0,
    192px 384px 0 #ddeff0, 200px 384px 0 #ddeff0, 208px 384px 0 #ddeff0,
    216px 384px 0 #ddeff0, 224px 384px 0 #ddeff0, 232px 384px 0 #ddeff0;
}

.kiki {
  width: 8px;
  height: 8px;
  box-shadow: 312px 224px 0 #b14436, 304px 232px 0 #b14436,
    312px 232px 0 #b14436, 320px 232px 0 #b14436, 304px 240px 0 #b14436,
    312px 240px 0 #b14436, 320px 240px 0 #b14436, 376px 240px 0 #b14436,
    280px 248px 0 #b14436, 288px 248px 0 #b14436, 296px 248px 0 #b14436,
    304px 248px 0 #b14436, 312px 248px 0 #b14436, 320px 248px 0 #b14436,
    328px 248px 0 #953d29, 336px 248px 0 #953d29, 360px 248px 0 #b14436,
    368px 248px 0 #b14436, 376px 248px 0 #b14436, 384px 248px 0 #b14436,
    272px 256px 0 #b14436, 280px 256px 0 #b14436, 288px 256px 0 #b14436,
    296px 256px 0 #b14436, 304px 256px 0 #b14436, 312px 256px 0 #b14436,
    320px 256px 0 #b14436, 328px 256px 0 #953d29, 336px 256px 0 #953d29,
    344px 256px 0 #953d29, 360px 256px 0 #b14436, 368px 256px 0 #b14436,
    376px 256px 0 #b14436, 384px 256px 0 #b14436, 280px 264px 0 #b14436,
    288px 264px 0 #b14436, 296px 264px 0 #b14436, 304px 264px 0 #b14436,
    312px 264px 0 #b14436, 320px 264px 0 #b14436, 328px 264px 0 #953d29,
    336px 264px 0 #953d29, 344px 264px 0 #953d29, 352px 264px 0 #b14436,
    360px 264px 0 #b14436, 368px 264px 0 #b14436, 376px 264px 0 #b14436,
    384px 264px 0 #b14436, 288px 272px 0 #b14436, 296px 272px 0 #b14436,
    304px 272px 0 #b14436, 312px 272px 0 #b14436, 320px 272px 0 #b14436,
    328px 272px 0 #953d29, 336px 272px 0 #b14436, 344px 272px 0 #b14436,
    352px 272px 0 #b14436, 360px 272px 0 #b14436, 368px 272px 0 #953d29,
    376px 272px 0 #953d29, 384px 272px 0 #b14436, 392px 272px 0 #b14436,
    296px 280px 0 #b14436, 304px 280px 0 #b14436, 312px 280px 0 #b14436,
    320px 280px 0 #953d29, 328px 280px 0 #b14436, 336px 280px 0 #b14436,
    344px 280px 0 #b14436, 352px 280px 0 #b14436, 360px 280px 0 #953d29,
    368px 280px 0 #953d29, 376px 280px 0 #953d29, 384px 280px 0 #b14436,
    392px 280px 0 #b14436, 400px 280px 0 #b14436, 280px 288px 0 #31211c,
    288px 288px 0 #31211c, 296px 288px 0 #31211c, 304px 288px 0 #31211c,
    312px 288px 0 #31211c, 320px 288px 0 #b14436, 328px 288px 0 #b14436,
    336px 288px 0 #953d29, 344px 288px 0 #953d29, 352px 288px 0 #953d29,
    360px 288px 0 #953d29, 368px 288px 0 #953d29, 376px 288px 0 #953d29,
    384px 288px 0 #953d29, 392px 288px 0 #953d29, 400px 288px 0 #953d29,
    280px 296px 0 #31211c, 288px 296px 0 #31211c, 296px 296px 0 #31211c,
    304px 296px 0 #31211c, 312px 296px 0 #31211c, 320px 296px 0 #b14436,
    328px 296px 0 #b14436, 336px 296px 0 #31211c, 344px 296px 0 #953d29,
    352px 296px 0 #953d29, 360px 296px 0 #953d29, 368px 296px 0 #953d29,
    376px 296px 0 #953d29, 384px 296px 0 #953d29, 392px 296px 0 #953d29,
    272px 304px 0 #31211c, 280px 304px 0 #31211c, 288px 304px 0 #31211c,
    296px 304px 0 #31211c, 304px 304px 0 #31211c, 312px 304px 0 #31211c,
    320px 304px 0 #1e1a1f, 328px 304px 0 #b14436, 336px 304px 0 #31211c,
    344px 304px 0 #31211c, 352px 304px 0 #31211c, 360px 304px 0 #31211c,
    368px 304px 0 #31211c, 376px 304px 0 #31211c, 384px 304px 0 #31211c,
    392px 304px 0 #31211c, 264px 312px 0 #31211c, 272px 312px 0 #31211c,
    280px 312px 0 #31211c, 288px 312px 0 #31211c, 296px 312px 0 #31211c,
    304px 312px 0 #1e1a1f, 312px 312px 0 #1e1a1f, 320px 312px 0 #1e1a1f,
    328px 312px 0 #b14436, 336px 312px 0 #b14436, 344px 312px 0 #31211c,
    352px 312px 0 #31211c, 360px 312px 0 #31211c, 368px 312px 0 #31211c,
    376px 312px 0 #31211c, 384px 312px 0 #31211c, 392px 312px 0 #31211c,
    400px 312px 0 #31211c, 264px 320px 0 #31211c, 272px 320px 0 #31211c,
    280px 320px 0 #e6ccac, 288px 320px 0 #31211c, 296px 320px 0 #1e1a1f,
    304px 320px 0 #1e1a1f, 312px 320px 0 #1e1a1f, 320px 320px 0 #1e1a1f,
    328px 320px 0 #1e1a1f, 336px 320px 0 #b14436, 344px 320px 0 #31211c,
    352px 320px 0 #31211c, 360px 320px 0 #31211c, 368px 320px 0 #31211c,
    376px 320px 0 #31211c, 384px 320px 0 #31211c, 392px 320px 0 #31211c,
    400px 320px 0 #31211c, 408px 320px 0 #31211c, 264px 328px 0 #31211c,
    272px 328px 0 #e6ccac, 280px 328px 0 #e6ccac, 288px 328px 0 #31211c,
    296px 328px 0 #1e1a1f, 304px 328px 0 #1e1a1f, 312px 328px 0 #1e1a1f,
    320px 328px 0 #1e1a1f, 328px 328px 0 #1e1a1f, 336px 328px 0 #b14436,
    344px 328px 0 #1e1a1f, 352px 328px 0 #1e1a1f, 360px 328px 0 #1e1a1f,
    368px 328px 0 #1e1a1f, 376px 328px 0 #1e1a1f, 384px 328px 0 #31211c,
    392px 328px 0 #31211c, 400px 328px 0 #31211c, 408px 328px 0 #31211c,
    416px 328px 0 #31211c, 272px 336px 0 #e6ccac, 280px 336px 0 #e6ccac,
    288px 336px 0 #e6ccac, 296px 336px 0 #1e1a1f, 304px 336px 0 #1e1a1f,
    312px 336px 0 #1e1a1f, 320px 336px 0 #1e1a1f, 328px 336px 0 #1e1a1f,
    336px 336px 0 #b14436, 344px 336px 0 #b14436, 352px 336px 0 #1e1a1f,
    360px 336px 0 #1e1a1f, 368px 336px 0 #1e1a1f, 376px 336px 0 #1e1a1f,
    384px 336px 0 #1e1a1f, 392px 336px 0 #1e1a1f, 400px 336px 0 #31211c,
    408px 336px 0 #31211c, 416px 336px 0 #31211c, 272px 344px 0 #e6ccac,
    280px 344px 0 #e6ccac, 288px 344px 0 #e6ccac, 296px 344px 0 #e6ccac,
    304px 344px 0 #1e1a1f, 312px 344px 0 #1e1a1f, 320px 344px 0 #1e1a1f,
    328px 344px 0 #1e1a1f, 336px 344px 0 #1e1a1f, 344px 344px 0 #b14436,
    352px 344px 0 #1e1a1f, 360px 344px 0 #1e1a1f, 368px 344px 0 #1e1a1f,
    376px 344px 0 #1e1a1f, 384px 344px 0 #1e1a1f, 392px 344px 0 #1e1a1f,
    400px 344px 0 #1e1a1f, 408px 344px 0 #31211c, 416px 344px 0 #31211c,
    272px 352px 0 #e6ccac, 280px 352px 0 #1e1a1f, 288px 352px 0 #e6ccac,
    296px 352px 0 #e6ccac, 304px 352px 0 #e6ccac, 312px 352px 0 #1e1a1f,
    320px 352px 0 #1e1a1f, 328px 352px 0 #1e1a1f, 336px 352px 0 #e4b38e,
    344px 352px 0 #b14436, 352px 352px 0 #1e1a1f, 360px 352px 0 #1e1a1f,
    368px 352px 0 #1e1a1f, 376px 352px 0 #1e1a1f, 384px 352px 0 #1e1a1f,
    392px 352px 0 #1e1a1f, 400px 352px 0 #1e1a1f, 408px 352px 0 #1e1a1f,
    416px 352px 0 #31211c, 272px 360px 0 #e6ccac, 280px 360px 0 #1e1a1f,
    288px 360px 0 #e6ccac, 296px 360px 0 #e6ccac, 304px 360px 0 #e4b38e,
    312px 360px 0 #1e1a1f, 320px 360px 0 #1e1a1f, 328px 360px 0 #e4b38e,
    336px 360px 0 #e4b38e, 344px 360px 0 #953d29, 352px 360px 0 #1e1a1f,
    360px 360px 0 #1e1a1f, 368px 360px 0 #1e1a1f, 376px 360px 0 #1e1a1f,
    384px 360px 0 #1e1a1f, 392px 360px 0 #1e1a1f, 400px 360px 0 #1e1a1f,
    408px 360px 0 #1e1a1f, 416px 360px 0 #31211c, 424px 360px 0 #31211c,
    264px 368px 0 #e6ccac, 272px 368px 0 #e6ccac, 280px 368px 0 #e6ccac,
    288px 368px 0 #e6ccac, 296px 368px 0 #e6ccac, 304px 368px 0 #e4b38e,
    312px 368px 0 #1e1a1f, 320px 368px 0 #1e1a1f, 328px 368px 0 #e4b38e,
    336px 368px 0 #e4b38e, 344px 368px 0 #953d29, 352px 368px 0 #1e1a1f,
    360px 368px 0 #1e1a1f, 368px 368px 0 #1e1a1f, 376px 368px 0 #1e1a1f,
    384px 368px 0 #1e1a1f, 392px 368px 0 #1e1a1f, 400px 368px 0 #1e1a1f,
    408px 368px 0 #1e1a1f, 424px 368px 0 #31211c, 256px 376px 0 #e6ccac,
    264px 376px 0 #e6ccac, 272px 376px 0 #e6ccac, 280px 376px 0 #e6ccac,
    288px 376px 0 #e6ccac, 296px 376px 0 #e6ccac, 304px 376px 0 #e4b38e,
    312px 376px 0 #e4b38e, 320px 376px 0 #1e1a1f, 328px 376px 0 #e4b38e,
    336px 376px 0 #1e1a1f, 344px 376px 0 #953d29, 352px 376px 0 #1e1a1f,
    360px 376px 0 #1e1a1f, 368px 376px 0 #1e1a1f, 376px 376px 0 #1e1a1f,
    384px 376px 0 #1e1a1f, 392px 376px 0 #1e1a1f, 400px 376px 0 #1e1a1f,
    408px 376px 0 #1e1a1f, 264px 384px 0 #e6ccac, 272px 384px 0 #e6ccac,
    280px 384px 0 #e6ccac, 288px 384px 0 #e6ccac, 296px 384px 0 #e4b38e,
    304px 384px 0 #e4b38e, 312px 384px 0 #e4b38e, 320px 384px 0 #e4b38e,
    328px 384px 0 #1e1a1f, 336px 384px 0 #1e1a1f, 344px 384px 0 #953d29,
    352px 384px 0 #1e1a1f, 360px 384px 0 #1e1a1f, 368px 384px 0 #1e1a1f,
    376px 384px 0 #1e1a1f, 384px 384px 0 #1e1a1f, 392px 384px 0 #1e1a1f,
    400px 384px 0 #1e1a1f, 408px 384px 0 #1e1a1f, 416px 384px 0 #1e1a1f,
    424px 384px 0 #1e1a1f, 264px 392px 0 #e6ccac, 272px 392px 0 #e6ccac,
    280px 392px 0 #e6ccac, 288px 392px 0 #e4b38e, 296px 392px 0 #e4b38e,
    304px 392px 0 #e4b38e, 312px 392px 0 #e4b38e, 320px 392px 0 #1e1a1f,
    328px 392px 0 #1e1a1f, 336px 392px 0 #1e1a1f, 344px 392px 0 #953d29,
    352px 392px 0 #1e1a1f, 360px 392px 0 #1e1a1f, 368px 392px 0 #1e1a1f,
    376px 392px 0 #1e1a1f, 384px 392px 0 #1e1a1f, 392px 392px 0 #1e1a1f,
    400px 392px 0 #1e1a1f, 264px 400px 0 #e6ccac, 272px 400px 0 #e6ccac,
    280px 400px 0 #e6ccac, 288px 400px 0 #e4b38e, 296px 400px 0 #e4b38e,
    304px 400px 0 #e4b38e, 312px 400px 0 #e4b38e, 320px 400px 0 #e4b38e,
    328px 400px 0 #e4b38e, 336px 400px 0 #1e1a1f, 344px 400px 0 #1e1a1f,
    352px 400px 0 #1e1a1f, 360px 400px 0 #1e1a1f, 368px 400px 0 #1e1a1f,
    376px 400px 0 #1e1a1f, 392px 400px 0 #1e1a1f, 408px 400px 0 #1e1a1f,
    272px 408px 0 #e6ccac, 280px 408px 0 #e6ccac, 288px 408px 0 #e4b38e,
    296px 408px 0 #e4b38e, 304px 408px 0 #e4b38e, 312px 408px 0 #e4b38e,
    320px 408px 0 #e4b38e, 328px 408px 0 #e4b38e, 336px 408px 0 #e4b38e,
    344px 408px 0 #1e1a1f, 272px 416px 0 #e6ccac, 280px 416px 0 #e4b38e,
    288px 416px 0 #e4b38e, 296px 416px 0 #e4b38e, 304px 416px 0 #e4b38e,
    312px 416px 0 #e4b38e, 320px 416px 0 #e4b38e, 328px 416px 0 #e4b38e,
    336px 416px 0 #2d282e, 344px 416px 0 #2d282e, 352px 416px 0 #2d282e,
    360px 416px 0 #2d282e, 312px 424px 0 #e4b38e, 320px 424px 0 #4d4657,
    328px 424px 0 #4d4657, 336px 424px 0 #2d282e, 344px 424px 0 #2d282e,
    352px 424px 0 #2d282e, 360px 424px 0 #2d282e, 368px 424px 0 #2d282e,
    376px 424px 0 #2d282e, 312px 432px 0 #4d4657, 320px 432px 0 #4d4657,
    328px 432px 0 #4d4657, 336px 432px 0 #2d282e, 344px 432px 0 #2d282e,
    352px 432px 0 #2d282e, 360px 432px 0 #2d282e, 368px 432px 0 #2d282e,
    376px 432px 0 #2d282e, 384px 432px 0 #2d282e, 392px 432px 0 #2d282e,
    304px 440px 0 #4d4657, 312px 440px 0 #4d4657, 320px 440px 0 #4d4657,
    328px 440px 0 #4d4657, 336px 440px 0 #4d4657, 344px 440px 0 #2d282e,
    352px 440px 0 #2d282e, 360px 440px 0 #2d282e, 368px 440px 0 #2d282e,
    376px 440px 0 #2d282e, 384px 440px 0 #1e1a1f, 392px 440px 0 #2d282e,
    400px 440px 0 #2d282e, 408px 440px 0 #e0774a, 416px 440px 0 #e0774a,
    424px 440px 0 #e0774a, 304px 448px 0 #4d4657, 312px 448px 0 #4d4657,
    320px 448px 0 #4d4657, 328px 448px 0 #4d4657, 336px 448px 0 #4d4657,
    344px 448px 0 #2d282e, 352px 448px 0 #2d282e, 360px 448px 0 #2d282e,
    368px 448px 0 #2d282e, 376px 448px 0 #2d282e, 384px 448px 0 #1e1a1f,
    392px 448px 0 #2d282e, 400px 448px 0 #2d282e, 408px 448px 0 #2d282e,
    416px 448px 0 #e0774a, 424px 448px 0 #e0774a, 432px 448px 0 #e0774a,
    440px 448px 0 #e0774a, 304px 456px 0 #4d4657, 312px 456px 0 #4d4657,
    320px 456px 0 #4d4657, 328px 456px 0 #4d4657, 336px 456px 0 #4d4657,
    344px 456px 0 #2d282e, 352px 456px 0 #2d282e, 360px 456px 0 #2d282e,
    368px 456px 0 #2d282e, 376px 456px 0 #2d282e, 384px 456px 0 #2d282e,
    392px 456px 0 #1e1a1f, 400px 456px 0 #2d282e, 408px 456px 0 #2d282e,
    416px 456px 0 #2d282e, 424px 456px 0 #ca6948, 432px 456px 0 #ca6948,
    440px 456px 0 #ca6948, 448px 456px 0 #e0774a, 456px 456px 0 #e0774a,
    488px 456px 0 #dd583b, 496px 456px 0 #dd583b, 304px 464px 0 #4d4657,
    312px 464px 0 #4d4657, 320px 464px 0 #4d4657, 328px 464px 0 #4d4657,
    336px 464px 0 #4d4657, 344px 464px 0 #2d282e, 352px 464px 0 #2d282e,
    360px 464px 0 #2d282e, 368px 464px 0 #2d282e, 376px 464px 0 #2d282e,
    384px 464px 0 #2d282e, 392px 464px 0 #1e1a1f, 400px 464px 0 #2d282e,
    408px 464px 0 #2d282e, 416px 464px 0 #2d282e, 424px 464px 0 #2d282e,
    432px 464px 0 #ca6948, 440px 464px 0 #ca6948, 448px 464px 0 #ca6948,
    456px 464px 0 #ca6948, 464px 464px 0 #ca6948, 472px 464px 0 #dd583b,
    480px 464px 0 #dd583b, 488px 464px 0 #dd583b, 496px 464px 0 #dd583b,
    504px 464px 0 #dd583b, 304px 472px 0 #4d4657, 312px 472px 0 #4d4657,
    320px 472px 0 #4d4657, 328px 472px 0 #4d4657, 336px 472px 0 #4d4657,
    344px 472px 0 #4d4657, 352px 472px 0 #2d282e, 360px 472px 0 #2d282e,
    368px 472px 0 #2d282e, 376px 472px 0 #2d282e, 384px 472px 0 #2d282e,
    392px 472px 0 #1e1a1f, 400px 472px 0 #2d282e, 408px 472px 0 #2d282e,
    416px 472px 0 #2d282e, 424px 472px 0 #2d282e, 432px 472px 0 #2d282e,
    440px 472px 0 #ca6948, 448px 472px 0 #ca6948, 456px 472px 0 #dd583b,
    464px 472px 0 #dd583b, 472px 472px 0 #dd583b, 480px 472px 0 #dd583b,
    488px 472px 0 #dd583b, 496px 472px 0 #dd583b, 504px 472px 0 #dd583b,
    512px 472px 0 #dd583b, 520px 472px 0 #dd583b, 296px 480px 0 #4d4657,
    304px 480px 0 #4d4657, 312px 480px 0 #4d4657, 320px 480px 0 #4d4657,
    328px 480px 0 #4d4657, 336px 480px 0 #4d4657, 344px 480px 0 #4d4657,
    352px 480px 0 #2d282e, 360px 480px 0 #2d282e, 368px 480px 0 #2d282e,
    376px 480px 0 #2d282e, 384px 480px 0 #2d282e, 392px 480px 0 #1e1a1f,
    400px 480px 0 #2d282e, 408px 480px 0 #2d282e, 416px 480px 0 #2d282e,
    424px 480px 0 #2d282e, 432px 480px 0 #2d282e, 440px 480px 0 #2d282e,
    448px 480px 0 #dd583b, 456px 480px 0 #dd583b, 464px 480px 0 #dd583b,
    472px 480px 0 #dd583b, 480px 480px 0 #dd583b, 488px 480px 0 #dd583b,
    496px 480px 0 #dd583b, 504px 480px 0 #dd583b, 512px 480px 0 #dd583b,
    520px 480px 0 #dd583b, 528px 480px 0 #bf4f37, 296px 488px 0 #4d4657,
    304px 488px 0 #4d4657, 312px 488px 0 #4d4657, 320px 488px 0 #4d4657,
    328px 488px 0 #4d4657, 336px 488px 0 #4d4657, 344px 488px 0 #4d4657,
    352px 488px 0 #2d282e, 360px 488px 0 #2d282e, 368px 488px 0 #2d282e,
    376px 488px 0 #2d282e, 384px 488px 0 #2d282e, 392px 488px 0 #1e1a1f,
    400px 488px 0 #2d282e, 408px 488px 0 #2d282e, 416px 488px 0 #2d282e,
    424px 488px 0 #2d282e, 432px 488px 0 #2d282e, 440px 488px 0 #dd583b,
    448px 488px 0 #dd583b, 456px 488px 0 #dd583b, 464px 488px 0 #dd583b,
    472px 488px 0 #dd583b, 480px 488px 0 #dd583b, 488px 488px 0 #dd583b,
    496px 488px 0 #dd583b, 504px 488px 0 #dd583b, 512px 488px 0 #dd583b,
    520px 488px 0 #dd583b, 528px 488px 0 #bf4f37, 296px 496px 0 #4d4657,
    304px 496px 0 #4d4657, 312px 496px 0 #4d4657, 320px 496px 0 #4d4657,
    328px 496px 0 #4d4657, 336px 496px 0 #4d4657, 344px 496px 0 #4d4657,
    352px 496px 0 #2d282e, 360px 496px 0 #2d282e, 368px 496px 0 #2d282e,
    376px 496px 0 #2d282e, 384px 496px 0 #2d282e, 392px 496px 0 #1e1a1f,
    400px 496px 0 #2d282e, 408px 496px 0 #2d282e, 416px 496px 0 #2d282e,
    424px 496px 0 #2d282e, 432px 496px 0 #dd583b, 440px 496px 0 #dd583b,
    448px 496px 0 #dd583b, 456px 496px 0 #dd583b, 464px 496px 0 #dd583b,
    472px 496px 0 #dd583b, 480px 496px 0 #dd583b, 488px 496px 0 #dd583b,
    496px 496px 0 #dd583b, 504px 496px 0 #dd583b, 512px 496px 0 #dd583b,
    520px 496px 0 #dd583b, 528px 496px 0 #dd583b, 536px 496px 0 #bf4f37,
    744px 496px 0 #ccb28a, 800px 496px 0 #ccb28a, 296px 504px 0 #4d4657,
    304px 504px 0 #4d4657, 312px 504px 0 #4d4657, 320px 504px 0 #4d4657,
    328px 504px 0 #4d4657, 336px 504px 0 #4d4657, 344px 504px 0 #4d4657,
    352px 504px 0 #2d282e, 360px 504px 0 #2d282e, 368px 504px 0 #2d282e,
    376px 504px 0 #1e1a1f, 384px 504px 0 #1e1a1f, 392px 504px 0 #2d282e,
    400px 504px 0 #2d282e, 408px 504px 0 #2d282e, 416px 504px 0 #2d282e,
    424px 504px 0 #dd583b, 432px 504px 0 #dd583b, 440px 504px 0 #dd583b,
    448px 504px 0 #dd583b, 456px 504px 0 #dd583b, 464px 504px 0 #dd583b,
    472px 504px 0 #dd583b, 480px 504px 0 #dd583b, 488px 504px 0 #dd583b,
    496px 504px 0 #dd583b, 504px 504px 0 #dd583b, 512px 504px 0 #dd583b,
    520px 504px 0 #dd583b, 528px 504px 0 #dd583b, 536px 504px 0 #bf4f37,
    544px 504px 0 #dd583b, 728px 504px 0 #ccb28a, 736px 504px 0 #ccb28a,
    784px 504px 0 #ccb28a, 792px 504px 0 #ccb28a, 288px 512px 0 #4d4657,
    296px 512px 0 #4d4657, 304px 512px 0 #4d4657, 312px 512px 0 #4d4657,
    320px 512px 0 #4d4657, 328px 512px 0 #4d4657, 336px 512px 0 #4d4657,
    344px 512px 0 #4d4657, 352px 512px 0 #1e1a1f, 360px 512px 0 #1e1a1f,
    368px 512px 0 #1e1a1f, 376px 512px 0 #1e1a1f, 384px 512px 0 #1e1a1f,
    392px 512px 0 #2d282e, 400px 512px 0 #2d282e, 408px 512px 0 #2d282e,
    416px 512px 0 #e0774a, 424px 512px 0 #e0774a, 432px 512px 0 #e0774a,
    440px 512px 0 #e0774a, 448px 512px 0 #e0774a, 456px 512px 0 #dd583b,
    464px 512px 0 #dd583b, 472px 512px 0 #dd583b, 480px 512px 0 #dd583b,
    488px 512px 0 #dd583b, 496px 512px 0 #dd583b, 504px 512px 0 #dd583b,
    512px 512px 0 #dd583b, 520px 512px 0 #dd583b, 528px 512px 0 #dd583b,
    536px 512px 0 #bf4f37, 544px 512px 0 #dd583b, 552px 512px 0 #dd583b,
    672px 512px 0 #ccb28a, 712px 512px 0 #ccb28a, 720px 512px 0 #ccb28a,
    760px 512px 0 #ccb28a, 768px 512px 0 #ccb28a, 776px 512px 0 #ccb28a,
    288px 520px 0 #4d4657, 296px 520px 0 #4d4657, 304px 520px 0 #4d4657,
    312px 520px 0 #4d4657, 320px 520px 0 #1e1a1f, 328px 520px 0 #1e1a1f,
    336px 520px 0 #1e1a1f, 344px 520px 0 #1e1a1f, 352px 520px 0 #1e1a1f,
    360px 520px 0 #1e1a1f, 368px 520px 0 #1e1a1f, 376px 520px 0 #1e1a1f,
    384px 520px 0 #1e1a1f, 392px 520px 0 #2d282e, 400px 520px 0 #e0774a,
    408px 520px 0 #e0774a, 416px 520px 0 #e0774a, 424px 520px 0 #e0774a,
    432px 520px 0 #e0774a, 440px 520px 0 #e0774a, 448px 520px 0 #e0774a,
    456px 520px 0 #e0774a, 464px 520px 0 #dd583b, 472px 520px 0 #dd583b,
    480px 520px 0 #dd583b, 488px 520px 0 #bf4f37, 496px 520px 0 #bf4f37,
    504px 520px 0 #bf4f37, 512px 520px 0 #dd583b, 520px 520px 0 #dd583b,
    528px 520px 0 #dd583b, 536px 520px 0 #bf4f37, 544px 520px 0 #dd583b,
    552px 520px 0 #dd583b, 664px 520px 0 #ccb28a, 696px 520px 0 #ccb28a,
    704px 520px 0 #ccb28a, 712px 520px 0 #ccb28a, 720px 520px 0 #ccb28a,
    728px 520px 0 #ccb28a, 736px 520px 0 #ccb28a, 744px 520px 0 #ccb28a,
    752px 520px 0 #ccb28a, 288px 528px 0 #4d4657, 296px 528px 0 #4d4657,
    304px 528px 0 #4d4657, 312px 528px 0 #1e1a1f, 320px 528px 0 #e4b38e,
    328px 528px 0 #e4b38e, 336px 528px 0 #e4b38e, 344px 528px 0 #1e1a1f,
    352px 528px 0 #1e1a1f, 360px 528px 0 #1e1a1f, 368px 528px 0 #1e1a1f,
    376px 528px 0 #2d282e, 384px 528px 0 #e0774a, 392px 528px 0 #e0774a,
    400px 528px 0 #e0774a, 408px 528px 0 #e0774a, 416px 528px 0 #e0774a,
    424px 528px 0 #e0774a, 432px 528px 0 #e0774a, 440px 528px 0 #e0774a,
    448px 528px 0 #e0774a, 456px 528px 0 #e0774a, 464px 528px 0 #dd583b,
    472px 528px 0 #dd583b, 480px 528px 0 #bf4f37, 488px 528px 0 #bf4f37,
    496px 528px 0 #bf4f37, 504px 528px 0 #bf4f37, 512px 528px 0 #dd583b,
    520px 528px 0 #dd583b, 528px 528px 0 #bf4f37, 536px 528px 0 #dd583b,
    544px 528px 0 #dd583b, 552px 528px 0 #dd583b, 560px 528px 0 #dd583b,
    648px 528px 0 #ccb28a, 656px 528px 0 #ccb28a, 664px 528px 0 #ccb28a,
    672px 528px 0 #ccb28a, 680px 528px 0 #ccb28a, 688px 528px 0 #ccb28a,
    696px 528px 0 #ccb28a, 704px 528px 0 #ccb28a, 712px 528px 0 #ccb28a,
    720px 528px 0 #ccb28a, 728px 528px 0 #ccb28a, 736px 528px 0 #ccb28a,
    744px 528px 0 #ccb28a, 752px 528px 0 #ccb28a, 760px 528px 0 #ccb28a,
    768px 528px 0 #ccb28a, 776px 528px 0 #ccb28a, 288px 536px 0 #4d4657,
    296px 536px 0 #4d4657, 304px 536px 0 #e6ccac, 312px 536px 0 #e6ccac,
    320px 536px 0 #e6ccac, 328px 536px 0 #e4b38e, 336px 536px 0 #ca6948,
    344px 536px 0 #1e1a1f, 352px 536px 0 #1e1a1f, 360px 536px 0 #2d282e,
    368px 536px 0 #2d282e, 376px 536px 0 #e0774a, 384px 536px 0 #e0774a,
    392px 536px 0 #e0774a, 400px 536px 0 #e0774a, 408px 536px 0 #e0774a,
    416px 536px 0 #e0774a, 424px 536px 0 #e0774a, 432px 536px 0 #e0774a,
    440px 536px 0 #e0774a, 448px 536px 0 #e0774a, 456px 536px 0 #e0774a,
    464px 536px 0 #dd583b, 472px 536px 0 #dd583b, 480px 536px 0 #dd583b,
    488px 536px 0 #bf4f37, 496px 536px 0 #bf4f37, 504px 536px 0 #bf4f37,
    512px 536px 0 #dd583b, 520px 536px 0 #dd583b, 528px 536px 0 #bf4f37,
    536px 536px 0 #dd583b, 544px 536px 0 #dd583b, 552px 536px 0 #dd583b,
    560px 536px 0 #dd583b, 632px 536px 0 #ccb28a, 640px 536px 0 #ccb28a,
    648px 536px 0 #ccb28a, 656px 536px 0 #ccb28a, 664px 536px 0 #ccb28a,
    672px 536px 0 #ccb28a, 680px 536px 0 #ccb28a, 688px 536px 0 #ccb28a,
    696px 536px 0 #ccb28a, 704px 536px 0 #ccb28a, 712px 536px 0 #ccb28a,
    720px 536px 0 #ccb28a, 728px 536px 0 #ccb28a, 736px 536px 0 #ccb28a,
    744px 536px 0 #ccb28a, 752px 536px 0 #ccb28a, 760px 536px 0 #ccb28a,
    768px 536px 0 #ccb28a, 776px 536px 0 #ccb28a, 784px 536px 0 #ccb28a,
    792px 536px 0 #ccb28a, 800px 536px 0 #ccb28a, 288px 544px 0 #4d4657,
    296px 544px 0 #e6ccac, 304px 544px 0 #e6ccac, 312px 544px 0 #e6ccac,
    320px 544px 0 #e6ccac, 328px 544px 0 #e4b38e, 336px 544px 0 #ca6948,
    344px 544px 0 #ca6948, 352px 544px 0 #2d282e, 360px 544px 0 #e0774a,
    368px 544px 0 #e0774a, 376px 544px 0 #e0774a, 384px 544px 0 #e0774a,
    392px 544px 0 #e0774a, 400px 544px 0 #e0774a, 408px 544px 0 #e0774a,
    416px 544px 0 #e0774a, 424px 544px 0 #e0774a, 432px 544px 0 #e0774a,
    440px 544px 0 #e0774a, 448px 544px 0 #e0774a, 456px 544px 0 #e0774a,
    464px 544px 0 #dd583b, 472px 544px 0 #dd583b, 480px 544px 0 #dd583b,
    488px 544px 0 #dd583b, 496px 544px 0 #dd583b, 504px 544px 0 #dd583b,
    512px 544px 0 #dd583b, 520px 544px 0 #bf4f37, 528px 544px 0 #dd583b,
    536px 544px 0 #dd583b, 544px 544px 0 #dd583b, 552px 544px 0 #dd583b,
    560px 544px 0 #dd583b, 616px 544px 0 #ccb28a, 624px 544px 0 #ccb28a,
    632px 544px 0 #ccb28a, 640px 544px 0 #ccb28a, 648px 544px 0 #ccb28a,
    656px 544px 0 #ccb28a, 664px 544px 0 #ccb28a, 672px 544px 0 #ccb28a,
    680px 544px 0 #ccb28a, 688px 544px 0 #ccb28a, 696px 544px 0 #ccb28a,
    704px 544px 0 #ccb28a, 712px 544px 0 #ccb28a, 720px 544px 0 #ccb28a,
    728px 544px 0 #ccb28a, 736px 544px 0 #ccb28a, 744px 544px 0 #ccb28a,
    752px 544px 0 #ccb28a, 760px 544px 0 #ccb28a, 288px 552px 0 #e4b38e,
    296px 552px 0 #e6ccac, 304px 552px 0 #e6ccac, 312px 552px 0 #e6ccac,
    320px 552px 0 #e4b38e, 328px 552px 0 #e4b38e, 336px 552px 0 #ca6948,
    344px 552px 0 #ca6948, 352px 552px 0 #ca6948, 360px 552px 0 #e0774a,
    368px 552px 0 #e0774a, 376px 552px 0 #e0774a, 384px 552px 0 #e0774a,
    392px 552px 0 #e0774a, 400px 552px 0 #e0774a, 408px 552px 0 #e0774a,
    416px 552px 0 #e0774a, 424px 552px 0 #e0774a, 432px 552px 0 #e0774a,
    440px 552px 0 #e0774a, 448px 552px 0 #e0774a, 456px 552px 0 #e0774a,
    464px 552px 0 #dd583b, 472px 552px 0 #dd583b, 480px 552px 0 #dd583b,
    488px 552px 0 #dd583b, 496px 552px 0 #dd583b, 504px 552px 0 #bf4f37,
    512px 552px 0 #bf4f37, 520px 552px 0 #dd583b, 528px 552px 0 #dd583b,
    536px 552px 0 #dd583b, 544px 552px 0 #dd583b, 552px 552px 0 #dd583b,
    560px 552px 0 #dd583b, 608px 552px 0 #ccb28a, 616px 552px 0 #ccb28a,
    624px 552px 0 #ccb28a, 632px 552px 0 #ccb28a, 640px 552px 0 #ccb28a,
    648px 552px 0 #ccb28a, 656px 552px 0 #ccb28a, 664px 552px 0 #ccb28a,
    672px 552px 0 #ccb28a, 680px 552px 0 #ccb28a, 688px 552px 0 #ccb28a,
    696px 552px 0 #a78f69, 704px 552px 0 #a78f69, 712px 552px 0 #ccb28a,
    720px 552px 0 #ccb28a, 728px 552px 0 #ccb28a, 736px 552px 0 #ccb28a,
    744px 552px 0 #a78f69, 752px 552px 0 #ccb28a, 760px 552px 0 #ccb28a,
    768px 552px 0 #ccb28a, 776px 552px 0 #ccb28a, 784px 552px 0 #ccb28a,
    792px 552px 0 #ccb28a, 288px 560px 0 #e4b38e, 296px 560px 0 #e6ccac,
    304px 560px 0 #e6ccac, 312px 560px 0 #e6ccac, 320px 560px 0 #4d4657,
    328px 560px 0 #4d4657, 336px 560px 0 #ca6948, 344px 560px 0 #ca6948,
    352px 560px 0 #e0774a, 360px 560px 0 #ca6948, 368px 560px 0 #ca6948,
    376px 560px 0 #ca6948, 384px 560px 0 #ca6948, 392px 560px 0 #ca6948,
    400px 560px 0 #e0774a, 408px 560px 0 #e0774a, 416px 560px 0 #e0774a,
    424px 560px 0 #e0774a, 432px 560px 0 #e0774a, 440px 560px 0 #e0774a,
    448px 560px 0 #e0774a, 456px 560px 0 #e0774a, 464px 560px 0 #dd583b,
    472px 560px 0 #dd583b, 480px 560px 0 #dd583b, 488px 560px 0 #dd583b,
    496px 560px 0 #bf4f37, 504px 560px 0 #dd583b, 512px 560px 0 #dd583b,
    520px 560px 0 #dd583b, 528px 560px 0 #dd583b, 536px 560px 0 #dd583b,
    544px 560px 0 #dd583b, 552px 560px 0 #dd583b, 560px 560px 0 #dd583b,
    600px 560px 0 #ccb28a, 608px 560px 0 #ccb28a, 616px 560px 0 #ccb28a,
    624px 560px 0 #ccb28a, 632px 560px 0 #ccb28a, 640px 560px 0 #ccb28a,
    648px 560px 0 #ccb28a, 656px 560px 0 #ccb28a, 664px 560px 0 #ccb28a,
    672px 560px 0 #ccb28a, 680px 560px 0 #a78f69, 688px 560px 0 #a78f69,
    696px 560px 0 #ccb28a, 704px 560px 0 #ccb28a, 712px 560px 0 #ccb28a,
    720px 560px 0 #a78f69, 728px 560px 0 #a78f69, 736px 560px 0 #a78f69,
    744px 560px 0 #ccb28a, 752px 560px 0 #ccb28a, 760px 560px 0 #ccb28a,
    768px 560px 0 #ccb28a, 776px 560px 0 #ccb28a, 176px 568px 0 #0b0b09,
    288px 568px 0 #e4b38e, 296px 568px 0 #e6ccac, 304px 568px 0 #4d4657,
    312px 568px 0 #4d4657, 320px 568px 0 #4d4657, 328px 568px 0 #4d4657,
    336px 568px 0 #ca6948, 344px 568px 0 #ca6948, 352px 568px 0 #e0774a,
    360px 568px 0 #e0774a, 368px 568px 0 #ca6948, 376px 568px 0 #e0774a,
    384px 568px 0 #e0774a, 392px 568px 0 #e0774a, 400px 568px 0 #ca6948,
    408px 568px 0 #ca6948, 416px 568px 0 #e0774a, 424px 568px 0 #e0774a,
    432px 568px 0 #e0774a, 440px 568px 0 #e0774a, 448px 568px 0 #e0774a,
    456px 568px 0 #e0774a, 464px 568px 0 #dd583b, 472px 568px 0 #dd583b,
    480px 568px 0 #bf4f37, 488px 568px 0 #bf4f37, 496px 568px 0 #dd583b,
    504px 568px 0 #dd583b, 512px 568px 0 #dd583b, 520px 568px 0 #dd583b,
    528px 568px 0 #dd583b, 536px 568px 0 #dd583b, 544px 568px 0 #dd583b,
    552px 568px 0 #dd583b, 560px 568px 0 #dd583b, 584px 568px 0 #ccb28a,
    592px 568px 0 #ccb28a, 600px 568px 0 #ccb28a, 608px 568px 0 #ccb28a,
    616px 568px 0 #ccb28a, 624px 568px 0 #ccb28a, 632px 568px 0 #ccb28a,
    640px 568px 0 #ccb28a, 648px 568px 0 #ccb28a, 656px 568px 0 #ccb28a,
    664px 568px 0 #a78f69, 672px 568px 0 #a78f69, 680px 568px 0 #a78f69,
    688px 568px 0 #ccb28a, 696px 568px 0 #ccb28a, 704px 568px 0 #ccb28a,
    712px 568px 0 #a78f69, 720px 568px 0 #a78f69, 728px 568px 0 #a78f69,
    736px 568px 0 #a78f69, 744px 568px 0 #a78f69, 752px 568px 0 #a78f69,
    760px 568px 0 #a78f69, 168px 576px 0 #c7bfcf, 176px 576px 0 #0b0b09,
    208px 576px 0 #0b0b09, 288px 576px 0 #4d4657, 296px 576px 0 #4d4657,
    304px 576px 0 #4d4657, 312px 576px 0 #4d4657, 320px 576px 0 #4d4657,
    328px 576px 0 #4d4657, 336px 576px 0 #4d4657, 344px 576px 0 #ca6948,
    352px 576px 0 #e0774a, 360px 576px 0 #e0774a, 368px 576px 0 #e0774a,
    376px 576px 0 #ca6948, 384px 576px 0 #e0774a, 392px 576px 0 #e0774a,
    400px 576px 0 #e0774a, 408px 576px 0 #e0774a, 416px 576px 0 #ca6948,
    424px 576px 0 #ca6948, 432px 576px 0 #e0774a, 440px 576px 0 #e0774a,
    448px 576px 0 #e0774a, 456px 576px 0 #bf4f37, 464px 576px 0 #bf4f37,
    472px 576px 0 #bf4f37, 480px 576px 0 #dd583b, 488px 576px 0 #dd583b,
    496px 576px 0 #dd583b, 504px 576px 0 #dd583b, 512px 576px 0 #dd583b,
    520px 576px 0 #dd583b, 528px 576px 0 #dd583b, 536px 576px 0 #dd583b,
    544px 576px 0 #dd583b, 552px 576px 0 #dd583b, 560px 576px 0 #dd583b,
    576px 576px 0 #ccb28a, 584px 576px 0 #ccb28a, 592px 576px 0 #ccb28a,
    600px 576px 0 #ccb28a, 608px 576px 0 #ccb28a, 616px 576px 0 #ccb28a,
    624px 576px 0 #ccb28a, 632px 576px 0 #ccb28a, 640px 576px 0 #ccb28a,
    648px 576px 0 #a78f69, 656px 576px 0 #a78f69, 664px 576px 0 #a78f69,
    672px 576px 0 #a78f69, 680px 576px 0 #a78f69, 688px 576px 0 #a78f69,
    696px 576px 0 #a78f69, 704px 576px 0 #a78f69, 712px 576px 0 #a78f69,
    720px 576px 0 #a78f69, 728px 576px 0 #a78f69, 736px 576px 0 #a78f69,
    744px 576px 0 #a78f69, 752px 576px 0 #a78f69, 760px 576px 0 #a78f69,
    768px 576px 0 #a78f69, 776px 576px 0 #a78f69, 784px 576px 0 #a78f69,
    792px 576px 0 #a78f69, 168px 584px 0 #c7bfcf, 176px 584px 0 #0b0b09,
    192px 584px 0 #0b0b09, 200px 584px 0 #0b0b09, 208px 584px 0 #c7bfcf,
    272px 584px 0 #4d4657, 280px 584px 0 #4d4657, 288px 584px 0 #4d4657,
    296px 584px 0 #4d4657, 304px 584px 0 #4d4657, 312px 584px 0 #4d4657,
    320px 584px 0 #4d4657, 328px 584px 0 #4d4657, 336px 584px 0 #4d4657,
    344px 584px 0 #4d4657, 352px 584px 0 #ca6948, 360px 584px 0 #e0774a,
    368px 584px 0 #e0774a, 376px 584px 0 #e0774a, 384px 584px 0 #e0774a,
    392px 584px 0 #e0774a, 400px 584px 0 #e0774a, 408px 584px 0 #e0774a,
    416px 584px 0 #e0774a, 424px 584px 0 #ca6948, 432px 584px 0 #ca6948,
    440px 584px 0 #ca6948, 448px 584px 0 #ca6948, 456px 584px 0 #dd583b,
    464px 584px 0 #dd583b, 472px 584px 0 #dd583b, 480px 584px 0 #dd583b,
    488px 584px 0 #dd583b, 496px 584px 0 #dd583b, 504px 584px 0 #dd583b,
    512px 584px 0 #dd583b, 520px 584px 0 #dd583b, 528px 584px 0 #dd583b,
    536px 584px 0 #dd583b, 544px 584px 0 #dd583b, 552px 584px 0 #dd583b,
    560px 584px 0 #dd583b, 568px 584px 0 #ccb28a, 576px 584px 0 #ccb28a,
    584px 584px 0 #ccb28a, 592px 584px 0 #ccb28a, 600px 584px 0 #ccb28a,
    608px 584px 0 #ccb28a, 616px 584px 0 #a78f69, 624px 584px 0 #a78f69,
    632px 584px 0 #a78f69, 640px 584px 0 #a78f69, 648px 584px 0 #a78f69,
    656px 584px 0 #a78f69, 664px 584px 0 #a78f69, 672px 584px 0 #a78f69,
    680px 584px 0 #a78f69, 688px 584px 0 #a78f69, 696px 584px 0 #a78f69,
    704px 584px 0 #a78f69, 712px 584px 0 #a78f69, 720px 584px 0 #a78f69,
    728px 584px 0 #a78f69, 736px 584px 0 #a78f69, 744px 584px 0 #a78f69,
    752px 584px 0 #a78f69, 760px 584px 0 #a78f69, 768px 584px 0 #a78f69,
    168px 592px 0 #0b0b09, 176px 592px 0 #0b0b09, 184px 592px 0 #0b0b09,
    192px 592px 0 #0b0b09, 200px 592px 0 #c7bfcf, 264px 592px 0 #4d4657,
    272px 592px 0 #4d4657, 280px 592px 0 #4d4657, 288px 592px 0 #4d4657,
    296px 592px 0 #4d4657, 304px 592px 0 #4d4657, 312px 592px 0 #4d4657,
    320px 592px 0 #4d4657, 328px 592px 0 #4d4657, 336px 592px 0 #4d4657,
    344px 592px 0 #4d4657, 352px 592px 0 #ca6948, 360px 592px 0 #e0774a,
    368px 592px 0 #e0774a, 376px 592px 0 #e0774a, 384px 592px 0 #e0774a,
    392px 592px 0 #e0774a, 400px 592px 0 #e0774a, 408px 592px 0 #e0774a,
    416px 592px 0 #e0774a, 424px 592px 0 #e0774a, 432px 592px 0 #e0774a,
    440px 592px 0 #e0774a, 448px 592px 0 #dd583b, 456px 592px 0 #dd583b,
    464px 592px 0 #dd583b, 472px 592px 0 #dd583b, 480px 592px 0 #dd583b,
    488px 592px 0 #dd583b, 496px 592px 0 #dd583b, 504px 592px 0 #dd583b,
    512px 592px 0 #dd583b, 520px 592px 0 #dd583b, 528px 592px 0 #dd583b,
    536px 592px 0 #dd583b, 544px 592px 0 #dd583b, 552px 592px 0 #dd583b,
    560px 592px 0 #ccb28a, 568px 592px 0 #ccb28a, 576px 592px 0 #ccb28a,
    584px 592px 0 #ccb28a, 592px 592px 0 #a68e69, 600px 592px 0 #a78f69,
    608px 592px 0 #a78f69, 616px 592px 0 #a78f69, 624px 592px 0 #a78f69,
    632px 592px 0 #a78f69, 640px 592px 0 #a78f69, 648px 592px 0 #a78f69,
    656px 592px 0 #a78f69, 664px 592px 0 #a78f69, 672px 592px 0 #a78f69,
    680px 592px 0 #a78f69, 688px 592px 0 #a78f69, 696px 592px 0 #8b7757,
    704px 592px 0 #8b7757, 712px 592px 0 #8b7757, 720px 592px 0 #8b7757,
    728px 592px 0 #8b7757, 736px 592px 0 #8b7757, 744px 592px 0 #a78f69,
    752px 592px 0 #a78f69, 760px 592px 0 #a78f69, 768px 592px 0 #a78f69,
    776px 592px 0 #a78f69, 784px 592px 0 #a78f69, 792px 592px 0 #a78f69,
    800px 592px 0 #a78f69, 160px 600px 0 #0b0b09, 168px 600px 0 #0b0b09,
    176px 600px 0 #0b0b09, 184px 600px 0 #0b0b09, 192px 600px 0 #0b0b09,
    200px 600px 0 #0b0b09, 256px 600px 0 #4d4657, 264px 600px 0 #4d4657,
    272px 600px 0 #4d4657, 280px 600px 0 #4d4657, 288px 600px 0 #4d4657,
    296px 600px 0 #4d4657, 304px 600px 0 #4d4657, 312px 600px 0 #4d4657,
    320px 600px 0 #4d4657, 328px 600px 0 #4d4657, 336px 600px 0 #4d4657,
    344px 600px 0 #4d4657, 352px 600px 0 #e0774a, 360px 600px 0 #e0774a,
    368px 600px 0 #e0774a, 376px 600px 0 #e0774a, 384px 600px 0 #e0774a,
    392px 600px 0 #e0774a, 400px 600px 0 #e0774a, 408px 600px 0 #e0774a,
    416px 600px 0 #e0774a, 424px 600px 0 #e0774a, 432px 600px 0 #e0774a,
    440px 600px 0 #e0774a, 448px 600px 0 #dd583b, 456px 600px 0 #dd583b,
    464px 600px 0 #dd583b, 472px 600px 0 #dd583b, 480px 600px 0 #dd583b,
    488px 600px 0 #dd583b, 496px 600px 0 #dd583b, 504px 600px 0 #dd583b,
    512px 600px 0 #dd583b, 520px 600px 0 #dd583b, 528px 600px 0 #dd583b,
    536px 600px 0 #dd583b, 544px 600px 0 #dd583b, 552px 600px 0 #dd583b,
    560px 600px 0 #ccb28a, 568px 600px 0 #ccb28a, 576px 600px 0 #a78f69,
    584px 600px 0 #a78f69, 592px 600px 0 #a68e69, 600px 600px 0 #8b7757,
    608px 600px 0 #8b7757, 616px 600px 0 #8b7757, 624px 600px 0 #a78f69,
    632px 600px 0 #a78f69, 640px 600px 0 #a78f69, 648px 600px 0 #a78f69,
    656px 600px 0 #a78f69, 664px 600px 0 #a78f69, 672px 600px 0 #a78f69,
    680px 600px 0 #a78f69, 688px 600px 0 #a78f69, 696px 600px 0 #a78f69,
    704px 600px 0 #a78f69, 712px 600px 0 #a78f69, 720px 600px 0 #a78f69,
    728px 600px 0 #a78f69, 736px 600px 0 #a78f69, 744px 600px 0 #a78f69,
    752px 600px 0 #a78f69, 760px 600px 0 #a78f69, 160px 608px 0 #f1eeeb,
    168px 608px 0 #f1eeeb, 176px 608px 0 #0b0b09, 184px 608px 0 #f1eeeb,
    192px 608px 0 #f1eeeb, 200px 608px 0 #0b0b09, 248px 608px 0 #4d4657,
    256px 608px 0 #4d4657, 264px 608px 0 #4d4657, 272px 608px 0 #4d4657,
    280px 608px 0 #4d4657, 288px 608px 0 #4d4657, 296px 608px 0 #4d4657,
    304px 608px 0 #4d4657, 312px 608px 0 #4d4657, 320px 608px 0 #4d4657,
    328px 608px 0 #4d4657, 336px 608px 0 #4d4657, 344px 608px 0 #2d282e,
    352px 608px 0 #2d282e, 360px 608px 0 #e0774a, 368px 608px 0 #e0774a,
    376px 608px 0 #e0774a, 384px 608px 0 #e0774a, 392px 608px 0 #e0774a,
    400px 608px 0 #e0774a, 408px 608px 0 #e0774a, 416px 608px 0 #e0774a,
    424px 608px 0 #e0774a, 432px 608px 0 #e0774a, 440px 608px 0 #e0774a,
    448px 608px 0 #dd583b, 456px 608px 0 #dd583b, 464px 608px 0 #dd583b,
    472px 608px 0 #dd583b, 480px 608px 0 #dd583b, 488px 608px 0 #dd583b,
    496px 608px 0 #dd583b, 504px 608px 0 #dd583b, 512px 608px 0 #dd583b,
    520px 608px 0 #dd583b, 528px 608px 0 #dd583b, 536px 608px 0 #dd583b,
    544px 608px 0 #dd583b, 552px 608px 0 #ccb28a, 560px 608px 0 #a78f69,
    568px 608px 0 #a78f69, 576px 608px 0 #a78f69, 584px 608px 0 #a78f69,
    592px 608px 0 #a78f69, 600px 608px 0 #a78f69, 608px 608px 0 #a78f69,
    616px 608px 0 #a78f69, 624px 608px 0 #a78f69, 632px 608px 0 #a78f69,
    640px 608px 0 #a78f69, 648px 608px 0 #a78f69, 656px 608px 0 #a78f69,
    664px 608px 0 #a78f69, 672px 608px 0 #a78f69, 680px 608px 0 #a78f69,
    688px 608px 0 #a78f69, 696px 608px 0 #a78f69, 704px 608px 0 #a78f69,
    712px 608px 0 #a78f69, 720px 608px 0 #a78f69, 728px 608px 0 #a78f69,
    736px 608px 0 #a78f69, 744px 608px 0 #a78f69, 752px 608px 0 #a78f69,
    760px 608px 0 #a78f69, 768px 608px 0 #a78f69, 776px 608px 0 #a78f69,
    160px 616px 0 #0b0b09, 168px 616px 0 #0b0b09, 176px 616px 0 #0b0b09,
    184px 616px 0 #0b0b09, 192px 616px 0 #0b0b09, 200px 616px 0 #0b0b09,
    240px 616px 0 #4d4657, 248px 616px 0 #4d4657, 256px 616px 0 #4d4657,
    264px 616px 0 #4d4657, 272px 616px 0 #4d4657, 280px 616px 0 #4d4657,
    288px 616px 0 #4d4657, 296px 616px 0 #4d4657, 304px 616px 0 #4d4657,
    312px 616px 0 #4d4657, 320px 616px 0 #4d4657, 328px 616px 0 #4d4657,
    336px 616px 0 #2d282e, 344px 616px 0 #2d282e, 352px 616px 0 #2d282e,
    360px 616px 0 #e0774a, 368px 616px 0 #e0774a, 376px 616px 0 #e0774a,
    384px 616px 0 #e0774a, 392px 616px 0 #e0774a, 400px 616px 0 #e0774a,
    408px 616px 0 #e0774a, 416px 616px 0 #e0774a, 424px 616px 0 #e0774a,
    432px 616px 0 #e0774a, 440px 616px 0 #e0774a, 448px 616px 0 #dd583b,
    456px 616px 0 #dd583b, 464px 616px 0 #dd583b, 472px 616px 0 #dd583b,
    480px 616px 0 #dd583b, 488px 616px 0 #dd583b, 496px 616px 0 #dd583b,
    504px 616px 0 #dd583b, 512px 616px 0 #dd583b, 520px 616px 0 #dd583b,
    528px 616px 0 #dd583b, 536px 616px 0 #dd583b, 544px 616px 0 #a78f69,
    552px 616px 0 #a78f69, 560px 616px 0 #a78f69, 568px 616px 0 #a78f69,
    576px 616px 0 #a78f69, 584px 616px 0 #a78f69, 592px 616px 0 #a78f69,
    600px 616px 0 #a78f69, 608px 616px 0 #a78f69, 616px 616px 0 #a78f69,
    624px 616px 0 #a78f69, 632px 616px 0 #a78f69, 640px 616px 0 #a78f69,
    648px 616px 0 #a78f69, 656px 616px 0 #a78f69, 664px 616px 0 #a78f69,
    672px 616px 0 #a78f69, 680px 616px 0 #a78f69, 688px 616px 0 #a78f69,
    696px 616px 0 #a78f69, 704px 616px 0 #a78f69, 712px 616px 0 #a78f69,
    720px 616px 0 #8b7757, 728px 616px 0 #a78f69, 736px 616px 0 #a78f69,
    152px 624px 0 #0b0b09, 160px 624px 0 #0b0b09, 168px 624px 0 #0b0b09,
    176px 624px 0 #0b0b09, 184px 624px 0 #0b0b09, 192px 624px 0 #0b0b09,
    200px 624px 0 #0b0b09, 240px 624px 0 #4d4657, 248px 624px 0 #4d4657,
    256px 624px 0 #4d4657, 264px 624px 0 #4d4657, 272px 624px 0 #4d4657,
    280px 624px 0 #4d4657, 288px 624px 0 #4d4657, 296px 624px 0 #4d4657,
    304px 624px 0 #4d4657, 312px 624px 0 #4d4657, 320px 624px 0 #1e1a1f,
    328px 624px 0 #2d282e, 336px 624px 0 #2d282e, 344px 624px 0 #2d282e,
    352px 624px 0 #2d282e, 360px 624px 0 #2d282e, 368px 624px 0 #e0774a,
    376px 624px 0 #e0774a, 384px 624px 0 #e0774a, 392px 624px 0 #e0774a,
    400px 624px 0 #e0774a, 408px 624px 0 #e0774a, 416px 624px 0 #e0774a,
    424px 624px 0 #e0774a, 432px 624px 0 #e0774a, 440px 624px 0 #e0774a,
    448px 624px 0 #dd583b, 456px 624px 0 #dd583b, 464px 624px 0 #dd583b,
    472px 624px 0 #dd583b, 480px 624px 0 #dd583b, 488px 624px 0 #dd583b,
    496px 624px 0 #dd583b, 504px 624px 0 #dd583b, 512px 624px 0 #dd583b,
    520px 624px 0 #dd583b, 528px 624px 0 #dd583b, 536px 624px 0 #a78f69,
    544px 624px 0 #a78f69, 552px 624px 0 #a78f69, 560px 624px 0 #a78f69,
    568px 624px 0 #a78f69, 576px 624px 0 #a78f69, 584px 624px 0 #a78f69,
    592px 624px 0 #a78f69, 600px 624px 0 #a78f69, 608px 624px 0 #a78f69,
    616px 624px 0 #a78f69, 624px 624px 0 #a78f69, 632px 624px 0 #a78f69,
    640px 624px 0 #a78f69, 648px 624px 0 #a78f69, 656px 624px 0 #a78f69,
    664px 624px 0 #a78f69, 672px 624px 0 #8b7757, 680px 624px 0 #8b7757,
    688px 624px 0 #8b7757, 696px 624px 0 #8b7757, 704px 624px 0 #8b7757,
    712px 624px 0 #8b7757, 720px 624px 0 #a78f69, 728px 624px 0 #a78f69,
    736px 624px 0 #a78f69, 744px 624px 0 #a78f69, 752px 624px 0 #a78f69,
    760px 624px 0 #a78f69, 144px 632px 0 #0b0b09, 152px 632px 0 #0b0b09,
    160px 632px 0 #0b0b09, 168px 632px 0 #0b0b09, 176px 632px 0 #0b0b09,
    184px 632px 0 #0b0b09, 192px 632px 0 #0b0b09, 200px 632px 0 #0b0b09,
    208px 632px 0 #0b0b09, 240px 632px 0 #4d4657, 248px 632px 0 #4d4657,
    256px 632px 0 #4d4657, 264px 632px 0 #4d4657, 272px 632px 0 #4d4657,
    280px 632px 0 #4d4657, 288px 632px 0 #2d282e, 296px 632px 0 #2d282e,
    304px 632px 0 #2d282e, 312px 632px 0 #2d282e, 320px 632px 0 #2d282e,
    328px 632px 0 #1e1a1f, 336px 632px 0 #1e1a1f, 344px 632px 0 #1e1a1f,
    352px 632px 0 #2d282e, 360px 632px 0 #2d282e, 368px 632px 0 #e0774a,
    376px 632px 0 #e0774a, 384px 632px 0 #e0774a, 392px 632px 0 #e0774a,
    400px 632px 0 #e0774a, 408px 632px 0 #e0774a, 416px 632px 0 #e0774a,
    424px 632px 0 #e0774a, 432px 632px 0 #e0774a, 440px 632px 0 #e0774a,
    448px 632px 0 #dd583b, 456px 632px 0 #dd583b, 464px 632px 0 #dd583b,
    472px 632px 0 #dd583b, 480px 632px 0 #dd583b, 488px 632px 0 #dd583b,
    496px 632px 0 #dd583b, 504px 632px 0 #dd583b, 512px 632px 0 #dd583b,
    520px 632px 0 #dd583b, 528px 632px 0 #dd583b, 536px 632px 0 #a78f69,
    544px 632px 0 #a78f69, 552px 632px 0 #a78f69, 560px 632px 0 #a78f69,
    568px 632px 0 #a78f69, 576px 632px 0 #a78f69, 584px 632px 0 #a78f69,
    592px 632px 0 #a78f69, 600px 632px 0 #a78f69, 608px 632px 0 #a78f69,
    616px 632px 0 #a78f69, 624px 632px 0 #a78f69, 632px 632px 0 #a78f69,
    640px 632px 0 #a78f69, 648px 632px 0 #a78f69, 656px 632px 0 #a78f69,
    664px 632px 0 #a78f69, 672px 632px 0 #a78f69, 680px 632px 0 #a78f69,
    688px 632px 0 #a78f69, 696px 632px 0 #a78f69, 704px 632px 0 #a78f69,
    712px 632px 0 #a78f69, 720px 632px 0 #a78f69, 728px 632px 0 #a78f69,
    736px 632px 0 #a78f69, 744px 632px 0 #a78f69, 752px 632px 0 #a78f69,
    760px 632px 0 #a78f69, 768px 632px 0 #a78f69, 776px 632px 0 #a78f69,
    784px 632px 0 #a78f69, 792px 632px 0 #a78f69, 800px 632px 0 #a78f69,
    144px 640px 0 #0b0b09, 152px 640px 0 #0b0b09, 160px 640px 0 #0b0b09,
    168px 640px 0 #0b0b09, 176px 640px 0 #0b0b09, 184px 640px 0 #0b0b09,
    192px 640px 0 #0b0b09, 200px 640px 0 #b39361, 208px 640px 0 #b39361,
    216px 640px 0 #b84531, 224px 640px 0 #b39361, 232px 640px 0 #b39361,
    240px 640px 0 #4d4657, 248px 640px 0 #4d4657, 256px 640px 0 #4d4657,
    264px 640px 0 #4d4657, 272px 640px 0 #2d282e, 280px 640px 0 #2d282e,
    288px 640px 0 #2d282e, 296px 640px 0 #2d282e, 304px 640px 0 #2d282e,
    312px 640px 0 #2d282e, 320px 640px 0 #2d282e, 328px 640px 0 #2d282e,
    336px 640px 0 #2d282e, 344px 640px 0 #1e1a1f, 352px 640px 0 #1e1a1f,
    360px 640px 0 #2d282e, 368px 640px 0 #2d282e, 376px 640px 0 #e0774a,
    384px 640px 0 #e0774a, 392px 640px 0 #e0774a, 400px 640px 0 #e0774a,
    408px 640px 0 #e0774a, 416px 640px 0 #e0774a, 424px 640px 0 #e0774a,
    432px 640px 0 #e0774a, 440px 640px 0 #e0774a, 448px 640px 0 #dd583b,
    456px 640px 0 #dd583b, 464px 640px 0 #dd583b, 472px 640px 0 #dd583b,
    480px 640px 0 #dd583b, 488px 640px 0 #dd583b, 496px 640px 0 #dd583b,
    504px 640px 0 #dd583b, 512px 640px 0 #dd583b, 520px 640px 0 #dd583b,
    528px 640px 0 #8b7757, 536px 640px 0 #8b7757, 544px 640px 0 #8b7757,
    552px 640px 0 #8b7757, 560px 640px 0 #a78f69, 568px 640px 0 #a78f69,
    576px 640px 0 #a78f69, 584px 640px 0 #a78f69, 592px 640px 0 #a78f69,
    600px 640px 0 #a78f69, 608px 640px 0 #a78f69, 616px 640px 0 #a78f69,
    624px 640px 0 #a78f69, 632px 640px 0 #a78f69, 640px 640px 0 #a78f69,
    648px 640px 0 #a78f69, 656px 640px 0 #a78f69, 664px 640px 0 #a78f69,
    672px 640px 0 #a78f69, 680px 640px 0 #a78f69, 688px 640px 0 #a78f69,
    696px 640px 0 #a78f69, 704px 640px 0 #a78f69, 712px 640px 0 #a78f69,
    720px 640px 0 #a78f69, 728px 640px 0 #a78f69, 736px 640px 0 #a78f69,
    744px 640px 0 #a78f69, 752px 640px 0 #a78f69, 760px 640px 0 #8b7757,
    768px 640px 0 #8b7757, 776px 640px 0 #8b7757, 144px 648px 0 #b39361,
    152px 648px 0 #b39361, 160px 648px 0 #b39361, 168px 648px 0 #b39361,
    176px 648px 0 #b39361, 184px 648px 0 #b39361, 192px 648px 0 #b39361,
    200px 648px 0 #b4945e, 208px 648px 0 #b4945e, 216px 648px 0 #b4945e,
    224px 648px 0 #b84531, 232px 648px 0 #b4945e, 240px 648px 0 #4d4657,
    248px 648px 0 #4d4657, 256px 648px 0 #4d4657, 264px 648px 0 #2d282e,
    272px 648px 0 #2d282e, 280px 648px 0 #2d282e, 288px 648px 0 #2d282e,
    296px 648px 0 #2d282e, 304px 648px 0 #2d282e, 312px 648px 0 #2d282e,
    320px 648px 0 #2d282e, 328px 648px 0 #2d282e, 336px 648px 0 #2d282e,
    344px 648px 0 #2d282e, 352px 648px 0 #2d282e, 360px 648px 0 #1e1a1f,
    368px 648px 0 #2d282e, 376px 648px 0 #e0774a, 384px 648px 0 #e0774a,
    392px 648px 0 #e0774a, 400px 648px 0 #e0774a, 408px 648px 0 #e0774a,
    416px 648px 0 #e0774a, 424px 648px 0 #e0774a, 432px 648px 0 #e0774a,
    440px 648px 0 #dd583b, 448px 648px 0 #dd583b, 456px 648px 0 #dd583b,
    464px 648px 0 #dd583b, 472px 648px 0 #dd583b, 480px 648px 0 #dd583b,
    488px 648px 0 #dd583b, 496px 648px 0 #dd583b, 504px 648px 0 #dd583b,
    512px 648px 0 #dd583b, 520px 648px 0 #a78f69, 528px 648px 0 #a78f69,
    536px 648px 0 #a78f69, 544px 648px 0 #a78f69, 552px 648px 0 #a78f69,
    560px 648px 0 #a78f69, 568px 648px 0 #a78f69, 576px 648px 0 #a78f69,
    584px 648px 0 #a78f69, 592px 648px 0 #a78f69, 600px 648px 0 #a78f69,
    608px 648px 0 #a78f69, 616px 648px 0 #a78f69, 624px 648px 0 #a78f69,
    632px 648px 0 #a78f69, 640px 648px 0 #a78f69, 648px 648px 0 #a78f69,
    656px 648px 0 #a78f69, 664px 648px 0 #a78f69, 672px 648px 0 #a78f69,
    680px 648px 0 #a78f69, 688px 648px 0 #a78f69, 696px 648px 0 #a78f69,
    704px 648px 0 #a78f69, 712px 648px 0 #a78f69, 720px 648px 0 #a78f69,
    728px 648px 0 #a78f69, 736px 648px 0 #a78f69, 744px 648px 0 #8b7757,
    752px 648px 0 #8b7757, 112px 656px 0 #b39361, 120px 656px 0 #b39361,
    128px 656px 0 #b39361, 136px 656px 0 #b39361, 144px 656px 0 #b4945e,
    152px 656px 0 #b4945e, 160px 656px 0 #b4945e, 168px 656px 0 #b4945e,
    176px 656px 0 #b4945e, 184px 656px 0 #b4945e, 192px 656px 0 #b4945e,
    200px 656px 0 #aa8666, 208px 656px 0 #aa8666, 216px 656px 0 #aa8666,
    224px 656px 0 #b84531, 232px 656px 0 #aa8666, 240px 656px 0 #4d4657,
    248px 656px 0 #4d4657, 256px 656px 0 #2d282e, 264px 656px 0 #2d282e,
    272px 656px 0 #2d282e, 280px 656px 0 #2d282e, 288px 656px 0 #2d282e,
    296px 656px 0 #2d282e, 304px 656px 0 #2d282e, 312px 656px 0 #2d282e,
    320px 656px 0 #2d282e, 328px 656px 0 #2d282e, 336px 656px 0 #e4b38e,
    344px 656px 0 #e4b38e, 352px 656px 0 #e4b38e, 360px 656px 0 #1e1a1f,
    368px 656px 0 #2d282e, 376px 656px 0 #2d282e, 384px 656px 0 #e0774a,
    392px 656px 0 #e0774a, 400px 656px 0 #e0774a, 408px 656px 0 #e0774a,
    416px 656px 0 #e0774a, 424px 656px 0 #e0774a, 432px 656px 0 #dd583b,
    440px 656px 0 #dd583b, 448px 656px 0 #dd583b, 456px 656px 0 #dd583b,
    464px 656px 0 #dd583b, 472px 656px 0 #dd583b, 480px 656px 0 #dd583b,
    488px 656px 0 #dd583b, 496px 656px 0 #dd583b, 504px 656px 0 #dd583b,
    512px 656px 0 #a78f69, 520px 656px 0 #a78f69, 528px 656px 0 #a78f69,
    536px 656px 0 #a78f69, 544px 656px 0 #a78f69, 552px 656px 0 #a78f69,
    560px 656px 0 #a78f69, 568px 656px 0 #a78f69, 576px 656px 0 #a78f69,
    584px 656px 0 #a78f69, 592px 656px 0 #a78f69, 600px 656px 0 #a78f69,
    608px 656px 0 #a78f69, 616px 656px 0 #a78f69, 624px 656px 0 #a78f69,
    632px 656px 0 #a78f69, 640px 656px 0 #a78f69, 648px 656px 0 #a78f69,
    656px 656px 0 #a78f69, 664px 656px 0 #a78f69, 672px 656px 0 #a78f69,
    680px 656px 0 #a78f69, 688px 656px 0 #a78f69, 696px 656px 0 #a78f69,
    704px 656px 0 #8b7757, 712px 656px 0 #8b7757, 720px 656px 0 #a78f69,
    728px 656px 0 #a78f69, 736px 656px 0 #a78f69, 744px 656px 0 #a78f69,
    72px 664px 0 #b39361, 80px 664px 0 #b39361, 88px 664px 0 #b39361,
    96px 664px 0 #b39361, 104px 664px 0 #b39361, 112px 664px 0 #b4945e,
    120px 664px 0 #b4945e, 128px 664px 0 #b4945e, 136px 664px 0 #b4945e,
    144px 664px 0 #aa8666, 152px 664px 0 #aa8666, 160px 664px 0 #aa8666,
    168px 664px 0 #aa8666, 176px 664px 0 #aa8666, 184px 664px 0 #aa8666,
    192px 664px 0 #aa8666, 224px 664px 0 #b84531, 248px 664px 0 #4d4657,
    256px 664px 0 #2d282e, 264px 664px 0 #2d282e, 272px 664px 0 #2d282e,
    280px 664px 0 #2d282e, 288px 664px 0 #2d282e, 296px 664px 0 #2d282e,
    304px 664px 0 #2d282e, 312px 664px 0 #2d282e, 320px 664px 0 #2d282e,
    328px 664px 0 #e4b38e, 336px 664px 0 #e4b38e, 344px 664px 0 #e4b38e,
    352px 664px 0 #e4b38e, 360px 664px 0 #e4b38e, 368px 664px 0 #e4b38e,
    376px 664px 0 #e4b38e, 384px 664px 0 #e4b38e, 392px 664px 0 #e0774a,
    400px 664px 0 #e0774a, 408px 664px 0 #e0774a, 416px 664px 0 #e0774a,
    424px 664px 0 #dd583b, 432px 664px 0 #dd583b, 440px 664px 0 #dd583b,
    448px 664px 0 #dd583b, 456px 664px 0 #dd583b, 464px 664px 0 #dd583b,
    472px 664px 0 #dd583b, 480px 664px 0 #dd583b, 488px 664px 0 #dd583b,
    496px 664px 0 #dd583b, 504px 664px 0 #a78f69, 512px 664px 0 #a78f69,
    520px 664px 0 #a78f69, 528px 664px 0 #a78f69, 536px 664px 0 #a78f69,
    544px 664px 0 #a78f69, 552px 664px 0 #a78f69, 560px 664px 0 #a78f69,
    568px 664px 0 #a78f69, 576px 664px 0 #a78f69, 584px 664px 0 #a78f69,
    592px 664px 0 #a78f69, 600px 664px 0 #a78f69, 608px 664px 0 #a78f69,
    616px 664px 0 #a78f69, 624px 664px 0 #a78f69, 632px 664px 0 #a78f69,
    640px 664px 0 #a78f69, 648px 664px 0 #a78f69, 656px 664px 0 #8b7757,
    664px 664px 0 #8b7757, 672px 664px 0 #8b7757, 680px 664px 0 #8b7757,
    688px 664px 0 #8b7757, 696px 664px 0 #8b7757, 704px 664px 0 #a78f69,
    712px 664px 0 #a78f69, 720px 664px 0 #a78f69, 728px 664px 0 #a78f69,
    736px 664px 0 #a78f69, 744px 664px 0 #a78f69, 16px 672px 0 #b39361,
    24px 672px 0 #b39361, 32px 672px 0 #b39361, 40px 672px 0 #b39361,
    48px 672px 0 #b39361, 56px 672px 0 #b39361, 64px 672px 0 #b39361,
    72px 672px 0 #b4945e, 80px 672px 0 #b4945e, 88px 672px 0 #b4945e,
    96px 672px 0 #b4945e, 104px 672px 0 #b4945e, 112px 672px 0 #b4945e,
    120px 672px 0 #aa8666, 128px 672px 0 #aa8666, 136px 672px 0 #aa8666,
    144px 672px 0 #aa8666, 176px 672px 0 #0b0b09, 184px 672px 0 #0b0b09,
    224px 672px 0 #b84531, 256px 672px 0 #2d282e, 264px 672px 0 #2d282e,
    272px 672px 0 #2d282e, 280px 672px 0 #2d282e, 288px 672px 0 #2d282e,
    296px 672px 0 #2d282e, 304px 672px 0 #2d282e, 312px 672px 0 #2d282e,
    320px 672px 0 #e4b38e, 328px 672px 0 #e4b38e, 336px 672px 0 #e4b38e,
    344px 672px 0 #e4b38e, 352px 672px 0 #e4b38e, 360px 672px 0 #e4b38e,
    368px 672px 0 #e4b38e, 376px 672px 0 #e4b38e, 384px 672px 0 #e4b38e,
    392px 672px 0 #e4b38e, 400px 672px 0 #e4b38e, 408px 672px 0 #e0774a,
    416px 672px 0 #dd583b, 424px 672px 0 #dd583b, 432px 672px 0 #dd583b,
    440px 672px 0 #dd583b, 448px 672px 0 #dd583b, 456px 672px 0 #dd583b,
    464px 672px 0 #dd583b, 472px 672px 0 #dd583b, 480px 672px 0 #dd583b,
    488px 672px 0 #a78f69, 496px 672px 0 #a78f69, 504px 672px 0 #a78f69,
    512px 672px 0 #a78f69, 520px 672px 0 #a78f69, 528px 672px 0 #a78f69,
    536px 672px 0 #a78f69, 544px 672px 0 #a78f69, 552px 672px 0 #a78f69,
    560px 672px 0 #a78f69, 568px 672px 0 #a78f69, 576px 672px 0 #a78f69,
    584px 672px 0 #a78f69, 592px 672px 0 #a78f69, 600px 672px 0 #a78f69,
    608px 672px 0 #a78f69, 616px 672px 0 #a78f69, 624px 672px 0 #8b7757,
    632px 672px 0 #8b7757, 640px 672px 0 #8b7757, 648px 672px 0 #8b7757,
    656px 672px 0 #a78f69, 664px 672px 0 #a78f69, 672px 672px 0 #a78f69,
    680px 672px 0 #a78f69, 688px 672px 0 #a78f69, 696px 672px 0 #a78f69,
    704px 672px 0 #a78f69, 712px 672px 0 #a78f69, 720px 672px 0 #a78f69,
    728px 672px 0 #a78f69, 736px 672px 0 #a78f69, 744px 672px 0 #a78f69,
    752px 672px 0 #a78f69, 760px 672px 0 #a78f69, 768px 672px 0 #a78f69,
    776px 672px 0 #a78f69, 8px 680px 0 #b4945e, 16px 680px 0 #b4945e,
    24px 680px 0 #b4945e, 32px 680px 0 #b4945e, 40px 680px 0 #b4945e,
    48px 680px 0 #b4945e, 56px 680px 0 #b4945e, 64px 680px 0 #b4945e,
    72px 680px 0 #aa8666, 80px 680px 0 #aa8666, 88px 680px 0 #aa8666,
    96px 680px 0 #aa8666, 104px 680px 0 #aa8666, 112px 680px 0 #aa8666,
    176px 680px 0 #0b0b09, 184px 680px 0 #0b0b09, 232px 680px 0 #b84531,
    264px 680px 0 #2d282e, 272px 680px 0 #2d282e, 280px 680px 0 #2d282e,
    288px 680px 0 #2d282e, 296px 680px 0 #2d282e, 304px 680px 0 #2d282e,
    312px 680px 0 #1e1a1f, 320px 680px 0 #e4b38e, 328px 680px 0 #e4b38e,
    336px 680px 0 #e4b38e, 344px 680px 0 #e4b38e, 352px 680px 0 #e4b38e,
    360px 680px 0 #e4b38e, 368px 680px 0 #e4b38e, 376px 680px 0 #e4b38e,
    384px 680px 0 #e4b38e, 392px 680px 0 #e4b38e, 400px 680px 0 #e4b38e,
    408px 680px 0 #e4b38e, 416px 680px 0 #e4b38e, 424px 680px 0 #e4b38e,
    432px 680px 0 #e4b38e, 440px 680px 0 #e4b38e, 448px 680px 0 #e4b38e,
    456px 680px 0 #e4b38e, 464px 680px 0 #944228, 472px 680px 0 #944228,
    480px 680px 0 #a78f69, 488px 680px 0 #a78f69, 496px 680px 0 #a78f69,
    504px 680px 0 #a78f69, 512px 680px 0 #a78f69, 520px 680px 0 #a78f69,
    528px 680px 0 #a78f69, 536px 680px 0 #a78f69, 544px 680px 0 #a78f69,
    552px 680px 0 #a78f69, 560px 680px 0 #a78f69, 568px 680px 0 #a78f69,
    576px 680px 0 #8b7757, 584px 680px 0 #8b7757, 592px 680px 0 #8b7757,
    600px 680px 0 #8b7757, 608px 680px 0 #8b7757, 616px 680px 0 #8b7757,
    624px 680px 0 #a78f69, 632px 680px 0 #a78f69, 640px 680px 0 #a78f69,
    648px 680px 0 #a78f69, 656px 680px 0 #a78f69, 664px 680px 0 #a78f69,
    672px 680px 0 #a78f69, 680px 680px 0 #a78f69, 688px 680px 0 #a78f69,
    696px 680px 0 #8b7757, 704px 680px 0 #8b7757, 712px 680px 0 #8b7757,
    720px 680px 0 #a78f69, 728px 680px 0 #a78f69, 736px 680px 0 #a78f69,
    744px 680px 0 #a78f69, 752px 680px 0 #a78f69, 8px 688px 0 #aa8666,
    16px 688px 0 #aa8666, 24px 688px 0 #aa8666, 32px 688px 0 #aa8666,
    40px 688px 0 #aa8666, 48px 688px 0 #aa8666, 56px 688px 0 #aa8666,
    64px 688px 0 #aa8666, 176px 688px 0 #0b0b09, 184px 688px 0 #0b0b09,
    192px 688px 0 #0b0b09, 232px 688px 0 #b84531, 264px 688px 0 #2d282e,
    272px 688px 0 #2d282e, 280px 688px 0 #2d282e, 288px 688px 0 #2d282e,
    296px 688px 0 #2d282e, 304px 688px 0 #1e1a1f, 312px 688px 0 #e4b38e,
    320px 688px 0 #e4b38e, 328px 688px 0 #e4b38e, 336px 688px 0 #e4b38e,
    344px 688px 0 #e4b38e, 352px 688px 0 #e4b38e, 360px 688px 0 #e4b38e,
    368px 688px 0 #e4b38e, 376px 688px 0 #e4b38e, 384px 688px 0 #e4b38e,
    392px 688px 0 #e4b38e, 400px 688px 0 #e4b38e, 408px 688px 0 #e4b38e,
    416px 688px 0 #e4b38e, 424px 688px 0 #e4b38e, 432px 688px 0 #e4b38e,
    440px 688px 0 #e4b38e, 448px 688px 0 #e4b38e, 456px 688px 0 #e4b38e,
    464px 688px 0 #e4b38e, 472px 688px 0 #944228, 480px 688px 0 #944228,
    504px 688px 0 #a78f69, 512px 688px 0 #a78f69, 520px 688px 0 #a78f69,
    528px 688px 0 #a78f69, 536px 688px 0 #a78f69, 544px 688px 0 #a78f69,
    552px 688px 0 #a78f69, 560px 688px 0 #a78f69, 568px 688px 0 #a78f69,
    576px 688px 0 #a78f69, 584px 688px 0 #a78f69, 592px 688px 0 #a78f69,
    600px 688px 0 #a78f69, 608px 688px 0 #a78f69, 616px 688px 0 #a78f69,
    624px 688px 0 #a78f69, 632px 688px 0 #a78f69, 640px 688px 0 #a78f69,
    648px 688px 0 #a78f69, 656px 688px 0 #a78f69, 664px 688px 0 #a78f69,
    672px 688px 0 #8b7757, 680px 688px 0 #8b7757, 688px 688px 0 #8b7757,
    184px 696px 0 #0b0b09, 192px 696px 0 #0b0b09, 240px 696px 0 #b84531,
    280px 696px 0 #2d282e, 288px 696px 0 #2d282e, 296px 696px 0 #1e1a1f,
    304px 696px 0 #1e1a1f, 312px 696px 0 #1e1a1f, 320px 696px 0 #e4b38e,
    328px 696px 0 #e4b38e, 336px 696px 0 #e4b38e, 344px 696px 0 #e4b38e,
    352px 696px 0 #e4b38e, 360px 696px 0 #e4b38e, 368px 696px 0 #e4b38e,
    376px 696px 0 #e4b38e, 384px 696px 0 #e4b38e, 392px 696px 0 #e4b38e,
    400px 696px 0 #e4b38e, 408px 696px 0 #e4b38e, 416px 696px 0 #e4b38e,
    424px 696px 0 #e4b38e, 432px 696px 0 #e4b38e, 440px 696px 0 #e4b38e,
    448px 696px 0 #e4b38e, 456px 696px 0 #e4b38e, 464px 696px 0 #e4b38e,
    472px 696px 0 #944228, 480px 696px 0 #944228, 488px 696px 0 #4a4039,
    496px 696px 0 #4a4039, 536px 696px 0 #a78f69, 544px 696px 0 #a78f69,
    552px 696px 0 #a78f69, 560px 696px 0 #a78f69, 568px 696px 0 #a78f69,
    576px 696px 0 #a78f69, 584px 696px 0 #a78f69, 592px 696px 0 #a78f69,
    600px 696px 0 #a78f69, 608px 696px 0 #a78f69, 616px 696px 0 #a78f69,
    624px 696px 0 #a78f69, 632px 696px 0 #a78f69, 640px 696px 0 #a78f69,
    648px 696px 0 #a78f69, 656px 696px 0 #a78f69, 664px 696px 0 #a78f69,
    672px 696px 0 #a78f69, 680px 696px 0 #a78f69, 688px 696px 0 #a78f69,
    696px 696px 0 #a78f69, 704px 696px 0 #a78f69, 712px 696px 0 #a78f69,
    192px 704px 0 #0b0b09, 200px 704px 0 #0b0b09, 240px 704px 0 #b84531,
    280px 704px 0 #1e1a1f, 288px 704px 0 #1e1a1f, 296px 704px 0 #1e1a1f,
    304px 704px 0 #1e1a1f, 336px 704px 0 #e4b38e, 344px 704px 0 #e4b38e,
    352px 704px 0 #e4b38e, 360px 704px 0 #e4b38e, 368px 704px 0 #e4b38e,
    376px 704px 0 #e4b38e, 384px 704px 0 #e4b38e, 392px 704px 0 #e4b38e,
    400px 704px 0 #e4b38e, 408px 704px 0 #e4b38e, 416px 704px 0 #e4b38e,
    424px 704px 0 #e4b38e, 432px 704px 0 #e4b38e, 440px 704px 0 #e4b38e,
    448px 704px 0 #e4b38e, 456px 704px 0 #e4b38e, 464px 704px 0 #e4b38e,
    472px 704px 0 #e4b38e, 480px 704px 0 #944228, 488px 704px 0 #944228,
    496px 704px 0 #4a4039, 504px 704px 0 #4a4039, 568px 704px 0 #a78f69,
    576px 704px 0 #a78f69, 584px 704px 0 #a78f69, 592px 704px 0 #a78f69,
    600px 704px 0 #a78f69, 608px 704px 0 #a78f69, 616px 704px 0 #a78f69,
    624px 704px 0 #a78f69, 632px 704px 0 #a78f69, 640px 704px 0 #a78f69,
    648px 704px 0 #a78f69, 656px 704px 0 #a78f69, 192px 712px 0 #0b0b09,
    200px 712px 0 #0b0b09, 208px 712px 0 #0b0b09, 240px 712px 0 #b84531,
    248px 712px 0 #b84531, 256px 712px 0 #b84531, 440px 712px 0 #e4b38e,
    448px 712px 0 #e4b38e, 456px 712px 0 #e4b38e, 464px 712px 0 #e4b38e,
    472px 712px 0 #e4b38e, 480px 712px 0 #944228, 488px 712px 0 #944228,
    496px 712px 0 #4a4039, 504px 712px 0 #4a4039, 664px 712px 0 #a78f69,
    672px 712px 0 #a78f69, 200px 720px 0 #0b0b09, 208px 720px 0 #0b0b09,
    232px 720px 0 #b84531, 240px 720px 0 #b84531, 248px 720px 0 #c7bfcf,
    256px 720px 0 #b84531, 264px 720px 0 #b84531, 272px 720px 0 #b84531,
    448px 720px 0 #e4b38e, 456px 720px 0 #e4b38e, 464px 720px 0 #e4b38e,
    472px 720px 0 #e4b38e, 480px 720px 0 #e4b38e, 488px 720px 0 #944228,
    496px 720px 0 #944228, 504px 720px 0 #4a4039, 680px 720px 0 #a78f69,
    200px 728px 0 #0b0b09, 208px 728px 0 #0b0b09, 216px 728px 0 #0b0b09,
    224px 728px 0 #b84531, 232px 728px 0 #b84531, 240px 728px 0 #c7bfcf,
    248px 728px 0 #c7bfcf, 256px 728px 0 #b84531, 264px 728px 0 #b84531,
    272px 728px 0 #b84531, 280px 728px 0 #b84531, 456px 728px 0 #e4b38e,
    464px 728px 0 #e4b38e, 472px 728px 0 #e4b38e, 480px 728px 0 #e4b38e,
    488px 728px 0 #944228, 496px 728px 0 #944228, 504px 728px 0 #944228,
    208px 736px 0 #0b0b09, 216px 736px 0 #b84531, 224px 736px 0 #b84531,
    232px 736px 0 #c7bfcf, 240px 736px 0 #c7bfcf, 248px 736px 0 #b84531,
    256px 736px 0 #b84531, 264px 736px 0 #953d29, 272px 736px 0 #b84531,
    280px 736px 0 #b84531, 288px 736px 0 #b84531, 464px 736px 0 #e4b38e,
    472px 736px 0 #e4b38e, 480px 736px 0 #944228, 488px 736px 0 #944228,
    496px 736px 0 #944228, 504px 736px 0 #944228, 512px 736px 0 #4a4039,
    208px 744px 0 #0b0b09, 216px 744px 0 #b84531, 224px 744px 0 #b84531,
    232px 744px 0 #c7bfcf, 240px 744px 0 #b84531, 248px 744px 0 #b84531,
    256px 744px 0 #953d29, 264px 744px 0 #b84531, 272px 744px 0 #953d29,
    280px 744px 0 #b84531, 288px 744px 0 #953d29, 296px 744px 0 #b84531,
    464px 744px 0 #944228, 472px 744px 0 #944228, 480px 744px 0 #944228,
    488px 744px 0 #944228, 496px 744px 0 #944228, 504px 744px 0 #944228,
    512px 744px 0 #4a4039, 216px 752px 0 #953d29, 224px 752px 0 #953d29,
    232px 752px 0 #b84531, 240px 752px 0 #b84531, 248px 752px 0 #953d29,
    256px 752px 0 #b84531, 264px 752px 0 #953d29, 272px 752px 0 #b84531,
    280px 752px 0 #953d29, 288px 752px 0 #b84531, 296px 752px 0 #b84531,
    472px 752px 0 #944228, 480px 752px 0 #944228, 488px 752px 0 #944228,
    496px 752px 0 #944228, 504px 752px 0 #944228, 512px 752px 0 #4a4039,
    224px 760px 0 #953d29, 232px 760px 0 #953d29, 240px 760px 0 #b84531,
    248px 760px 0 #b84531, 256px 760px 0 #953d29, 264px 760px 0 #b84531,
    272px 760px 0 #953d29, 280px 760px 0 #b84531, 288px 760px 0 #b84531,
    296px 760px 0 #953d29, 472px 760px 0 #944228, 480px 760px 0 #944228,
    488px 760px 0 #944228, 496px 760px 0 #944228, 504px 760px 0 #944228,
    512px 760px 0 #4a4039, 232px 768px 0 #953d29, 240px 768px 0 #953d29,
    248px 768px 0 #953d29, 256px 768px 0 #b84531, 264px 768px 0 #b84531,
    272px 768px 0 #b84531, 280px 768px 0 #b84531, 288px 768px 0 #953d29,
    480px 768px 0 #944228, 488px 768px 0 #944228, 496px 768px 0 #944228,
    504px 768px 0 #4a4039, 512px 768px 0 #4a4039, 240px 776px 0 #953d29,
    248px 776px 0 #953d29, 256px 776px 0 #953d29, 264px 776px 0 #b84531,
    272px 776px 0 #b84531, 280px 776px 0 #953d29, 488px 776px 0 #944228,
    496px 776px 0 #944228, 504px 776px 0 #4a4039, 512px 776px 0 #4a4039,
    256px 784px 0 #953d29, 264px 784px 0 #953d29, 272px 784px 0 #953d29,
    488px 784px 0 #4a4039, 496px 784px 0 #4a4039, 504px 784px 0 #4a4039,
    512px 784px 0 #4a4039, 496px 792px 0 #4a4039, 504px 792px 0 #4a4039;
}

.star {
  width: 8px;
  height: 8px;
  box-shadow: 40px 16px 0 #ecf0f1, 40px 24px 0 #ecf0f1, 24px 32px 0 #ecf0f1,
    32px 32px 0 #ecf0f1, 40px 32px 0 #ecf0f1, 48px 32px 0 #ecf0f1,
    40px 40px 0 #ecf0f1;
}

.small-star {
  width: 8px;
  height: 8px;
  box-shadow: 32px 32px 0 #ecf0f1, 40px 32px 0 #ecf0f1, 32px 40px 0 #ecf0f1,
    40px 40px 0 #ecf0f1;
}

html,
body {
  margin: 0;
  padding: 0;
  background: #1a313f;
}

.kiki {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -175px;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}

.moon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -225px;
  margin-left: -200px;
}

@-moz-keyframes bounce {
  0%,
  20%,
  40%,
  80%,
  100% {
    -moz-transform: scale(0.5) translateY(0);
    transform: scale(0.5) translateY(0);
  }

  60% {
    -moz-transform: scale(0.5) translateY(-15px);
    transform: scale(0.5) translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%,
  20%,
  40%,
  80%,
  100% {
    -webkit-transform: scale(0.5) translateY(0);
    transform: scale(0.5) translateY(0);
  }

  60% {
    -webkit-transform: scale(0.5) translateY(-15px);
    transform: scale(0.5) translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  40%,
  80%,
  100% {
    -moz-transform: scale(0.5) translateY(0);
    -ms-transform: scale(0.5) translateY(0);
    -webkit-transform: scale(0.5) translateY(0);
    transform: scale(0.5) translateY(0);
  }

  60% {
    -moz-transform: scale(0.5) translateY(-15px);
    -ms-transform: scale(0.5) translateY(-15px);
    -webkit-transform: scale(0.5) translateY(-15px);
    transform: scale(0.5) translateY(-15px);
  }
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.star:nth-child(3) {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -300px;
  opacity: 0.3;
  -webkit-transform: scale(0.7) rotate(35deg);
  -moz-transform: scale(0.7) rotate(35deg);
  -ms-transform: scale(0.7) rotate(35deg);
  -o-transform: scale(0.7) rotate(35deg);
  transform: scale(0.7) rotate(35deg);
}

.star:nth-child(4) {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: 250px;
  margin-top: -200px;
  opacity: 0.6;
  -webkit-transform: scale(0.5) rotate(-75deg);
  -moz-transform: scale(0.5) rotate(-75deg);
  -ms-transform: scale(0.5) rotate(-75deg);
  -o-transform: scale(0.5) rotate(-75deg);
  transform: scale(0.5) rotate(-75deg);
}

.star:nth-child(5) {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: 350px;
  margin-top: 150px;
  opacity: 0.4;
  -webkit-transform: scale(0.6) rotate(-5deg);
  -moz-transform: scale(0.6) rotate(-5deg);
  -ms-transform: scale(0.6) rotate(-5deg);
  -o-transform: scale(0.6) rotate(-5deg);
  transform: scale(0.6) rotate(-5deg);
}

.star:nth-child(6) {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: 200px;
  opacity: 0.7;
  -webkit-transform: scale(0.5) rotate(-150deg);
  -moz-transform: scale(0.5) rotate(-150deg);
  -ms-transform: scale(0.5) rotate(-150deg);
  -o-transform: scale(0.5) rotate(-150deg);
  transform: scale(0.5) rotate(-150deg);
}

.small-star:nth-child(7) {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -100px;
  margin-top: 250px;
  opacity: 0.5;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}

.small-star:nth-child(8) {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -50px;
  opacity: 0.8;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}

.small-star:nth-child(9) {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: 400px;
  margin-top: -100px;
  opacity: 0.7;
}

.small-star:nth-child(10) {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: 0px;
  margin-top: -350px;
  opacity: 0.7;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
}
</style>