<template>
  <div class="filter">
    <v-chip
      class="ma-2"
      label
      v-for="n in filter"
      :key="n.key"
      @click="Actions('Edit', n)"
    >
      {{ n.Headers }} {{ n.Operator }}
      <b style="margin-left: 4px" v-if="n.Condition.length > 1"
        >{{ n.Condition.length }} selected</b
      >
      <b style="margin-left: 4px" v-if="n.Condition.length == 1">{{
        n.Condition[0]
      }}</b>
      <v-icon
        color="error"
        @click="removeItems(n.Key)"
        style="margin-left: 10px; cursor: pointer"
        >mdi-close-circle</v-icon
      >
    </v-chip>
    <v-chip
      class="ma-2"
      label
      style="cursor: pointer"
      @click="Actions('Create', null)"
    >
      <v-icon style="margin-right: 5px">mdi-plus</v-icon>Filter
    </v-chip>

    <v-dialog v-model="dialog" max-width="500">
      <v-card style="padding: 30px">
        <v-row>
          <v-col cols="8" xs="8" sm="8" md="8" lg="8" xl="8">
            <v-select
              :items="headers"
              v-model="filter_default.headers"
              item-text="text"
              item-value="value"
              label="Headers"
              outlined
              dense
              name="headers"
              v-validate="'required'"
            ></v-select>
            <transition name="slide-fade">
              <v-alert
                type="error"
                style="margin-top: -20px"
                dense
                v-show="errors.has('headers')"
                >{{ errors.first("headers") }}</v-alert
              >
            </transition>
          </v-col>
          <v-col cols="4" xs="4" sm="4" md="4" lg="4" xl="4">
            <v-select
              :items="operator"
              v-model="filter_default.operator"
              label="Operator"
              outlined
              dense
              name="operator"
              v-validate="'required'"
            ></v-select>
            <transition name="slide-fade">
              <v-alert
                type="error"
                style="margin-top: -20px"
                dense
                v-show="errors.has('operator')"
                >{{ errors.first("operator") }}</v-alert
              >
            </transition>
          </v-col>
          <v-col cols="12" style="margin-top: -30px">
            <v-text-field
              :disabled="filter_default.operator == ''"
              v-model="num"
              type="number"
              label="Condition"
              outlined
              dense
              name="condition"
              v-validate="'required'"
              v-if="
                filter_default.operator == '>=' ||
                filter_default.operator == '<='
              "
            ></v-text-field>
            <v-combobox
              :disabled="filter_default.operator == ''"
              v-model="filter_default.condition"
              label="Condition"
              multiple
              outlined
              small-chips
              dense
              name="condition"
              v-validate="'required'"
              v-if="
                filter_default.operator == '==' ||
                filter_default.operator == '!=' ||
                filter_default.operator == 'LIKE'
              "
            ></v-combobox>
            <transition name="slide-fade">
              <v-alert
                type="error"
                style="margin-top: -20px"
                dense
                v-show="errors.has('condition')"
                >{{ errors.first("condition") }}</v-alert
              >
            </transition>
            <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date"
                  label="Condition"
                  append-icon="event"
                  readonly
                  v-on="on"
                  outlined
                  dense
                  name="condition-date"
                  v-validate="'required'"
                  v-show="
                    filter_default.operator == 'startwith' ||
                    filter_default.operator == 'endwith'
                  "
                ></v-text-field>
              </template>
              <v-date-picker v-model="date"></v-date-picker>
            </v-menu>
            <transition name="slide-fade">
              <v-alert
                type="error"
                style="margin-top: -20px"
                dense
                v-show="errors.has('condition-date')"
                >{{ errors.first("condition-date") }}</v-alert
              >
            </transition>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="AddFilter"
            style="margin: 10px 0px -15px 0px"
            v-if="action == 'Create'"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Add
          </v-btn>
          <v-btn
            color="primary"
            @click="EditFilter"
            style="margin: 10px 0px -15px 0px"
            v-if="action == 'Edit'"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { Filter } from "../function/Filter";

export default {
  data() {
    return {
      dialog: false,
      menu_date: false,
      date: moment().format("YYYY-MM-DD"),
      num: 0,
      filter_default: {
        headers: "",
        operator: "",
        condition: [],
      },
      filter: [],
      operator: ["<=", ">=", "==", "!=", "LIKE", "startwith", "endwith"],
      action: "",
      key: 0,
      Items: this.FilterData,
    };
  },
  props: ["FilterData", "headers"],
  methods: {
    // process validate
    async ProcessFilter() {
      //console.log('start filter');
      if (this.filter.length > 0) {
        // console.log('data to filter ',this.Items)
        const obj = {
          Filter: this.filter,
          Data: this.Items,
        };
        Filter.Filter(obj).then((result) => {
          let res = {
            status: true,
            data: result,
          };
          this.Clear();
          this.$emit("update-data", res);
        });
      } else {
        let res = {
          status: false,
          data: null,
        };
        this.$emit("update-data", res);
        this.Clear();
      }
    },
    // main function
    async AddFilter() {
      this.ValidateFields().then(async (response) => {
        if (response) {
          this.filter_default.key = this.key++;
          if (
            this.filter_default.operator == "startwith" ||
            this.filter_default.operator == "endwith"
          ) {
            this.filter_default.condition.push(this.date);
          } else if (
            this.filter_default.operator == ">=" ||
            this.filter_default.operator == "<="
          ) {
            this.filter_default.condition.push(this.num);
          }
          const result = {
            Key: this.filter_default.key,
            Headers: this.filter_default.headers,
            Operator: this.filter_default.operator,
            Condition: this.filter_default.condition,
          };
          this.filter.push(result);
          console.log('push result', this.filter);
          await this.ProcessFilter();
        }
      });
    },
    async EditFilter() {
      this.ValidateFields().then(async (response) => {
        if (response) {
          for (var i = 0; i < this.filter.length; i++) {
            if (this.filter[i].Key == this.filter_default.key) {
              this.filter[i].Headers = this.filter_default.headers;
              this.filter[i].Operator = this.filter_default.operator;
              this.filter[i].Condition = this.filter_default.condition;
              if (
                this.filter_default.operator == "startwith" ||
                this.filter_default.operator == "endwith"
              ) {
                this.filter[i].Condition = [];
                this.filter[i].Condition.push(this.date);
              } else if (
                this.filter_default.operator == ">=" ||
                this.filter_default.operator == "<="
              ) {
                this.filter[i].Condition = [];
                this.filter[i].Condition.push(this.num);
              }
            }
          }
          await this.ProcessFilter();
        }
      });
    },
    async removeItems(key) {
      for (var i = 0; i < this.filter.length; i++) {
        if (this.filter[i].Key === key) {
          this.filter.splice(i, 1);
        }
      }
      await this.ProcessFilter();
    },
    // other function
    Actions(action, n) {
      this.action = action;
      if (action == "Edit") {
        this.filter_default = {
          key: n.Key,
          headers: n.Headers,
          operator: n.Operator,
          condition: n.Condition,
        };
        this.dialog = !this.dialog;
      } else if (action == "Create") {
        (this.filter_default = {
          headers: "",
          operator: "",
          condition: [],
        }),
          (this.dialog = !this.dialog);
      }
    },
    async ValidateFields() {
      return new Promise(async (resolve, reject) => {
        //console.log('start validate');
        let validator = [
          this.$validator.validate("headers"),
          this.$validator.validate("operator"),
        ];

        if (
          this.filter_default.operator != "startwith" &&
          this.filter_default.operator != "endwith"
        ) {
          validator.push(this.$validator.validate("condition"));
        } else {
          validator.push(this.$validator.validate("condition-date"));
        }

        const results = Promise.all(validator);

        const areValid = (await results).every((isValid) => isValid);
        //console.log('end validate');
        resolve(areValid);
      });
    },
    RandomString(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    Clear() {
      this.filter_default = {
        key: "",
        headers: "",
        operator: "",
        condition: [],
      };
      this.$validator.reset();
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .filter{
    margin-top: 20px;
  }
</style>