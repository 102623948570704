<template>
  <div class="profile">
    <div class="content-inside">
      <div class="content-inside-header">
        <v-tabs background-color="white" show-arrows centered grow class="content-inside-tab">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab @click="tabs = 1">Data Team Indicator Dashboard</v-tab>
          <v-tab @click="tabs = 2">Monthly Time Sheet Dashboard</v-tab>
        </v-tabs>
        <div style="border-bottom: 1px solid rgb(224, 224, 224);" />
      </div>
    </div>
    <div class="content-area">
      <DTID v-if="tabs == 1"/>
      <MTD v-if="tabs == 2"/>
    </div>
  </div>
</template>

<script>
import { WindowResize } from "../../function/WindowResize.js";
import DTID from '../../components/HR_PerformanceDashboard/Data_team_indicator_dashboard.vue'
import MTD from '../../components/HR_PerformanceDashboard/Monthly_Timesheet_dashboard.vue'

export default {
  data() {
    return {
      width: 0,
      height: 0,
      tabs: 1,
    };
  },
  components: {
    DTID,
    MTD
  },
  methods: {
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
  },
  created() {
      this.resize();
  }
};
</script>

<style scoped>
.profile {
  margin-top: 60px;
  width: 100%;
}

.content-inside-header {
  transition: 0.3s all;
}

.content-inside-tab {
  opacity: 0.5;
  transition: 0.5s all;
}

.content-inside-tab:hover {
  opacity: 1;
}

</style>