<template>
  <div class="leave">
    <div class="leave-area">
      <div class="leave-area-btn">
        <div class="header">
          <v-row>
            <v-col>
              <v-btn
                color="secondary"
                style="float: left"
                text
                @click="gotoAboutLeaveRequest()"
              >
                <v-icon style="margin-right: 5px">mdi-help</v-icon>About Leave
                Request
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <span v-show="level > 90">
              <v-col style="padding-right: 0">
                <v-btn
                  color="success"
                  style="float: right"
                  text
                  @click="dowloadAsCSV"
                >
                  <v-icon style="margin-right: 5px"
                    >mdi-arrow-down-bold-box-outline</v-icon
                  >CSV
                </v-btn>
              </v-col>
            </span>

            <span>
              <v-col style="padding-right: 0">
                <v-btn
                  color="primary"
                  style="float: right"
                  text
                  @click="leave_dialog = true"
                >
                  <v-icon>mdi-file-document-edit</v-icon>Leave Request
                </v-btn>
              </v-col>
            </span>
          </v-row>
        </div>
        <div class="status-area">
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-select
                label="Year"
                item-text="LeaveYear"
                item-value="leave_request_summary"
                v-model="leave_request_summary"
                :items="leave_request_summary_all"
                outlined
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3"
              >ขออนุมัติลาพักร้อน ล่วงหน้าอย่างน้อย 3 วันทำการ</v-col
            >
            <v-col cols="12" xs="12" sm="6" md="3"
              >พนักงานจะได้วันลาพักร้อนเพิ่มทุกต้นไตรมาสเมื่ออายุงานครบ 1
              ปีแล้ว</v-col
            >
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-card>
                <v-card-title>
                  <v-icon
                    large
                    class="status-icon"
                    style="border-right: 3px solid gray"
                    >mdi-account</v-icon
                  >
                  <div style="margin-left: 10px">
                    ลาทั้งหมด
                    <span style="font-size: 30px; padding: 10px">{{
                      leave_request_summary.AllLeaveDays
                    }}</span>
                    วัน
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-card dark color="warning">
                <v-card-title>
                  <v-icon large class="status-icon">mdi-calendar-minus</v-icon>
                  <div style="margin-left: 10px">
                    ยืมวันลา
                    <span style="font-size: 22px; padding: 5px"
                      >{{ leave_request_summary.LoanLeaveDays }} /
                      {{ leave_request_summary.MaxLoanPaidVacation }}</span
                    >
                    วัน
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-card v-on="on" dark color="success">
                    <v-card-title>
                      <v-icon large class="status-icon mr-3">mdi-island</v-icon>
                      <span
                        >ลาพักร้อน
                        {{ leave_request_summary.VacationLeaveDays }} /
                        {{
                          leave_request_summary.MaxVacationLeaveDays +
                          leave_request_summary.ExtraLeaveDays
                        }}
                        วัน</span
                      >
                    </v-card-title>
                  </v-card>
                </template>
                <span
                  >ลาได้ปีปัจจุบัน
                  {{ leave_request_summary.MaxVacationLeaveDays }} วัน +
                  ยกจากปีก่อน
                  {{ leave_request_summary.ExtraLeaveDays }} วัน</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-card dark color="primary">
                <v-card-title>
                  <v-icon large class="status-icon">mdi-code-not-equal</v-icon>
                  <div style="margin-left: 10px">
                    ลากิจ
                    <span style="font-size: 22px; padding: 5px"
                      >{{ leave_request_summary.PersonalLeaveDays }} /
                      {{ leave_request_summary.MaxPersonalLeaveDays }}</span
                    >
                    วัน
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-card dark color="error">
                <v-card-title>
                  <v-icon large class="status-icon"
                    >mdi-hospital-box-outline</v-icon
                  >
                  <div style="margin-left: 10px">
                    ลาป่วย
                    <span style="font-size: 22px; padding: 5px"
                      >{{ leave_request_summary.SickLeaveDays }} /
                      {{ leave_request_summary.MaxSickLeaveDays }}</span
                    >
                    วัน
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="data-table">
        <v-card>
          <AdvanceFilter
            v-if="ready == true"
            :headers="headers"
            :FilterData="FilterItems"
            @update-data="UpdateData"
          />
          <v-card-title>
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div v-if="level > 90">
            <v-data-table
              :headers="headers"
              :items="leave_data"
              :search="search"
              item-key="IdPk"
              class="elevation-1"
            >
              <template v-slot:item.LeaveRequestType="{ item }">{{
                ConvertLeaveRequestType(item.LeaveRequestType)
              }}</template>
              <template v-slot:item.LeaveFromDate="{ item }">{{
                Moment(item.LeaveFromDate)
              }}</template>
              <template v-slot:item.LeaveToDate="{ item }">{{
                Moment(item.LeaveToDate)
              }}</template>
              <template v-slot:item.Attachment="{ item }">
                <v-btn
                  icon
                  :disabled="
                    item.Attachment == 'null' || item.Attachment == null
                  "
                  @click="downloadFile(item.Attachment)"
                >
                  <v-icon>mdi-cloud-download</v-icon>
                </v-btn>
              </template>
              <template class="primary" v-slot:item.Status="{ item }">
                <v-chip :color="getStatusColor(item.Status)" dark>{{
                  item.Status
                }}</v-chip>
              </template>
              <template v-slot:item.Active="{ item }">
                <v-btn
                  :disabled="item.Status != 'Pending'"
                  icon
                  @click="deleteLeaveRequest(item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.ApproveBy="{ item }">
                <v-btn
                  class="mr-2"
                  dense
                  style="font-size: 13px"
                  v-show="
                    item.Status == 'Pending' &&
                    level > 90 &&
                    $store.state.currentUser.EmployeeEmail != item.EmployeeEmail
                  "
                  color="success"
                  @click="approveLeaveRequest(item)"
                  >Approve</v-btn
                >
                <!-- <v-btn color="success" @click="approveLeaveRequest(item)">Approve</v-btn> -->

                <v-btn
                  style="font-size: 13px"
                  dense
                  v-show="
                    item.Status == 'Pending' &&
                    level > 90 &&
                    $store.state.currentUser.EmployeeEmail != item.EmployeeEmail
                  "
                  color="error"
                  @click="rejectLeaveRequest(item)"
                  >Reject</v-btn
                >

                <div v-show="item.Status != 'Pending' && level > 90">
                  <div v-if="item.ApproveBy != null">
                    {{ getEmployeeName(item.ApproveBy) }}
                  </div>
                  <div v-else>{{ "-" }}</div>
                </div>

                <!-- View of normal user -->
                <div v-show="level < 11">
                  <div v-if="item.ApproveBy != null">
                    {{ getEmployeeName(item.ApproveBy) }}
                  </div>
                  <div v-else>{{ "-" }}</div>
                </div>
              </template>
            </v-data-table>
          </div>
          <div v-else>
            <v-data-table
              :headers="headers"
              :items="leave_data"
              :search="search"
              item-key="RowID"
              class="elevation-1"
            >
              <template v-slot:item.LeaveRequestType="{ item }">{{
                ConvertLeaveRequestType(item.LeaveRequestType)
              }}</template>
              <template v-slot:item.LeaveFromDate="{ item }">{{
                Moment(item.LeaveFromDate)
              }}</template>
              <template v-slot:item.LeaveToDate="{ item }">{{
                Moment(item.LeaveToDate)
              }}</template>
              <template v-slot:item.Attachment="{ item }">
                <v-btn
                  icon
                  :disabled="
                    item.Attachment == 'null' || item.Attachment == null
                  "
                  @click="downloadFile(item.Attachment)"
                >
                  <v-icon>mdi-cloud-download</v-icon>
                </v-btn>
              </template>
              <template class="primary" v-slot:item.Status="{ item }">
                <v-chip :color="getStatusColor(item.Status)" dark>{{
                  item.Status
                }}</v-chip>
              </template>
              <template v-slot:item.Active="{ item }">
                <v-btn
                  :disabled="item.Status != 'Pending'"
                  icon
                  @click="deleteLeaveRequest(item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>

              <template v-slot:item.ApproveBy="{ item }">
                <!-- View of normal user -->
                <div v-show="level < 11">
                  <div v-if="item.ApproveBy != null">
                    {{ getEmployeeName(item.ApproveBy) }}
                  </div>
                  <div v-else>{{ "-" }}</div>
                </div>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </div>
    </div>
    <!-- dialog -->
    <v-dialog v-model="leave_dialog" persistent max-width="480">
      <v-card>
        <v-container>
          <v-card-title class="headline">Leave Request</v-card-title>
          <div id="field-area">
            <br />
            <v-row no-gutters>
              <!-- <v-col cols="12">
                <p v-if="errors.has('User Name')">{{ errors.first('User Name') }}</p>  
                <p class="error">"Test error"</p>  
              </v-col>-->

              <v-col cols="12">
                <v-select
                  :items="type"
                  label="Type"
                  outlined
                  v-model="leave_request.type"
                  v-validate="'required'"
                  name="type"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu_from_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="leave_request.from_date"
                      label=" From Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                      name="from-date"
                      v-validate="'required'"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="leave_request.from_date"
                    @input="menu_from_date = false"
                    @change="checkDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="menu_to_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="leave_request.to_date"
                      label="To Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                      name="to-date"
                      v-validate="'required'"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="leave_request.to_date"
                    @input="menu_to_date = false"
                    @change="checkDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="period"
                  label="LeavePeriod"
                  outlined
                  v-model="leave_request.period"
                  :disabled="period_status"
                  v-validate="'required'"
                  name="leave-period"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  required
                  outlined
                  label="Note (Options)"
                  v-model="leave_request.note"
                />
              </v-col>
              <v-col cols="12">
                <div class="select-file">
                  <input
                    id="fileUpload"
                    type="file"
                    hidden
                    @change="handleFileUpload"
                  />
                  <v-btn block color="primary" @click="chooseFiles">
                    <v-icon style="margin-right: 5px">mdi-file</v-icon>Add Files
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" v-if="leave_request.attachment.length != 0">
                <br />
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th class="text-right">Size</th>
                        <th class="text-center">Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="n in leave_request.attachment" :key="n.name">
                        <td>{{ n.name }}</td>
                        <td class="text-right">{{ pretty(n.size) }}</td>
                        <td class="text-center">
                          <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="red"
                            @click="removeItems(n.name)"
                            style="width: 25px; height: 25px; margin: 4px"
                          >
                            <v-icon dark size="15">mdi-close</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <br />
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="CancelRequest">Cancel</v-btn>
            <v-btn color="green darken-1" text @click="LeaveRequest"
              >Request</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { IP, AuthKey } from "../config";
import pretty from "prettysize";
import Swal from "sweetalert2";
import download from "downloadjs";
import { Others } from "../function/Others";
import { AccessControl } from "../AccessControl";
const cardName = "LeaveRequest";
export default {
  data() {
    return {
      ready: false,
      user: "",
      defaultYear: "2020",
      years: [],
      level: 0,
      headers: [
        { text: "Request By", value: "EmployeeName" },
        { text: "Type", value: "LeaveRequestType" },
        { text: "From Date", value: "LeaveFromDate" },
        { text: "To Date", value: "LeaveToDate" },
        { text: "Days", value: "TotalLeaveDays" },
        { text: "Note", value: "Note" },
        { text: "Attachment", value: "Attachment" },
        { text: "Status", value: "Status" },
        { text: "Action", value: "Active" },
        { text: "Approve By", value: "ApproveBy", align: "center" },
      ],
      filterHeader: [],
      data: [],
      leave_dialog: false,
      type: ["ลากิจ", "ลาป่วย", "ลาพักร้อน"],
      period: ["ครึ่งวันเช้า", "ครึ่งวันบ่าย", "เต็มวัน"],
      leave_request: {
        type: "",
        from_date: new Date().toISOString().substr(0, 10),
        to_date: new Date().toISOString().substr(0, 10),
        period: "",
        note: "",
        attachment: [],
      },
      menu_from_date: false,
      menu_to_date: false,
      period_status: false,
      leave_data: [],
      leave_request_summary2: [],
      leave_request_summary_all: [],
      leave_request_summary: {
        AllLeaveDays: 0,
        VacationLeaveDays: 0,
        SickLeaveDays: 0,
        PersonalLeaveDays: 0,
        MaxVacationLeaveDays: 0,
        MaxLoanPaidVacation: 0,
        MaxSickLeaveDays: 30,
        MaxPersonalLeaveDays: 30,
      },
      search: "",
      error: [],
      employeeEmailAndName: [],
      FilterItems: [],
    };
  },
  methods: {
    UpdateData(result) {
      if (result.status == true) {
        this.leave_data = JSON.parse(result.data);
      } else {
        this.leave_data = JSON.parse(this.FilterItems);
      }
    },

    gotoAboutLeaveRequest() {
      window.open(
        "https://life.stelligence.com/blog/read/%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%9A%E0%B8%B5%E0%B8%A2%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%A5%E0%B8%B2_2021-01-04-05-21-17",
        "_blank"
      );
    },
    downloadFile(filename) {
      fetch(`${IP}/leave-request-download-file`, {
        method: "post",
        headers: {
          Authorization: AuthKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filename: filename,
        }),
      }).then((res) => {
        const blob = res.blob();
        blob.then(async (result) => {
          await download(result, filename, result.type);
        }, this);
      });
    },
    approveLeaveRequest(e) {
      // console.log("e.RowID", e.RowID);
      let id = e.RowID;

      Swal.fire({
        title: "คุณต้องการดำเนินการต่อหรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          axios
            .get(
              `${IP}/leave-response/1/${parseInt(id)}/${
                this.user.EmployeeEmail
              }/web`,
              {
                headers: {
                  Authorization: AuthKey,
                },
              }
            )
            .then(async (response) => {
              Swal.fire("Approve success !", `success !`, "success");
              await this.initialize();
            });
        }
      }, this);
    },
    rejectLeaveRequest(e) {
      let id = e.RowID;
      Swal.fire({
        title: "คุณต้องการดำเนินการต่อหรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          axios
            .get(
              `${IP}/leave-response/0/${parseInt(id)}/${
                this.user.EmployeeEmail
              }/web`,
              {
                headers: {
                  Authorization: AuthKey,
                },
              }
            )
            .then(async (response) => {
              Swal.fire("Reject success !", `reject !`, "success");
              await this.initialize();
            });
        }
      }, this);
    },
    deleteLeaveRequest(item) {
      Swal.fire({
        title: "คุณต้องการดำเนินการต่อหรือไม่ ?",
        text: `คุณต้องการลบการร้องขอหรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          // console.log(item);
          Object.assign(item, { Operation: "Remove" });
          axios({
            method: "post",
            url: IP + "/cancel-leave-request",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              data: item,
            },
          }).then(async (response) => {
            // console.log("Response = ", response.data);
            if (response.data == "Success") {
              Swal.fire("ยกเลิกเสร็จสิ้น !", `ยกเลิกเสร็จสิ้น !`, "success");
              await this.initialize();
            } else {
              Swal.fire("ยกเลิกล้มเหลว !", `ยกเลิกล้มเหลว !`, "success");
              await this.initialize();
            }
          });
        }
      }, this);
    },
    ConvertLeaveRequestType(type) {
      if (type == "PersonalLeave") {
        return "ลากิจ";
      } else if (type == "SickLeave") {
        return "ลาป่วย";
      } else if (type == "VacationLeave") {
        return "ลาพักร้อน";
      }
    },
    Moment(date) {
      return moment(date).format("MMM Do YY");
    },
    CancelRequest() {
      this.leave_request = {
        type: "",
        from_date: new Date().toISOString().substr(0, 10),
        to_date: new Date().toISOString().substr(0, 10),
        period: "",
        note: "",
        attachment: [],
      };
      this.leave_dialog = false;
      this.leave_request.period = "";
      this.period_status = false;
    },

    CheckValidData() {
      if (this.leave_request.type == "") {
        this.error.push("\nLeave type");
      }
      if (this.leave_request.period == "") {
        this.error.push("\nLeave period");
      }
      const checkDate =
        moment(this.leave_request.to_date).isAfter(
          this.leave_request.from_date
        ) || this.leave_request.from_date == this.leave_request.to_date;

      if (checkDate == false) {
        this.error.push("\nFrom date should be less than To date");
      }
    },

    LeaveRequest() {
      this.CheckValidData();
      if (this.error.length != 0) {
        Swal.fire({
          title: "<small>กรุณากรอกข้อมูลให้ถูกต้อง<small>",
          text: `${this.error}`,
          icon: "error",
          confirmButtonColor: "#EF5350",
        });
        this.error = [];
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            Swal.fire({
              title: "คุณต้องการดำเนินการต่อหรือไม่ ?",
              text: `คุณต้องการร้องขอ${this.leave_request.type}หรือไม่ !`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            }).then(async (result) => {
              // console.log(result);
              if (result.value) {
                this.LeaveRequestProcess().then(async (response) => {
                  console.log(response);
                  if (response.originalError != null) {
                    Swal.fire(
                      "ร้องขอล้มเหลว !",
                      `ร้องขอ${this.leave_request.type}ล้มเหลว !`,
                      "error"
                    );
                    await this.CancelRequest();
                  } else if (response.code < 0) {
                    Swal.fire("ร้องขอล้มเหลว !", `${response.text}`, "error");
                    await this.CancelRequest();
                  } else {
                    Swal.fire(
                      "ร้องขอเสร็จสิ้น !",
                      `ร้องขอ${this.leave_request.type}เสร็จสิ้น !`,
                      "success"
                    );
                    await this.CancelRequest();
                    await this.initialize();
                  }
                }, this);
              }
            }, this);
          }
        }, this);
      }
    },

    LeaveRequestProcess() {
      return new Promise(async (resolve, reject) => {
        const data = new FormData();
        const file = this.leave_request.attachment;
        data.append("file", file[0]);
        data.append("LeaveRequestType", this.leave_request.type);
        data.append("LeaveFromDate", this.leave_request.from_date);
        data.append("LeaveToDate", this.leave_request.to_date);
        data.append("LeavePeriod", this.leave_request.period);
        data.append("Note", this.leave_request.note);
        data.append("EmployeeEmail", this.user.EmployeeEmail);
        data.append("EmployeeName", this.user.EmployeeName);
        data.append("Operation", "Add");
        data.append(
          "RemainLeaveDay",
          this.leave_request_summary.MaxVacationLeaveDays +
            this.leave_request_summary.ExtraLeaveDays -
            this.leave_request_summary.VacationLeaveDays
        );
        axios
          .post(IP + "/leave-request", data, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            resolve(response.data);
          });
      });
    },
    async dowloadAsCSV() {
      const headerDataJson = {
        // Key : firstColumnDisplay
        EmployeeName: "EmployeeName",
        EmployeeEmail: "EmployeeEmail",
        LeaveRequestType: "LeaveRequestType",
        LeavePeriod: "LeavePeriod",
        LeaveFromDate: "LeaveFromDate",
        LeaveToDate: "LeaveToDate",
        Note: "Note",
        Status: "Status",
        ApproveBy: "ApproveBy",
      };

      await Others.dowloadCSV(headerDataJson, this.leave_data, "LeaveRequest");
    },
    ConvertDate(date) {
      const result = moment(date).format("L");
      return result;
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    handleFileUpload(e) {
      this.leave_request.attachment = [];
      const file = e.target.files;
      for (var i = 0; i < file.length; i++) {
        this.leave_request.attachment.push(file[i]);
      }
    },
    checkDate() {
      const from = moment(this.leave_request.from_date);
      const to = moment(this.leave_request.to_date);
      const sum = to.diff(from, "days");

      if (sum > 0) {
        this.leave_request.period = "เต็มวัน";
        this.period_status = true;
      } else {
        this.period_status = false;
        this.leave_request.period = "";
      }
    },
    removeItems(name) {
      for (var i = 0; i < this.leave_request.attachment.length; i++) {
        if (this.leave_request.attachment[i].name === name) {
          this.leave_request.attachment.splice(i, 1);
        }
      }
    },
    pretty(size) {
      return pretty(size);
    },
    getLeaveRequest() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/select-leave-request",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.user.EmployeeEmail,
            Level: AccessControl.getReadAccessLevel(
              cardName,
              this.user.AccessRightsJSON
            ),
          },
        }).then((response) => {
          // console.log("ss", response.data);
          let level = AccessControl.getReadAccessLevel(
            cardName,
            this.user.AccessRightsJSON
          );
          // console.log('', level)
          this.level = level;
          resolve(response.data);
        });
      });
    },
    getLeaveRequestSummary() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/select-leave-request-summary",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.user.EmployeeEmail,
          },
        }).then((response) => {
          // console.log("object", response.data);
          resolve(response.data);
        });
      });
    },
    getEmployeeName(email) {
      // console.log("Email", email);
      if (email == null) return "";
      return this.employeeEmailAndName.filter(function (data) {
        return data.EmployeeEmail == email;
      })[0].EmployeeName;
    },
    async initialize() {
      await this.getLeaveRequestSummary().then(async (response) => {
        for (let i in response) {
          if (response[i] != undefined) {
            this.leave_request_summary = response[i];
            this.leave_request_summary_all.push(response[i]);
            this.years.push(response[i].LeaveYear);
          }
        }

        await axios
          .get(`${IP}/getAllUserEmailAndName`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((res) => {
            for (const index in res.data) {
              this.employeeEmailAndName.push(res.data[index]);
            }
          });

        await this.getLeaveRequest().then(async (response) => {
          // Add new employee name field
          for (const key in response) {
            const json = response[key];
            json["NameApproveBy"] = await this.getEmployeeName(
              json["ApproveBy"]
            );
          }
          this.leave_data = response;
          // console.log("All data",this.leave_data)
        }, this);
      });
      this.ready = true;
      this.filterHeader = this.headers;
      this.filterHeader[8] = { text: "Approve By", value: "NameApproveBy" };
      this.FilterItems = JSON.stringify(this.leave_data);
      // console.log(this.FilterItems);
      // console.log("data=", this.leave_data);
    },

    getStatusColor(status) {
      if (status == "Pending") {
        return "orange";
      } else if (status == "Approve") {
        return "green";
      } else {
        return "red";
      }
    },
  },
  async created() {
    this.user = this.$store.getters.currentUser;
    await this.initialize();
  },
};
</script>

<style lang="scss">
.leave {
  margin-top: 100px;
}

.leave-area {
  width: 95%;
  margin: 0 auto;
}

.leave-area-btn {
  width: 100%;
}

#field-area {
  width: 80%;
  margin: 0 auto;
}

.select-file {
  width: 100%;
}

#summary-text {
  margin-top: 10px;
}

.header-text {
  font-size: 30px;
}

.header {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.status-icon {
  margin-right: 5px;
  padding-right: 15px;
  border-right: 3px solid rgb(255, 255, 255);
}

.width-all-content {
  width: -webkit-fill-available;
}

v-btn:hover {
  font-size: 20px;
}
</style>