<template>
  <div class="trainingList">
    <AdvanceFilter
      v-if="ready == true"
      :headers="headers"
      :FilterData="FilterItems"
      @update-data="UpdateData"
    />
    <v-data-table
      :headers="headers"
      :items="listItems"
      :search="search"
      sort-by="RunningNumberCode"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Training List</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                text
                dark
                class="mb-2"
                v-on="on"
                @click="errors.clear()"
              >
                <v-icon style="margin-right: 5px">mdi-file-document-edit</v-icon
                >New Request
              </v-btn>
              <span v-show="level > 90">
                <v-btn
                  color="success"
                  class="mb-2"
                  style="float: right"
                  text
                  @click="dowloadAsCSV"
                >
                  <v-icon style="margin-right: 5px"
                    >mdi-arrow-down-bold-box-outline</v-icon
                  >CSV
                </v-btn>
              </span>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <!--New Request -->
              <v-card-text>
                <form enctype="multipart/form-data">
                  <v-container>
                    <v-row>
                      <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                        <v-text-field
                          outlined
                          label="Topic Name"
                          v-model="requestItem.TopicName"
                          v-validate="'required'"
                          name="topicName"
                        />
                        <transition name="slide-fade">
                          <v-alert
                            type="error"
                            v-show="errors.has('topicName')"
                            >{{ errors.first("topicName") }}</v-alert
                          >
                        </transition>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="select-type"
                      >
                        <v-select
                          :items="select_type"
                          label="Type"
                          outlined
                          v-model="requestItem.Type"
                        ></v-select>
                        <transition name="slide-fade">
                          <v-alert
                            type="error"
                            v-show="errors.has('topicName')"
                            >{{ errors.first("topicName") }}</v-alert
                          >
                        </transition>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        style="margin-top: -30px"
                      >
                        <!-- <v-text-field
                          outlined
                          v-model="requestItem.CourseDescription"
                          v-validate="'required'"
                          name="courseDescription"
                        />-->
                        <v-textarea
                          color="black"
                          label="Course Description"
                          outlined
                          v-model="requestItem.CourseDescription"
                          v-validate="'required'"
                          name="courseDescription"
                        ></v-textarea>
                        <transition name="slide-fade">
                          <v-alert
                            type="error"
                            v-show="errors.has('courseDescription')"
                            >{{ errors.first("courseDescription") }}</v-alert
                          >
                        </transition>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        style="margin-top: -30px"
                      >
                        <!-- <v-text-field
                          outlined
                          label="Trainee"
                          v-model="requestItem.AttendanceName"
                          v-validate="'required'"
                          name="trainee"
                        />-->
                        <v-select
                          :items="userLists"
                          label="Trainee"
                          outlined
                          v-model="requestItem.trainee"
                          v-validate="'required'"
                          name="trainee"
                          item-text="EmployeeName"
                          return-object
                        ></v-select>
                        <transition name="slide-fade">
                          <v-alert
                            type="error"
                            v-show="errors.has('trainee')"
                            >{{ errors.first("trainee") }}</v-alert
                          >
                        </transition>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        style="margin-top: -30px"
                      >
                        <v-menu
                          v-model="menu_date1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          full-width
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="requestItem.StartTrainingDate"
                              label=" Start Training Date"
                              append-icon="event"
                              v-on="on"
                              name="StartTrainingDate"
                              v-validate="'required'"
                              outlined
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="requestItem.StartTrainingDate"
                            @input="menu_date1 = false"
                          ></v-date-picker>
                        </v-menu>
                        <transition name="slide-fade">
                          <v-alert
                            type="error"
                            v-show="errors.has('StartTrainingDate')"
                            >{{ errors.first("StartTrainingDate") }}</v-alert
                          >
                        </transition>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        style="margin-top: -30px"
                      >
                        <v-menu
                          v-model="menu_date2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          full-width
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="requestItem.EndTrainingDate"
                              label=" End Training Date"
                              append-icon="event"
                              v-on="on"
                              name="EndTrainingDate"
                              v-validate="'required'"
                              outlined
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="requestItem.EndTrainingDate"
                            @input="menu_date2 = false"
                          ></v-date-picker>
                        </v-menu>
                        <transition name="slide-fade">
                          <v-alert
                            type="error"
                            v-show="errors.has('EndTrainingDate')"
                            >{{ errors.first("EndTrainingDate") }}</v-alert
                          >
                        </transition>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        style="margin-top: -30px"
                      >
                        <v-text-field
                          outlined
                          label="Duration (Days) "
                          type="number"
                          v-model="requestItem.Duration"
                        />
                        <transition name="slide-fade">
                          <v-alert
                            type="error"
                            v-show="errors.has('calDuration')"
                            >{{ errors.first("calDuration") }}</v-alert
                          >
                        </transition>
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        sm="6"
                        md="6"
                        lg="6"
                        xl="6"
                        style="margin-top: -30px"
                      >
                        <v-text-field
                          outlined
                          label="Budget to be used (THB)"
                          v-model="requestItem.Budget"
                          v-validate="'required'"
                          name="budget"
                        />
                        <transition name="slide-fade">
                          <v-alert type="error" v-show="errors.has('budget')">
                            {{ errors.first("budget") }}
                          </v-alert>
                        </transition>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        style="margin-top: -30px"
                      >
                        <input
                          id="fileUpload"
                          name="fileUpload"
                          type="file"
                          hidden
                          @change="handleFileUpload"
                        />

                        <!-- Case have file when click edit request -->
                        <v-btn
                          tile
                          block
                          color="success"
                          @click="chooseFiles"
                          v-if="
                            requestItem.AttachFile != '' && editedIndex != -1
                          "
                        >
                          <v-icon style="margin-right: 5px">mdi-file</v-icon>
                          {{ getFileNameForUrl(requestItem.AttachFile) }}
                        </v-btn>
                        <transition name="slide-fade">
                          <v-alert
                            type="error"
                            v-show="error != ''"
                            style="margin-top: 10px"
                            >{{ error }}</v-alert
                          >
                        </transition>

                        <!-- Case no file when click new request -->
                        <v-btn
                          tile
                          block
                          color="primary"
                          @click="chooseFiles"
                          v-if="
                            requestItem.AttachFile == '' || editedIndex == -1
                          "
                        >
                          <v-icon style="margin-right: 5px">mdi-file</v-icon>

                          <div
                            v-if="
                              getFileNameForUrl(requestItem.AttachFile) == null
                            "
                          >
                            Add File
                          </div>

                          <div v-else>
                            {{ getFileNameForUrl(requestItem.AttachFile) }}
                          </div>
                        </v-btn>

                        <transition name="slide-fade">
                          <v-alert
                            type="error"
                            v-show="error != ''"
                            style="margin-top: 10px"
                            >{{ error }}</v-alert
                          >
                        </transition>
                      </v-col>
                    </v-row>
                    <div
                      class="information-area"
                      v-if="requestItem.AttachFile != null"
                    >
                      <v-divider></v-divider>
                      <!-- <v-simple-table hidden dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th class="text-right">Size</th>
                              <th class="text-center">Active</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ requestItem.AttachFile.name }}</td>
                              <td class="text-right">{{ pretty(requestItem.AttachFile.size) }}</td>
                              <td class="text-center">
                                <v-btn
                                  class="mx-2"
                                  fab
                                  dark
                                  x-small
                                  color="red"
                                  @click="removeItems"
                                  style="width: 25px; height: 25px; margin: 4px;"
                                >
                                  <v-icon dark size="15">mdi-close</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>-->
                    </div>
                  </v-container>
                </form>
              </v-card-text>
              <v-card-actions style="margin-top: -40px">
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                  style="margin: 10px"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  style="margin: 10px"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.StartTrainingDate="{ item, index }">
        <div style="text-align: center">
          {{ CovertDateFormat(item.StartTrainingDate) }} -
          {{ CovertDateFormat(item.EndTrainingDate) }}
        </div>
      </template>
      <template v-slot:item.Duration="{ item }">
        <div style="text-align: center">{{ item.Duration }}</div>
      </template>
      <template v-slot:item.Budget="{ item }">
        <div style="text-align: right">{{ ConvertCurrency(item.Budget) }}</div>
      </template>

      <template v-slot:item.ApprovedBy="{ item }">
        <div style="text-align: -webkit-center">
          <v-btn
            v-show="item.RequestStatus == 'รอการตอบรับ' && level > 90"
            id="approve-btn"
            dense
            color="success"
            @click="approveTrainingRequest(item)"
            >Approve</v-btn
          >

          <v-btn
            v-show="item.RequestStatus == 'รอการตอบรับ' && level > 90"
            id="reject-btn"
            dense
            color="error"
            @click="rejectTrainingRequest(item)"
            >Reject</v-btn
          >

          <div v-show="item.RequestStatus != 'รอการตอบรับ' && level > 90">
            <div v-if="item.ApprovedBy != null">{{ item.ApprovedBy }}</div>
            <div v-else>{{ "-" }}</div>
            <!-- <div>{{item}}</div> -->
          </div>

          <!-- View of normal user -->
          <div v-show="level < 11">
            <div v-if="item.ApprovedBy != null">{{ item.ApprovedBy }}</div>
            <div v-else>{{ "-" }}</div>
          </div>
        </div>
      </template>

      <template v-slot:item.CreateBy="{ item }">
        <div style="text-align: right">
          {{ getEmployeeName(item.CreateBy) }}
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon
          :disabled="checkFileExist(item.AttachFile)"
          small
          class="ml-1 mr-1"
          style="cursor: pointer"
          @click="DownloadFile(item)"
          >mdi-cloud-download</v-icon
        >
        <v-icon
          class="ml-1 mr-1"
          small
          style="cursor: pointer"
          @click="editItem(item)"
          >edit</v-icon
        >
        <v-icon
          class="ml-1 mr-1"
          small
          style="cursor: pointer"
          @click="deleteItem(item)"
          >delete</v-icon
        >
      </template>
      <template v-slot:no-data>
        <p style="font-size: 18px; text-align: center; margin: 40px">
          ยังไม่มีรายการ
        </p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import moment from "moment";
import Swal from "sweetalert2";
import download from "downloadjs";
import pretty from "prettysize";
import { Others } from "../../function/Others";
import { AccessControl } from "../../AccessControl";
const cardName = "LeaveRequest";
// const cardName = "TrainRequest";
export default {
  name: "trainingList",
  data: () => ({
    ready: false,
    FilterItems: [],
    filterHeader: [],
    attachment: [],
    error: "",
    auth: null,
    user: "",
    userLists: [],
    search: "",
    dialog: false,
    headers: [
      { text: "TopicName", value: "TopicName" },
      // { text: "CourseDescription", value: "CourseDescription" },
      { text: "AttendanceName", value: "AttendanceName" },
      { text: "Start - End Date", value: "StartTrainingDate" },
      // { text: "EndTrainingDate", value: "EndTrainingDate" },
      { text: "Duration (Days)", value: "Duration" },
      { text: "Budget (THB)", value: "Budget" },
      { text: "CourseStatus", value: "CourseStatus" },
      { text: "RequestStatus", value: "RequestStatus" },
      { text: "Approved by", value: "ApprovedBy" },
      { text: "Create by", value: "CreateBy" },
      { text: "Actions", value: "action", sortable: false },
    ],
    listItems: [],
    editedIndex: -1,
    requestItem: {
      TopicName: "",
      Type: "Training",
      CourseDescription: "",
      AttendanceName: "",
      StartTrainingDate: new Date().toISOString().substr(0, 10),
      EndTrainingDate: new Date().toISOString().substr(0, 10),
      Budget: "",
      Email: "",
      Duration: 0,
      AttachFile: null,
      trainee: {},
    },
    select_type: ["Training", "Certificate Exam"],

    defaultItem: {
      TopicName: "",
      CourseDescription: "",
      AttendanceName: "",
      StartTrainingDate: new Date().toISOString().substr(0, 10),
      EndTrainingDate: new Date().toISOString().substr(0, 10),
      CourseStatus: "",
      RequestStatus: "",
      Budget: "",
      Email: "",
      Duration: 0,
      ApprovedBy: null,
      AttachFile: null,
    },
    menu_date1: false,
    menu_date2: false,
    loading: false,
    items: {},
    RunningNumberItems: [],
    level: 0,
    previousFileName: null,
    employeeEmailAndName: [],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Request" : "Edit Item";
    },
    calDuration() {
      let start = moment([
        parseInt(this.requestItem.StartTrainingDate.substr(0, 4)),
        parseInt(this.requestItem.StartTrainingDate.substr(5, 7)),
        parseInt(this.requestItem.StartTrainingDate.substr(8, 10)),
      ]);
      let finish = moment([
        parseInt(this.requestItem.EndTrainingDate.substr(0, 4)),
        parseInt(this.requestItem.EndTrainingDate.substr(5, 7)),
        parseInt(this.requestItem.EndTrainingDate.substr(8, 10)),
      ]);
      let days = finish.diff(start, "days") + 1;
      let duration = days;

      return duration;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    UpdateData(result) {
      if (result.status == true) {
        this.listItems = JSON.parse(result.data);
      } else {
        this.listItems = JSON.parse(this.FilterItems);
      }
    },
    getFileNameForUrl(url) {
      if (url == null) {
        return url;
      } else if (typeof url == "string") {
        return url.split("/")[4] || null;
      } else {
        return url.name || null;
      }
    },
    checkFileExist(attachFile) {
      return attachFile == "";
    },
    CovertDateFormat(date) {
      // console.log("Date to format=", date);
      // var DATE_RFC2822 = "ddd, DD MMM YYYY";
      var DATE_RFC2822 = "DD MMM YYYY";
      return moment(date).format(DATE_RFC2822);
    },
    ConvertCurrency(int) {
      return int.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    removeItems() {
      this.requestItem.AttachFile = null;
    },
    pretty(size) {
      return pretty(size);
    },
    DownloadFile(data) {
      let apiurl = IP + `/api/v1/download-file/${data.Id}`;
      fetch(apiurl, {
        method: "get",
        headers: {
          Authorization: AuthKey,
        },
      }).then((res) => {
        const blob = res.blob();
        blob.then(async (result) => {
          await download(
            result,
            data.AttachFile.split("certificates/")[1],
            result.type
          );
        }, this);
      });
    },
    //Upload File
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    async handleFileUpload(e) {
      const file = e.target.files;
      this.requestItem.AttachFile = file[0];
    },

    getEmployeeName(email) {
      // console.log("Email", email);
      if (email == null) return "";
      return this.employeeEmailAndName.filter(function (data) {
        return data.EmployeeEmail == email;
      })[0].EmployeeName;
    },
    //Initialize
    async initialize() {
      this.listItems = [];
      let apiurl = "";

      await axios
        .get(`${IP}/getAllUserEmailAndName`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((res) => {
          // console.log(res.data);
          for (const index in res.data) {
            this.employeeEmailAndName.push(res.data[index]);
          }
        });

      await axios({
        url: IP + "/api/v1/trainings",
        method: "post",
        headers: {
          Authorization: AuthKey,
        },
        data: {
          Email: this.user.EmployeeEmail,
        },
      }).then(async (resp) => {
        const result = resp.data;
        this.level = AccessControl.getReadAccessLevel(
          cardName,
          this.user.AccessRightsJSON
        );
        for (var i = 0; i < result.length; i++) {
          var val = result[i];
          const startDate = val.StartTrainingDate.substr(0, 10);
          const endDate = val.EndTrainingDate.substr(0, 10);
          // console.log("Create by", val.CreateBy);
          const data = {
            Id: val.ReqId,
            TopicName: val.TopicName,
            Type: val.Type,
            CourseDescription: val.CourseDescription,
            AttendanceName: val.AttendanceName,
            StartTrainingDate: startDate,
            EndTrainingDate: endDate,
            CourseStatus: val.CourseStatus,
            RequestStatus: val.RequestStatus,
            Duration: val.Duration,
            Budget: val.Budget,
            Email: val.Email,
            ApprovedBy: val.ApproveBy,
            CreateBy: val.CreateBy,
            AttachFile: val.AttachFile,
          };
          data.CourseStatus =
            moment(Date.now()).isBetween(startDate, endDate) ||
            (moment(startDate).isSame(endDate) &&
              moment(Date.now()).isSame(endDate))
              ? "เริ่มแล้ว"
              : "ยังไม่ได้เริ่ม";

          // console.log("Approve by",val.ApprovedBy)
          data["NameCreateBy"] = await this.getEmployeeName(val.CreateBy);

          if (data.RequestStatus === 0) {
            data.RequestStatus = "รอการตอบรับ";
          } else if (data.RequestStatus === 1) {
            data.RequestStatus = "อนุมัติ";
          } else {
            data.RequestStatus = "ไม่อนุมัติ";
          }
          this.listItems.push(data);
        }
        this.ready = true;
        this.filterHeader = this.headers;
        this.filterHeader[8] = { text: "Create By", value: "NameCreateBy" };
        this.FilterItems = JSON.stringify(this.listItems);
        // console.log("All data train", this.listItems);
      });
    },
    editItem(item) {
      // console.log("Edit item=", item);
      this.previousFileName = this.getFileNameForUrl(item.AttachFile);
      this.requestItem.Duration = this.calDuration;
      this.editedIndex = this.listItems.indexOf(item);
      this.requestItem = Object.assign({}, item);
      this.dialog = true;
    },

    approveTrainingRequest(item) {
      const data = new FormData();
      data.append("id", parseInt(item.Id));
      data.append("action", "Approve");
      data.append("approveBy", this.user.EmployeeName);
      axios
        .post(`${IP}/api/v1/trainings/webVerifyTraining`, data, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((res) => {
          Swal.fire(
            "Approved!",
            "Training Request has been approved.",
            "success"
          );
          this.initialize();
        });
    },
    rejectTrainingRequest(item) {
      // alert("Reject item", item);
      const data = new FormData();
      data.append("id", item.Id);
      data.append("action", "Reject");
      // console.log("User click=",this.user.EmployeeName);
      data.append("approveBy", this.user.EmployeeName);
      axios
        .post(`${IP}/api/v1/trainings/webVerifyTraining`, data, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((res) => {
          // console.log("Response = ", res);
          Swal.fire(
            "Rejected!",
            "Training Request has been rejected.",
            "success"
          );
          this.initialize();
        });
    },

    deleteItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        // console.log(result);
        if (result.value) {
          const index = await this.listItems.indexOf(item);
          await this.listItems.splice(index, 1);
          await this.DeleteDataToTrainingDataBase(item);
          Swal.fire(
            "Deleted!",
            "Training Request has been deleted.",
            "success"
          );
        }
      });
    },

    close() {
      this.dialog = false;
      this.errors.clear();
      setTimeout(() => {
        this.requestItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      this.error = "";
      this.requestItem.Duration = this.calDuration;
      this.requestItem.Email = this.requestItem.trainee.EmployeeEmail;
      this.requestItem.AttendanceName = this.requestItem.trainee.EmployeeName;
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (this.editedIndex > -1) {
            Object.assign(this.listItems[this.editedIndex], this.requestItem);
            this.UpdateDataToTrainingDataBase(this.requestItem);
          } else {
            this.InsertDataToTrainingDataBase(this.requestItem);
          }
          this.errors.clear();
          this.close();
        }
      });
    },
    async dowloadAsCSV() {
      const headerDataJson = {
        // Key : firstColumnDisplay
        TopicName: "TopicName",
        Type: "Type",
        CourseDescription: "CourseDescription",
        AttendanceName: "AttendanceName",
        StartTrainingDate: "StartTrainingDate",
        EndTrainingDate: "EndTrainingDate",
        Email: "Email",
        Budget: "Budget",
        Duration: "Duration",
        CreateBy: "CreateBy",
      };

      await Others.dowloadCSV(
        headerDataJson,
        this.listItems,
        "TrainingRequest"
      );
    },

    createFormData(data) {
      let formData = new FormData();
      formData.append("TopicName", data.TopicName);
      formData.append("Type", data.Type);
      formData.append("CourseDescription", data.CourseDescription);
      formData.append("AttendanceName", data.AttendanceName);
      formData.append("StartTrainingDate", data.StartTrainingDate);
      formData.append("EndTrainingDate", data.EndTrainingDate);
      formData.append("Budget", data.Budget);
      formData.append("Email", data.Email);
      formData.append("Duration", data.Duration);
      formData.append("AttachFile", data.AttachFile);
      formData.append("ManagerName", this.user.EmployeeName);
      formData.append("CreateBy", this.user.EmployeeEmail);
      return formData;
    },

    //Insert
    InsertDataToTrainingDataBase(data) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // data.Email = this.user.EmployeeEmail;
          let params = data;
          let formData = this.createFormData(data);
          let apiurl = IP + "/api/v1/trainings/add";
          axios
            .post(apiurl, formData, {
              headers: {
                Authorization: AuthKey,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((resp) => {
              // console.log(resp)
              this.initialize();
            })
            .catch((error) => {
              console.log(error);
              this.initialize();
            });
        }
      });
    },

    //Update
    UpdateDataToTrainingDataBase(data) {
      let apiurl = IP + `/api/v1/edit/${data.Id}`;
      let params = this.createFormData(data);
      params.append("PreviousFileName", this.previousFileName);
      // console.log("Data to update",params)
      axios
        .put(apiurl, params, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((resp) => {
          this.initialize();
          this.previousFileName = null;
        });
    },

    // Deleted
    DeleteDataToTrainingDataBase(data) {
      let apiurl = IP + `/api/v1/trainings/${data.Id}/remove`;
      axios({
        method: "delete",
        url: apiurl,
        headers: {
          Authorization: AuthKey,
        },
      }).then((resp) => {});
    },
    getAllUserEmailAndName() {
      axios
        .get(`${IP}/getAllUserEmailAndName`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((response) => {
          this.userLists = response.data;
        }, this);
    },
    ConvertDate(date) {
      const result = moment(date).format("L");
      return result;
    },
  },
  async created() {
    this.user = this.$store.getters.currentUser;
    if (this.user == null) {
      this.$router.push("/");
    } else {
      await this.getAllUserEmailAndName();
      await this.initialize();
    }
  },
};
</script>
<style scoped lang="scss">
@import "@/css/transition.scss";

#approve-btn {
  // style="font-size:13px; padding:10px; margin:2px;"
  font-size: 13px;
  padding: 10px;
  margin: 2px;
}

#reject-btn {
  font-size: 13px;
  padding: 10px;
  margin: 2px;
  margin-right: -1px;
}

.trainingList {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
}

#fileUpload {
  width: 0;
  height: 0;
  display: none;
}

input[type="file"] {
  color: transparent;
}

@media screen and (max-width: 600px) {
  .select-type {
    margin-top: -30px;
  }
}
</style>
