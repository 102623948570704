<template>
  <div class="main-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Project</v-col>
        <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
          <v-btn
            color="success"
            block
            @click="dialog = !dialog"
            style="float: right"
          >
            <v-icon style="margin: 0px 10px 0px -5px">mdi-plus</v-icon>Create
            Project
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px">
        ยังไม่มีโปรเจค
      </p>
    </div>
    <div v-if="listItems.length > 0">
      <v-card-title v-if="listItems.length > 0">
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          v-for="n in resultQuery"
          :key="n.ID"
        >
          <v-container>
            <v-card class="card" @click="goToTask(n.ProjectPath)">
              <v-container>
                <v-card-title class="headline">{{
                  n.ProjectName
                }}</v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle>
                  <v-row>
                    <v-col cols="6">
                      <b>Type :</b>
                      {{ n.ProjectType }}
                    </v-col>
                    <v-col cols="6">
                      <b>TAG :</b>
                      {{ n.ProjectTag }}
                    </v-col>
                    <v-col cols="12" style="margin-top: -20px">
                      <b>Customer :</b>
                      {{ n.ProjectCustomer }}
                    </v-col>
                    <v-col cols="12" style="margin-top: -20px">
                      <b>Created :</b>
                      {{ Others.ConvertDate(n.CreateDate) }}
                    </v-col>
                  </v-row>
                </v-card-subtitle>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </div>
    <!-- dialog -->
    <v-dialog v-model="dialog" width="100%" max-width="500">
      <v-card>
        <v-card-title>Create Project</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Project Name"
                outlined
                v-model="project.name"
                name="name"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('name')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("name") }}</v-alert
                >
              </transition>
              <v-card-subtitle
                v-if="project.name != ''"
                style="margin-top: -40px"
                >{{ CreatePath(project.name) }}</v-card-subtitle
              >
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >
              <v-text-field
                label="Project Type"
                outlined
                v-model="project.type"
                name="type"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('type')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("type") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >
              <v-text-field
                label="TAG"
                outlined
                v-model="project.tag"
                name="tag"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Customer"
                outlined
                v-model="project.customer"
                name="tag"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="project.team"
                :items="team"
                outlined
                chips
                small-chips
                item-text="TeamName"
                item-value="TeamName"
                label="Team"
                multiple
                @change="GetMemberByTeam"
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="project.member"
                :items="raw_member"
                outlined
                chips
                small-chips
                item-text="EmployeeName"
                item-value="EmployeeEmail"
                label="Member"
                multiple
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <transition name="slide-fade">
            <v-alert
              type="error"
              v-if="error != ''"
              dense
              style="margin-top: 10px; margin-bottom: -10px"
              >{{ error }}</v-alert
            >
          </transition>
        </div>
        <v-divider style="margin-top: 20px;"></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="success" @click="CreateProject" style="margin: 10px">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../config";
import { Others } from "../function/Others";

export default {
  data() {
    return {
      Others: Others,
      IP: IP,
      user: "",
      listItems: [],
      loading: false,
      error: "",
      action: "",
      dialog: false,
      search: "",
      team: [],
      member_by_team: [],
      raw_member: [],
      project: {
        name: "",
        type: "",
        tag: "",
        customer: "",
        path: "",
        team: [],
        member: [],
      },
    };
  },
  computed: {
    resultQuery() {
      const value = this.search.toLowerCase();
      return this.listItems.filter(function (val) {
        return (
          val.ProjectName.toLowerCase().indexOf(value) > -1 ||
          val.ProjectTag.toLowerCase().indexOf(value) > -1 ||
          val.ProjectType.toLowerCase().indexOf(value) > -1 ||
          val.ProjectCustomer.toLowerCase().indexOf(value) > -1
        );
      });
    },
  },
  methods: {
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    GetMemberByTeam() {
      axios({
        method: "post",
        url: IP + "/task/get-member-by-team",
        headers: {
          Authorization: AuthKey,
        },
        data: {
          team: this.project.team,
        },
      }).then((response) => {
        const result = response.data;
        // set unique member_by_team object version
        this.member_by_team = Array.from(
          new Set(result.map((s) => s.EmployeeEmail))
        ).map((EmployeeEmail) => {
          return {
            EmployeeEmail: EmployeeEmail,
            EmployeeName: result.find((s) => s.EmployeeEmail === EmployeeEmail)
              .EmployeeName,
          };
        });
        // remove owner from object
        this.member_by_team = this.member_by_team.filter(function (e) {
          return e.EmployeeEmail != this.user.EmployeeEmail;
        }, this);

        // for loop push member to project obj
        this.member_by_team.forEach(function (val, i) {
          this.project.member.push(val.EmployeeEmail);
        }, this);
        // set unique data
        this.project.member = this.project.member.filter(this.onlyUnique);
      });
    },
    CreateProject() {
      this.error = "";
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.CheckProject(this.project.path).then((response) => {
            if (!response) {
              Swal.fire({
                title: "Are you sure ?",
                text: "คุณต้องการสร้างโปรเจคหรือไม่ !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              }).then(async (result) => {
                if (result.value) {
                  this.project.email = this.user.EmployeeEmail;
                  this.project.member.push(this.user.EmployeeEmail);
                  axios({
                    method: "post",
                    url: IP + "/task/create-project",
                    headers: {
                      Authorization: AuthKey,
                    },
                    data: {
                      project: this.project,
                    },
                  }).then(async (response) => {
                    if (response.data == "success") {
                      await this.Clear();
                      await this.GetProject();
                      Swal.fire("สร้างโปรเจคเสร็จสิ้น !", "", "success");
                    }
                  });
                }
              });
            } else {
              this.error = `Project name "${this.project.name}" has been already.`;
            }
          });
        }
      });
    },
    CheckProject(path) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/check-project",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            path: path,
          },
        }).then((response) => {
          const result = response.data;
          if (result.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    GetTeamByEmail() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/get-team-by-email",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.user.EmployeeEmail,
          },
        }).then((response) => {
          this.team = response.data;
          resolve(true);
        });
      });
    },
    GetMember() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "get",
          url: IP + "/task/get-member",
          headers: {
            Authorization: AuthKey,
          },
        }).then((response) => {
          const result = response.data;
          this.raw_member = Array.from(
            new Set(result.map((s) => s.EmployeeEmail))
          ).map((EmployeeEmail) => {
            return {
              EmployeeEmail: EmployeeEmail,
              EmployeeName: result.find(
                (s) => s.EmployeeEmail === EmployeeEmail
              ).EmployeeName,
            };
          });
          // remove owner from object
          this.raw_member = this.raw_member.filter(function (e) {
            return e.EmployeeEmail != this.user.EmployeeEmail;
          }, this);
          resolve(true);
        });
      });
    },
    CreatePath(name) {
      var replaced = name.split(" ").join("-");
      //   var lower = replaced.toLowerCase();
      this.project.path = replaced;
      return this.project.path;
    },
    goToTask(path, id, name) {
      this.$router.push({
        name: "task",
        params: {
          path: path,
        },
      });
    },
    Clear() {
      this.error = "";
      this.project = {
        name: "",
        type: "",
        tag: "",
        customer: "",
        path: "",
      };
      this.dialog = false;
      this.$validator.reset();
    },
    GetProject() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/get-project",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.user.EmployeeEmail,
          },
        }).then((response) => {
          this.listItems = response.data;
          resolve(true);
        });
      });
    },
    async start() {
      this.loading = true;
      this.user = this.$store.getters.currentUser;
      await this.GetProject().then(async (response) => {
        this.GetTeamByEmail().then(async (response) => {
          this.GetMember().then(async (response) => {
            var that = this;
            setTimeout(function () {
              that.loading = false;
            }, 1000);
          });
        });
      });
    },
  },
  created() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../css/transition.scss";

.main-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>