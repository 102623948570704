<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- header -->
    <v-app-bar :clipped-left="true" app color="green" dark>
      <div>
        <v-list-item-title>
          <v-icon small style="margin: -3px 5px 0px 0px">mdi-account</v-icon>
          {{ CutEmail(exam.exam.Examiner) }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-icon small style="margin: -3px 5px 0px 0px"
            >mdi-file-document-edit-outline</v-icon
          >
          {{ exam.exam_info.ExamTitle }} |
          {{ exam.exam_part[session].ExamPartTitle }}
        </v-list-item-subtitle>
      </div>
      <v-spacer></v-spacer>
      <div style="text-align: right">
        <v-list-item-title style="font-size: 25px" v-if="!unlimited">
          <v-icon style="margin: -3px 0px 0px 0px">mdi-clock-outline</v-icon>
          {{ time.hour }} : {{ time.minute }} : {{ time.second }}
        </v-list-item-title>
        <v-list-item-title style="font-size: 25px" v-if="unlimited">
          <v-icon style="margin: -3px 0px 0px 0px">mdi-clock-outline</v-icon
          >Unlimited
        </v-list-item-title>
        <v-list-item-subtitle style="margin-top: -3px" v-if="!unlimited"
          >Duration :
          {{
            DurationTime(exam.exam_info.TimingInterval)
          }}</v-list-item-subtitle
        >
        <v-list-item-subtitle style="margin-top: -3px" v-if="unlimited"
          >Duration : Unlimited</v-list-item-subtitle
        >
      </div>
    </v-app-bar>
    <!-- navigation -->
    <div v-for="(n, mid) in exam.exam_part" :key="mid">
      <v-navigation-drawer
        :clipped="true"
        permanent
        app
        width="90"
        v-if="session == mid"
      >
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title style="text-align: center; font-size: 14px"
                >Question</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in n.QuestionSetData.Question"
            :key="index"
            link
            @click="article = index"
            :class="index === article ? 'list-focus' : ''"
          >
            <v-list-item-content>
              <v-list-item-title style="text-align: center; font-size: 20px">{{
                index + 1
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <!-- exam area -->
      <div
        :style="`margin-top: 100px; width: ${
          width - 200
        }px; margin-left: 140px;`"
        v-if="session == mid"
      >
        <v-card
          :style="`padding: 20px; min-height: ${height - 250}px`"
          v-for="(item, index) in n.QuestionSetData.Question"
          :key="index"
          v-show="index == article"
        >
          <v-card-title>{{ item.QuestionTitle }}</v-card-title>
          <v-card-subtitle
            style="margin-top: 10px"
            v-html="item.QuestionDescription"
          ></v-card-subtitle>
          <!-- multiple choice radio -->
          <div class="choice-area" v-if="item.QuestionType == 'multiplechoice'">
            <v-radio-group
              v-model="item.answer"
              @change="PutAnswer(item.answer, index)"
              :disabled="disable"
            >
              <div
                v-for="(ch, ch_index) in item.QuestionChoices"
                :key="ch_index"
              >
                <span style="float: left; margin-right: 10px; margin-top: 30px"
                  >{{ list_choice[ch_index] }}.</span
                >
                <v-radio :value="ch_index">
                  <template slot="label">
                    <div class="output ql-snow" style="color: black">
                      <div
                        class="ql-editor"
                        style="padding-top: 30px"
                        v-html="ch"
                      ></div>
                    </div>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </div>
          <!-- subjective -->
          <div class="choice-area" v-if="item.QuestionType == 'subjective'">
            <v-textarea
              name="input-7-1"
              label="Answer"
              style="margin-top: 20px"
              v-model="item.answer"
              outlined
              v-on:change="PutAnswer(item.answer, index)"
            ></v-textarea>
          </div>
        </v-card>
      </div>
    </div>
    <!-- bottom menu -->
    <v-container style="margin-left: 100px">
      <v-row>
        <v-col cols="12" xs="12" sm="2" md="2" lg="1" xl="1">
          <p style="margin-top: 5px" v-if="width > 600">
            <b>Question</b>
          </p>
          <p style="margin-bottom: -10px" v-if="width <= 600">
            <b>Question</b>
          </p>
        </v-col>
        <v-col
          cols="5"
          xs="5"
          sm="4"
          md="1"
          lg="1"
          xl="1"
          style="text-align: right"
        >
          <v-btn
            block
            color="primary"
            @click="article--"
            :disabled="article == 0"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="2"
          xs="2"
          sm="2"
          md="1"
          lg="1"
          xl="1"
          style="text-align: center"
        >
          <p style="margin-top: 5px">
            <b>{{ article + 1 }}</b>
          </p>
        </v-col>
        <v-col
          cols="5"
          xs="5"
          sm="4"
          md="1"
          lg="1"
          xl="1"
          style="text-align: center"
        >
          <v-btn
            block
            color="primary"
            @click="article++"
            :disabled="
              article ==
              exam.exam_part[session].QuestionSetData.Question.length - 1
            "
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" v-if="width <= 430">
          <p style="margin-bottom: -10px; margin-top: -10px">
            <b>Section</b>
          </p>
        </v-col>
        <v-col
          cols="6"
          xs="6"
          sm="6"
          md="3"
          lg="3"
          xl="3"
          style="text-align: right"
        >
          <v-btn
            color="primary"
            block
            :disabled="session == 0"
            v-if="exam.exam_part.length > 1"
            @click="Session('prev')"
          >
            <v-icon style="margin-right: 10px">mdi-arrow-left</v-icon>
            <span v-if="width > 550">Prev Section</span>
            <span v-if="width <= 550 && width > 430">Section</span>
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          xs="6"
          sm="6"
          md="3"
          lg="3"
          xl="3"
          style="text-align: left"
        >
          <v-btn
            color="primary"
            block
            :disabled="session == exam.exam_part.length - 1"
            v-if="exam.exam_part.length > 1"
            @click="Session('next')"
          >
            <span v-if="width > 550">Next Section</span>
            <span v-if="width <= 550 && width > 430">Section</span>
            <v-icon style="margin-left: 10px">mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="1"
          lg="2"
          xl="2"
          style="text-align: right"
        >
          <v-btn
            color="success"
            block
            @click="Finish"
            :disabled="finish_disable"
            >Finish</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { WindowResize } from "../../function/WindowResize";
import axios from "axios";
import { IP, AuthKey } from "../../config";

export default {
  data() {
    return {
      loading: false,
      width: 0,
      height: 0,
      session: 0,
      duration: 0,
      article: 0,
      time: {
        minute: 0,
        second: 0,
        hour: 0,
      },
      count_alert: 0,
      count_sync: 0,
      unlimited: false,
      disable: false,
      finish_disable: false,
      ready: false,
      list_choice: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  props: ["exam"],
  mounted() {
    setInterval(() => {
      if (this.ready) {
        this.Timer();
      }
    }, 1000);
    if (this.ready) {
      this.Timer();
    }
  },
  methods: {
    Session(menu) {
      this.article = 0;
      if (menu == "next") {
        this.session = this.session + 1;
      } else if (menu == "prev") {
        this.session = this.session - 1;
      }
    },
    PutAnswer(item, question) {
      this.disable = true;
      axios({
        method: "PUT",
        url: `${IP}/exam/put/answer/${this.exam.exam.ID}`,
        headers: {
          Authorization: AuthKey,
        },
        data: {
          session_no: this.session,
          answer: item,
          question_no: question,
        },
      }).then((response) => {
        this.disable = false;
        console.log("put answers: ", response.data);
      });
    },
    Finish() {
      this.$swal
        .fire({
          title: "Are you sure you want to Finish the exam ?",
          text: `Exam once finished cannot be resumed.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then(async (result) => {
          if (result.value) {
            this.finish_disable = true;
            this.$emit("finish", this.exam);
          }
        });
    },
    async ConvertBase64ToString(str) {
      // return await atob(str);
      if (str != null) {
        str = str.replace(/\s/g, "");
        return decodeURIComponent(escape(window.atob(str)));
      }
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    DurationTime(time) {
      time = time * 3600;
      let minute = ("0" + (parseInt(time / 60) % 60)).slice(-2);
      let hour = ("0" + parseInt(time / 3600)).slice(-2);
      return `${hour} : ${minute}`;
    },
    CutEmail(email) {
      let split = email.split("@");
      return split[0];
    },
    Timer() {
      console.log("interval: ", this.exam.exam_info.TimingInterval);
      if (this.exam.exam_info.TimingInterval != null) {
        if (this.duration > 0) {
          this.duration = this.duration - 1000;
          this.count_sync++;
          if (this.count_sync > 60) {
            // console.log('sync!')
            this.GetServerTime().then(() => {
              this.CheckTimeOut();
            });
            this.count_sync = 0;
          }

          let seconds = this.duration / 1000;
          let minutes = seconds / 60;
          let hours = minutes / 60;

          this.time.hour = ("0" + parseInt(hours % 24)).slice(-2);
          this.time.minute = ("0" + parseInt(minutes % 60)).slice(-2);
          this.time.second = ("0" + parseInt(seconds % 60)).slice(-2);
          // console.log("duration: ", this.duration);
        } else {
          if (this.count_alert == 0) {
            alert("exam time is expired!");
            this.finish_disable = true;
            this.$emit("finish", this.exam);
            this.count_alert++;
          }
        }
      } else {
        this.unlimited = true;
      }
    },
    async SetUpData() {
      this.loading = true;
      for (var i = 0; i < this.exam.exam_part.length; i++) {
        let question = this.exam.exam_part[i].QuestionSetData.Question;
        for (var x = 0; x < question.length; x++) {
          // question[x].QuestionChoices = await atob(question[x].QuestionChoices);
          // question[x].QuestionDescription = await atob(
          //   question[x].QuestionDescription
          // );
          // question[x].QuestionTitle = await atob(question[x].QuestionTitle);
          question[x].QuestionChoices = await this.ConvertBase64ToString(
            question[x].QuestionChoices
          );
          question[x].QuestionDescription = await this.ConvertBase64ToString(
            question[x].QuestionDescription
          );
          question[x].QuestionTitle = await this.ConvertBase64ToString(
            question[x].QuestionTitle
          );
          try {
            question[x].QuestionChoices = JSON.parse(
              question[x].QuestionChoices
            );
          } catch (err) {
            // console.log(err);
          }
        }
        this.exam.exam_part[i].QuestionSetData.Question = question;
      }
      if (this.exam.exam.Answer != null) {
        let answers = JSON.parse(this.exam.exam.Answer);
        for (var y = 0; y < this.exam.exam_part.length; y++) {
          let question = this.exam.exam_part[y].QuestionSetData.Question;
          for (var z = 0; z < question.length; z++) {
            question[z].answer = answers[y][z];
            if (question[z].QuestionType == "subjective") {
              // question[z].answer = await atob(question[z].answer);
              question[z].answer = await Buffer.from(
                question[z].answer,
                "base64"
              ).toString();
            }
          }
          this.exam.exam_part[y].QuestionSetData.Question = question;
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    CheckTimeOut() {
      return new Promise(async (resolve, reject) => {
        if (this.exam_out_time != null && this.exam.exam.ExamBegin != null) {
          let exam_out_time = new Date(this.exam_out_time);
          let begin = new Date(this.exam.exam.ExamBegin);
          let used_time = exam_out_time.getTime() - begin.getTime();
          let combine_time = 3600000 * this.exam.exam_info.TimingInterval;
          let continue_time = combine_time - used_time;
          this.duration = continue_time;
          // delete 3000 millisecond
          this.duration = this.duration - 3000;
          this.duration = parseInt(this.duration);
          // console.log("duration: ", this.duration);
          resolve(true);
        } else {
          resolve(true);
        }
      });
    },
    GetServerTime() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/getServerTime`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            // console.log("get server time: ", response.data);
            this.exam_out_time = response.data;
            resolve(true);
          });
      });
    },
    SetDuration() {
      if (this.exam.exam_info.TimingInterval != null) {
        if (this.exam.exam.ExamBegin != null) {
          this.GetServerTime().then(() => {
            this.CheckTimeOut().then((response) => {
              this.ready = true;
            });
          });
        } else {
          this.duration = this.exam.exam_info.TimingInterval * 3600000;
          // delete 3000 millisecond
          this.duration = this.duration - 3000;
          this.duration = parseInt(this.duration);
          console.log("duration: ", this.duration);
          this.ready = true;
        }
      } else {
        this.unlimited = true;
      }
    },
    Start() {
      this.resize();
      this.SetUpData();
      this.SetDuration();
      console.log("take exam start! : ", this.exam);
    },
  },
  created() {
    this.resize();
    this.Start();
  },
};
</script>

<style lang="scss" scoped>
.list-focus {
  background: rgb(207, 207, 207);
}

.choice-area {
  border-top: 1px solid rgb(197, 197, 197);
  padding: 20px;
}
</style>