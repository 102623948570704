<template>
  <div class="form-page">
    <v-container class="d-flex flex-column justify-center">
      <v-card
        hover
        class="pa-5"
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
        outlined
      >
        <v-list-item-content>
          <v-text-field
            v-model="quiz.quizName"
            label="Quiz Name"
            :rules="rules"
          ></v-text-field>
          <v-text-field
            v-model="quiz.quizDescription"
            label="Quiz Description"
          ></v-text-field>
        </v-list-item-content>
      </v-card>
      <div
        class="mt-5"
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
        outlined
        v-for="(section, sectionIndex) in quiz.sections"
        :key="sectionIndex"
      >
        <v-card hover cols="12" xs="12" sm="6" md="4" lg="4" xl="3" outlined>
          <div class="mt-2 d-flex flex-row-reverse">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon color="blue-grey">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, i) in itemsMenu"
                  :key="i"
                  @click="action(item, sectionIndex)"
                >
                  <input
                    v-show="false"
                    ref="inputUpload"
                    type="file"
                    @change="handleFile(sectionIndex)"
                  />
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-list-item>
            <v-list-item-content>
              <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                <v-select
                  :item-text="sectionItems.text"
                  :item-value="sectionItems.value"
                  :items="sectionItems"
                  :value="section.quizTypeId"
                  v-model="section.quizTypeId"
                  label="Section Type"
                  outlined
                ></v-select>
              </v-col>

              <v-text-field
                v-model="section.quizSectionDescription"
                label="Section Description"
              ></v-text-field>

              <v-text-field
                class="pb-10"
                v-model="section.sectionTime"
                hide-details
                single-line
                type="number"
                label="Section Time (minutes) "
                dense
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
        </v-card>

        <v-card
          hover
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          class="pa-5 mt-5"
          outlined
          v-if="isShowTitle"
        >
          <div class="d-flex flex-row-reverse">
            <v-icon @click="deleteTitle(sectionIndex)">{{ deleteIcon }}</v-icon>
          </div>
          <v-text-field
            v-model="section.quizSectionPassage"
            label="Passage"
          ></v-text-field>
        </v-card>
        <v-card
          class="mx-auto pa-5 mt-5"
          max-width="800"
          outlined
          v-for="(image, imageIndex) in image_preview"
          :key="imageIndex"
        >
          <div class="d-flex flex-row-reverse">
            <v-icon @click="deleteImage(sectionIndex, imageIndex)">{{
              deleteIcon
            }}</v-icon>
          </div>
          <v-img
            width="300"
            height="300"
            :src="image"
            :lazy-src="image"
            aspect-ratio="1"
            class="grey lighten-2"
            v-if="image != ''"
          />
        </v-card>
        <v-card
          hover
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          class="pa-5 mt-5"
          v-for="(question, questionIndex) in section.questions"
          :key="questionIndex"
          outlined
        >
          <div class="d-flex flex-row-reverse">
            <v-icon @click="deleteQuestion(sectionIndex, questionIndex)">{{
              deleteIcon
            }}</v-icon>
          </div>

          <v-list-item>
            <div>
              <v-row class="flex-row align-center">
                <v-col>
                  <inputText
                    label="Question"
                    v-model="question.questionContent"
                  ></inputText>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="4" lg="4" xl="3">
                  <v-select
                    :items="items"
                    :value="question.questionType"
                    v-model="question.questionType"
                    label="Question Type"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <div>
                <v-row
                  class="flex-row align-center ml-5"
                  cols="12"
                  sm="12"
                  v-for="(choice, chocieIndex) in question.choices"
                  :key="chocieIndex"
                >
                  <inputText
                    v-model="choice.choiceContent"
                    label="Choice"
                  ></inputText>
                  <v-icon
                    @click="
                      deleteChoice(sectionIndex, questionIndex, chocieIndex)
                    "
                    class="ml-5 mr-5"
                    >{{ closeIcon }}</v-icon
                  >
                </v-row>
              </div>

              <v-row class="flex-row">
                <v-btn
                  rounded
                  color="primary"
                  dark
                  @click="addChoice(sectionIndex, questionIndex)"
                >
                  <v-icon class="mr-2">{{ addIcon }}</v-icon
                  >Add choice</v-btn
                >
              </v-row>
              <div
                v-if="
                  quiz.sections[sectionIndex].questions[questionIndex]
                    .questionType == 'Paragraph'
                "
              >
                <v-text-field
                  label="Long answer"
                  hide-details="auto"
                  disabled
                ></v-text-field>
              </div>
            </div>
          </v-list-item>
        </v-card>
      </div>
      <div>
        <v-col class="text-center">
          <v-btn color="success" @click="onSubmit">Submit</v-btn>
        </v-col>
      </div>
      <div>{{ quiz }}</div>
    </v-container>
  </div>
</template>

<script>
import { mdiTrashCan } from "@mdi/js";
import { mdiClose } from "@mdi/js";
import { mdiPlusCircle } from "@mdi/js";
import inputText from "@/components/POC/Input.vue";
import { mdiViewStream } from "@mdi/js";
import { mdiPlusBox } from "@mdi/js";
import { mdiFormatTitle } from "@mdi/js";
import { mdiImage } from "@mdi/js";
import axios from "axios";
import { IP, AuthKey } from "../../config";
export default {
  name: "form-page",
  components: {
    inputText,
  },
  data() {
    return {
      isShowTitle: false,
      itemsMenu: [
        { title: "Add section" },
        { title: "Add question" },
        { title: "Add title" },
        { title: "Add image" },
        { title: "Delete section" },
      ],
      addSectionIcon: mdiViewStream,
      addQuestionIcon: mdiPlusBox,
      addTitleIcon: mdiFormatTitle,
      addImageIcon: mdiImage,
      image_preview: [],
      quiz: {
        quizName: "Untitle",
        quizDescription: "",
        sections: [
          {
            quizSectionDescription: "",
            sectionTime: null,
            quizTypeId: "Writing",
            quizSectionPassage: "",
            titles: [],
            questionImage: [],
            questions: [],
          },
        ],
      },

      rules: [(value) => !!value || "Required."],
      show: false,
      items: ["Paragraph", "Multiple choice"],
      sectionItems: [
        { text: "Reading", value: "1" },
        { text: "Writing", value: "2" },
        { text: "Logical", value: "3" },
      ],
      deleteIcon: mdiTrashCan,
      closeIcon: mdiClose,
      addIcon: mdiPlusCircle,
      hover: false,
    };
  },
  methods: {
    action(item, sectionIndex) {
      if (item.title == "Add section") {
        this.addSection(sectionIndex);
      } else if (item.title == "Add question") {
        this.addQuestion(sectionIndex);
      } else if (item.title == "Add title") {
        this.addTitle(sectionIndex);
      } else if (item.title == "Add image") {
        this.inputFile(sectionIndex);
      } else if (item.title == "Delete section") {
        if (this.quiz.sections.length) this.deleteSection(sectionIndex);
      }
    },
    onSubmit() {
      let quiz = this.quiz;
      console.log("obj", quiz);
      axios({
        method: "post",
        url: IP + "/api/v1/quiz",
        headers: {
          Authorization: AuthKey,
        },
        data: quiz,
      }).then((response) => {
        console.log(response.data);
      });
    },
    toBase64(file) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    addSection(sectionIndex) {
      let obj = {
        sectionTime: null,
        quizSectionDescription: "",
        quizTypeId: "Writing",
        quizSectionPassage: "",
        questionImage: [],
        questions: [],
      };
      this.quiz.sections.splice(sectionIndex + 1, 0, obj);
    },
    deleteSection(sectionIndex) {
      if (sectionIndex > 0) {
        this.quiz.sections.splice(sectionIndex, 1);
      }
    },
    addTitle(sectionIndex) {
      this.isShowTitle = true;
      this.quiz.sections[sectionIndex].quizSectionPassage = "";
    },
    deleteTitle(sectionIndex) {
      this.quiz.sections[sectionIndex].quizSectionPassage = "";
      this.isShowTitle = false;
    },
    inputFile(sectionIndex) {
      this.$refs.inputUpload[sectionIndex].click();
    },
    async handleFile(sectionIndex, imageIndex) {
      this.quiz.sections[sectionIndex].questionImage.push(
        event.target.files[0]
      );
      this.image_preview.push(await this.toBase64(event.target.files[0]));
    },

    deleteImage(sectionIndex, imageIndex) {
      this.quiz.sections[sectionIndex].questionImage.splice(imageIndex, 1);
      this.image_preview.splice(imageIndex, 1);
    },

    addQuestion(sectionIndex) {
      let obj = {
        questionContent: "",
        questionType: "Multiple choice",
        image: "",
        choices: [],
        choiceStatus: false,
      };
      this.quiz.sections[sectionIndex].questions.push(obj);
    },
    deleteQuestion(sectionIndex, questionIndex) {
      this.quiz.sections[sectionIndex].questions.splice(questionIndex, 1);
    },
    addChoice(sectionIndex, questionIndex) {
      let choices =
        this.quiz.sections[sectionIndex].questions[questionIndex].choices;
      choices.push({
        choiceContent: "",
        image: "",
      });
      this.checkStatusChoice(choices, sectionIndex, questionIndex);
    },
    deleteChoice(sectionIndex, questionIndex, choiceIndex) {
      let choices =
        this.quiz.sections[sectionIndex].questions[questionIndex].choices;
      choices.splice(choiceIndex, 1);
      this.checkStatusChoice(choices, sectionIndex, questionIndex);
    },
    checkStatusChoice(choices, sectionIndex, questionIndex) {
      if (choices.length == 0) {
        this.quiz.sections[sectionIndex].questions[
          questionIndex
        ].choiceStatus = false;
      } else {
        this.quiz.sections[sectionIndex].questions[
          questionIndex
        ].choiceStatus = true;
      }
    },
  },
};
</script>

<style>
.form-page {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
}
</style>
