<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <p style="font-size: 30px">
              Project Management
              <v-chip class="ma-2" color="primary" label dark> Mokcup </v-chip>
            </p>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-select
                :items="project_size_items"
                label="Project Size"
                outlined
                v-model="detailItems.project_size"
                dense
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-select
                :items="project_priority_items"
                label="Project Priority"
                outlined
                v-model="detailItems.project_priority"
                dense
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Planned Headcount"
                v-model="detailItems.planned_headcount"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Planned Mandays"
                v-model="detailItems.planned_mandays"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-menu
                v-model="planned_start_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.planned_start_date"
                    label="Planned Start Date"
                    append-icon="event"
                    readonly
                    :color="color"
                    v-on="on"
                    name="planned-start-date"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.planned_start_date"
                  @input="planned_start_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-menu
                v-model="planned_close_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.planned_close_date"
                    label="Planned Close Date"
                    append-icon="event"
                    readonly
                    :color="color"
                    v-on="on"
                    name="planned-close-date"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.planned_close_date"
                  @input="planned_close_date_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-menu
                v-model="planned_extend_close_date_i_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.planned_extend_close_date_i"
                    label="Planned Extend Close Date I"
                    append-icon="event"
                    readonly
                    :color="color"
                    v-on="on"
                    name="planned-extend-close-date-i"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.planned_extend_close_date_i"
                  @input="planned_extend_close_date_i_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-menu
                v-model="planned_extend_close_date_ii_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.planned_extend_close_date_ii"
                    label="Planned Extend Close Date II"
                    append-icon="event"
                    readonly
                    :color="color"
                    v-on="on"
                    name="planned-extend-close-date-ii"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.planned_extend_close_date_ii"
                  @input="planned_extend_close_date_ii_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="detailItems.project_member"
                :items="project_member_items"
                label="Project Member"
                multiple
                hide-details
                outlined
                small-chips
                :readonly="readonly"
                :color="color"
              >
                <template v-slot:selection="{ item }">
                  <v-chip>
                    {{ item }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="detailItems.project_note"
                outlined
                dense
                hide-details
                rows="3"
                label="Project Note"
                :readonly="readonly"
                :color="color"
                style="margin-bottom: 10px"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import data_mapping_json from "../../json/sale_orders/project_management.json";
import moment from "moment";

export default {
  data() {
    return {
      readonly: this.ro,
      panel: [0],
      color: "",
      data: JSON.parse(JSON.stringify(this.dt)),
      detailItems: {
        project_member: ["Member 1", "Member 2", "Member 3"],
      },
      project_size_items: ["Small", "Medium", "Large"],
      project_priority_items: ["Priority 1", "Priority 2", "Priority 3"],
      project_member_items: ["Member 1", "Member 2", "Member 3", "Member 4"],
      planned_start_date_menu: false,
      planned_close_date_menu: false,
      planned_extend_close_date_i_menu: false,
      planned_extend_close_date_ii_menu: false,
    };
  },
  props: ["ro", "dt"],
  methods: {
    PrepareData() {
      return new Promise(async (resolve, reject) => {
        for (let dt_json in data_mapping_json) {
          if (data_mapping_json[dt_json] != "") {
            if (dt_json.includes("date")) {
              this.detailItems[dt_json] = moment(
                JSON.parse(
                  JSON.stringify(this.data[data_mapping_json[dt_json]])
                )
              ).format("YYYY-MM-DD");
            } else {
              this.detailItems[dt_json] = JSON.parse(
                JSON.stringify(this.data[data_mapping_json[dt_json]])
              );
            }
          }
        }
      });
    },
  },
  created() {
    if (this.ro) {
      this.color = "grey-lighten-5";
    }
  },
};
</script>