<template>
  <div class="row" style="margin-top: 20px;">
    <div class="col-4">
      <div style="height: 50px;">
        <h3 style="float: left;">TO DO</h3>
        <v-btn icon style="float: right;" @click="add">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <hr />
      <draggable class="list-group" :list="list1" group="people" v-bind="dragOptions" @change="log">
        <div
          class="list-group-item"
          v-for="element in list1"
          :key="element.name"
          @click="information('info', element)"
        >
          <span>{{ element.name }}</span>
          <v-btn icon style="float: right; margin-top: -6px;" @click="remove(element)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </draggable>
    </div>

    <div class="col-4">
      <div style="height: 50px;">
        <h3 style="float: left;">DOING</h3>
      </div>
      <hr />
      <draggable class="list-group" :list="list2" group="people" v-bind="dragOptions" @change="log">
        <div
          class="list-group-item"
          v-for="element in list2"
          :key="element.name"
          @click="information('info', element)"
        >
          <span>{{ element.name }}</span>
          <v-btn icon style="float: right; margin-top: -6px;" @click="remove(element)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </draggable>
    </div>

    <div class="col-4">
      <div style="height: 50px;">
        <h3 style="float: left;">DONE</h3>
      </div>
      <hr />
      <draggable class="list-group" :list="list3" group="people" v-bind="dragOptions" @change="log">
        <div
          class="list-group-item"
          v-for="element in list3"
          :key="element.name"
          @click="information('info', element)"
        >
          <span>{{ element.name }}</span>
          <v-btn icon style="float: right; margin-top: -6px;" @click="remove(element)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </draggable>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  data() {
    return {
      list1: [
        { name: "Task 1", id: 1 },
        { name: "Task 2", id: 2 },
        { name: "Task 3", id: 3 },
        { name: "Task 4", id: 4 }
      ],
      list2: [],
      list3: []
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    add() {
      console.log("add!");
      let index = this.list1.length + this.list2.length + this.list3.length;
      index = index + 1;
      this.list1.push({
        name: `Task ${index}`,
        id: index
      });
    },
    remove(item) {
      console.log("remove!", item);
      for (var i = 0; i < this.list1.length; i++) {
        if (this.list1[i].id === item.id) {
          this.list1.splice(i, 1);
        }
      }
      for (var x = 0; x < this.list2.length; x++) {
        if (this.list2[x].id === item.id) {
          this.list2.splice(x, 1);
        }
      }
      for (var y = 0; y < this.list3.length; y++) {
        if (this.list3[y].id === item.id) {
          this.list3.splice(y, 1);
        }
      }
    },
    information(menu, item) {
      if (menu == "info") {
        console.log('info! ', item);
      }
    },
    log: function(evt) {
      window.console.log(evt);
    }
  }
};
</script>

<style scoped lang="scss">
.list-group {
  padding: 20px;
}

.list-group-item {
  border: 1px solid gray;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
</style>