<template>
  <div class="timer">
    <span>Time : {{ time | prettify }}</span>
  </div>
</template>

<script>
export default {
  name: "timers",
  props: ["time"],

  filters: {
    prettify: function(value) {
      // console.log('value', value)
      let data = value.split(":");
      let minutes = data[0];
      let secondes = data[1];
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (secondes < 10) {
        secondes = "0" + secondes;
      }
      // let currentTime = minutes + ":" + secondes
      // localStorage.setItem('currentTime',currentTime)
      // this.time = localStorage.getItem('currentTime')
      // return localStorage.getItem('currentTime');
      return minutes + ":" + secondes;
    }
  }
};
</script>

<style></style>
