<template>
  <div class="qc-applicant">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div class="main-content" v-if="!loading">
      <div style="border-bottom: 1px solid rgb(216, 216, 216)">
        <v-row>
          <v-col style="font-size: 30px"
            >QC Applicant : {{ job_info.Position }}</v-col
          >
        </v-row>
      </div>
      <v-text-field
        v-model="search"
        label="search"
        append-icon="mdi-magnify"
        single-line
        hide-details
        v-if="applicants.length > 0"
      ></v-text-field>
      <br />
      <v-data-table
        :headers="headers"
        :items="applicants"
        :search="search"
        class="elevation-1"
        v-if="applicants.length > 0"
      >
        <template v-slot:item.keyword="{ item }">
          <v-chip
            small
            class="ma-2"
            color="primary"
            text-color="white"
            v-for="(n, index) in item.AnalysisValue.string_match"
            :key="index"
            >{{ n }}</v-chip
          >
        </template>
        <template v-slot:item.work_experience="{ item }">
          <div
            style="color: red"
            v-for="date in item.AnalysisValue.experience.without_final"
            :key="date.index"
          >
            {{ date.value }}
          </div>
          <div
            style="color: green"
            v-for="(final, x) in item.AnalysisValue.experience.final_date"
            :key="x"
          >
            {{ final }}
          </div>
          <!-- {{ item.AnalysisValue.experience.final_date }} -->
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom style="z-index: 999">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="DownloadResume(item)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Download Resume</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../../config";
import download from "downloadjs";

export default {
  data() {
    return {
      user: "",
      job_info: null,
      applicants: [],
      loading: false,
      search: "",
      headers: [
        { text: "Name", value: "Name" },
        { text: "Email", value: "AnalysisValue.email" },
        { text: "GPA", value: "AnalysisValue.gpa" },
        { text: "Toeic", value: "AnalysisValue.toeic" },
        { text: "Total date in Resume", value: "work_experience" },
        { text: "Keyword", value: "keyword" },
        { text: "Actions", align: "right", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    DownloadResume(item) {
      console.log("download: ", item.OnedriveFileID);
      axios
        .get(`${IP}/applicant/download/${item.OnedriveFileID}`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((response) => {
          // console.log('response: ', response.data)
          fetch(response.data, {
            method: "get",
            headers: {
              Authorization: AuthKey,
            },
          }).then((res) => {
            const blob = res.blob();
            blob.then(async (result) => {
              await download(result, item.Filename);
            }, this);
          });
        });
    },
    GetJobDetailByID() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/qc-applicant/get-job/${this.$route.params.id}`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then(async (response) => {
            let result = response.data;
            if (result.length > 0) {
              result[0].Condition = await this.ConvertBase64ToString(
                result[0].Condition
              );
              result[0].Condition = JSON.parse(result[0].Condition);
            }
            this.job_info = result[0];
            console.log("job info: ", this.job_info);
            resolve(true);
          });
      });
    },
    GetApplicantByJob() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/qc-applicant/get-applicant-by-job`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            position: this.job_info.Position,
          },
        }).then(async (response) => {
          let result = response.data;
          for (var i = 0; i < result.length; i++) {
            result[i].AnalysisValue = await this.ConvertBase64ToString(
              result[i].AnalysisValue
            );
            result[i].AnalysisValue = JSON.parse(result[i].AnalysisValue);
            result[i].ExtractString = await this.ConvertBase64ToString(
              result[i].ExtractString
            );
          }
          this.applicants = result;
          console.log("applicant: ", this.applicants);
          resolve(true);
        });
      });
    },
    async ConvertBase64ToString(str) {
      if (str != null) {
        str = str.replace(/\s/g, "");
        return decodeURIComponent(escape(window.atob(str)));
      }
    },
    Start() {
      this.loading = true;
      this.user = this.$store.getters.currentUser;
      this.GetJobDetailByID().then(() => {
        this.GetApplicantByJob().then(() => {
          this.loading = false;
        });
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      console.log("id: ", this.$route.params.id);
      this.Start();
    } else {
      this.$router.push("/qc-applicant");
    }
  },
};
</script>

<style>
.qc-applicant {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>