const moment = require('moment')
const XLSX = require('xlsx')

const Others = {
    ConvertDate(date) {
        var convert = moment(date).subtract(7, 'hour').format("MMMM Do YYYY, h:mm:ss a");
        return convert
    },
    CreateDataJsonArray(headerJsonArray, data) {
        // console.log("Call create data json")
        const dataJsonArray = headerJsonArray;
        // Part add data to json array by map to the header json to get the key
        for (const key in data) {
            const jsonData = data[key];
            // Order the data to suit the header above
            const pushData = {};
            for (const key in headerJsonArray) {
                if (headerJsonArray.hasOwnProperty(key)) {
                    for (value in headerJsonArray[key]) {
                        pushData[value] = jsonData[value];
                    }
                }
            }
            dataJsonArray.push(pushData);
        }
        // console.log(dataJsonArray);
        return dataJsonArray;
    },
    getCsvText(objJsonArray) {
        const array =
            typeof objJsonArray !== "object" ? JSON.parse(objJsonArray) : objJsonArray;
        let csvStr = "";
        for (let i = 0; i < array.length; i++) {
            let line = "";
            for (const index in array[i]) {
                let str = `${array[i][index]}`;
                let n = str.includes(",");
                // if (array[i][index] != null && n) {
                line += '"' + `${array[i][index]}` + '"' + ",";
                //   console.log(n);
                // } else {
                //     line += array[i][index] + ",";
                // }
            }
            csvStr += line + "\r\n";
        }
        return csvStr;
    },
    async CreateBlobCSV(headerJsonArray, data) {
        // dataJsonArray stand for array of json init with header
        const dataJsonArray = this.CreateDataJsonArray(headerJsonArray, data)
        // console.log(dataJsonArray);
        const csv = this.getCsvText(dataJsonArray);
        const blob = new Blob([csv], {
            type: "text/csv;charset=utf-8;"
        });
        return blob
    },
    async dowloadCSV(headerJsonArray, data, fileName) {
        var jsonArray = [];
        jsonArray.push(headerJsonArray);
        const exportedFilenmae = fileName + ".csv" || "export.csv";
        const blob = await this.CreateBlobCSV(jsonArray, data);
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = "hidden";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    },
    randomNumber(min, max) {
        return Math.random() * (max - min) + min;
    },
    ConvertCurrency(int) {
        return int.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    GenerateKeyID(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    },
    formatCurrency(value, dec) {
        dec = dec || 0
        if (value === null) {
            return 0
        }
        return '' + value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
    },
    ExportData(type, raw, filename){
        const data = XLSX.utils.json_to_sheet(raw);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data);
        XLSX.writeFile(wb, `${filename}.${type}`)
    },
    DownloadURI(uri, name){
        const link = document.createElement('a')
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
    }
}

module.exports = {
    Others
}