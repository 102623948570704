<template>
  <div class="main-area">
    <AlertMessage :data="message" :key="AlertMessageKey" />
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Sale Order</v-col>
        <v-col
          cols="12"
          xs="12"
          sm="auto"
          md="auto"
          v-if="permission.export.value"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                color="success"
                v-bind="attrs"
                v-on="on"
                :loading="export_loading"
                :disabled="export_loading || listItems.length == 0"
              >
                <v-icon class="mr-2">mdi-file-export-outline</v-icon>export
              </v-btn>
            </template>
            <v-list style="text-align: center">
              <v-list-item @click="Export('xlsx')">
                <v-list-item-title>XLSX</v-list-item-title>
              </v-list-item>
              <v-list-item @click="Export('csv')">
                <v-list-item-title>CSV</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn
            color="success"
            block
            @click="initialize"
            :disabled="loading || export_loading"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <AdvanceFilterV2 :headers="merge_headers" @update-data="UpdateFilterData" />
    <v-card-title>
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="listItems"
      :single-expand="true"
      :expanded.sync="expanded"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{ itemsPerPageOptions: this.rowsPerPageItems }"
      :server-items-length="itemsSize"
      item-key="SONumber"
      :loading="loading"
      show-expand
      class="elevation-1"
      expand-icon="mdi-menu-down"
      style="margin-bottom: 50px"
    >
      <!-- custom main column -->
      <template v-slot:item.SOType="{ item }">
        {{ SOTypeMapping(item.SOType) }}
      </template>
      <template v-slot:item.SODate="{ item }">
        {{ ConvertDate(item.SODate) }}
      </template>
      <template v-slot:item.SOAmountUntaxed="{ item }">
        {{ ConvertCurrency(item.SOAmountUntaxed) }}
      </template>
      <template v-slot:item.Status="{ item }">
        <v-chip
          class="ma-2"
          :color="StatusColor(item.Status, 'so')"
          small
          text-color="white"
          v-if="item.Status != null && item.Status != ''"
        >
          {{ item.Status }}
        </v-chip>
        <v-chip
          class="ma-2"
          color="grey-darken-4"
          small
          text-color="white"
          v-else
        >
          No Status
        </v-chip>
      </template>
      <template v-slot:item.AttachmentStatus="{ item }">
        <v-chip
          class="ma-2"
          :color="StatusColor(item.AttachmentStatus, 'attachment')"
          small
          text-color="white"
          v-if="item.AttachmentStatus != null && item.AttachmentStatus != ''"
        >
          {{ item.AttachmentStatus }}
        </v-chip>
        <v-chip class="ma-2" color="black" small text-color="white" v-else>
          Never before
        </v-chip>
      </template>
      <!-- expanded -->
      <template v-slot:expanded-item="{ headers, item }">
        <td
          style="padding: 0"
          v-if="width >= mobileBreakPoint"
          :colspan="headers.length"
          class="td-expand"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="(row, rindex) in expandedHeader" :key="rindex">
                    {{ row.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-for="(row, rindex) in expandedHeader" :key="rindex">
                    <span v-if="row.type == 'text'">
                      {{ item[row.value] }}
                    </span>
                    <span v-if="row.type == 'date'">
                      {{ ConvertDate(item[row.value]) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
        <template v-else>
          <div style="margin: 15px -5px 15px 15px">
            <v-row
              style="width: 100%"
              v-for="(row, rindex) in expandedHeader"
              :key="rindex"
            >
              <v-col cols="6"
                ><b>{{ row.text }}</b></v-col
              >
              <v-col cols="6" style="text-align: right">
                <span v-if="row.type == 'text'">
                  {{ item[row.value] }}
                </span>
                <span v-if="row.type == 'date'">
                  {{ ConvertDate(item[row.value]) }}
                </span>
              </v-col>
            </v-row>
          </div>
        </template>
      </template>
      <template v-slot:item.Actions="{ item }">
        <div style="width: 80px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-1"
                v-on="on"
                small
                @click="Detail('watch', item)"
                v-if="permission.watch.value"
                >mdi-eye</v-icon
              >
            </template>
            <span>Watch</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-1"
                v-on="on"
                small
                @click="Detail('edit', item)"
                v-if="permission.edit.value"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-1"
                v-on="on"
                small
                @click="FilesDialog(item)"
                v-if="permission.files.value"
                >mdi-file</v-icon
              >
            </template>
            <span>Files</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <!-- dialog -->
    <FileTable
      :filesDialog="filesDialog"
      :so="SOSelected"
      :key="filesDialogKey"
      @close="fileDialogUpdate"
    />
  </div>
</template>

<script>
import moment from "moment";
import { Others } from "../function/Others";
import { WindowResize } from "../function/WindowResize.js";
import { TpAxios } from "../helpers/tp_axios.js";
import FileTable from "../components/SaleOrder/FileTable.vue";
import { Permissions } from "../function/Permissions";
// json
import headers_items_json from "../json/sale_orders/list/headers_items.json";
import expanded_headers_items_json from "../json/sale_orders/list/expanded_headers_items.json";
import status_items_json from "../json/sale_orders/list/status_items.json";
import so_type_items_json from "../json/sale_orders/list/so_type_items.json";

export default {
  data() {
    return {
      user: "",
      loading: false,
      export_loading: false,
      ready: false,
      search: "",
      filter_str: "",
      headers: headers_items_json,
      expandedHeader: expanded_headers_items_json,
      merge_headers: [],
      FilterItems: [],
      listItems: [],
      EmployeeListItems: [],
      page: 1,
      itemsPerPage: 10,
      itemsSize: 0,
      rowsPerPageItems: [10, 20, 50],
      SOSelected: "",
      filesDialog: false,
      filesDialogKey: null,
      AlertMessageKey: null,
      expanded: [],
      mobileBreakPoint: 600,
      width: 0,
      timerId: null,
      status_items: status_items_json,
      so_type_items: so_type_items_json,
      message: [],
      // permissions
      list_permission: null,
      user_permission: null,
      permission: {
        list: {
          id: [0],
          value: "",
        },
        financial: {
          id: [5],
          value: false,
        },
        watch: {
          id: [3, 5],
          value: false,
        },
        edit: {
          id: [4],
          value: false,
        },
        files: {
          id: [6],
          value: false,
        },
        export: {
          id: [2],
          value: false,
        },
      },
    };
  },
  components: {
    FileTable,
  },
  watch: {
    search(val) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.loading = true;
        this.page = 1;
        this.itemsPerPage = 10;
        this.$router
          .push({
            query: {
              page: this.page,
              limit: this.itemsPerPage,
            },
          })
          .catch(() => {});
        this.GetSaleOrders().then((response) => {
          this.loading = false;
        });
      }, 1000);
    },
    page(val) {
      // console.log('page')
      this.loading = true;
      this.$router
        .push({
          query: {
            page: val,
            limit: this.itemsPerPage,
          },
        })
        .catch(() => {});
      this.GetSaleOrders().then((response) => {
        this.loading = false;
      });
    },
    itemsPerPage(val) {
      // console.log('limit')
      this.loading = true;
      this.$router
        .push({
          query: {
            page: this.page,
            limit: val,
          },
        })
        .catch(() => {});
      this.GetSaleOrders().then((response) => {
        this.loading = false;
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === from.name) {
      this.initialize();
    }
    next();
  },
  methods: {
    GetAllEmployee() {
      return new Promise(async (resolve, reject) => {
        this.EmployeeListItems = [];
        TpAxios.Get(`/employee-select-all`).then((response) => {
          this.EmployeeListItems = response.recordset;
          resolve(true);
        });
      });
    },
    GetSaleOrders(
      page = this.page,
      size = this.itemsPerPage,
      search = this.search,
      filter = this.filter_str
    ) {
      return new Promise(async (resolve, reject) => {
        this.listItems = [];
        this.message = [];
        let sales_name = "";
        if (this.list_permission != null) {
          this.page = parseInt(this.$route.query.page);
          this.itemsPerPage = parseInt(this.$route.query.limit);
          page = this.$route.query.page;
          size = this.$route.query.limit;
          // console.log('page: ', page, ' size: ', size)
          if (size === -1) {
            size = this.itemsSize;
          }
          if (search != "") {
            search = `&search[any]=${search}`;
          }
          if (filter != "") {
            filter = `&where=${filter}`;
          }
          if (this.list_permission === "only-yours") {
            sales_name = `&sales_name=${this.user.EmployeeName}`;
          }
          TpAxios.Get(
            `/sale-orders/get?page=${page}&size=${size}&order=desc${search}${filter}${sales_name}`
          ).then((response) => {
            console.log("res: ", response);
            if (response.message != undefined) {
              this.AlertMessage("error", response.message);
            } else {
              this.listItems = response.data;
              this.itemsSize = response.total;
            }
            resolve(true);
          });
        } else {
          resolve(false);
        }
      });
    },
    async Export(type) {
      this.export_loading = true;
      this.message = [];
      let date = moment().format();
      let filename = `sale_orders_report_${date}`;
      TpAxios.Get(`/sale-orders/get/all`).then(async (response) => {
        if (response.message != undefined) {
          this.AlertMessage("error", response.message);
        } else {
          let data = response.data;
          console.log("data: ", data);
          for (var i = 0; i < data.length; i++) {
            let so_type = so_type_items_json.find(
              (x) => x.id === data[i].SOType
            );
            if (so_type != undefined) {
              data[i].SOType = `${data[i].SOType} - ${so_type.name}`;
            }
          }
          console.log("export: ", data);
          await Others.ExportData(type, data, filename);
        }
        this.export_loading = false;
      });
    },
    StatusColor(status, type) {
      let color = "";
      for (var i = 0; i < this.status_items.length; i++) {
        if (type === this.status_items[i].type) {
          if (status === this.status_items[i].name) {
            color = this.status_items[i].color;
          }
        }
      }
      return color;
    },
    SOTypeMapping(id) {
      let name = "";
      for (var i = 0; i < this.so_type_items.length; i++) {
        if (id === this.so_type_items[i].id) {
          name = this.so_type_items[i].name;
        }
      }
      return name;
    },
    UpdateFilterData(result) {
      // console.log("update filter data: ", result);
      this.filter_str = result;
      this.loading = true;
      this.GetSaleOrders().then((response) => {
        this.loading = false;
      });
    },
    Detail(action, item) {
      let name = "";
      let params = {};
      // console.log("item: ", item);
      if (action == "watch") {
        name = "sale-orders-read";
        params = {
          so_number: item.SONumber,
        };
      } else if (action == "edit") {
        name = "sale-orders-edit";
        params = {
          so_number: item.SONumber,
        };
      }
      this.$router.push({
        name: name,
        params: params,
      });
    },
    async FilesDialog(item) {
      this.SOSelected = item.SONumber;
      this.filesDialogKey = await Others.GenerateKeyID(10);
      this.filesDialog = true;
    },
    // Delete(item) {
    //   console.log("delete: ", item);
    // },
    AlertMessage(type, text) {
      this.message.push({
        type: type,
        text: text,
      });
      this.AlertMessageKey = Others.GenerateKeyID(10);
    },
    fileDialogUpdate(e) {
      this.filesDialog = e;
    },
    ConvertDate(date) {
      if (date === "" || date === null || date === undefined) {
        return "";
      } else {
        return moment(date).format("ll");
      }
    },
    resize() {
      this.width = window.innerWidth - 35;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth() - 35;
        },
        this
      );
    },
    PermissionsInit() {
      return new Promise(async (resolve, reject) => {
        this.user = this.$store.getters.currentUser;
        this.user_permission = await Permissions.GetRoleAndPermissions(
          "SaleOrders"
        );
        this.permission = await Permissions.SetAccess(
          this.user_permission,
          this.permission
        );
        // .specific
        if (this.user_permission.permissions.length > 0) {
          this.list_permission = this.user_permission.permissions.find(
            (x) => x.id === 0
          );
        }
        if (this.list_permission === undefined) {
          this.list_permission = null;
        }
        if (Array.isArray(this.list_permission.specific)) {
          if (this.list_permission.specific.length > 0) {
            this.list_permission = this.list_permission.specific[0];
          }
        } else {
          // console.log('else')
          this.list_permission = this.list_permission.specific;
        }
        console.log("user permission: ", this.user_permission);
        console.log("feature permission: ", this.permission);
        console.log("list permission: ", this.list_permission);
        resolve(true);
      });
    },
    async InsertHeaders() {
      let array_obj = [
        {
          text: "SO Amount Untaxed",
          value: "SOAmountUntaxed",
          type: "text",
          align: "right",
          sortable: false,
        }
      ];
      let headers = JSON.parse(JSON.stringify(this.headers))
      for(var i = 0; i < array_obj.length; i++){
        let find = headers.find((n) => n.value === array_obj[i].value)
        if(find == undefined){
          headers.splice(9, 0, ...array_obj)
        }
      }
      this.headers = headers;
      return true;
    },
    ConvertCurrency(value) {
      if (value != null && value != undefined) {
        return Others.ConvertCurrency(value);
      } else {
        return "";
      }
    },
    async initialize() {
      this.loading = true;
      this.merge_headers = this.headers.concat(this.expandedHeader);
      await this.PermissionsInit();
      await this.GetAllEmployee();
      await this.GetSaleOrders();
      if (this.permission.financial.value) {
        await this.InsertHeaders();
      }
      this.loading = false;
    },
  },
  mounted() {
    if (this.$route.query.page == null) {
      // console.log('mounted')
      this.$router
        .replace({
          query: {
            page: this.page,
            limit: this.itemsPerPage,
          },
        })
        .catch(() => {});
    } else {
      // console.log('not mounted')
      this.page = parseInt(this.$route.query.page);
      this.itemsPerPage = parseInt(this.$route.query.limit);
    }
  },
  async created() {
    this.resize();
    await this.initialize();
  },
};
</script>

<style scoped lang="scss">
.main-area {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>