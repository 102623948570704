<template>
<div class="main-area">
<div v-html="tabelauLoginHTML">
    
</div>
</div>
</template>

<script>


export default {
  data() {
    return {
      tabelauLoginHTML: `<div class='tableauPlaceholder' style='width: 1366px; height: 603px;'>
      <object class='tableauViz' width='1366' height='603' style='display:none;'>
      <param name='host_url' value='https%3A%2F%2F10az.online.tableau.com%2F' /> 
      <param name='embed_code_version' value='3' /> 
      <param name='site_root' value='&#47;t&#47;stelligence' />
      <param name='name' value='STelligenceAcademyLearningReport&#47;OverviewDashboard' />
      <param name='tabs' value='yes' /><param name='toolbar' value='yes' />
      <param name='showAppBanner' value='false' />
      </object>
      </div>`    
    };
  },
    created() {
    let scriptTag = document.createElement("script");
    scriptTag.setAttribute("src","https://10az.online.tableau.com/javascripts/api/viz_v1.js")
    document.head.appendChild(scriptTag);
  }
};
</script>

<style scoped lang="scss">
@import "../../css/stelligence.scss";
</style>