<template>
  <div class="applicant">
    <v-container>
      <v-card class="mx-auto" max-width="100%" outlined>
        <div align="center" style="padding: 1rem">
          <v-list-item-title class="headline mb-1"
            >ข้อมูลผู้ที่มาสมัครงาน</v-list-item-title
          >
        </div>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="Detial(item)">visibility</v-icon>
            <v-icon small class="mr-2" @click="DownloadResume(item)"
              >mdi-download</v-icon
            >
            <v-icon small class="mr-2" @click="Delete(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card>

      <v-row justify="center">
        <v-dialog v-model="dialog" scrollable max-width="900px">
          <v-card>
            <v-card-title>
              <span class="headline">Profile</span>
            </v-card-title>

            <v-card-text class="dialog-area">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Firstname"
                      v-model="applicantDetail.Firstname"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Lastname"
                      v-model="applicantDetail.Lastname"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Email"
                      v-model="applicantDetail.Email"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Position"
                      v-model="applicantDetail.Position"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      label="Tel."
                      v-model="applicantDetail.Tel"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headerEducations"
                      :items="educations"
                      class="elevation-1"
                    ></v-data-table>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headerWorkExps"
                      :items="workExps"
                      class="elevation-1"
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="Close">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { apiURL, IP, AuthKey } from "../../config";
import download from "downloadjs";

export default {
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Firstname", value: "Firstname" },
        { text: "Lastname", value: "Lastname" },
        { text: "Email", value: "Email" },
        { text: "Position", value: "Position" },
        { text: "Tel.", value: "Tel" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      educations: [],
      workExps: [],
      headerEducations: [
        { text: "ระดับการศึกษา", value: "EducationLevel" },
        { text: "สถานศึกษา", value: "Institution" },
        { text: "สาขาวิชา", value: "Major" },
        { text: "ผลการศึกษา", value: "Result" },
      ],
      headerWorkExps: [
        { text: "ชื่อบริษัท", value: "Company" },
        { text: "ตั้งแต่", value: "StartDate" },
        { text: "สิ้นสุด", value: "EndDate" },
        { text: "ตำแหน่ง", value: "Position" },
        { text: "เงินเดือน", value: "Salary" },
      ],
      desserts: [],
      StatusMessage: {},
      applicantDetail: {
        Employment: {},
        Personal: {},
        Education: [],
        WorkExp: [],
        Language: {},
        Special: {},
      },
      defaultDetail: {
        Employment: {},
        Personal: {},
        Education: [],
        WorkExp: [],
        Language: {},
        Special: {},
      },
      color: {},
    };
  },
  mounted() {
    this.getAll();
  },
  created() {
    this.getAll();
  },
  methods: {
    DownloadResume(item) {
      // console.log('download: ', item.ResumeFile)
      let file = item.ResumeFile;
      let filename = file.split(",");
      axios
        .get(`${IP}/applicant/download/${item.ResumeFile}`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((response) => {
          // console.log('response: ', response.data)
          fetch(response.data, {
            method: "get",
            headers: {
              Authorization: AuthKey,
            },
          }).then((res) => {
            const blob = res.blob();
            blob.then(async (result) => {
              await download(result, filename[1]);
            }, this);
          });
        });
    },
    ConvertCurrency(int) {
      return int.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    Detial(e) {
      this.applicantDetail = e;
      axios
        .get(`${IP}/applicant/detail/${e.ID}`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((response) => {
          let result = response.data;
          for (var i = 0; i < result.work_exp.length; i++) {
            result.work_exp[i].StartDate = this.$moment(
              result.work_exp[i].StartDate
            ).format("DD MMMM YYYY");
            result.work_exp[i].EndDate = this.$moment(
              result.work_exp[i].EndDate
            ).format("DD MMMM YYYY");
          }

          this.educations = result.education;
          this.workExps = result.work_exp;
          this.dialog = true;
        });
    },
    Close(e) {
      this.dialog = false;
      this.$nextTick(() => {
        this.applicantDetail = Object.assign({}, this.defaultDetail);
      });
    },
    Delete(e) {
      this.$swal({
        title: "Are you sure delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          axios
            .delete(`${IP}/applicant/delete/${e.ID}`, {
              headers: {
                Authorization: AuthKey,
              },
            })
            .then((res) => {
              this.$swal("Deleted!", "", "success");
              this.getAll();
            });
        }
      });
    },
    getAll() {
      axios
        .get(`${IP}/applicant/get`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((response) => {
          this.desserts = response.data;
          console.log(this.desserts);
        });
    },
    getColor(e) {
      if (e == true) return "green";
      if (e == false) return "orange";
    },
    getStatusMessage(e) {
      if (e == true) return (this.StatusMessage.done = "ทำเทสแล้ว");
      if (e == false) return (this.StatusMessage.notdone = "ยังไม่ได้ทำเทส");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../css/listApplicant.scss";
.applicant {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
}
</style>