<template>
  <div class="read-blog">
    <div class="title-content">{{ Decode(news_data.NewsTitle) }}</div>
    <v-row>
      <v-col cols="auto">
        <v-list-item>
          <v-list-item-avatar
            color="grey"
            @click="goToProfile(news_data.NewsCreatedBy)"
            style="cursor: pointer"
          >
            <span
              v-if="
                news_data.ProfileImage == null ||
                news_data.ProfileImage == 'null'
              "
              style="color: white"
              >{{ short(news_data.NewsCreatedBy) }}</span
            >
            <v-img
              v-if="
                news_data.ProfileImage != null &&
                news_data.ProfileImage != 'null'
              "
              :src="`${IP}/profile_img/${news_data.ProfileImage}`"
              :lazy-src="`${IP}/profile_img/${news_data.ProfileImage}`"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">{{
              news_data.EmployeeName
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              news_data.NewsCreatedBy
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-subtitle>
            <v-icon small style="margin-top: -3px; margin-right: 5px"
              >mdi-eye</v-icon
            >
            {{ news_data.Views }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-col>
      <v-col>
        <v-img
          src="https://img.icons8.com/color/48/000000/line-me.png"
          lazy-src="https://img.icons8.com/color/48/000000/line-me.png"
          width="30px"
          class="mx-auto"
          style="float: right; cursor: pointer; margin-top: 20px"
          @click="dialog = !dialog"
          v-if="
            user.Role == 'Admin' ||
            user.EmployeeEmail == news_data.NewsCreatedBy
          "
        ></v-img>
      </v-col>
    </v-row>
    <div class="title-img">
      <v-img
        :src="`${IP}/news_file/${title_img}`"
        :lazy-src="`${IP}/news_file/${title_img}`"
        width="100%"
        max-width="700"
        class="mx-auto"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="description" v-if="news_data.NewsDescription != ''">
      <v-icon style="margin-top: -10px" size="18">mdi-format-quote-open</v-icon>
      {{ Decode(news_data.NewsDescription) }}
      <v-icon style="margin-top: -10px" size="18"
        >mdi-format-quote-close</v-icon
      >
    </div>
    <br />
    <froalaView
      v-model="news_data.NewsContent"
      v-if="editorTool == '' || editorTool == null"
    ></froalaView>
    <div class="output ql-snow" v-if="editorTool != '' || editorTool != null">
      <div class="ql-editor" v-html="news_data.NewsContent"></div>
    </div>
    <br />
    <br />

    <!-- dialog -->
    <v-dialog width="100%" max-width="500" v-model="dialog">
      <v-card>
        <v-card-title>Send To STelligence Group</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row style="margin: -10px 0px -20px 0px">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Description"
                outlined
                v-model="line.description"
                name="description"
              />
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="LineNotify"
            style="margin: 10px"
            width="200"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-send</v-icon>Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <hr />
    <br />
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="8" xl="8">
        <h2>Comment</h2>
        <Comment v-bind:BlogID="news_data.NewsID" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
        <h2>Others Blog</h2>
        <Recommend />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey, webURL } from "../../config";
import Comment from "./Comment";
import Recommend from "./RecommendBlog";

export default {
  data() {
    return {
      IP: IP,
      webURL: webURL,
      user: {
        Role: "",
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
        },
      },
      editorTool: "",
      loading: false,
      news_data: "",
      title_img: "",
      dialog: false,
      line: {
        description: "",
        url: "",
        email: "",
      },
    };
  },
  components: {
    Comment,
    Recommend,
  },
  methods: {
    short(email) {
      var result = "";
      if (email != undefined) {
        var res = email.substr(0, 1);
        result = res.charAt(0).toUpperCase();
      }
      return result;
    },
    goToProfile(email) {
      this.$router.push({
        name: "profile",
        params: {
          email: email,
        },
      });
    },
    LineNotify() {
      var url = `${webURL}/blog/read/${this.$route.params.id}`;
      this.line.url = url;
      this.line.email = this.user.EmployeeEmail;
      axios({
        method: "post",
        url: IP + "/line/notify",
        headers: {
          Authorization: AuthKey,
        },
        data: {
          line: this.line,
        },
      }).then((response) => {
        if (response.data.statusCode == "200") {
          this.dialog = false;
          alert("Share to group success!");
        }
      });
    },
    Decode(data) {
      console.log("decode : ", data);
      var result = "";
      if (data != undefined) {
        result = Buffer.from(data, "base64").toString();
      }
      return result;
    },
    GetNewsByPath() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/select-news-by-path",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            path: this.$route.params.id,
          },
        }).then(async (response) => {
          this.news_data = response.data;
          this.editorTool = response.data.EditorTool;
          // console.log(this.news_data);
          if (this.news_data.PublishStatus == "STelligence") {
            if (this.user.EmployeeEmail == null) {
              this.$router.push("/");
            } else {
              this.title_img = response.data.NewsBackgroundImage;
              this.news_data.NewsContent = await this.Decode(
                this.news_data.NewsContent
              );
            }
          } else if (this.news_data.PublishStatus == "Draft") {
            if (this.news_data.NewsCreatedBy == this.user.EmployeeEmail) {
              this.title_img = response.data.NewsBackgroundImage;
              this.news_data.NewsContent = await this.Decode(
                this.news_data.NewsContent
              );
            } else {
              this.$router.push("/");
            }
          } else {
            // console.log("news data: ", this.news_data);
            this.title_img = response.data.NewsBackgroundImage;
            this.news_data.NewsContent = await this.Decode(
              this.news_data.NewsContent
            );
          }
          resolve(true);
        });
      });
    },
    GetVisitHistory(obj) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/news/get-visit-history`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            cookie: obj.cookie,
            email: obj.user,
            news_id: obj.news_id,
          },
        }).then((response) => {
          const result = response.data;
          if (result.length > 0) {
            resolve([true, result]);
          } else {
            resolve(false, null);
          }
        });
      });
    },
    InsertVisit(obj) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/news/insert-visit`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            obj: obj,
          },
        }).then((response) => {
          // console.log(response.data);
          resolve(true);
        });
      });
    },
    UpdateVisit(obj) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/news/update-visit`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            obj: obj,
          },
        }).then((response) => {
          // console.log(response.data);
          resolve(true);
        });
      });
    },
    UpdateViews() {
      return new Promise(async (resolve, reject) => {
        this.news_data.Views = this.news_data.Views + 1;
        axios({
          method: "post",
          url: `${IP}/news/update-view`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            news_id: this.news_data.NewsID,
            view: this.news_data.Views,
          },
        }).then((response) => {
          // console.log(response.data);
          resolve(true);
        });
      });
    },
    Visitor() {
      let cookie = document.cookie;
      if (cookie != undefined && cookie != "") {
        let user = "Guest";
        if (this.user.EmployeeEmail != undefined) {
          user = this.user.EmployeeEmail;
        }
        let obj = {
          cookie: cookie,
          news_id: this.news_data.NewsID,
          user: user,
        };

        this.GetVisitHistory(obj).then((response) => {
          if (response[0]) {
            let data = response[1][0];
            let today = new Date();
            let date = new Date(data.LastAccess);
            today.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);
            if (today > date) {
              this.UpdateVisit(obj).then((response) => {
                this.UpdateViews();
              });
            }
          } else {
            this.InsertVisit(obj).then((response) => {
              this.UpdateViews();
            });
          }
        });
      }
    },
    start() {
      this.GetNewsByPath().then((response) => {
        this.Visitor();
      });
    },
  },
  created() {
    window.scrollTo(0, 0);
    if (this.$route.params.id) {
      if (this.$store.getters.currentUser) {
        this.user = this.$store.getters.currentUser;
      }
      this.start();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped lang="scss">
.read-blog {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.title-content {
  font-size: 40px;
}

.title-img {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.description {
  width: 60%;
  min-width: 200px;
  margin: 0 auto;
  padding: 30px;
  font-size: 14px;
  color: gray;
  text-align: center;
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 40rem;
    overflow: hidden;
  }

  .output {
    width: 100%;
    height: 20rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;

    &.code {
      padding: 1rem;
      height: 16rem;
    }

    &.ql-snow {
      border-top: none;
      height: 24rem;
    }
  }
}
</style>