<template>
  <div class="drawer">
    <v-navigation-drawer
      id="core-navigation-drawer"
      v-model="drawer"
      mobile-breakpoint="960"
      absolute
      temporary
      right
      style="padding-top: 70px; position: fixed"
      width="300"
      :height="`${height}`"
    >
      <div
        style="
          position: fixed;
          background: white;
          z-index: 50;
          padding: 20px 40px 20px 20px;
          width: 100%;
        "
      >
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </div>
      <v-row style="margin-top: 70px">
        <v-col cols="12">
          <v-list-item
            two-line
            v-for="n in resultQuery"
            :key="n.ID"
            @click="goToProfile(n.EmployeeEmail)"
          >
            <v-list-item-avatar color="grey">
              <span
                v-if="n.ProfileImage == null || n.ProfileImage == 'null'"
                style="color: white"
                >{{ short(n.EmployeeEmail) }}</span
              >
              <v-img
                v-if="n.ProfileImage != null && n.ProfileImage != 'null'"
                :src="`${IP}/profile_img/${n.ProfileImage}`"
                :lazy-src="`${IP}/profile_img/${n.ProfileImage}`"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ n.EmployeeName }}</v-list-item-title>
              <v-list-item-subtitle>{{ n.EmployeeEmail }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { IP, AuthKey, webURL } from "../../config";
import axios from "axios";
import { WindowResize } from "../../function/WindowResize.js";

export default {
  data() {
    return {
      IP: IP,
      user: "",
      employee: [],
      search: "",
      width: 0,
      height: 0,
    };
  },
  computed: {
    resultQuery() {
      const value = this.search.toLowerCase();
      return this.employee.filter(function (val) {
        if (val.Facebook != null && val.Line != null) {
          return (
            val.EmployeeEmail.toLowerCase().indexOf(value) > -1 ||
            val.EmployeeName.toLowerCase().indexOf(value) > -1 ||
            val.Facebook.toLowerCase().indexOf(value) > -1 ||
            val.Line.toLowerCase().indexOf(value) > -1
          );
        } else if (val.Facebook != null && val.Line == null) {
          return (
            val.EmployeeEmail.toLowerCase().indexOf(value) > -1 ||
            val.EmployeeName.toLowerCase().indexOf(value) > -1 ||
            val.Facebook.toLowerCase().indexOf(value) > -1
          );
        } else if (val.Facebook == null && val.Line != null) {
          return (
            val.EmployeeEmail.toLowerCase().indexOf(value) > -1 ||
            val.EmployeeName.toLowerCase().indexOf(value) > -1 ||
            val.Line.toLowerCase().indexOf(value) > -1
          );
        } else {
          return (
            val.EmployeeEmail.toLowerCase().indexOf(value) > -1 ||
            val.EmployeeName.toLowerCase().indexOf(value) > -1
          );
        }
      });
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
  methods: {
    goToProfile(email) {
      //   const url = `${webURL}/profile/${email}`
      //     window.location = url
      this.$router.push({
        name: "profile",
        params: {
          email: email,
        },
      });
    },
    Test(item) {
      console.log(item);
    },
    short(email) {
      var res = email.substr(0, 1);
      var result = res.charAt(0).toUpperCase();
      return result;
    },
    GetAllEmployeeInformation() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "get",
          url: IP + "/user-management/get-all-employee",
          headers: {
            Authorization: AuthKey,
          },
        }).then((response) => {
          let employee = response.data
          let array = []
          if(employee.length > 0){
            for(var i = 0; i < employee.length; i++){
              if(employee[i].Status == 'Active'){
                array.push(employee[i])
              }
            }
            this.employee = array;
          }
          // this.employee = response.data;
        });
      });
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    this.GetAllEmployeeInformation();
    this.resize();
  },
};
</script>

<style scoped>
.drawer {
  z-index: 200;
}
</style>