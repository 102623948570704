<template>
  <div>
    <div
      class="loading"
      v-if="!ready"
      style="text-align: center; padding: 30px"
    >
      Loading...
    </div>
    <div v-if="ready">
      <div
        v-for="(n, index) in clean_data"
        :key="index"
        style="padding: 10px; border-bottom: 1px solid rgb(230, 230, 230)"
      >
        <div style="margin-top: 10px; float: right">
          <v-chip class="ma-2" color="blue" text-color="white">
            Part No.
            <v-avatar right class="white" style="color: blue">
              {{ n.part_no + 1 }}
            </v-avatar>
          </v-chip>
          <v-chip class="ma-2" color="red" text-color="white">
            Question No.
            <v-avatar right class="white" style="color: red">
              {{ n.question_no + 1 }}
            </v-avatar>
          </v-chip>
        </div>
        <br />
        <b>{{ n.question_title }}</b>
        <br />
        <br />
        <p v-html="n.question_description" />
        <br />
        <p><b>Answer</b></p>
        <p>{{ n.answer }}</p>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="UpdateStatus(false)">
          <v-icon class="mr-2">mdi-close-circle-outline</v-icon>
          Fail</v-btn
        >
        <v-btn color="success" @click="UpdateStatus(true)">
          <v-icon class="mr-2">mdi-check-circle-outline</v-icon>
          Pass</v-btn
        >
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../../config";

export default {
  data() {
    return {
      exam_data: null,
      ready: false,
      clean_data: [],
    };
  },
  props: ["exam"],
  methods: {
    UpdateStatus(status) {
      this.$swal
        .fire({
          title: "Are you sure you want to update exam result ?",
          text: ``,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then(async (result) => {
          if (result.value) {
            this.exam.Score.writing_question.is_pass = status;
            this.exam.Score.writing_question.is_progress = true;

            axios({
              method: "POST",
              url: `${IP}/exam/update-writing-status`,
              headers: {
                Authorization: AuthKey,
              },
              data: {
                exam: this.exam,
              },
            }).then((response) => {
              this.$swal.fire("Update exam result success!", "", "success");
              this.$emit("update-data", true);
            });
          }
        });
    },
    async ConvertBase64ToString(str) {
      if (str != null) {
        str = str.replace(/\s/g, "");
        return decodeURIComponent(escape(window.atob(str)));
      }
    },
    GetExamSource(id) {
      return new Promise(async (resolve, reject) => {
        console.log("id: ", id);
        axios({
          method: "POST",
          url: `${IP}/exam/get-source`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            id: id,
          },
        }).then(async (response) => {
          this.exam_data = response.data;
          console.log("exam source: ", this.exam_data);
          resolve(true);
        });
      });
    },
    SetUpData() {
      return new Promise(async (resolve, reject) => {
        let answer = JSON.parse(this.exam.Answer);
        for (var i = 0; i < answer.length; i++) {
          for (var x = 0; x < answer[i].length; x++) {
            let question =
              this.exam_data.exam_part[i].QuestionSetData.Question[x];
            if (typeof answer[i][x] == "string") {
              let obj = {
                part_no: i,
                question_no: x,
                question_title: await this.ConvertBase64ToString(
                  question.QuestionTitle
                ),
                question_description: await this.ConvertBase64ToString(
                  question.QuestionDescription
                ),
                answer: await this.ConvertBase64ToString(answer[i][x]),
              };
              this.clean_data.push(obj);
            }
          }
        }
        console.log("clean data: ", this.clean_data);
        resolve(true);
      });
    },
    Start() {
      console.log("check writing: ", this.exam);
      if (this.exam == null) {
        this.$router.push("/exam");
      } else {
        this.GetExamSource(this.exam.ID).then((response) => {
          this.SetUpData().then(() => {
            this.ready = true;
          });
        });
      }
    },
  },
  created() {
    this.Start();
  },
};
</script>