<template>
  <div class="body_page">
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          background: white;
          opacity: 0.5;
          top: 0;
          left: 0;
        "
      ></div>
    </v-dialog>

    <v-row justify="center">
      <v-dialog
        v-model="result"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card class="resultcard">
          <v-toolbar>
            <v-btn icon @click="result = false">
              <v-icon color="black">mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Predictive Result</v-toolbar-title>
          </v-toolbar>
          <div style="width: 100%; height: 100%">
            <div
              v-for="(output, counter) in Output"
              v-bind:key="counter"
              justify="center"
              class="resultpane"
            >
              <v-row>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="pt-2 mt-4"
                  style="font-size: 11px; text-align-last: center"
                >
                  <h1>{{ DealNum }} {{ counter + 1 }}</h1>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="pt-2 mt-4"
                  style="font-size: 8px; text-align-last: left"
                >
                  <h1>Deal Detail</h1>
                </v-col>
                <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.SalespersonCreatedTime"
                    label="SalespersonCreatedTime"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Waiting_Time"
                    label="Waiting_Time"
                    outlined
                    readonly
                    suffix="month"
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.OrgName"
                    label="OrgName"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.ProductName"
                    label="ProductName"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                  <v-text-field
                    v-model="output.DealValue"
                    label="DealValue"
                    outlined
                    readonly
                    suffix="baht"
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                  <v-text-field
                    v-model="output.Total_Activity"
                    label="Total-Activity"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.OrgDoneActivitiesCount"
                    label="OrgDoneActivitiesCount"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.OrgUndoneActivitiesCount"
                    label="OrgUndoneActivitiesCount"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                  <v-text-field
                    v-model="output.TotalDoneAct"
                    label="TotalDoneActivity"
                    outlined
                    readonly
                    suffix="%"
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    v-model="output.DealExpectedCloseDate"
                    label="DealExpectedCloseDate"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    v-model="output.ExpectStatus"
                    label="ExpectedCloseDate Status"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="pt-2 mt-4"
                  style="font-size: 8px; text-align-last: left"
                >
                  <h1>Feature Group @ Deal Group</h1>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Waiting_Time_Group"
                    label="Waiting-Time Group (Time)"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.DealValue_Group"
                    label="DealValue Group (Price)"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Total_Activity_Group"
                    label="Total-Activity Group (Interaction)"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.TotalDone_Group"
                    label="Total-Activity Done Group (% Done Activity)"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Deal_Group_won"
                    label="Deal Won Percent"
                    outlined
                    readonly
                    suffix="%"
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Deal_Group_lost"
                    label="Deal Lost Percent"
                    outlined
                    readonly
                    suffix="%"
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    v-model="output.Deal_Group"
                    label="Deal Group"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  class="pt-2 mt-4"
                  style="font-size: 8px; text-align-last: left"
                >
                  <h1>Existing Org @ Product Status</h1>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Existing_Org"
                    label="Existing Org Status"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Org_won_count"
                    label="Org won count"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Org_lost_count"
                    label="Org lost count"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.ExistingOrg_Winpercent"
                    label="Existing Org Winpercent"
                    outlined
                    readonly
                    suffix="%"
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Existing_Product"
                    label="Existing Product Status"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Product_won_count"
                    label="Product won count"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.Product_lost_count"
                    label="Product lost count"
                    outlined
                    readonly
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="output.ExistingProduct_WinPercent"
                    label="Existing Product Winpercent"
                    outlined
                    readonly
                    suffix="%"
                    style="text-align-last: center"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>

    <div
      class="previous"
      v-for="(applicant, counter) in applicants"
      v-bind:key="counter"
    >
      <v-row>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="pt-2"
          style="font-size: 20px; text-align-last: left"
        >
          <span @click="deleteVisa(counter)"><v-icon>mdi-close</v-icon></span>
        </v-col>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="pt-2"
          style="font-size: 20px; text-align-last: center"
        >
          {{ DealNum }} {{ counter + 1 }}
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-menu
            v-model="applicant.menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="applicant.SalespersonCreatedTime"
                label="SalespersonCreatedTime"
                autofocus
                readonly
                v-on="on"
                append-icon="event"
                :name="`SalespersonCreatedTime-${counter}`"
                v-validate="'required'"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="applicant.SalespersonCreatedTime"
              @input="applicant.menu = false"
              :min="'2018-01-01'"
              :max="saledate.nowDate"
            ></v-date-picker>
          </v-menu>
          <!-- <v-text-field
            label="SalespersonCreatedTime"
            outlined
            v-model="applicant.SalespersonCreatedTime"
            type="text"
            autofocus
            name="SalespersonCreatedTime"
            v-validate="validators.SaleCreateDate"
          ></v-text-field> -->
          <transition name="slide-fade">
            <v-alert
              type="error"
              v-show="errors.has(`SalespersonCreatedTime-${counter}`)"
              style="margin-top: -10px; margin-bottom: 25px"
              >{{ errors.first(`SalespersonCreatedTime-${counter}`) }}</v-alert
            >
          </transition>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-combobox
            v-model="applicant.OrgName"
            :items="OrgNames"
            type="text"
            label="Select OrgName or create a new one"
            style="text-align-last: center"
            v-validate="'required'"
            :name="`OrgName-${counter}`"
          ></v-combobox>
          <transition name="slide-fade">
            <v-alert
              type="error"
              v-show="errors.has(`OrgName-${counter}`)"
              style="margin-top: 7px"
              >{{ errors.first(`OrgName-${counter}`) }}</v-alert
            >
          </transition>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <v-combobox
            v-model="applicant.ProductName"
            :items="ProductNames"
            type="text"
            label="Select ProductName or create a new one"
            style="text-align-last: center"
            v-validate="'required'"
            :name="`ProductName-${counter}`"
          ></v-combobox>
          <transition name="slide-fade">
            <v-alert
              type="error"
              v-show="errors.has(`ProductName-${counter}`)"
              style="margin-top: 7px"
              >{{ errors.first(`ProductName-${counter}`) }}</v-alert
            >
          </transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
          <v-text-field
            label="DealValue"
            outlined
            v-model="applicant.DealValue"
            type="number"
            suffix="baht"
            :name="`DealValue-${counter}`"
            v-validate="'required|min_value:0'"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              type="error"
              v-show="errors.has(`DealValue-${counter}`)"
              style="margin-top: 7px"
              >{{ errors.first(`DealValue-${counter}`) }}</v-alert
            >
          </transition>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
          <v-text-field
            label="OrgDoneActivitiesCount"
            outlined
            v-model="applicant.OrgDoneActivitiesCount"
            type="number"
            :name="`OrgDoneActivitiesCount-${counter}`"
            v-validate="'required|min_value:0 '"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              type="error"
              v-show="errors.has(`OrgDoneActivitiesCount-${counter}`)"
              style="margin-top: 7px"
              >{{ errors.first(`OrgDoneActivitiesCount-${counter}`) }}</v-alert
            >
          </transition>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
          <v-text-field
            label="OrgUndoneActivitiesCount"
            outlined
            v-model="applicant.OrgUndoneActivitiesCount"
            type="number"
            :name="`OrgUndoneActivitiesCount-${counter}`"
            v-validate="'required|min_value:0'"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              type="error"
              v-show="errors.has(`OrgUndoneActivitiesCount-${counter}`)"
              style="margin-top: 7px"
              >{{
                errors.first(`OrgUndoneActivitiesCount-${counter}`)
              }}</v-alert
            >
          </transition>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
          <v-menu
            v-model="applicant.menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="applicant.DealExpectedCloseDate"
                label="DealExpectedCloseDate"
                readonly
                v-on="on"
                append-icon="event"
                :name="`DealExpectedCloseDate-${counter}`"
                v-validate="'required'"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="applicant.DealExpectedCloseDate"
              @input="applicant.menu2 = false"
            ></v-date-picker>
          </v-menu>
          <!-- <v-text-field
            label="DealExpectedCloseDate"
            outlined
            v-model="applicant.DealExpectedCloseDate"
            type="date"
            autofocus
            name="DealExpectedCloseDate"
            v-validate="'required'"
          ></v-text-field> -->
          <transition name="slide-fade">
            <v-alert
              type="error"
              v-show="errors.has(`DealExpectedCloseDate-${counter}`)"
              style="margin-top: 7px"
              >{{ errors.first(`DealExpectedCloseDate-${counter}`) }}</v-alert
            >
          </transition>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="font-size: 20px; text-align-last: center"
      >
        <v-btn x-large @click="addVisa">Add Deal</v-btn>
        <v-btn x-large @click="addSelectdeal" :disabled="isActive"
          >Predict</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import { mdbTbl, mdbTblHead, mdbTblBody } from "mdbvue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Test",
  components: {
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
      check: [],
      applicants: [
        {
          SalespersonCreatedTime: new Date().toISOString().substr(0, 10),
          OrgName: "",
          ProductName: "",
          DealValue: "",
          OrgDoneActivitiesCount: "",
          OrgUndoneActivitiesCount: "",
          DealExpectedCloseDate: new Date().toISOString().substr(0, 10),
          menu: false,
          menu2: false,
        },
      ],
      saledate: {
        nowDate: new Date().toISOString().slice(0, 10),
      },
      row: 0,
      OrgNames: [],
      ProductNames: [],
      count: 0,
      questions: [],
      loading: false,
      result: false,
      isActive: false,
      obj: {},
      IP: IP,
      DealNum: "Deal No.",
      Output: {},
      PipelineName: "Select One",
      TextField: {
        SalespersonCreatedTimeValue: "",
        OrgNameValue: "",
        ProductNameValue: "",
        DealValueValue: "",
        OrgDoneActivitiesCountValue: "",
        OrgUndoneActivitiesCountValue: "",
        DealExpectedCloseDateValue: "",
      },
    };
  },
  methods: {
    async addSelectdeal() {
      return new Promise(async (resolve, reject) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.isActive = true;
            this.loading = true;
            for (let i = 0; i < Object.keys(this.applicants).length; i++) {
              //  this.SelectDeal.push(this.applicants[i])
              this.TextField.SalespersonCreatedTimeValue += String(
                this.applicants[i].SalespersonCreatedTime
              );
              this.TextField.OrgNameValue += this.applicants[i].OrgName.replace(
                /=/g,
                ""
              );
              this.TextField.ProductNameValue += this.applicants[
                i
              ].ProductName.replace(/=/g, "");
              this.TextField.DealValueValue += String(
                this.applicants[i].DealValue
              );
              this.TextField.OrgDoneActivitiesCountValue += String(
                this.applicants[i].OrgDoneActivitiesCount
              );
              this.TextField.OrgUndoneActivitiesCountValue += String(
                this.applicants[i].OrgUndoneActivitiesCount
              );
              this.TextField.DealExpectedCloseDateValue += String(
                this.applicants[i].DealExpectedCloseDate
              );

              this.TextField.SalespersonCreatedTimeValue += "=";
              this.TextField.OrgNameValue += "=";
              this.TextField.ProductNameValue += "=";
              this.TextField.DealValueValue += "=";
              this.TextField.OrgDoneActivitiesCountValue += "=";
              this.TextField.OrgUndoneActivitiesCountValue += "=";
              this.TextField.DealExpectedCloseDateValue += "=";
            }
            this.questions.push(
              {
                name: "SalespersonCreatedTime",
                value: this.TextField.SalespersonCreatedTimeValue.slice(0, -1),
              },
              {
                name: "OrgName",
                value: this.TextField.OrgNameValue.slice(0, -1),
              },
              {
                name: "ProductName",
                value: this.TextField.ProductNameValue.slice(0, -1),
              },
              {
                name: "DealValue",
                value: this.TextField.DealValueValue.slice(0, -1),
              },
              {
                name: "OrgDoneActivitiesCount",
                value: this.TextField.OrgDoneActivitiesCountValue.slice(0, -1),
              },
              {
                name: "OrgUndoneActivitiesCount",
                value: this.TextField.OrgUndoneActivitiesCountValue.slice(
                  0,
                  -1
                ),
              },
              {
                name: "DealExpectedCloseDate",
                value: this.TextField.DealExpectedCloseDateValue.slice(0, -1),
              }
            );
            axios({
              method: "post",
              url: `${IP}/alteryx/trigger`,
              headers: {
                Authorization: AuthKey,
              },
              data: {
                // SelectDeal: this.SelectDeal
                questions: this.questions,
              },
            }).then(async (response) => {
              // console.log(response.data);
              if (response.data == "error") {
                alert("Process failed, Timeout execution");
                this.loading = false;
              } else {
                this.Output = response.data;
                this.loading = false;
                this.result = true;
              }
              this.isActive = false;
              this.applicants = [
                {
                  SalespersonCreatedTime: new Date()
                    .toISOString()
                    .substr(0, 10),
                  OrgName: "",
                  ProductName: "",
                  DealValue: "",
                  OrgDoneActivitiesCount: "",
                  OrgUndoneActivitiesCount: "",
                  DealExpectedCloseDate: new Date().toISOString().substr(0, 10),
                  menu: false,
                  menu2: false,
                },
              ];
              this.TextField = {
                SalespersonCreatedTimeValue: "",
                OrgNameValue: "",
                ProductNameValue: "",
                DealValueValue: "",
                OrgDoneActivitiesCountValue: "",
                OrgUndoneActivitiesCountValue: "",
                DealExpectedCloseDateValue: "",
              };
              this.questions = [];
            });
          }
        });
      });
    },
    addVisa() {
      this.applicants.push({
        SalespersonCreatedTime: new Date().toISOString().substr(0, 10),
        OrgName: "",
        ProductName: "",
        DealValue: "",
        OrgDoneActivitiesCount: "",
        OrgUndoneActivitiesCount: "",
        DealExpectedCloseDate: new Date().toISOString().substr(0, 10),
        menu: false,
        menu2: false,
      });
    },
    deleteVisa(counter) {
      this.applicants.splice(counter, 1);
    },
    async SelectAllDeal() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/pipedrivepresaledb/get-alldeal`, {
            headers: {
              Authorization: AuthKey,
            },
            onUploadProgress: (ProgressEvent) => {},
          })
          .then(async (response) => {
            resolve(response.data);
            this.OrgNames = response.data.map((i) => i.OrgName);
            this.ProductNames = response.data.map((i) => i.ProductName);
            //IF DEAL STATUS Unknow it equal Wating
            // for (let i = 0; i < Object.keys(response.data).length; i++ ) {
            //   this.Existing_Deals.push(Object.values(response.data[i]))
            // }
            // console.log(this.Existing_Deals)
          });
      });
    },
    start() {
      this.SelectAllDeal();
    },
  },
  mounted() {
    this.start();
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body_page {
  padding: 30px;
}

#deal_form {
  text-align: center;
}

.table_form {
  margin-top: 35px;
  text-align: center;
}

input {
  font-size: 15px;
  border: 1px double rgb(102, 97, 96);
  border-radius: 4px;
  margin: auto;
}

button {
  font-size: 20px;
  background-image: url("../../../public/predictive.jpg");
  padding: 0.4rem 1.3rem;
  text-align: center;
  border: 3px solid black;
  cursor: pointer;
  border-radius: 12px;
  margin: 20px;
}

span {
  width: 30px;
  float: right;
  cursor: pointer;
}

span:hover {
  color: brown;
}

.previous {
  width: 100%;
  padding: 30px;
  margin-right: 30px;
  margin-top: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  transition: 0.3s;
  background-color: #ffffff;
  background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
}

.resultcard {
  background-color: #2884b8;
  background-image: linear-gradient(315deg, #2884b8 0%, #d1bad2 74%);
}

.resultpane {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  transition: 0.3s;
  background-color: #ffffff;
  background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
  overflow: hidden;
}

td {
  padding: 0 20px;
}
</style>