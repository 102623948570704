<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="step2.address"
            label="ที่อยู่ปัจจุบันเลขที่ (Present address)"
            :rules="nameAddress"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="step2.moo"
            :rules="nameMoo"
            label="หมู่ (Moo)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="step2.road"
            label="ถนน (Road)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="step2.district"
            :rules="nameSubDistrict"
            label="ตําบล/แขวง (SubDistrict)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="step2.amphur"
            :rules="nameDistrict"
            label="อําเภอ/เขต (District)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="step2.province"
            :rules="nameProvince"
            label="จังหวัด (Province)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="step2.postcode"
            :rules="namePostcode"
            counter="5"
            label="รหัสไปรษณีย์ (Post code)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="step2.tel"
            :rules="nameTel"
            counter="10"
            label="โทรศัพทั (Tel.)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="step2.email"
            :rules="nameEmail"
            label="อีเมล์ (Email)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
            v-model="step2.date"
            label="วัน เดือน ปีเกิด"
            :rules="nameDate"
            prepend-icon="event"
          ></v-text-field>
          <!-- <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="step2.date"
                label="วัน เดือน ปีเกิด"
                :rules="nameDate"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="step2.date"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>-->
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="step2.age"
            :rules="nameAge"
            label="อายุ (Age)"
            append-icon="ปี"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="step2.race"
            :rules="nameRace"
            label="เชื้อชาติ (Race)"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="step2.nationality"
            :rules="nameNationality"
            label="สัญชาติ (Nationality)"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="step2.religion"
            :rules="nameReligion"
            label="ศาสนา (Religion)"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-radio-group
            v-model="step2.militaryStatus"
            :mandatory="false"
            :rules="nameMilitary"
            row
            label="ภาวะทางทหาร (Military status):"
          >
            <v-radio
              label="ได้รับการยกเว้น (Exempted)"
              class="mx-4"
              value="ได้รับการยกเว้น"
            ></v-radio>
            <v-radio
              label="ปลดเป็นทหารกองหนุน (Served)"
              class="mx-4"
              value="ปลดเป็นทหารกองหนุน"
            ></v-radio>
            <v-radio
              label="ยังไม่ได้รับการเกณฑ์ (Not yet served)"
              class="mx-4"
              value="ยังไม่ได้รับการเกณฑ์"
            ></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <v-radio-group
            v-model="step2.maritalStatus"
            :mandatory="false"
            :rules="nameMarital"
            row
            label="สถานภาพ (Marital status):"
          >
            <v-radio label="โสด (Single)" class="mx-3" value="โสด"></v-radio>
            <v-radio
              label="แต่งงาน (Married)"
              class="mx-3"
              value="แต่งงาน"
            ></v-radio>
            <v-radio
              label="หม้าย (Widowed)"
              class="mx-3"
              value="หม้าย"
            ></v-radio>
            <v-radio
              label="แยกกัน (Separated)"
              class="mx-3"
              value="แยกกัน"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn class="mx-1" text @click="previousState(2)">
          <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>
          previous</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="primary" text class="mx-1" @click="validate(3)">
          Next
          <v-icon style="margin-left: 5px">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step2: {},
      menu: false,
      nextStep1: 2,
      valid: true,
      nameAddress: [
        (v) => !!v || "Address is required",
        (v) =>
          (v && v.length <= 50) || "Address must be less than 50 characters",
        (v) => (v && v.length > 2) || "Address must be more than 2 characters",
        // v =>
        //   /^(\b\D+\b)?\s*(\b.*?\d.*?\b)\s*(\b\D+\b)?$/.test(v) ||
        //   "Address does not match the pattern"
      ],
      nameMoo: [
        (v) => !!v || "Moo is required",
        (v) => /^[0-9]*$/.test(v) || "Moo must be numbers only",
      ],
      nameSubDistrict: [
        (v) => !!v || "SubDistrict is required",
        (v) => (v && v.length > 3) || "SubDistrict must be more than 3 characters",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "SubDistrict must be in English and Thai only",
      ],
      nameDistrict: [
        (v) => !!v || "District is required",
        (v) => (v && v.length > 3) || "District must be more than 3 characters",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "District must be in English and Thai only",
      ],
      nameProvince: [
        (v) => !!v || "Province is required",
        (v) => (v && v.length > 3) || "Province must be more than 3 characters",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Province must be in English and Thai only",
      ],
      namePostcode: [
        (v) => !!v || "Postcode is required",
        (v) =>
          (v && v.length == 5) || "Postcode must be less than 5 characters",
        (v) =>
          (v && v.length == 5) || "Postcode must be more than 5 characters",
        (v) =>
          /^[0-9]{5}(?:-[0-9]{4})?$/.test(v) ||
          "Postcode does not match the pattern",
      ],
      nameTel: [
        (v) => !!v || "Tel is required",
        (v) => (v && v.length == 10) || "Tel must be less than 10 characters",
        (v) => (v && v.length == 10) || "Tel must be more than 10 characters",
        (v) =>
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
          "Tel does not match the pattern",
      ],
      nameEmail: [
        (v) => !!v || "Email is required",
        (v) =>
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
          "Email does not match the pattern",
      ],
      nameDate: [
        (v) => !!v || "Date is required",
        (v) =>
          /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(
            v
          ) || "Please enter the birth date correctly, such as 20/02/1999.",
      ],
      nameAge: [(v) => !!v || "Age is required"],
      nameRace: [
        (v) => !!v || "Race is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Race must be in English and Thai only",
      ],
      nameNationality: [
        (v) => !!v || "Nationality is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Nationality must be in English and Thai only",
      ],
      nameReligion: [
        (v) => !!v || "Religion is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Religion must be in English and Thai only",
      ],
      nameMilitary: [(v) => !!v || "Military is required"],
      nameMarital: [(v) => !!v || "Marital is required"],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    step2() {
      this.$emit(
        "step2",
        this.step2.address,
        this.step2.moo,
        this.step2.road,
        this.step2.district,
        this.step2.amphur,
        this.step2.province,
        this.step2.postcode,
        this.step2.tel,
        this.step2.email,
        this.step2.date,
        this.step2.age,
        this.step2.race,
        this.step2.nationality,
        this.step2.religion,
        this.step2.militaryStatus,
        this.step2.maritalStatus
      );
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    previousState(e) {
      this.nextStep1 = e - 1;
      this.$emit("previousE1", parseInt(this.nextStep1));
    },
    validate(e) {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == true) {
        this.nextStep1 = e;
        this.$emit("nextE1", parseInt(this.nextStep1));
      }
    },
  },
};
</script>