<template>
  <div class="main-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Certificates</v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                block
                color="success"
                @click="Action('create', null)"
              >
                <v-icon class="mr-2">mdi-plus</v-icon>Create
              </v-btn>
            </template>
            <span>Create Certificate</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" block color="success" @click="UploadAction">
                <v-icon class="mr-2">mdi-plus</v-icon>Multiple Create
              </v-btn>
            </template>
            <span>Multiple Create Certificate (Upload)</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" block color="success" @click="ExportCSV">
                <v-icon class="mr-2">mdi-file-download</v-icon>CSV
              </v-btn>
            </template>
            <span>Export CSV</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <!-- <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px">
        ยังไม่มี Certificates
      </p>
    </div> -->
    <AdvanceFilter
      :headers="headers"
      :FilterData="FilterItems"
      @update-data="UpdateFilterData"
      v-if="ready == true"
    />
    <v-card-title>
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="listItems"
      :search="search"
      class="elevation-1"
      style="margin-bottom: 50px"
    >
      <template v-slot:item.EmployeeStatus="{ item }">
        <div style="text-align: center">
          <v-chip
            small
            class="ma-2"
            color="green"
            text-color="white"
            v-if="item.EmployeeStatus == 'Active'"
          >
            {{ item.EmployeeStatus }}
          </v-chip>
          <v-chip small class="ma-2" color="red" text-color="white" v-else>
            {{ item.EmployeeStatus }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.IssuedDate="{ item }">
        <div v-if="item.IssuedDate != null">
          {{ ConvertDate(item.IssuedDate) }}
        </div>
        <div v-else>{{ item.IssuedDateType }}</div>
      </template>
      <template v-slot:item.ExpiredDate="{ item }">
        <div v-if="item.ExpiredDate != null">
          {{ ConvertDate(item.ExpiredDate) }}
        </div>
        <div v-else>{{ item.ExpiredDateType }}</div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-2"
              v-on="on"
              style="cursor: pointer"
              :disabled="item.CertificateFile == 'null'"
              @click="DownloadFile(item.CertificateFile)"
              >mdi-cloud-download</v-icon
            >
          </template>
          <span>Download File</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              v-if="userlevel >= 97"
              v-on="on"
              @click="DeleteCertificate(item)"
              style="cursor: pointer"
              >delete</v-icon
            >
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- dialog -->
    <v-dialog v-model="dialog" scrollable presistent max-width="500">
      <v-card>
        <v-card-title v-if="action == 'create'"
          >Create Certificate</v-card-title
        >
        <v-card-title v-if="action == 'update'"
          >Update Certificate</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="card-text-area">
          <div class="information-area">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-select
                  item-text="name"
                  item-value="email"
                  :items="resultEmployee"
                  v-model="certificates.email"
                  label="Employee"
                  outlined
                  name="employee"
                  v-validate="'required'"
                  dense
                  hide-details
                ></v-select>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('employee')"
                    >{{ errors.first("employee") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-select
                  :items="categoryType"
                  v-model="category_select_type"
                  label="Category Type"
                  outlined
                  name="category_type"
                  v-validate="'required'"
                  dense
                  hide-details
                ></v-select>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('category_type')"
                    >{{ errors.first("category_type") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-select
                  v-if="category_select_type == 'Technical'"
                  :items="categoryTech"
                  v-model="certificates.category"
                  label="Category"
                  outlined
                  name="category"
                  v-validate="'required'"
                  dense
                  hide-details
                ></v-select>
                <v-select
                  v-if="category_select_type == 'Non-Tech'"
                  :items="categoryNonTech"
                  v-model="certificates.category"
                  label="Category"
                  outlined
                  name="category"
                  v-validate="'required'"
                  dense
                  hide-details
                ></v-select>
                <v-select
                  v-if="category_select_type == ''"
                  label="Category"
                  outlined
                  name="category"
                  disabled
                  dense
                  hide-details
                ></v-select>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('category')"
                    >{{ errors.first("category") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                  label="Name"
                  outlined
                  v-model="certificates.name"
                  name="name"
                  v-validate="'required'"
                  dense
                  hide-details
                />
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('name')"
                    >{{ errors.first("name") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-textarea
                  label="Description"
                  outlined
                  v-model="certificates.description"
                  name="description"
                  dense
                  hide-details
                  rows="2"
                />
                <!-- <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  style="margin-top: 10px; margin-bottom: -10px;"
                  v-show="errors.has('description')"
                  >{{ errors.first("description") }}</v-alert
                >
              </transition> -->
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                  label="Issued By"
                  outlined
                  v-model="certificates.issuedby"
                  name="issuedby"
                  v-validate="'required'"
                  dense
                  hide-details
                />
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('issuedby')"
                    >{{ errors.first("issuedby") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select
                  :items="issued_type"
                  v-model="issueddate_type"
                  label="Issued Date Type"
                  outlined
                  v-validate="'required'"
                  dense
                  hide-details
                  name="issueddate_type"
                  style="margin-bottom: 10px"
                ></v-select>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: 10px"
                    v-show="errors.has('issueddate_type')"
                    >{{ errors.first("issueddate_type") }}</v-alert
                  >
                </transition>
                <v-menu
                  v-model="menu_issueddate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-if="issueddate_type == 'Date specified'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="certificates.issueddate"
                      label="Issued Date"
                      append-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      name="issueddate"
                      dense
                      hide-details
                      v-validate="'required'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="certificates.issueddate"
                    @input="menu_issueddate = false"
                  ></v-date-picker>
                </v-menu>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('issueddate')"
                    >{{ errors.first("issueddate") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select
                  :items="expired_type"
                  v-model="expiredate_type"
                  label="Expired Date Type"
                  outlined
                  v-validate="'required'"
                  dense
                  hide-details
                  name="expiredate_type"
                  style="margin-bottom: 10px"
                ></v-select>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: 10px"
                    v-show="errors.has('expiredate_type')"
                    >{{ errors.first("expiredate_type") }}</v-alert
                  >
                </transition>
                <v-menu
                  v-model="menu_expiredate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  v-if="expiredate_type == 'Date specified'"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="certificates.expiredate"
                      label="Expired Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                      outlined
                      name="expiredate"
                      dense
                      hide-details
                      v-validate="'required'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="certificates.expiredate"
                    @input="menu_expiredate = false"
                  ></v-date-picker>
                </v-menu>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('expiredate')"
                    >{{ errors.first("expiredate") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style="margin: 0px 0px 15px 0px"
              >
                <input
                  id="fileUpload"
                  type="file"
                  ref="file"
                  hidden
                  @change="handleFileUpload"
                />
                <v-btn tile color="indigo" dark block @click="chooseFiles">
                  <v-icon style="margin: 0px 5px 0px 0px"
                    >mdi-file-document</v-icon
                  >Upload File
                </v-btn>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    v-show="error != ''"
                    style="margin-top: 10px"
                    >{{ error }}</v-alert
                  >
                </transition>
              </v-col>
            </v-row>
          </div>
          <div class="information-area" v-if="file != null">
            <v-divider></v-divider>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th class="text-right">Size</th>
                    <th class="text-center">Active</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ file.name }}</td>
                    <td class="text-right">{{ pretty(file.size) }}</td>
                    <td class="text-center">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="red"
                        @click="removeItems"
                        style="width: 25px; height: 25px; margin: 4px"
                      >
                        <v-icon dark size="15">mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            v-if="action == 'create'"
            @click="CreateCertificate"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- multiple create dialog -->
    <v-dialog v-model="upload_dialog" width="100%" max-width="700">
      <v-card>
        <v-card-title>Multiple Create Certificate (Upload)</v-card-title>
        <v-divider></v-divider>
        <div class="information-area" style="margin-bottom: 20px">
          <v-row style="margin-bottom: 0px">
            <v-col cols="12" style="text-align: center; padding: 20px">
              <input
                id="multiplefileUpload"
                type="file"
                hidden
                ref="multiple_file"
                multiple="multiple"
                @change="handleMultipleFileUpload"
              />
              <v-btn text color="success" @click="chooseMultipleFiles">
                <v-icon class="mr-1">mdi-plus</v-icon>
                Select
              </v-btn>
              <v-btn text color="primary" @click="DownloadTemplateFile">
                <v-icon class="mr-1">mdi-file-download</v-icon>
                Download Template
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div
            class="upload-content-area"
            style="text-align: center; margin: 20px"
          >
            <div v-if="upload_file.length === 0">
              Please select format (.CSV only) and certificate files.
            </div>
            <v-simple-table dense v-else>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th class="text-right">Size</th>
                    <th class="text-center">Active</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(file, n) in upload_file" :key="n">
                    <td>{{ file.name }}</td>
                    <td class="text-right">{{ pretty(file.size) }}</td>
                    <td class="text-center">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="red"
                        @click="removeUploadItems(file)"
                        style="width: 25px; height: 25px; margin: 4px"
                      >
                        <v-icon dark size="15">mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
        <v-divider v-show="multiple_create_error != ''"></v-divider>
        <div class="error-area" v-show="multiple_create_error != ''">
          <transition name="slide-fade">
            <v-alert type="error" dense style="margin: 20px">{{
              multiple_create_error
            }}</v-alert>
          </transition>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="CreateMultipleCertificates"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../../config";
import pretty from "prettysize";
import download from "downloadjs";
import moment from "moment";
import { AccessControl } from "../../AccessControl";
import { Others } from "../../function/Others";
import { TpAxios } from "../../helpers/tp_axios";
const cardName = "Certificates";

export default {
  data() {
    return {
      ready: false,
      IP: IP,
      user: "",
      headers: [
        { text: "Employee", value: "EmployeeName" },
        { text: "Employee Status", value: "EmployeeStatus" },
        { text: "Category", value: "Category" },
        { text: "Name", value: "CertificateName" },
        { text: "Description", value: "CertificateDescription" },
        { text: "Issued By", value: "IssuedBy" },
        { text: "Issued Date", value: "IssuedDate" },
        { text: "Expired Date", value: "ExpiredDate" },
        { text: "Actions", value: "action", sortable: false },
      ],
      listItems: [],
      listUsers: [],
      search: "",
      dialog: false,
      upload_dialog: false,
      action: "",
      certificates: {},
      upload_certificates: {},
      file: null,
      upload_file: [],
      menu_issueddate: false,
      menu_expiredate: false,
      error: "",
      multiple_create_error: "",
      loading: false,
      FilterItems: [],
      userlevel: 10,
      category_select_type: "",
      categoryType: ["Technical", "Non-Tech"],
      categoryTech: [
        "AA",
        "AWS",
        "Alteryx",
        "Google",
        "Microsoft",
        "Neo4J",
        "NetGain",
        "PM&BA",
        "Splunk Certification",
        "Tableau",
        "Uipath",
        "Other ex. CompTIA",
      ],
      categoryNonTech: ["Business", "Accountant", "Others"],
      issued_type: [
        "Date specified",
        "No date specified",
        "No expiration date",
      ],
      expired_type: [
        "Date specified",
        "No date specified",
        "No expiration date",
      ],
      issueddate_type: "",
      expiredate_type: "",
    };
  },
  computed: {
    resultEmployee() {
      const value = this.listUsers;
      var array = [];
      for (var i = 0; i < value.length; i++) {
        var obj = {
          name: value[i].EmployeeName,
          email: value[i].EmployeeEmail,
        };
        array.push(obj);
      }
      return array;
    },
  },
  methods: {
    UpdateFilterData(result) {
      if (result.status == true) {
        this.listItems = JSON.parse(result.data);
      } else {
        this.listItems = JSON.parse(this.FilterItems);
      }
    },
    async CreateCertificate() {
      // if (this.file != null) {
      this.error = "";
      this.certificates.file = this.file;
      this.$validator.validateAll().then((result) => {
        if (result) {
          Swal.fire({
            title: "Are you sure ?",
            text: "คุณต้องการบันทึก Certificate นี้หรือไม่ ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.loading = true;
              this.CreateProcess().then(async (response) => {
                if (response) {
                  await this.Clear();
                  await this.start();
                  this.loading = false;
                  Swal.fire("เพิ่มข้อมูลเสร็จสิ้น !", "", "success");
                }
              });
            }
          });
        }
      });
      // } else {
      //   this.error = "Please select your file.";
      // }
    },
    CreateProcess() {
      return new Promise(async (resolve, reject) => {
        const data = new FormData();
        if (this.certificates.description == undefined) {
          this.certificates.description = "";
        }
        if (this.issueddate_type !== "Date specified") {
          this.certificates.issueddate = null;
        }
        if (this.expiredate_type !== "Date specified") {
          this.certificates.expiredate = null;
        }
        data.append("file", this.certificates.file);
        data.append("category", this.certificates.category);
        data.append("name", this.certificates.name);
        data.append("description", this.certificates.description);
        data.append("issuedby", this.certificates.issuedby);
        data.append("issueddate_type", this.issueddate_type);
        data.append("issueddate", this.certificates.issueddate);
        data.append("email", this.certificates.email);
        data.append("expiredate_type", this.expiredate_type);
        data.append("expiredate", this.certificates.expiredate);

        console.log("issued date: ", this.certificates.issueddate);
        console.log("expired date: ", this.certificates.expiredate);

        axios
          .post(IP + "/user-management/create-certificate", data, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            resolve(response.data);
          });
      });
    },
    CreateMultipleCertificates() {
      if (this.upload_file.length >= 2) {
        this.multiple_create_error = "";
        Swal.fire({
          title: "Are you sure ?",
          text: "คุณต้องการเพิ่ม Certificates หรือไม่ ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.value) {
            this.CreateMultipleProcess().then(async (response) => {
              if (response) {
                await this.Clear();
                await this.start();
                Swal.fire("เพิ่มข้อมูลเสร็จสิ้น !", "", "success");
              }
            });
          }
        });
      } else {
        this.multiple_create_error =
          "Please select format and certificates files.";
      }
    },
    CreateMultipleProcess() {
      return new Promise(async (resolve, reject) => {
        this.loading = true;

        const data = new FormData();
        this.upload_file.forEach((file, index) => {
          data.append("files", file);
        });

        axios
          .post(IP + "/user-management/create-multiple-certificate", data, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            this.loading = false;
            // console.log("response: ", response.data);
            resolve(response.data);
          });
      });
    },
    DeleteCertificate(data) {
      // console.log(data);
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบ Certificate นี่หรือไม่ ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          this.loading = true;
          this.DeleteProcess(data).then(async (response) => {
            if (response) {
              await this.Clear();
              await this.start();
              this.loading = false;
              Swal.fire("ลบข้อมูลเสร็จสิ้น !", "", "success");
            }
          });
        }
      });
    },
    DeleteProcess(data) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/delete-certificate",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            data: data,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    ExportCSV() {
      axios({
        method: "get",
        url: IP + "/certificates/get-all-certificates",
        headers: {
          Authorization: AuthKey,
        },
      }).then(async (response) => {
        let data = response.data;
        for (var i = 0; i < data.length; i++) {
          data[i].IssuedDate = moment(data[i].IssuedDate).format("L");
          data[i].ExpiredDate = moment(data[i].ExpiredDate).format("L");
          data[i].CreateDate = moment(data[i].CreateDate).format("L");
        }
        const headerDataJson = {
          Category: "Category",
          CertificateName: "Certificate Name",
          CertificateDescription: "Certificate Description",
          EmployeeName: "Employee Name",
          EmployeeEmail: "Employee Email",
          IssuedBy: "Issue By",
          IssuedDate: "Issue Date",
          ExpiredDate: "Expired Date",
          CertificateFile: "Certificate File",
          CreateDate: "Created Date",
        };
        await Others.dowloadCSV(
          headerDataJson,
          response.data,
          "CertificatesRecord"
        );
      });
    },
    DownloadFile(file) {
      let id = file.split(",");
      TpAxios.Get(`/onedrive/files/download/${id[0]}`).then((response) => {
        let download = Others.DownloadURI(response, id[1]);
      });
    },
    // DownloadFile(filename) {
    //   fetch(`${IP}/user-management/download-certificate`, {
    //     method: "post",
    //     headers: {
    //       Authorization: AuthKey,
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       filename: filename,
    //     }),
    //   }).then((res) => {
    //     const blob = res.blob();
    //     blob.then(async (result) => {
    //       await download(result, filename, result.type);
    //     }, this);
    //   });
    // },
    DownloadTemplateFile() {
      fetch(`${IP}/certificates/download-template`, {
        method: "post",
        headers: {
          Authorization: AuthKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((res) => {
        const blob = res.blob();
        blob.then(async (result) => {
          await download(result, "Upload Certificate template", result.type);
        }, this);
      });
    },
    Clear() {
      this.certificates = {};
      this.category_select_type = "";
      this.file = null;
      this.dialog = false;
      this.upload_certificates = {};
      this.upload_file = [];
      this.upload_dialog = false;
    },
    ConvertDate(date) {
      const result = moment(date).format("L");
      return result;
    },
    Action(action, data) {
      this.certificates = {};
      this.category_select_type = "";
      this.file = null;
      this.action = action;
      this.$validator.reset();
      this.dialog = !this.dialog;
    },
    UploadAction() {
      this.upload_certificates = {};
      this.upload_file = [];
      this.upload_dialog = !this.upload_dialog;
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    chooseMultipleFiles() {
      document.getElementById("multiplefileUpload").click();
    },
    async handleFileUpload() {
      let file = this.$refs.file.files;
      this.file = file[0];
    },
    async handleMultipleFileUpload() {
      for (var i = 0; i < this.$refs.multiple_file.files.length; i++) {
        let file = this.$refs.multiple_file.files[i];
        file.key = Others.GenerateKeyID(10);
        this.upload_file.push(file);
      }
      let unique_array = this.upload_file.filter(
        (obj, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.name === obj.name &&
              t.size === obj.size &&
              t.lastModified === obj.lastModified
          )
      );
      this.upload_file = unique_array;
      this.$refs.multiple_file.value = null;
    },
    removeItems() {
      this.$refs.file.value = null;
      this.file = null;
    },
    removeUploadItems(file) {
      for (var i = 0; i < this.upload_file.length; i++) {
        if (this.upload_file[i].key === file.key) {
          this.upload_file.splice(i, 1);
        }
      }
    },
    pretty(size) {
      return pretty(size);
    },
    GetAllCertificates() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "get",
          url: IP + "/certificates/get-all-certificates",
          headers: {
            Authorization: AuthKey,
          },
        }).then((response) => {
          this.listItems = response.data;
          this.FilterItems = JSON.stringify(this.listItems);
          this.ready = true;
          // console.log("certificate: ", this.listItems);
          resolve(true);
        });
      });
    },
    GetAllEmployeeInformation() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "get",
          url: IP + "/certificates/get-all-employee",
          headers: {
            Authorization: AuthKey,
          },
        }).then((response) => {
          this.listUsers = response.data;
          resolve(true);
        });
      });
    },
    async start() {
      await this.GetAllCertificates().then(async (response) => {
        await this.GetAllEmployeeInformation().then(async (response) => {
          var that = this;
          setTimeout(function () {
            that.loading = false;
          }, 1000);
        });
      });
    },
  },
  async created() {
    this.loading = true;
    this.user = this.$store.getters.currentUser;
    //console.log(this.user.AccessRightsJSON)
    this.userlevel = AccessControl.getReadAccessLevel(
      cardName,
      this.user.AccessRightsJSON
    );
    //console.log(this.userLevel)
    await this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";

.main-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.expansion-panels {
  margin-bottom: 30px;
}

.information-area {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.card-text-area {
  width: 100%;
  max-height: 600px;
}

@media screen and (max-height: 920px) {
  .card-text-area {
    width: 100%;
    max-height: 480px;
  }
}
</style>