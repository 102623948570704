<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <p style="font-size: 30px">
              Related Person Information
              <v-chip class="ma-2" color="primary" label dark> Mokcup </v-chip>
            </p>
            <p style="margin-bottom: 9px; margin-top: -10px">
              Vendor, Supplier, SubContract, etc.
            </p>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in detailItems" :key="index">
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                :label="`Related ${index + 1} Contact Name`"
                v-model="item.contact_name"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                :label="`Related ${index + 1} Company Name`"
                v-model="item.company_name"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                :label="`Related ${index + 1} phone`"
                v-model="item.phone"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                :label="`Related ${index + 1} email`"
                v-model="item.email"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="item.notes"
                outlined
                dense
                hide-details
                rows="2"
                :label="`Related ${index + 1} Notes`"
                :readonly="readonly"
                :color="color"
                v-bind:style="[
                  index + 1 == detailItems.length
                    ? {
                        marginBottom: '10px',
                      }
                    : {},
                ]"
              />
            </v-col>
            <v-col cols="12" v-show="index + 1 != detailItems.length">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data() {
    return {
      readonly: this.ro,
      color: "",
      panel: [0],
      detailItems: [{}, {}, {}, {}],
    };
  },
  props: ["ro"],
  methods: {},
  created() {
    if (this.ro) {
      this.color = "grey-lighten-5";
    }
  },
};
</script>

<style scoped lang="scss">
@media (min-width: 0px) {
  .col.grid-5-custom {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .col.grid-5-custom {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media (min-width: 960px) {
  .col.grid-5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>