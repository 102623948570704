<template>
  <div class="main-area">
    <div v-if="width > 600">
      <v-navigation-drawer
        permanent
        class="navigation"
        :style="`position: fixed; width: 250px; height: ${
          height - 155
        }px; margin-top: 80px;`"
      >
        <!-- <v-list style="margin-top: 80px">
          <v-list-item
            link
            v-for="(item, index) in menu"
            :key="index"
            :id="item.id === focus ? 'list-focus' : ''"
            @click="focus = item.id"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon class="mr-2">{{ item.icon }}</v-icon> {{ item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
        <v-list dense>
          <v-list-group
            v-for="(group, i) in menu"
            :key="i"
            v-show="group.children.length > 0"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ group.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ group.name }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              link
              v-for="(item, index) in group.children"
              :key="index"
              :id="item.id === focus ? 'list-focus' : ''"
              @click="focus = item.id"
            >
              <v-list-item-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
      <br />
      <div
        class="content-area"
        :style="`width: ${width - 330}px; margin-left: 280px;`"
      >
        <v-card :style="`min-height: ${height - 200}px`">
          <div class="content-inside">
            <v-container>
              <!-- component -->
              <div
                v-if="focus == null"
                style="text-align: center; margin-top: 50px"
              >
                Please select menu.
              </div>
              <Exam v-if="focus == 1" />
              <ExamPart v-if="focus == 2" />
              <Question v-if="focus == 3" />
              <QuestionSet v-if="focus == 4" />
              <ManageExam v-if="focus == 5" />
              <ExamHistory v-if="focus == 7" />
              <ExamSummary v-if="focus == 8" />
            </v-container>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { WindowResize } from "../../function/WindowResize";
// component
import Exam from "./ExamManagement/Exam";
import ExamPart from "./ExamManagement/ExamPart";
import QuestionSet from "./ExamManagement/QuestionSet";
import Question from "./ExamManagement/Question";
import ManageExam from "./ExamManagement/ManageExam";
import ExamHistory from './ExamManagement/ExamHistory';
import ExamSummary from './ExamManagement/ExamSummary';

export default {
  data() {
    return {
      user: "",
      width: 0,
      height: 0,
      focus: null,
      menu: [
        {
          name: "Exam Management",
          icon: "mdi-book-open-variant",
          id: 0,
          children: [
            {
              name: "Exam",
              icon: "mdi-book-open-variant",
              id: 1,
            },
            {
              name: "Exam Part",
              icon: "mdi-book-open-variant",
              id: 2,
            },
            {
              name: "Question",
              icon: "mdi-file-question",
              id: 3,
            },
            {
              name: "Question Set",
              icon: "mdi-file-question",
              id: 4,
            },
            {
              name: "Add Exam to User",
              icon: "mdi-account",
              id: 5,
            },
          ],
        },
        {
          name: "Exam Result",
          icon: "mdi-book-open-variant",
          id: 6,
          children: [
            // {
            //   name: "Exam History",
            //   icon: "mdi-book-open-variant",
            //   id: 7,
            // },
            {
              name: "Exam Summary",
              icon: "mdi-book-open-variant",
              id: 8,
            },
          ],
        },
      ],
    };
  },
  components: {
    Exam,
    ExamPart,
    QuestionSet,
    Question,
    ManageExam,
    ExamHistory,
    ExamSummary
  },
  methods: {
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    start() {
      this.resize();
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    this.start();
  },
};
</script>

<style lang="scss">
.main-area {
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
}

#list-focus {
  background: rgba(209, 209, 209, 0.452);
}
</style>