<template>
  <div>
    <h1>Test Graph</h1>
    <apexcharts width="100%" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: "area" // bar , radar , line , area
        },
        xaxis: {
          categories: ["April", "May", "June", "July", "August", "September"],
          labels: {
            show: true,
          }
        }
      },
      series: [
        {
          name: "Test",
          data: [2, 4, 3, 7, 1, 0]
        }
      ]
    };
  },
  components: {
    apexcharts: VueApexCharts
  },
  methods: {}
};
</script>