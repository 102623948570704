const WindowResize = {
    windowWidth(){
        var width = window.innerWidth;
        return width;
    },
    windowHeight(){
        var height = window.innerHeight;
        return height;
    }
}

module.exports = {
    WindowResize
}