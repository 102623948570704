<template>
  <div class="manage-slide">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <v-container>
      <h2 style="padding-left: 10px">Slides Public</h2>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-card-title v-if="news_data.length > 0">
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="6"
            lg="6"
            xl="4"
            v-for="n in resultQuery"
            :key="n.NewsID"
          >
            <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="10">
                <v-card height="220px">
                  <div class="fry">
                    <div class="fry-box">
                      <v-img
                        :src="`${IP}/news_file/${n.NewsBackgroundImage}`"
                        class="img-box"
                      ></v-img>
                    </div>
                  </div>
                  <div class="content-card-slide">
                    <v-list-item-title
                      style="
                        padding-top: 30px;
                        padding-left: 5px;
                        padding-right: 20px;
                        font-size: 20px;
                        font-weight: bold;
                      "
                      >{{ Decode(n.NewsTitle) }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      style="
                        color: gray;
                        margin-left: 5px;
                        margin-bottom: 15px;
                        padding-right: 20px;
                      "
                    >
                      {{ n.EmployeeName }}
                    </v-list-item-subtitle>
                    <div class="text-description">
                      {{ Decode(n.NewsDescription) }}
                    </div>
                    <v-card-actions class="card-actions">
                      <v-card-subtitle class="content-card-slide-date">{{
                        Others.ConvertDate(n.NewsCreatedDate)
                      }}</v-card-subtitle>
                      <v-spacer></v-spacer>
                      <v-switch
                        inset
                        color="green"
                        v-model="n.switch"
                        v-on:change="SwitchActive(n)"
                      ></v-switch>
                    </v-card-actions>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import { WindowResize } from "../../function/WindowResize.js";
import { Others } from "../../function/Others";

export default {
  data() {
    return {
      Others: Others,
      IP: IP,
      message_active: false,
      message: {
        description: "",
        code: "",
        message: "",
      },
      news_data: [],
      width: 0,
      height: 0,
      search: "",
      loading: false,
    };
  },
  computed: {
    resultQuery() {
      const value = this.search.toLowerCase();
      return this.news_data.filter(function (val) {
        var title = this.Decode(val.NewsTitle);
        return (
          title.toLowerCase().indexOf(value) > -1 ||
          val.NewsCreatedBy.toLowerCase().indexOf(value) > -1 ||
          val.NewsTag.toLowerCase().indexOf(value) > -1 ||
          val.EmployeeName.toLowerCase().indexOf(value) > -1
        );
      }, this);
    },
  },
  methods: {
    getNews() {
      axios({
        method: "get",
        url: IP + "/select-news",
        headers: {
          Authorization: AuthKey,
        },
      }).then((response) => {
        console.log("get news: ", response.data);
        if (response.data.code) {
          this.message_active = true;
          this.message.description = "Something wrong !";
          this.message.code = response.data.code;
          this.message.message = response.data.originalError.message;
          this.loading = false;
        } else {
          this.message_active = false;
          this.message = "";
          const result = response.data;
          for (var i = 0; i < result.length; i++) {
            if (result[i].PublishStatus != "Draft") {
              if (result[i].NewsStatus == "noti-active") {
                result[i].switch = true;
              } else {
                result[i].switch = false;
              }
              this.news_data.push(result[i]);
            }
          }
          this.loading = false;
          // console.log(this.news_data);
        }
      });
    },
    Decode(data) {
      var result = Buffer.from(data, "base64").toString();
      return result;
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    async SwitchActive(data) {
      if (data.switch) {
        data.NewsStatus = "noti-active";
      } else {
        data.NewsStatus = "active";
      }
      await this.UpdateStatus(data);
    },
    UpdateStatus(data) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/update-news-status`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            id: data.NewsID,
            status: data.NewsStatus,
          },
        }).then((response) => {
          console.log(response.data);
        });
      });
    },
    start() {
      this.loading = true;
      this.resize();
      this.user = this.$store.getters.currentUser;
      this.getNews();
    },
  },
  created() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
.manage-slide {
  margin-top: 80px;
}

.card-slide {
  height: 220px;
}

.fry {
  width: 160px;
  height: 150px;
  position: absolute;
  margin-top: 35px;
  margin-left: -50px;
}

.img-box {
  height: 150px;
  border-radius: 15px;
}

.fry-box {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
}

.content-card-slide {
  margin-left: 130px;
}

.content-card-slide-date {
  margin-left: 110px;
}

.title-description {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-description {
  display: block;
  display: -webkit-box;
  padding: 0px 30px 0px 20px;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-actions {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 570px) {
  .content-card-slide {
    margin-left: 100px;
  }

  .content-card-slide-date {
    position: absolute;
    left: 0;
    margin-left: -0px;
    margin-top: 10px;
  }

  .fry {
    width: 120px;
    height: 120px;
    position: absolute;
    margin-top: 35px;
    margin-left: -50px;
  }

  .img-box {
    height: 120px;
  }
}

@media screen and (max-width: 400px) {
  .content-card-slide {
    margin-left: 50px;
  }

  .content-card-slide-date {
    position: absolute;
    left: 0;
    margin-left: -0px;
    margin-top: 5px;
    width: 190px;
  }

  .fry {
    width: 80px;
    height: 80px;
    position: absolute;
    margin-top: 35px;
    margin-left: -50px;
  }

  .fry-box {
    border-radius: 5px;
  }

  .img-box {
    height: 80px;
    border-radius: 5px;
  }
}
</style>