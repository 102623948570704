<template>
  <div class="fileTable">
    <v-dialog
      width="80%"
      max-width="1280"
      persistent
      scrollable
      v-model="dialog"
    >
      <template>
        <v-card>
          <v-card-text>
            <v-card-title
              >Files
              <div style="position: absolute; right: 0; margin-right: 25px">
                <v-btn
                  small
                  text
                  color="primary"
                  @click="UploadComponenet(true)"
                  v-if="!upload_component && permission.upload.value"
                  :disabled="loading"
                >
                  <v-icon small class="mr-1">mdi-file-upload</v-icon>
                  upload component
                </v-btn>
                <v-btn
                  small
                  text
                  color="success"
                  @click="Upload"
                  :disabled="
                    (file.quotation === null &&
                      file.po === null &&
                      file.contract === null &&
                      file.cost_sheet === null &&
                      file.invoice_do === null &&
                      file.others === null) ||
                    loading
                  "
                  :loading="loading"
                  v-if="upload_component"
                >
                  <v-icon small class="mr-1">mdi-upload</v-icon>
                  upload
                </v-btn>
                <v-btn
                  small
                  text
                  color="primary"
                  @click="UploadComponenet(false)"
                  v-if="upload_component"
                  :disabled="loading"
                >
                  <v-icon small class="mr-1">mdi-file</v-icon>
                  files history
                </v-btn>
                <v-btn
                  small
                  text
                  color="error"
                  @click="dialog = false"
                  :disabled="loading"
                >
                  <v-icon small class="mr-1">mdi-close</v-icon>
                  close
                </v-btn>
              </div>
            </v-card-title>
            <v-card class="d-flex flex-row" v-if="!upload_component">
              <v-list dense>
                <v-list-item-group :value="fileItem" color="teal">
                  <v-list-item
                    v-for="(item, i) in fileItems"
                    :key="i"
                    @click="fileItem = item.value"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

              <!-- files content -->
              <div style="width: 100%">
                <!-- Quotation file -->
                <v-data-table
                  v-if="fileItem === 0 && permission.view.specific.quotation"
                  :headers="fileHeaders"
                  :items="FileList.quotation"
                >
                  <template v-slot:item.fileName="{ item }">
                    <div style="max-width: 200px">
                      {{ item.fileName }}
                    </div>
                  </template>
                  <template v-slot:item.createdDate="{ item }">
                    {{ ConvertDate(item.createdDate) }}
                  </template>
                  <template v-slot:item.updatedDate="{ item }">
                    {{ ConvertDate(item.updatedDate) }}
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <div style="text-align: center; width: 60px">
                      <v-icon
                        small
                        class="ml-3 mr-3"
                        style="cursor: pointer"
                        @click="DownloadFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.download.specific.quotation"
                        >mdi-cloud-download</v-icon
                      >
                      <v-icon
                        small
                        style="cursor: pointer"
                        @click="DeleteFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.delete.specific.quotation"
                        >mdi-delete</v-icon
                      >
                      <v-progress-circular
                        :size="15"
                        :width="2"
                        indeterminate
                        v-show="action_id === item.filePath"
                        class="ml-6 mr-3"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-data-table>
                <!-- PO file -->
                <v-data-table
                  v-if="fileItem === 1 && permission.view.specific.po"
                  :headers="fileHeaders"
                  :items="FileList.po"
                >
                  <template v-slot:item.fileName="{ item }">
                    <div style="max-width: 200px">
                      {{ item.fileName }}
                    </div>
                  </template>
                  <template v-slot:item.createdDate="{ item }">
                    {{ ConvertDate(item.createdDate) }}
                  </template>
                  <template v-slot:item.updatedDate="{ item }">
                    {{ ConvertDate(item.updatedDate) }}
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <div style="text-align: center; width: 60px">
                      <v-icon
                        small
                        class="ml-3 mr-3"
                        style="cursor: pointer"
                        @click="DownloadFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.download.specific.po"
                        >mdi-cloud-download</v-icon
                      >
                      <v-icon
                        small
                        style="cursor: pointer"
                        @click="DeleteFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.delete.specific.po"
                        >mdi-delete</v-icon
                      >
                      <v-progress-circular
                        :size="15"
                        :width="2"
                        indeterminate
                        v-show="action_id === item.filePath"
                        class="ml-6 mr-3"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-data-table>
                <!-- Contract file -->
                <v-data-table
                  v-if="fileItem === 2 && permission.view.specific.contract"
                  :headers="fileHeaders"
                  :items="FileList.contract"
                >
                  <template v-slot:item.fileName="{ item }">
                    <div style="max-width: 200px">
                      {{ item.fileName }}
                    </div>
                  </template>
                  <template v-slot:item.createdDate="{ item }">
                    {{ ConvertDate(item.createdDate) }}
                  </template>
                  <template v-slot:item.updatedDate="{ item }">
                    {{ ConvertDate(item.updatedDate) }}
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <div style="text-align: center; width: 60px">
                      <v-icon
                        small
                        class="ml-3 mr-3"
                        style="cursor: pointer"
                        @click="DownloadFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.download.specific.contract"
                        >mdi-cloud-download</v-icon
                      >
                      <v-icon
                        small
                        style="cursor: pointer"
                        @click="DeleteFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.delete.specific.contract"
                        >mdi-delete</v-icon
                      >
                      <v-progress-circular
                        :size="15"
                        :width="2"
                        indeterminate
                        v-show="action_id === item.filePath"
                        class="ml-6 mr-3"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-data-table>
                <!-- CostSheet file -->
                <v-data-table
                  v-if="fileItem === 3 && permission.view.specific.cost_sheet"
                  :headers="fileHeaders"
                  :items="FileList.cost_sheet"
                >
                  <template v-slot:item.fileName="{ item }">
                    <div style="max-width: 200px">
                      {{ item.fileName }}
                    </div>
                  </template>
                  <template v-slot:item.createdDate="{ item }">
                    {{ ConvertDate(item.createdDate) }}
                  </template>
                  <template v-slot:item.updatedDate="{ item }">
                    {{ ConvertDate(item.updatedDate) }}
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <div style="text-align: center; width: 60px">
                      <v-icon
                        small
                        class="ml-3 mr-3"
                        style="cursor: pointer"
                        @click="DownloadFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.download.specific.cost_sheet"
                        >mdi-cloud-download</v-icon
                      >
                      <v-icon
                        small
                        style="cursor: pointer"
                        @click="DeleteFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.delete.specific.cost_sheet"
                        >mdi-delete</v-icon
                      >
                      <v-progress-circular
                        :size="15"
                        :width="2"
                        indeterminate
                        v-show="action_id === item.filePath"
                        class="ml-6 mr-3"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-data-table>
                <!-- Invoice & DO file -->
                <v-data-table
                  v-if="fileItem === 4 && permission.view.specific.invoice_do"
                  :headers="fileHeaders"
                  :items="FileList.invoice_do"
                >
                  <template v-slot:item.fileName="{ item }">
                    <div style="max-width: 200px">
                      {{ item.fileName }}
                    </div>
                  </template>
                  <template v-slot:item.createdDate="{ item }">
                    {{ ConvertDate(item.createdDate) }}
                  </template>
                  <template v-slot:item.updatedDate="{ item }">
                    {{ ConvertDate(item.updatedDate) }}
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <div style="text-align: center; width: 60px">
                      <v-icon
                        small
                        class="ml-3 mr-3"
                        style="cursor: pointer"
                        @click="DownloadFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.download.specific.invoice_do"
                        >mdi-cloud-download</v-icon
                      >
                      <v-icon
                        small
                        style="cursor: pointer"
                        @click="DeleteFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.delete.specific.invoice_do"
                        >mdi-delete</v-icon
                      >
                      <v-progress-circular
                        :size="15"
                        :width="2"
                        indeterminate
                        v-show="action_id === item.filePath"
                        class="ml-6 mr-3"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-data-table>
                <!-- Others file -->
                <v-data-table
                  v-if="fileItem === 5 && permission.view.specific.others"
                  :headers="fileHeaders"
                  :items="FileList.others"
                >
                  <template v-slot:item.fileName="{ item }">
                    <div style="max-width: 200px">
                      {{ item.fileName }}
                    </div>
                  </template>
                  <template v-slot:item.createdDate="{ item }">
                    {{ ConvertDate(item.createdDate) }}
                  </template>
                  <template v-slot:item.updatedDate="{ item }">
                    {{ ConvertDate(item.updatedDate) }}
                  </template>
                  <template v-slot:item.Actions="{ item }">
                    <div style="text-align: center; width: 60px">
                      <v-icon
                        small
                        class="ml-3 mr-3"
                        style="cursor: pointer"
                        @click="DownloadFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.download.specific.others"
                        >mdi-cloud-download</v-icon
                      >
                      <v-icon
                        small
                        style="cursor: pointer"
                        @click="DeleteFile(item)"
                        :disabled="action_loading"
                        v-show="action_id != item.filePath"
                        v-if="permission.delete.specific.others"
                        >mdi-delete</v-icon
                      >
                      <v-progress-circular
                        :size="15"
                        :width="2"
                        indeterminate
                        v-show="action_id === item.filePath"
                        class="ml-6 mr-3"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-data-table>
              </div>
            </v-card>
            <v-card v-if="upload_component">
              <v-card-text>
                <v-row style="padding: 5px">
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-file-input
                      v-model="file.quotation"
                      label="Quotation"
                      prepend-icon="mdi-paperclip"
                      outlined
                      dense
                      hide-details
                      :show-size="1000"
                      :truncate-length="70"
                      :disabled="
                        loading || !permission.upload.specific.quotation
                      "
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-file-input
                      v-model="file.po"
                      label="PO"
                      prepend-icon="mdi-paperclip"
                      outlined
                      dense
                      hide-details
                      :show-size="1000"
                      :truncate-length="70"
                      :disabled="loading || !permission.upload.specific.po"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-file-input
                      v-model="file.contract"
                      label="Contract"
                      prepend-icon="mdi-paperclip"
                      outlined
                      dense
                      hide-details
                      :show-size="1000"
                      :truncate-length="70"
                      :disabled="
                        loading || !permission.upload.specific.contract
                      "
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-file-input
                      v-model="file.cost_sheet"
                      label="CostSheet"
                      prepend-icon="mdi-paperclip"
                      outlined
                      dense
                      hide-details
                      :show-size="1000"
                      :truncate-length="70"
                      :disabled="
                        loading || !permission.upload.specific.cost_sheet
                      "
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-file-input
                      v-model="file.invoice_do"
                      label="Invoice & DO"
                      prepend-icon="mdi-paperclip"
                      outlined
                      dense
                      hide-details
                      :show-size="1000"
                      :truncate-length="70"
                      :disabled="
                        loading || !permission.upload.specific.invoice_do
                      "
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-file-input
                      v-model="file.others"
                      label="Others"
                      outlined
                      dense
                      hide-details
                      :show-size="1000"
                      :truncate-length="70"
                      :disabled="loading || !permission.upload.specific.others"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import pretty from "prettysize";
import { TpAxios } from "../../helpers/tp_axios";
import { Others } from "../../function/Others";
import { Permissions } from "../../function/Permissions";

export default {
  data() {
    return {
      user: "",
      loading: false,
      action_id: "",
      action_loading: false,
      dialog: this.filesDialog,
      upload_component: false,
      file: {
        quotation: null,
        po: null,
        contract: null,
        cost_sheet: null,
        invoice_do: null,
        others: null,
      },
      fileItem: 0,
      file_items: [
        { text: "Quotation file", value: 0, key: "quotation" },
        { text: "PO file", value: 1, key: "po" },
        { text: "Contract file", value: 2, key: "contract" },
        { text: "CostSheet file", value: 3, key: "cost_sheet" },
        { text: "Invoice & DO file", value: 4, key: "invoice_do" },
        { text: "Others file", value: 5, key: "others" },
      ],
      fileItems: [],
      EmployeeListItems: [],
      fileHeaders: [
        { text: "Name", value: "fileName" },
        { text: "Size", value: "fileSize" },
        { text: "Created Time", value: "createdDate" },
        { text: "Updated Time", value: "updatedDate" },
        { text: "Created by", value: "createdBy" },
        { text: "Updated by", value: "updatedBy" },
        { text: "Actions", value: "Actions", sortable: false },
      ],
      FileList: {
        quotation: [],
        po: [],
        contract: [],
        cost_sheet: [],
        invoice_do: [],
        others: [],
      },
      // permissions
      user_permission: null,
      permission: {
        view: {
          id: [6],
          value: false,
          specific: {
            quotation: false,
            po: false,
            contract: false,
            cost_sheet: false,
            invoice_do: false,
            others: false,
          },
        },
        download: {
          id: [7],
          value: false,
          specific: {
            quotation: false,
            po: false,
            contract: false,
            cost_sheet: false,
            invoice_do: false,
            others: false,
          },
        },
        upload: {
          id: [8],
          value: false,
          specific: {
            quotation: false,
            po: false,
            contract: false,
            cost_sheet: false,
            invoice_do: false,
            others: false,
          },
        },
        delete: {
          id: [9],
          value: false,
          specific: {
            quotation: false,
            po: false,
            contract: false,
            cost_sheet: false,
            invoice_do: false,
            others: false,
          },
        },
      },
    };
  },
  props: ["filesDialog", "so"],
  methods: {
    UploadComponenet(action) {
      this.file = {
        quotation: null,
        po: null,
        contract: null,
        cost_sheet: null,
        invoice_do: null,
        others: null,
      };
      this.upload_component = action;
    },
    close() {
      this.$emit("close", false);
    },
    DownloadFile(file) {
      this.action_loading = true;
      this.action_id = file.filePath;
      let id = file.filePath.split(",");
      TpAxios.Get(`/sale-orders/files/download/${id[0]}`).then((response) => {
        let download = Others.DownloadURI(response, file.fileName);
        if (download) {
          this.action_loading = false;
          this.action_id = "";
        }
      });
    },
    DeleteFile(file) {
      this.action_loading = true;
      this.action_id = file.filePath;
      TpAxios.Delete(`/sale-orders/files/delete/${this.action_id}`).then(
        (response) => {
          this.action_loading = false;
          this.action_id = "";
          this.initialize();
        }
      );
    },
    Upload() {
      this.loading = true;
      let files = this.file;
      let file_items = [];
      const data = new FormData();
      for (let obj in files) {
        if (files[obj] != null) {
          file_items.push(obj);
          data.append(`file_${obj}`, files[obj]);
        }
      }
      console.log("file_items: ", file_items);
      data.append("file_items", JSON.stringify(file_items));
      data.append("create_by", this.user.EmployeeName);

      TpAxios.Post(`/sale-orders/files/add/${this.so}`, data).then(
        (response) => {
          this.file = {
            quotation: null,
            po: null,
            contract: null,
            cost_sheet: null,
            invoice_do: null,
            others: null,
          };
          this.loading = false;
          this.initialize();
        }
      );
      // }
    },
    FindEmployeeName(data) {
      return new Promise(async (resolve, reject) => {
        // console.log("data: ", data);
        if (data != null) {
          let check = data.includes("@");
          let employee_items = JSON.parse(
            JSON.stringify(this.EmployeeListItems)
          );
          let employee_name = data;
          if (check) {
            let find = employee_items.find((x) => x.EmployeeEmail === data);
            if (find.EmployeeName != undefined) {
              employee_name = find.EmployeeName;
            }
          }
          resolve(employee_name);
        } else {
          resolve(null);
        }
      });
    },
    GetAllEmployee() {
      return new Promise(async (resolve, reject) => {
        this.EmployeeListItems = [];
        TpAxios.Get(`/employee-select-all`).then((response) => {
          this.EmployeeListItems = response.recordset;
          resolve(true);
        });
      });
    },
    GetFilesHistory() {
      return new Promise(async (resolve, reject) => {
        TpAxios.Get(`/sale-orders/files/get/${this.so}`).then((response) => {
          resolve(response.data);
        });
      });
    },
    async PrepareData(data) {
      this.FileList = {
        quotation: [],
        po: [],
        contract: [],
        cost_sheet: [],
        invoice_do: [],
        others: [],
      };
      for (var i = 0; i < data.length; i++) {
        let filename = data[i].FilePath.split(",");
        let obj = {
          fileName: filename[1],
          fileSize: pretty(data[i].FileSize),
          filePath: data[i].FilePath,
          createdBy: await this.FindEmployeeName(data[i].CreateBy),
          updatedBy: await this.FindEmployeeName(data[i].UpdateBy),
          createdDate: data[i].CreateDate,
          updatedDate: data[i].UpdateDate,
        };
        this.FileList[data[i].FileCategory].push(obj);
      }
      console.log("file table items: ", this.FileList);
    },
    ConvertDate(date) {
      if (date === null || date === undefined) {
        return "";
      }
      // return moment(date).calendar();
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
      // return date
    },
    PermissionsInit() {
      return new Promise(async (resolve, reject) => {
        this.user = this.$store.getters.currentUser;
        this.fileItem = null;
        this.fileItems = [];
        this.user_permission = await Permissions.GetRoleAndPermissions(
          "SaleOrders"
        );
        this.permission = await Permissions.SetAccess(
          this.user_permission,
          this.permission
        );
        for (let key in this.permission.view.specific) {
          if (this.permission.view.specific[key]) {
            let obj = this.file_items.find((x) => x.key === key);
            this.fileItems.push(obj);
          }
        }
        if (this.fileItems.length > 0) {
          this.fileItem = this.fileItems[0].value;
        }
        resolve(true);
      });
    },
    async initialize() {
      await this.PermissionsInit();
      await this.GetAllEmployee();
      if (this.so != "") {
        this.GetFilesHistory().then((response) => {
          this.PrepareData(response);
        });
      }
    },
  },
  async created() {
    this.initialize();
  },
};
</script>

<style>
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: -16px -25px 0px 0px;
}

.text-container {
  position: relative;
  width: 200px; /* Adjust as needed */
  height: 20px; /* Adjust as needed */
  overflow: hidden;
}

.ellipsis {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
</style>
