<template>
  <div class="main-area">
    <div v-if="width > 600">
      <v-navigation-drawer
        permanent
        class="navigation"
        :style="`position: fixed; width: 350px; height: ${height - 74}px`"
      >
        <v-container>
          <v-row
            style="font-size: 18px; border-bottom: 1px solid rgb(230, 230, 230)"
          >
            <!-- <v-col style="margin-top: 15px">Notification</v-col> -->
            <v-col style="text-align: right">
              <v-chip class="ma-2" color="warning" text-color="white">
                <v-avatar left>
                  <v-icon>mdi-alert-circle</v-icon>
                </v-avatar>
                {{ noti_count }}
              </v-chip>
              <v-chip class="ma-2" color="primary" text-color="white">
                <v-avatar left>
                  <v-icon>mdi-mail</v-icon>
                </v-avatar>
                {{ notification.length }}
              </v-chip>
            </v-col>
          </v-row>
        </v-container>
        <v-list v-if="notification.length > 0">
          <v-list-item
            v-for="(item, index) in notification"
            :key="index"
            link
            @click="Read(item, index)"
            :class="{ focus: item.Status == 'Unread' }"
            :id="index === focus ? 'list-focus' : ''"
          >
            <v-list-item-content>
              <div style="margin-bottom: 10px; font-size: 12px">
                <div style="float: left">
                  <b>
                    <v-icon small color="warning" v-if="item.Status == 'Unread'"
                      >mdi-alert-circle</v-icon
                    ><v-icon small color="success" v-if="item.Status == 'Read'"
                      >mdi-check-circle</v-icon
                    >
                    {{ item.Module }}</b
                  >
                </div>
                <div style="float: right">{{ Moment(item.CreatedDate) }}</div>
              </div>
              <v-list-item-title>{{ item.Title }}</v-list-item-title>
              <!-- <v-list-item-subtitle v-html="item.Description" style="height: 20px; word-wrap: break-word;"></v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-if="notification.length == 0">
          <v-list-item>
            <v-list-item-content>
              <span style="text-align: center">Notification not yet.</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <br />
      <div
        class="content-area"
        :style="`width: ${width - 500}px; margin-left: 430px;`"
      >
        <v-container>
          <v-card :style="`min-height: ${height - 230}px`">
            <div class="content-inside">
              <v-container v-if="noti_data == null">
                <div
                  :style="`font-size: 20px; text-align: center; margin-top: 100px;`"
                >
                  Please Select Notification.
                </div>
              </v-container>
              <v-container v-if="noti_data != null">
                <br />
                <!-- {{ noti_data }} -->
                <v-row
                  style="
                    font-size: 20px;
                    padding-bottom: 20px;
                    border-bottom: 1px solid rgb(230, 230, 230);
                    margin-bottom: 20px;
                  "
                >
                  <v-col>{{ noti_data.Module }}</v-col>
                  <v-col style="text-align: right">
                    {{ Moment(noti_data.CreatedDate) }}
                  </v-col>
                </v-row>
                <h3>{{ noti_data.Title }}</h3>
                <br />
                <p v-html="noti_data.Description"></p>
                <div
                  :style="`width: 88%; position: absolute; bottom: 0; margin-bottom: 50px;`"
                >
                  <v-row>
                    <v-col v-if="noti_data.Status == 'Unread'"
                      >Status :
                      <b style="color: #ffa000">{{
                        noti_data.Status
                      }}</b></v-col
                    >
                    <v-col v-if="noti_data.Status == 'Read'"
                      >Status :
                      <b style="color: green">{{ noti_data.Status }}</b></v-col
                    >
                    <v-col style="text-align: right">
                      <!-- <v-btn color="success" text :disabled="noti_data.Status == 'Read'" @click="UpdateStatus('Read')"><v-icon style="margin-right: 10px;">mdi-check-circle</v-icon>Read</v-btn> -->
                      <v-btn color="error" text @click="UpdateStatus('Delete')"
                        ><v-icon style="margin-right: 10px"
                          >mdi-close-circle</v-icon
                        >Delete</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </div>
          </v-card>
        </v-container>
      </div>
    </div>
    <div v-if="width <= 600">
      <div class="noti_list" v-if="noti_data == null">
        <v-container>
          <v-row
            style="font-size: 18px; border-bottom: 1px solid rgb(230, 230, 230)"
          >
            <!-- <v-col style="margin-top: 15px">Notification</v-col> -->
            <v-col style="text-align: right">
              <v-chip class="ma-2" color="warning" text-color="white">
                <v-avatar left>
                  <v-icon>mdi-alert-circle</v-icon>
                </v-avatar>
                {{ noti_count }}
              </v-chip>
              <v-chip class="ma-2" color="primary" text-color="white">
                <v-avatar left>
                  <v-icon>mdi-mail</v-icon>
                </v-avatar>
                {{ notification.length }}
              </v-chip>
            </v-col>
          </v-row>
        </v-container>
        <v-list v-if="notification.length > 0">
          <v-list-item
            v-for="(item, index) in notification"
            :key="index"
            link
            @click="Read(item, index)"
            :class="{ focus: item.Status == 'Unread' }"
          >
            <v-list-item-content>
              <div style="margin-bottom: 10px; font-size: 12px">
                <div style="float: left">
                  <b>
                    <v-icon small color="warning" v-if="item.Status == 'Unread'"
                      >mdi-alert-circle</v-icon
                    ><v-icon small color="success" v-if="item.Status == 'Read'"
                      >mdi-check-circle</v-icon
                    >
                    {{ item.Module }}</b
                  >
                </div>
                <div style="float: right">{{ Moment(item.CreatedDate) }}</div>
              </div>
              <v-list-item-title>{{ item.Title }}</v-list-item-title>
              <!-- <v-list-item-subtitle v-html="item.Description"></v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-if="notification.length == 0">
          <v-list-item>
            <v-list-item-content>
              <span style="text-align: center">Notification not yet.</span>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div class="noti_content" v-if="noti_data != null">
        <v-container>
          <v-card>
            <v-container>
              <div>
                <v-btn icon @click="noti_data = null">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-container v-if="noti_data != null">
                  <!-- {{ noti_data }} -->
                  <v-row
                    style="
                      font-size: 16px;
                      padding-bottom: 20px;
                      border-bottom: 1px solid rgb(230, 230, 230);
                      margin-bottom: 20px;
                    "
                  >
                    <v-col>{{ noti_data.Module }}</v-col>
                    <v-col style="text-align: right">
                      {{ Moment(noti_data.CreatedDate) }}
                    </v-col>
                  </v-row>
                  <h3>{{ noti_data.Title }}</h3>
                  <br />
                  <p v-html="noti_data.Description"></p>
                  <div style="margin-top: 50px">
                    <v-row>
                      <v-col v-if="noti_data.Status == 'Unread'"
                        >Status :
                        <b style="color: #ffa000">{{
                          noti_data.Status
                        }}</b></v-col
                      >
                      <v-col v-if="noti_data.Status == 'Read'"
                        >Status :
                        <b style="color: green">{{
                          noti_data.Status
                        }}</b></v-col
                      >
                      <v-col style="text-align: right">
                        <!-- <v-btn color="success" text :disabled="noti_data.Status == 'Read'" @click="UpdateStatus('Read')"><v-icon style="margin-right: 10px;">mdi-check-circle</v-icon>Read</v-btn> -->
                        <v-btn
                          color="error"
                          text
                          @click="UpdateStatus('Delete')"
                          ><v-icon style="margin-right: 10px"
                            >mdi-close-circle</v-icon
                          >Delete</v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </div>
            </v-container>
          </v-card>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import socketIOClient from "socket.io-client";
import axios from "axios";
import { IP, AuthKey } from "../config";
import moment from "moment";
import { WindowResize } from "../function/WindowResize";

const socket = socketIOClient(IP);

export default {
  data() {
    return {
      IP: IP,
      user: "",
      mini: true,
      drawer: true,
      notification: [],
      ready: false,
      width: 0,
      height: 0,
      noti_data: null,
      noti_count: 0,
      focus: null,
    };
  },
  methods: {
    UpdateStatus(status) {
      return new Promise(async (resolve, reject) => {
        if (this.noti_data.Status != status) {
          axios({
            method: "post",
            url: `${IP}/notification/update-status`,
            headers: {
              Authorization: AuthKey,
            },
            data: {
              ID: this.noti_data.ID,
              Status: status,
            },
          })
            .then(async (response) => {
              console.log("response: ", response.data);
              await axios.get(`${IP}/notification/active`, {
                headers: {
                  Authorization: AuthKey,
                },
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    Read(item, index) {
      this.focus = index;
      console.log("focus: ", index);
      this.noti_data = item;
      this.UpdateStatus("Read");
    },
    Moment(date) {
      if (date != null && date != "") {
        return moment(date).format("lll");
      } else {
        return date;
      }
    },
    GetNotification() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/notification/get`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            EmployeeEmail: this.user.EmployeeEmail,
          },
        }).then((response) => {
          this.noti_count = 0;
          this.notification = response.data;
          if (this.noti_data != null) {
            let ID = this.noti_data.ID;
            this.noti_data = null;
            for (var i = 0; i < this.notification.length; i++) {
              if (ID == this.notification[i].ID) {
                this.noti_data = this.notification[i];
                this.UpdateStatus("Read");
              }
            }
          }
          for (var x = 0; x < this.notification.length; x++) {
            if (this.notification[x].Status == "Unread") {
              this.noti_count++;
            }
          }
          resolve(true);
        });
      });
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    async response() {
      socket.on("active", (notification) => {
        this.GetNotification();
      });
    },
    start() {
      this.response();
      this.resize();
      this.GetNotification().then(() => {
        if (this.$route.query.focus != undefined) {
          let focus = this.$route.query.focus;
          for (var i = 0; i < this.notification.length; i++) {
            if (focus == this.notification[i].ID) {
              this.noti_data = this.notification[i];
              this.UpdateStatus("Read");
            }
          }
        }
      });
    },
  },
  watch: {
    "$route.query.focus": function (focus) {
      this.start();
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    this.start();
  },
};
</script>

<style scoped>
.main-area {
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
}

.navigation {
  padding-top: 65px;
  z-index: 50;
  transition: 0.3s all;
}

.content-inside {
  width: 90%;
  margin: 0 auto;
}

.focus {
  background: rgb(240, 240, 240);
}

#list-focus {
  background: rgb(207, 207, 207);
}
</style>