<template>
  <div class="filter">
    <AdvanceFilter
      :headers="headers"
      :FilterData="FilterItems"
      @update-data="UpdateData"
      v-if="ready == true"
    />
    <v-text-field v-model="search" label="Search ..."></v-text-field>
    <v-data-table
      :headers="headers"
      :items="listItems"
      :search="search"
      class="elevation-1"
    />
  </div>
</template>

<script>
import { IP, AuthKey } from "../../../config";
import axios from "axios";

// filter component
import AdvanceFilter from "./Filter.vue";

export default {
  data() {
    return {
      search: "",
      listItems: [],
      FilterItems: [],
      headers: [
        { text: "Name", value: "Name" },
        { text: "Description", value: "Description" },
        { text: "Create By", value: "CreateBy" },
        { text: "Number", value: "Number" },
        { text: "Date", value: "CreatedDate" },
      ],
      ready: false,
    };
  },
  components: {
    AdvanceFilter,
  },
  methods: {
    UpdateData(result) {
      if (result.status == true) {
        this.listItems = JSON.parse(result.data);
      } else {
        this.listItems = JSON.parse(this.FilterItems);
      }
    },
    GetAllDigitalAsset() {
      this.ready = false;
      axios({
        methods: "get",
        url: IP + "/get-all-digital-asset",
        headers: {
          Authorization: AuthKey,
        },
      }).then((response) => {
        response.data.forEach(function (val, i) {
          val.Number = Math.floor(Math.random() * 101);
        }, this);
        this.FilterItems = JSON.stringify(response.data);
        this.listItems = response.data;
        this.ready = true;
      });
    },
  },
  created() {
    this.GetAllDigitalAsset();
  },
};
</script>