<template>
  <div class="expansion-panels">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Project Experience</v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn color="success" block @click="Action('create', null)">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px">
        ยังไม่มี Project Experience
      </p>
    </div>
    <v-card-title v-if="listItems.length > 0">
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="listItems"
      :search="search"
      class="elevation-1"
      v-if="listItems.length > 0"
    >
      <template v-slot:item.StartProject="{ item }">
        {{ ConvertDate(item.StartProject) }}
      </template>
      <template v-slot:item.EndProject="{ item }">
        <div v-if="item.EndProject != null">
          {{ ConvertDate(item.EndProject) }}
        </div>
      </template>
      <template v-slot:item.ProjectStatus="{ item }">
        <v-chip
          class="ma-2"
          color="success"
          v-if="item.ProjectStatus == 'In Progress'"
          >{{ item.ProjectStatus }}</v-chip
        >
        <v-chip
          class="ma-2"
          color="primary"
          v-if="item.ProjectStatus == 'Close'"
          >{{ item.ProjectStatus }}</v-chip
        >
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-2"
              v-on="on"
              style="cursor: pointer"
              @click="Action('update', item)"
              >edit</v-icon
            >
          </template>
          <span>Update</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              v-on="on"
              style="cursor: pointer"
              @click="DeleteProjectExperience(item)"
              >delete</v-icon
            >
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- dialog -->
    <v-dialog v-model="dialog" width="100%" max-width="500">
      <v-card>
        <v-card-title v-if="action == 'create'"
          >Create Project Experience</v-card-title
        >
        <v-card-title v-if="action == 'update'"
          >Update Project Experience</v-card-title
        >
        <v-divider style="margin-bottom: -10px"></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                label="Customer"
                outlined
                v-model="experience.customer"
                name="customer"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('customer')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("customer") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                label="Project Name"
                outlined
                v-model="experience.projectname"
                name="projectname"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('projectname')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("projectname") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-textarea
                label="Description"
                outlined
                v-model="experience.description"
                name="description"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('description')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("description") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-menu
                v-model="menu_startdate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="experience.startdate"
                    label="Start Date"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    v-validate="'required'"
                    name="startdate"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="experience.startdate"
                  @input="menu_startdate = false"
                ></v-date-picker>
              </v-menu>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('startdate')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("startdate") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-menu
                v-model="menu_enddate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="experience.enddate"
                    label="End Date"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    name="enddate"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="experience.enddate"
                  @input="menu_enddate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                label="Project Manager"
                outlined
                v-model="experience.projectmanager"
                name="projectmanager"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-select
                label="Status"
                :items="status"
                outlined
                v-model="experience.status"
                name="status"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('status')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("status") }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider style="margin-top: 20px"></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="CreateProjectExperience"
            v-if="action == 'create'"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
          <v-btn
            color="warning"
            @click="UpdateProjectExperience"
            v-if="action == 'update'"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../../config";
import moment from "moment";

export default {
  data() {
    return {
      IP: IP,
      user: "",
      headers: [
        { text: "Project Name", value: "ProjectName" },
        { text: "Customer", value: "Customer" },
        { text: "Description", value: "ProjectDescription" },
        { text: "Start Date", value: "StartProject" },
        { text: "End Date", value: "EndProject" },
        { text: "Project Manager", value: "ProjectManager" },
        { text: "Status", value: "ProjectStatus" },
        { text: "Actions", value: "action", sortable: false },
      ],
      listItems: [],
      search: "",
      dialog: false,
      experience: {},
      menu_startdate: false,
      menu_enddate: false,
      action: "",
      status: ["In Progress", "Close"],
      loading: false,
    };
  },
  methods: {
    GetProjectExperience() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/get-experience-by-email",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.email,
          },
        }).then((response) => {
          this.listItems = response.data;
          resolve(true);
        });
      });
    },
    CreateProjectExperience() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          Swal.fire({
            title: "Are you sure ?",
            text: "คุณต้องการบันทึกประสบการณ์นี้หรือไม่ !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.CreateProcess().then(async (response) => {
                if (response == "success") {
                  await this.Clear();
                  await this.GetProjectExperience();
                  Swal.fire("เพิ่มข้อมูลเสร็จสิ้น !", "", "success");
                }
              });
            }
          });
        }
      });
    },
    CreateProcess() {
      return new Promise(async (resolve, reject) => {
        this.experience.email = this.email;
        axios({
          method: "post",
          url: IP + "/user-management/create-experience",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            experience: this.experience,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    UpdateProjectExperience() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          Swal.fire({
            title: "Are you sure ?",
            text: "คุณต้องการแก้ไขหรือไม่ !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.UpdateProcess().then(async (response) => {
                if (response == "success") {
                  await this.Clear();
                  await this.GetProjectExperience();
                  Swal.fire("แก้ไขข้อมูลเสร็จสิ้น !", "", "success");
                }
              });
            }
          });
        }
      });
    },
    UpdateProcess() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/update-experience",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            experience: this.experience,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    DeleteProjectExperience(data) {
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบหรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          this.DeleteProcess(data.ID).then(async (response) => {
            if (response == "success") {
              await this.Clear();
              await this.GetProjectExperience();
              Swal.fire("ลบข้อมูลเสร็จสิ้น !", "", "success");
            }
          });
        }
      });
    },
    DeleteProcess(id) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/delete-experience",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            id: id,
            email: this.email,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    Clear() {
      this.experience = {};
      this.dialog = false;
    },
    ConvertDate(date) {
      const result = moment(date).format("L");
      return result;
    },
    Action(action, data) {
      this.experience = {};
      this.action = action;
      if (action == "update") {
        this.experience = {
          id: data.ID,
          projectname: data.ProjectName,
          customer: data.Customer,
          description: data.ProjectDescription,
          startdate: moment(data.StartProject).format("YYYY-MM-DD"),
          projectmanager: data.ProjectManager,
          status: data.ProjectStatus,
        };
        if (data.EndProject != null) {
          this.experience.enddate = moment(data.EndProject).format(
            "YYYY-MM-DD"
          );
        } else {
          this.experience.enddate = null;
        }
      }
      this.dialog = !this.dialog;
    },
    async start() {
      this.loading = true;
      await this.GetProjectExperience().then((response) => {
        var that = this;
        setTimeout(function () {
          that.loading = false;
        }, 1000);
      });
    },
  },
  props: ["email"],
  created() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";

.expansion-panels {
  margin-bottom: 30px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>