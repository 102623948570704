<template>
  <div class="chat" id="chat">
    <ChatDrawer
      :items="menu_items"
      @update-link="UpdateLink"
      @delete="DeleteChat"
      :loading="!ready.menu"
    />
    <div
      class="bottom-div"
      :style="{ transform: transformStyle }"
      ref="bottomDiv"
    >
      <div class="bottom-div-content" :style="bottomDivStyle">
        <div
          class="editor-box"
          :style="[width < 920 ? { margin: '10px 0px 10px 0px' } : {}]"
        >
          <v-row
            v-if="files.length"
            style="border-bottom: 2px solid #f3f3f3; padding-bottom: 5px"
            no-gutters
          >
            <v-col cols="auto" v-for="file in files" :key="file.name">
              <v-list-item class="file-item-area">
                <v-icon
                  small
                  id="remove-btn"
                  @click="removeFile(files.indexOf(file))"
                  >mdi-close</v-icon
                >
                <v-icon large class="mr-2">{{ typeToIcon(file.type) }}</v-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ file.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    prettySize(file.size)
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="auto" class="align-self-end">
              <v-menu top offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!ready.content"
                    id="left-btn"
                    style="margin-top: 5px"
                  >
                    <v-icon>mdi-translate</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(language_item, index) in language_items"
                    :key="index"
                    @click="ChooseLanguage(language_item)"
                  >
                    <v-list-item-title>{{
                      language_item.text
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="auto" class="align-self-end">
              <VoiceRecognition
                @update-voice-recognition="updateVoiceRecognition"
                :lang="language"
                :key="key.voice"
              />
              <div class="small-lang">
                {{ language_text }}
              </div>
            </v-col>
            <v-col cols="auto" class="align-self-end">
              <input
                type="file"
                name="file"
                id="fileInput"
                class="hidden-input"
                @change="handleFileInput"
                ref="fileInput"
                accept=".pdf, .docx"
                multiple
              />
              <v-btn depressed x-small @click="chooseFileInput" id="left-btn">
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-textarea
                v-model="message"
                label="Send a message"
                class="custom-textarea"
                dark
                dense
                hide-details
                single-line
                auto-grow
                text
                rows="1"
                @keydown.enter.prevent="handleEnterKey"
                @change="backupMessage"
              ></v-textarea>
            </v-col>
            <v-col cols="auto" class="align-self-end">
              <v-btn
                depressed
                x-small
                :style="[
                  message != ''
                    ? { background: '#65a000' }
                    : { background: '#212121' },
                ]"
                @click="sendMessage"
                :disabled="!ready.content"
                :loading="loading"
                id="right-btn"
              >
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <!-- chat area -->
    <div
      class="custom-container"
      ref="container"
      :style="[
        chat_items.length >= 2
          ? { marginBottom: '80px', marginBottom: '155px' }
          : { marginBottom: '0px' },
      ]"
    >
      <div v-if="!ready.content" style="height: 80vh">
        <ContentLoading :width="48" :height="60" :fillHeight="true" />
      </div>
      <div v-if="ready.content">
        <div class="no-chat-items" v-if="chat_items.length === 0">
          <ChatIntroduction v-if="width >= 700" />
        </div>
        <div v-for="(chat_item, n) in chat_items" :key="n">
          <div
            class="chat-div"
            :style="[
              chat_item.role === 'assistant'
                ? { background: '#3D3D3D' }
                : { background: '#121212' },
            ]"
          >
            <div class="chat-div-content">
              <v-row>
                <v-col cols="auto">
                  <div
                    class="avatar-img"
                    v-if="chat_item.role === 'assistant'"
                    style="background: #ffffff; color: #616161"
                  >
                    <img :src="require(`../../public/stel-logo.png`)" />
                  </div>
                  <div
                    class="avatar-img"
                    v-if="chat_item.role === 'user'"
                    style="background: #71a714"
                  >
                    <div
                      v-if="
                        $store.state.currentUser.ProfileImage == null ||
                        $store.state.currentUser.ProfileImage == 'null'
                      "
                      style="padding: 11px 10px 10px 17px"
                    >
                      {{ short(user.EmployeeEmail) }}
                    </div>
                    <img
                      :src="`${IP}/profile_img/${$store.state.currentUser.ProfileImage}`"
                      v-if="
                        $store.state.currentUser.ProfileImage != null &&
                        $store.state.currentUser.ProfileImage != 'null'
                      "
                    />
                  </div>
                </v-col>
                <v-col>
                  <div v-html="chat_item.content"></div>
                  <!-- <div class="output ql-snow">
                    <div class="ql-editor" v-html="chat_item.content"></div>
                  </div> -->
                  <!-- <quill-editor
                    v-model="chat_item.content"
                    :options="editorOption"
                    :disabled="true"
                  ></quill-editor> -->
                </v-col>
                <!-- files display -->
                <v-col cols="12" v-if="chat_item.files.length > 0">
                  <v-row style="padding-bottom: 5px" no-gutters>
                    <v-col
                      cols="auto"
                      v-for="chat_file in chat_item.files"
                      :key="chat_file.name"
                    >
                      <v-list-item class="file-item-area">
                        <v-icon large class="mr-2">{{
                          typeToIcon(chat_file.type)
                        }}</v-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            chat_file.name
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            prettySize(chat_file.size)
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="chat_item.res_files.length > 0">
                  <v-row style="padding-bottom: 5px" no-gutters>
                    <v-col
                      cols="auto"
                      v-for="res_chat_file in chat_item.res_files"
                      :key="res_chat_file.name"
                    >
                      <v-list-item class="file-item-area">
                        <v-icon large class="mr-2">{{
                          typeToIcon(res_chat_file.FileType)
                        }}</v-icon>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            res_chat_file.OriginalFileName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            prettySize(res_chat_file.FileSize)
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="chat-div" style="background: #3d3d3d" v-if="loading">
          <div class="chat-div-content">
            <v-row>
              <v-col cols="auto">
                <div
                  class="avatar-img"
                  style="background: #ffffff; color: #616161"
                >
                  <img :src="require(`../../public/stel-logo.png`)" />
                </div>
              </v-col>
              <v-col>
                <ChatLoading :width="24" :height="30" color="#FFF" />
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="chat-div" style="background: #f44336" v-if="falseResult">
          <div class="chat-div-content">
            <v-row>
              <v-col cols="auto">
                <div
                  class="avatar-img"
                  style="background: #ffffff; color: #616161"
                >
                  <img :src="require(`../../public/stel-logo.png`)" />
                </div>
              </v-col>
              <v-col>
                Sorry, the system has a problem. Do you need to re-generate?
                <br />
                <v-btn
                  outlined
                  dark
                  small
                  style="margin-top: 10px"
                  @click="ReGenerate"
                >
                  <v-icon small class="mr-1">mdi-reload</v-icon>
                  re generate
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pretty from "prettysize";
import { IP, AuthKey } from "../config";

import { quillEditor } from "vue-quill-editor";
// import "highlight.js/styles/atom-one-dark.css";
// import hljs from "highlight.js";

// import "highlight.js/lib/common";
// import hljsVuePlugin from "@highlightjs/vue-plugin";

import { Formatted } from "../function/Formatted";
import { WindowResize } from "../function/WindowResize.js";
import { Others } from "../function/Others.js";
import { Base64 } from "../function/Base64.js";
// json
import json_file_items from "../json/chat/file_type_items.json";
// components
import ChatDrawer from "../components/Chat/ChatDrawer.vue";
import VoiceRecognition from "../components/Chat/VoiceRecognition.vue";
import ChatIntroduction from "../components/Chat/ChatIntroduction.vue";
import ContentLoading from "../components/ContentLoading.vue";
import ChatLoading from "../components/ChatLoading.vue";
import DeliveryServicepureAnimation from "../components/DeliveryServicepureAnimation.vue";

export default {
  data() {
    return {
      IP: IP,
      loading: false,
      user: "",
      mini: false,
      file_type_items: json_file_items,
      language: "en",
      language_text: "EN",
      language_items: [
        {
          text: "EN",
          value: "en",
        },
        {
          text: "TH",
          value: "th",
        },
      ],
      menu_items: [],
      files: [],
      chat_items: [],
      message: "",
      message_backup: "",
      editorOption: {
        theme: "snow",
        modules: {
          toolbar: false,
          syntax: {
            // highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      transformStyle: "translateY(0px)",
      width: 0,
      height: 0,
      isRecording: false,
      // mockup
      message_mockup: "",
      files_mockup: [],
      ready: {
        menu: false,
        content: false,
      },
      falseResult: false,
      key: {
        voice: null,
      },
    };
  },
  components: {
    quillEditor,
    ChatDrawer,
    VoiceRecognition,
    ChatIntroduction,
    ContentLoading,
    ChatLoading,
    DeliveryServicepureAnimation,
    // highlightjs: hljsVuePlugin.component,
  },
  computed: {
    bottomDivStyle() {
      if (this.$store.state.chatDrawerMini && this.width >= 920) {
        return {
          transform: "translate(-28px)",
        };
      } else if (!this.$store.state.chatDrawerMini && this.width >= 920) {
        return {
          transform: "translate(-131px)",
        };
      } else {
        return {
          transform: "translate(-0px)",
          padding: "0px",
        };
      }
    },
  },
  methods: {
    ChooseLanguage(val) {
      this.language = val.value;
      this.language_text = val.text;
      this.key.voice = Others.GenerateKeyID(10);
    },
    ReGenerate() {
      return new Promise(async (resolve, reject) => {
        let chat_id = this.$route.params.id;
        this.loading = true;
        this.falseResult = false;
        axios({
          method: "POST",
          url: `${IP}/chat/re-generate`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            chatID: chat_id,
            owner: this.user.EmployeeEmail,
          },
        }).then((response) => {
          this.loading = false;
          this.falseResult = false;
          let obj = {
            role: response.data.role,
            content: response.data.content,
            files: response.data.files,
            res_files: response.data.files,
          };
          this.chat_items.push(obj);
        });
      });
    },
    GetChatInformation() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "POST",
          url: `${IP}/chat/get-information`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.user.EmployeeEmail,
          },
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log("get chat information error: ", error);
            resolve([]);
          });
      });
    },
    GetChatContent(id) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `${IP}/chat/get-detail`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            chatID: id,
            owner: this.user.EmployeeEmail,
          },
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log("get chat detail error: ", error);
            resolve([]);
          });
      });
    },
    sendMessage() {
      let message = JSON.parse(JSON.stringify(this.message.trim()));
      if (message != "" && !this.loading) {
        console.log("files: ", this.files);
        this.message = "";
        this.message_backup = "";
        let obj = {
          role: "user",
          content: message,
          files: this.files,
          res_files: [],
        };
        console.log("obj: ", obj);
        this.chat_items.push(obj);
        this.loading = true;
        const files = this.files;
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("files", file);
        });
        let chat_id = this.$route.params.id;
        if (chat_id == undefined) {
          chat_id = "";
        }
        //  with HTML format
        formData.append("message", `${message}`);
        formData.append("chat_id", chat_id);
        formData.append("owner", this.user.EmployeeEmail);
        this.files = [];

        axios({
          method: "POST",
          url: `${IP}/chat/send-message`,
          headers: {
            Authorization: AuthKey,
          },
          data: formData,
        }).then(async (response) => {
          this.loading = false;
          // console.log("res: ", response.data);
          if (response.data.newChat) {
            this.$router.push(`/chat/${response.data.chatId}`);
            this.Start();
          } else {
            let res_content = await Formatted.MarkdownIt(response.data.content);
            let obj = {
              role: response.data.role,
              content: res_content,
              files: response.data.files,
              res_files: [],
            };
            this.chat_items.push(obj);
          }
          setTimeout(() => {
            this.scrollToEnd();
          }, 10);
        });
      }
    },
    DeleteChat(id) {
      axios({
        method: "POST",
        url: `${IP}/chat/delete`,
        headers: {
          Authorization: AuthKey,
        },
        data: {
          id: id,
        },
      })
        .then((response) => {
          this.ready.menu = false;
          this.ready.content = false;
          this.Start();
        })
        .catch((error) => {
          console.log("get chat detail error: ", error);
        });
    },
    setMenuItems(rows) {
      return new Promise(async (resolve, reject) => {
        let array = [];
        // console.log("rows: ", rows);
        for (var i = 0; i < rows.length; i++) {
          let title = await Base64.Decode(rows[i].ChatName);
          let obj = {
            id: rows[i].ChatID,
            title: title,
            icon: "mdi-chat",
            to: `/chat/${rows[i].ChatID}`,
            disabled: false,
          };
          array.push(obj);
        }
        resolve(array);
      });
    },
    setContent(rows) {
      return new Promise(async (resolve, reject) => {
        let array = [];
        // console.log("rows: ", rows);
        for (var i = 0; i < rows.length; i++) {
          let content = await Base64.Decode(rows[i].ChatDetail);
          if (rows[i].ChatRole === 'assistant') {
            content = await Formatted.MarkdownIt(content);
          }
          let res_files = rows[i].FileStatus ? rows[i].FileItems : [];
          // console.log("formatted centent: ", content);
          let obj = {
            role: rows[i].ChatRole,
            content: content,
            files: [],
            res_files: res_files,
          };
          array.push(obj);
        }
        // console.log("array: ", array);
        resolve(array);
      });
    },
    backupMessage() {
      if (!this.isRecording) {
        this.message_backup = JSON.parse(JSON.stringify(this.message));
      }
    },
    clearMessage() {
      this.message = "";
      this.message_backup = "";
      this.files = [];
    },
    updateVoiceRecognition(val, isRecording) {
      if (isRecording) {
        this.message = JSON.parse(JSON.stringify(this.message_backup));
        this.message = this.message + val;
      } else {
        this.backupMessage();
      }
    },
    handleEnterKey(event) {
      if (event.shiftKey) {
        // Add a new line manually when Shift+Enter is pressed
        const textArea = event.target;
        const caretPosition = textArea.selectionStart;
        const textBeforeCaret = this.message.substring(0, caretPosition);
        const textAfterCaret = this.message.substring(caretPosition);

        this.message = textBeforeCaret + "\n" + textAfterCaret;

        // Adjust the caret position to after the inserted newline
        this.$nextTick(() => {
          const newCaretPosition = caretPosition + 1;
          textArea.setSelectionRange(newCaretPosition, newCaretPosition);
        });

        event.preventDefault();
      } else {
        if (this.message != "") {
          this.sendMessage();
        }
      }
    },
    handleFileInput(e) {
      // const files = e.target.files;
      // this.onChange(files);
      const input = this.$refs.fileInput;
      if (input.files.length > 0) {
        this.onChange(input.files);
      }
      this.$refs.fileInput.value = "";
    },
    chooseFileInput() {
      document.getElementById("fileInput").click();
    },
    onChange(files) {
      const self = this;
      let incomingFiles = Array.from(files);
      const fileExist = self.files.some((r) =>
        incomingFiles.some(
          (file) => file.name === r.name && file.size === r.size
        )
      );
      if (fileExist) {
        self.showMessage = true;
        alert("New upload contains files that already exist");
      } else {
        self.files.push(...incomingFiles);
      }
    },
    removeFile(i) {
      this.files.splice(i, 1);
    },
    prettySize(size) {
      return pretty(size);
    },
    typeToIcon(type) {
      let icon = "";
      icon = this.file_type_items.find((x) => x.type === type).icon;
      return icon;
    },
    scrollToEnd() {
      let bottom = document.body.scrollHeight;
      window.scrollTo(0, bottom);
    },
    short(email) {
      var result = "";
      if (email != undefined) {
        var res = email.substr(0, 1);
        result = res.charAt(0).toUpperCase();
      } else {
        result = "UN";
      }
      return result;
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    updateTransform() {
      this.$nextTick(() => {
        const containerHeight = this.$refs.container.clientHeight;
        const divHeight = this.$refs.bottomDiv.clientHeight;
        const translateY =
          divHeight > containerHeight ? -(divHeight - containerHeight) : 0;
        this.transformStyle = `translateY(${translateY}px)`;
      });
    },
    UpdateLink(status) {
      this.falseResult = false;
      this.ready.content = false;
      this.clearMessage();
      setTimeout(() => {
        this.Start();
      }, 0);
    },
    async Start() {
      this.chat_items = [];
      this.falseResult = false;
      this.GetChatInformation().then((response) => {
        // console.log("get chat information: ", response);
        this.setMenuItems(response).then((response) => {
          this.menu_items = response;
        });
        if (this.$route.params.id != "") {
          this.GetChatContent(this.$route.params.id).then((response) => {
            console.log("get chat content: ", response);
            this.key.voice = Others.GenerateKeyID(10);
            if (response.status) {
              this.setContent(response.content).then((response) => {
                this.chat_items = response;
                if (
                  this.chat_items[this.chat_items.length - 1].role == "user"
                ) {
                  this.falseResult = true;
                }
                this.ready.menu = true;
                this.ready.content = true;
              });
            } else {
              this.$router.push("/chat").catch((err) => {});
              this.ready.menu = true;
              this.ready.content = true;
            }
          });
        } else {
          console.log("new chat.");
          this.ready.menu = true;
          this.ready.content = true;
        }
      });
    },
  },
  mounted() {
    this.updateTransform();
    this.resize();
  },
  created() {
    this.ready.menu = false;
    this.ready.content = false;
    this.user = this.$store.getters.currentUser;
    setTimeout(() => {
      this.Start();
    }, 0);
  },
};
</script>

<style scoped>
body {
  background: #121212;
}

.v-textarea >>> .v-input__slot::before {
  border: none !important;
}

.v-textarea >>> .v-input__slot::after {
  border: none !important;
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: #23241f; /* <== change this color */
  color: #f8f8f2;
  overflow: visible;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.custom-textarea {
  color: #fff;
  margin: 10px 15px 0px 15px;
}

.chat {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background: #ffffff; */
  background: #121212;
}

.chat-div {
  padding: 20px;
  color: white;
  width: calc(100% - 35px);
  margin: 0 auto;
  border-radius: 5px;
}

.chat-div-content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
}

.custom-container {
  padding: 0;
  /* min-height: calc(100vh - 139px); */
  height: 100%;
  /* margin-top: 64px; */
  padding-top: 80px;
  background: #121212;
}

.bottom-div {
  position: fixed;
  z-index: 100;
  bottom: 0;
  width: 100%;
  padding: 10px;
  transition: transform 0.3s ease;
  background: #212121;
}

.bottom-div-content {
  margin: 0 auto;
  width: 85%;
  max-width: 800px;
  padding: 10px;
  transition: 0.3s all;
}

@media only screen and (max-width: 1200px) {
  .bottom-div-content {
    max-width: 650px;
  }
}

.editor-box {
  padding: 5px;
  margin: 20px 0px 20px 0px;
  /* color: white; */
  /* background: #e4e4e4; */
  background: #3d3d3d;
  border-radius: 8px;
  transition: 0.2s all;
}

.avatar {
  color: white;
  padding: 10px 17px 10px 17px;
  border-radius: 5px;
  background: gray;
}

.avatar-img {
  width: 45px;
  height: 45px;
  padding: 5;
  margin: 0;
  color: white;
  border-radius: 5px;
  background: gray;
}

.avatar-img img {
  width: 100%;
  border-radius: 5px;
}

.no-chat-items {
  /* padding-top: 200px; */
  padding-top: 120px;
}

#left-btn {
  margin: 0px 0px 5px 5px;
  width: 35px;
  height: 35px;
  transition: 0.3s all;
  background: #212121;
  color: #fff;
}

#left-btn:hover {
  background: #65a000;
  color: white;
}

#right-btn {
  margin: 5px 5px 3px 0px;
  width: 35px;
  height: 35px;
  transition: 0.3s all;
  background: #65a000;
  color: #fff;
}

.file-item-area {
  max-width: 182px;
  margin: 5px;
  border-radius: 5px;
  background: #f3f3f3;
  cursor: pointer;
  transition: 0.2s all;
}

.file-item-area:hover {
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
}

#remove-btn {
  background: #616161;
  color: #e4e4e4;
  border-radius: 3px;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.2s all;
  margin-top: -5px;
  margin-right: -5px;
}

#remove-btn:hover {
  background: #f44336;
  color: #ffffff;
}

.small-lang {
  background: #eeeeee;
  padding: 2px;
  border-radius: 2px;
  font-size: 7px;
  color: #000000;
  position: absolute;
  transform: translate(28px, -17px);
}
</style>
