<template>
  <div class="quizListPage">
    <div
      cols="12"
      xs="12"
      sm="6"
      md="4"
      lg="4"
      xl="3"
      class="mb-5 d-flex flex-row-reverse"
    >
      <div>
        <v-row class="flex-row">
          <v-btn rounded color="primary" dark @click="addQuiz">
            <v-icon class="mr-2">{{ addIcon }}</v-icon
            >New Quiz</v-btn
          >
        </v-row>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="listItems"
      class="elevation-1"
      v-if="listItems.length > 0"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.QuizId">
            <td class="d-block d-sm-table-cell">{{ item.QuizName }}</td>
            <td class="d-block d-sm-table-cell">{{ item.QuizDescription }}</td>
            <td class="d-block d-sm-table-cell">{{ item.QuizStatus }}</td>
            <td>
              <v-tooltip>
                <template v-slot:activator="{ on }">
                  <v-icon small class="mr-2" v-on="on" @click="goTo(item)">
                    description
                  </v-icon>

                  <v-icon small v-on="on" class="mr-2" @click="editItem(item)"
                    >edit</v-icon
                  >
                </template>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import { mdiPlusCircle } from "@mdi/js";
export default {
  name: "quizListPage",
  data() {
    return {
      addIcon: mdiPlusCircle,
      attachment: [],
      error: "",
      auth: null,
      user: "",
      search: "",
      dialog: false,

      headers: [
        { text: "Quiz Name", value: "QuizName" },
        { text: "Description", value: "QuizDescription" },
        { text: "Status", value: "Status" },
        { text: "Actions", value: "action", sortable: false },
      ],
      listItems: [],
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    addQuiz() {
      this.$router.push({ name: "form-page" });
    },
    initialize() {
      this.listItems = [];
      let apiurl = "";
      axios({
        url: IP + "/api/v1/quiz-list-page",
        method: "get",
        headers: {
          Authorization: AuthKey,
        },
      }).then((resp) => {
        this.listItems = resp.data;
      });
    },
    goTo(item) {
      this.$router.push({ name: "quiz-page", params: { url: item.QuizId } });
    },
  },
  created() {
    this.initialize();
  },
};
</script>

<style>
.quizListPage {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
}
</style>
