<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <p style="font-size: 30px">
              Customer Information
              <v-chip class="ma-2" color="primary" label dark> Mokcup </v-chip>
            </p>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="PM Contact Name"
                v-model="detailItems.pm_contact_name"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="PM Phone"
                v-model="detailItems.pm_phone"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-text-field
                dense
                outlined
                label="PM email"
                v-model="detailItems.pm_email"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="detailItems.pm_notes"
                outlined
                dense
                hide-details
                rows="2"
                label="PM Notes"
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="Purchase Contact Name"
                v-model="detailItems.purchase_contact_name"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="Purchase Phone"
                v-model="detailItems.purchase_phone"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-text-field
                dense
                outlined
                label="Purchase email"
                v-model="detailItems.purchase_email"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="detailItems.purchase_notes"
                outlined
                dense
                hide-details
                rows="2"
                label="Purchase Notes"
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="Finance Contact Name"
                v-model="detailItems.finance_contact_name"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="Finance Phone"
                v-model="detailItems.finance_phone"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-text-field
                dense
                outlined
                label="Finance email"
                v-model="detailItems.finance_email"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="detailItems.finance_notes"
                outlined
                dense
                hide-details
                rows="2"
                label="Finance Notes"
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="Other Contact Name"
                v-model="detailItems.other_contact_name"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="Other Phone"
                v-model="detailItems.other_phone"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-text-field
                dense
                outlined
                label="Other email"
                v-model="detailItems.other_email"
                hide-details
                :readonly="readonly"
                :color="color"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="detailItems.other_notes"
                outlined
                dense
                hide-details
                rows="2"
                label="Other Notes"
                :readonly="readonly"
                :color="color"
                style="margin-bottom: 10px"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data() {
    return {
      readonly: this.ro,
      color: "",
      panel: [0],
      detailItems: {},
      user_items: ["Name 1", "Name 2", "Name 3"],
    };
  },
  props: ["ro"],
  methods: {},
  created() {
    if (this.ro) {
      this.color = "grey-lighten-5";
    }
  },
};
</script>