<template>
  <div class="home">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <div
      class="header-home"
      :style="`margin-top:${this.height / this.space}px; transition: 0.5s all;`"
      v-if="user == null"
    >
      <v-img
        :src="require('../assets/stel-logo-black.png')"
        style="
          display: block;
          margin-left: auto;
          margin-right: auto;
          max-width: 350px;
          width: 80%;
        "
      ></v-img>
      <br />
      <h3
        style="
          width: 270px;
          margin: 0 auto;
          border-top: 1px solid rgb(220, 220, 220);
          padding: 10px;
        "
      >
        INTERNAL WEBSITE
      </h3>
      <!-- <WebCam /> -->
      <p style="color: red">{{ error_message }}</p>
      <v-btn
        large
        color="primary"
        style="margin: 25px; max-width: 250px; width: 80%"
        @click="LoginWithAzureAD"
      >
        <v-icon left>mdi-azure</v-icon>Microsoft 365 Login
      </v-btn>
    </div>
    <div v-if="user != null">
      <div style="margin-top: 70px; margin-bottom: 100px; min-height: 100%">
        <SlideNotification />
        <Card />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../config";
import { CLIENDID } from "../config";
import Notification from "../components/Home/Notification";
import Card from "../components/Home/Card";
import WebCam from "../components/Home/Webcam";
import * as Msal from "msal";
import SlideNotification from "../components/Home/SlideNotification";

const msalConfig = {
  auth: {
    clientId: CLIENDID,
    authority:
      "https://login.microsoftonline.com/stelligence365.onmicrosoft.com",
    // authority: "https://login.microsoftonline.com/common"
  },
};

const msalInstance = new Msal.UserAgentApplication(msalConfig);

msalInstance.handleRedirectCallback((error, response) => {
  //
});

export default {
  data() {
    return {
      height: 0,
      space: 4,
      auth: null,
      user: "",
      error_message: "",
      loading: false,
    };
  },
  components: {
    Notification,
    Card,
    WebCam,
    SlideNotification,
  },
  methods: {
    LoginWithAzureAD() {
      var loginRequest = {
        scopes: ["user.read", "mail.send"],
      };
      msalInstance
        .loginPopup(loginRequest)
        .then(async (response) => {
          this.error_message = "";
          localStorage.setItem("microsoft", JSON.stringify(response));
          const email = response.account.userName;
          console.log(response);
          this.loading = true;
          const result = await this.SelectUser(email);
          if (result.error) {
            this.error_message = result.error.message;
            this.loading = false;
          } else if (result.EmployeeEmail) {
            this.$store.commit("loginSuccess", result);
            localStorage.setItem("auth", 1);
            this.loading = false;
            location.reload();
          } else {
            this.error_message = `couldn't find account, Please check your account.`;
            alert(`couldn't find account, Please check your account.`);
            msalInstance.logout();
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    SelectUser(email) {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/select-user-data",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: email,
          },
        }).then((response) => {
          const data = response.data;
          console.log("data: ", data);
          resolve(data);
        });
      });
    },
    windowHeight() {
      this.height = window.innerHeight;
    },
  },
  created() {
    if (process.browser) {
      this.height = window.innerHeight;
      window.addEventListener("resize", this.windowHeight);
    }
    this.user = this.$store.getters.currentUser;
  },
};
</script>

<style scoped>
.header-home {
  margin: 0 auto;
  text-align: center;
}
</style>