<template>
  <div class="expansion-panels">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Training Course</v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn color="success" @click="Action('create', null)" disabled block>
            <v-icon class="mr-2">mdi-plus</v-icon>Create
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px">
        ยังไม่มี course ลงทะเบียน
      </p>
    </div>
    <v-card-title v-if="listItems.length > 0">
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="listItems"
      :search="search"
      class="elevation-1"
      v-if="listItems.length > 0"
    >
      <template v-slot:item.Budget="{ item }">
        <div style="text-align: right">{{ ConvertCurrency(item.Budget) }}</div>
      </template>
      <template v-slot:item.CourseStatus="{ item }">
        <v-chip class="ma-2" color="warning" v-if="item.CourseStatus == false"
          >Not Started</v-chip
        >
        <v-chip class="ma-2" color="success" v-if="item.CourseStatus == true"
          >Starting</v-chip
        >
      </template>
      <template v-slot:item.RequestStatus="{ item }">
        <v-chip class="ma-2" color="primary" v-if="item.RequestStatus == 0"
          >Pending</v-chip
        >
        <v-chip class="ma-2" color="success" v-if="item.RequestStatus == 1"
          >Approved</v-chip
        >
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              v-on="on"
              @click="DownloadFile(item)"
              :disabled="item.AttachFile == ''"
              style="cursor: pointer; margin-right: 10px"
              >mdi-cloud-download</v-icon
            >
          </template>
          <span>Download</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../../config";
import download from "downloadjs";

export default {
  data() {
    return {
      IP: IP,
      user: "",
      headers: [
        { text: "Topic name", value: "TopicName" },
        { text: "Description", value: "CourseDescription" },
        { text: "Budget (THB)", value: "Budget" },
        { text: "Course Status", value: "CourseStatus" },
        { text: "Request Status", value: "RequestStatus" },
        { text: "Actions", value: "action", sortable: false },
      ],
      listItems: [],
      search: "",
      dialog: false,
      course: {},
      action: "",
      loading: false,
    };
  },
  methods: {
    DownloadFile(data) {
      fetch(`${IP}/api/v1/download-file/${data.ReqId}`, {
        method: "get",
        headers: {
          Authorization: AuthKey,
        },
      }).then((res) => {
        const blob = res.blob();
        blob.then(async (result) => {
          await download(result, "download", result.type);
        }, this);
      });
    },
    GetTrainingCourseByEmail() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/api/v1/trainings",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            Email: this.email,
          },
        }).then((response) => {
          this.listItems = response.data;
          console.log(this.listItems);
          resolve(true);
        });
      });
    },
    Clear() {
      this.course = {};
      this.dialog = false;
    },
    Action(action, data) {
      this.course = {};
      this.action = action;
      // if (action == "update") {

      // }
      this.dialog = !this.dialog;
    },
    ConvertCurrency(int) {
      return int.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    async start() {
      this.loading = true;
      await this.GetTrainingCourseByEmail().then((response) => {
        var that = this;
        setTimeout(function () {
          that.loading = false;
        }, 1000);
      });
    },
  },
  props: ["email"],
  created() {
    this.start();
  },
};
</script>

<style scoped>
@import "../../css/transition.scss";

.expansion-panels {
  margin-bottom: 30px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>