
<template>
  <div class="card">
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          v-for="cn in category"
          :key="cn.name"
        >
          <br v-if="cn.show" />
          <p
            v-if="cn.show"
            style="
              font-size: 30px;
              padding: 5px;
              border-bottom: 1px solid rgb(220, 220, 220);
            "
          >
            {{ cn.name }}
          </p>
          <v-row no-gutters>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="4"
              lg="4"
              xl="3"
              v-for="n in menu"
              :key="n.link"
              v-show="cn.name == n.category"
            >
              <v-container>
                <v-card
                  :color="n.color"
                  :dark="n.dark"
                  class="area-card hvr-bounce-in transition"
                  @click="goTo(n)"
                >
                  <div
                    style="
                      position: absolute;
                      z-index: -1;
                      width: 100%;
                      height: 100%;
                    "
                  >
                    <v-img
                      :src="require(`../../assets/card/${n.background}`)"
                      class="background-card"
                    ></v-img>
                  </div>
                  <v-container>
                    <v-card-title id="card-text" class="headline">
                      <!-- <v-icon class="mr-2">{{ n.icon }}</v-icon> -->
                      {{ n.title }}
                    </v-card-title>
                    <v-card-subtitle id="card-text">{{
                      n.subtitle
                    }}</v-card-subtitle>
                  </v-container>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: "",
      // "HR",
      category: [
        {
          name: "Sales & Finance",
          show: false,
        },
        {
          name: "Others",
          show: false,
        },
        {
          name: "Test",
          show: false,
        },
      ],
      menu: [],
    };
  },
  methods: {
    goTo(obj) {
      if (obj.external) {
        window.open(obj.link, "_blank");
      } else {
        this.$router.push({ name: obj.link });
      }
    },
    filterMenu(accessRight) {
      //console.log("right", accessRight);
      //console.log("card", accessRight[0].Card);
      for (var i = 0; i < accessRight[0].Card.length; i++) {
        //console.log(accessRight[0].Card[i]);
        if (accessRight[0].Card[i] == "LeaveRequest") {
          this.menu.push(
            // {
            //   link: "leave",
            //   title: "Leave Request",
            //   subtitle: "Online Leave Request and Approval",
            //   dark: true,
            //   color: "deep-orange darken-4",
            //   category: "HR",
            //   icon: "mdi-hiking",
            //   background: "leave-request.jpg",
            // },
            // {
            //   link:
            //     "https://stelligence365.sharepoint.com/sites/HRDocument/Shared%20Documents/Forms/AllItems.aspx",
            //   title: "HR Documentation",
            //   subtitle: "All file from HR resources in our company",
            //   dark: true,
            //   color: "blue lighten-1",
            //   category: "HR",
            //   icon: "mdi-file-document",
            //   external: true,
            //   background: "hr-document.jpg",
            // },
            // {
            //   link: "folder-digital-asset",
            //   title: "Digital Asset",
            //   subtitle: "Our Digital Asset Inventory",
            //   dark: true,
            //   color: "blue-grey darken-4",
            //   category: "Others",
            //   icon: "mdi-arrow-right-drop-circle",
            //   background: "digital-asset.jpg",
            // },
            // {
            //   link: "hr-performance-menu",
            //   title: "HR & Performance",
            //   subtitle: "HR & Performance System",
            //   dark: true,
            //   color: "blue-grey darken-4",
            //   category: "Others",
            //   icon: "mdi-arrow-right-drop-circle",
            //   background: "hr-document.jpg",
            // },
            {
              link: "support",
              title: "Customer Support",
              subtitle: "Customer Support",
              dark: true,
              color: "blue darken-4",
              category: "Others",
              icon: "mdi-hand-heart",
              background: "support.jpg",
            },
            {
              link: "internalsupport",
              title: "Internal Support",
              subtitle: "Internal Support",
              dark: true,
              color: "green darken-4",
              category: "Others",
              icon: "mdi-hand-heart",
              background: "support.jpg",
            },
            {
              link: "chat-gpt",
              title: "Chat GPT",
              subtitle: "The Chat GPT Features",
              dark: true,
              color: "#409965",
              category: "Others",
              icon: "mdi-cart-arrow-right",
              background: "ai.jpg",
            }
            // {
            //   link: "choose-room-reservation",
            //   title: "Room Reservation",
            //   subtitle: "Company reservation in our company",
            //   dark: true,
            //   color: "green darken-4",
            //   category: "Others",
            //   icon: "mdi-gesture-tap-box",
            // background: "digital-asset.jpg",
            // }
            // {
            //   link: "holiday",
            //   title: "Holiday",
            //   subtitle: "วันหยุดของบริษัท",
            //   dark: true,
            //   color: "deep-orange darken-4",
            //   category: "HR",
            //   icon: "mdi-hiking",
            //   background: "leave-request.jpg",
            // },
            // {
            //   link: "main-exam",
            //   title: "Exam",
            //   subtitle: "System for testing.",
            //   dark: true,
            //   color: "yellow darken-4",
            //   category: "Others",
            //   icon: "mdi-book-open-outline",
            //   background: "exam.jpg"
            // }
          );
        } else if (accessRight[0].Card[i] == "TrainRequest") {
          this.menu
            .push
            // {
            //   link: "training-list",
            //   title: "Training",
            //   subtitle: "Request for training and certificate examination",
            //   dark: true,
            //   color: "deep-purple darken-4",
            //   category: "HR",
            //   icon: "mdi-book-open-variant",
            //   background: "training.jpg",
            // },
            // {
            //   link: "TrainingStatistics",
            //   title: "Training Statistics",
            //   subtitle: "Training statistics from Teachable",
            //   dark: true,
            //   color: "green darken-2",
            //   category: "HR",
            //   icon: "mdi-teach",
            //   background: "training-statistics.jpg",
            // }
            ();
        } else if (accessRight[0].Card[i] == "SaleOrders") {
          this.menu.push(
            // {
            //   link: "sale-orders-new",
            //   title: "Sale Order",
            //   subtitle: "Register and Manage Sales Order and Project",
            //   dark: true,
            //   color: "#385F73",
            //   category: "Sales & Finance",
            //   icon: "mdi-cart-arrow-right",
            //   background: "sale-order.jpg",
            // },
            {
              link: "sale-orders",
              title: "Sale Order",
              subtitle: "Register and Manage Sales Order and Project",
              dark: true,
              color: "#385F73",
              category: "Sales & Finance",
              icon: "mdi-cart-arrow-right",
              background: "sale-order.jpg",
            }
          );
        } else if (accessRight[0].Card[i] == "SaleOpportunity") {
          this.menu.push({
            link: "sale-opportunity-predictive",
            title: "POC Sales Opportunity",
            subtitle: "POC Sales Opportunity Predictive",
            dark: true,
            color: "#385F73",
            category: "Sales & Finance",
            icon: "mdi-cart-arrow-right",
            background: "sale-order.jpg",
          });
        } else if (accessRight[0].Card[i] == "Certificates") {
          this.menu.push({
            link: "certificates",
            title: "Certificates",
            subtitle: "View Employee certificates",
            dark: true,
            color: "#385F73",
            category: "Sales & Finance",
            icon: "mdi-cart-arrow-right",
            background: "certificates.jpg",
          });
        } else if (accessRight[0].Card[i] == "Test") {
          // this.menu.push({
          //   link: "chat-gpt",
          //   title: "Chat GPT",
          //   subtitle: "The Chat GPT Features",
          //   dark: true,
          //   color: "#409965",
          //   category: "Others",
          //   icon: "mdi-cart-arrow-right",
          //   background: "ai.jpg",
          // });
        }
      }
      for (var x = 0; x < this.category.length; x++) {
        for (var y = 0; y < this.menu.length; y++) {
          if (this.category[x].name == this.menu[y].category) {
            this.category[x].show = true;
          }
        }
      }
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    this.filterMenu(JSON.parse(this.user.AccessRightsJSON));
  },
};
</script>

<style scoped lang="scss">
//
</style>