<template>
  <div>
    <div class="mouseArea" @mousedown="captureOn" @mouseup="captureOff" @mousemove="mo"></div>
    <!-- <div>x : {{x}}</div>
    <div>y : {{y}}</div> -->
    <p v-if="x == 0" style="text-align: center; font-size: 30px;">
        กรุณาคลิกในช่องสี่เหลี่ยม
    </p>
    <div v-if="x != 0" :style="`text-align: center; border-radius: 180px; width: 200px; height: 200px; background: rgb(129, 129, 129); position: absolute; top: ${y + 200}px; left: ${x - 100}px`">
        <p style="margin-top: 70px; font-size: 20px; color: white;">x : {{ x }} <br> y : {{ y }} </p>
    </div>
    
     <!-- <div class="block-area" @mousedown="captureOn" @mouseup="captureOff" @mousemove="mo" :style="`top: ${y - 100}px; left: ${x - 100}px;`"></div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      captureToggle: false,
      x: 0,
      y: 0
    };
  },
  methods: {
    mo: function(evt) {
        // console.log('mo')
      if (this.captureToggle) {
        this.x = evt.x;
        this.y = evt.y;
      }
    },
    captureOn: function() {
        // console.log('captureOn')
      this.captureToggle = true;
    },
    captureOff: function() {
        // console.log('captureOff')
      this.captureToggle = false;
    }
  }
};
</script>

<style scoped>
.mouseArea {
  width: 80%;
  height: 200px;
  margin: 0 auto;
  border: 1px solid rgb(129, 129, 129);
}

.block-area{
    width: 200px;
    height: 200px;
    background: rgb(80, 80, 80);
    opacity: 0.5;
    transition: 0.2s all;
    position: absolute;
}

.block-area:active{
    opacity: 1;
}
</style>