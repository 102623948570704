<template>
  <div class="main-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col cols="auto">
          <v-btn
            icon
            color="teal"
            style="margin: 5px 10px 0px 0px"
            to="/project"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col style="font-size: 30px">{{ project.ProjectName }}</v-col>
        <v-col>
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                style="float: right; margin: 5px 10px 0px 0px"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="Action('create-task', null)">
                <v-list-item-title>Assign Task</v-list-item-title>
              </v-list-item>
              <v-list-item @click="Action('create-subtask', null)">
                <v-list-item-title>Create Subtask</v-list-item-title>
              </v-list-item>
              <v-list-item @click="Action('project-information', null)">
                <v-list-item-title>Project Information</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            icon
            @click="dialog_planning = !dialog_planning"
            style="float: right; margin: 5px 10px 0px 0px"
          >
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- component -->
    <v-tabs
      v-model="tab"
      background-color="transparent"
      grow
      class="content-inside-tab"
    >
      <v-tab v-for="item in tab_item" :key="item">{{ item }}</v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <Accountable
            v-bind:listItems="listItemsAccountable"
            v-bind:member="member"
            @update-data="UpdateData"
          />
        </v-tab-item>
        <v-tab-item>
          <Responsible
            v-bind:listItems="listItemsResponsible"
            @update-data="UpdateData"
          />
        </v-tab-item>
        <v-tab-item>
          <Calendar v-bind:all_task="all_task" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <!-- dialog task -->
    <v-dialog v-model="dialog_task" width="100%;" max-width="500" key="uuid">
      <v-card>
        <v-card-title>Assign Task</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Task Name"
                outlined
                v-model="task.name"
                name="name-task"
                v-validate="'required'"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('name-task')"
                  style="margin-top: -20px"
                  >{{ errors.first("name-task") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-autocomplete
                v-model="task.assign_to"
                :items="resultAssign"
                outlined
                label="Assign to"
                item-text="name"
                item-value="email"
                name="assign"
                v-validate="'required'"
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove('assign', data.item)"
                    >{{ data.item.name }}</v-chip
                  >
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('assign')"
                  style="margin-top: -20px"
                  >{{ errors.first("assign") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-autocomplete
                v-model="task.accountable"
                :items="resultAccountable"
                outlined
                label="Accountable"
                item-text="name"
                item-value="email"
                name="accountable"
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove('accountable', data.item)"
                    >{{ data.item.name }}</v-chip
                  >
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('accountable')"
                  style="margin-top: -20px"
                  >{{ errors.first("accountable") }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="success" @click="CreateTask" style="margin: 10px">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Assign
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog subtask -->
    <v-dialog v-model="dialog_subtask" width="100%;" max-width="500">
      <v-card>
        <v-card-title v-if="action == 'create-subtask'"
          >Create Subtask</v-card-title
        >
        <v-card-title v-if="action == 'update-subtask'"
          >Update Subtask</v-card-title
        >
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Subtask Name"
                outlined
                v-model="subtask.name"
                name="create-name-subtask"
                v-validate="'required'"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('create-name-subtask')"
                  style="margin-top: -20px"
                  >{{ errors.first("create-name-subtask") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-select
                item-text="name"
                item-value="id"
                :items="resultSubtaskOf"
                v-model="subtask.subtask_of"
                label="Subtask Of"
                outlined
                name="create-subtask-of"
                v-validate="'required'"
              ></v-select>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('create-subtask-of')"
                  style="margin-top: -20px"
                  >{{ errors.first("create-subtask-of") }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="CreateSubtask"
            style="margin: 10px"
            v-if="action == 'create-subtask'"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
          <v-btn
            color="success"
            @click="UpdateSubtask"
            style="margin: 10px"
            v-if="action == 'update-subtask'"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-update</v-icon>Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog project information -->
    <v-dialog v-model="dialog_project" width="100%" max-width="500">
      <v-card>
        <v-card-title>Update Project</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Project Name"
                outlined
                v-model="update_project.name"
                name="project-name"
                v-validate="'required'"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('project-name')"
                  style="margin-top: -20px"
                  >{{ errors.first("project-name") }}</v-alert
                >
              </transition>
              <v-card-subtitle
                v-if="update_project.name != ''"
                style="margin-top: -40px"
                >{{ CreatePath(update_project.name) }}</v-card-subtitle
              >
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              style="margin-top: -30px"
            >
              <v-text-field
                label="Project Type"
                outlined
                v-model="update_project.type"
                name="project-type"
                v-validate="'required'"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('project-type')"
                  style="margin-top: -20px"
                  >{{ errors.first("project-type") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="6"
              lg="6"
              xl="6"
              style="margin-top: -30px"
            >
              <v-text-field
                label="TAG"
                outlined
                v-model="update_project.tag"
                name="tag"
              />
            </v-col>
            <v-col cols="12" style="margin-top: -30px">
              <v-text-field
                label="Customer"
                outlined
                v-model="update_project.customer"
                name="tag"
              />
            </v-col>
            <v-col cols="12" style="margin-top: -30px">
              <v-autocomplete
                v-model="update_project.member"
                :items="resultMember"
                outlined
                chips
                small-chips
                item-text="EmployeeName"
                item-value="EmployeeEmail"
                label="Member"
                multiple
              ></v-autocomplete>
            </v-col>
          </v-row>
          <transition name="slide-fade">
            <v-alert
              type="error"
              v-if="error != ''"
              style="margin-top: -30px"
              >{{ error }}</v-alert
            >
          </transition>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="success" @click="UpdateProject" style="margin: 10px">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Update
          </v-btn>
          <v-btn color="error" @click="DeleteProject" style="margin: 10px">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-delete</v-icon>Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="100%" scrollable v-model="dialog_planning">
      <!-- <Planning v-bind:member="member" @update-data="UpdateData" /> -->
      <v-card>
        <v-card-text class="planning-area">
          <Planning
            v-bind:member="member"
            v-bind:project="project"
            @update-data="UpdateData"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../config";
import moment from "moment";

import Responsible from "../components/Task/Responsible";
import Accountable from "../components/Task/Accountable";
import Planning from "../components/Task/Planning";
import Calendar from "../components/Task/Calendar";

export default {
  data() {
    return {
      IP: IP,
      user: "",
      listItems: [],
      listItemsResponsible: [],
      listItemsAccountable: [],
      listCheckin: [],
      loading: false,
      dialog: false,
      dialog_task: false,
      dialog_subtask: false,
      dialog_project: false,
      dialog_planning: false,
      task: {
        name: "",
        assign_to: [],
        accountable: [],
      },
      subtask: {},
      member: {},
      action: "",
      select_checkin: ["Doing", "Finish"],
      test: "",
      tab: null,
      tab_item: ["Accountable", "Responsible", "Calendar"],
      project: {
        member: [],
      },
      update_project: {
        name: "",
        type: "",
        tag: "",
        customer: "",
        path: "",
        member: [],
      },
      error: "",
      all_task: {},
    };
  },
  components: {
    Responsible,
    Accountable,
    Planning,
    Calendar,
  },
  computed: {
    resultMember() {
      const value = this.member;
      var array = [];
      for (var i = 0; i < value.length; i++) {
        if (value[i].EmployeeEmail != this.user.EmployeeEmail) {
          var obj = {
            EmployeeEmail: value[i].EmployeeEmail,
            EmployeeName: value[i].EmployeeName,
          };
          array.push(obj);
        }
      }
      return array;
    },
    resultAssign() {
      const value = this.member;
      var array = [];
      for (var i = 0; i < value.length; i++) {
        // if (value[i].EmployeeEmail != this.user.EmployeeEmail) {
        var obj = {
          email: value[i].EmployeeEmail,
          name: value[i].EmployeeName,
        };
        array.push(obj);
        // }
      }
      return array;
    },
    resultAccountable() {
      const value = this.member;
      var array = [];
      for (var i = 0; i < value.length; i++) {
        if (value[i].EmployeeEmail != this.user.EmployeeEmail) {
          var obj = {
            email: value[i].EmployeeEmail,
            name: value[i].EmployeeName,
          };
          array.push(obj);
        }
      }
      return array;
    },
    resultSubtaskOf() {
      let value_response = this.listItemsResponsible;
      let value = value_response.concat(this.listItemsAccountable);

      var array = [];
      for (var i = 0; i < value.length; i++) {
        if (
          value[i].SubtaskOf == null &&
          (value[i].TaskStatus != "Pending" || value[i].Role == "Accountable")
        ) {
          var obj = {
            id: value[i].ID,
            name: value[i].TaskName,
          };
          array.push(obj);
        }
      }
      return array;
    },
  },
  methods: {
    async AllGet() {
      await this.GetMember();
      await this.GetTaskResponsible();
      await this.GetTaskAccountable();
      await this.GetAllTaskByEmail();
    },
    async GetAllTaskByEmail() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/get-all-tasl-by-email",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.user.EmployeeEmail,
          },
        }).then((response) => {
          const data = response.data;
          const uniqueData = [
            ...new Map(data.map((item) => [item.ID, item])).values(),
          ];
          this.all_task = uniqueData;
          console.log(this.all_task);
          resolve(true);
        });
      });
    },
    async UpdateData() {
      console.log("update data!");
      this.dialog_planning = false;
      await this.AllGet();
    },
    async DeleteProject() {
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบโปรเจคหรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          axios({
            method: "post",
            url: IP + "/task/delete-project",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              id: this.project.ID,
            },
          }).then((response) => {
            if (response.data == "success") {
              Swal.fire("ลบโปรเจคเสร็จสิ้น !", "", "success");
              this.$router.push("/project");
            }
          });
        }
      });
    },
    async UpdateProject() {
      const results = Promise.all([
        this.$validator.validate("project-name"),
        this.$validator.validate("project-type"),
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        this.CheckProject(this.update_project.path).then((response) => {
          if (!response) {
            this.update_project.email = this.user.EmployeeEmail;
            axios({
              method: "post",
              url: IP + "/task/update-project",
              headers: {
                Authorization: AuthKey,
              },
              data: {
                project: this.update_project,
              },
            }).then((response) => {
              this.$router.push("/project");
            });
          } else {
            this.error = `Project name "${this.update_project.name}" has been already.`;
          }
        });
      }
    },
    CheckProject(path) {
      return new Promise(async (resolve, reject) => {
        if (path != this.project.ProjectPath) {
          axios({
            method: "post",
            url: IP + "/task/check-project",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              path: path,
            },
          }).then((response) => {
            const result = response.data;
            if (result.length > 0) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        } else {
          resolve(false);
        }
      });
    },
    async CreateTask() {
      const results = Promise.all([
        this.$validator.validate("name-task"),
        this.$validator.validate("assign"),
        // this.$validator.validate("accountable")
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        Swal.fire({
          title: "Are you sure ?",
          text: `คุณต้องการมอบหมายงาน ${this.task.name} ให้ ${this.task.assign} หรือไม่ !`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.value) {
            this.task.project_id = this.project.ID;
            this.task.create_by = this.user.EmployeeEmail;
            this.task.accountable.push(this.user.EmployeeEmail);
            this.task.type = "maintask";
            this.task.subtask_of = null;
            this.task.status = "Pending";
            axios({
              method: "post",
              url: IP + "/task/create-task",
              headers: {
                Authorization: AuthKey,
              },
              data: {
                task: this.task,
              },
            }).then(async (response) => {
              if (response.data == "success") {
                await this.Clear();
                await this.AllGet();
                Swal.fire("มอบหมายงานเสร็จสิ้น !", "", "success");
              }
            });
          }
        });
      }
    },
    async CreateSubtask() {
      const results = Promise.all([
        this.$validator.validate("create-name-subtask"),
        this.$validator.validate("create-subtask-of"),
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        var obj = {};
        this.listItems.forEach(function (val, i) {
          if (val.ID == this.subtask.subtask_of) {
            obj.maintask = val.TaskName;
            obj.assign_from = val.AssignFrom;
          }
        }, this);
        Swal.fire({
          title: "Are you sure ?",
          text: `คุณต้องการเพิ่มงาน ${this.subtask.name} ไปใน ${obj.maintask} หรือไม่ !`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.value) {
            this.subtask.project_id = this.project.ID;
            this.subtask.status = "Pending";
            this.subtask.create_by = this.user.EmployeeEmail;
            this.subtask.type = "subtask";
            axios({
              method: "post",
              url: IP + "/task/create-task",
              headers: {
                Authorization: AuthKey,
              },
              data: {
                task: this.subtask,
              },
            }).then(async (response) => {
              if (response.data == "success") {
                await this.Clear();
                await this.AllGet();
                Swal.fire("มอบหมายงานเสร็จสิ้น !", "", "success");
              }
            });
          }
        });
      }
    },
    Action(action, data) {
      if (action == "create-task") {
        this.task = {
          accountable: [],
        };
        this.dialog_task = true;
      } else if (action == "create-subtask") {
        this.subtask = {};
        this.dialog_subtask = true;
      } else if (action == "update-task") {
        // continue
      } else if (action == "update-subtask") {
        this.subtask = {
          id: data.ID,
          name: data.TaskName,
          subtask_of: data.SubtaskOf,
        };
        this.dialog_subtask = true;
      } else if (action == "checkin") {
        this.checkin = {
          id: data.ID,
          type: data.TaskType,
          subtask_of: data.SubtaskOf,
          status: "Doing",
        };
        this.dialog_checkin = true;
      } else if (action == "project-information") {
        this.update_project = {
          id: this.project.ID,
          name: this.project.ProjectName,
          type: this.project.ProjectType,
          tag: this.project.ProjectTag,
          path: this.project.ProjectPath,
          customer: this.project.ProjectCustomer,
          member: this.project.member,
        };
        this.dialog_project = true;
      }
      this.action = action;
    },
    remove(action, item) {
      if (action == "assign") {
        const index = this.task.assign_to.indexOf(item.email);
        if (index >= 0) this.task.assign_to.splice(index, 1);
      } else if (action == "accountable") {
        const index = this.task.accountable.indexOf(item.email);
        if (index >= 0) this.task.accountable.splice(index, 1);
      }
    },
    Clear() {
      this.dialog_task = false;
      this.dialog_subtask = false;
      this.dialog_checkin = false;
      this.dialog_project = false;
      this.task = {};
      this.subtask = {};
      this.checkin = {};
      this.update_project = {
        name: "",
      };
      this.action = "";
      this.$validator.reset();
    },
    GetTaskAccountable() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/get-task-accountable",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            project_id: this.project.ID,
            email: this.user.EmployeeEmail,
          },
        }).then((response) => {
          const data = response.data;
          this.listTask(data).then((response) => {
            this.listItemsAccountable = response;
            resolve(true);
          });
          resolve(true);
        });
      });
    },
    GetTaskResponsible() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/get-task-responsible",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            project_id: this.project.ID,
            email: this.user.EmployeeEmail,
          },
        }).then((response) => {
          const data = response.data;
          this.listTask(data).then((response) => {
            this.listItemsResponsible = response;
            resolve(true);
          });
          resolve(true);
        });
      });
    },
    listTask(value) {
      return new Promise(async (resolve, reject) => {
        var maintask = [];
        var subtask = [];
        for (var i = 0; i < value.length; i++) {
          if (value[i].SubtaskOf == null) {
            maintask.push(value[i]);
          } else {
            subtask.push(value[i]);
          }
        }
        for (var x = 0; x < maintask.length; x++) {
          maintask[x].subtask = [];
          for (var y = 0; y < subtask.length; y++) {
            if (subtask[y].SubtaskOf == maintask[x].ID) {
              maintask[x].TotalHours =
                maintask[x].TotalHours + subtask[y].TotalHours;
              maintask[x].subtask.push(subtask[y]);
            }
          }
        }
        resolve(maintask);
      });
    },
    GetMember() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "get",
          url: IP + "/task/get-member",
          headers: {
            Authorization: AuthKey,
          },
        }).then((response) => {
          this.member = response.data;
          console.log("raw member: ", this.member);
          resolve(true);
        });
      });
    },
    GetProjectInformation() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/project-information",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            path: this.$route.params.path,
            email: this.user.EmployeeEmail,
          },
        }).then((response) => {
          if (response.data != false) {
            this.project = response.data;
            this.project.member = [];
            this.GetProjectMember().then((response) => {
              console.log(response.data);
            });
            resolve(true);
          } else {
            this.$router.push("/project");
          }
        });
      });
    },
    GetProjectMember() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/get-project-member",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            id: this.project.ID,
          },
        }).then((response) => {
          const result = response.data;
          result.forEach(function (val, i) {
            this.project.member.push(val.EmployeeEmail);
          }, this);
          console.log("project: ", this.project);
        });
      });
    },
    CreatePath(name) {
      var replaced = name.split(" ").join("-");
      //   var lower = replaced.toLowerCase();
      this.update_project.path = replaced;
      return this.update_project.path;
    },
    async start() {
      this.loading = true;
      await this.GetProjectInformation().then(async (response) => {
        await this.AllGet();
        var that = this;
        setTimeout(function () {
          that.loading = false;
        }, 1000);
      }, this);
    },
  },
  created() {
    if (this.$route.params.path) {
      this.user = this.$store.getters.currentUser;
      this.start();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped lang="scss">
// @import "../css/transition.scss";

.main-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.header-text {
  font-size: 30px;
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}

.planning-area {
  width: 100%;
  height: 100%;
  // max-height: 100px;
  margin: 20px 20px 20px 0px;
}
</style>