<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-container>
      <div class="content-area">
        <v-row style="border-bottom: 1px solid rgb(216, 216, 216)">
          <v-col cols="auto" style="font-size: 30px">Add Exam to User</v-col>
          <v-col style="width: 100%; padding: 0px 20px 0px 20px"> </v-col>
          <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <v-btn
              color="primary"
              block
              style="float: right"
              v-if="selected"
              @click="Back"
            >
              <v-icon style="margin: 0px 5px 0px -5px">mdi-arrow-left</v-icon
              >Back
            </v-btn>
          </v-col>
        </v-row>
        <div class="select-card" v-if="!selected">
          <v-row>
            <v-col>
              <v-card
                class="card-style"
                id="card-style"
                @click="selected = 'applicant'"
              >
                Applicant
              </v-card>
            </v-col>
            <v-col>
              <v-card
                class="card-style"
                id="card-style"
                @click="selected = 'employee'"
              >
                Employee
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="select-card" v-if="selected == 'applicant'">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="applicant.exam"
                :items="examItems"
                item-text="ExamTitle"
                item-value="ID"
                outlined
                dense
                chips
                small-chips
                label="Exam"
                multiple
                name="exam"
                v-validate="'required'"
              ></v-autocomplete>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  style="margin-top: -20px"
                  dense
                  v-show="errors.has('exam')"
                  >{{ errors.first("exam") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" style="margin-top: -20px">
              <v-combobox
                v-model="applicant.email"
                label="Email"
                multiple
                outlined
                dense
                chips
                small-chips
                name="email"
                v-validate="'email|required'"
              ></v-combobox>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  style="margin-top: -20px"
                  dense
                  v-show="errors.has('email')"
                  >{{ errors.first("email") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" style="margin-top: -20px">
              <v-card-actions class="justify-center" @click="AddExamToUser">
                <v-btn color="success" width="200">Add</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </div>
        <div class="select-card" v-if="selected == 'employee'">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="employee.exam"
                :items="examItems"
                item-text="ExamTitle"
                item-value="ID"
                outlined
                dense
                chips
                small-chips
                label="Exam"
                multiple
                name="exam"
                v-validate="'required'"
              ></v-autocomplete>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  style="margin-top: -20px"
                  dense
                  v-show="errors.has('exam')"
                  >{{ errors.first("exam") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" style="margin-top: -20px">
              <v-autocomplete
                v-model="employee.email"
                :items="employeeItems"
                item-text="EmployeeName"
                item-value="EmployeeEmail"
                outlined
                dense
                chips
                small-chips
                label="Employee"
                multiple
                name="employee"
                v-validate="'required'"
              ></v-autocomplete>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  style="margin-top: -20px"
                  dense
                  v-show="errors.has('employee')"
                  >{{ errors.first("employee") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" style="margin-top: -20px">
              <v-card-actions class="justify-center" @click="AddExamToUser">
                <v-btn color="success" width="200">Add</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../../config";

export default {
  data() {
    return {
      user: "",
      loading: false,
      selected: false,
      employee: {},
      applicant: {},
      examItems: [],
      employeeItems: [],
    };
  },
  methods: {
    Back() {
      this.employee = {};
      this.applicant = {};
      this.selected = false;
    },
    AddExamToUser() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$swal
            .fire({
              title: "Are you sure ?",
              text: `Do you want to add exam to user?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            })
            .then(async (result) => {
              if (result.value) {
                this.loading = true;
                let obj = {
                  employee: this.employee,
                  applicant: this.applicant,
                  selected: this.selected,
                };
                console.log("add: ", obj);
                obj.create_by = this.user.EmployeeEmail;

                axios({
                  method: "POST",
                  url: `${IP}/exam/add-to-user`,
                  headers: {
                    Authorization: AuthKey,
                  },
                  data: {
                    obj: obj,
                  },
                }).then((response) => {
                  this.loading = false;
                  console.log("response: ", response.data);
                  this.$swal.fire("Add exam to user success !", "", "success");
                  this.Clear();
                });
              }
            });
        }
      });
    },
    Clear() {
      this.employee = {};
      this.applicant = {};
    },
    GetAllExam() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/exam/get-all`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            // console.log("examItems: ", response.data);
            this.examItems = response.data;
            resolve(true);
          });
      });
    },
    GetAllEmployeeInformation() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/user-management/get-all-employee`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            this.employeeItems = response.data;
            // console.log("employeeItems: ", this.employeeItems);
            resolve(true);
          });
      });
    },
    async Start() {
      this.user = this.$store.getters.currentUser;
      await this.GetAllExam().then(async (response) => {
        await this.GetAllEmployeeInformation();
      });
    },
  },
  created() {
    this.Start();
  },
};
</script>

<style lang="scss" scoped>
.content-area {
  width: 95%;
  margin: 0 auto;
}

.select-card {
  min-width: 300px;
  width: 60%;
  margin: 0 auto;
  margin-top: 50px;
}

.card-style {
  padding: 30px;
  font-size: 30px;
  text-align: center;
  cursor: pointer;
}

#card-style {
  transition: 0.3s all;
}

#card-style:hover {
  background: rgba(236, 236, 236, 0.616);
}
</style>