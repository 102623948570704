<template>
  <div>
    <v-expansion-panels v-model="panel" multiple :disabled="disabled">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <p style="margin-bottom: 9px">General Information</p>
            <p id="header-text" ref="header_text" @click="CopyHeader">
              {{ $route.params.so_number }} {{ detailItems.project_name }}
            </p>
            <v-icon small class="ml-1" style="margin-top: -30px"
              >mdi-content-copy</v-icon
            >
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="!loading">
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col
              cols="12"
              v-if="
                !(field_disabled.always && !old_data) &&
                specific.project_name &&
                !readonly
              "
            >
              <v-text-field
                dense
                outlined
                label="Project"
                v-model="detailItems.project_name"
                hide-details
                :readonly="readonly && !old_data"
                :disabled="
                  (field_disabled.always && !old_data) || !specific.project_name
                "
                :class="
                  (field_disabled.always && !old_data) || !specific.project_name || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-select
                :items="product_items"
                label="Main Product"
                outlined
                item-value="ProductName"
                item-text="ProductName"
                v-model="detailItems.main_project"
                dense
                hide-details
                :readonly="readonly"
                :disabled="field_disabled.condition || !specific.main_product"
                :class="
                  field_disabled.condition || !specific.main_product || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-select
                :items="so_type_items"
                label="SO Type"
                outlined
                item-value="id"
                item-text="name"
                v-model="detailItems.so_type"
                dense
                hide-details
                readonly
                :disabled="field_disabled.always"
                color="grey-lighten-5"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="2" md="2">
              <v-checkbox
                v-model="detailItems.boi_flag"
                style="margin: 5px 5px 0px 0px"
                label="B(BOI)"
                hide-details
                :readonly="readonly && !old_data"
                :disabled="
                  (field_disabled.always && !old_data) || !specific.boi_flag
                "
                :class="
                  (field_disabled.always && !old_data) || !specific.boi_flag || readonly
                    ? ''
                    : 'can-edit'
                "
                color="grey-lighten-5"
                @change="DataChange"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="2">
              <v-select
                :items="so_status_items"
                label="SO Status"
                outlined
                v-model="detailItems.so_status"
                item-text="name"
                item-value="name"
                dense
                hide-details
                readonly
                :disabled="field_disabled.always"
                color="grey-lighten-5"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="2">
              <v-select
                :items="attach_status_items"
                label="Attach Status"
                outlined
                v-model="detailItems.attach_status"
                item-text="name"
                item-value="name"
                dense
                hide-details
                readonly
                :disabled="field_disabled.always"
                color="grey-lighten-5"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Company"
                v-model="detailItems.company"
                hide-details
                :readonly="readonly && !old_data"
                :disabled="
                  (field_disabled.always && !old_data) || !specific.company
                "
                :class="
                  (field_disabled.always && !old_data) || !specific.company || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Customer"
                v-model="detailItems.customer"
                hide-details
                :readonly="readonly && !old_data"
                :disabled="
                  (field_disabled.always && !old_data) || !specific.customer
                "
                :class="
                  (field_disabled.always && !old_data) || !specific.customer || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="End User"
                v-model="detailItems.end_user"
                hide-details
                :readonly="readonly"
                :disabled="field_disabled.condition || !specific.end_user"
                :class="
                  field_disabled.condition || !specific.end_user || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-select
                :items="invoice_status_items"
                label="Invoice Status"
                outlined
                v-model="detailItems.invoice_status"
                item-text="name"
                item-value="name"
                dense
                hide-details
                readonly
                :disabled="field_disabled.always"
                color="grey-lighten-5"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-select
                :items="user_items"
                item-text="EmployeeName"
                item-value="EmployeeName"
                label="Created by"
                outlined
                v-model="detailItems.created_by"
                dense
                hide-details
                readonly
                :disabled="field_disabled.always"
                color="grey-lighten-5"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-select
                :items="user_items"
                item-text="EmployeeName"
                item-value="EmployeeName"
                label="Sales Name"
                outlined
                v-model="detailItems.sales_name"
                dense
                hide-details
                :readonly="readonly && !old_data"
                :disabled="
                  (field_disabled.always && !old_data) || !specific.sales_name
                "
                :class="
                  (field_disabled.always && !old_data) || !specific.sales_name || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-select
                :items="user_items"
                item-text="EmployeeName"
                item-value="EmployeeName"
                label="PM Name"
                outlined
                v-model="detailItems.pm_name"
                dense
                hide-details
                :readonly="readonly"
                :disabled="field_disabled.condition || !specific.pm_name"
                :class="
                  field_disabled.condition || !specific.pm_name || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-menu
                v-model="order_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="
                  readonly ||
                  (field_disabled.always && !old_data) ||
                  !specific.order_date
                "
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.order_date"
                    label="Order Date"
                    append-icon="event"
                    readonly
                    :disabled="
                      (field_disabled.always && !old_data) ||
                      !specific.order_date
                    "
                    :class="
                      (field_disabled.always && !old_data) ||
                      !specific.order_date || readonly
                        ? ''
                        : 'can-edit'
                    "
                    v-on="on"
                    name="order-date"
                    outlined
                    hide-details
                    dense
                    :color="color"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.order_date"
                  @input="order_date_menu = false"
                  @change="DataChange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-menu
                v-model="license_start_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.license_start_date"
                    label="License Start Date"
                    append-icon="event"
                    readonly
                    :disabled="
                      field_disabled.condition || !specific.license_start_date
                    "
                    :class="
                      field_disabled.condition || !specific.license_start_date || readonly
                        ? ''
                        : 'can-edit'
                    "
                    v-on="on"
                    name="order-date"
                    outlined
                    hide-details
                    dense
                    :color="color"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.license_start_date"
                  @input="license_start_date_menu = false"
                  @change="DataChange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-menu
                v-model="license_end_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.license_end_date"
                    label="License End Date"
                    append-icon="event"
                    readonly
                    :disabled="
                      field_disabled.condition || !specific.license_end_date
                    "
                    :class="
                      field_disabled.condition || !specific.license_end_date || readonly
                        ? ''
                        : 'can-edit'
                    "
                    v-on="on"
                    name="order-date"
                    outlined
                    hide-details
                    dense
                    :color="color"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.license_end_date"
                  @input="license_end_date_menu = false"
                  @change="DataChange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-text-field
                dense
                outlined
                label="Project Duration (Days)"
                v-model="detailItems.project_duration"
                hide-details
                type="number"
                :readonly="readonly"
                :disabled="
                  field_disabled.condition || !specific.project_duration
                "
                :class="
                  field_disabled.condition || !specific.project_duration || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-menu
                v-model="project_start_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.project_start_date"
                    label="Project Start Date"
                    append-icon="event"
                    readonly
                    :disabled="
                      field_disabled.condition || !specific.project_start_date
                    "
                    :class="
                      field_disabled.condition || !specific.project_start_date || readonly
                        ? ''
                        : 'can-edit'
                    "
                    v-on="on"
                    name="order-date"
                    outlined
                    hide-details
                    dense
                    :color="color"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.project_start_date"
                  @input="project_start_date_menu = false"
                  @change="DataChange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-menu
                v-model="project_close_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.project_close_date"
                    label="Project Close Date"
                    append-icon="event"
                    readonly
                    :disabled="
                      field_disabled.condition || !specific.project_close_date
                    "
                    :class="
                      field_disabled.condition || !specific.project_close_date || readonly
                        ? ''
                        : 'can-edit'
                    "
                    v-on="on"
                    name="order-date"
                    outlined
                    hide-details
                    dense
                    :color="color"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.project_close_date"
                  @input="project_close_date_menu = false"
                  @change="DataChange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-text-field
                dense
                outlined
                label="Warranty/MA Duration (Days)"
                v-model="detailItems.warranty_ma_duration"
                hide-details
                type="number"
                :readonly="readonly"
                :disabled="
                  field_disabled.condition || !specific.warranty_ma_duration
                "
                :class="
                  field_disabled.condition || !specific.warranty_ma_duration || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-menu
                v-model="warranty_ma_start_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.warranty_ma_start_date"
                    label="Warranly/MA Start Date"
                    append-icon="event"
                    readonly
                    :disabled="
                      field_disabled.condition ||
                      !specific.warranty_ma_start_date
                    "
                    :class="
                      field_disabled.condition ||
                      !specific.warranty_ma_start_date || readonly
                        ? ''
                        : 'can-edit'
                    "
                    v-on="on"
                    name="order-date"
                    outlined
                    hide-details
                    dense
                    :color="color"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.warranty_ma_start_date"
                  @input="warranty_ma_start_date_menu = false"
                  @change="DataChange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="4">
              <v-menu
                v-model="warranty_ma_close_date_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="readonly"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="detailItems.warranty_ma_close_date"
                    label="Warranly/MA Close Date"
                    append-icon="event"
                    readonly
                    :disabled="
                      field_disabled.condition ||
                      !specific.warranty_ma_close_date
                    "
                    :class="
                      field_disabled.condition ||
                      !specific.warranty_ma_close_date || readonly
                        ? ''
                        : 'can-edit'
                    "
                    v-on="on"
                    name="order-date"
                    outlined
                    hide-details
                    dense
                    :color="color"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="detailItems.warranty_ma_close_date"
                  @input="warranty_ma_close_date_menu = false"
                  @change="DataChange"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <v-select
                :items="sla_items"
                label="SLA"
                outlined
                v-model="detailItems.sla"
                dense
                hide-details
                :readonly="readonly"
                :disabled="field_disabled.condition || !specific.sla"
                :class="
                  field_disabled.condition || !specific.sla || readonly ? '' : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
              <v-textarea
                v-model="detailItems.sla_details"
                outlined
                dense
                hide-details
                rows="3"
                label="Details SLA"
                :readonly="readonly"
                :disabled="field_disabled.condition || !specific.sla_details"
                :class="
                  field_disabled.condition || !specific.sla_details || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
                style="margin-top: 15px"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <v-select
                :items="preventive_ma_items"
                label="Preventive MA"
                outlined
                v-model="detailItems.preventive_ma"
                dense
                hide-details
                :readonly="readonly"
                :disabled="field_disabled.condition || !specific.preventive_ma"
                :class="
                  field_disabled.condition || !specific.preventive_ma || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
              />
              <v-textarea
                v-model="detailItems.preventive_ma_details"
                outlined
                dense
                hide-details
                rows="3"
                label="Details Preventive MA"
                :readonly="readonly"
                :disabled="
                  field_disabled.condition || !specific.preventive_ma_details
                "
                :class="
                  field_disabled.condition || !specific.preventive_ma_details || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
                style="margin-top: 15px"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="detailItems.note"
                outlined
                dense
                hide-details
                rows="3"
                label="Note"
                readonly
                :disabled="field_disabled.always"
                :color="color"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="detailItems.comment"
                outlined
                dense
                hide-details
                rows="3"
                label="Comment"
                :readonly="readonly"
                :disabled="field_disabled.condition || !specific.comment"
                :class="
                  field_disabled.condition || !specific.comment || readonly
                    ? ''
                    : 'can-edit'
                "
                :color="color"
                @change="DataChange"
                style="margin-bottom: 10px"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import moment from "moment";
// json
import data_mapping_json from "../../json/sale_orders/general_information.json";
import status_items_json from "../../json/sale_orders/list/status_items.json";
import so_type_items_json from "../../json/sale_orders/list/so_type_items.json";

export default {
  data() {
    return {
      loading: false,
      readonly: this.ro,
      panel: [0],
      color: "",
      data: JSON.parse(JSON.stringify(this.dt)) || {},
      detailItems: {},
      so_type_items: so_type_items_json,
      so_status_items: status_items_json,
      attach_status_items: status_items_json,
      invoice_status_items: status_items_json,
      user_items: this.ep,
      product_items: this.pd,
      old_data: this.ot,
      sla_items: [
        "No SLA",
        "Standard 8 x 5, 4 Hrs, Response Time",
        "Standard 24 x 7, 4 Hrs, Response Time",
        "Other",
      ],
      preventive_ma_items: [
        "No Preventive MA",
        "2 Times a Year",
        "3 Times a Year",
        "4 Times a Year",
        "Other",
      ],
      order_date_menu: false,
      license_start_date_menu: false,
      license_end_date_menu: false,
      project_start_date_menu: false,
      project_close_date_menu: false,
      warranty_ma_start_date_menu: false,
      warranty_ma_close_date_menu: false,
      disabled: false,
      field_disabled: {
        always: false,
        condition: false,
      },
      specific: this.sp || {
        project_name: false,
        main_product: false,
        boi_flag: false,
        company: false,
        customer: false,
        end_user: false,
        sales_name: false,
        pm_name: false,
        order_date: false,
        license_start_date: false,
        license_end_date: false,
        project_duration: false,
        project_start_date: false,
        project_close_date: false,
        warranty_ma_duration: false,
        warranty_ma_start_date: false,
        warranty_ma_close_date: false,
        sla: false,
        sla_details: false,
        preventive_ma: false,
        preventive_ma_details: false,
        comment: false,
        can_edit: "",
      },
    };
  },
  props: ["ro", "dt", "ep", "pd", "page", "ot", "sp"],
  methods: {
    DataChange() {
      this.$emit("update-data", this.detailItems, "general_information");
    },
    PrepareData() {
      return new Promise(async (resolve, reject) => {
        let obj = {};
        for (let dt_json in data_mapping_json) {
          if (data_mapping_json[dt_json] != "") {
            if (dt_json.includes("date")) {
              if (this.data[data_mapping_json[dt_json]] != null) {
                obj[dt_json] = moment(
                  JSON.parse(
                    JSON.stringify(this.data[data_mapping_json[dt_json]])
                  )
                ).format("YYYY-MM-DD");
              } else {
                obj[dt_json] = null;
              }
            } else {
              if (this.data[data_mapping_json[dt_json]] != undefined) {
                obj[dt_json] = JSON.parse(
                  JSON.stringify(this.data[data_mapping_json[dt_json]])
                );
              } else {
                obj[dt_json] = null;
              }
            }
          }
        }
        if (obj.boi_flag != null) {
          obj.boi_flag = true;
        } else {
          obj.boi_flag = false;
        }
        resolve(obj);
      });
    },
    CopyHeader() {
      this.disabled = true;
      let text = this.$refs.header_text;
      text = text.innerHTML;
      navigator.clipboard.writeText(text);
      setTimeout(() => {
        this.disabled = false;
        alert("copied");
      }, 500);
    },
  },
  created() {
    this.loading = true;
    // console.log("data: ", this.data);
    if (this.ro) {
      this.color = "grey-lighten-5";
    }
    // console.log(this.page);
    if (this.page === "edit") {
      this.field_disabled.always = true;
      this.field_disabled.condition = this.readonly;
      // console.log("sp: ", this.sp);
      // console.log("specific: ", this.specific);
    } else if (this.page === "read") {
      this.specific = {
        project_name: true,
        main_product: true,
        boi_flag: true,
        company: true,
        customer: true,
        end_user: true,
        sales_name: true,
        pm_name: true,
        order_date: true,
        license_start_date: true,
        license_end_date: true,
        project_duration: true,
        project_start_date: true,
        project_close_date: true,
        warranty_ma_duration: true,
        warranty_ma_start_date: true,
        warranty_ma_close_date: true,
        sla: true,
        sla_details: true,
        preventive_ma: true,
        preventive_ma_details: true,
        comment: true,
      };
    }
    this.PrepareData().then((response) => {
      this.detailItems = response;
      // console.log('old data: ', this.old_data)
      // console.log('fields disable: ', this.field_disabled.always)
      // console.log('spcific: ', this.specific.order_date)
      // console.log('condition: ', (this.field_disabled.always && !this.old_data) || !this.specific.order_date)
      this.loading = false;
    });
  },
};
</script>

<style scoped>
#header-text {
  font-size: 24px;
  display: inline-block;
}

.can-edit.v-text-field--outlined >>> fieldset {
  border-color: #2196f3;
}

.can-edit.v-input--checkbox >>> .theme--light.v-icon {
  color: #2196f3;
}

@media (min-width: 0px) {
  .col.grid-5-custom {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .col.grid-5-custom {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media (min-width: 960px) {
  .col.grid-5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>