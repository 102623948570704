<template>
  <div class="main-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Internal Support Ticket</v-col>
      </v-row>
    </div>
    <br />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      style="max-width: 700px; margin: 0 auto; margin-bottom: 10px"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <p id="p-title">
            <b>Internal Ticket Title</b>
          </p>
          <v-text-field
            outlined
            dense
            v-model="create.title"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <p id="p-title">
            <b>Related Product</b>
          </p>
          <v-select
            :items="related_product"
            v-model="create.product"
            :rules="[rules.required]"
            item-text="ProductName"
            item-value="ProductName"
            label="Select an Option"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" class="py-0">
          <p id="p-title">
            <b>Priority</b>
          </p>
          <v-select
            :items="priority_items"
            v-model="create.priority"
            :rules="[rules.required]"
            label="Select an Option"
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col cols="12" class="py-0">
          <p id="p-title">
            <b>Category</b>
          </p>
          <v-select
            :items="category"
            item-text="category"
            item-value="category"
            v-model="create.category"
            :rules="[rules.required]"
            label="Select an Option"
            outlined
            dense
            v-on:change="ChangeCategory"
          ></v-select>
        </v-col>
        <v-col cols="12" class="py-0">
          <p id="p-title">
            <b>Summary</b>
          </p>
          <vue-editor
            id="editor"
            v-model="create.summary"
            :editorToolbar="customToolbar"
          ></vue-editor>
        </v-col>
        <v-col cols="12" class="py-0" v-if="error != ''">
          <v-alert
            border="left"
            type="error"
            icon="mdi-alert-circle"
            style="margin: 20px 0px 20px 0px"
            >{{ error }}</v-alert
          >
        </v-col>
        <v-col cols="12" style="margin-top: 20px">
          <hr style="opacity: 0.3" />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="CreateTicket">Submit</v-btn>
            <v-btn text color="error" @click="Clear">Clear</v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import { IP, AuthKey } from "../config";
import Swal from "sweetalert2";
import related_product from "../json/related_product.json";
import category from "../json/category.json";

export default {
  data() {
    return {
      related_product: related_product,
      category: category,
      loading: false,
      error: "",
      valid: true,
      rules: {
        required: (value) => !!value || "Required.",
      },
      create: {
        title: "",
        product: "",
        priority: "",
        category: "",
        status: "New",
        summary: "",
        request_by: "",
        request_department: "",
        employee_raised: "IT Support",
        assigned_to: "IT Support",
        group: "IT Support",
      },
      priority_items: ["Urgent", "High", "Medium", "Low"],
      product_items: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // ["link", "video"],
        ["clean"], // remove formatting button
      ],
    };
  },
  components: {
    VueEditor,
  },
  methods: {
    ChangeCategory(item) {
      for (var i = 0; i < this.category.length; i++) {
        if (item == this.category[i].category) {
          this.create.summary = this.category[i].default_summary;
        }
      }
    },
    CreateTicket() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.create.request_by = this.user.EmployeeEmail;
        this.create.request_department = this.user.Department;

        let create = JSON.parse(JSON.stringify(this.create));
        let check_summary = /^(<p> *<\/p>)$/.test(create.summary);
        if (create.summary != "" && !check_summary) {
          create.summary =
            `<p><b>Category</b>: ${create.category}</p>` + create.summary;

          Swal.fire({
            title: "Are you sure ?",
            text: "คุณต้องการบันทึกข้อมูลหรือไม่ ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.loading = true;
              this.error = "";
              axios({
                method: "POST",
                url: `${IP}/vtiger/create`,
                headers: {
                  Authorization: AuthKey,
                },
                data: {
                  create: create,
                },
              })
                .then((response) => {
                  setTimeout(() => {
                    if (response.data.success) {
                      // console.log("reseponse: ", response.data);
                      this.Clear();
                      Swal.fire("บันทึกข้อมูลเสร็จสิ้น", "", "success");
                    } else {
                      this.error = `[${response.data.error.code}] : ${response.data.error.message}`;
                    }
                    this.loading = false;
                  }, 1000);
                })
                .catch((error) => {
                  // console.log("error: ", error);
                  setTimeout(() => {
                    this.loading = false;
                    this.error = `[Error] : Something went wrong!`;
                  }, 1000);
                });
            }
          });
        } else {
          this.error = "Please fill some summary field.";
        }
      }
    },
    Clear() {
      this.create = {
        title: "",
        product: "",
        priority: "",
        category: "",
        status: "New",
        summary: "",
        request_by: "",
        request_department: "",
        employee_raised: "IT Support",
        assigned_to: "IT Support",
        group: "IT Support",
      };
      this.$refs.form.resetValidation();
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    // console.log("user: ", this.user);
    // this.GetProduct();
  },
};
</script>

<style scoped lang="scss">
@import "../css/transition.scss";

.main-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}

#p-title {
  margin-bottom: 4px;
}
</style>