<template>
  <div class="root-div-choose-room">
    <v-container>
      <v-row class="row-items" no-gutters>
        <div class="title">
          <h2>Room Reservation</h2>
        </div>
      </v-row>
      <v-row class="row-items" no-gutters>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          v-for="n in menu"
          :key="n.params"
        >
          <v-container class="container">
            <v-card
              :color="n.color"
              :dark="n.dark"
              class="room-card hvr-push transition"
              @click="goTo(n.link, n.params)"
            >
              <v-img class="white--text" height="200px" :src="n.img_url">
                <!-- <v-card-title> Australian beaches</v-card-title> -->
              </v-img>
              <v-container class="card-detail">
                <v-card-subtitle>{{ n.subtitle }}</v-card-subtitle>
                <v-card-title style="padding-bottom: 0" class="headline">{{
                  n.title[0]
                }}</v-card-title>
                <v-card-title style="padding-top: 0" class="headline"
                  >[{{ n.title[1] }}</v-card-title
                >
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
export default {
  name: "ChooseRoomReservation",
  data: () => ({
    menu: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "green",
      "orange",
      "grey darken-1",
    ],
  }),
  methods: {
    initialize() {
      this.getAllRoom();
    },
    getAllRoom() {
      const api_url = `${IP}/api/getAllRoom`;
      axios
        .get(api_url, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((response) => {
          for (const key in response.data) {
            const element = response.data[key];
            const data = {
              link: "room-reservation",
              img_url: `${IP}${element["img_url"]}`,
              title: element["room_name"].split("["),
              params: element["id"],
              subtitle: `Seat in this room ${element["seat"]}`,
              dark: true,
              color: this.randomColorHSL(),
            };
            this.menu.push(data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    goTo(to, params) {
      this.$router.push({ name: to, params: { roomID: params } });
    },
    randomColorHSL() {
      return `hsla(${~~(360 * Math.random())},40%,51%,0.9)`;
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    this.initialize();
  },
};
</script>

<style scoped lang="scss">
.area-card {
  transition: 0.5s all;
}

.area-card:hover {
  opacity: 0.8;
}

.container {
  margin-top: 30px;
  width: 100%;
}

.card-detail {
  padding-top: 0;
  margin-top: 0;
}

.row-items {
  justify-content: center;
}

.title {
  margin-top: 20px;
}

.root-div-choose-room {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*==== push ====*/
@keyframes hvr-push {
  70% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.hvr-push {
  backface-visibility: hidden;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  display: inline-block;
  transform: translateZ(0px);
  vertical-align: middle;
}
.hvr-push:hover,
.hvr-push:focus,
.hvr-push:active {
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-name: hvr-push;
  animation-timing-function: linear;
}
</style>