<template>
  <div class="comment">
    <v-container v-if="user != null">
      <v-row>
        <v-col cols="auto">
          <v-avatar color="grey" size="36" style="margin: 0px 0px 0px 10px">
            <span
              v-if="user.ProfileImage == null || user.ProfileImage == 'null'"
              style="color: white"
              >{{ short(user.EmployeeEmail) }}</span
            >
            <v-img
              v-if="user.ProfileImage != null && user.ProfileImage != 'null'"
              :src="`${IP}/profile_img/${user.ProfileImage}`"
              :lazy-src="`${IP}/profile_img/${user.ProfileImage}`"
            />
          </v-avatar>
        </v-col>
        <v-col>
          <div class="editor-box">
            <quill-editor
              style="margin: 0px 0px -15px 0px"
              v-model="comment.content"
              :options="editorOption"
            />
            <v-btn
              icon
              color="teal"
              @click="SendComment('main')"
              style="margin: 10px; margin-top: -10px; float: right"
            >
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div style="border-top: 1px solid rgb(238, 238, 238)"></div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          v-for="n in listComment"
          :key="n.ID"
          style="margin-top: -15px"
        >
          <v-row>
            <v-col cols="auto">
              <v-avatar color="grey" size="36" style="margin: 0px 0px 0px 10px">
                <span
                  v-if="n.ProfileImage == null || n.ProfileImage == 'null'"
                  style="color: white"
                  >{{ short(n.EmployeeEmail) }}</span
                >
                <v-img
                  v-if="n.ProfileImage != null && n.ProfileImage != 'null'"
                  :src="`${IP}/profile_img/${n.ProfileImage}`"
                  :lazy-src="`${IP}/profile_img/${n.ProfileImage}`"
                />
              </v-avatar>
            </v-col>
            <v-col>
              <div
                class="editor-box"
                style="padding: 10px; margin-bottom: -15px"
              >
                <div v-if="edit != n.ID">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-icon small style="float: right" v-on="on"
                        >mdi-dots-horizontal</v-icon
                      >
                    </template>
                    <v-list>
                      <v-list-item
                        @click="EditComment(n)"
                        v-if="n.EmployeeEmail == user.EmployeeEmail"
                      >
                        <v-list-item-title>Edit</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="DeleteComment(n)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <b>{{ n.EmployeeName }}</b>
                  <div class="content ql-editor" v-html="n.Content"></div>
                </div>
                <div v-if="edit == n.ID">
                  <quill-editor
                    style="margin: 0px 0px -15px 0px"
                    v-model="edit_comment.content"
                    :options="editorOption"
                  />
                  <v-btn
                    icon
                    color="teal"
                    @click="SendEditComment"
                    style="margin: 10px; margin-top: -10px; float: right"
                  >
                    <v-icon>mdi-send</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12" v-if="user != null">
              <div style="float: right; margin-top: -10px" v-if="reply != n.ID">
                <a @click="Reply(n.ID)">Reply</a>
              </div>
              <v-row v-if="reply == n.ID">
                <v-col cols="1"></v-col>
                <v-col cols="auto">
                  <v-avatar
                    color="grey"
                    size="36"
                    style="margin: 0px 0px 0px 10px"
                  >
                    <span
                      v-if="
                        user.ProfileImage == null || user.ProfileImage == 'null'
                      "
                      style="color: white"
                      >{{ short(user.EmployeeEmail) }}</span
                    >
                    <v-img
                      v-if="
                        user.ProfileImage != null && user.ProfileImage != 'null'
                      "
                      :src="`${IP}/profile_img/${user.ProfileImage}`"
                      :lazy-src="`${IP}/profile_img/${user.ProfileImage}`"
                    />
                  </v-avatar>
                </v-col>
                <v-col>
                  <div class="editor-box">
                    <quill-editor
                      style="margin: 0px 0px -15px 0px"
                      v-model="sub_comment.content"
                      :options="editorOption"
                    />
                    <v-btn
                      icon
                      color="teal"
                      @click="SendComment('sub', n)"
                      style="margin: 10px; margin-top: -10px; float: right"
                    >
                      <v-icon>mdi-send</v-icon>
                    </v-btn>
                  </div>
                  <div
                    style="
                      margin-top: 20px;
                      border-bottom: 1px solid rgb(238, 238, 238);
                    "
                  ></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="n.sub.length > 0">
            <v-col cols="12">
              <v-row v-for="s in n.sub" :key="s.ID">
                <v-col cols="1"></v-col>
                <v-col cols="11" style="margin-top: -15px">
                  <v-row>
                    <v-col cols="auto">
                      <v-avatar
                        color="grey"
                        size="36"
                        style="margin: 0px 0px 0px 10px"
                      >
                        <span
                          v-if="
                            s.ProfileImage == null || s.ProfileImage == 'null'
                          "
                          style="color: white"
                          >{{ short(s.EmployeeEmail) }}</span
                        >
                        <v-img
                          v-if="
                            s.ProfileImage != null && s.ProfileImage != 'null'
                          "
                          :src="`${IP}/profile_img/${s.ProfileImage}`"
                          :lazy-src="`${IP}/profile_img/${s.ProfileImage}`"
                        />
                      </v-avatar>
                    </v-col>
                    <v-col>
                      <div
                        class="editor-box"
                        style="padding: 10px; margin-bottom: -15px"
                      >
                        <div v-if="edit != s.ID">
                          <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                              <v-icon small style="float: right" v-on="on"
                                >mdi-dots-horizontal</v-icon
                              >
                            </template>
                            <v-list>
                              <v-list-item @click="EditComment(s)">
                                <v-list-item-title>Edit</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="DeleteComment(s)">
                                <v-list-item-title>Delete</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <b>{{ s.EmployeeName }}</b>
                          <div
                            class="content ql-editor"
                            v-html="s.Content"
                          ></div>
                        </div>
                        <div v-if="edit == s.ID">
                          <quill-editor
                            style="margin: 0px 0px -15px 0px"
                            v-model="edit_comment.content"
                            :options="editorOption"
                          />
                          <v-btn
                            icon
                            color="teal"
                            @click="SendEditComment"
                            style="
                              margin: 10px;
                              margin-top: -10px;
                              float: right;
                            "
                          >
                            <v-icon>mdi-send</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div style="border-bottom: 1px solid rgb(238, 238, 238)"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { IP, AuthKey } from "../../config";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import axios from "axios";

export default {
  data() {
    return {
      IP: IP,
      user: null,
      editorOption: {
        placeholder: "Comment here ...",
        theme: "bubble",
        modules: {
          toolbar: false,
        },
      },
      comment: {
        content: "",
      },
      edit_comment: {
        content: "",
      },
      sub_comment: {
        content: "",
      },
      listComment: [],
      reply: 0,
      edit: 0,
    };
  },
  props: ["BlogID"],
  components: {
    quillEditor,
  },
  methods: {
    Reply(id) {
      this.reply = id;
    },
    EditComment(item) {
      console.log("edit: ", item);
      this.edit_comment.content = item.Content;
      this.edit_comment.id = item.ID;
      this.edit = item.ID;
    },
    DeleteComment(item) {
      axios({
        method: "post",
        url: `${IP}/news/delete-comment`,
        headers: {
          Authorization: AuthKey,
        },
        data: {
          id: item.ID,
          type: item.CommentType,
        },
      }).then(async (response) => {
        if (response.data == "success") {
          await this.GetCommentByBlogID();
          this.Clear();
        }
      });
    },
    short(email) {
      var res = email.substr(0, 1);
      var result = res.charAt(0).toUpperCase();
      return result;
    },
    SendComment(type, sub_of) {
      if (this.comment.content != "" || this.sub_comment.content != "") {
        let comment = {};
        if (type == "main") {
          comment = this.comment;
          comment.sub_of = null;
        } else if (type == "sub") {
          comment = this.sub_comment;
          comment.sub_of = sub_of.ID;
        }

        comment.type = type;
        comment.email = this.user.EmployeeEmail;
        comment.blog_id = this.BlogID;

        axios({
          method: "post",
          url: `${IP}/news/create-comment`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            comment: comment,
          },
        }).then(async (response) => {
          if (response.data == "success") {
            await this.GetCommentByBlogID();
            this.Clear();
          }
        });
      }
    },
    SendEditComment() {
      if (this.edit_comment.content != "") {
        axios({
          method: "post",
          url: `${IP}/news/update-comment`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            comment: this.edit_comment,
          },
        }).then(async (response) => {
          if (response.data == "success") {
            await this.GetCommentByBlogID();
            this.Clear();
          }
        });
      } else {
        this.edit = 0;
      }
    },
    GetCommentByBlogID() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/news/get-comment-by-news-id`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            id: this.BlogID,
          },
        }).then((response) => {
          const result = response.data;
          let main = [];
          let sub = [];
          for (var i = 0; i < result.length; i++) {
            if (result[i].CommentType == "main") {
              result[i].sub = [];
              result[i].edit = false;
              main.push(result[i]);
            } else {
              result[i].edit = false;
              sub.push(result[i]);
            }
          }
          for (var x = 0; x < main.length; x++) {
            for (var y = 0; y < sub.length; y++) {
              if (main[x].ID == sub[y].SubOf) {
                main[x].sub.push(sub[y]);
              }
            }
          }
          this.listComment = main;
          resolve(true);
        });
      });
    },
    Clear() {
      this.reply = 0;
      this.edit = 0;
      this.comment = {
        content: "",
      };
      this.sub_comment = {
        content: "",
      };
    },
  },
  async created() {
    if (this.$store.getters.currentUser) {
      this.user = this.$store.getters.currentUser;
      await this.GetCommentByBlogID();
    }
  },
};
</script>

<style scoped lang="scss">
.comment {
  padding: 20px;
}

.editor-box {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

.ql-picker-label {
  font-size: 24px;
}
</style>