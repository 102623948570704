/*local*/
// export const CLIENDID = "2a8a31ff-2178-49fc-a026-be6f400eed5e"
// export const IP = 'http://localhost:8080'
// export const webURL = 'http://localhost:8081'
// export const apiURL = 'http://localhost:8080'
// export const AuthKey = 'f380082f-8d4d-4192-b71d-e763f75add5c'

/*azure test*/
// export const IP = 'https://stelligenceinternalapi.azurewebsites.net'
// export const webURL = 'https://stelligenceinternalnode.azurewebsites.net'
// export const apiURL = 'https://stelligenceinternalapi.azurewebsites.net'
// export const CLIENDID = "2a8a31ff-2178-49fc-a026-be6f400eed5e"
// export const AuthKey = 'f380082f-8d4d-4192-b71d-e763f75add5c'

/*azure production*/
export const IP = 'https://ourstelligenceapi.azurewebsites.net'
export const webURL = 'https://ourstelligence.azurewebsites.net'
export const apiURL = 'https://ourstelligenceapi.azurewebsites.net'
export const CLIENDID = "2a8a31ff-2178-49fc-a026-be6f400eed5e"
export const AuthKey = 'f380082f-8d4d-4192-b71d-e763f75add5c'