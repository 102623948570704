<template>
  <div class="main-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <div
      style="border-bottom: 1px solid rgb(216, 216, 216); margin-bottom: 20px"
    >
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Sale Order</v-col>
        <v-col
          cols="12"
          xs="12"
          sm="auto"
          md="auto"
          v-if="permission.file_table.value"
        >
          <v-btn color="primary" block @click="FilesDialog" :disabled="loading">
            <v-icon small class="mr-2">mdi-file</v-icon> Files
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn
            color="success"
            block
            @click="EditSaleOrders"
            :disabled="loading || save_loading"
            :loading="save_loading"
          >
            <v-icon small class="mr-2">mdi-content-save</v-icon>
            SAVE
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn color="success" block @click="initialize" :disabled="loading">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- content area -->
    <AlertMessage :data="message" :key="AlertMessageKey" />
    <!-- <div v-if="loading && !ready" class="loading-content">
      <p>Loading ...</p>
    </div> -->
    <div>
      <GeneralInformation
        :ro="false"
        :dt="data"
        :ep="EmployeeListItems"
        :pd="ProductItems"
        :ot="old_data"
        :key="GeneralInformationKey"
        :sp="permission.general_information.specific"
        page="edit"
        @update-data="UpdateData"
        v-if="permission.general_information.value"
      />
      <br />
      <FinancialInformation
        :ro="true"
        :dt="data"
        :key="FinancialInformationKey"
        v-if="permission.financial_information.value"
      />
      <br />
      <!-- <ProjectManagement :ro="false" :dt="data" :key="ProjectManagementKey" />
      <br />
      <CustomerInformation
        :ro="false"
        :dt="data"
        :key="CustomerInformationKey"
      />
      <br />
      <RelatedPersonInformation
        :ro="false"
        :dt="data"
        :key="RelatedPersonInformationKey"
      />
      <br /> -->
    </div>
    <v-divider></v-divider>
    <!-- dialog -->
    <FileTable
      :filesDialog="filesDialog"
      :so="$route.params.so_number"
      :key="filesDialogKey"
      @close="fileDialogUpdate"
      v-if="permission.file_table.value"
    />
  </div>
</template>

<script>
import moment from "moment";
import { TpAxios } from "../../helpers/tp_axios";
import { Others } from "../../function/Others";
import { Permissions } from "../../function/Permissions";
// components
import GeneralInformation from "../../components/SaleOrder/GeneralInformation.vue";
import FinancialInformation from "../../components/SaleOrder/FinancialInformation.vue";
import ProjectManagement from "../../components/SaleOrder/ProjectManagement.vue";
import CustomerInformation from "../../components/SaleOrder/CustomerInformation.vue";
import RelatedPersonInformation from "../../components/SaleOrder/RelatedPersonInformation.vue";
import FileTable from "../../components/SaleOrder/FileTable.vue";
// json
import general_information from "../../json/sale_orders/general_information.json";
import financial_information from "../../json/sale_orders/financial_information.json";
import project_management from "../../json/sale_orders/project_management.json";
import customer_information from "../../json/sale_orders/customer_information.json";
import related_person_information from "../../json/sale_orders/related_person_information.json";

export default {
  data() {
    return {
      old_data_condition: "2023-05-01",
      old_data: false,
      json_mapping: {
        general_information: general_information,
        financial_information: financial_information,
        project_management: project_management,
        customer_information: customer_information,
        related_person_information: related_person_information,
      },
      user: null,
      ready: false,
      loading: false,
      save_loading: false,
      raw_data: null,
      data: null,
      EmployeeListItems: [],
      ProductItems: [],
      message: [],
      filesDialog: false,
      AlertMessageKey: null,
      filesDialogKey: null,
      // components key
      GeneralInformationKey: null,
      FinancialInformationKey: null,
      ProjectManagementKey: null,
      CustomerInformationKey: null,
      RelatedPersonInformationKey: null,
      // permissions
      user_permission: null,
      permission: {
        general_information: {
          id: [4],
          value: false,
          specific: {
            project_name: false,
            main_product: false,
            boi_flag: false,
            company: false,
            customer: false,
            end_user: false,
            sales_name: false,
            pm_name: false,
            order_date: false,
            license_start_date: false,
            license_end_date: false,
            project_duration: false,
            project_start_date: false,
            project_close_date: false,
            warranty_ma_duration: false,
            warranty_ma_start_date: false,
            warranty_ma_close_date: false,
            sla: false,
            sla_details: false,
            preventive_ma: false,
            preventive_ma_details: false,
            comment: false,
            note: false
          }
        },
        financial_information: {
          id: [5],
          value: false,
        },
        file_table: {
          id: [6, 7, 8, 9],
          value: false,
        },
      },
    };
  },
  components: {
    GeneralInformation,
    FinancialInformation,
    ProjectManagement,
    CustomerInformation,
    RelatedPersonInformation,
    FileTable,
  },
  methods: {
    UpdateData(data, components) {
      for (let new_obj in this.json_mapping[components]) {
        let mapping = this.json_mapping[components][new_obj];
        this.data[mapping] = data[new_obj];
      }
    },
    EditSaleOrders() {
      this.message = [];
      this.save_loading = true;
      this.data.SONumber = this.data.SONumber[0];
      this.data.UpdateBy = this.user.EmployeeName;
      // console.log("data: ", this.data);
      TpAxios.Post(`/sale-orders/edit/${this.$route.params.so_number}`, {
        data: this.data,
      }).then((response) => {
        this.loading = false;
        this.save_loading = false;
        if (response.message != undefined) {
          this.AlertMessage("error", response.message);
        } else {
          this.message = [];
          this.initialize();
        }
      });
    },
    GetAllProductType() {
      return new Promise(async (resolve, reject) => {
        this.ProductItems = [];
        TpAxios.Get(`/GetProductInfomation`).then((response) => {
          this.ProductItems = response;
          resolve(true);
        });
      });
    },
    GetAllEmployee() {
      return new Promise(async (resolve, reject) => {
        this.EmployeeListItems = [];
        TpAxios.Get(`/employee-select-all`).then((response) => {
          this.EmployeeListItems = response.recordset;
          resolve(true);
        });
      });
    },
    GetSaleOrdersBySOID() {
      return new Promise(async (resolve, reject) => {
        TpAxios.Get(`/sale-orders/get/${this.$route.params.so_number}`).then(
          (response) => {
            if (response.message != undefined) {
              this.AlertMessage("error", response.message);
            } else {
              // console.log("data: ", response.data);
              this.raw_data = JSON.parse(JSON.stringify(response.data));
              this.data = JSON.parse(JSON.stringify(response.data));
            }
            resolve(true);
          }
        );
      });
    },
    async FilesDialog() {
      this.filesDialogKey = await Others.GenerateKeyID(10);
      this.filesDialog = true;
    },
    fileDialogUpdate(e) {
      this.filesDialog = e;
    },
    AlertMessage(type, text) {
      this.message.push({
        type: type,
        text: text,
      });
      this.AlertMessageKey = Others.GenerateKeyID(10);
    },
    async SetComponentsKey() {
      this.GeneralInformationKey = await Others.GenerateKeyID(10);
      this.FinancialInformationKey = await Others.GenerateKeyID(10);
      this.ProjectManagementKey = await Others.GenerateKeyID(10);
      this.CustomerInformationKey = await Others.GenerateKeyID(10);
      this.RelatedPersonInformationKey = await Others.GenerateKeyID(10);
      return true;
    },
    async SetLoading(state) {
      this.loading = state;
      // this.save_loading = state;
      this.ready = !this.loading;
    },
    async initialize() {
      this.SetLoading(true);
      this.message = []
      await this.GetAllEmployee();
      await this.GetAllProductType();
      await this.GetSaleOrdersBySOID();
      // console.log('so date: ', this.data.SODate)
      if (this.data.SODate != null && this.data.SODate != undefined) {
        let so_date = moment(this.data.SODate).unix();
        let old_data_condition_date = moment(this.old_data_condition).unix();
        if (so_date < old_data_condition_date) {
          this.old_data = true;
          this.AlertMessage(
            "info",
            `This data is before ${moment(this.old_data_condition).format(
              "MMMM Do YYYY"
            )}, you able to change more information.`
          );
        }
      }
      await this.SetComponentsKey();
      this.SetLoading(false);
    },
  },
  async created() {
    this.user = this.$store.getters.currentUser;
    this.user_permission = await Permissions.GetRoleAndPermissions(
      "SaleOrders"
    );
    this.permission = await Permissions.SetAccess(
      this.user_permission,
      this.permission
    );
    if (this.permission.general_information.value) {
      // console.log('user permission: ', this.user_permission)
      // console.log('permission: ', this.permission)
      await this.initialize();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.main-area {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 50px;
}

.loading-content {
  padding: 30px;
  font-size: 18px;
  text-align: center;
}
</style>