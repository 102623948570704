<template>
  <div class="blog">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Blogs</v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn color="primary" block @click="goTo('create-blog')"
            >Create blog</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div style="margin-top: 10px">
      <v-row>
        <v-col cols="12" xs="12" sm="8" md="9" lg="10" xl="10">
          <v-text-field
            v-model="search"
            label="Search"
            append-icon="mdi-magnify"
            single-line
            hide-details
            style="margin-top: 10px"
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <v-select
            :items="filterType"
            label="Filter"
            outlined
            v-model="filter"
            @change="SetDataNews"
            dense
            style="margin-top: 15px"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <div class="list-blog">
      <v-row>
        <v-col
          class="d-flex flex-column"
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          xl="3"
          v-for="n in resultQuery"
          :key="n.NewsID"
        >
          <v-hover v-slot:default="{ hover }" open-delay="100">
            <v-card
              id="card-show"
              class="elevation-5 flex flex-column"
              :elevation="hover ? 21 : 3"
            >
              <v-list-item class="v-list-item-title">
                <v-list-item-avatar
                  color="grey"
                  @click="goToProfile(n.NewsCreatedBy)"
                  style="cursor: pointer"
                >
                  <span
                    v-if="n.ProfileImage == null || n.ProfileImage == 'null'"
                    style="color: white"
                    >{{ short(n.NewsCreatedBy) }}</span
                  >
                  <v-img
                    v-if="n.ProfileImage != null && n.ProfileImage != 'null'"
                    :src="`${IP}/profile_img/${n.ProfileImage}`"
                    :lazy-src="`${IP}/profile_img/${n.ProfileImage}`"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline">{{
                    Decode(n.NewsTitle)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    n.EmployeeName
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-img
                :src="`${IP}/news_file/${n.NewsBackgroundImage}`"
                max-height="200"
              ></v-img>

              <v-card-text class="flex">
                <div class="ma-2" id="description-area">
                  {{ Decode(n.NewsDescription) }}
                </div>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p style="margin-top: 10px">
                      {{ Others.ConvertDate(n.NewsCreatedDate) }}
                    </p>
                    <br />
                    <p style="margin-top: -30px">
                      <v-icon small style="margin-right: 5px">mdi-eye</v-icon>
                      {{ n.Views }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    style="margin-top: -20px; margin-bottom: 30px"
                  >
                    <v-chip
                      class="ma-2"
                      small
                      color="indigo"
                      text-color="white"
                      v-for="t in n.NewsTag"
                      :key="t"
                      >{{ t }}</v-chip
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="card-actions">
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      color="red"
                      @click="deleteBlog(n)"
                      v-if="user.EmployeeEmail == n.NewsCreatedBy"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Blog</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      color="primary"
                      @click="updateBlog(n.NewsPath)"
                      v-if="user.EmployeeEmail == n.NewsCreatedBy"
                    >
                      <v-icon>mdi-pen</v-icon>
                    </v-btn>
                  </template>
                  <span>Update Blog</span>
                </v-tooltip>
                <v-btn
                  text
                  color="deep-purple"
                  style="border-radius: 20px"
                  @click="readBlog(n.NewsPath)"
                >
                  <v-icon style="margin-right: 10px"
                    >mdi-book-open-variant</v-icon
                  >Read
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="message_active == true"
      :style="`text-align: center; font-size: 25px; margin-top: ${
        this.height / 4
      }px;`"
    >
      <p>{{ message.description }}</p>
      <p style="font-size: 15px; color: gray" v-if="message.code != ''">
        <b>CODE :</b>
        {{ message.code }}
        <br />
        <b>Message :</b>
        {{ message.message }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../config";
import { WindowResize } from "../function/WindowResize.js";
import { Others } from "../function/Others";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      Others: Others,
      filter: "All",
      filterType: ["All", "Official"],
      IP: IP,
      news_data: "",
      news_show: [],
      message_active: false,
      message: {
        description: "",
        code: "",
        message: "",
      },
      width: 0,
      height: 0,
      search: "",
      loading: true,
    };
  },
  computed: {
    resultQuery() {
      const value = this.search.toLowerCase();
      return this.news_show.filter(function (val) {
        var title = this.Decode(val.NewsTitle);
        var tag = JSON.stringify(val.NewsTag);
        return (
          title.toLowerCase().indexOf(value) > -1 ||
          val.NewsCreatedBy.toLowerCase().indexOf(value) > -1 ||
          tag.toLowerCase().indexOf(value) > -1 ||
          val.EmployeeName.toLowerCase().indexOf(value) > -1
        );
      }, this);
    },
  },
  methods: {
    FilterType() {
      console.log("filter: ", this.filter);
      console.log(this.news_show);
    },
    short(email) {
      var res = email.substr(0, 1);
      var result = res.charAt(0).toUpperCase();
      return result;
    },
    goTo(to) {
      this.$router.push({ name: to });
    },
    goToProfile(email) {
      this.$router.push({
        name: "profile",
        params: {
          email: email,
        },
      });
    },
    readBlog(id) {
      this.$router.push({
        name: "read-blog",
        params: {
          id: id,
        },
      });
    },
    updateBlog(id) {
      this.$router.push({
        name: "update-blog",
        params: {
          id: id,
        },
      });
    },
    findAllImgName(str) {
      var regex = /<img [^>]*src="[^"]*"[^>]*>/gm,
        matches = regex.exec(str),
        array = [];
      while (matches != null) {
        var src_img = matches.map((x) => x.replace(/.*src="([^"]*)".*/, "$1"));
        var str_src_img = src_img[0].toString();
        if (str_src_img.startsWith("data") != true) {
          array.push(str_src_img.split("news_file/")[1]);
          // console.log("Img",src_img.split("news_file/")[1])
        }
        matches = regex.exec(str);
      }
      return array;
    },
    deleteBlog(data) {
      return new Promise(async (resolve, reject) => {
        Swal.fire({
          title: "คุณต้องการดำเนินการต่อหรือไม่ ?",
          text: `คุณต้องการลบเนื้อหาหรือไม่ !`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.value) {
            var content_array = [];
            axios({
              method: "post",
              url: `${IP}/select-news-content-by-id`,
              headers: {
                Authorization: AuthKey,
              },
              data: {
                id: data.NewsID,
              },
            }).then(async (response) => {
              if (response.data.length > 0) {
                response.data.forEach(function (val, i) {
                  content_array.push(val.ContentLocation);
                }, this);
              }
              var contentStr = this.Decode(data.NewsContent);
              var arrayImgDelete = this.findAllImgName(contentStr);
              axios({
                method: "post",
                url: `${IP}/delete-news`,
                headers: {
                  Authorization: AuthKey,
                },
                data: {
                  id: data.NewsID,
                  content_array: content_array,
                  arrayImgDelete: arrayImgDelete,
                  title_img: data.NewsBackgroundImage,
                },
              }).then((response) => {
                if (response.data == "success") {
                  // location.reload();
                  this.getNews();
                  Swal.fire(
                    "ลบบทความเสร็จสิ้น !",
                    `ลบบทความเสร็จสิ้น !`,
                    "success"
                  );
                }
              }, this);
            }, this);
          }
        });
      });
    },
    getNews() {
      axios({
        method: "get",
        url: IP + "/select-news",
        headers: {
          Authorization: AuthKey,
        },
      }).then(async (response) => {
        // console.log("res: ", response.data);
        if (response.data.code) {
          this.message_active = true;
          this.message.description = "Something wrong !";
          this.message.code = response.data.code;
          this.message.message = response.data.originalError.message;
        } else {
          this.message_active = false;
          this.message = "";
          this.news_show = [];
          this.news_data = response.data;
          // console.log("Data=",this.news_data);
          await this.SetDataNews();
          // console.log("Data=", this.news_show);
        }
        this.loading = false;
      });
    },
    SetDataNews() {
      this.news_show = [];
      this.news_data.forEach(function (val, i) {
        try {
          val.NewsTag = JSON.parse(val.NewsTag);
        } catch (err) {
          console.log("is json!");
        }
        if (this.filter == "All") {
          if (val.PublishStatus == "Draft") {
            if (this.user.EmployeeEmail == val.NewsCreatedBy) {
              this.news_show.push(val);
            }
          } else {
            this.news_show.push(val);
          }
        } else if (this.filter == "Official") {
          if (val.Official == true) {
            if (val.PublishStatus == "Draft") {
              if (this.user.EmployeeEmail == val.NewsCreatedBy) {
                this.news_show.push(val);
              }
            } else {
              this.news_show.push(val);
            }
          }
        }
      }, this);
    },
    Decode(data) {
      var result = "";
      if (data != undefined) {
        result = Buffer.from(data, "base64").toString();
      }
      return result;
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    start() {
      this.resize();
      this.user = this.$store.getters.currentUser;
      this.getNews();
    },
  },
  created() {
    this.start();
  },
};
</script>

<style scoped>
.blog {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 60px;
}

#description-area {
  text-indent: 20px;
  max-width: 100%;
  height: 50px;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-actions {
  width: 100%;
  bottom: 0;
  position: absolute;
}

#card-show {
  border-radius: 15px 15px 15px 15px;
  -moz-border-radius: 15px 15px 15px 15px;
  -webkit-border-radius: 15px 15px 15px 15px;
  border: 1px solid #d9d9d9;
  height: auto;
}

.v-list-item-title {
  max-height: max-content;
}

.list-blog {
  align-items: stretch;
}
</style>