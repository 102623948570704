var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-expansion-panels',{attrs:{"multiple":"","disabled":_vm.disabled},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',[_c('p',{staticStyle:{"margin-bottom":"9px"}},[_vm._v("General Information")]),_c('p',{ref:"header_text",attrs:{"id":"header-text"},on:{"click":_vm.CopyHeader}},[_vm._v(" "+_vm._s(_vm.$route.params.so_number)+" "+_vm._s(_vm.detailItems.project_name)+" ")]),_c('v-icon',{staticClass:"ml-1",staticStyle:{"margin-top":"-30px"},attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]),(!_vm.loading)?_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),(
              !(_vm.field_disabled.always && !_vm.old_data) &&
              _vm.specific.project_name &&
              !_vm.readonly
            )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{class:(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.project_name || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"dense":"","outlined":"","label":"Project","hide-details":"","readonly":_vm.readonly && !_vm.old_data,"disabled":(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.project_name,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.project_name),callback:function ($$v) {_vm.$set(_vm.detailItems, "project_name", $$v)},expression:"detailItems.project_name"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3"}},[_c('v-select',{class:_vm.field_disabled.condition || !_vm.specific.main_product || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"items":_vm.product_items,"label":"Main Product","outlined":"","item-value":"ProductName","item-text":"ProductName","dense":"","hide-details":"","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.main_product,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.main_project),callback:function ($$v) {_vm.$set(_vm.detailItems, "main_project", $$v)},expression:"detailItems.main_project"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":_vm.so_type_items,"label":"SO Type","outlined":"","item-value":"id","item-text":"name","dense":"","hide-details":"","readonly":"","disabled":_vm.field_disabled.always,"color":"grey-lighten-5"},model:{value:(_vm.detailItems.so_type),callback:function ($$v) {_vm.$set(_vm.detailItems, "so_type", $$v)},expression:"detailItems.so_type"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"2","md":"2"}},[_c('v-checkbox',{class:(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.boi_flag || _vm.readonly
                  ? ''
                  : 'can-edit',staticStyle:{"margin":"5px 5px 0px 0px"},attrs:{"label":"B(BOI)","hide-details":"","readonly":_vm.readonly && !_vm.old_data,"disabled":(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.boi_flag,"color":"grey-lighten-5"},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.boi_flag),callback:function ($$v) {_vm.$set(_vm.detailItems, "boi_flag", $$v)},expression:"detailItems.boi_flag"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"5","md":"2"}},[_c('v-select',{attrs:{"items":_vm.so_status_items,"label":"SO Status","outlined":"","item-text":"name","item-value":"name","dense":"","hide-details":"","readonly":"","disabled":_vm.field_disabled.always,"color":"grey-lighten-5"},model:{value:(_vm.detailItems.so_status),callback:function ($$v) {_vm.$set(_vm.detailItems, "so_status", $$v)},expression:"detailItems.so_status"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"5","md":"2"}},[_c('v-select',{attrs:{"items":_vm.attach_status_items,"label":"Attach Status","outlined":"","item-text":"name","item-value":"name","dense":"","hide-details":"","readonly":"","disabled":_vm.field_disabled.always,"color":"grey-lighten-5"},model:{value:(_vm.detailItems.attach_status),callback:function ($$v) {_vm.$set(_vm.detailItems, "attach_status", $$v)},expression:"detailItems.attach_status"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3"}},[_c('v-text-field',{class:(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.company || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"dense":"","outlined":"","label":"Company","hide-details":"","readonly":_vm.readonly && !_vm.old_data,"disabled":(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.company,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.company),callback:function ($$v) {_vm.$set(_vm.detailItems, "company", $$v)},expression:"detailItems.company"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3"}},[_c('v-text-field',{class:(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.customer || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"dense":"","outlined":"","label":"Customer","hide-details":"","readonly":_vm.readonly && !_vm.old_data,"disabled":(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.customer,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.customer),callback:function ($$v) {_vm.$set(_vm.detailItems, "customer", $$v)},expression:"detailItems.customer"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3"}},[_c('v-text-field',{class:_vm.field_disabled.condition || !_vm.specific.end_user || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"dense":"","outlined":"","label":"End User","hide-details":"","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.end_user,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.end_user),callback:function ($$v) {_vm.$set(_vm.detailItems, "end_user", $$v)},expression:"detailItems.end_user"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":_vm.invoice_status_items,"label":"Invoice Status","outlined":"","item-text":"name","item-value":"name","dense":"","hide-details":"","readonly":"","disabled":_vm.field_disabled.always,"color":"grey-lighten-5"},model:{value:(_vm.detailItems.invoice_status),callback:function ($$v) {_vm.$set(_vm.detailItems, "invoice_status", $$v)},expression:"detailItems.invoice_status"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-select',{attrs:{"items":_vm.user_items,"item-text":"EmployeeName","item-value":"EmployeeName","label":"Created by","outlined":"","dense":"","hide-details":"","readonly":"","disabled":_vm.field_disabled.always,"color":"grey-lighten-5"},model:{value:(_vm.detailItems.created_by),callback:function ($$v) {_vm.$set(_vm.detailItems, "created_by", $$v)},expression:"detailItems.created_by"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-select',{class:(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.sales_name || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"items":_vm.user_items,"item-text":"EmployeeName","item-value":"EmployeeName","label":"Sales Name","outlined":"","dense":"","hide-details":"","readonly":_vm.readonly && !_vm.old_data,"disabled":(_vm.field_disabled.always && !_vm.old_data) || !_vm.specific.sales_name,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.sales_name),callback:function ($$v) {_vm.$set(_vm.detailItems, "sales_name", $$v)},expression:"detailItems.sales_name"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-select',{class:_vm.field_disabled.condition || !_vm.specific.pm_name || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"items":_vm.user_items,"item-text":"EmployeeName","item-value":"EmployeeName","label":"PM Name","outlined":"","dense":"","hide-details":"","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.pm_name,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.pm_name),callback:function ($$v) {_vm.$set(_vm.detailItems, "pm_name", $$v)},expression:"detailItems.pm_name"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.readonly ||
                (_vm.field_disabled.always && !_vm.old_data) ||
                !_vm.specific.order_date},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({class:(_vm.field_disabled.always && !_vm.old_data) ||
                    !_vm.specific.order_date || _vm.readonly
                      ? ''
                      : 'can-edit',attrs:{"label":"Order Date","append-icon":"event","readonly":"","disabled":(_vm.field_disabled.always && !_vm.old_data) ||
                    !_vm.specific.order_date,"name":"order-date","outlined":"","hide-details":"","dense":"","color":_vm.color},model:{value:(_vm.detailItems.order_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "order_date", $$v)},expression:"detailItems.order_date"}},on))]}}],null,false,2138893853),model:{value:(_vm.order_date_menu),callback:function ($$v) {_vm.order_date_menu=$$v},expression:"order_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.order_date_menu = false},"change":_vm.DataChange},model:{value:(_vm.detailItems.order_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "order_date", $$v)},expression:"detailItems.order_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({class:_vm.field_disabled.condition || !_vm.specific.license_start_date || _vm.readonly
                      ? ''
                      : 'can-edit',attrs:{"label":"License Start Date","append-icon":"event","readonly":"","disabled":_vm.field_disabled.condition || !_vm.specific.license_start_date,"name":"order-date","outlined":"","hide-details":"","dense":"","color":_vm.color},model:{value:(_vm.detailItems.license_start_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "license_start_date", $$v)},expression:"detailItems.license_start_date"}},on))]}}],null,false,2334606114),model:{value:(_vm.license_start_date_menu),callback:function ($$v) {_vm.license_start_date_menu=$$v},expression:"license_start_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.license_start_date_menu = false},"change":_vm.DataChange},model:{value:(_vm.detailItems.license_start_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "license_start_date", $$v)},expression:"detailItems.license_start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({class:_vm.field_disabled.condition || !_vm.specific.license_end_date || _vm.readonly
                      ? ''
                      : 'can-edit',attrs:{"label":"License End Date","append-icon":"event","readonly":"","disabled":_vm.field_disabled.condition || !_vm.specific.license_end_date,"name":"order-date","outlined":"","hide-details":"","dense":"","color":_vm.color},model:{value:(_vm.detailItems.license_end_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "license_end_date", $$v)},expression:"detailItems.license_end_date"}},on))]}}],null,false,3374303618),model:{value:(_vm.license_end_date_menu),callback:function ($$v) {_vm.license_end_date_menu=$$v},expression:"license_end_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.license_end_date_menu = false},"change":_vm.DataChange},model:{value:(_vm.detailItems.license_end_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "license_end_date", $$v)},expression:"detailItems.license_end_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-text-field',{class:_vm.field_disabled.condition || !_vm.specific.project_duration || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"dense":"","outlined":"","label":"Project Duration (Days)","hide-details":"","type":"number","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.project_duration,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.project_duration),callback:function ($$v) {_vm.$set(_vm.detailItems, "project_duration", $$v)},expression:"detailItems.project_duration"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({class:_vm.field_disabled.condition || !_vm.specific.project_start_date || _vm.readonly
                      ? ''
                      : 'can-edit',attrs:{"label":"Project Start Date","append-icon":"event","readonly":"","disabled":_vm.field_disabled.condition || !_vm.specific.project_start_date,"name":"order-date","outlined":"","hide-details":"","dense":"","color":_vm.color},model:{value:(_vm.detailItems.project_start_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "project_start_date", $$v)},expression:"detailItems.project_start_date"}},on))]}}],null,false,2513307490),model:{value:(_vm.project_start_date_menu),callback:function ($$v) {_vm.project_start_date_menu=$$v},expression:"project_start_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.project_start_date_menu = false},"change":_vm.DataChange},model:{value:(_vm.detailItems.project_start_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "project_start_date", $$v)},expression:"detailItems.project_start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({class:_vm.field_disabled.condition || !_vm.specific.project_close_date || _vm.readonly
                      ? ''
                      : 'can-edit',attrs:{"label":"Project Close Date","append-icon":"event","readonly":"","disabled":_vm.field_disabled.condition || !_vm.specific.project_close_date,"name":"order-date","outlined":"","hide-details":"","dense":"","color":_vm.color},model:{value:(_vm.detailItems.project_close_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "project_close_date", $$v)},expression:"detailItems.project_close_date"}},on))]}}],null,false,1580469922),model:{value:(_vm.project_close_date_menu),callback:function ($$v) {_vm.project_close_date_menu=$$v},expression:"project_close_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.project_close_date_menu = false},"change":_vm.DataChange},model:{value:(_vm.detailItems.project_close_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "project_close_date", $$v)},expression:"detailItems.project_close_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"4"}},[_c('v-text-field',{class:_vm.field_disabled.condition || !_vm.specific.warranty_ma_duration || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"dense":"","outlined":"","label":"Warranty/MA Duration (Days)","hide-details":"","type":"number","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.warranty_ma_duration,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.warranty_ma_duration),callback:function ($$v) {_vm.$set(_vm.detailItems, "warranty_ma_duration", $$v)},expression:"detailItems.warranty_ma_duration"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({class:_vm.field_disabled.condition ||
                    !_vm.specific.warranty_ma_start_date || _vm.readonly
                      ? ''
                      : 'can-edit',attrs:{"label":"Warranly/MA Start Date","append-icon":"event","readonly":"","disabled":_vm.field_disabled.condition ||
                    !_vm.specific.warranty_ma_start_date,"name":"order-date","outlined":"","hide-details":"","dense":"","color":_vm.color},model:{value:(_vm.detailItems.warranty_ma_start_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "warranty_ma_start_date", $$v)},expression:"detailItems.warranty_ma_start_date"}},on))]}}],null,false,2917120266),model:{value:(_vm.warranty_ma_start_date_menu),callback:function ($$v) {_vm.warranty_ma_start_date_menu=$$v},expression:"warranty_ma_start_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.warranty_ma_start_date_menu = false},"change":_vm.DataChange},model:{value:(_vm.detailItems.warranty_ma_start_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "warranty_ma_start_date", $$v)},expression:"detailItems.warranty_ma_start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.readonly},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({class:_vm.field_disabled.condition ||
                    !_vm.specific.warranty_ma_close_date || _vm.readonly
                      ? ''
                      : 'can-edit',attrs:{"label":"Warranly/MA Close Date","append-icon":"event","readonly":"","disabled":_vm.field_disabled.condition ||
                    !_vm.specific.warranty_ma_close_date,"name":"order-date","outlined":"","hide-details":"","dense":"","color":_vm.color},model:{value:(_vm.detailItems.warranty_ma_close_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "warranty_ma_close_date", $$v)},expression:"detailItems.warranty_ma_close_date"}},on))]}}],null,false,1673240394),model:{value:(_vm.warranty_ma_close_date_menu),callback:function ($$v) {_vm.warranty_ma_close_date_menu=$$v},expression:"warranty_ma_close_date_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.warranty_ma_close_date_menu = false},"change":_vm.DataChange},model:{value:(_vm.detailItems.warranty_ma_close_date),callback:function ($$v) {_vm.$set(_vm.detailItems, "warranty_ma_close_date", $$v)},expression:"detailItems.warranty_ma_close_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('v-select',{class:_vm.field_disabled.condition || !_vm.specific.sla || _vm.readonly ? '' : 'can-edit',attrs:{"items":_vm.sla_items,"label":"SLA","outlined":"","dense":"","hide-details":"","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.sla,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.sla),callback:function ($$v) {_vm.$set(_vm.detailItems, "sla", $$v)},expression:"detailItems.sla"}}),_c('v-textarea',{class:_vm.field_disabled.condition || !_vm.specific.sla_details || _vm.readonly
                  ? ''
                  : 'can-edit',staticStyle:{"margin-top":"15px"},attrs:{"outlined":"","dense":"","hide-details":"","rows":"3","label":"Details SLA","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.sla_details,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.sla_details),callback:function ($$v) {_vm.$set(_vm.detailItems, "sla_details", $$v)},expression:"detailItems.sla_details"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('v-select',{class:_vm.field_disabled.condition || !_vm.specific.preventive_ma || _vm.readonly
                  ? ''
                  : 'can-edit',attrs:{"items":_vm.preventive_ma_items,"label":"Preventive MA","outlined":"","dense":"","hide-details":"","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.preventive_ma,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.preventive_ma),callback:function ($$v) {_vm.$set(_vm.detailItems, "preventive_ma", $$v)},expression:"detailItems.preventive_ma"}}),_c('v-textarea',{class:_vm.field_disabled.condition || !_vm.specific.preventive_ma_details || _vm.readonly
                  ? ''
                  : 'can-edit',staticStyle:{"margin-top":"15px"},attrs:{"outlined":"","dense":"","hide-details":"","rows":"3","label":"Details Preventive MA","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.preventive_ma_details,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.preventive_ma_details),callback:function ($$v) {_vm.$set(_vm.detailItems, "preventive_ma_details", $$v)},expression:"detailItems.preventive_ma_details"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","dense":"","hide-details":"","rows":"3","label":"Note","readonly":"","disabled":_vm.field_disabled.always,"color":_vm.color},model:{value:(_vm.detailItems.note),callback:function ($$v) {_vm.$set(_vm.detailItems, "note", $$v)},expression:"detailItems.note"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{class:_vm.field_disabled.condition || !_vm.specific.comment || _vm.readonly
                  ? ''
                  : 'can-edit',staticStyle:{"margin-bottom":"10px"},attrs:{"outlined":"","dense":"","hide-details":"","rows":"3","label":"Comment","readonly":_vm.readonly,"disabled":_vm.field_disabled.condition || !_vm.specific.comment,"color":_vm.color},on:{"change":_vm.DataChange},model:{value:(_vm.detailItems.comment),callback:function ($$v) {_vm.$set(_vm.detailItems, "comment", $$v)},expression:"detailItems.comment"}})],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }