<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div style="margin-bottom: 5px">Question Title</div>
          <v-text-field
            outlined
            v-model="question.title"
            name="question-title"
            v-validate="'required'"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              dense
              type="error"
              v-show="errors.has('question-title')"
              style="margin-top: -10px"
              v-if="error == ''"
              >{{ errors.first("question-title") }}</v-alert
            >
          </transition>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        style="margin-top: -40px"
      >
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div>Question Type</div>
        </div>
        <v-select
          :items="questionTypeItems"
          item-text="text"
          item-value="value"
          v-model="question.type"
          outlined
          name="question-type"
          v-validate="'required'"
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            dense
            type="error"
            v-show="errors.has('question-type')"
            style="margin-top: -10px"
            v-if="error == ''"
            >{{ errors.first("question-type") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        style="margin-top: -40px"
      >
        <div style="padding: 0px 0px 10px 0px; color: gray; display: flex">
          <div>Question Set</div>
          <v-spacer></v-spacer>
          <div
            style="font-size: 13px; margin-top: 5px; cursor: pointer"
            @click="dialog_question_set = true"
          >
            add new question set
          </div>
        </div>
        <v-select
          :items="questionSetItems"
          item-text="QuestionSetName"
          item-value="ID"
          v-model="question.question_set"
          outlined
          name="question-set"
          v-validate="'required'"
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            dense
            type="error"
            v-show="errors.has('question-set')"
            style="margin-top: -10px"
            v-if="error == ''"
            >{{ errors.first("question-set") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-top: -30px"
      >
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div>Question Description</div>
        </div>
        <quill-editor
          id="editor"
          :tag="'textarea'"
          :config="config"
          :options="editorOption"
          v-model="question.desc"
          name="question-description"
          v-validate="'required'"
        />
        <br />
        <transition name="slide-fade">
          <v-alert
            dense
            type="error"
            v-show="errors.has('question-description')"
            v-if="error == ''"
            >{{ errors.first("question-description") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div>Answers</div>
          <div
            v-if="question.type == undefined || question.type == null"
            style="text-align: center"
          >
            Please select question type.
          </div>
          <div v-if="question.type == 'subjective'" style="text-align: center">
            Subjective Question.
          </div>
          <div v-if="question.type == 'multiplechoice'">
            <div>
              <transition name="slide-fade">
                <v-alert
                  dense
                  type="error"
                  v-show="errors.has('question-answer')"
                  v-if="error == ''"
                  >{{ errors.first("question-answer") }}</v-alert
                >
              </transition>
            </div>
            <v-row>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                v-for="(n, index) in question.choice"
                :key="index"
                style="margin-top: -30px"
              >
                <v-radio-group
                  v-model="question.answer"
                  name="question-answer"
                  v-validate="'required'"
                >
                  <v-row>
                    <v-col>
                      <v-radio
                        :label="`${eng[index]}`"
                        :value="index"
                      ></v-radio>
                    </v-col>
                    <v-col>
                      <v-icon @click="RemoveChoice(index)" class="btn-delete"
                        >mdi-delete</v-icon
                      >
                    </v-col>
                  </v-row>
                  <quill-editor
                    id="editor"
                    :tag="'textarea'"
                    :config="config"
                    :options="editorOptionAnswer"
                    v-model="question.choice[index]"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <div
              style="margin-top: 15px; padding: 0px 0px 10px 0px; color: gray"
            >
              <v-btn text @click="AddNewChoice">
                <v-icon>mdi-plus</v-icon>Add New Choice
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            width="120"
            @click="SaveQuestion"
            :disabled="question.choice.length == 0"
            v-if="QuestionData == null"
          >
            <v-icon style="margin-right: 5px">mdi-content-save</v-icon>save
          </v-btn>
          <v-btn
            color="success"
            width="120"
            @click="UpdateQuestion"
            :disabled="question.choice.length == 0"
            v-if="QuestionData != null"
          >
            <v-icon style="margin-right: 5px">mdi-content-save</v-icon>save
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <!-- dialog -->
    <v-dialog v-model="dialog_question_set" max-width="400">
      <v-card>
        <v-card-title>Add Question Set</v-card-title>
        <v-card-text style="margin-top: 10px">
          <v-text-field
            outlined
            label="Question Set Name"
            v-model="question_set_name"
            name="question-set-name"
            v-validate="'required'"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              dense
              type="error"
              v-show="errors.has('question-set-name')"
              style="margin-top: -10px"
              v-if="error == ''"
              >{{ errors.first("question-set-name") }}</v-alert
            >
            <v-alert
              dense
              type="error"
              style="margin-top: -10px"
              v-if="error != ''"
              >{{ error }}</v-alert
            >
          </transition>
        </v-card-text>
        <v-card-actions style="margin-top: -20px">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog_question_set = false"
            >cancel</v-btn
          >
          <v-btn color="green darken-1" text @click="SaveQuestionSet"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../../../config";

export default {
  data() {
    return {
      user: "",
      eng: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      config: {
        fileUpload: false,
        events: {
          content: [],
          initialized: function () {
            //   console.log("initialized");
          },
          "image.inserted": function ($img) {
            // console.log("image.inserted");
          },
        },
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
        },
      },
      editorOptionAnswer: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ script: "sub" }, { script: "super" }],
            ["clean"],
            ["image", "video"],
          ],
        },
      },
      question: {
        answer: null,
        answers: [],
        choice: ["", "", "", ""],
        desc: "",
      },
      question_set_name: "",
      dialog_question_set: false,
      questionTypeItems: [
        {
          text: "Multiple Choice",
          value: "multiplechoice",
        },
        {
          text: "Subjective",
          value: "subjective",
        },
      ],
      questionSetItems: [],
      error: "",
      loading: false,
    };
  },
  props: ["QuestionData"],
  methods: {
    AddNewChoice() {
      this.question.choice.push("");
    },
    RemoveChoice(index) {
      if (index == this.question.answer) {
        this.question.answer = null;
      }
      this.question.choice.splice(index, 1);
    },
    async SaveQuestion() {
      let array = [
        this.$validator.validate("question-title"),
        this.$validator.validate("question-type"),
        this.$validator.validate("question-set"),
        // this.$validator.validate("question-description"),
      ];

      if (this.question.type == "multiplechoice") {
        array.push(this.$validator.validate("question-answer"));
      }

      const results = Promise.all(array);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        this.$swal
          .fire({
            title: "Are you sure ?",
            text: `Do you want to create this question ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          })
          .then(async (result) => {
            if (result.value) {
              this.loading = true;
              this.question.answers = [];
              for (var i = 0; i < this.question.choice.length; i++) {
                if (this.question.answer == i) {
                  this.question.answers.push(1);
                } else {
                  this.question.answers.push(0);
                }
              }

              this.question.create_by = this.user.EmployeeEmail;
              console.log("question: ", this.question);

              axios({
                method: "POST",
                url: `${IP}/question/create`,
                headers: {
                  Authorization: AuthKey,
                },
                data: {
                  question: this.question,
                },
              }).then(async (response) => {
                this.loading = false;
                this.$swal.fire("Create Question Success!", "", "success");
                await this.Clear();
                await this.Start();
              });
            }
          });
      }
    },
    async UpdateQuestion() {
      let array = [
        this.$validator.validate("question-title"),
        this.$validator.validate("question-type"),
        this.$validator.validate("question-set"),
        // this.$validator.validate("question-description"),
      ];

      if (this.question.type == "multiplechoice") {
        array.push(this.$validator.validate("question-answer"));
      }

      const results = Promise.all(array);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        this.$swal
          .fire({
            title: "Are you sure ?",
            text: `Do you want to update this question ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          })
          .then(async (result) => {
            if (result.value) {
              this.loading = true;
              this.question.answers = [];
              for (var i = 0; i < this.question.choice.length; i++) {
                if (this.question.answer == i) {
                  this.question.answers.push(1);
                } else {
                  this.question.answers.push(0);
                }
              }

              console.log("question: ", this.question);
              axios({
                method: "POST",
                url: `${IP}/question/update`,
                headers: {
                  Authorization: AuthKey,
                },
                data: {
                  question: this.question,
                },
              }).then(async (response) => {
                this.loading = false;
                this.$swal.fire("Update Question Success!", "", "success");
                // await this.Clear();
                // await this.Start();
                this.$emit("update-data");
              });
            }
          });
      }
    },
    async SaveQuestionSet() {
      const results = Promise.all([
        this.$validator.validate("question-set-name"),
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        this.error = "";
        this.$swal
          .fire({
            title: "Are you sure ?",
            text: "Do you want to create this question set ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          })
          .then(async (result) => {
            if (result.value) {
              console.log("create", this.question_set);
              axios({
                method: "POST",
                url: `${IP}/question-set/create`,
                headers: {
                  Authorization: AuthKey,
                },
                data: {
                  QuestionSetName: this.question_set_name,
                  CreateBy: this.user.EmployeeEmail,
                },
              }).then(async (response) => {
                this.$swal.fire("Create Question Set Success!", "", "success");
                this.question_set_name = "";
                this.dialog_question_set = false;
                this.$validator.reset();
                await this.Start();
              });
            }
          });
      }
    },

    Clear() {
      (this.question = {
        answer: null,
        answers: [],
        desc: "",
        choice: ["", "", "", ""],
      }),
        this.$validator.reset();
    },
    GetAllQuestionSet() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/question-set/get-all`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            console.log("questionSetItems: ", response.data);
            this.questionSetItems = response.data;
            resolve(true);
          });
      });
    },
    SetData() {
      return new Promise(async (resolve, reject) => {
        console.log("QuestionData: ", this.QuestionData);
        this.question.title = this.QuestionData.QuestionTitle;
        this.question.desc = this.QuestionData.QuestionDescription;
        this.question.type = this.QuestionData.QuestionType;
        this.question.question_set = this.QuestionData.QuestionSetID;
        this.question.id = this.QuestionData.ID;
        if (this.question.type == "multiplechoice") {
          this.question.choice = JSON.parse(this.QuestionData.QuestionChoices);
          this.question.answers = JSON.parse(this.QuestionData.QuestionAnswer);
          for (var i = 0; i < this.question.answers.length; i++) {
            if (this.question.answers[i] == 1) {
              this.question.answer = i;
            }
          }
        }
        resolve(true);
      });
    },
    async Start() {
      this.user = this.$store.getters.currentUser;
      await this.GetAllQuestionSet();
      if (this.QuestionData != null) {
        await this.SetData();
      }
    },
  },
  created() {
    this.Start();
  },
};
</script>

<style scoped lang="scss">
.btn-delete {
  transition: 0.4s all;
  float: right;
  cursor: pointer;
}

.btn-delete:hover {
  color: red;
}
</style>
