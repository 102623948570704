var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dashboard-nav-drawer"},[_c('v-navigation-drawer',{staticClass:"navigation",style:([
        _vm.width >= 920
          ? { marginTop: '64px' }
          : { marginTop: '55px', zIndex: 900, height: 'calc(100vh - 55px)' },
      ]),attrs:{"width":_vm.width >= 920 ? 260 : '100%',"mini-variant":_vm.$store.state.chatDrawerMini,"app":_vm.width >= 920,"permanent":_vm.width >= 920,"temporary":_vm.width < 920,"absolute":_vm.width < 920,"color":"#252525"},on:{"update:miniVariant":function($event){return _vm.$set(_vm.$store.state, "chatDrawerMini", $event)},"update:mini-variant":function($event){return _vm.$set(_vm.$store.state, "chatDrawerMini", $event)}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-icon',{staticStyle:{"margin-left":"-7px"}},[(_vm.width >= 920)?_c('v-btn',{staticStyle:{"width":"30px","height":"36px"},attrs:{"outlined":"","x-small":"","color":"#FFF"},on:{"click":function($event){$event.stopPropagation();_vm.$store.state.chatDrawerMini = !_vm.$store.state.chatDrawerMini}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e(),(_vm.width < 920)?_c('v-btn',{staticStyle:{"width":"30px","height":"36px"},attrs:{"outlined":"","x-small":"","color":"#FFF"},on:{"click":function($event){$event.stopPropagation();return _vm.Drawer.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e()],1),_c('v-list-item-title',{staticStyle:{"margin-left":"-10px"}},[_c('v-btn',{attrs:{"outlined":"","block":"","dense":"","color":"#FFF"},on:{"click":function($event){return _vm.GoTo('/chat')}}},[_vm._v("New Chat")])],1)],1),_c('v-divider',{attrs:{"dark":""}}),(_vm.loading)?_c('ContentLoading',{staticStyle:{"margin-top":"50px"},attrs:{"width":36,"height":45}}):_vm._e(),(_vm.items.length == 0 && !_vm.$store.state.chatDrawerMini && !_vm.loading)?_c('div',{staticClass:"no-items"},[_vm._v(" No Chat Available ")]):_vm._e(),(_vm.items.length > 0 && !_vm.loading)?_c('v-list',_vm._l((_vm.items),function(item,i){return _c('div',{key:i},[(!item.sub_links)?_c('v-list-item',{style:([
              item.disabled == false
                ? _vm.$route.path == item.to
                  ? {
                      color: '#FFF',
                      'border-right': '5px solid #71A714',
                    }
                  : { color: '#FFF' }
                : { color: '#CFCFCF' },
            ]),attrs:{"disabled":item.disabled,"link":""}},[_c('v-tooltip',{staticStyle:{"z-index":"9"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item-icon',_vm._g({staticStyle:{"margin-left":"-3px"}},on),[(_vm.$route.path != item.to)?_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-chat")]):_vm._e(),(_vm.$route.path == item.to)?_c('v-icon',{staticClass:"delete-icon",attrs:{"dark":""},on:{"click":function($event){return _vm.OpenDelete(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1),_c('v-list-item-content',_vm._g({staticStyle:{"margin-left":"-20px"},on:{"click":function($event){return _vm.GoTo(item.to)}}},on),[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1):_c('v-list-group',{key:item.title,attrs:{"no-action":"","disabled":item.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{staticStyle:{"z-index":"9"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item-icon',_vm._g({},on),[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',_vm._g({},on),[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]},proxy:true}],null,true)},[_c('v-divider'),_vm._l((item.sub_links),function(sublink){return _c('v-list-item',{key:sublink.title,style:([
                _vm.$route.hash == sublink.to
                  ? {
                      color: '#65a000',
                      'border-right': '5px solid #65a000',
                    }
                  : { color: '#65a000' },
              ]),attrs:{"link":"","color":"#B5B5B5"},on:{"click":function($event){return _vm.GoToHash(item.to, sublink.to)}}},[_c('v-tooltip',{staticStyle:{"z-index":"9"},attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item-content',_vm._g({},on),[_c('v-list-item-title',[_vm._v(_vm._s(sublink.title))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(sublink.title))])])],1)})],2)],1)}),0):_vm._e()],1),(_vm.width < 920)?_c('div',{staticClass:"mb-dv-btn"},[_c('v-btn',{staticStyle:{"width":"45px","height":"36px","border-radius":"0px 5px 5px 0px"},attrs:{"dark":"","x-small":"","color":"#616161"},on:{"click":_vm.Drawer}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Delete chat? ")]),_c('v-card-text',[_vm._v("This will delete "),_c('b',[_vm._v(_vm._s(_vm.row.title))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"#616161","text":""},on:{"click":_vm.CloseDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.Delete}},[_vm._v(" Delete ")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }