<template>
<div>
  <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999;">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular indeterminate :size="70" :width="7" color="teal"></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
  <div style="margin-top: 50px; margin-bottom: 50px;">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">step 1</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">step 2</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 3" step="3">step 3</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 4" step="4">step 4</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 5" step="5">step 5</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 6" step="6">step 6</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 == 7" step="7">Finished</v-stepper-step>
          </v-stepper-header>
          <br />
          <div align="center">
            <h2>ใบสมัครงาน</h2>
            <p>กรอกข้อมูลของตัวท่านเอง</p>
            <b>(To be completed in own handwriting)</b>
          </div>
          <br />
          <v-divider></v-divider>
          <br />
          <div align="center">
            <p v-if="e1 == 1">{{titleStep1}}</p>
            <p v-if="e1 == 2">{{titleStep2}}</p>
            <p v-if="e1 == 3">{{titleStep3}}</p>
            <p v-if="e1 == 4">{{titleStep4}}</p>
            <p v-if="e1 == 5">{{titleStep5}}</p>
            <p v-if="e1 == 6">{{titleStep6}}</p>
          </div>
          <v-stepper-items>
            <v-stepper-content step="1">
              <ApplicantInformation @step1="dataStep1" @nextE1="nextState" />
              <!-- <v-btn color="primary" @click="validate(2)">Next</v-btn> -->
            </v-stepper-content>

            <v-stepper-content step="2">
              <PersonalInformation
                @step2="dataStep2"
                @nextE1="nextState"
                @previousE1="previousState"
              />
              <!-- <v-btn class="mx-1" @click="previousState(2)">previous</v-btn> -->
              <!-- <v-btn color="primary" @click="validate(3)">Next</v-btn> -->
            </v-stepper-content>

            <v-stepper-content step="3">
              <Education @education="dataStep3" @nextE1="nextState" @previousE1="previousState" />
              <!-- <v-btn class="mx-2" @click="previousState(3)">previous</v-btn>
              <v-btn color="primary" @click="validate(4)">Next</v-btn>-->
            </v-stepper-content>

            <v-stepper-content step="4">
              <WorkingExperience
                @workExp="dataStep4"
                @nextE1="nextState"
                @previousE1="previousState"
              />
              <!-- <v-btn class="mx-2" @click="previousState(4)">previous</v-btn>
              <v-btn color="primary" @click="validate(5)">Next</v-btn>-->
            </v-stepper-content>

            <v-stepper-content step="5">
              <LanguageAbility
                @language="dataStep5"
                @nextE1="nextState"
                @previousE1="previousState"
              />
              <!-- <v-btn class="mx-2" @click="previousState(5)">previous</v-btn>
              <v-btn color="primary" @click="validate(6)">Next</v-btn>-->
            </v-stepper-content>

            <v-stepper-content step="6">
              <v-row>
                <v-col class="d-flex" cols="12" md="6">
                  <v-text-field
                    v-model="form.programmingLanguage"
                    label="ภาษาที่ใช้ในการเขียนโปรแกรม : ระบุ"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" md="6">
                  <v-text-field v-model="form.specialKnowledge" label="ความรู้พิเศษอื่นๆ : ระบุ"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-radio-group
                    v-model="form.driving"
                    :mandatory="false"
                    :rules="nameDriving"
                    row
                    label="ขับรถยนต์ได้ :"
                  >
                    <v-radio label="ไม่ได้" class="mx-4" value="ไม่ได้"></v-radio>
                    <v-radio label="ได้" class="mx-4" value="ได้"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="6" v-if="form.driving === 'ได้'">
                  <v-text-field
                    v-model="form.drivingLicense"
                    :rules="nameDrivingLicense"
                    label="ใบขับขี่เลขที่"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-radio-group
                    v-model="form.country"
                    :mandatory="false"
                    :rules="nameCountry"
                    row
                    label="สามารถไปปฏิบัติงานต่างจังหวัด :"
                  >
                    <v-radio label="ไม่ได้" class="mx-4" value="ไม่ได้"></v-radio>
                    <v-radio label="ได้" class="mx-4" value="ได้"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.workStart"
                    :rules="nameWorkStart"
                    label="วันที่สามารถเริ่มงานได้"
                  ></v-text-field>
                </v-col>
                <!-- <v-col class="d-flex" cols="12">
                  <input ref="inputUpload" type="file" @change="processFile($event)" />
                </v-col>-->

                <v-col cols="12">
                  <v-textarea
                    outlined
                    label="กรุณาแนะนำตัวท่านเอง เพื่อให้บริษัทรู้จักตัวท่านดีขึ้น"
                    :rules="nameAboutYourself"
                    v-model="form.aboutYourself"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn class="mx-2" @click="previousState2(6)" text>
                  <v-icon style="margin-right: 5px;">mdi-arrow-left</v-icon>
                  previous</v-btn>
                <v-spacer></v-spacer>
              <v-btn color="primary" @click="validate(7)" text>Submit</v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="7">
              <v-alert dense text type="success">ขอบคุณที่มาสมัครงานกับเรา</v-alert>
              <!-- <div align="center">
                <br />
                <v-btn color="primary" @click="goTo('/quiz-list')">กรุณาคลิก เพื่อทำเทส</v-btn>
              </div> -->
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-form>
  </div>
</div>
</template>

<script>
import ApplicantInformation from "../../components/RegisJob/ApplicantInformation";
import PersonalInformation from "../../components/RegisJob/PersonalInformation";
import WorkingExperience from "../../components/RegisJob/WorkingExperience";
import LanguageAbility from "../../components/RegisJob/LanguageAbility";
import Education from "../../components/RegisJob/Education";
import axios from "axios";
import { apiURL } from "../../config";
export default {
  data() {
    return {
      loading: false,
      form: {},
      e1: 1,
      valid: true,
      titleStep1: "ข้อมูลผู้สมัคร",
      titleStep2: "Personal information (ประวัติส่วนตัว)",
      titleStep3: "Education (การศึกษา)",
      titleStep4:
        "Working Experience in Chronological (รายละเอียดของงาน / ฝึกงานที่ผ่านมา)",
      titleStep5: "Language Ability (ภาษา)",
      titleStep6: "Special Ability (ความสามารถพิเศษ)",
      inputs: [
        {
          educationLevel: "",
          institution: "",
          major: "",
          studyResult: ""
        }
      ],

      menu: false,
      menu1: false,
      nameDriving: [v => !!v || "Driving is required"],
      nameWorkStart: [
        v => !!v || "WorkStart is required",
        v =>
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
            v
          ) || "Please enter the workstart date  correctly, such as 20/02/2020."
      ],
      nameDrivingLicense: [v => !!v || "DrivingLicense is required"],
      nameCountry: [v => !!v || "Country is required"],
      nameAboutYourself: [v => !!v || "Yourself is required"]
    };
  },
  components: {
    ApplicantInformation,
    PersonalInformation,
    Education,
    WorkingExperience,
    LanguageAbility
  },
  methods: {
    goTo(value) {
      this.$router.push(value);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    nextState(e) {
      this.e1 = e;
    },
    previousState(e) {
      this.e1 = e;
    },
    previousState2(e) {
      this.e1 = e - 1;
    },
    dataStep1(val1, val2, val3, val4, val5) {
      this.form.nameThai = val1;
      this.form.nameEng = val2;
      this.form.position = val3;
      this.form.salary = val4;
      this.form.sex = val5;
      console.log('data step 1: ', this.form)
    },
    dataStep2(
      val1,
      val2,
      val3,
      val4,
      val5,
      val6,
      val7,
      val8,
      val9,
      val10,
      val11,
      val12,
      val13,
      val14,
      val15,
      val16
    ) {
      this.form.address = val1 || "";
      this.form.moo = val2 || "";
      this.form.road = val3 || "";
      this.form.district = val4 || "";
      this.form.amphur = val5 || "";
      this.form.province = val6 || "";
      this.form.postcode = val7 || "";
      this.form.tel = val8 || "";
      this.form.email = val9 || "";
      this.form.date = val10 || "";
      this.form.age = val11 || "";
      this.form.race = val12 || "";
      this.form.nationality = val13 || "";
      this.form.religion = val14 || "";
      this.form.militaryStatus = val15 || "";
      this.form.maritalStatus = val16 || "";
      console.log('data step 2: ', this.form)
    },
    dataStep3(e) {
      this.form.educations = e;
      console.log('data step 3: ', this.form)
    },
    dataStep4(e) {
      this.form.exps = e;
      console.log('data step 4: ', this.form)
    },
    dataStep5(val1, val2, val3, val4, val5, val6, val7, val8, val9) {
      this.form.languageThaiSpeaking = val1 || "";
      this.form.languageThaiWriting = val2 || "";
      this.form.languageThaiReading = val3 || "";
      this.form.languageEngSpeaking = val4 || "";
      this.form.languageEngWriting = val5 || "";
      this.form.languageEngReading = val6 || "";
      this.form.languageChineseSpeaking = val7 || "";
      this.form.languageChineseWriting = val8 || "";
      this.form.languageChineseReading = val9 || "";
      console.log('data step 5: ', this.form)
    },

    validate(e) {
      this.$refs.form.validate();

      if (this.$refs.form.validate() == true && e == 7) {
        this.$swal({
          title: "Are you sure?",
          icon: "warning",
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        }).then(result => {
          console.log('this.form', JSON.stringify(this.form))
          if (result.value) {
            this.loading = true
            axios
              .post(`${apiURL}/api/v1/applicants`, this.form)
              .then(res => {
                this.loading = false
                this.e1 = 7;
                console.log('data', res.data)
                localStorage.setItem('empId',res.data)
              })
              .catch(err => console.log("err", err));
          }
        });
      }
    }
  },
  created(){
    if(this.$store.getters.currentUser != null){
      this.$router.push('/')
    }
  }
};
</script>
