<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <v-row>
      <v-col cols="12" v-if="answer != ''">
        <v-row>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <div class="output ql-snow">
              <div class="ql-editor" v-html="answer"></div>
            </div>
          </v-col>
          <v-col cols="12" v-if="api_message != ''">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" v-if="api_message != ''">
            <v-alert type="warning" dense style="margin-bottom: -0px">{{
              api_message
            }}</v-alert>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-btn block color="error" @click="Reset">reset</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" v-if="answer == ''">
        <v-row>
          <v-col cols="12">
            <input
              id="multiplefileUpload"
              type="file"
              hidden
              ref="multiple_file"
              multiple="multiple"
              accept="application/pdf"
              @change="handleMultipleFileUpload"
            />
            <v-btn block color="success" @click="chooseMultipleFiles">
              <v-icon class="mr-1">mdi-plus</v-icon>
              Select Job Description Files (.pdf)
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <div
              class="upload-content-area"
              style="text-align: center; margin: 20px"
            >
              <div v-if="upload_file.length === 0">
                Please select Job Description files (.pdf only)
              </div>
              <v-simple-table dense v-else>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th class="text-right">Size</th>
                      <th class="text-center">Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(file, n) in upload_file" :key="n">
                      <td style="text-align: left">{{ file.name }}</td>
                      <td class="text-right">{{ pretty(file.size) }}</td>
                      <td class="text-center">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="red"
                          @click="removeUploadItems(file)"
                          style="width: 25px; height: 25px; margin: 4px"
                        >
                          <v-icon dark size="15">mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" v-if="answer == ''">
        <v-row>
          <v-col cols="12">
            <input
              id="fileUpload"
              type="file"
              hidden
              accept="application/pdf"
              @change="handleFileUpload"
            />
            <v-btn block color="success" @click="chooseFiles">
              <v-icon class="mr-1">mdi-plus</v-icon>
              Select CV Files (.pdf)
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <div
              class="upload-content-area"
              style="text-align: center; margin: 20px"
            >
              <div v-if="file === null">
                Please select CV files (.pdf only)
              </div>
              <v-simple-table dense v-else>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th class="text-right">Size</th>
                      <th class="text-center">Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="text-align: left">{{ file.name }}</td>
                      <td class="text-right">{{ pretty(file.size) }}</td>
                      <td class="text-center">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="red"
                          @click="removeItems(file)"
                          style="width: 25px; height: 25px; margin: 4px"
                        >
                          <v-icon dark size="15">mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="answer == ''">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" v-if="error_message != ''">
        <v-alert type="error" dense style="margin-bottom: -0px">{{
          error_message
        }}</v-alert>
      </v-col>
      <v-col cols="12" v-if="error_message != ''">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" v-if="answer == ''">
        <v-btn block color="success" @click="Confirm">
          Process with Chat GPT
        </v-btn>
      </v-col>
    </v-row>
    <vue-editor
      id="editor"
      style="display: none"
      :editorToolbar="customToolbar"
    ></vue-editor>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { VueEditor } from "vue2-editor";
import { IP, AuthKey } from "../../../config";
import pretty from "prettysize";
import { Others } from "../../../function/Others";

export default {
  data() {
    return {
      loading: false,
      file: null,
      upload_file: [],
      error_message: "",
      api_message: "",
      answer: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "video"],
        ["clean"], // remove formatting button
      ],
    };
  },
  components: {
    VueEditor,
  },
  methods: {
    Reset() {
      this.file = null;
      this.upload_file = [];
      this.error_message = "";
      this.answer = "";
    },
    Confirm() {
      if (this.upload_file.length > 0 && this.file != null) {
        this.error_message = "";
        Swal.fire({
          title: "Are you sure ?",
          text: "Do you want to continue the process ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.value) {
            this.loading = true;
            this.ProcessWithChatGPT().then(async (response) => {
              this.loading = false;
              console.log("res: ", response);
              this.api_message = response.message;
              this.answer = response.result;
            });
          }
        });
      } else {
        this.error_message = "Please select CV and Job Description files.";
      }
    },
    ProcessWithChatGPT() {
      return new Promise(async (resolve, reject) => {
        const data = new FormData();
        this.upload_file.forEach((file, index) => {
          data.append("jobdesc_files", file);
        });
        data.append("cv_files", this.file);
        data.append("action", "which-jobdesc-suitable-cv");
        data.append("question", "");

        axios({
          method: "post",
          url: `${IP}/azure-openai/cv-and-jobdesc-validates`,
          headers: {
            Authorization: AuthKey,
          },
          data: data,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            resolve(error);
          });
      });
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    chooseMultipleFiles() {
      document.getElementById("multiplefileUpload").click();
    },
    async handleFileUpload(e) {
      const file = e.target.files;
      this.file = file[0];
    },
    async handleMultipleFileUpload() {
      for (var i = 0; i < this.$refs.multiple_file.files.length; i++) {
        let file = this.$refs.multiple_file.files[i];
        file.key = Others.GenerateKeyID(10);
        this.upload_file.push(file);
      }
    },
    removeItems() {
      this.file = null;
    },
    removeUploadItems(file) {
      for (var i = 0; i < this.upload_file.length; i++) {
        if (this.upload_file[i].key === file.key) {
          this.upload_file.splice(i, 1);
        }
      }
    },
    pretty(size) {
      return pretty(size);
    },
  },
};
</script>