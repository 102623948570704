<template>
  <div class="expansion-panels">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div class="information-area">
      <div style="border-bottom: 1px solid rgb(216, 216, 216)">
        <v-row style="margin-bottom: 0px">
          <v-col style="font-size: 30px">Access Control</v-col>
          <v-col cols="12" xs="12" sm="auto" md="auto">
            <v-btn
              color="primary"
              block
              @click="dialog_permission = !dialog_permission"
            >
              <v-icon style="margin: 0px 5px 0px -5px">mdi-pencil</v-icon
              >Permission
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-if="listItems.length == 0">
        <p style="font-size: 20px; text-align: center; margin: 40px">
          ไม่มีข้อมูล
        </p>
      </div>
      <v-card-title v-if="listItems.length > 0">
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listItems"
        :search="search"
        class="elevation-1"
        v-if="listItems.length > 0"
      >
        <template v-slot:item.Role="{ item }">
          <v-chip class="ma-2" color="primary" v-if="item.Role == 'User'">{{
            item.Role
          }}</v-chip>
          <v-chip class="ma-2" color="error" v-if="item.Role == 'Admin'">{{
            item.Role
          }}</v-chip>
          <v-chip class="ma-2" color="success" v-if="item.Role == 'Sale'">{{
            item.Role
          }}</v-chip>
          <v-chip
            class="ma-2"
            color="success"
            v-if="item.Role == 'SaleManager'"
            >{{ item.Role }}</v-chip
          >
          <v-chip
            class="ma-2"
            v-if="
              item.Role != 'SaleManager' &&
              item.Role != 'User' &&
              item.Role != 'Admin' &&
              item.Role != 'Sale'
            "
            >{{ item.Role }}</v-chip
          >
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn small icon @click="DialogUpdate(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <!-- dialog -->
      <v-dialog scrollable v-model="dialog" max-width="600">
        <v-card>
          <v-card-title>Edit Access Control</v-card-title>
          <v-card-text style="max-height: 400px">
            <v-container>
              <div
                style="
                  color: gray;
                  border-bottom: 1px solid rgb(216, 216, 216);
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                "
              >
                Role
              </div>
              <v-select
                :items="Role"
                v-model="update.role"
                label="Role"
                outlined
              ></v-select>
              <div
                style="
                  color: gray;
                  border-bottom: 1px solid rgb(216, 216, 216);
                  padding-bottom: 15px;
                  margin-bottom: 5px;
                "
              >
                Access Control
              </div>
              <v-text-field
                v-model="dialog_search"
                label="Search"
                append-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
              <v-data-table
                v-model="update.access_json"
                :headers="updateHeaders"
                :search="dialog_search"
                item-key="card"
                :items="role"
                class="elevation-1"
                show-select
              >
                <template v-slot:item.level="{ item }">
                  <br />
                  <v-text-field
                    v-model="item.level"
                    type="number"
                    outlined
                    dense
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              color="success"
              @click="SaveAccessControl"
              style="margin: 20px 0px 20px 0px"
              width="120"
              >save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog_permission" max-width="600">
        <v-card>
          <v-container>
            <v-card-title>Permission</v-card-title>
            <v-card-text>
              <v-row style="margin-bottom: -20px">
                <v-col cols="8">
                  <v-text-field
                    v-model="permission"
                    label="Permission Name"
                    outlined
                    dense
                    name="permission"
                    v-validate="'required'"
                  ></v-text-field>
                  <transition name="slide-fade">
                    <v-alert
                      type="error"
                      style="margin-top: -20px"
                      dense
                      v-show="errors.has('permission')"
                      v-if="error == ''"
                      >{{ errors.first("permission") }}</v-alert
                    >
                    <v-alert
                      type="error"
                      style="margin-top: -20px"
                      dense
                      v-if="error != ''"
                      >{{ error }}</v-alert
                    >
                  </transition>
                </v-col>
                <v-col cols="4">
                  <v-btn color="success" block @click="AddPermission"
                    >save</v-btn
                  >
                </v-col>
              </v-row>
              <div style="border-bottom: 1px solid rgb(216, 216, 216)"></div>
              <v-text-field
                v-model="permission_search"
                label="Search"
                append-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
              <v-data-table
                :headers="permissionHeaders"
                :search="permission_search"
                :items="role"
                class="elevation-1"
              >
                <template v-slot:item.action="{ item }">
                  <v-btn icon @click="DeletePermission(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
            <!-- <v-card-actions class="justify-center" style="margin-top: -20px; margin-bottom: 20px;"></v-card-actions> -->
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      error: "",
      permission: "",
      dialog_permission: false,
      set_role: false,
      loading: false,
      dialog: false,
      listItems: [],
      mockup: [],
      Role: [],
      role: "",
      search: "",
      dialog_search: "",
      permission_search: "",
      permissionHeaders: [
        { text: "Permission Name", value: "card" },
        { text: "Actions", value: "action", sortable: false },
      ],
      updateHeaders: [
        { text: "Card Name", value: "card" },
        { text: "Level", value: "level" },
      ],
      headers: [
        {
          text: "Employee Name",
          align: "start",
          sortable: false,
          value: "EmployeeName",
        },
        { text: "Role", value: "Role", align: "center" },
        { text: "Actions", value: "action", sortable: false },
      ],
      update: {
        id: 0,
        access_json: null,
      },
    };
  },
  methods: {
    async DeletePermission(item) {
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบสิทธิ์การเข้าถึงนี่หรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          let role = this.role;
          for (var i = 0; i < role.length; i++) {
            if (role[i].card == item.card) {
              role.splice(i, 1);
            }
          }
          this.ConvertToJSONFormat(role).then((response) => {
            const result = response;
            this.ApiPermission(result).then((response) => {
              if (response == true) {
                this.role = role;
              }
            }, this);
          }, this);
        }
      }, this);
    },
    async AddPermission() {
      const results = Promise.all([this.$validator.validate("permission")]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        this.error = "";
        let role = this.role;
        let check = false;
        for (var i = 0; i < role.length; i++) {
          if (role[i].card == this.permission) {
            check = true;
          }
        }

        if (check != true) {
          let obj = {
            card: this.permission,
            level: 0,
          };
          role.push(obj);
          this.ConvertToJSONFormat(role).then((response) => {
            const result = response;
            this.ApiPermission(result).then((response) => {
              if (response == true) {
                this.role = role;
                this.permission = "";
                this.error = "";
                this.$validator.reset();
              }
            });
          }, this);
        } else {
          this.error = "Permission has been already.";
        }
      }
    },
    ApiPermission(access_json) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/accesscontrol/update-permission`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            access_json: access_json,
          },
        }).then((response) => {
          if (response.data == "success") {
            resolve(true);
          }
        });
      });
    },
    SaveAccessControl() {
      this.MapDataToMaster(this.update.access_json, "current").then(
        (response) => {
          this.update.access_json = response;
          this.ConvertToJSONFormat(this.update.access_json).then((response) => {
            this.update.access_json = response;
            axios
              .put(`${IP}/accesscontrol-data/${this.update.id}`, this.update, {
                headers: {
                  Authorization: AuthKey,
                },
              })
              .then(async (response) => {
                if (response.data == "success") {
                  Swal.fire("อัพเดตสิทธิ์การเข้าถึงเสร็จสิ้น !", "", "success");
                  this.dialog = false;
                  await this.start();
                }
              });
          });
        }
      );
    },
    ConvertToJSONFormat(item) {
      return new Promise(async (resolve, reject) => {
        console.log("item: ", item);
        let array = [];
        let card = {
          Card: [],
        };
        let obj = {};
        for (var i = 0; i < item.length; i++) {
          card.Card.push(item[i].card);
        }
        array.push(card);
        for (var x = 0; x < card.Card.length; x++) {
          for (var y = 0; y < item.length; y++) {
            if (card.Card[x] == item[y].card) {
              obj[card.Card[x]] = item[y].level;
              array.push(obj);
              obj = {};
            }
          }
        }
        let str = JSON.stringify(array);
        resolve(str);
      });
    },
    DialogUpdate(item) {
      this.MapDataToMaster(item.AccessRightsJSON, "role").then((response) => {
        this.ProcessData(item.AccessRightsJSON).then((response) => {
          this.update.id = item.RowID;
          this.update.access_json = response;
          this.update.role = item.Role;
          this.dialog = true;

          console.log("update: ", this.update);
          console.log("master: ", this.role);
        });
      });
    },
    async MapDataToMaster(item, action) {
      return new Promise(async (resolve, reject) => {
        let current = item;
        if (action == "role") {
          current = await this.ProcessData(item);
        }
        for (var i = 0; i < this.role.length; i++) {
          for (var x = 0; x < current.length; x++) {
            if (current[x].card == this.role[i].card) {
              if (action == "role") {
                this.role[i].level = parseInt(current[x].level);
              } else {
                current[x].level = parseInt(this.role[i].level);
              }
            }
          }
        }
        resolve(current);
      });
    },
    ProcessData(item) {
      return new Promise(async (resolve, reject) => {
        let access_json = [];
        let access = item;
        try {
          access = JSON.parse(access);
        } catch (err) {
          console.log(err);
        }
        for (var i = 0; i < access[0].Card.length; i++) {
          let level = 0;
          for (var x = 0; x < access.length; x++) {
            if (access[x][access[0].Card[i]] != undefined) {
              level = access[x][access[0].Card[i]];
            }
            var obj = {
              card: access[0].Card[i],
              level: level,
            };
          }
          access_json.push(obj);
        }
        resolve(access_json);
      });
    },
    GetAccessControlData() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/select-accesscontrol-data`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            console.log("get access control: ", response.data);
            const result = response.data;
            this.listItems = result.user;
            this.mockup = result.mockup;
            this.Role = result.Roles;
            if (this.set_role != true) {
              console.log("set role!");
              for (var i = 0; i < result.mockup.length; i++) {
                if (result.mockup[i].Role == "AllRole") {
                  this.role = result.mockup[i].AccessRightsJSON;
                  this.ProcessData(this.role).then((response) => {
                    this.role = response;
                    this.set_role = true;
                    resolve(true);
                  }, this);
                }
              }
            } else {
              resolve(true);
            }
          });
      });
    },
    async start() {
      this.loading = true;
      await this.GetAccessControlData().then((response) => {
        var that = this;
        setTimeout(function () {
          that.loading = false;
        }, 1000);
      });
    },
  },
  mounted() {
    this.user = this.$store.getters.currentUser;
    this.start();
  },
};
</script>

<style scoped>
@import "../../css/transition.scss";

.expansion-panels {
  margin-top: 100px;
  margin-bottom: 30px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>