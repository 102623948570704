<template>
  <div class="card">
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          v-for="n in menu"
          :key="n.link"
        >
          <v-container>
            <v-card
              :color="n.color"
              :dark="n.dark"
              class="area-card hvr-bounce-in transition"
              @click="goTo(n.link)"
            >
              <div
                style="
                  position: absolute;
                  z-index: -1;
                  width: 100%;
                  height: 100%;
                "
              >
                <v-img
                  :src="require(`../../assets/card/${n.background}`)"
                  class="background-card"
                ></v-img>
              </div>
              <v-container>
                <v-card-title id="card-text" class="headline">{{
                  n.title
                }}</v-card-title>
                <v-card-subtitle id="card-text">{{
                  n.subtitle
                }}</v-card-subtitle>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          link: "accesscontrol",
          title: "Access Control",
          subtitle: "ระบบจัดการสิทธิ์การเข้าถึง",
          dark: true,
          color: "#409965",
          background: "key.jpg",
        },
        {
          link: "listApplicant",
          title: "Applicant",
          subtitle: "ข้อมูลผู้สมัครงาน",
          dark: true,
          color: "#952175",
          background: "applicant.jpg",
        },
        {
          link: "certificates",
          title: "Certificates",
          subtitle: "ระบบจัดการใบรับรอง (Certificates) ของพนักงาน",
          dark: true,
          color: "#385F73",
          background: "certificates.jpg",
        },
        {
          link: "admin-employee",
          title: "Employee",
          subtitle: "ข้อมูลพนักงาน",
          dark: true,
          color: "indigo darken-1",
          background: "employee.jpg",
        },
        {
          link: "admin-exam",
          title: "Exam Management",
          subtitle: "ระบบจัดการแบบทดสอบ",
          dark: true,
          color: "light-green darken-1",
          background: "exam.jpg",
        },
        {
          link: "admin-face-management",
          title: "Face Management",
          subtitle: "ระบบจัดการ การเข้าสู่ระบบด้วยใบหน้า",
          dark: true,
          color: "#385F73",
          background: "face-scan.jpg",
        },
        {
          link: "holiday",
          title: "Holiday",
          subtitle: "ระบบจัดการวันหยุด (Holiday)",
          dark: true,
          color: "pink darken-3",
          background: "holiday.jpg",
        },
        {
          link: "admin-slides",
          title: "Slides Management",
          subtitle: "ระบบจัดการประกาศข่าวสาร",
          dark: true,
          color: "blue-grey darken-1",
          background: "news.jpg",
        },
        // {
        //   link: "QCApplicant",
        //   title: "QC Applicant",
        //   subtitle: "QC job applicants system.",
        //   dark: true,
        //   color: "#952175"
        // },

        {
          link: "TeamManagement",
          title: "Team Management",
          subtitle: "ระบบจัดการทีมและสมาชิกทีมภายในบริษัท",
          dark: true,
          color: "#7e62cc",
          background: "team.jpg",
        },
        {
          link: "admin-manually",
          title: "Manually",
          subtitle: "ระบบเรียกการใช้งานคำสั่งด้วยตนเอง",
          dark: true,
          color: "#706454",
          background: "manually.jpg"
        }
      ],
    };
  },
  methods: {
    goTo(to) {
      this.$router.push({ name: to });
    },
  },
};
</script>

<style scoped lang="scss">
// 
</style>