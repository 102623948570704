import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import StoreData from './store'
import VueRouter from 'vue-router'
import {
  routes
} from './routes'
import {
  initialize
} from './helpers/general'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import VeeValidate from 'vee-validate'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vueHeadful from 'vue-headful'
import VueFroala from 'vue-froala-wysiwyg'
import VueSweetalert2 from 'vue-sweetalert2'
import VueQuillEditor from 'vue-quill-editor'
//Import Froala Editor 
import 'froala-editor/js/plugins.pkgd.min.js'
//Import third party plugins
import "froala-editor/js/third_party/embedly.min";
import "froala-editor/js/third_party/font_awesome.min";
import "froala-editor/js/third_party/spell_checker.min";
import "froala-editor/js/third_party/image_tui.min";
// Import Froala Editor css files.
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import VueApexCharts from "vue-apexcharts";
// Global CSS
import "./css/main.scss";
// components
import AdvanceFilter from './components/AdvanceFilter.vue'
import AdvanceFilterV2 from './components/AdvanceFilterV2.vue'
import AlertMessage from './components/AlertMessage.vue'

const moment = require('moment')
Vue.config.productionTip = false
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueFroala)
Vue.use(VueApexCharts)
Vue.use(VueSweetalert2);
Vue.use(VeeValidate, {
  fieldsBagName: "veeFields",
});
Vue.use(require("vue-moment"), {
  moment,
});
Vue.component("vue-headful", vueHeadful);
Vue.component("apexchart", VueApexCharts);
Vue.component("AdvanceFilter", AdvanceFilter);
Vue.component("AdvanceFilterV2", AdvanceFilterV2);
Vue.component("AlertMessage", AlertMessage);

Vue.use(Vuetify, {
  iconfont: ["mdi", "fab", "md", "mdiSvg", "fa", "fa4"], // 'md' || 'mdi' || 'fa' || 'fa4'
});

const store = new Vuex.Store(StoreData);
const router = new VueRouter({
  routes,
  mode: "history",
});

initialize(store, router);

new Vue({
  el: "#app",
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: "mdi",
    },
  }),
  created() {
    //
  },
  render: (h) => h(App),
}).$mount("#app");
