<template>
  <div class="admin">
    <div style="margin-top: 100px;">
      <Card />
    </div>
  </div>
</template>

<script>
// Component
import Card from '../components/Admin/Card'

export default {
  data() {
    return {
      email: "",
      password: "",
      height: 0,
      space: 3,
      show: false,
      error: "Email or Password invalid.",
      error_state: false,
      user: ""
    };
  },
  components: {
    Card
  },
  methods: {
    windowHeight() {
      this.height = window.innerHeight;
    }
  },
  created() {
    if (process.browser) {
      this.height = window.innerHeight;
      window.addEventListener("resize", this.windowHeight);
    }
    this.user = this.$store.getters.currentUser;
  }
};
</script>

<style scoped lang="scss">
@import "../css/transition.scss";

.login {
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
</style>