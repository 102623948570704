<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div v-for="(input,k) in addFieldEducations" :key="k">
        <v-row>
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              :items="educationLevel"
              v-model="input.educationLevel"
              :rules="nameEducation"
              label="ระดับการศึกษา"
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6">
            <v-text-field v-model="input.institution" :rules="nameInstitution" label="สถานศึกษา"></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6">
            <v-text-field v-model="input.major" :rules="nameMajor" label="สาขาวิชา"></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6">
            <v-text-field v-model="input.studyResult" :rules="nameStudyResult" label="ผลการศึกษา"></v-text-field>
          </v-col>
        </v-row>
        <div align="center">
          <v-btn small color="error" class="mx-2" @click="add(k)" v-show="(addFieldEducations.length - 1) == k">เพิ่มประวัติการศึกษา</v-btn>
          <v-btn
            small
            color="primary"
            class="mx-2"
            @click="remove(k)"
            v-show="k || ( !k && addFieldEducations.length > 1)"
          >ลบ</v-btn>
        </div>
      </div>
      <v-card-actions>
        <v-btn class="mx-2" text @click="previousState(3)">
          <v-icon style="margin-right: 5px;">mdi-arrow-left</v-icon>
          previous</v-btn>
        <v-spacer></v-spacer>
      <v-btn color="primary" text @click="validate(4)">Next
        <v-icon style="margin-left: 5px;">mdi-arrow-right</v-icon>
      </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
export default {
  updated() {
    this.$emit("education", this.$data.addFieldEducations);
  },
  data() {
    return {
      nextStep1: 3,
      valid: true,
      addFieldEducations: [
        {
          educationLevel: "",
          institution: "",
          major: "",
          studyResult: ""
        }
      ],
      educationLevel: [
        "ระดับชั้นมัธยมปลาย",
        "ปวช",
        "ปวส",
        "ปริญญาตรี",
        "ปริญญาโท",
        "ปริญญาเอก"
      ],
      nameEducation: [
        v => !!v || "Education is required"
      ],
      nameInstitution: [
        v => !!v || "Institution is required",
        v =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Institution must be in English and Thai only"
      ],
      nameMajor: [
        v => !!v || "Institution is required",
        v =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Institution must be in English and Thai only"
      ],
      nameStudyResult: [
        v => !!v || "Grade is required",
        v =>
          /^[0-4]\.\d{2}$/.test(v) ||
          "Grade does not match the pattern"
      ],
    };
  },
  methods: {
    add(k) {
      this.addFieldEducations.push({
        educationLevel: "",
        institution: "",
        major: "",
        studyResult: ""
      });
    },
    remove(index) {
      this.addFieldEducations.splice(index, 1);
    },
    previousState(e) {
      this.nextStep1 = e - 1;
      this.$emit("previousE1", parseInt(this.nextStep1));
    },
    validate(e) {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == true) {
        this.nextStep1 = e;
        this.$emit("nextE1", parseInt(this.nextStep1));
      }
    }
  }
};
</script>