<template>
  <div class="main-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Manually</v-col>
      </v-row>
    </div>
    <div class="content-area">
      <v-container>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Birthday Notify to Line</td>
                <td>ส่งการ์ดอวยพรวันเกิดไปในไลน์กลุ่ม STelligence</td>
                <td class="text-right">
                  <v-btn text rounded color="primary" @click="BirthdayAlert"
                    >Send</v-btn
                  >
                </td>
              </tr>
              <tr>
                <td><b>[Sale Orders]</b> Download All Attachment Files</td>
                <td>ดาวน์โหลดเอกสารทั้งหมดของคำสั่งขายตามประเภทที่ต้องการ</td>
                <td class="text-right">
                  <v-btn text disabled rounded color="primary" @click="SORAFDialog"
                    >Dialog</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
    </div>

    <!-- sale orders: request all attachment files -->
    <v-dialog v-model="so_raf_dialog" scrollable presistent max-width="500">
      <v-card>
        <v-card-title>Download Attachment Files</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="card-text-area">
          <div class="information-area">
            <v-row no-gutters>
              <v-col cols="12">
                <v-autocomplete
                  v-model="attachment_type_selected"
                  :items="attachment_type_items"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  chips
                  small-chips
                  label="File Type"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="so_status_selected"
                  :items="so_status_items"
                  outlined
                  dense
                  chips
                  small-chips
                  label="SO Status"
                  multiple
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" v-show="dialog_error != ''">
                <transition name="slide-fade">
                  <v-alert type="error" style="margin-top: 10px">{{
                    dialog_error
                  }}</v-alert>
                </transition>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="DownloadAllAttachmentFile"
            style="margin: 10px"
          >
            Download
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import { TpAxios } from "../../helpers/tp_axios";

export default {
  data() {
    return {
      ready: false,
      IP: IP,
      user: "",
      loading: false,
      so_raf_dialog: false,
      dialog_error: "",
      attachment_type_items: [
        { text: "Quotation file", value: "quotation" },
        { text: "PO file", value: "po" },
        { text: "Contract file", value: "contract" },
        { text: "CostSheet file", value: "cost_sheet" },
        { text: "Invoice & DO file", value: "invoice_do" },
        { text: "Others file", value: "others" },
      ],
      attachment_type_selected: [],
      so_status_items: ["Booking", "Confirm"],
      so_status_selected: [],
    };
  },
  methods: {
    BirthdayAlert() {
      console.log("birthday alert.");
      TpAxios.Get(`/manual/line-notify/birthday`).then(response => {
        console.log('res: ', response)
      })
    },
    SORAFDialog() {
      this.attachment_type_selected = [];
      this.so_status_selected = [];
      this.so_raf_dialog = true;
    },
    DownloadAllAttachmentFile() {
      console.log("download all attachment file.");
      console.log(this.attachment_type_selected)
      console.log(this.so_status_selected)
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";

.main-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}
</style>