<template>
  <div class="user-management">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      absolute
      class="navigation"
      style="position: fixed"
    >
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-menu</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="title">Menu</v-list-item-title>
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item link @click="to = 1">
          <v-list-item-icon>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Employee Information</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="to = 2">
          <v-list-item-icon>
            <v-icon>mdi-book-open</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Training Course</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="to = 3">
          <v-list-item-icon>
            <v-icon>mdi-lightbulb-on</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Skill</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="to = 4">
          <v-list-item-icon>
            <v-icon>mdi-certificate</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Certificates</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="to = 5">
          <v-list-item-icon>
            <v-icon>mdi-trophy</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Project Experience</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="to = 6" disabled>
          <v-list-item-icon>
            <v-icon disabled>mdi-lead-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Evaluation History</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- Starting -->
    <div class="header-area" v-if="to == 0">
      <div class="text-header">
        <div id="header">Employee Information</div>
        <div id="sub-header">{{ $route.params.email }}</div>
      </div>
      <div class="card-area">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              xs="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
              v-for="n in card"
              :key="n.to"
            >
              <v-card
                :color="n.color"
                :dark="n.dark"
                class="area-card hvr-bounce-in transition"
                :disabled="n.disabled"
                @click="to = n.to"
              >
                <div
                  style="
                    position: absolute;
                    z-index: -1;
                    width: 100%;
                    height: 100%;
                  "
                >
                  <v-img
                    :src="require(`../assets/card/${n.background}`)"
                    class="background-card"
                  ></v-img>
                </div>
                <v-container>
                  <v-card-title id="card-text">{{ n.header }}</v-card-title>
                  <v-card-subtitle id="card-text">{{
                    n.description
                  }}</v-card-subtitle>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <!-- To -->
    <div class="content-area">
      <EmployeeInformation :email="manage_email" v-if="to == 1" />
      <TrainingCourse :email="manage_email" v-if="to == 2" />
      <Skill :email="manage_email" v-if="to == 3" />
      <Certificates :email="manage_email" v-if="to == 4" />
      <ProjectExperience :email="manage_email" v-if="to == 5" />
      <EvaluationHistory :email="manage_email" v-if="to == 6" />
    </div>
  </div>
</template>

<script>
import EmployeeInformation from "../components/UserManagement/EmployeeInformation.vue";
import TrainingCourse from "../components/UserManagement/TrainingCourse.vue";
import Skill from "../components/UserManagement/Skill.vue";
import Certificates from "../components/UserManagement/Certificates.vue";
import EvaluationHistory from "../components/UserManagement/EvaluationHistory.vue";
import ProjectExperience from "../components/UserManagement/ProjectExperience.vue";

export default {
  data() {
    return {
      user: "",
      manage_email: "",
      drawer: true,
      mini: true,
      to: 0,
      card: [
        {
          header: "Employee Information",
          description: "ข้อมูลพนักงาน",
          to: 1,
          color: "primary",
          background: "employee-information.jpg",
          dark: true,
          disabled: false,
        },
        {
          header: "Training Course",
          description: "ข้อมูลการอบรม",
          to: 2,
          color: "success",
          background: "training-v2.jpg",
          dark: true,
          disabled: false,
        },
        {
          header: "Skill",
          description: "ความสามารถต่างๆ",
          to: 3,
          color: "amber darken-3",
          background: "skill.jpg",
          dark: true,
          disabled: false,
        },
        {
          header: "Certificates",
          description: "ใบรับรอง",
          to: 4,
          color: "#385F73",
          background: "certificates.jpg",
          dark: true,
          disabled: false,
        },
        {
          header: "Project Experience",
          description: "ประสบการณ์การทำโปรเจค",
          to: 5,
          color: "red darken-3",
          background: "project.jpg",
          dark: true,
          disabled: false,
        },
        {
          header: "Evaluation History",
          description: "ประวัติการประเมิน",
          to: 6,
          color: "deep-purple accent-3",
          background: "evaluation.jpg",
          dark: true,
          disabled: true,
        },
      ],
    };
  },
  components: {
    EmployeeInformation,
    TrainingCourse,
    Skill,
    Certificates,
    EvaluationHistory,
    ProjectExperience,
  },
  methods: {
    start() {
      if (this.$route.params.email) {
        this.user = this.$store.getters.currentUser;
        this.manage_email = this.$route.params.email;
        if (this.user.Role != "Admin") {
          if (this.user.EmployeeEmail != this.manage_email) {
            this.$router.push("/");
          }
        }
      } else {
        this.$router.push("/");
      }
    },
  },
  watch: {
    "$route.params.email": function (email) {
      this.start();
    },
  },
  created() {
    if (this.$route.params.email) {
      this.start();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
.user-management {
  width: 100%;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
}

.header-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 80px;
  padding-left: 50px;
}

.text-header {
  text-align: center;
  padding-bottom: 40px;
  border-bottom: 1px solid rgb(196, 196, 196);
}

#header {
  font-size: 40px;
}

#sub-header {
  font-size: 20px;
  color: #5a5a5a;
}

.navigation {
  padding-top: 65px;
  z-index: 50;
  transition: 0.3s all;
}
/* 
.navigation:hover{
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
} */

.content-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
  padding-left: 60px;
}

.card-area {
  margin-top: 40px;
}
</style>