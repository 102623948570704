<template>
  <div class="froala">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        v-if="user.Role != 'Admin'"
      >
        <v-text-field
          label="Title"
          outlined
          v-model="content.title"
          autofocus
          name="title"
          v-validate="'required'"
          dense
          hide-details
        ></v-text-field>
        <div class="show-path" v-if="content.title != ''">
          {{ CreatePath(this.content.title) }}
        </div>
        <transition name="slide-fade">
          <v-alert
            type="error"
            dense
            v-show="errors.has('title')"
            style="margin-top: 10px; margin-bottom: -10px"
            >{{ errors.first("title") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col v-if="user.Role == 'Admin'">
        <v-text-field
          label="Title"
          outlined
          v-model="content.title"
          autofocus
          name="title"
          v-validate="'required'"
          dense
          hide-details
        ></v-text-field>
        <div class="show-path" v-if="content.title != ''">
          {{ CreatePath(this.content.title) }}
        </div>
        <transition name="slide-fade">
          <v-alert
            type="error"
            dense
            v-show="errors.has('title')"
            style="margin-top: 10px; margin-bottom: -10px"
            >{{ errors.first("title") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col cols="auto" v-if="user.Role == 'Admin'">
        <v-checkbox
          v-model="content.official"
          class="mx-2"
          label="Official"
          hide-details
          dense
        ></v-checkbox>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <!-- <v-text-field label="Tag" outlined v-model="content.tag" name="tag" v-validate="'required'"></v-text-field> -->
        <v-combobox
          v-model="content.tag"
          label="Tag"
          outlined
          multiple
          chips
          name="tag"
          v-validate="'required'"
          dense
          hide-details
        ></v-combobox>
        <transition name="slide-fade">
          <v-alert
            type="error"
            v-show="errors.has('tag')"
            style="margin-top: 10px; margin-bottom: -10px"
            >{{ errors.first("tag") }}</v-alert
          >
        </transition>
      </v-col>
    </v-row>
    <div class="select-file">
      <v-row>
        <v-col cols="12" xs="12" sm="8" md="9" lg="10" xl="10">
          <input
            id="fileUpload"
            type="file"
            accept="image/*"
            hidden
            @change="handleFileUpload"
          />
          <v-btn block color="primary" @click="chooseFiles">
            <v-icon style="margin-right: 5px">mdi-file</v-icon>Add Image Title
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <v-btn
            block
            color="red darken-1"
            style="color: white"
            :disabled="preview == ''"
            @click="RemoveTitleImage"
            >remove image</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <v-textarea
      outlined
      label="Description"
      v-model="content.description"
      name="description"
      v-validate="'required'"
    ></v-textarea>
    <transition name="slide-fade">
      <v-alert
        type="error"
        v-show="errors.has('description')"
        style="margin-top: -10px; margin-bottom: 25px"
        >{{ errors.first("description") }}</v-alert
      >
    </transition>
    <!-- <froala id="editor" :tag="'textarea'" :config="config" v-model="content.model"></froala> -->
    <quill-editor
      id="editor"
      :tag="'textarea'"
      :config="config"
      :options="editorOption"
      v-model="content.model"
    />
    <br />
    <v-row>
      <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
        <v-select
          :items="select_status"
          outlined
          v-model="content.status"
          block
          dense
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
        <v-btn color="green darken-1" block @click="SaveContent" dense dark
          >Save</v-btn
        >
      </v-col>
    </v-row>
    <br />
    <hr />
    <br />
    <div class="title-content">{{ content.title }}</div>
    <div class="title-img">
      <v-img
        v-if="preview != ''"
        :src="preview"
        :lazy-src="preview"
        width="100%"
        max-width="700"
        class="mx-auto"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="description" v-if="content.description != ''">
      <v-icon style="margin-top: -10px" size="18">mdi-format-quote-open</v-icon>
      {{ content.description }}
      <v-icon style="margin-top: -10px" size="18"
        >mdi-format-quote-close</v-icon
      >
    </div>
    <br />
    <!-- <froalaView v-model="content.model"></froalaView> -->
    <!-- <quill-editor v-model="content.model"/> -->
    <div class="output ql-snow">
      <div class="ql-editor" v-html="content.model"></div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import VueFroala from "vue-froala-wysiwyg";
import * as blobUtil from "blob-util";
import { webURL, apiURL, IP, AuthKey } from "../../config";
import axios from "axios";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import VueQuillEditor from "vue-quill-editor";
import Quill from "quill";
import ImageResize from "quill-image-resize-module";

Quill.register("modules/imageResize", ImageResize);

export default {
  data() {
    return {
      user: "",
      loading: false,
      editorOption: {
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            toolbarButtonStyles: {
              display: "none",
            },
          },
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [
              {
                // color: [
                //   "#22bc9c",
                //   "#1ba085",
                //   "#2ecb70",
                //   "#27ae60",
                //   "#3398db",
                //   "#2a7fb9",
                //   "#9b59b6",
                //   "#8d44ad",
                //   "#34495e",
                //   "#2c3e50",
                //   "#f1c40d",
                //   "#f39b11",
                //   "#e67e22",
                //   "#d35300",
                //   "#e84c3c",
                //   "#c0392b",
                //   "#ecf0f1",
                //   "#bcc3c7",
                //   "#95a5a6",
                //   "#7e8c8d",
                // ],
                color: [],
              },
              {
                background: [],
              },
            ],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
        },
      },
      content: {
        title: "",
        tag: [],
        title_img: "",
        description: "",
        model: "Edit Your Content Here!",
        data: "",
        content_img: [],
        path: "",
        status: "Draft",
      },
      img_src: [],
      img_name: [],
      preview: "",
      config: {
        fileUpload: false,
        events: {
          content: [],
          initialized: function () {
            //   console.log("initialized");
          },
          "image.inserted": function ($img) {
            // console.log("image.inserted");
          },
        },
      },
      select_status: ["Draft", "STelligence", "Public"],
    };
  },
  methods: {
    // onEditorChange({ quill, html, text }) {
    //   console.log("editor change!", quill, html, text);
    //   // this.content = html;
    // },
    CreatePath(name) {
      var replaced = name.split(" ").join("-");
      var lower = replaced.toLowerCase();
      var date = moment(new Date())
        .tz("Asia/Bangkok")
        .format("YYYY-MM-DD-hh-mm-ss");
      var path = lower + "_" + date;
      this.content.path = path;
      return this.content.path;
    },
    SaveContent() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          Swal.fire({
            title: "คุณต้องการดำเนินการต่อหรือไม่ ?",
            text: `คุณต้องการสร้างเนื้อหาใหม่หรือไม่ !`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.loading = true;
              // console.log("Result of content before regex", this.content);
              this.RegexCutImgPathAndReturnBlobArray().then(
                async (responseBlobArray) => {
                  // console.log("Blob array response from regex cut", responseBlobArray);
                  if (responseBlobArray.length > 0) {
                    // Cases content including img
                    this.ConvertBlobToFile(responseBlobArray).then(
                      (response) => {
                        // console.log("Blob to file",response)
                        // console.log(
                        //   "Content before replace with url =",
                        //   this.content
                        // );
                        // console.log("Model before file", this.content.model);
                        this.LoopReplaceSrcImgWithLink(this.content.model).then(
                          async (response) => {
                            // console.log("After loop replace = ", this.content);
                            await this.SaveAllContent();
                          }
                        );
                      }
                    );
                  } else {
                    // All cotent is text no img
                    await this.SaveAllContent();
                  }
                }
              );
            }
          });
        }
      });
    },
    async base64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      // For img with resize
      b64Data = b64Data.split('"')[0];
      var byteCharacters = await atob(b64Data);
      var byteArrays = [];
      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = await byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = await new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = await new Blob(byteArrays, { type: contentType });
      return blob;
    },

    regexModifyIframeTag(content) {
      // Part of regex to find iframe tag
      var regex_iframe = /(?:<iframe[^>]*)(?:>)/g,
        matches_iframe = regex_iframe.exec(content);
      while (matches_iframe != null) {
        // array.push(matches_iframe[0]);
        if (matches_iframe[0].startsWith("<iframe")) {
          // console.log("Iframe=", matches_iframe[0]);
          var modify_iframe = matches_iframe[0].toString().slice(0, -1);
          modify_iframe =
            modify_iframe + ' style="width:90%;height:550px; margin:auto;">';
          // console.log("After Modify", modify_iframe);
          this.content.data = this.content.data.replace(
            matches_iframe[0],
            modify_iframe
          );
          // console.log("Content data", this.content.data);
        }
        matches_iframe = regex_iframe.exec(content);
      }
    },

    RegexCutImgPathAndReturnBlobArray() {
      return new Promise(async (resolve, reject) => {
        var str = this.content.model;
        // Part of regex to find img
        var regex = /<img [^>]*src="[^"]*"[^>]*>/gm,
          matches = regex.exec(str),
          array = [];

        while (matches != null) {
          array.push(matches[0]);
          this.img_src.push(
            matches.map((x) => x.replace(/.*src="([^"]*)".*/, "$1"))
          );
          matches = regex.exec(str);
        }
        // console.log("Array Img tag cut from content=", array);
        var blobArray = [];
        for (const key in array) {
          const element = array[key].slice(0, -2);
          var base64Data = element.split(",")[1];
          var contentType = element.slice(15).split(";")[0];
          // console.log("Base64data=", base64Data);
          // console.log("Content type=", contentType);
          var blob = await this.base64toBlob(base64Data, contentType);
          // var blobUrl = URL.createObjectURL(blob);
          blobArray.push(blob);
        }
        // console.log("Blob array =", blobArray);
        resolve(blobArray);
      });
    },
    generateID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    ConvertBlobToFile(blobArrayObj) {
      // console.log("Start covert blob to file");
      return new Promise(async (resolve, reject) => {
        var count = 0;
        var lengthArray = 0;
        if (blobArrayObj.length > 0) {
          lengthArray = blobArrayObj.length;
          for (const key in blobArrayObj) {
            const element = blobArrayObj[key];
            // console.log("Blob =",element);
            var fileName =
              this.generateID() + "." + String(element.type).split("/")[1];
            this.img_name.push(fileName);
            // console.log("Filename=", fileName);
            // console.log("Element=", element);
            // console.log("Type=", element.type);
            var file = new File([element], fileName, {
              type: element.type,
              lastModified: Date.now(),
            });
            this.content.content_img.push(file);
          }
          resolve("success");
        } else {
          resolve("success");
        }
      });
    },

    LoopReplaceSrcImgWithLink(str) {
      return new Promise(async (resolve, reject) => {
        var str_to_replace = str;
        for (let index = 0; index < this.img_src.length; index++) {
          const element = this.img_src[index];
          str_to_replace = str_to_replace.replace(
            this.img_src[index],
            `${apiURL}/news_file/${this.img_name[index]}`
          );
          //  str_to_replace = str_to_replace.replace(this.img_src[index], "123");
        }
        // console.log("Afte url replace=", str_to_replace);
        this.content.data = str_to_replace;
        this.regexModifyIframeTag(this.content.data);
        resolve("success");
      }, this);
    },
    async SaveAllContent() {
      return new Promise(async (resolve, reject) => {
        const data = new FormData();
        const content_img = this.content.content_img;
        // console.log("All content to create ", this.content);

        if (this.content.official == undefined) {
          this.content.official = false;
        }

        if (this.content.data == "") {
          this.content.data = this.content.model;
          this.regexModifyIframeTag(this.content.data);
        }
        if (content_img.length > 0) {
          for (var i = 0; i < content_img.length; i++) {
            data.append("content_img", content_img[i]);
            // console.log("Content img", i, "=", content_img[i]);
          }
        } else {
          data.append("content_img", null);
        }
        var tag = JSON.stringify(this.content.tag);

        data.append("title_img", this.content.title_img);
        data.append("title", this.content.title);
        data.append("description", this.content.description);
        data.append("data", this.content.data);
        data.append("tag", tag);
        data.append("create_by", this.user.EmployeeEmail);
        data.append("path", this.content.path);
        data.append("status", this.content.status);
        data.append("official", this.content.official);
        data.append("editortool", "quill");

        await axios
          .post(`${IP}/create-news`, data, {
            headers: {
              Authorization: AuthKey,
            },
            onUploadProgress: (ProgressEvent) => {
              console.log(
                parseInt(
                  Math.round(
                    (ProgressEvent.loaded / ProgressEvent.total) * 100
                  ) + "%"
                )
              );
            },
          })
          .then(async (response) => {
            // console.log(response.data);
            if (response.data == "success") {
              await this.clear();
              Swal.fire(
                "สร้างเนื้อหาเสร็จสิ้น !",
                `สร้างเนื้อหาเสร็จสิ้น !`,
                "success"
              );
              this.$router.push("/blog");
            }
          });
      });
    },
    ReplaceContent(str) {
      return new Promise(async (resolve, reject) => {
        var res = str.replace(`blob:${webURL}/`, `${apiURL}/news_file/`);
        resolve(res);
      });
    },
    RemoveTitleImage() {
      this.content.title_img = "";
      this.preview = "";
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    async handleFileUpload(e) {
      const file = e.target.files;
      this.content.title_img = file[0];
      this.preview = await this.toBase64(file[0]);
      // console.log(file[0]);
    },
    toBase64(file) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    clear() {
      this.content = {
        title: "",
        tag: [],
        title_img: "",
        description: "",
        model: "Edit Your Content Here!",
        data: "",
        content_img: [],
        path: "",
      };
      this.preview = "";
      this.$validator.reset();
      this.loading = false;
    },
  },
  async created() {
    this.user = this.$store.getters.currentUser;
    // console.log(this.user);
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";
.froala {
  max-width: 1200px;
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.title-content {
  font-size: 40px;
}

.title-img {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.select-file {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.description {
  width: 60%;
  min-width: 200px;
  margin: 0 auto;
  padding: 30px;
  font-size: 14px;
  color: gray;
  text-align: center;
}

.show-path {
  margin-top: -10px;
  margin-bottom: 15px;
  margin-left: 20px;
  color: gray;
}
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 40rem;
    overflow: hidden;
  }

  .output {
    width: 100%;
    height: 20rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;

    &.code {
      padding: 1rem;
      height: 16rem;
    }

    &.ql-snow {
      border-top: none;
      height: 24rem;
    }
  }
}
</style>