<template>
  <div class="quiz">
    <div v-if="items.sectionType === 'Writing'">
      <v-container>
        <v-row justify="center">
          <!-- <p class="title text-center">{{ items[0]["testName"] }}</p> -->
        </v-row>

        <v-alert v-if="this.time === 0" dense border="left" type="warning">
          หมดเวลาทำข้อสอบ หลังจากหมดเวลาไม่สามารถเปลี่ยนคำตอบได้
          สามารถส่งข้อสอบโดยการกด submit ด้านล่าง
        </v-alert>
        <v-row>
          <!-- <span>Time : {{ this.time | prettify }}</span> -->
          <Timer :time="prettyTime"></Timer>
        </v-row>
        <v-row>
          <span class="font-weight-bold">{{ items.sectionType }}:</span>
          <span>{{ questions[0].content }}</span>
        </v-row>
        <!-- <v-row>Total time: {{ items[0].testTime[0] }} minutes</v-row> -->
        <v-row></v-row>
        <v-radio-group v-model="topicsGroup">
          <div @click="showAnswer">
            <v-radio
              v-for="choice in choices[0]"
              :key="choice.ChoiceId"
              :label="choice.ChoiceContent"
              :value="choice.ChoiceId"
              :isShowAnswer="isShowAnswer"
              v-model="answer.topicId"
            ></v-radio>
          </div>
        </v-radio-group>

        <v-textarea
          v-if="isShowAnswer"
          class="answerBlock"
          outlined
          name="input"
          label="Answer"
          value
          v-model="textField"
        ></v-textarea>
        <v-layout column wrap>
          <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="400">
              <template v-slot:activator="{ on }">
                <div class="my-5">
                  <v-btn color="primary" dark v-on="on">Submit</v-btn>
                </div>
              </template>
              <v-card>
                <v-card-title class="headline"
                  >Do you submit answer ?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="green darken-1" text @click="onSubmitWriting"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-layout>
      </v-container>
    </div>

    <div
      v-if="items.sectionType === 'Reading' || items.sectionType === 'Logical'"
    >
      <v-container>
        <v-row justify="center">
          <!-- <p class="title text-center">{{ items[0]["testName"] }}</p> -->
          <!-- <v-col cols="12">
            <img :src="this.srcimg" class="img"/>
          </v-col>-->
        </v-row>
        <v-alert v-if="this.time === 0" dense border="left" type="warning">
          หมดเวลาทำข้อสอบ หลังจากหมดเวลาไม่สามารถเปลี่ยนคำตอบได้
          สามารถส่งข้อสอบโดยการกด submit ด้านล่าง
        </v-alert>
        <v-row>
          <Timer :time="prettyTime"></Timer>
        </v-row>
        <v-row>
          <span class="font-weight-bold">{{ items.sectionType }}:</span>
          <!-- <span>{{ items[0]["sectionDescription"] }}</span> -->
        </v-row>
        <!-- <v-row>Total time: {{ items[0].testTime[1] }} minutes</v-row> -->
        <v-row v-if="items.sectionType === 'Reading'">{{ passage }}</v-row>
        <br />
        <br />
        <v-row></v-row>
        <v-row>Question (1-5)</v-row>

        <v-layout column wrap>
          <div v-for="(question, index) in questions" :key="index">
            <Question
              :id="index + 1"
              :questionId="question.questionId"
              :subject="question.content"
              :choices="choices[index]"
              :src="question.image"
              :sectionType="items.sectionType"
              @check="check"
            />
          </div>

          <v-row justify="center">
            <v-dialog v-model="dialog" persistent max-width="400">
              <template v-slot:activator="{ on }">
                <div class="my-5">
                  <v-btn color="primary" dark v-on="on">Submit</v-btn>
                </div>
              </template>
              <v-card>
                <v-card-title class="headline"
                  >Do you submit answer ?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="dialog = false"
                    >Cancel</v-btn
                  >

                  <v-btn color="green darken-1" text @click="onSubmitReading"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import Question from "@/components/POC/Question.vue";
import Timer from "@/components/POC/Timer.vue";
import axios from "axios";
import { IP, AuthKey } from "../../config";

export default {
  name: "writingTest",
  components: {
    Question,
    Timer,
  },
  computed: {
    prettyTime() {
      // localStorage.removeItem('time')

      let time = this.time / 60;
      let minutes = parseInt(time);
      let seconds = Math.round((time - minutes) * 60);
      // let timeout =
      // if (minutes == 0 && seconds == 0) {
      //   console.log("timeout", "timeout");
      // }
      // console.log('minutes', minutes + ":" + seconds)
      return minutes + ":" + seconds;
    },
    answerChoice() {
      let n = this.items.questionId.length;
      console.log("object", this.items.questionId);
      let answerChoice = [];
      for (let i = 0; i < n; i++) answerChoice.push({});
      return answerChoice;
    },
  },
  created() {
    // if (this.$router.currentRoute.path != "/quiz") {
    //   this.$router.push("/quiz");
    // }
    this.GetQuestion();
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  },
  mounted() {
    this.time = this.$route.params.time * 60;
    this.start();
  },
  updated() {
    if (this.time !== 0) {
      this.answer.textField = this.textField;
    }
  },
  data() {
    return {
      srcimg: require("../../assets/stel-logo-black.png"),
      //Section
      choices: [],
      questions: [],
      questionImage: [],
      //items
      items: {},
      passage: {},
      //timer
      isRunning: false,
      time: 0,
      minutes: 0,
      seconds: 0,
      timer: null,
      //Writing Test
      dialog: false,
      topicsGroup: "",
      textField: "",
      answer: {
        topicId: "",
        textField: "",
      },
      isShowAnswer: false,
      instruction: [
        "writing test includes 3 Topics and you should choose only one topic for writing essay",
      ],
    };
  },
  methods: {
    start() {
      this.isRunning = true;
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    check(value, id) {
      if (this.time !== 0) {
        this.answerChoice[id - 1].questionId = id;
        this.answerChoice[id - 1].choiceId = value;
      }
    },
    async GetQuestion() {
      if (this.$route.params.time != undefined) {
        return new Promise(async (resolve, reject) => {
          axios({
            method: "get",
            url: IP + `/api/v1/quiz-page/quiz/${this.$route.params.url}`,
            headers: {
              Authorization: AuthKey,
            },
          })
            .then((response) => {
              this.items = response.data;
              this.passage = response.data.passage;
              this.questions = response.data.question;
              // console.log("items", this.items);
            })
            .then((response) => {
              this.items.questionId.forEach((questionId) => {
                this.GetChoice(questionId);
              });
            });
          // window.history.forward(1)
        });
      }
      this.$router.push("/quiz-list");
    },
    async GetChoice(questionId) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "get",
          url: IP + `/api/v1/quiz-page/quiz/question/${questionId}`,
          headers: {
            Authorization: AuthKey,
          },
        }).then((resp) => {
          this.choices.push(resp.data);
          console.log(this.choices);
        });
      });
    },
    onWrtingTest() {
      this.dailogWritingTest = false;
      this.isTestWriting = true;
    },
    showAnswer() {
      this.isShowAnswer = true;
      this.answer.topicId = this.topicsGroup;
    },
    async onSubmitAnswer() {
      if (this.items.sectionType === "Writing") {
        onSubmitWriting();
      } else if (this.items.sectionType === "Reading") {
        onSubmitReading();
      }
    },
    async onSubmitWriting() {
      return new Promise((resolve, reject) => {
        let data = {
          choiceId: this.answer.topicId,
          answerText: this.answer.textField,
          answer: null,
          applicantId: localStorage.getItem("empId"),
          sectionType: this.items.sectionType,
          quizName: localStorage.getItem("QuizName"),
        };
        axios({
          method: "post",
          url: IP + "/api/v1/quiz-page/quiz/answer",
          headers: {
            Authorization: AuthKey,
          },
          data: data,
        }).then((response) => {
          const data = response.data;
          resolve(data);
          this.dialog = false;

          if (localStorage.getItem("QuizId") == 1)
            this.$store.commit("SET_WRITINGENGFULL", true);
          else if (localStorage.getItem("QuizId") == 3)
            this.$store.commit("SET_WRITINGENGSHORT", true);

          this.$router.push({
            name: "quiz-page",
            params: { url: localStorage.getItem("QuizId") },
          });
        });

        // console.log("data", data);
      });
    },
    async onSubmitReading() {
      return new Promise((resolve, reject) => {
        let data = {
          answer: this.answerChoice,
          applicantId: localStorage.getItem("empId"),
          sectionType: this.items.sectionType,
          quizName: localStorage.getItem("QuizName"),
        };
        axios({
          method: "post",
          url: IP + "/api/v1/quiz-page/quiz/answer",
          headers: {
            Authorization: AuthKey,
          },
          data: data,
        }).then((response) => {
          const data = response.data;
          resolve(data);
          this.dialog = false;
          if (
            this.items.sectionType == "Reading" &&
            localStorage.getItem("QuizId") == 1
          )
            this.$store.commit("SET_READINGENGFULL", true);
          else if (
            this.items.sectionType == "Reading" &&
            localStorage.getItem("QuizId") == 3
          )
            this.$store.commit("SET_READINGENGSHORT", true);
          else if (
            this.items.sectionType == "Logical" &&
            localStorage.getItem("QuizId") == 2
          )
            this.$store.commit("SET_LOGICAL", true);

          this.$router.push({
            name: "quiz-page",
            params: { url: localStorage.getItem("QuizId") },
          });
        });

        // console.log("data", data);
      });
    },
  },
};
</script>

<style>
.answerBlock {
  width: 780px;
}
.quiz {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
}
</style>
