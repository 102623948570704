const userStr = localStorage.getItem("user")
// permissions
const sale_orders_permissions = require('../json/permissions/sale_orders.json')
// roles
const sale_orders_roles = require('../json/roles/sale_orders.json')

let permission_items = {
    SaleOrders: sale_orders_permissions
}

let role_items = {
    SaleOrders: sale_orders_roles
}

var user = null
var access = null
if(userStr != undefined && userStr != null && userStr != ""){
    user = JSON.parse(userStr)
    // console.log(user.AccessRightsJSON)
    access = JSON.parse(user.AccessRightsJSON)
    // console.log(access)
}

const Permissions = {
    GetRoleAndPermissions(module){
        return new Promise(async (resolve ,reject) => {
            let obj = {}
            let md = await this.CheckModule(module)
            if(md != null){
                let role = await role_items[module].find(x => x.id === md.value)
                let permission = await this.GetAllPermissions(role, module)
                obj = {
                    role: role,
                    permissions: permission
                }
                resolve(obj)
            }else{
                resolve(obj)
            }
        })
    },
    CheckModule(module){
        return new Promise(async (resolve, reject) => {
            let obj = null
            for(var i = 0; i < access.length; i++){
                if(access[i][module] != undefined){
                    md = access[i]
                    obj = {
                        module: module,
                        value: access[i][module]
                    }
                }
            }
            resolve(obj)
        })
    },
    GetAllPermissions(role, module){
        return new Promise(async (resolve, reject) => {
            let array = []
            if(role.id === 99){
                array = permission_items[module]
            }else{
                for(var i = 0; i < role.permission.length; i++){
                    for(var x = 0; x < permission_items[module].length; x++){
                        if(role.permission[i] === permission_items[module][x].id){
                            array.push(permission_items[module][x])
                        }
                    }
                }
            }
            let sp = null
            if(role.specific != undefined){
                for(var y = 0; y < role.specific.length; y++){
                    sp = array.find(x => x.id === role.specific[y].id)
                    sp.specific = role.specific[y].value
                    delete sp.specific_default
                }
            }
            for(var z = 0; z < array.length; z++){
                if(array[z].specific_default != undefined){
                    array[z].specific = array[z].specific_default
                    delete array[z].specific_default
                }
            }
            resolve(array)
        })
    },
    SetAccess(user_permission, permission){
        return new Promise(async (resolve, reject) => {
            // console.log('user_permission: ', user_permission)
            // console.log('permission: ', permission)
            let permissions = user_permission.permissions;
            for (let key in permission) {
                let obj = permission[key];
                for (var i = 0; i < permissions.length; i++) {
                    for (var x = 0; x < obj.id.length; x++) {
                        // normal
                        if (permissions[i].id === obj.id[x]) {
                            // set specific
                            if(permissions[i].specific != undefined && permission[key].specific != undefined){
                                for(let s_key in permission[key].specific){
                                    if(permissions[i].specific === 'all'){
                                        permission[key].specific[s_key] = true;
                                    }else{
                                        if(permissions[i].specific.length > 0){
                                            for(var y = 0; y < permissions[i].specific.length; y++){
                                                if(s_key === permissions[i].specific[y]){
                                                    permission[key].specific[s_key] = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            // set value
                            // value is false
                            if (permission[key].value === false) {
                                permission[key].value = true;
                            }
                            // value is string
                            if (permission[key].value === "") {
                                permission[key].value = permissions[i].specific;
                            }
                        }
                    }
                }
            }
            // console.log('permission: ', permission)
            resolve(permission);
        })
    }
}

module.exports = {
    Permissions
}