<template>
  <div class="chat-gpt">
    <v-container>
      <div class="text-header-area">
        <div id="text-header">Welcome to Chat GPT Features</div>
        <div id="text-subheader">
          สำรวจฟีเจอร์หลากหลายที่ต่อยอดการทำงานของ
          <v-chip class="ma-2" color="#11A281" dark>
            <a
              href="https://openai.com/blog/chatgpt/"
              style="color: white; text-decoration: none"
              >Chat GPT</a
            >
          </v-chip>
        </div>
      </div>
      <v-row no-gutters>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          v-for="n in menu"
          :key="n.link"
        >
          <v-container>
            <v-card
              :color="n.color"
              :dark="n.dark"
              class="area-card hvr-bounce-in transition"
              @click="goTo(n.link)"
            >
              <div id="chip-on-card">
                <v-chip small label :color="n.from_color"> {{ n.from }} </v-chip>
              </div>
              <div
                style="
                  position: absolute;
                  z-index: -1;
                  width: 100%;
                  height: 100%;
                "
              >
                <v-img
                  :src="require(`../assets/card/${n.background}`)"
                  class="background-card"
                ></v-img>
              </div>
              <v-container>
                <v-card-title id="card-text" class="headline">{{
                  n.title
                }}</v-card-title>
                <v-card-subtitle id="card-text">{{
                  n.subtitle
                }}</v-card-subtitle>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          link: "chat-gpt-create-completion",
          title: "Create Completion",
          subtitle: "ถาม Chat GPT กันมั้ย",
          dark: true,
          color: "#0B5295",
          background: "ask.jpg",
          from: "azure openai",
          from_color: "#0B5295",
        },
        {
          link: "chat-gpt-cv-job-validation",
          title: "CV & Job Validation",
          subtitle: "ระบบแนะนำความเหมาะสมของ CV & Job Description",
          dark: true,
          color: "#0B5295",
          background: "cv.jpg",
          from: "azure openai",
          from_color: "#0B5295",
        },
        {
          link: "chat-gpt-create-image",
          title: "Image Generation",
          subtitle: "ระบบสร้างรูปภาพโดย Chat GPT",
          dark: true,
          color: "#0F9B79",
          background: "photos.jpg",
          from: "openai",
          from_color: "#0F9B79",
        },
      ],
    };
  },
  methods: {
    goTo(to) {
      this.$router.push({ name: to });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-gpt {
  margin-top: 100px;
}
.text-header-area {
  text-align: center;
  padding: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(220, 220, 220);
}
#text-header {
  font-size: 40px;
}
#text-subheader {
  font-size: 18px;
}

#chip-on-card{
  position: absolute;
  right: 0;
  margin-top: -20px;
}
</style>