<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-container>
      <div class="content-area">
        <v-row style="border-bottom: 1px solid rgb(216, 216, 216)">
          <v-col cols="auto" style="font-size: 30px">Exams</v-col>
          <v-col style="width: 100%; padding: 0px 20px 0px 20px">
            <v-text-field
              v-model="search"
              label="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- data table -->
        <div class="data-table">
          <div v-if="listItems.length == 0">
            <p style="font-size: 20px; text-align: center; margin: 40px">
              {{ message }}
            </p>
          </div>
          <br />
          <v-data-table
            :headers="headers"
            :items="listItems"
            :search="search"
            class="elevation-1"
            v-if="listItems.length > 0"
          >
            <template v-slot:item.TimingType="{ item }">
              <div v-if="item.TimingType == 'unlimited'">Unlimited</div>
              <div v-if="item.TimingType == 'limited'">
                {{ item.TimingInterval }} Hr.
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                color="success"
                @click="TakeExam(item.ID)"
                v-if="item.ExamBegin == null"
                >take exam</v-btn
              >
              <v-btn
                color="primary"
                @click="TakeExam(item.ID)"
                v-if="item.ExamBegin != null"
                >continue exam</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";

export default {
  data() {
    return {
      loading: false,
      search: "",
      listItems: [],
      headers: [
        { text: "Exam Name", value: "ExamTitle" },
        { text: "Duration", value: "TimingType" },
        { text: "Actions", align: "right", value: "action", sortable: false },
      ],
      message: "No exam yet.",
    };
  },
  props: ["email"],
  methods: {
    TakeExam(id) {
      this.$router.push({
        name: "exam-take",
        params: {
          id: id,
        },
      });
    },
    GetExamByEmail() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "POST",
          url: `${IP}/exam/get-by-email`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.email,
          },
        }).then((response) => {
          console.log("response: ", response.data);
          this.listItems = response.data;
          resolve(true);
        });
      });
    },
    Start() {
      this.message = "Loading...";
      // console.log("email: ", this.email);
      this.GetExamByEmail().then((response) => {
        this.message = "No exam yet.";
      });
    },
  },
  created() {
    this.Start();
  },
};
</script>

<style lang="scss" scoped>
.content-area {
  width: 95%;
  margin: 0 auto;
}
</style>