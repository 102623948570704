<template>
  <div class="quiz">
    <v-container>
      <v-card class="mx-auto" max-width="100%" outlined>
        <div align="center" style="padding:1rem;">
          <v-list-item-title class="headline mb-1">Quiz</v-list-item-title>
        </div>
        <v-row class="fill-height" align="center" justify="center" style="padding:1rem;">
          <template v-for="(item) in items">
            <v-col :key="item.QuizId" cols="12" md="4">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  :elevation="hover ? 12 : 2"
                  :class="{ 'on-hover': hover }"
                 
                >
                  <v-card-title class="title">
                    <v-row class="fill-height flex-column" justify="space-between">
                      <p class="mt-4 subheading text-center">{{ item.QuizName }}</p>
                      
                      <div align="center">
                        <v-btn color="primary" dark @click="goTo(item)">Test</v-btn>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </template>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
export default {
  data() {
    return {
      items: [],
      status:'Pending'
    };
  },
  methods: {
    initialize() {
      axios({
        url: IP + "/api/v1/quiz-list-page",
        headers: {
          Authorization: AuthKey,
        },
        method: "get"
      }).then(resp => {
        this.items = resp.data;
        // console.log('object', this.items)
      });
    },
    goTo(item) {
      localStorage.setItem('QuizId',item.QuizId)
      this.$router.push({ name: "quiz-page", params: { url: item.QuizId } });
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style scoped lang="scss">
.quiz {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
}
</style>