<template>
  <div class="chat-gpt">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <v-container class="content-area">
      <div class="text-header-area">
        <div id="text-header">
          <!-- <v-chip
            class="ma-2"
            color="#11A281"
            dark
            style="position: absolute; transform: translate(-100px, -20px)"
          >
            <a
              href="https://openai.com/blog/chatgpt/"
              style="color: white; text-decoration: none"
              >Chat GPT</a
            >
          </v-chip> -->
          CV & Job Validation
        </div>
        <div id="text-subheader">
          ระบบแนะนำความเหมาะสมของ CV และ Job Description.
        </div>
      </div>
      <div class="content-area">
        <v-row>
          <v-col cols="12">
            <v-select
              item-text="text"
              item-value="value"
              :items="action_items"
              v-model="action"
              label="Action"
              outlined
              name="action"
              dense
              hide-details
              v-on:change="ChangeAction"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <CVsJob v-if="action == 'which-cv-suitable-jobdesc'" :key="CVsJobKey" />
            <JobsCV v-if="action == 'which-jobdesc-suitable-cv'" :key="JobsCVKey" />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import CVsJob from "../../components/ChatGPT/CVJobValidation/CVsJob.vue";
import JobsCV from "../../components/ChatGPT/CVJobValidation/JobsCV.vue";
import { Others } from "../../function/Others";

export default {
  data() {
    return {
      loading: false,
      action: "which-cv-suitable-jobdesc",
      action_items: [
        {
          value: "which-cv-suitable-jobdesc",
          text: "Which CV is suitable for the Job Description",
        },
        {
          value: "which-jobdesc-suitable-cv",
          text: "Which Job Description is suitable for the CV",
        },
      ],
      CVsJobKey: "",
      JobsCVKey: "",
    };
  },
  components: {
    CVsJob,
    JobsCV,
  },
  methods: {
    ChangeAction(action){
      if(action == 'which-cv-suitable-jobdesc'){
        this.CVsJobKey = Others.GenerateKeyID(10)
      }else if(action == 'which-jobdesc-suitable-cv'){
        this.JobsCVKey = Others.GenerateKeyID(10)
      }
    },
    Start(){
      this.CVsJobKey = Others.GenerateKeyID(10)
      this.JobsCVKey = Others.GenerateKeyID(10)
    }
  },
  created(){
    this.Start();
  }
};
</script>

<style lang="scss" scoped>
.content-area{
  max-width: 1200px;
  margin: 0 auto;
}

.chat-gpt {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
}
.text-header-area {
  text-align: center;
  padding: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(220, 220, 220);
}
#text-header {
  font-size: 40px;
}
#text-subheader {
  font-size: 18px;
}
</style>