<template>
  <div class="footer">© 2020 by STelligence Co., Ltd. All rights reserved</div>
</template>

<script>
import { WindowResize } from "../function/WindowResize";

export default {
  data() {
    return {
      height: 0,
      width: 0
    };
  },
  methods: {
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    }
  },
  created() {
    this.resize();
  }
};
</script>

<style scoped>
.footer {
  width: 100%;
  background: rgb(51, 51, 51);
  color: white;
  text-align: center;
  padding: 25px;
}
</style>