<template>
  <div class="employee">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Employee</v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn color="success" block @click="dialog = !dialog">
            <v-icon class="mr-2">mdi-plus</v-icon>Create
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn color="success" block @click="dowloadAsCSV">
            <v-icon class="mr-2">mdi-arrow-down-bold-box</v-icon>CSV
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px">
        ไม่มีพนักงาน | มีปัญหาในการเชื่อมต่อ
      </p>
    </div>
    <div v-if="listItems.length > 0">
      <br />
      <AdvanceFilter
        :headers="headers"
        :FilterData="FilterItems"
        @update-data="UpdateData"
      />
      <v-card-title>
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listItems"
        :search="search"
        class="elevation-1"
        v-if="listItems.length > 0"
        style="margin-bottom: 50px"
      >
        <template v-slot:item.Status="{ item }">
          <v-chip class="ma-2" color="success" v-if="item.Status == 'Active'">{{
            item.Status
          }}</v-chip>
          <v-chip class="ma-2" color="error" v-if="item.Status == 'Inactive'">{{
            item.Status
          }}</v-chip>
          <v-chip
            class="ma-2"
            color="warning"
            v-if="item.Status != 'Inactive' && item.Status != 'Active'"
            >Unknow</v-chip
          >
        </template>
        <template v-slot:item.Progress="{ item }">
          <v-chip class="ma-2" color="error" v-if="item.Progress < 30"
            >{{ item.Progress }}%</v-chip
          >
          <v-chip
            class="ma-2"
            color="warning"
            v-if="item.Progress >= 30 && item.Progress < 50"
            >{{ item.Progress }}%</v-chip
          >
          <v-chip
            class="ma-2"
            color="primary"
            v-if="item.Progress >= 50 && item.Progress < 80"
            >{{ item.Progress }}%</v-chip
          >
          <v-chip class="ma-2" color="success" v-if="item.Progress >= 80"
            >{{ item.Progress }}%</v-chip
          >
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                style="cursor: pointer"
                @click="UserManagement(item.EmployeeEmail)"
                >edit</v-icon
              >
            </template>
            <span>Update</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="DeleteEmployee(item)"
                style="cursor: pointer"
                >delete</v-icon
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <!-- dialog -->
    <v-dialog v-model="dialog" width="100%" max-width="500">
      <v-card>
        <v-card-title>Add Employee</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
              <v-text-field
                label="Email"
                outlined
                v-model="employee.email"
                name="email"
                v-validate="'required|email'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('email')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("email") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
              <v-text-field
                label="ID"
                outlined
                v-model="employee.id"
                name="id"
                v-validate="'required|numeric'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('id')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("id") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                label="Name"
                outlined
                v-model="employee.name"
                name="name"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('name')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("name") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                label="Nickname"
                outlined
                v-model="employee.nickname"
                name="nickname"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('nickname')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("nickname") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-menu
                v-model="menu_start_date"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="employee.start_date"
                    label="Start Date"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    name="start-date"
                    v-validate="'required'"
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="employee.start_date"
                  @input="menu_start_date = false"
                ></v-date-picker>
              </v-menu>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('start-date')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("start-date") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <!-- select team -->
              <v-select
                :items="teamItems"
                label="Team"
                outlined
                v-model="employee.team"
                item-text="TeamName"
                item-value="TeamName"
                v-validate="'required'"
                name="team"
                dense
                hide-details
              ></v-select>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('team')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("team") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <!-- select role -->
              <v-select
                :items="teamRole"
                label="Team Role"
                outlined
                v-model="employee.team_role"
                v-validate="'required'"
                name="team_role"
                dense
                hide-details
              ></v-select>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('team_role')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("team_role") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-if="error != ''"
                  dense
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ error }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="success" @click="CreateEmployee" style="margin: 10px">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../../config";
import { Others } from "../../function/Others";

export default {
  data() {
    return {
      IP: IP,
      listItems: [],
      user: "",
      headers: [
        { text: "Name", value: "EmployeeName" },
        { text: "Nick Name", value: "NickName" },
        { text: "Email", value: "EmployeeEmail" },
        { text: "Status", value: "Status" },
        { text: "Progress", value: "Progress" },
        { text: "Actions", value: "action", sortable: false },
      ],
      search: "",
      loading: false,
      dialog: false,
      menu_start_date: false,
      employee: {
        team_role: "Member",
      },
      error: "",
      FilterItems: [],
      teamItems: [],
      teamRole: ["Member", "Manager"],
    };
  },
  methods: {
    UpdateData(result) {
      if (result.status == true) {
        this.listItems = JSON.parse(result.data);
      } else {
        this.listItems = JSON.parse(this.FilterItems);
      }
    },
    CreateEmployee() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.error = "";
          this.CheckEmployeeEmailandID().then((response) => {
            if (response) {
              this.error = "Email or EmployeeID has been already.";
            } else {
              Swal.fire({
                title: "Are you sure ?",
                text: "คุณต้องการบันทึกข้อมูลพนักงานหรือไม่ !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              }).then(async (result) => {
                if (result.value) {
                  this.CreateEmployeeProcess().then(async (response) => {
                    if (response == "success") {
                      await this.Clear();
                      await this.GetAllEmployeeInformation();
                      await this.GetAllTeamInformation();
                      Swal.fire("บันทึกข้อมูลเสร็จสิ้น !", "", "success");
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    CheckEmployeeEmailandID() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/check-email-and-id",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            employee: this.employee,
          },
        }).then((response) => {
          if (response.data.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    CreateEmployeeProcess() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/create-employee",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            employee: this.employee,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    DeleteEmployee(data) {
      Swal.fire({
        title: "Are you sure ?",
        text: "คุณต้องการลบข้อมูลพนักงานหรือไม่ !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          axios({
            method: "post",
            url: IP + "/user-management/delete-employee",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              employee: data,
            },
          }).then(async (response) => {
            if (response.data == "success") {
              await this.Clear();
              await this.GetAllEmployeeInformation();
              Swal.fire("ลบข้อมูลเสร็จสิ้น !", "", "success");
            }
          });
        }
      });
    },
    async dowloadAsCSV() {
      const headerDataJson = {
        // Key : firstColumnDisplay
        EmployeeName: "Name",
        NickName: "Nick Name",
        EmployeeEmail: "Email",
        Status: "Status",
        Progress: "Progress",
      };
      await Others.dowloadCSV(headerDataJson, this.listItems, "EmployeeList");
    },
    UserManagement(email) {
      this.$router.push({
        name: "user-management",
        params: {
          email: email,
        },
      });
    },
    GetAllEmployeeInformation() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "get",
          url: IP + "/user-management/get-all-employee",
          headers: {
            Authorization: AuthKey,
          },
        }).then((response) => {
          this.CalculateProgress(response.data).then((response) => {
            this.listItems = response;
            this.FilterItems = JSON.stringify(response);
            resolve(true);
          });
        });
      });
    },
    GetAllTeamInformation() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "get",
          url: `${IP}/user-management/get-all-team`,
          headers: {
            Authorization: AuthKey,
          },
        }).then((response) => {
          this.teamItems = response.data;
          resolve(true);
        });
      });
    },
    async CalculateProgress(data) {
      return new Promise(async (resolve, reject) => {
        var num = Object.keys(data[0]).length;
        var key_name = Object.keys(data[0]);

        data.forEach(function (val, i) {
          var x = 0;
          key_name.forEach(function (val_a, i) {
            if (
              val[val_a] != null &&
              val[val_a] != "" &&
              val[val_a] != "null"
            ) {
              x++;
            }
          }, this);
          var sum = (x / key_name.length) * 100;
          val.Progress = parseInt(sum);
        });
        resolve(data);
      });
    },
    Clear() {
      this.employee = {
        team_role: "Member",
      };
      this.error = "";
      this.dialog = false;
    },
    async start() {
      this.loading = true;
      await this.GetAllEmployeeInformation().then(async (response) => {
        await this.GetAllTeamInformation().then((response) => {
          var that = this;
          // console.log("Item",this.listItems)
          setTimeout(function () {
            that.loading = false;
          }, 1000);
        });
      });
    },
  },
  created() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";

.employee {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.expansion-panels {
  margin-bottom: 30px;
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>