<template>
  <!-- 5 -->
  <div class="loader loader--style5" title="4">
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="`${wd}px`"
      :height="`${hi}px`"
      viewBox="0 0 24 30"
      style="enable-background: new 0 0 50 50"
      xml:space="preserve"
    >
      <rect x="0" y="0" width="4" height="10" :fill="co">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="translate"
          values="0 0; 0 10; 0 0"
          begin="0"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="10" y="0" width="4" height="10" :fill="co">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="translate"
          values="0 0; 0 10; 0 0"
          begin="0.2s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
      <rect x="20" y="0" width="4" height="10" :fill="co">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="translate"
          values="0 0; 0 10; 0 0"
          begin="0.4s"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wd: this.width || 48,
      hi: this.height || 60,
      co: this.color || "#333",
    };
  },
  props: ["width", "height", "color"],
};
</script>

<style scoped>
/* body {
  padding: 1em;
  background: #2b3134;
  color: #777;
  text-align: center;
  font-family: "Gill sans", sans-serif;
  width: 100%;
  margin: 0 auto;
} */
h1 {
  /* margin: 1em 0; */
  /* border-bottom: 1px dashed; */
  /* padding-bottom: 1em; */
  font-weight: lighter;
}
p {
  font-style: italic;
}
.loader {
  /* margin: 0 0 2em; */
  /* height: 100px; */
  /* width: 20%; */
  text-align: center;
  padding: 1em;
  /* margin: 0 auto 1em; */
  display: inline-block;
  vertical-align: top;
}

/*
  Set the color of the icon
*/
svg path,
svg rect {
  /* fill: #ffffff; */
}
</style>