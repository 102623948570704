<template>
    <div class="parent">
        <!-- <br>
        <p>Parent : </p>
        {{ array }}
        <br>
        <v-text-field v-model="array[0].name" outlined></v-text-field>
        <p>Child : </p>
        <Child v-bind:text="array" /> -->
        <h3>Object</h3>
        <p>{{ object }}</p>
        <v-text-field v-model="object.name" outlined></v-text-field>
        <v-text-field v-model="object.surname" outlined></v-text-field>
        <h3>Template</h3>
        <p>{{ template }}</p>
        <v-btn color="primary" @click="SetTemplate" style="margin-right: 15px;">set template</v-btn>
        <v-btn color="primary" @click="Reset">reset</v-btn>
    </div>
</template>

<script>

import Child from './Child'

export default {
    data(){
        return {
            text: 'default',
            array: [{
                name: 'name',
                surname: 'surname'
            }],
            object: {
                name: 'name',
                surname: 'surname'
            },
            template: {}
        }
    },
    components: {
        Child
    },
    methods: {
        SetTemplate(){
            this.template = JSON.parse(JSON.stringify(this.object))
        },
        Reset(){
            this.object = JSON.parse(JSON.stringify(this.template))
        }
        // UpdateData(value){
        //     console.log('result: ', value)
        // }
    }
}
</script>