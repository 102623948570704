const Filter = {
    Filter(obj) {
        return new Promise(async (resolve, reject) => {
            // header : header name
            // operator : >= , <= , == , !=, like, startwith, endwith
            // condition : int , int , (int , string) , string, date, date
            // console.log('filter obj: ', obj)

            const filter = obj.Filter
            let data
            try {
                data = JSON.parse(obj.Data)
            } catch (e) {
                data = obj.Data
            }
            // for filter
            for (var i = 0; i < filter.length; i++) {
                var x = data.length

                // for data length
                while (x--) {
                    var item = null
                    var condition = null
                    var y = 0
                    var split_status = false
                    var count = 0

                    if (filter[i].Operator == '<=') {
                        for (y = 0; y < filter[i].Condition.length; y++) {
                            item = parseInt(data[x][filter[i].Headers])
                            condition = parseInt(filter[i].Condition[y])
                            if (isNaN(item) || isNaN(condition)) {
                                data.splice(x, 1);
                            } else {
                                if (!(item <= condition)) {
                                    data.splice(x, 1);
                                }
                            }
                        }
                    } else if (filter[i].Operator == '>=') {
                        for (y = 0; y < filter[i].Condition.length; y++) {
                            item = parseInt(data[x][filter[i].Headers])
                            condition = parseInt(filter[i].Condition[y])
                            if (isNaN(item) || isNaN(condition)) {
                                data.splice(x, 1);
                            } else {
                                if (!(item >= condition)) {
                                    data.splice(x, 1);
                                }
                            }
                        }
                    } else if (filter[i].Operator == '==') {
                        count = filter[i].Condition.length
                        split_status = false
                        for (y = 0; y < filter[i].Condition.length; y++) {
                            if(filter[i].Condition[y].toLowerCase() == 'null'){
                                if(data[x][filter[i].Headers] == null){
                                    count--
                                }
                            }else if (data[x][filter[i].Headers] == filter[i].Condition[y]) {
                                count--
                            }
                        }

                        if (count == filter[i].Condition.length) {
                            data.splice(x, 1);
                        }
                    } else if (filter[i].Operator == '!=') {
                        count = filter[i].Condition.length
                        split_status = false
                        for (y = 0; y < filter[i].Condition.length; y++) {
                            if(filter[i].Condition[y].toLowerCase() == 'null'){
                                if(data[x][filter[i].Headers] != null){
                                    console.log('test: ', data[x][filter[i].Headers])
                                    count--
                                }
                            }else if (data[x][filter[i].Headers] != filter[i].Condition[y]) {
                                count--
                            }
                        }

                        if (count == filter[i].Condition.length) {
                            data.splice(x, 1);
                        }
                    } else if (filter[i].Operator == 'LIKE') {
                        count = filter[i].Condition.length
                        split_status = false
                        for (y = 0; y < filter[i].Condition.length; y++) {
                            if (data[x][filter[i].Headers] != null) {
                                check = data[x][filter[i].Headers].toLowerCase().includes(filter[i].Condition[y].toLowerCase())
                                if (check) {
                                    count--
                                }
                            }
                        }

                        if (count == filter[i].Condition.length) {
                            data.splice(x, 1);
                        }
                    } else if (filter[i].Operator == 'startwith') {
                        for (y = 0; y < filter[i].Condition.length; y++) {
                            item = new Date(data[x][filter[i].Headers])
                            condition = new Date(filter[i].Condition[y])
                            if (!(item.getTime() >= condition.getTime())) {
                                data.splice(x, 1);
                            }
                        }
                    } else if (filter[i].Operator == 'endwith') {
                        for (y = 0; y < filter[i].Condition.length; y++) {
                            item = new Date(data[x][filter[i].Headers])
                            condition = new Date(filter[i].Condition[y])
                            condition = condition.setDate(condition.getDate() + 1)
                            if (!(item.getTime() <= condition)) {
                                data.splice(x, 1);
                            }
                        }
                    }
                }
            }
            resolve(JSON.stringify(data))
        })
    }
}

module.exports = {
    Filter
}