<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div style="margin-bottom: 5px">Exam Part Title</div>
          <v-text-field
            outlined
            v-model="exam_part.title"
            name="exam-part-title"
            v-validate="'required'"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              dense
              type="error"
              v-show="errors.has('exam-part-title')"
              style="margin-top: -10px"
              v-if="error == ''"
              >{{ errors.first("exam-part-title") }}</v-alert
            >
          </transition>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        style="margin-top: -40px"
      >
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div>Of Exam</div>
        </div>
        <v-select
          :items="exam_data"
          item-text="ExamTitle"
          item-value="ID"
          v-model="exam_id"
          outlined
          name="exam-id"
          v-validate="'required'"
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            dense
            type="error"
            v-show="errors.has('exam-id')"
            style="margin-top: -10px"
            v-if="error == ''"
            >{{ errors.first("exam-id") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        style="margin-top: -40px"
      >
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div>Question Set</div>
        </div>
        <v-select
          :items="question_set_data"
          item-text="QuestionSetName"
          item-value="ID"
          v-model="exam_part.question_set"
          outlined
          name="question-set"
          v-validate="'required'"
          v-on:change="ChangeQuestionSet"
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            dense
            type="error"
            v-show="errors.has('question-set')"
            style="margin-top: -10px"
            v-if="error == ''"
            >{{ errors.first("question-set") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-top: -30px"
      >
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div>Exam Part Description</div>
        </div>
        <quill-editor
          id="editor"
          :tag="'textarea'"
          :config="config"
          :options="editorOption"
          v-model="exam_part.desc"
          name="exam-part-description"
          v-validate="'required'"
        />
        <br />
        <transition name="slide-fade">
          <v-alert
            dense
            type="error"
            v-show="errors.has('exam-part-description')"
            v-if="error == ''"
            >{{ errors.first("exam-part-description") }}</v-alert
          >
        </transition>
      </v-col>
      <!-- grade config -->
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-top: -30px"
      >
        <v-row>
          <v-col>
            <div style="margin-bottom: 5px; color: gray">
              Grade Config (Options)
            </div>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="ActionsDialog('create')"
                  style="cursor: pointer; float: right"
                  >mdi-plus-circle-outline</v-icon
                >
              </template>
              <span>Add Grade Config</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <div style="border-bottom: 1px solid rgb(216, 216, 216)"></div>
        <div
          style="text-align: center; color: gray; margin-top: 10px"
          v-if="exam_part.grade_config.length == 0"
        >
          No configuration
        </div>
        <v-data-table
          :headers="headers"
          :items="exam_part.grade_config"
          class="elevation-1"
          v-if="exam_part.grade_config.length > 0"
        >
          <template v-slot:item.action="{ item }">
            <v-icon small @click="DeleteGradeConfig(item.key)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" v-if="error_grobal != ''">
        <transition name="slide-fade">
          <v-alert dense type="error">{{ error_grobal }}</v-alert>
        </transition>
      </v-col>
      <v-col cols="12">
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            width="120"
            @click="SaveExamPart"
            v-if="ExamPartData == null"
          >
            <v-icon style="margin-right: 5px">mdi-content-save</v-icon>save
          </v-btn>
          <v-btn
            color="success"
            width="120"
            @click="UpdateExamPart"
            v-if="ExamPartData != null"
          >
            <v-icon style="margin-right: 5px">mdi-content-save</v-icon>save
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <!-- dialog -->
    <v-dialog v-model="dialog_grade_config" max-width="600">
      <v-card>
        <v-card-title v-if="menu == 'create'">Add Grade Config</v-card-title>
        <v-card-title v-if="menu == 'update'">Update Grade Config</v-card-title>
        <v-card-text style="margin-top: 10px">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-select
                :items="grade_type"
                v-model="grade_config.range"
                label="Range"
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
              v-if="grade_config.type != 'between'"
            >
              <v-text-field
                label="Max"
                outlined
                type="number"
                v-model="grade_config.max"
                v-if="grade_config.range == 'less'"
              ></v-text-field>
              <v-text-field
                label="Min"
                outlined
                type="number"
                v-model="grade_config.min"
                v-if="grade_config.range == 'more'"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              style="margin-top: -30px"
              v-if="grade_config.range == 'between'"
            >
              <v-text-field
                label="Min"
                outlined
                type="number"
                v-model="grade_config.min"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              style="margin-top: -30px"
              v-if="grade_config.range == 'between'"
            >
              <v-text-field
                label="Max"
                outlined
                type="number"
                v-model="grade_config.max"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              v-if="
                grade_config.range != null || grade_config.range != undefined
              "
              style="margin-top: -30px"
            >
              <v-text-field
                v-model="grade_config.grade"
                label="Grade"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              v-if="
                grade_config.range != null || grade_config.range != undefined
              "
              style="margin-top: -30px"
            >
              <v-select
                :items="pass_list"
                v-model="grade_config.is_pass"
                item-text="text"
                item-value="value"
                label="Pass"
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <transition name="slide-fade">
                <v-alert
                  dense
                  type="error"
                  v-if="this.error != ''"
                  style="margin-top: -10px"
                  >{{ error }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="margin-top: -20px" v-if="menu == 'create'">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog_grade_config = false"
            >cancel</v-btn
          >
          <v-btn color="green darken-1" text @click="SaveGradeConfig"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../../../config";

export default {
  data() {
    return {
      exam_id: null,
      user: "",
      token: "",
      error: "",
      error_grobal: "",
      question_config_error: "",
      number_of_config: 0,
      menu: "",
      question_search: "",
      question_config: {},
      grade_config: {},
      questionConfigHeaders: [
        { text: "Question Name", value: "question_title", sortable: false },
        { text: "Actions", value: "action", align: "right", sortable: false },
      ],
      headers: [
        { text: "Range", value: "range" },
        { text: "Min", value: "min" },
        { text: "Max", value: "max" },
        { text: "Grade", value: "grade" },
        { text: "Pass", value: "is_pass" },
        { text: "Actions", value: "action", sortable: false },
      ],
      exam_data: [],
      question_set_data: [],
      question_in_question_set: [],
      exam_part: {
        random_question: true,
        grade_config: [],
        question_config: [],
        desc: "",
      },
      dialog_grade_config: false,
      dialog_question_config: false,
      timing_type: ["limited", "unlimited"],
      grade_type: ["less", "more", "between"],
      question_type: ["Indicate", "Random"],
      random_options: [true, false],
      pass_list: [
        {
          text: "Pass",
          value: true,
        },
        {
          text: "Fail",
          value: false,
        },
      ],
      config: {
        fileUpload: false,
        events: {
          content: [],
          initialized: function () {
            //   console.log("initialized");
          },
          "image.inserted": function ($img) {
            // console.log("image.inserted");
          },
        },
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
        },
      },
      loading: false,
    };
  },
  props: ["ExamPartData"],
  methods: {
    SaveQuestionConfig() {
      this.question_config_error = "";
      if (
        this.question_config.random != undefined &&
        this.question_config.random != null
      ) {
        if (this.question_config.random == false) {
          if (
            this.question_config.question_id != undefined &&
            this.question_config.question_id != null
          ) {
            for (var x = 0; x < this.question_in_question_set.length; x++) {
              if (
                this.question_config.id == this.question_in_question_set[x].id
              ) {
                this.question_in_question_set[x].config_data = {
                  random: this.question_config.random,
                  question_id: this.question_config.question_id,
                };
                this.question_in_question_set[x].config = true;
                this.dialog_question_config = false;
              }
            }
          } else {
            this.question_config_error = "Please select question";
          }
        } else if (this.question_config.random == true) {
          if (
            this.question_config.max_point != undefined &&
            this.question_config.max_point != null &&
            this.question_config.max_point != ""
          ) {
            for (var i = 0; i < this.question_in_question_set.length; i++) {
              if (
                this.question_config.id == this.question_in_question_set[i].id
              ) {
                this.question_in_question_set[i].config_data = {
                  random: this.question_config.random,
                  max_point: this.question_config.max_point,
                  tag: this.question_config.tag,
                };
                this.question_in_question_set[i].config = true;
                this.dialog_question_config = false;
              }
            }
          } else {
            this.question_config_error = "Please select max point";
          }
        }
      } else {
        this.question_config_error = "Please select config random";
      }
    },
    QuestionConfigDialog(item) {
      if (item.config == false) {
        this.question_config = {
          id: item.id,
          tag: [],
        };
      } else {
        this.question_config = {
          id: item.id,
          random: item.config_data.random,
          question_id: item.config_data.question_id,
          max_point: item.config_data.max_point,
          tag: item.config_data.tag,
        };
      }
      this.dialog_question_config = true;
    },
    async ChangeQuestionSet() {
      this.number_of_config = 0;
    },
    async SaveExamPart() {
      this.error_grobal = "";
      const results = Promise.all([
        this.$validator.validate("exam-part-title"),
        this.$validator.validate("exam-id"),
        this.$validator.validate("question-set"),
        // this.$validator.validate("number-of-config"),
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        var count = 0;
        for (var x = 0; x < this.question_in_question_set.length; x++) {
          if (this.question_in_question_set[x].config == false) {
            count++;
          }
        }
        if (count > 0) {
          this.error_grobal = "Please config all question";
        } else {
          this.exam_part.question_config = [];
          let obj = {};
          for (var i = 0; i < this.question_in_question_set.length; i++) {
            obj = {};
            if (this.question_in_question_set[i].config_data.random == true) {
              obj = {
                max_point: parseInt(
                  this.question_in_question_set[i].config_data.max_point
                ),
                question_tag: this.question_in_question_set[i].config_data.tag,
                is_random: true,
              };
              this.exam_part.question_config.push(obj);
            } else if (
              this.question_in_question_set[i].config_data.random == false
            ) {
              obj = {
                question_id:
                  this.question_in_question_set[i].config_data.question_id,
                is_random: false,
              };
              this.exam_part.question_config.push(obj);
            }
          }

          this.$swal
            .fire({
              title: "Are you sure ?",
              text: `Do you want to create this exam part?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            })
            .then(async (result) => {
              if (result.value) {
                this.loading = true;
                this.exam_part.exam_id = this.exam_id;
                this.exam_part.create_by = this.user.EmployeeEmail;
                console.log("exam part: ", this.exam_part);
                axios({
                  method: "POST",
                  url: `${IP}/exam-part/create`,
                  headers: {
                    Authorization: AuthKey,
                  },
                  data: {
                    exam_part: this.exam_part,
                  },
                }).then(async (response) => {
                  this.loading = false;
                  this.$swal.fire("Create Exam Part Success!", "", "success");
                  await this.Clear();
                  await this.Start();
                });
              }
            });
        }
      }
    },
    async UpdateExamPart() {
      this.error_grobal = "";
      const results = Promise.all([
        this.$validator.validate("exam-part-title"),
        this.$validator.validate("exam-id"),
        this.$validator.validate("question-set"),
        // this.$validator.validate("number-of-config"),
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        var count = 0;
        for (var x = 0; x < this.question_in_question_set.length; x++) {
          if (this.question_in_question_set[x].config == false) {
            count++;
          }
        }
        if (count > 0) {
          this.error_grobal = "Please config all question";
        } else {
          this.exam_part.question_config = [];
          let obj = {};
          for (var i = 0; i < this.question_in_question_set.length; i++) {
            obj = {};
            if (this.question_in_question_set[i].config_data.random == true) {
              obj = {
                max_point: parseInt(
                  this.question_in_question_set[i].config_data.max_point
                ),
                question_tag: this.question_in_question_set[i].config_data.tag,
                is_random: true,
              };
              this.exam_part.question_config.push(obj);
            } else if (
              this.question_in_question_set[i].config_data.random == false
            ) {
              obj = {
                question_id:
                  this.question_in_question_set[i].config_data.question_id,
                is_random: false,
              };
              this.exam_part.question_config.push(obj);
            }
          }

          this.$swal
            .fire({
              title: "Are you sure ?",
              text: `Do you want to update this exam part?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            })
            .then(async (result) => {
              if (result.value) {
                this.loading = true;
                console.log("exam part: ", this.exam_part);
                this.exam_part.exam_id = this.exam_id;

                axios({
                  method: "POST",
                  url: `${IP}/exam-part/update`,
                  headers: {
                    Authorization: AuthKey,
                  },
                  data: {
                    exam_part: this.exam_part,
                  },
                }).then(async (response) => {
                  this.loading = false;
                  this.$swal.fire("Update Exam Part Success!", "", "success");
                  this.$emit("update-data");
                });
              }
            });
        }
      }
    },
    Clear() {
      this.$validator.reset();
      this.exam_part = {
        random_question: true,
        grade_config: [],
        question_config: [],
        desc: "",
      };
      if (this.$route.query.exof == undefined) {
        this.exam_id = null;
      }
    },
    SaveGradeConfig() {
      this.error = "";
      this.CheckGradeConfig(this.grade_config).then((response) => {
        if (response == true) {
          this.grade_config.key = this.RandomKey(10);
          this.exam_part.grade_config.push(this.grade_config);
          this.grade_config = {};
          this.dialog_grade_config = false;
        } else {
          this.error = "Please complete all information.";
        }
      }, this);
    },
    ActionsDialog(menu, item) {
      this.menu = menu;
      if (this.menu == "update") {
        this.grade_config = item;
      } else if (this.menu == "create") {
        this.grade_config = {};
      }
      this.dialog_grade_config = true;
    },
    DeleteGradeConfig(key) {
      for (var i = 0; i < this.exam_part.grade_config.length; i++) {
        if (this.exam_part.grade_config[i].key == key) {
          this.exam_part.grade_config.splice(i, 1);
        }
      }
    },
    CheckGradeConfig(grade_config) {
      return new Promise(async (resolve, reject) => {
        if (grade_config != undefined) {
          if (
            grade_config.grade != undefined &&
            grade_config.grade != null &&
            grade_config.is_pass != undefined &&
            grade_config.is_pass != null
          ) {
            if (grade_config.range == "less") {
              if (
                grade_config.max != undefined &&
                grade_config.max != null &&
                grade_config.max != ""
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else if (grade_config.range == "more") {
              if (
                grade_config.min != undefined &&
                grade_config.min != null &&
                grade_config.min != ""
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else if (grade_config.range == "between") {
              if (
                grade_config.min != undefined &&
                grade_config.min != null &&
                grade_config.min != "" &&
                grade_config.max != undefined &&
                grade_config.max != null &&
                grade_config.max != ""
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            }
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    },
    GetQuestionSet() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/question-set/get-all`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            console.log("questionSetItems: ", response.data);
            this.question_set_data = response.data;
            resolve(true);
          });
      });
    },
    GetAllExam() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/exam/get-all`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            console.log("examItems: ", response.data);
            this.exam_data = response.data;
            resolve(true);
          });
      });
    },
    async GetAllQuestionInQuestionSet(id) {
      return new Promise(async (resolve, reject) => {
        //
      });
    },
    RandomKey(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    SetData() {
      console.log("ExamPartData: ", this.ExamPartData);
      this.exam_id = this.ExamPartData.OfExam;
      this.exam_part.title = this.ExamPartData.ExamPartTitle;
      this.exam_part.question_set = this.ExamPartData.QuestionSet;
      this.exam_part.desc = this.ExamPartData.ExamPartDescription;
      this.exam_part.id = this.ExamPartData.ID;
      if (this.ExamPartData.GradeConfig != null) {
        this.exam_part.grade_config = JSON.parse(this.ExamPartData.GradeConfig);
      }
    },
    Start() {
      this.user = this.$store.getters.currentUser;
      this.GetAllExam().then(() => {
        this.GetQuestionSet().then(async () => {
          if (this.ExamPartData != null) {
            await this.SetData();
          }
        }, this);
      }, this);
    },
  },
  created() {
    this.Start();
  },
};
</script>