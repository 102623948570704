<template>
  <div class="froala">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        v-if="user.Role != 'Admin'"
      >
        <v-text-field
          label="Title"
          outlined
          v-model="content.title"
          autofocus
          name="title"
          v-validate="'required'"
          disabled
        ></v-text-field>
        <div class="show-path" v-if="content.title != ''">
          {{ content.path }}
        </div>
        <transition name="slide-fade">
          <v-alert
            type="error"
            v-show="errors.has('title')"
            style="margin-top: -10px; margin-bottom: 25px"
            >{{ errors.first("title") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col v-if="user.Role == 'Admin'">
        <v-text-field
          label="Title"
          outlined
          v-model="content.title"
          autofocus
          name="title"
          v-validate="'required'"
          disabled
        ></v-text-field>
        <div class="show-path" v-if="content.title != ''">
          {{ content.path }}
        </div>
        <transition name="slide-fade">
          <v-alert
            type="error"
            v-show="errors.has('title')"
            style="margin-top: -10px; margin-bottom: 25px"
            >{{ errors.first("title") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col cols="auto" v-if="user.Role == 'Admin'">
        <v-checkbox
          v-model="content.official"
          class="mx-2"
          label="Official"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-top: -20px"
      >
        <v-combobox
          v-model="content.tag"
          label="Tag"
          outlined
          multiple
          chips
          name="tag"
          v-validate="'required'"
        ></v-combobox>
        <transition name="slide-fade">
          <v-alert
            type="error"
            v-show="errors.has('tag')"
            style="margin-top: -10px; margin-bottom: 25px"
            >{{ errors.first("tag") }}</v-alert
          >
        </transition>
      </v-col>
    </v-row>
    <div class="select-file">
      <v-row>
        <v-col cols="12" xs="12" sm="8" md="9" lg="10" xl="10">
          <input
            id="fileUpload"
            type="file"
            accept="image/*"
            hidden
            @change="handleFileUpload"
          />
          <v-btn block color="primary" @click="chooseFiles" disabled>
            <v-icon style="margin-right: 5px">mdi-file</v-icon>Add Image Title
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <v-btn block color="red darken-1" style="color: white" disabled
            >remove image</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <v-textarea
      outlined
      label="Description"
      v-model="content.description"
      name="description"
      v-validate="'required'"
    ></v-textarea>
    <transition name="slide-fade">
      <v-alert
        type="error"
        v-show="errors.has('description')"
        style="margin-top: -10px; margin-bottom: 25px"
        >{{ errors.first("description") }}</v-alert
      >
    </transition>
    <!-- <froala id="editor" :tag="'textarea'" :config="config" v-model="content.model"></froala> -->
    <quill-editor
      id="editor"
      :tag="'textarea'"
      :options="editorOption"
      v-model="content.model"
    />
    <br />
    <v-row>
      <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
        <v-select
          :items="select_status"
          outlined
          v-model="content.status"
          block
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
        <v-btn
          color="primary"
          style="color: white; height: 54px"
          block
          @click="UpdateContent"
          >Update</v-btn
        >
      </v-col>
    </v-row>
    <br />
    <hr />
    <br />
    <div class="title-content">{{ content.title }}</div>
    <div class="title-img">
      <v-img
        v-if="preview != ''"
        :src="preview"
        :lazy-src="preview"
        width="100%"
        max-width="700"
        class="mx-auto"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <div class="description" v-if="content.description != ''">
      <v-icon style="margin-top: -10px" size="18">mdi-format-quote-open</v-icon>
      {{ content.description }}
      <v-icon style="margin-top: -10px" size="18"
        >mdi-format-quote-close</v-icon
      >
    </div>
    <br />
    <froalaView
      v-model="content.model"
      v-if="editorTool == '' || editorTool == null"
    ></froalaView>
    <div class="output ql-snow" v-if="editorTool != '' || editorTool != null">
      <div class="ql-editor" v-html="content.model"></div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import VueFroala from "vue-froala-wysiwyg";
import * as blobUtil from "blob-util";
import { webURL, apiURL, IP, AuthKey } from "../../config";
import axios from "axios";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Quill from "quill";
import ImageResize from "quill-image-resize-module";

Quill.register("modules/imageResize", ImageResize);

export default {
  data() {
    return {
      user: "",
      loading: false,
      editorOption: {
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            toolbarButtonStyles: {
              display: "none",
            },
          },
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link", "image", "video"],
          ],
        },
      },
      editorTool: "",
      content: {
        title: "",
        tag: [],
        title_img: "",
        description: "",
        model: "",
        data: "",
        content_img: [],
        path: "",
        status: "",
      },
      preview: "",
      config: {
        fileUpload: false,
        events: {
          content: [],
          initialized: function () {
            //   console.log("initialized");
          },
          "image.inserted": function ($img) {
            // console.log("image.inserted");
          },
        },
      },
      path: "",
      news_data: "",
      select_status: ["Draft", "STelligence", "Public"],
      // img_change_index: [],
      img_src: [],
      img_name: [],
      init_img_src: [],
      remain_img_src: [],
      delete_img_src: [],
    };
  },
  methods: {
    CreatePath(name) {
      var replaced = name.split(" ").join("-");
      var lower = replaced.toLowerCase();
      var date = moment(new Date())
        .tz("Asia/Bangkok")
        .format("YYYY-MM-DD-hh-mm-ss");
      var path = lower + "_" + date;
      this.content.path = path;
      return this.content.path;
    },
    UpdateContent() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          Swal.fire({
            title: "คุณต้องการดำเนินการต่อหรือไม่ ?",
            text: `คุณต้องการอัพเดตเนื้อหาใหม่หรือไม่ !`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.loading = true;
              this.RegexCutImgPathAndReturnBlobArray().then(
                (responseBlobArray) => {
                  if (responseBlobArray.length > 0) {
                    this.ConvertBlobToFile(responseBlobArray).then(
                      (response) => {
                        this.LoopReplaceSrcImgWithLink(this.content.model).then(
                          (response) => {
                            this.SaveAllContent();
                          }
                        );
                      }
                    );
                  } else {
                    this.SaveAllContent();
                  }
                }
              );
            }
          });
        }
      });
    },
    async base64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      b64Data = b64Data.split('"')[0];
      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = await byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = await new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = await new Blob(byteArrays, { type: contentType });
      return blob;
    },
    RegexCutImgPathAndReturnBlobArray() {
      return new Promise(async (resolve, reject) => {
        var str = this.content.model;
        // console.log("Model content =", this.content.model);
        var regex = /<img [^>]*src="[^"]*"[^>]*>/gm,
          matches = regex.exec(str),
          array = [];
        // console.log("Init Content=", this.content);
        while (matches != null) {
          var src_img = matches.map((x) =>
            x.replace(/.*src="([^"]*)".*/, "$1")
          );
          var str_src_img = src_img[0].toString();
          if (str_src_img.startsWith("data") == true) {
            array.push(matches[0]);
            this.img_src.push(src_img);
          } else {
            this.remain_img_src.push(src_img);
          }
          matches = regex.exec(str);
        }
        // console.log("Remaining img src", this.remain_img_src);
        // console.log("Array of img tag", array);
        // console.log("Array of index img tag to change", this.img_src);

        for (const key in this.init_img_src) {
          var initStr = this.init_img_src[key][0];
          var count = 0;
          for (const key in this.remain_img_src) {
            const remainStr = this.remain_img_src[key][0];
            if (remainStr == initStr) {
              // console.log("Comapre init", initStr);
              // console.log("Compare remain", remainStr);
              count += 1;
            }
          }
          if (count == 0) {
            initStr = initStr.split("news_file/");
            // console.log("Init str=",initStr)
            this.delete_img_src.push(initStr[1]);
          }
        }
        // console.log("Img src to Del", this.delete_img_src);

        var blobArray = [];
        for (const key in array) {
          const element = array[key].slice(0, -2);
          var base64Data = element.split(",")[1];
          var contentType = element.slice(15).split(";")[0];
          var blob = await this.base64toBlob(base64Data, contentType);
          blobArray.push(blob);
        }
        // console.log("Blob array =", blobArray);

        resolve(blobArray);
      });
    },
    generateID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    ConvertBlobToFile(blobArrayObj) {
      return new Promise(async (resolve, reject) => {
        var count = 0;
        var lengthArray = 0;
        if (blobArrayObj.length > 0) {
          lengthArray = blobArrayObj.length;
          for (const key in blobArrayObj) {
            const element = blobArrayObj[key];
            var fileName =
              this.generateID() + "." + String(element.type).split("/")[1];
            this.img_name.push(fileName);
            var file = new File([element], fileName, {
              type: element.type,
              lastModified: Date.now(),
            });
            this.content.content_img.push(file);
          }
          // console.log("File array=", this.content.content_img);
          resolve("success");
        } else {
          resolve("success");
        }
      });
    },
    LoopReplaceSrcImgWithLink(str) {
      return new Promise(async (resolve, reject) => {
        var str_to_replace = str;
        for (let index = 0; index < this.img_src.length; index++) {
          const element = this.img_src[index];
          str_to_replace = str_to_replace.replace(
            this.img_src[index],
            `${apiURL}/news_file/${this.img_name[index]}`
          );
        }
        // console.log("Afte url replace=", str_to_replace);
        this.content.data = str_to_replace;
        resolve("success");
      }, this);
    },
    regexModifyIframeTag(content) {
      // Part of regex to find iframe tag
      var regex_iframe = /(?:<iframe[^>]*)(?:>)/g,
        matches_iframe = regex_iframe.exec(content);
      while (matches_iframe != null) {
        // array.push(matches_iframe[0]);
        if (matches_iframe[0].startsWith("<iframe")) {
          // console.log("Iframe=", matches_iframe[0]);
          var modify_iframe = matches_iframe[0].toString().slice(0, -1);
          modify_iframe =
            modify_iframe + ' style="width:90%;height:550px; margin:auto;">';
          // console.log("After Modify", modify_iframe);
          this.content.data = this.content.data.replace(
            matches_iframe[0],
            modify_iframe
          );
          // console.log("Content data", this.content.data);
        }
        matches_iframe = regex_iframe.exec(content);
      }
    },
    SaveAllContent() {
      return new Promise(async (resolve, reject) => {
        // console.log(this.content);
        const data = new FormData();
        const content_img = this.content.content_img;
        if (this.content.data == "") {
          this.content.data = this.content.model;
        }
        this.regexModifyIframeTag(this.content.data);

        // console.log("This content data=",this.content.data);
        if (content_img.length > 0) {
          for (var i = 0; i < content_img.length; i++) {
            data.append("content_img", content_img[i]);
          }
        } else {
          data.append("content_img", null);
        }

        // console.log("dataimg", this.content.data);
        data.append("description", this.content.description);
        data.append("data", this.content.data);
        data.append("del_img", this.delete_img_src);
        data.append("path", this.content.path);
        data.append("update_by", this.user.EmployeeEmail);
        data.append("news_id", this.content.news_id);
        data.append("status", this.content.status);
        data.append("tag", JSON.stringify(this.content.tag));
        data.append("official", this.content.official);
        data.append("editortool", "quill");

        axios
          .post(`${IP}/update-news`, data, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then(async (response) => {
            // console.log(response.data);
            if (response.data == "success") {
              Swal.fire(
                "อัพเดตเนื้อหาเสร็จสิ้น !",
                `อัพเดตเนื้อหาเสร็จสิ้น !`,
                "success"
              ).then(() => {
                this.$router.push("/blog");
              });
            }
          });
      });
    },

    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    async handleFileUpload(e) {
      const file = e.target.files;
      this.content.title_img = file[0];
      this.preview = await this.toBase64(file[0]);
      // console.log(file[0]);
    },
    toBase64(file) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    findAllInitImgTag(str) {
      var regex = /<img [^>]*src="[^"]*"[^>]*>/gm,
        matches = regex.exec(str),
        array = [];
      while (matches != null) {
        var src_img = matches.map((x) => x.replace(/.*src="([^"]*)".*/, "$1"));
        var str_src_img = src_img[0].toString();
        if (str_src_img.startsWith("data") != true) {
          this.init_img_src.push(src_img);
        }
        matches = regex.exec(str);
      }
    },
    getNewsByID() {
      axios({
        method: "post",
        url: IP + "/select-news-by-path",
        headers: {
          Authorization: AuthKey,
        },
        data: {
          path: this.$route.params.id,
        },
      }).then(async (response) => {
        if (response.data.NewsCreatedBy == this.user.EmployeeEmail) {
          this.content = {
            news_id: response.data.NewsID,
            title: await this.Decode(response.data.NewsTitle),
            tag: JSON.parse(response.data.NewsTag),
            description: await this.Decode(response.data.NewsDescription),
            model: await this.Decode(response.data.NewsContent),
            content_img: [],
            path: response.data.NewsPath,
            data: "",
            status: response.data.PublishStatus,
            official: response.data.Official,
          };
          this.findAllInitImgTag(this.content.model);
          // console.log("Init img Tag", this.init_img_src);
          this.editorTool = response.data.EditorTool;
          this.preview = `${IP}/news_file/${response.data.NewsBackgroundImage}`;
        } else {
          this.$router.push("/");
        }
      });
    },
    Decode(data) {
      var result = Buffer.from(data, "base64").toString();
      return result;
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.user = this.$store.getters.currentUser;
      this.getNewsByID();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";
.froala {
  max-width: 1200px;
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.title-content {
  font-size: 40px;
}

.title-img {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.select-file {
  width: 100%;
  margin-top: -20px;
  margin-bottom: 20px;
}

.description {
  width: 60%;
  min-width: 200px;
  margin: 0 auto;
  padding: 30px;
  font-size: 14px;
  color: gray;
  text-align: center;
}

.show-path {
  margin-top: -10px;
  margin-bottom: 15px;
  margin-left: 20px;
  color: gray;
}
.example {
  display: flex;
  flex-direction: column;

  .editor {
    height: 40rem;
    overflow: hidden;
  }

  .output {
    width: 100%;
    height: 20rem;
    margin: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
    resize: vertical;

    &.code {
      padding: 1rem;
      height: 16rem;
    }

    &.ql-snow {
      border-top: none;
      height: 24rem;
    }
  }
}
</style>
