import MarkdownIt from "markdown-it";

const languageItems = {
  javascript: "language-javascript",
  js: "language-js",
  python: "language-python",
  html: "language-html",
  bash: "language-bash",
  // Add more languages as needed
};

const Formatted = {
  MarkdownIt(content) {
    const md = new MarkdownIt();
    let res = md.render(content);
    res = res.replace(/<table>/g, '<table class="markdown-table">');
    res = res.replace(/<pre>/g, '<pre class="markdown-pre">');
    console.log('markdown: ', res)
    return res
  },
  async Main(content) {
    let replacedContent = content;

    // code
    const codeBlockRegex = /```(\w+)?([\s\S]*?)```/g;
    const replacedText = content.replace(
      codeBlockRegex,
      (match, language, code) => {
        const escapedCode = code.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        const languageClass = language ? language : "";
        return `<pre class="ql-syntax ${languageClass}">${escapedCode}</pre>`;
      }
    );
    replacedContent = replacedText;

    return replacedContent;
  },
};

export { Formatted };
