const Base64 = {
  async Decode(data) {
    return new Promise(async (resolve, reject) => {
      var result = await Buffer.from(data, "base64").toString("utf8");
      resolve(result);
    });
  },
  async Encode(data) {
    return new Promise(async (resolve, reject) => {
      var result = Buffer.from(data).toString("base64");
      resolve(result);
    });
  },
};

module.exports = {
  Base64,
};
