<template>
  <div class="recomment-blog">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="12"
          lg="12"
          xl="12"
          v-for="n in listItems"
          :key="n.NewsID"
        >
          <v-hover v-slot:default="{ hover }" open-delay="100">
            <v-card
              id="recommend-card"
              max-width="350"
              :elevation="hover ? 21 : 3"
            >
              <v-list-item>
                <v-list-item-avatar
                  color="grey"
                  @click="goToProfile(n.NewsCreatedBy)"
                  style="cursor: pointer"
                >
                  <span
                    v-if="n.ProfileImage == null || n.ProfileImage == 'null'"
                    style="color: white"
                    >{{ short(n.NewsCreatedBy) }}</span
                  >
                  <v-img
                    v-if="n.ProfileImage != null && n.ProfileImage != 'null'"
                    :src="`${IP}/profile_img/${n.ProfileImage}`"
                    :lazy-src="`${IP}/profile_img/${n.ProfileImage}`"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="headline">{{
                    Decode(n.NewsTitle)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    n.EmployeeName
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-img
                :src="`${IP}/news_file/${n.NewsBackgroundImage}`"
                height="194"
              ></v-img>

              <v-card-text style="line-height: normal">
                <div class="ma-2" id="description-area">
                  {{ Decode(n.NewsDescription) }}
                </div>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p style="margin-top: 10px">
                      {{ Others.ConvertDate(n.NewsCreatedDate) }}
                    </p>
                    <br />
                    <p style="margin-top: -30px">
                      <v-icon small style="margin-right: 5px">mdi-eye</v-icon>
                      {{ n.Views }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    style="margin-top: -20px; margin-bottom: 30px"
                  >
                    <v-chip
                      class="ma-2"
                      small
                      color="indigo"
                      text-color="white"
                      v-for="t in n.NewsTag"
                      :key="t"
                      >{{ t }}</v-chip
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="card-actions">
                <v-spacer></v-spacer>
                <v-btn text color="deep-purple" @click="readBlog(n.NewsPath)">
                  <v-icon style="margin-right: 10px"
                    >mdi-book-open-variant</v-icon
                  >Read
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import { Others } from "../../function/Others";

export default {
  data() {
    return {
      Others: Others,
      IP: IP,
      listItems: [],
    };
  },
  methods: {
    readBlog(id) {
      this.$router
        .push({
          name: "read-blog",
          params: {
            id: id,
          },
        })
        .then(() => {
          location.reload();
        });
    },
    Decode(data) {
      var result = Buffer.from(data, "base64").toString();
      return result;
    },
    short(email) {
      var res = email.substr(0, 1);
      var result = res.charAt(0).toUpperCase();
      return result;
    },
    goToProfile(email) {
      this.$router.push({
        name: "profile",
        params: {
          email: email,
        },
      });
    },
    GetRecommendBlog() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "get",
          url: `${IP}/select-random-news_limit-4`,
          headers: {
            Authorization: AuthKey,
          },
        }).then((response) => {
          this.listItems = [];
          const result = response.data;
          result.forEach(function (val, i) {
            try {
              val.NewsTag = JSON.parse(val.NewsTag);
            } catch (err) {
              console.log("is json!");
            }
            this.listItems.push(val);
          }, this);
        });
      });
    },
  },
  async created() {
    await this.GetRecommendBlog();
  },
};
</script>

<style scoped lang="scss">
.recomment-blog {
  padding: 20px;
}

.card-actions {
  width: 100%;
  position: absolute;
  bottom: 0;
}
#recommend-card {
  border-radius: 40px 40px 40px 40px;
  -moz-border-radius: 40px 40px 40px 40px;
  -webkit-border-radius: 40px 40px 40px 40px;
  border: 3px solid #d9d9d9;
  height: 535px;
}
</style>