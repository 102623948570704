<template>
  <div class="expansion-panels">
    <div class="header">
      <p class="header-text" style="float: left;">Evaluation History</p>
      <v-btn color="success" @click="Action('create',null)" style="float: right;">
        <v-icon style="margin: 0px 5px 0px -5px;">mdi-plus</v-icon>Create
      </v-btn>
    </div>
    <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px;">ยังไม่มี Evaluation History</p>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      IP: IP,
      user: "",
      headers: [],
      listItems: [],
      search: "",
      dialog: false,
      evaluation: {},
      action: ''
    };
  },
  methods: {
    Action(action,data){
      this.evaluation = {}
      this.action = action
      // if(action == 'update'){

      // }
      this.dialog = !this.dialog
    },
    start() {}
  },
  props: ["email"],
  created() {
    this.start();
  }
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";

.expansion-panels {
  margin-bottom: 30px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>