<template>
    <div class="logout">
        Logout ...
    </div>
</template>

<script>
export default {
    data(){
        return{}
    },
    created(){
        console.log('logout.')
        this.$store.commit("logout");
        this.$router.push('/')
    }
}
</script>