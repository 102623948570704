<template>
  <div class="header-exam">
    <v-app-bar color="green" dark id="app-bar" flat>
      <v-toolbar-title>
        <span>Exam</span>
        <span style="color: rgb(0, 80, 0)">@</span>
        <span>STelligence</span>
      </v-toolbar-title>
      <v-spacer />
      <div>
        <v-icon class="mr-2">mdi-account</v-icon>
          <span style="margin-right: 10px;">{{ CutEmail(email) }}</span>
          |
          <v-btn outlined style="margin-left: 10px; border-radius: 90px;" @click="Logout">logout</v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { WindowResize } from "../../function/WindowResize";

export default {
  data() {
    return {
      width: 0,
      height: 0
    };
  },
  props: ['email'],
  methods: {
    Logout(){
      this.$emit('update-data', true)
    },
      CutEmail(email){
          let split = email.split('@')
          return split[0]
      },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.header-exam {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  color: rgb(0, 80, 0);
}
</style>