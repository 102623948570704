<template>
  <div class="text-to-speech"></div>
</template>

<script>
export default {
  data() {
    return {
      synth: window.speechSynthesis,
      ct: this.content || null,
    };
  },
  props: ["content"],
  methods: {
    async Main() {
      if (this.ct != null) {
        let voiceItems = this.synth.getVoices();
        let voice = voiceItems[2];
        const msg = new SpeechSynthesisUtterance();
        msg.text = this.ct;
        msg.voice = voice;
        console.log("msg: ", msg);
        this.synth.speak(msg);
      }
    },
  },
  created() {
    console.log("content: ", this.ct);
    this.Main();
  },
};
</script>