
const AccessControl = {
    getReadAccessLevel(Cardname,UserAccessRight) {
        // console.log(UserAccessRight);
        
        let temp = JSON.parse(UserAccessRight);
        // console.log(temp[0]);
        for(var i = 0;i<temp[0].Card.length;i++){
            // console.log(Cardname);
            // console.log(temp[0].Card[i]);
            if(Cardname == temp[0].Card[i]){
                return temp[i+1][Cardname];
            }
        }
        return 0;
    },
    modifyObjectVisibility(currentDOM){
        console.log(currentDOM);
        let obj = document.getElementById("stel_89_exportCSVBtn");
        console.log(obj.attributes);
        console.log(obj.style.visibility);
        obj.style.visibility = "hidden";
    }    
}

module.exports = {
    AccessControl
}