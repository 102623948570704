<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- intro -->
    <div style="text-align: center; padding: 30px" v-if="!ready">
      Loading...
    </div>
    <div class="confirm-area" v-if="ready && !confirm && !finish">
      <v-container style="margin-top: 50px">
        <h2
          style="
            text-align: center;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid rgb(230, 230, 230);
          "
        >
          {{ exam.exam_info.ExamTitle }}
        </h2>
        <p>This exam consisted of {{ exam.exam_part.length }} Part.</p>
        <ul>
          <li v-for="(n, index) in exam.exam_part" :key="index">
            <b>{{ n.ExamPartTitle }}</b>
            <br />
            Total question : {{ n.QuestionSetData.Question.length }}
          </li>
        </ul>
        <br />
        <div v-for="(part, index) in exam.exam_part" :key="index">
          <h3>{{ part.ExamPartTitle }}</h3>
          <br />
          <span v-html="part.ExamPartDescription"></span>
        </div>
        <div v-if="exam.exam_info.TimingType == 'limited'">
          <b>Time Interval : </b> {{ exam.exam_info.TimingInterval }} Hr.
        </div>
        <div v-if="exam.exam_info.TimingType == 'unlimited'">
          <b>Time Interval : </b> Unlimited
        </div>
        <div
          v-if="
            exam.exam_info.TimingType == 'limited' &&
            exam.exam.ExamBegin != null
          "
        >
          <b>Continue Time : </b> {{ time.hour }}:{{ time.minute }}:{{
            time.second
          }}
        </div>
        <v-checkbox
          v-model="agree"
          label="I have read and understood the instructions. I agree to accept the results generated by this system."
        ></v-checkbox>
        <v-card-actions>
          <v-btn color="success" :disabled="!agree || loading" @click="TakeExam"
            >Start</v-btn
          >
        </v-card-actions>
      </v-container>
    </div>
    <TakeExam
      v-if="ready && confirm && !finish"
      :exam="exam"
      @finish="Finish"
    />
    <Finish v-if="finish" />
  </div>
</template>

<script>
import TakeExam from "../components/MainExam/TakeExam";
import Finish from "../components/MainExam/Finish";
import axios from "axios";
import { IP, AuthKey } from "../config";

export default {
  data() {
    return {
      id: 0,
      exam: null,
      confirm: false,
      finish: false,
      ready: false,
      agree: false,
      loading: false,
      continue: false,
      count_alert: 0,
      count_sync: 0,
      time: {
        minute: 0,
        second: 0,
        hour: 0,
      },
    };
  },
  components: {
    TakeExam,
    Finish,
  },
  async beforeRouteLeave(to, from, next) {
    if (this.confirm && !this.finish && this.ready) {
      this.noBack();
    } else {
      if (localStorage.getItem("take_exam_status")) {
        alert("You should not refresh page when your take exam!");
        // this.CompleteWhenRefreshPage();
        localStorage.removeItem("take_exam_status");
        next();
      } else {
        next();
      }
    }
  },
  mounted() {
    setInterval(() => {
      if (this.continue) {
        this.Timer();
      }
    }, 1000);
    if (this.continue) {
      this.Timer();
    }
  },
  methods: {
    noBack() {
      window.history.forward();
    },
    async TakeExam() {
      if (this.exam.exam_part.length > 0) {
        this.loading = true;
        if (this.exam.exam.ExamBegin == null) {
          await this.ExamBegin().then(async (response) => {
            console.log("get exam: ", this.exam.exam.ID);
            await this.GetExamSource(this.exam.exam.ID).then((response) => {
              console.log("start: ", this.exam);
              this.loading = false;
              this.confirm = true;
            });
          });
        } else {
          // continue
          console.log("start: ", this.exam);
          localStorage.setItem("take_exam_status", true);
          this.loading = false;
          this.confirm = true;
        }
      } else {
        this.$swal.fire(
          `This exam don't have exam part for take exam. Please contact administrator.`,
          "",
          "error"
        );
        this.$router.push("/exam");
      }
    },
    ExamBegin() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/exam/begin/${this.exam.exam.ID}`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            // console.log('response: ', response.data)
            resolve(true);
          });
      });
    },
    async ConvertBase64ToString(str) {
      // return await atob(str);
      if (str != null) {
        str = str.replace(/\s/g, "");
        return decodeURIComponent(escape(window.atob(str)));
      }
    },
    GetExamSource(id) {
      return new Promise(async (resolve, reject) => {
        console.log("id: ", id);
        axios({
          method: "POST",
          url: `${IP}/exam/get-source`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            id: id,
          },
        }).then(async (response) => {
          this.exam = response.data;

          for (var i = 0; i < this.exam.exam_part.length; i++) {
            this.exam.exam_part[i].ExamPartDescription =
              await this.ConvertBase64ToString(
                this.exam.exam_part[i].ExamPartDescription
              );
          }
          this.ready = true;
          // console.log("exam: ", this.exam);
          resolve(true);
        });
      });
    },
    Complete(item) {
      return new Promise(async (resolve, reject) => {
        axios
          .put(`${IP}/exam/complete/${item.exam.ID}`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            resolve(true);
          });
      });
    },
    GetHistory(item) {
      return new Promise(async (resolve, reject) => {
        resolve(true);
      });
    },
    Finish(item) {
      console.log("finish: ", item);
      this.Complete(item).then((response) => {
        this.GetHistory(item).then((response) => {
          localStorage.removeItem("take_exam_status");
          this.finish = true;
        });
      });
    },
    Timer() {
      if (this.exam.exam_info.TimingInterval != null) {
        if (this.duration > 0) {
          this.duration = this.duration - 1000;
          this.count_sync++;
          if (this.count_sync > 60) {
            // console.log('sync!')
            this.GetServerTime().then(() => {
              this.CheckTimeOut();
            });
            this.count_sync = 0;
          }

          let seconds = this.duration / 1000;
          let minutes = seconds / 60;
          let hours = minutes / 60;

          this.time.hour = ("0" + parseInt(hours % 24)).slice(-2);
          this.time.minute = ("0" + parseInt(minutes % 60)).slice(-2);
          this.time.second = ("0" + parseInt(seconds % 60)).slice(-2);
          // console.log("duration: ", this.duration);
        } else {
          if (this.count_alert == 0) {
            alert("exam time is expired!");
            this.Finish(this.exam);
            this.count_alert++;
          }
        }
      } else {
        this.unlimited = true;
      }
    },
    CheckTimeOut() {
      return new Promise(async (resolve, reject) => {
        if (this.exam_out_time != null && this.exam.exam.ExamBegin != null) {
          let exam_out_time = new Date(this.exam_out_time);
          let begin = new Date(this.exam.exam.ExamBegin);
          let used_time = exam_out_time.getTime() - begin.getTime();
          let combine_time = 3600000 * this.exam.exam_info.TimingInterval;
          let continue_time = combine_time - used_time;
          this.duration = continue_time;
          // delete 3000 millisecond
          this.duration = this.duration - 3000;
          this.duration = parseInt(this.duration);
          // console.log("duration: ", this.duration);
          resolve(true);
        } else {
          resolve(true);
        }
      });
    },
    GetServerTime() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/getServerTime`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            // console.log("get server time: ", response.data);
            this.exam_out_time = response.data;
            resolve(true);
          });
      });
    },
    Start() {
      if (this.$route.params.id == null) {
        this.$router.push("/exam");
      } else {
        this.id = this.$route.params.id;
        this.GetExamSource(this.id).then((response) => {
          if (this.exam.exam.ExamBegin != null) {
            // continue
            this.GetServerTime().then(() => {
              this.CheckTimeOut().then((response) => {
                this.continue = true;
              });
            });
          }
        });
      }
    },
  },
  created() {
    this.Start();
  },
};
</script>

<style lang="scss" scoped>
.confirm-area {
  width: 70%;
  margin: 0 auto;
  min-width: 300px;
}
</style>