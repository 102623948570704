<template>
  <div class="dialog-content-area">
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div
        style="
          width: 100%;
          height: 100%;
          position: absolute;
          background: white;
          opacity: 0.5;
          top: 0;
          left: 0;
        "
      ></div>
    </v-dialog>
    <v-row>
      <v-col cols="8" xs="8" sm="8" md="8" lg="3" xl="3">
        <v-text-field
          dense
          v-if="editedIndex !== -1"
          :disabled="true"
          outlined
          label="SO Number"
          v-model="editedItem.SONumber"
        />
        <v-select
          :items="RunningNumberItems"
          item-text="RunningNumberName"
          item-value="RunningNumberCode"
          v-model="editedItem.SONumber"
          outlined
          dense
          label="SO Number"
          v-validate="'required'"
          name="so-number"
          v-else
        ></v-select>

        <transition name="slide-fade">
          <v-alert
            type="error"
            dense
            style="margin-top: -20px"
            v-show="errors.has('so-number')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col cols="4" xs="4" sm="4" md="4" lg="1" xl="1">
        <v-checkbox
          v-model="editedItem.BOIFlag"
          :disabled="this.editedIndex !== -1"
          style="margin: 5px 5px 0px -10px"
          label="B(BOI)"
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        class="fields-margin"
      >
        <v-select
          :items="SaleInformation"
          item-text="EmployeeName"
          item-value="EmployeeEmail"
          v-model="editedItem.SaleName"
          outlined
          dense
          label="SaleName"
          v-validate="'required'"
          name="sale-name"
          v-if="editedIndex !== -1"
        ></v-select>
        <v-select
          :items="SaleInformation"
          item-text="EmployeeName"
          item-value="EmployeeEmail"
          v-model="editedItem.SaleName"
          outlined
          dense
          label="SaleName"
          v-validate="'required'"
          name="sale-name"
          v-else
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            type="error"
            dense
            style="margin-top: -20px"
            v-show="errors.has('sale-name')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="2"
        xl="2"
        class="fields-margin"
      >
        <v-select
          :items="items.Company"
          item-text="CompanyName"
          item-value="CompanyName"
          v-model="editedItem.Company"
          outlined
          dense
          label="Company"
          v-validate="'required'"
          name="company"
          v-if="editedIndex !== -1"
        ></v-select>
        <v-select
          :items="items.Company"
          item-text="CompanyName"
          item-value="CompanyName"
          v-model="editedItem.Company"
          outlined
          dense
          label="Company"
          v-validate="'required'"
          name="company"
          v-else
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            type="error"
            style="margin-top: -20px"
            dense
            v-show="errors.has('company')"
            >{{ errors.first("company") }}</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="2"
        xl="2"
        class="fields-margin"
      >
        <v-select
          :items="items.PipeDriveFlagStatus"
          v-model="editedItem.PipeDriveFlagStatus"
          outlined
          dense
          label="PipeDriveFlagStatus"
          v-validate="'required'"
          name="PipeDriveFlagStatus"
          v-if="editedIndex !== -1"
        ></v-select>
        <v-select
          :items="items.PipeDriveFlagStatus"
          v-model="editedItem.PipeDriveFlagStatus"
          outlined
          dense
          label="PipeDriveFlagStatus"
          v-validate="'required'"
          name="PipeDriveFlagStatus"
          v-else
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            type="error"
            dense
            style="margin-top: -20px"
            v-show="errors.has('PipeDriveFlagStatus')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        style="margin-top: -30px"
      >
        <v-text-field
          v-model="editedItem.Customer"
          outlined
          dense
          label="Customer"
          v-validate="'required'"
          name="customer"
          v-if="editedIndex !== -1"
        />
        <v-text-field
          v-model="editedItem.Customer"
          outlined
          dense
          label="Customer"
          v-validate="'required'"
          name="customer"
          v-else
        />
        <transition name="slide-fade">
          <v-alert
            type="error"
            dense
            style="margin-top: -20px"
            v-show="errors.has('customer')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        style="margin-top: -30px"
      >
        <v-text-field
          v-model="editedItem.EndUser"
          outlined
          dense
          label="End User"
          v-validate="'required'"
          name="end-user"
          v-if="editedIndex !== -1"
        />
        <v-text-field
          v-model="editedItem.EndUser"
          outlined
          dense
          label="End User"
          v-validate="'required'"
          name="end-user"
          v-else
        />
        <transition name="slide-fade">
          <v-alert
            type="error"
            dense
            style="margin-top: -20px"
            v-show="errors.has('end-user')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        style="margin-top: -30px"
      >
        <v-select
          :items="items.EndUserStatus"
          v-model="editedItem.EndUserStatus"
          outlined
          dense
          label="End User Status"
          v-validate="'required'"
          name="end-user-status"
          v-if="editedIndex !== -1"
        ></v-select>
        <v-select
          :items="items.EndUserStatus"
          v-model="editedItem.EndUserStatus"
          outlined
          dense
          label="End User Status"
          v-validate="'required'"
          name="end-user-status"
          v-else
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            style="margin-top: -20px"
            type="error"
            dense
            v-show="errors.has('end-user-status')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        style="margin-top: -30px"
      >
        <v-menu
          v-model="menu_date"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          full-width
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="editedItem.CustomerPODate"
              label=" Customer PO Date [Y-M-D]"
              append-icon="event"
              readonly
              v-on="on"
              name="po-date"
              v-validate="'required'"
              outlined
              dense
              v-if="editedIndex !== -1"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.CustomerPODate"
              label=" Customer PO Date [Y-M-D]"
              append-icon="event"
              readonly
              v-on="on"
              name="po-date"
              v-validate="'required'"
              outlined
              dense
              v-else
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="editedItem.CustomerPODate"
            @input="menu_date = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        style="margin-top: -30px"
      >
        <v-menu
          v-model="start_license_data"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          full-width
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="editedItem.StartLicenseDate"
              label=" Start License Date [Y-M-D]"
              append-icon="event"
              readonly
              v-on="on"
              name="start-date"
              outlined
              dense
              v-if="editedIndex !== -1"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.StartLicenseDate"
              label=" Start License Date [Y-M-D]"
              append-icon="event"
              readonly
              v-on="on"
              name="start-date"
              outlined
              dense
              v-else
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="editedItem.StartLicenseDate"
            @input="start_license_data = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        style="margin-top: -30px"
      >
        <v-menu
          v-model="end_license_data"
          :close-on-content-click="false"
          :nudge-right="0"
          transition="scale-transition"
          offset-y
          full-width
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="editedItem.EndLicenseDate"
              label=" End License Date [Y-M-D]"
              append-icon="event"
              readonly
              v-on="on"
              name="end-date"
              outlined
              dense
              v-if="editedIndex !== -1"
            ></v-text-field>
            <v-text-field
              v-model="editedItem.EndLicenseDate"
              label=" End License Date [Y-M-D]"
              append-icon="event"
              readonly
              v-on="on"
              name="end-date"
              outlined
              dense
              v-else
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="editedItem.EndLicenseDate"
            @input="end_license_data = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        style="margin-top: -30px"
      >
        <v-text-field
          v-model="editedItem.ProjectName"
          outlined
          label="Product/Project Name"
          v-validate="'required'"
          name="product-project"
          dense
          v-if="editedIndex !== -1"
        />
        <v-text-field
          v-model="editedItem.ProjectName"
          outlined
          label="Product/Project Name"
          v-validate="'required'"
          name="product-project"
          dense
          v-else
        />
        <transition name="slide-fade">
          <v-alert
            style="margin-top: -20px"
            type="error"
            dense
            v-show="errors.has('product-project')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="6"
        md="6"
        lg="4"
        xl="4"
        style="margin-top: -30px"
      >
        <v-select
          :items="items.ProductSold"
          v-model="editedItem.ProductSold"
          item-text="ProductName"
          item-value="ProductName"
          label="Product Sold"
          outlined
          v-validate="'required'"
          name="product-sold"
          dense
          v-if="editedIndex !== -1"
        ></v-select>
        <v-select
          :items="items.ProductSold"
          v-model="editedItem.ProductSold"
          item-text="ProductName"
          item-value="ProductName"
          label="Product Sold"
          outlined
          v-validate="'required'"
          name="product-sold"
          dense
          v-else
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            style="margin-top: -20px"
            type="error"
            dense
            v-show="errors.has('product-sold')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="2"
        xl="2"
        style="margin-top: -30px"
      >
        <v-text-field
          outlined
          v-model="editedItem.POAmount"
          label="PO Amount"
          type="number"
          v-validate="'required'"
          name="po-amount"
          dense
          v-if="editedIndex !== -1"
        />
        <v-text-field
          outlined
          v-model="editedItem.POAmount"
          label="PO Amount"
          type="number"
          v-validate="'required'"
          name="po-amount"
          dense
          v-else
        />
        <transition name="slide-fade">
          <v-alert
            style="margin-top: -20px"
            type="error"
            dense
            v-show="errors.has('po-amount')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="2"
        xl="2"
        style="margin-top: -30px"
      >
        <v-select
          :items="items.Currency"
          label="Currency"
          outlined
          v-model="editedItem.Currency"
          v-validate="'required'"
          name="currency"
          dense
          v-if="editedIndex !== -1"
        ></v-select>
        <v-select
          :items="items.Currency"
          label="Currency"
          outlined
          v-model="editedItem.Currency"
          v-validate="'required'"
          name="currency"
          dense
          v-else
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            type="error"
            style="margin-top: -20px"
            dense
            v-show="errors.has('currency')"
            >This field is required</v-alert
          >
        </transition>
      </v-col>
      <v-col
        cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        style="margin-top: -30px"
      >
        <v-select
          :items="preventive_ma"
          label="Preventive MA"
          outlined
          v-model="editedItem.PreventiveMA"
          v-validate="'required'"
          name="preventive-ma"
          dense
          v-if="editedIndex !== -1"
          v-on:change="MAorSLAChange('ma')"
        ></v-select>
        <v-select
          :items="preventive_ma"
          label="Preventive MA"
          outlined
          v-model="editedItem.PreventiveMA"
          v-validate="'required'"
          name="preventive-ma"
          dense
          v-else
          v-on:change="MAorSLAChange('ma')"
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            type="error"
            style="margin-top: -20px; margin-bottom: 20px"
            dense
            v-show="errors.has('preventive-ma')"
            >This field is required</v-alert
          >
        </transition>
        <v-textarea
          v-model="PreventiveMAOther"
          :disabled="editedItem.PreventiveMA != 'Other'"
          outlined
          dense
          rows="3"
          label="Preventive MA Other"
          name="preventive-ma-other"
          style="margin-top: -15px"
        />
      </v-col>
      <v-col
        cols="6"
        xs="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        style="margin-top: -30px"
      >
        <v-select
          :items="sla"
          label="SLA"
          outlined
          v-model="editedItem.SLA"
          v-validate="'required'"
          name="sla"
          dense
          v-if="editedIndex !== -1"
          v-on:change="MAorSLAChange('sla')"
        ></v-select>
        <v-select
          :items="sla"
          label="SLA"
          outlined
          v-model="editedItem.SLA"
          v-validate="'required'"
          name="sla"
          dense
          v-else
          v-on:change="MAorSLAChange('sla')"
        ></v-select>
        <transition name="slide-fade">
          <v-alert
            type="error"
            style="margin-top: -20px; margin-bottom: 20px"
            dense
            v-show="errors.has('sla')"
            >This field is required</v-alert
          >
        </transition>
        <v-textarea
          v-model="SLAOther"
          :disabled="editedItem.SLA != 'Other'"
          outlined
          dense
          rows="3"
          label="SLA Other"
          name="sla-other"
          style="margin-top: -15px"
        />
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-top: -30px"
      >
        <v-textarea
          v-model="editedItem.Comment"
          v-if="editedIndex !== -1"
          outlined
          dense
          label="Comment"
          name="comment"
        />
        <v-textarea
          v-model="editedItem.Comment"
          v-else
          outlined
          dense
          label="Comment"
          name="comment"
        />
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        style="margin-top: -30px"
      >
        <input
          id="POFileUpload"
          type="file"
          hidden
          @change="handlePOFileUpload"
        />
        <v-btn
          tile
          color="indigo"
          dark
          block
          @click="chooseFiles('po')"
          v-if="
            file.po_name == null &&
            editedItem.PoFile == undefined &&
            editedIndex == -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>PO
          File
        </v-btn>
        <v-btn
          tile
          color="indigo"
          dark
          block
          @click="chooseFiles('po')"
          v-if="
            file.po_name == null &&
            editedItem.PoFile == undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>PO
          File
        </v-btn>
        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('po')"
          v-if="
            file.po_name != null &&
            editedItem.PoFile != undefined &&
            editedIndex == -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          PO File : {{ SubString(file.po_name, 10) }}
        </v-btn>
        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('po')"
          v-if="
            file.po_name == null &&
            editedItem.PoFile != undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          PO File : {{ SubStringEdit(editedItem.PoFile, 10) }}
        </v-btn>
        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('po')"
          v-if="
            file.po_name != null &&
            editedItem.PoFile != undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          PO File : {{ SubString(file.po_name, 10) }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
        style="margin-top: -30px"
      >
        <input
          id="ContractFileUpload"
          type="file"
          hidden
          @change="handleContractFileUpload"
        />
        <v-btn
          tile
          color="indigo"
          dark
          block
          @click="chooseFiles('contract')"
          v-if="
            file.contract_name == null &&
            editedItem.ContractFile == undefined &&
            editedIndex == -1
          "
          class="btn-contract-file"
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon
          >Contract File
        </v-btn>

        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('contract')"
          v-if="
            file.contract_name != null &&
            editedItem.ContractFile != undefined &&
            editedIndex == -1
          "
          class="btn-contract-file"
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          Contract File {{ SubString(file.contract_name, 10) }}
        </v-btn>
        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('contract')"
          v-if="
            file.contract_name == null &&
            editedItem.ContractFile != undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          Contract File : {{ SubStringEdit(editedItem.ContractFile, 10) }}
        </v-btn>
        <v-btn
          tile
          color="indigo"
          dark
          block
          @click="chooseFiles('contract')"
          v-if="
            file.contract_name == null &&
            editedItem.ContractFile == undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon
          >Contract File :
        </v-btn>
        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('contract')"
          v-if="
            file.contract_name != null &&
            editedItem.ContractFile != undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          Contract File : {{ SubString(file.contract_name, 10) }}
        </v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <input
          id="CostSheetFileUpload"
          type="file"
          hidden
          @change="handleCostSheetFileUpload"
        />
        <v-btn
          tile
          color="indigo"
          dark
          block
          @click="chooseFiles('cost-sheet')"
          v-if="
            file.cost_sheet_name == null &&
            editedItem.CostSheetFile == undefined &&
            editedIndex == -1
          "
          class="btn-contract-file"
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>Cost
          Sheet File
        </v-btn>

        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('cost-sheet')"
          v-if="
            file.cost_sheet_name != null &&
            editedItem.CostSheetFile != undefined &&
            editedIndex == -1
          "
          class="btn-contract-file"
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          Cost Sheet File {{ SubString(file.cost_sheet_name, 10) }}
        </v-btn>
        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('cost-sheet')"
          v-if="
            file.cost_sheet_name == null &&
            editedItem.CostSheetFile != undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          Cost Sheet File : {{ SubStringEdit(editedItem.CostSheetFile, 10) }}
        </v-btn>
        <v-btn
          tile
          color="indigo"
          dark
          block
          @click="chooseFiles('cost-sheet')"
          v-if="
            file.cost_sheet_name == null &&
            editedItem.CostSheetFile == undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>Cost
          Sheet File :
        </v-btn>
        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('cost-sheet')"
          v-if="
            file.cost_sheet_name != null &&
            editedItem.CostSheetFile != undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          Cost Sheet File : {{ SubString(file.cost_sheet_name, 10) }}
        </v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <input
          id="AttachFileUpload"
          type="file"
          hidden
          @change="handleAttachFileUpload"
        />
        <v-btn
          tile
          color="indigo"
          dark
          block
          @click="chooseFiles('others')"
          v-if="
            file.others_name == null &&
            editedItem.AttachFile == undefined &&
            editedIndex == -1
          "
          class="btn-contract-file"
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon
          >Others File
        </v-btn>

        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('others')"
          v-if="
            file.others_name != null &&
            editedItem.AttachFile != undefined &&
            editedIndex == -1
          "
          class="btn-contract-file"
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          Others File {{ SubString(file.others_name, 10) }}
        </v-btn>
        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('others')"
          v-if="
            file.others_name == null &&
            editedItem.AttachFile != undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          Others File : {{ SubStringEdit(editedItem.AttachFile, 10) }}
        </v-btn>
        <v-btn
          tile
          color="indigo"
          dark
          block
          @click="chooseFiles('others')"
          v-if="
            file.others_name == null &&
            editedItem.AttachFile == undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon
          >Others File :
        </v-btn>
        <v-btn
          tile
          color="success"
          dark
          block
          @click="chooseFiles('others')"
          v-if="
            file.others_name != null &&
            editedItem.AttachFile != undefined &&
            editedIndex != -1
          "
        >
          <v-icon style="margin: 0px 5px 0px 0px">mdi-file-document</v-icon>
          Others File : {{ SubString(file.others_name, 10) }}
        </v-btn>
      </v-col>
      <hr />
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-card-actions class="justify-center" style="margin-top: 30px">
          <v-btn
            class="ma-2"
            style="width: 100%; max-width: 300px"
            color="success"
            @click="CreateSaleOrder"
          >
            <v-icon style="margin-right: 10px">mdi-cloud-download</v-icon>Save
          </v-btn>
          <v-btn class="ma-2" color="error" @click="close">Close</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { IP, AuthKey } from "../../config";
import axios from "axios";
export default {
  data() {
    return {
      menu_date: false,
      loading: false,
      start_license_data: false,
      end_license_data: false,
      file: {
        po_name: null,
        contract_name: null,
        cost_sheet_name: null,
        others_name: null,
      },
      defaultFile: {
        po_name: null,
        contract_name: null,
        cost_sheet_name: null,
        others_name: null,
      },
      preventive_ma: [
        "No Preventive MA",
        "2 Times a Year",
        "3 Times a Year",
        "4 Times a Year",
        "Other",
      ],
      sla: [
        "No SLA",
        "Standard 8 x 5, 4 Hrs, Response Time",
        "Standard 24 x 7, 4 Hrs, Response Time",
        "Other",
      ],
      PreventiveMAOther: "",
      SLAOther: "",
    };
  },
  methods: {
    // process to backend
    MAorSLAChange(type) {
      if (type == "ma") {
        this.PreventiveMAOther = "";
      } else if (type == "sla") {
        this.SLAOther = "";
      }
    },
    cleanString(comment) {
      return comment
        .replace(/(?!\w|\s)./g, "")
        .replace(/\s+/g, " ")
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, "$2"); // Replaces multiple hyphens with single one.
    },
    CreateSaleOrder() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          for (let i in this.RunningNumberItems) {
            if (
              this.editedItem.SONumber ==
              this.RunningNumberItems[i].RunningNumberCode
            ) {
              this.editedItem.SOType =
                this.RunningNumberItems[i].RunningNumberName;
            }
          }
          let formData = new FormData();
          if (this.editedItem.PipeDriveFlagStatus == "Yes") {
            this.editedItem.PipeDriveFlagStatus = 1;
          } else {
            this.editedItem.PipeDriveFlagStatus = 0;
          }
          if (
            this.editedItem.Comment == null ||
            this.editedItem.Comment == undefined ||
            this.editedItem.Comment == ""
          ) {
            this.editedItem.Comment = "No comment";
          } else {
            this.editedItem.Comment = this.cleanString(this.editedItem.Comment);
          }
          this.editedItem.ActiveStatusColumn = 1;
          this.editedItem.CreateBy = this.Email;

          if (this.editedItem.Currency == "USD") {
            this.editedItem.POAmount = this.editedItem.POAmount * 32;
            this.editedItem.Currency = "THB";
          }
          console.log(this.editedItem);
          formData.append("SONumber", this.editedItem.SONumber);
          formData.append("SOType", this.editedItem.SOType);
          formData.append("BOIFlag", this.editedItem.BOIFlag);
          formData.append("Company", this.editedItem.Company);
          formData.append("CustomerPODate", this.editedItem.CustomerPODate);
          formData.append("Customer", this.editedItem.Customer);
          formData.append("EndUser", this.editedItem.EndUser);
          formData.append("EndUserStatus", this.editedItem.EndUserStatus);
          formData.append("ProductSold", this.editedItem.ProductSold);
          formData.append("ProjectName", this.editedItem.ProjectName);
          formData.append("POAmount", this.editedItem.POAmount);
          formData.append("Currency", this.editedItem.Currency);
          formData.append("SaleName", this.editedItem.SaleName);
          formData.append("CreateBy", this.editedItem.CreateBy);
          formData.append("PoFile", this.editedItem.PoFile);
          formData.append("ContractFile", this.editedItem.ContractFile);
          formData.append("CostSheetFile", this.editedItem.CostSheetFile);
          formData.append("AttachFile", this.editedItem.AttachFile);
          formData.append("StartLicenseDate", this.editedItem.StartLicenseDate);
          formData.append("EndLicenseDate", this.editedItem.EndLicenseDate);
          formData.append(
            "PipeDriveFlagStatus",
            this.editedItem.PipeDriveFlagStatus
          );
          formData.append("Comment", this.editedItem.Comment);
          formData.append(
            "ActiveStatusColumn",
            this.editedItem.ActiveStatusColumn
          );
          formData.append("PreventiveMA", this.editedItem.PreventiveMA);
          formData.append("PreventiveMAOther", this.PreventiveMAOther);
          formData.append("SLA", this.editedItem.SLA);
          formData.append("SLAOther", this.SLAOther);
          console.log(this.editedItem);
          let apiurl = IP + "/api/v1/saleOrder";
          if (this.editedIndex !== -1) {
            this.loading = true;
            axios
              .put(`${apiurl}/edit/${this.editedItem.SOId}`, formData, {
                headers: {
                  Authorization: AuthKey,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(async (e) => {
                await axios.get(`${IP}/notification/active`, {
                  headers: {
                    Authorization: AuthKey,
                  },
                });
                this.file = Object.assign({}, this.defaultFile);
                this.$emit("close", false);
                this.$emit("update-data", false);
                this.loading = false;
              });
          } else {
            this.loading = true;
            axios
              .post(apiurl, formData, {
                headers: {
                  Authorization: AuthKey,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(async (e) => {
                await axios.get(`${IP}/notification/active`, {
                  headers: {
                    Authorization: AuthKey,
                  },
                });
                this.file = Object.assign({}, this.defaultFile);
                this.$emit("update-data", false);
                this.$emit("close", false);
                this.loading = false;
              });
          }
        }
      });
    },
    // others function
    SubString(str, num) {
      // console.log("str", str);
      var res = "";
      if (str.length > num) {
        res = str.substring(0, num) + "...";
      } else {
        res = str;
      }
      return res;
    },
    SubStringEdit(str, num) {
      // console.log("str", str);
      var res = "";
      var name = str.split(",");
      if (str.length > num) {
        res = name[1].substring(0, num) + "...";
      } else {
        res = str;
      }
      return res;
    },
    close() {
      this.file = Object.assign({}, this.defaultFile);
      this.$emit("close", false);
    },
    chooseFiles(id) {
      if (id == "po") {
        document.getElementById("POFileUpload").click();
      } else if (id == "contract") {
        document.getElementById("ContractFileUpload").click();
      } else if (id == "cost-sheet") {
        document.getElementById("CostSheetFileUpload").click();
      } else if (id == "others") {
        document.getElementById("AttachFileUpload").click();
      }
    },
    async handlePOFileUpload(e) {
      this.state = 0;
      const file = e.target.files;
      this.editedItem.PoFile = file[0];
      this.file.po_name = this.editedItem.PoFile.name;
    },
    async handleContractFileUpload(e) {
      const file = e.target.files;
      this.editedItem.ContractFile = file[0];
      this.file.contract_name = this.editedItem.ContractFile.name;
    },
    async handleCostSheetFileUpload(e) {
      const file = e.target.files;
      this.editedItem.CostSheetFile = file[0];
      this.file.cost_sheet_name = this.editedItem.CostSheetFile.name;
    },
    async handleAttachFileUpload(e) {
      const file = e.target.files;
      this.editedItem.AttachFile = file[0];
      this.file.others_name = this.editedItem.AttachFile.name;
    },
  },
  props: [
    "RunningNumberItems",
    "SaleInformation",
    "items",
    "editedItem",
    "Email",
    "editedIndex",
  ],
  created() {
    if (this.editedItem.SLAOther != null) {
      this.SLAOther = JSON.parse(JSON.stringify(this.editedItem.SLAOther));
    }
    if (this.editedItem.PreventiveMAOther != null) {
      this.PreventiveMAOther = JSON.parse(
        JSON.stringify(this.editedItem.PreventiveMAOther)
      );
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../css/saleorders.scss";
</style>