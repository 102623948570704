<template>
  <div class="expansion-panels">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Skill</v-col>
        <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn color="success" block @click="Action('create', null)">
            <v-icon class="mr-2">mdi-plus</v-icon>Create
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px">
        ยังไม่มีความสามารถ
      </p>
    </div>
    <v-card-title v-if="listItems.length > 0">
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="listItems"
      :search="search"
      class="elevation-1"
      v-if="listItems.length > 0"
    >
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-2"
              v-on="on"
              style="cursor: pointer"
              @click="Action('update', item)"
              >edit</v-icon
            >
          </template>
          <span>Update</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              v-on="on"
              style="cursor: pointer"
              @click="DeleteSkill(item)"
              >delete</v-icon
            >
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- dialog -->
    <v-dialog v-model="dialog" width="100%" max-width="500">
      <v-card>
        <v-card-title v-if="action == 'create'">Create Skill</v-card-title>
        <v-card-title v-if="action == 'update'">Update Skill</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Name"
                outlined
                v-model="skill.name"
                name="name"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('name')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("name") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                label="Type"
                outlined
                v-model="skill.type"
                name="type"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('type')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("type") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-select
                label="Expertise"
                :items="expertise"
                outlined
                v-model="skill.expertise"
                name="expertise"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('expertise')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("expertise") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-textarea
                label="Description"
                outlined
                v-model="skill.description"
                name="description"
                v-validate="'required'"
                dense
                hide-details
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('description')"
                  style="margin-top: 10px; margin-bottom: -10px"
                  >{{ errors.first("description") }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider style="margin-top: 20px"></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            v-if="action == 'create'"
            @click="CreateSkill"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
          <v-btn
            color="warning"
            v-if="action == 'update'"
            @click="UpdateSkill"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../../config";

export default {
  data() {
    return {
      IP: IP,
      user: "",
      headers: [
        { text: "Name", value: "SkillName" },
        { text: "Type", value: "SkillType" },
        { text: "Description", value: "SkillDescription" },
        { text: "Expertise", value: "SkillExpertise" },
        { text: "Actions", value: "action", sortable: false },
      ],
      listItems: [],
      search: "",
      dialog: false,
      expertise: ["None", "Normal", "Medium", "Expert"],
      skill: {},
      action: "",
      loading: false,
    };
  },
  methods: {
    async CreateSkill() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          Swal.fire({
            title: "Are you sure ?",
            text: "คุณต้องการบันทึกความสามารถนี้หรือไม่ !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.CreateProcess().then(async (response) => {
                if (response == "success") {
                  // continue process
                  await this.Clear();
                  await this.GetSkillByEmail();
                  Swal.fire("บันทึกข้อมูลเสร็จสิ้น !", "", "success");
                }
              });
            }
          });
        }
      });
    },
    CreateProcess() {
      return new Promise(async (resolve, reject) => {
        this.skill.email = this.email;
        axios({
          method: "post",
          url: IP + "/user-management/create-skill",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            skill: this.skill,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    UpdateSkill() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          Swal.fire({
            title: "Are you sure ?",
            text: "คุณต้องการแก้ไขความสามารถนี้หรือไม่ !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.UpdateProcess().then(async (response) => {
                if (response == "success") {
                  await this.Clear();
                  await this.GetSkillByEmail();
                  Swal.fire("แก้ไขข้อมูลเสร็จสิ้น !", "", "success");
                }
              });
            }
          });
        }
      });
    },
    UpdateProcess() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/update-skill",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            skill: this.skill,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    DeleteSkill(data) {
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบความสามารถ ${data.SkillName} หรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          this.DeleteProcess(data.SkillID).then(async (response) => {
            if (response == "success") {
              await this.Clear();
              await this.GetSkillByEmail();
              Swal.fire("ลบข้อมูลเสร็จสิ้น !", "", "success");
            }
          });
        }
      });
    },
    DeleteProcess(id) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/delete-skill",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            id: id,
            email: this.email,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    Clear() {
      this.skill = {};
      this.dialog = false;
    },
    Action(action, data) {
      this.skill = {};
      this.action = action;
      if (action == "update") {
        this.skill = {
          name: data.SkillName,
          type: data.SkillType,
          description: data.SkillDescription,
          expertise: data.SkillExpertise,
          id: data.SkillID,
          email: data.EmployeeEmail,
        };
      }
      this.dialog = !this.dialog;
    },
    GetSkillByEmail() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/get-skill-by-email",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.email,
          },
        }).then((response) => {
          this.listItems = response.data;
          resolve(true);
        });
      });
    },
    async start() {
      this.loading = true;
      await this.GetSkillByEmail().then((response) => {
        var that = this;
        setTimeout(function () {
          that.loading = false;
        }, 1000);
      });
    },
  },
  props: ["email"],
  created() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";

.expansion-panels {
  margin-bottom: 30px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>