<template>
  <div>
    <v-app-bar
      :dark="$router.currentRoute.name == 'chat'"
      flat
      class="header-v2-area"
    >
      <v-toolbar-title
        @click="
          goTo('home');
          setClose();
          setEnableScroll();
        "
        style="cursor: pointer; width: 100px"
      >
        <v-img
          :src="require('../assets/stel-logo-white.png')"
          aspect-ratio="1.4"
          width="70"
          v-if="$router.currentRoute.name == 'chat'"
        ></v-img>
        <v-img
          :src="require('../assets/stel-logo-black.png')"
          aspect-ratio="1.4"
          width="70"
          v-else
        ></v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div v-if="width >= 600">
        <v-tooltip bottom style="z-index: 999">
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              class="ml-1 mr-1 menu-icon-area"
              :id="
                $router.currentRoute.name == 'chat'
                  ? 'menu-btn-dark'
                  : 'menu-btn'
              "
              text
              v-on="on"
              to="/blog"
              :style="[
                $route.path == '/blog'
                  ? {
                      color: 'white',
                      background: '#65a000',
                    }
                  : {},
              ]"
            >
              <v-icon>mdi-book-open-page-variant</v-icon>
            </v-btn>
          </template>
          <span>Blog</span>
        </v-tooltip>

        <v-tooltip bottom style="z-index: 999">
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              class="ml-1 mr-1 menu-icon-area"
              :id="
                $router.currentRoute.name == 'chat'
                  ? 'menu-btn-dark'
                  : 'menu-btn'
              "
              text
              v-on="on"
              to="/project"
              :style="[
                $route.path == '/project'
                  ? {
                      color: 'white',
                      background: '#65a000',
                    }
                  : {},
              ]"
            >
              <v-icon>mdi-calendar-text</v-icon>
            </v-btn>
          </template>
          <span>Task</span>
        </v-tooltip>

        <v-tooltip bottom style="z-index: 999">
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              class="ml-1 mr-1 menu-icon-area"
              :id="
                $router.currentRoute.name == 'chat'
                  ? 'menu-btn-dark'
                  : 'menu-btn'
              "
              text
              @click="setDrawer(!drawer)"
              v-on="on"
            >
              <v-icon>mdi-account-supervisor</v-icon>
            </v-btn>
          </template>
          <span>Employee</span>
        </v-tooltip>

        <v-btn
          x-small
          class="ml-1 mr-1 menu-icon-area"
          :id="
            $router.currentRoute.name == 'chat' ? 'menu-btn-dark' : 'menu-btn'
          "
          text
          v-if="notification.length == 0"
          @click="$router.push('/notification')"
          :style="[
            $route.path == '/notification'
              ? {
                  color: 'white',
                  background: '#65a000',
                }
              : {},
          ]"
        >
          <v-icon>mdi-bell</v-icon>
        </v-btn>
        <v-menu offset-y v-if="notification.length > 0">
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              class="ml-1 mr-1 menu-icon-area"
              :id="
                $router.currentRoute.name == 'chat'
                  ? 'menu-btn-dark'
                  : 'menu-btn'
              "
              text
              v-on="on"
              :style="[
                $route.path == '/notification'
                  ? {
                      color: 'white',
                      background: '#65a000',
                    }
                  : {},
              ]"
            >
              <v-badge
                content="9+"
                value="9+"
                v-if="noti_count > 9"
                color="error"
                overlap
              >
                <v-icon>mdi-bell</v-icon>
              </v-badge>
              <v-badge
                :content="noti_count"
                :value="noti_count"
                v-if="noti_count <= 9"
                color="error"
                overlap
              >
                <v-icon>mdi-bell</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <v-card
            class="mx-auto"
            max-width="400"
            v-show="notification.length > 0"
            tile
            style="text-align: left; margin-top: 15px"
          >
            <v-list-item
              two-line
              v-for="(item, index) in notification"
              :key="index"
              v-show="index < 3"
              @click="Notification(item)"
              :class="{ focus: item.Status == 'Unread' }"
            >
              <v-list-item-content>
                <div style="margin-bottom: 10px; font-size: 12px">
                  <div style="float: left">
                    <b>
                      <v-icon
                        small
                        color="warning"
                        v-if="item.Status == 'Unread'"
                        >mdi-alert-circle</v-icon
                      >
                      <v-icon small color="success" v-if="item.Status == 'Read'"
                        >mdi-check-circle</v-icon
                      >
                      {{ item.Module }}
                    </b>
                  </div>
                  <div style="float: right">{{ Moment(item.CreatedDate) }}</div>
                </div>
                <v-list-item-title>{{ item.Title }}</v-list-item-title>
                <!-- <v-list-item-subtitle v-html="item.Description" style="height: 20px;"></v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card
            class="mx-auto"
            max-width="400"
            color="rgb(230, 230, 230)"
            v-show="notification.length > 0"
            tile
          >
            <v-list-item @click="$router.push('/notification')">
              <v-list-item-content>
                <span style="text-align: center">All</span>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-menu>

        <!-- <v-tooltip bottom style="z-index: 999">
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              class="ml-1 mr-1 menu-icon-area"
              :id="
                $router.currentRoute.name == 'chat'
                  ? 'menu-btn-dark'
                  : 'menu-btn'
              "
              text
              v-on="on"
              to="/chat"
              :style="[
                $route.path == '/chat'
                  ? {
                      color: 'white',
                      background: '#65a000',
                    }
                  : {},
              ]"
            >
              <v-icon>mdi-chat</v-icon>
            </v-btn>
          </template>
          <span>Chat</span>
        </v-tooltip> -->

        <v-tooltip bottom v-if="user.Role == 'Admin'" style="z-index: 999">
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              class="ml-1 mr-1 menu-icon-area"
              :id="
                $router.currentRoute.name == 'chat'
                  ? 'menu-btn-dark'
                  : 'menu-btn'
              "
              text
              v-on="on"
              to="/admin"
              :style="[
                $route.path == '/admin'
                  ? {
                      color: 'white',
                      background: '#65a000',
                    }
                  : {},
              ]"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <span>Admin</span>
        </v-tooltip>
      </div>

      <div v-else id="sidemenu">
        <button
          class="sidemenu__btn"
          v-on:click="
            navOpen = !navOpen;
            setDisableScroll();
          "
          v-bind:class="{ active: navOpen }"
        >
          <span class="top"></span>
          <span class="mid"></span>
          <span class="bottom"></span>
        </button>
        <transition name="translateX">
          <nav
            :style="{ height: height + 'px' }"
            class="nav-humberger"
            v-show="navOpen"
          >
            <div class="sidemenu__wrapper">
              <ul class="sidemenu__list">
                <li class="sidemenu__item">
                  <a @click="goToPage('blog')">
                    <v-icon class="mr-2">mdi-book-open-page-variant</v-icon>Blog
                  </a>
                </li>
                <li class="sidemenu__item">
                  <a @click="goToPage('project')">
                    <v-icon class="mr-2">mdi-calendar-text</v-icon>Task
                  </a>
                </li>
                <li class="sidemenu__item">
                  <a
                    @click="
                      setDrawer(!drawer);
                      setClose();
                      setEnableScroll();
                    "
                  >
                    <v-icon class="mr-2">mdi-account-supervisor</v-icon>Employee
                  </a>
                </li>
                <li class="sidemenu__item">
                  <a @click="goToPage('notification')">
                    <v-badge
                      content="9+"
                      value="9+"
                      v-if="noti_count > 9"
                      color="error"
                      overlap
                    >
                      <v-icon>mdi-bell</v-icon>
                    </v-badge>
                    <v-badge
                      :content="noti_count"
                      :value="noti_count"
                      v-if="noti_count <= 9"
                      color="error"
                      overlap
                    >
                      <v-icon>mdi-bell</v-icon> </v-badge
                    >Notification
                  </a>
                </li>
                <li class="sidemenu__item">
                  <a @click="goToPage('chat')">
                    <v-icon class="mr-2">mdi-chat</v-icon>Chat
                  </a>
                </li>
                <li v-if="user.Role == 'Admin'" class="sidemenu__item">
                  <a @click="goToPage('admin')">
                    <v-icon class="mr-2">mdi-account</v-icon>Admin
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </transition>
      </div>

      <div style="margin-right: 10px" />
      |
      <div class="mx-1" />

      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
            :id="
              $router.currentRoute.name == 'chat' ? 'menu-btn-dark' : 'menu-btn'
            "
            style="width: 60px"
            class="ml-1"
            :style="[
              $route.path == `/user-management/${user.EmployeeEmail}` ||
              $route.path == '/my-profile'
                ? {
                    color: 'white',
                    background: '#65a000',
                  }
                : {},
            ]"
          >
            <v-avatar size="27" id="avatar-btn" class="ml-1">
              <span
                v-if="
                  $store.state.currentUser.ProfileImage == null ||
                  $store.state.currentUser.ProfileImage == 'null'
                "
                >{{ short(user.EmployeeEmail) }}</span
              >
              <img
                :src="`${IP}/profile_img/${$store.state.currentUser.ProfileImage}`"
                v-if="
                  $store.state.currentUser.ProfileImage != null &&
                  $store.state.currentUser.ProfileImage != 'null'
                "
              />
            </v-avatar>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card max-width="344" class="mx-auto">
          <v-list :tile="false" nav>
            <v-list-item
              class="btn-to-info"
              @click="
                UserManagement();
                setClose();
                setEnableScroll();
              "
            >
              <v-list-item-avatar color="grey">
                <span
                  v-if="
                    $store.state.currentUser.ProfileImage == null ||
                    $store.state.currentUser.ProfileImage == 'null'
                  "
                  style="color: white"
                  >{{ short(user.EmployeeEmail) }}</span
                >
                <img
                  :src="`${IP}/profile_img/${$store.state.currentUser.ProfileImage}`"
                  v-if="
                    $store.state.currentUser.ProfileImage != null &&
                    $store.state.currentUser.ProfileImage != 'null'
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="headline">
                  {{ $store.state.currentUser.EmployeeName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.state.currentUser.EmployeeEmail }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  class="menu-list-item"
                  block
                  text
                  @click="
                    goToProfile();
                    setClose();
                    setEnableScroll();
                  "
                  >Profile</v-btn
                >
              </v-list-item-title>
              <v-list-item-title>
                <v-btn class="menu-list-item" block text @click="Logout"
                  >Logout</v-btn
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import socketIOClient from "socket.io-client";
import { WindowResize } from "../function/WindowResize.js";
import { IP, AuthKey } from "../config";
import { mapState, mapMutations } from "vuex";
import axios from "axios";
import moment from "moment";

const socket = socketIOClient(IP);

export default {
  data() {
    return {
      IP: IP,
      auth: null,
      user: "",
      width: 0,
      height: 0,
      notification: [],
      navOpen: false,
      scrollStatus: true,
      noti_count: 0,
    };
  },
  computed: {
    ...mapState(["drawer"]),
  },
  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
  },
  methods: {
    Moment(date) {
      if (date != null && date != "") {
        return moment(date).format("lll");
      } else {
        return date;
      }
    },
    GetNotification() {
      return new Promise(async (resolve, reject) => {
        // console.log("user: ", this.user);
        axios({
          method: "post",
          url: `${IP}/notification/get`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            EmployeeEmail: this.user.EmployeeEmail,
          },
        }).then((response) => {
          this.notification = response.data;
          // console.log("notification: ", this.notification);
          this.noti_count = 0;
          for (var i = 0; i < this.notification.length; i++) {
            if (this.notification[i].Status == "Unread") {
              this.noti_count++;
            }
          }
        });
      });
    },
    Notification(item) {
      // console.log("notification item: ", item);
      this.$router.push(`/notification?focus=${item.ID}`);
    },
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "landscape-primary") {
        if (window.screen.width >= 600) {
          this.setEnableScroll();
          this.setClose();
        }
      }
    },
    short(email) {
      var result = "";
      if (email != undefined) {
        var res = email.substr(0, 1);
        result = res.charAt(0).toUpperCase();
      } else {
        result = "UN";
      }
      return result;
    },
    Logout() {
      this.$store.commit("logout");
    },
    goTo(to) {
      if (this.$router.currentRoute.name != to) {
        this.$router.push({ name: to });
      }
    },
    goToPage(to) {
      this.navOpen = false;
      this.scrollStatus = true;
      this.setEnableScroll();
      if (this.$router.currentRoute.name != to) {
        this.$router.push({ name: to });
      }
    },
    setDisableScroll() {
      if (this.scrollStatus == true) {
        document.documentElement.style.overflow = "hidden";
        this.scrollStatus = false;
      } else {
        this.setEnableScroll();
      }
    },
    setEnableScroll() {
      document.documentElement.style.overflow = "auto";
      this.scrollStatus = true;
    },
    goToProfile() {
      if (this.$router.currentRoute.name != "profile-me") {
        this.$router.push({ name: "profile-me" });
      }
    },
    setClose() {
      this.navOpen = false;
    },
    UserManagement() {
      let router_name = this.$router.currentRoute.name;
      let router_params = this.$router.currentRoute.params.email;
      if (
        router_name != "user-management" ||
        router_params != this.user.EmployeeEmail
      ) {
        this.$router.push({
          name: "user-management",
          params: {
            email: this.user.EmployeeEmail,
          },
        });
      }
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    async response() {
      socket.on("active", (notification) => {
        this.GetNotification();
        // this.notification.push(notification)
      });
    },
    start() {
      this.response();
      this.resize();
      this.GetNotification();
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    this.start();
  },
};
</script>

<style scoped lang="scss">
.header-v2 {
  width: 100%;
  z-index: 555;
  margin-bottom: 50px;
  position: fixed;
}

.menu-list-item {
  transition: 0.3s all;
}

.menu-list-item:hover {
  background: #65a000;
  color: white;
}

.btn-to-info {
  cursor: pointer;
  transition: 0.3s all;
}

.btn-to-info:hover {
  background: rgba(240, 240, 240, 0.521);
}

.header-v2-area {
  transition: 0.3s all;
}

.header-v2-area:hover {
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.75);
}

#sidemenu {
  nav {
    width: 100%;
    background: rgb(255, 255, 255);
    position: absolute;
    top: 40;
    left: 0;
  }
  .sidemenu {
    &__btn {
      display: block;
      width: 30px;
      height: 30px;
      // background: rgb(138, 138, 138);
      background: white;
      border: none;
      position: relative;
      appearance: none;
      cursor: pointer;
      outline: none;

      // Hambur ---
      span {
        display: block;
        width: 20px;
        height: 2px;
        margin: auto;
        // background: white;
        background: rgb(138, 138, 138);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.4s ease;

        &.top {
          transform: translateY(-8px);
        }

        &.bottom {
          transform: translateY(8px);
        }
      }
      &.active {
        .top {
          transform: rotate(-45deg);
        }
        .mid {
          transform: translateX(-20px) rotate(360deg);
          opacity: 0;
        }
        .bottom {
          transform: rotate(45deg);
        }
      }
    }

    &__wrapper {
      padding-top: 5px;
      position: relative;
      margin: auto auto;
      top: 45.15%;
      transform: translate(0%, -50%);
    }

    &__list {
      list-style: none;
      padding: 0;
    }

    &__item {
      a {
        text-decoration: none;
        line-height: 1.6em;
        font-size: 1.6em;
        padding: 0.5em;
        display: block;
        color: grey;
        transition: 0.4s ease;
        text-align: center;

        &:hover {
          background: lightgrey;
          color: dimgrey;
        }
      }
    }
  }
}

// Animate slide when click
.translateX-enter {
  transform: translateY(-200px);
  opacity: 0;
}
.translateX-enter-active,
.translateX-leave-active {
  transform-origin: top left 0;
  transition: 0.2s ease;
}
.translateX-leave-to {
  transform: translateY(-200px);
  opacity: 0;
}

.focus {
  background: rgb(240, 240, 240);
}

// update 03/05/2023

.menu-icon-area {
  min-height: 36px;
}

#menu-btn {
  color: #3e3e3e;
  transition: 0.3s all;
}

#menu-btn:hover {
  color: white;
  background: #65a000;
}

#menu-btn-dark {
  color: #fff;
  transition: 0.3s all;
}

#menu-btn-dark:hover {
  color: white;
  background: #65a000;
}

#avatar-btn {
  background: #65a000;
  color: white;
  transition: 0.3s all;
}

#menu-btn:hover #avatar-btn {
  background: white;
  color: #65a000;
}

#menu-btn-dark:hover #avatar-btn {
  background: white;
  color: #65a000;
}
</style>