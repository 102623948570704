<template>
  <div class="holiday">
    <v-container>
      <v-data-table
        :headers="headers"
        :items="listItems"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Holiday</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                  >Add Holiday</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.HolidayName"
                          dense
                          label="HolidayName"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.HolidayDate"
                              label="HolidayYear"
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            v-model="editedItem.HolidayDate"
                            scrollable
                            no-title
                            @change="$refs.menu.save(editedItem.HolidayDate)"
                          ></v-date-picker>
                        </v-menu>
                        <!-- <v-text-field v-model="editedItem.HolidayDate" dense label="HolidayDate"></v-text-field> -->
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          dense
                          v-model="editedItem.Note"
                          label="Note"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      dialog: false,
      menu: false,
      headers: [
        {
          text: "HolidayYear",
          align: "start",
          sortable: false,
          value: "HolidayYear",
        },
        { text: "HolidayName", value: "HolidayName" },
        { text: "HolidayDate", value: "HolidayDate" },
        { text: "Note", value: "Note" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      listItems: [],
      editedIndex: -1,
      editedItem: {
        HolidayYear: "",
        HolidayName: "",
        HolidayDate: "",
        Note: "",
      },
      defaultItem: {
        HolidayYear: "",
        HolidayName: "",
        HolidayDate: "",
        Note: "",
      },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  async created() {
    await this.initialize();
  },

  methods: {
    async initialize() {
      await axios
        .get(`${IP}/api/v1/holiday`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((resp) => {
          for (let i in resp.data) {
            resp.data[i].HolidayDate = this.ConvertDate(
              resp.data[i].HolidayDate
            );
          }
          this.listItems = resp.data;
        });
    },
    editItem(item) {
      this.editedIndex = this.listItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      Swal.fire({
        title: "Are you sure?",

        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2E7D32",
        cancelButtonColor: "#C62828",
        confirmButtonText: "Yes, remove it!",
      }).then(async (result) => {
        if (result.value) {
          const index = await this.listItems.indexOf(item);
          await this.listItems.splice(index, 1);
          await axios.delete(`${IP}/api/v1/holiday/${item.IDHoliday}`, {
            headers: {
              Authorization: AuthKey,
            },
          });
          Swal.fire("Removed!", "Holiday has been removed.", "success");
        }
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    ConvertDate(date) {
      const result = this.$moment(date).format("L");
      return result;
    },
    async save() {
      if (this.editedIndex > -1) {
        await axios.put(`${IP}/api/v1/holiday`, this.editedItem, {
          headers: {
            Authorization: AuthKey,
          },
        });
        await this.initialize();
        // Object.assign(this.listItems[this.editedIndex], this.editedItem);
      } else {
        await axios.post(`${IP}/api/v1/holiday`, this.editedItem, {
          headers: {
            Authorization: AuthKey,
          },
        });
        await this.initialize();
      }
      this.close();
    },
  },
};
</script>

<style>
.holiday {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
}
</style>