import { render, staticRenderFns } from "./Maintenance.vue?vue&type=template&id=20cf4ee7&scoped=true"
var script = {}
import style0 from "./Maintenance.vue?vue&type=style&index=0&id=20cf4ee7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20cf4ee7",
  null
  
)

export default component.exports