<template>
  <div class="chat-gpt">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <v-container>
      <div class="text-header-area">
        <div id="text-header">
          <!-- <v-chip
            class="ma-2"
            color="#11A281"
            dark
            style="position: absolute; transform: translate(-100px, -20px)"
          >
            <a
              href="https://openai.com/blog/chatgpt/"
              style="color: white; text-decoration: none"
              >Chat GPT</a
            >
          </v-chip> -->
          Image Generation
        </div>
        <div id="text-subheader">ระบบสร้างรูปภาพตามคำสั่ง</div>
      </div>
      <div class="content-area">
        <v-row>
          <v-col cols="12" xs="8" sm="10" md="11">
            <v-text-field
              :label="question_label"
              outlined
              v-model="question"
              v-validate="'required'"
              dense
              hide-details
              @keyup.enter="CreateImage"
            />
          </v-col>
          <v-col cols="12" xs="4" sm="2" md="1">
            <v-btn block color="success" @click="CreateImage">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <hr />
          </v-col>
          <v-col cols="12">
            <div v-if="answer != '' && ready" style="text-align: center">
              <v-img
                :lazy-src="answer"
                max-height="1024"
                max-width="1024"
                :src="answer"
              ></v-img>
              <div style="margin: 30px">
                <a
                  download="by-chat-gpt.png"
                  :href="answer"
                  target="_blank"
                  title="by-chat-gpt"
                >
                  Download
                </a>
              </div>
              <!-- <img :src="answer" alt="Image from Chat GPT" /> -->
            </div>
            <div v-if="answer == '' && ready" style="text-align: center">
              มีไอเดียอยากสร้างรูปอะไรมั้ย? ลองพิมพ์ในช่อง
              <b>"{{ question_label }}"</b> และกด <b>ปุ่มเขียว (ส่ง)</b> ดูนะ
              <br /><br />
              <b>ปล.</b> พิมพ์เป็นภาษาอังกฤษจะเร็วกว่านะ
            </div>
            <div v-if="answer == '' && !ready" style="text-align: center">
              รอแปบนะ ...
            </div>
            <div v-if="error_message != ''">
              {{ error_message }}
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";

export default {
  data() {
    return {
      loading: false,
      ready: true,
      question: "A beautiful Cat",
      answer: "",
      raw_answer: "",
      error_message: "",
      question_label: "ให้ Chat GPT สร้างรูปอะไรดี?",
    };
  },
  methods: {
    CreateImage() {
      this.loading = true;
      this.ready = false;
      this.answer = "";
      this.error_message = "";
      axios({
        method: "post",
        url: `${IP}/chat-gpt/create-image`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${AuthKey}`,
        },
        data: {
          prompt: this.question,
        },
      })
        .then((response) => {
          this.raw_answer = JSON.parse(JSON.stringify(response.data));
          this.answer = JSON.parse(JSON.stringify(response.data));
          this.ready = true;
          this.loading = false;
        })
        .catch((error) => {
          this.error_message = error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-gpt {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
}
.text-header-area {
  text-align: center;
  padding: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(220, 220, 220);
}
#text-header {
  font-size: 40px;
}
#text-subheader {
  font-size: 18px;
}

.content-area {
  max-width: 800px;
  margin: 0 auto;
}
</style>