<template>
  <div class="profile">
    <div class="content-header">
      <div class="banner" :style="`height: ${height / 3}px`">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <input
              id="fileUploadBanner"
              type="file"
              accept="image/*"
              hidden
              @change="handleFileUploadBanner"
            />
            <v-btn
              v-on="on"
              @click="chooseFiles('banner')"
              icon
              class="btn-change-banner"
            >
              <v-icon color="grey">mdi-camera</v-icon>
            </v-btn>
          </template>
          <span>Update Banner Image</span>
        </v-tooltip>
        <v-img
          v-if="banner_preview != ''"
          :src="banner_preview"
          :lazy-src="banner_preview"
          class="banner-img"
          :style="`height: ${height / 3}px`"
        ></v-img>
        <v-img
          v-if="
            banner_preview == '' && $store.state.currentUser.BannerImage != null
          "
          :src="`${IP}/banner_img/${$store.state.currentUser.BannerImage}`"
          :lazy-src="`${IP}/banner_img/${$store.state.currentUser.BannerImage}`"
          class="banner-img"
          :style="`height: ${height / 3}px`"
        ></v-img>
      </div>
    </div>
    <div class="content-inside">
      <div class="content-inside-header">
        <v-tabs
          background-color="white"
          show-arrows
          centered
          grow
          class="content-inside-tab"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab @click="tabs = 1">Introduce</v-tab>
          <v-tab @click="tabs = 2">Blogs</v-tab>
        </v-tabs>
        <v-avatar class="profile-avatar" size="150" color="white">
          <v-img
            v-if="profile_preview != ''"
            :src="profile_preview"
            :lazy-src="profile_preview"
          />
          <v-img
            v-if="
              profile_preview == '' &&
              $store.state.currentUser.ProfileImage != null &&
              $store.state.currentUser.ProfileImage != 'null'
            "
            :src="`${IP}/profile_img/${$store.state.currentUser.ProfileImage}`"
            :lazy-src="`${IP}/profile_img/${$store.state.currentUser.ProfileImage}`"
          />
          <v-icon
            size="40"
            v-if="
              profile_preview == '' &&
              ($store.state.currentUser.ProfileImage == null ||
                $store.state.currentUser.ProfileImage == 'null')
            "
            >mdi-account-circle</v-icon
          >
        </v-avatar>
        <div class="content-introduce">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="headline">{{
                user.EmployeeName
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                user.EmployeeEmail
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <div style="border-left: 1px solid rgb(224, 224, 224)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <input
                    id="fileUploadProfile"
                    type="file"
                    accept="image/*"
                    hidden
                    @change="handleFileUploadProfile"
                  />
                  <v-btn
                    v-on="on"
                    icon
                    @click="chooseFiles('profile')"
                    style="margin-left: 10px"
                  >
                    <v-icon>mdi-account-box</v-icon>
                  </v-btn>
                </template>
                <span>Update Profile Image</span>
              </v-tooltip>
            </div>
          </v-list-item>
        </div>
        <div style="border-bottom: 1px solid rgb(224, 224, 224)" />
      </div>
    </div>
    <div class="content-area">
      <Introduce :email="user.EmployeeEmail" v-if="tabs == 1" />
      <Blogs :email="user.EmployeeEmail" v-if="tabs == 2" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../config";
import { WindowResize } from "../function/WindowResize.js";
import Blogs from "../components/Profile/Blogs";
import Introduce from "../components/Profile/Introduce";

export default {
  data() {
    return {
      IP: IP,
      user: "",
      width: 0,
      height: 0,
      profile_preview: "",
      banner_preview: "",
      tabs: 1,
      profile_img: "",
      banner_img: "",
    };
  },
  components: {
    Blogs,
    Introduce,
  },
  methods: {
    chooseFiles(type) {
      if (type == "profile") {
        document.getElementById("fileUploadProfile").click();
      } else if (type == "banner") {
        document.getElementById("fileUploadBanner").click();
      }
    },
    async handleFileUploadProfile(e) {
      const file = e.target.files;
      this.profile_img = file[0];
      this.profile_preview = await this.toBase64(file[0]);
      await this.UpdateProfileImage().then((response) => {
        this.user.ProfileImage = response;
        this.$store.commit("updateProfile", this.user);
      });
    },
    async UpdateProfileImage() {
      return new Promise(async (resolve, reject) => {
        const data = new FormData();
        const img = this.profile_img;
        if (img != "") {
          data.append("profile_img", img);
          data.append("email", this.user.EmployeeEmail);
          if (this.user.ProfileImage != null) {
            data.append("old_img", this.user.ProfileImage);
          } else {
            data.append("old_img", null);
          }
          axios
            .post(`${IP}/update-profile-img`, data, {
              headers: {
                Authorization: AuthKey,
              },
            })
            .then(async (response) => {
              resolve(response.data);
            });
        }
      });
    },
    async handleFileUploadBanner(e) {
      const file = e.target.files;
      this.banner_img = file[0];
      this.banner_preview = await this.toBase64(file[0]);
      await this.UpdateBannerImage().then((response) => {
        this.user.BannerImage = response;
        this.$store.commit("updateProfile", this.user);
      });
    },
    async UpdateBannerImage() {
      return new Promise(async (resolve, reject) => {
        const data = new FormData();
        const img = this.banner_img;
        if (img != "") {
          data.append("banner_img", img);
          data.append("email", this.user.EmployeeEmail);
          if (this.user.BannerImage != null) {
            data.append("old_img", this.user.BannerImage);
          } else {
            data.append("old_img", null);
          }
          axios
            .post(`${IP}/update-banner-img`, data, {
              headers: {
                Authorization: AuthKey,
              },
            })
            .then(async (response) => {
              resolve(response.data);
            });
        }
      });
    },
    toBase64(file) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    start() {
      this.resize();
      this.user = this.$store.getters.currentUser;
    },
  },
  created() {
    this.start();
  },
};
</script>

<style scoped>
.profile {
  margin-top: 60px;
  width: 100%;
}

.banner {
  width: 100%;
  /* height: 300px; */
  background: rgb(207, 207, 207);
  display: block;
}

.banner-img {
  width: 100%;
  /* height: 300px; */
}

.profile-avatar {
  width: 100px;
  border: 3px solid white;
  left: 50%;
  margin-left: -75px;
  margin-top: -150px;
  z-index: 100;
  transition: 0.5s all;
}

.content-inside-header {
  transition: 0.3s all;
}

.content-inside-tab {
  opacity: 0.5;
  transition: 0.5s all;
}

.content-inside-tab:hover {
  opacity: 1;
}

.content-inside-header:hover .profile-avatar {
  opacity: 1;
  margin-top: -290px;
}

.content-introduce {
  width: 90%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
  margin-top: -10px;
}

.btn-change-banner {
  position: absolute;
  margin: 20px;
  right: 0;
  z-index: 100;
}
</style>