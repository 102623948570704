<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-container>
      <div class="content-area">
        <v-row style="border-bottom: 1px solid rgb(216, 216, 216)">
          <v-col cols="auto" style="font-size: 30px" v-if="!crud"
            >Exams Part</v-col
          >
          <v-col
            cols="auto"
            style="font-size: 30px"
            v-if="crud && action == 'create'"
            >Create New Exams Part</v-col
          >
          <v-col
            cols="auto"
            style="font-size: 30px"
            v-if="crud && action == 'update'"
            >Update Exams Part</v-col
          >
          <v-col style="width: 100%; padding: 0px 20px 0px 20px">
            <v-text-field
              v-model="search"
              label="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
              v-if="!crud"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <v-btn
              color="success"
              block
              style="float: right"
              v-if="!crud"
              @click="Actions('create')"
            >
              <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
            </v-btn>
            <v-btn
              color="primary"
              block
              style="float: right"
              v-if="crud"
              @click="Back"
            >
              <v-icon style="margin: 0px 5px 0px -5px">mdi-arrow-left</v-icon
              >Back
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="!crud">
          <!-- data table -->
          <div v-if="listItems.length == 0">
            <p style="font-size: 20px; text-align: center; margin: 40px">
              {{ message }}
            </p>
          </div>
          <v-data-table
            :headers="headers"
            :items="listItems"
            :search="search"
            class="elevation-1"
            style="margin-top: 30px"
            v-if="listItems.length > 0"
          >
            <template v-slot:item.OfExam="{ item }">{{
              ExamIDToExamName(item.OfExam)
            }}</template>
            <template v-slot:item.action="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-cogs</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="Actions('update', item)">
                    <v-list-item-title>Update</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="DeleteExamPart(item.ID)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
        <div v-if="crud">
          <ExamPart :ExamPartData="updateItem" @update-data="Back" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../../config";
import ExamPart from "./CRUDComponent/ExamPart";

export default {
  data() {
    return {
      search: "",
      listItems: [],
      headers: [
        { text: "Exam Part Name", value: "ExamPartTitle" },
        { text: "Of Exam Name", value: "OfExam" },
        { text: "Actions", value: "action", sortable: false },
      ],
      message: "Not Exam part yet.",
      crud: false,
      action: "",
      loading: false,
      examItems: [],
      updateItem: null,
    };
  },
  components: {
    ExamPart,
  },
  methods: {
    Back() {
      this.crud = false;
      this.Start();
    },
    Actions(action, item) {
      if (action == "create") {
        this.updateItem = null;
        this.action = action;
        this.crud = true;
      } else if (action == "update") {
        this.updateItem = item;
        this.action = action;
        this.crud = true;
      }
    },
    ExamIDToExamName(id) {
      for (var i = 0; i < this.examItems.length; i++) {
        if (this.examItems[i].ID == id) {
          return this.examItems[i].ExamTitle;
        }
      }
    },
    DeleteExamPart(id) {
      this.$swal
        .fire({
          title: "Are you sure ?",
          text: `Do you want to delete this exam part?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then(async (result) => {
          if (result.value) {
            this.loading = true;
            console.log("delete: ", id);
            axios({
              method: "DELETE",
              url: `${IP}/exam-part/delete/${id}`,
              headers: {
                Authorization: AuthKey,
              },
            }).then((response) => {
              this.Start();
              this.loading = false;
              this.$swal.fire("Delete exam part success !", "", "success");
            });
          }
        });
    },
    GetAllExam() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/exam/get-all`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            console.log("examItems: ", response.data);
            this.examItems = response.data;
            resolve(true);
          });
      });
    },
    GetAllExamPart() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/exam-part/get-all`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then(async (response) => {
            let result = response.data;
            for (var i = 0; i < result.length; i++) {
              // result[i].ExamPartDescription = await atob(result[i].ExamPartDescription)
              result[i].ExamPartDescription = await Buffer.from(
                result[i].ExamPartDescription,
                "base64"
              ).toString();
            }
            this.listItems = result;
            console.log("listItems: ", this.listItems);
            resolve(true);
          });
      });
    },
    async Start() {
      this.user = this.$store.getters.currentUser;
      this.message = "Loading...";
      await this.GetAllExam().then((response) => {
        this.GetAllExamPart().then(() => {
          this.message = "No exam part yet.";
        });
      });
    },
  },
  created() {
    this.Start();
  },
};
</script>

<style lang="scss" scoped>
.content-area {
  width: 95%;
  margin: 0 auto;
}
</style>