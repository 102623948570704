<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn color="grey" dark v-on="on" icon>
          <v-badge
          :content="notification.length"
          :value="notification.length"
          color="error"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
        </v-btn>
      </template>
      <v-card class="mx-auto" max-width="400" tile style="text-align: left;">
        <v-list-item two-line v-for="(item, index) in notification" :key="index" @click="Notification(item)">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import socketIOClient from "socket.io-client";
import { IP, AuthKey } from "../../config";
const socket = socketIOClient(IP);
export default {
  data() {
    return {
      notification: [],
      IP: IP,
    };
  },
  methods: {
    Notification(item){
      console.log(item)
    },
    AddNotification() {
      this.notification.push("message");
    },
    ResetNotification() {
      this.notification = [];
    },
    async response() {
      socket.on("new-notification", (notification) => {
        this.notification.push(notification);
      });
    },
  },
  created() {
    this.response();
  },
};
</script>