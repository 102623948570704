<template>
  <div class="profile-blogs">
    <!-- loading -->
    <div
      v-if="loading"
      :style="`text-align: center; margin-top: ${this.height / 6}px;`"
    >
      <v-progress-circular
        :width="3"
        color="green"
        indeterminate
      ></v-progress-circular>
    </div>
    <!-- content area -->
    <v-container v-if="!loading">
      <v-row>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          xl="3"
          v-for="n in blogs"
          :key="n.NewsID"
        >
          <v-card>
            <v-list-item>
              <v-list-item-avatar color="grey">
                <span
                  v-if="n.ProfileImage == null || n.ProfileImage == 'null'"
                  style="color: white"
                  >{{ short(n.NewsCreatedBy) }}</span
                >
                <v-img
                  v-if="n.ProfileImage != null && n.ProfileImage != 'null'"
                  :src="`${IP}/profile_img/${n.ProfileImage}`"
                  :lazy-src="`${IP}/profile_img/${n.ProfileImage}`"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="headline">{{
                  Decode(n.NewsTitle)
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  n.EmployeeName
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-img
              :src="`${IP}/news_file/${n.NewsBackgroundImage}`"
              height="194"
            ></v-img>

            <v-card-text>
              <div class="ma-2" id="description-area">
                {{ Decode(n.NewsDescription) }}
              </div>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <p style="margin-top: 10px">
                    {{ Others.ConvertDate(n.NewsCreatedDate) }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-top: -20px; margin-bottom: 30px"
                >
                  <v-chip
                    class="ma-2"
                    small
                    color="indigo"
                    text-color="white"
                    v-for="t in n.NewsTag"
                    :key="t"
                    >{{ t }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="card-actions">
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    color="red"
                    @click="deleteBlog(n)"
                    v-if="user.EmployeeEmail == email"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete News</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    color="primary"
                    @click="updateBlog(n.NewsPath)"
                    v-if="user.EmployeeEmail == email"
                  >
                    <v-icon>mdi-update</v-icon>
                  </v-btn>
                </template>
                <span>Update News</span>
              </v-tooltip>
              <v-btn text color="deep-purple" @click="readBlog(n.NewsPath)">
                <v-icon style="margin-right: 10px">mdi-book-open-variant</v-icon
                >Read
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import { Others } from "../../function/Others";
import Swal from "sweetalert2";
import { WindowResize } from "../../function/WindowResize.js";

export default {
  data() {
    return {
      Others: Others,
      IP: IP,
      blogs: [],
      user: "",
      loading: true,
      width: 0,
      height: 0,
    };
  },
  methods: {
    short(email) {
      var res = email.substr(0, 1);
      var result = res.charAt(0).toUpperCase();
      return result;
    },
    readBlog(id) {
      this.$router.push({
        name: "read-blog",
        params: {
          id: id,
        },
      });
    },
    updateBlog(id) {
      this.$router.push({
        name: "update-blog",
        params: {
          id: id,
        },
      });
    },
    deleteBlog(data) {
      return new Promise(async (resolve, reject) => {
        Swal.fire({
          title: "คุณต้องการดำเนินการต่อหรือไม่ ?",
          text: `คุณต้องการลบเนื้อหาหรือไม่ !`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.value) {
            var content_array = [];
            axios({
              method: "post",
              url: `${IP}/select-news-content-by-id`,
              headers: {
                Authorization: AuthKey,
              },
              data: {
                id: data.NewsID,
              },
            }).then((response) => {
              console.log(response.data);
              if (response.data.length > 0) {
                response.data.forEach(function (val, i) {
                  content_array.push(val.ContentLocation);
                }, this);
              }
              axios({
                method: "post",
                url: `${IP}/delete-news`,
                headers: {
                  Authorization: AuthKey,
                },
                data: {
                  id: data.NewsID,
                  content_array: content_array,
                  title_img: data.NewsBackgroundImage,
                },
              }).then((response) => {
                if (response.data == "success") {
                  console.log("delete !");
                  this.GetNewsByEmail();
                }
              }, this);
            }, this);
          }
        }, this);
      }, this);
    },
    GetNewsByEmail() {
      this.blogs = "";
      axios({
        method: "post",
        url: `${IP}/select-news-by-email`,
        headers: {
          Authorization: AuthKey,
        },
        data: {
          email: this.email,
        },
      }).then((response) => {
        const blogs = response.data;
        var array = [];
        blogs.forEach(function (val, i) {
          val.NewsTag = JSON.parse(val.NewsTag);
          if (val.PublishStatus == "Draft") {
            if (val.NewsCreatedBy == this.user.EmployeeEmail) {
              array.push(val);
            }
          } else {
            array.push(val);
          }
        }, this);
        this.blogs = array;
        this.loading = false;
      });
    },
    Decode(data) {
      var result = Buffer.from(data, "base64").toString();
      return result;
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    start() {
      this.resize();
      this.user = this.$store.getters.currentUser;
      this.GetNewsByEmail();
    },
  },
  props: ["email"],
  created() {
    this.start();
  },
};
</script>

<style scoped>
#description-area {
  text-indent: 20px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-actions {
  width: 100%;
  position: absolute;
  bottom: 0;
}
</style>