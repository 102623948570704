const axios = require('axios')
const { IP, AuthKey } = require('../config')

const TpAxios = {
    Get(path){
        return new Promise(async (resolve, reject) => {
            axios.get(`${IP}${path}`, {
                headers: {
                    Authorization: AuthKey
                }
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                resolve(error.response)
            })
        })
    },
    Post(path, data){
        return new Promise(async (resolve, reject) => {
            axios.post(`${IP}${path}`, data, {
                headers: {
                    Authorization: AuthKey
                }
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                resolve(error.response)
            })
        })
    },
    Put(path, data){
        return new Promise(async (resolve, reject) => {
            axios.put(`${IP}${path}`, data, {
                headers: {
                    Authorization: AuthKey
                }
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                resolve(error.response)
            })
        })
    },
    Delete(path, data){
        return new Promise(async (resolve, reject) => {
            axios.delete(`${IP}${path}`, {
                data: data,
                headers: {
                    Authorization: AuthKey
                }
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                resolve(error.response)
            })
        })
    }
}

module.exports = {
    TpAxios
}