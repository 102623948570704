<template>
  <div class="container">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <vue-web-cam
      ref="webcam"
      :device-id="deviceId"
      width="100%"
      @started="onStarted"
      @stopped="onStopped"
      @error="onError"
      @cameras="onCameras"
      @camera-change="onCameraChange"
      hidden
    />
    <p style="color: red">{{ error_message }}</p>
    <v-btn text v-if="btn_error == true" @click="TryFaceLogin" color="red">
      <v-icon style="margin-right: 5px">mdi-face</v-icon>Try Again !
    </v-btn>
    <!-- <div class="row">
      <div class="col-md-12">
        <button type="button" class="btn btn-primary" @click="onCapture">Capture Photo</button>
        <button type="button" class="btn btn-danger" @click="onStop">Stop Camera</button>
        <button type="button" class="btn btn-success" @click="onStart">Start Camera</button>
      </div>
    </div>-->
    <!-- <figure class="figure">
      <img :src="img" class="img-responsive" />
    </figure>-->
  </div>
</template>

<script>
import { WebCam } from "vue-web-cam";
import axios from "axios";
import { IP, AuthKey } from "../../config";

export default {
  name: "App",
  components: {
    "vue-web-cam": WebCam,
  },
  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      error: false,
      IP: IP,
      loading: false,
      btn_error: false,
      error_message: "",
    };
  },
  computed: {
    device: function () {
      return this.devices.find((n) => n.deviceId === this.deviceId);
    },
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: async function () {
      // Once we have a list select the first one
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
        const self = this;
        this.loading = true;
        setTimeout(async function () {
          await self.onCapture();
          await self.onStop();
          await self.Login();
        }, 3000);
      }
    },
  },
  methods: {
    async TryFaceLogin() {
      this.loading = true;
      Promise.resolve()
        .then(() => {
          this.onStart();
        })
        .then(() => {
          setTimeout(() => {
            this.onCapture();
            this.onStop();
            this.Login();
          }, 3000);
        });
    },
    async onCapture() {
      this.img = this.$refs.webcam.capture();
    },
    onStarted(stream) {
      //   console.log("On Started Event", stream);
    },
    onStopped(stream) {
      //   console.log("On Stopped Event", stream);
    },
    async onStop() {
      this.$refs.webcam.stop();
      console.log("stop camera");
    },
    async onStart() {
      this.$refs.webcam.start();
      console.log("start camera");
    },
    async onError(error) {
      this.error = true;
      console.log("On Error Event", error);
    },
    async onCameras(cameras) {
      this.devices = cameras;
      //   console.log("On Cameras Event", cameras);
    },
    async onCameraChange(deviceId) {
      this.deviceId = deviceId;
      //   console.log("On Camera Change Event", deviceId);
    },
    async Login() {
      console.log("start login");
      this.error_message = "";
      axios({
        method: "post",
        url: this.IP + "/login-with-face-api",
        headers: {
          Authorization: AuthKey,
        },
        data: {
          image: this.img,
        },
      }).then((response) => {
        const data = response.data;
        console.log("api data : ", data);
        if (data != null) {
          if (data.error) {
            this.btn_error = true;
            this.error_message = data.error.message;
            this.loading = false;
          } else if (data.EmployeeEmail) {
            this.$store.commit("loginSuccess", data);
            this.loading = false;
            location.reload();
          }
        } else {
          this.btn_error = true;
          this.loading = false;
        }
      });
    },
    CountDown() {
      if (this.count > 0) {
        setTimeout(() => {
          this.count -= 1;
          this.CountDown();
        }, 1000);
      }
    },
  },
  created() {},
};
</script>