<template>
  <div>
    <v-expansion-panels v-model="panel" multiple :disabled="disabled">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div>
            <p style="margin-bottom: 9px">Financial Information</p>
            <p id="header-text" ref="header_text" @click="CopyHeader">
              {{ ConvertCurrency(detailItems.so_amount_untaxed) }}
            </p>
            <!-- {{ detailItems.currency }} -->
            <span style="font-size: 16px; margin-left: 5px">THB</span>
            <v-icon small class="ml-1" style="margin-top: -30px"
              >mdi-content-copy</v-icon
            >
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content v-if="!loading">
          <v-row>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <!-- cols="12" xs="12" sm="6" md="3" -->
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Hardware"
                :value="formatCurrency(detailItems.hardware)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Software"
                :value="formatCurrency(detailItems.software)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Implementation"
                :value="formatCurrency(detailItems.implementation)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="PS"
                :value="formatCurrency(detailItems.ps)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="MA Software"
                :value="formatCurrency(detailItems.ma_software)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="MA Warranty"
                :value="formatCurrency(detailItems.ma_warranty)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="MA Service"
                :value="formatCurrency(detailItems.ma_service)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Managed Service"
                :value="formatCurrency(detailItems.managed_service)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Course Training"
                :value="formatCurrency(detailItems.course_training)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Project Training"
                :value="formatCurrency(detailItems.project_training)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="One-Time Service"
                :value="formatCurrency(detailItems.one_time_service)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="3">
              <v-text-field
                dense
                outlined
                label="Marketing"
                :value="formatCurrency(detailItems.marketing)"
                hide-details
                readonly
                color="grey-lighten-2"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                label="Others"
                :value="formatCurrency(detailItems.others)"
                hide-details
                readonly
                color="grey-lighten-2"
                style="margin-bottom: 10px"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { Others } from "../../function/Others";
import data_mapping_json from "../../json/sale_orders/financial_information.json";

export default {
  data() {
    return {
      loading: false,
      readonly: this.ro,
      disabled: false,
      panel: [0],
      data: JSON.parse(JSON.stringify(this.dt)) || {},
      detailItems: {
        total: 0,
      },
    };
  },
  props: ["ro", "dt"],
  methods: {
    PrepareData() {
      return new Promise(async (resolve, reject) => {
        let obj = {};
        for (let dt_json in data_mapping_json) {
          if (data_mapping_json[dt_json] != "") {
            if (this.data[data_mapping_json[dt_json]] != null) {
              obj[dt_json] = JSON.parse(
                JSON.stringify(this.data[data_mapping_json[dt_json]])
              );
            } else {
              obj[dt_json] = 0;
            }
          } else {
            obj[dt_json] = 0;
          }
        }
        resolve(obj);
      });
    },
    CopyHeader() {
      this.disabled = true;
      let text = this.$refs.header_text;
      text = text.innerHTML;
      navigator.clipboard.writeText(text);
      setTimeout(() => {
        this.disabled = false;
        alert("copied");
      }, 500);
    },
    formatCurrency(value) {
      if (value != null && value != undefined) {
        return Others.formatCurrency(value, 2);
      } else {
        return "";
      }
    },
    ConvertCurrency(value) {
      if (value != null && value != undefined) {
        return Others.ConvertCurrency(value);
      } else {
        return "";
      }
    },
  },
  created() {
    this.loading = true;
    this.PrepareData().then((response) => {
      this.detailItems = response;
      this.loading = false;
    });
  },
};
</script>

<style lang="scss">
#header-text {
  font-size: 30px;
  display: inline-block;
}

@media (min-width: 0px) {
  .col.grid-5-custom {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .col.grid-5-custom {
    width: 50%;
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media (min-width: 960px) {
  .col.grid-5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>