<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div style="margin-bottom: 5px">Exam Title</div>
          <v-text-field
            outlined
            v-model="exam.title"
            name="exam-title"
            v-validate="'required'"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              dense
              type="error"
              v-show="errors.has('exam-title')"
              style="margin-top: -10px"
              >{{ errors.first("exam-title") }}</v-alert
            >
          </transition>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        style="margin-top: -40px"
      >
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div style="margin-bottom: 5px">Timing Type</div>
          <v-select
            :items="timing_type"
            v-model="exam.timing_type"
            outlined
            name="timing-type"
            v-validate="'required'"
            v-on:change="ChangeInterval"
          ></v-select>
          <transition name="slide-fade">
            <v-alert
              dense
              type="error"
              v-show="errors.has('timing-type')"
              style="margin-top: -10px"
              >{{ errors.first("timing-type") }}</v-alert
            >
          </transition>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        style="margin-top: -40px"
      >
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div style="margin-bottom: 5px">
            Timing Interval (1.5 = 1 Hr 30 Minute)
          </div>
          <!-- :disabled="exam.timing_type == 'unlimited'" -->
          <v-text-field
            v-model="exam.timing_interval"
            outlined
            type="number"
            v-validate="'between:0,10|required'"
            name="timing-interval"
            :disabled="timing_interval_disable"
            step="0.5"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              dense
              type="error"
              v-show="errors.has('timing-interval')"
              style="margin-top: -10px"
              >{{ errors.first("timing-interval") }}</v-alert
            >
          </transition>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        style="margin-top: -40px"
      >
        <div style="padding: 0px 0px 10px 0px; color: gray">
          <div style="margin-bottom: 5px">Retry</div>
          <v-text-field
            v-model="exam.retry_count"
            outlined
            type="number"
            v-validate="'between:0,10|required'"
            name="exam-retry"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              dense
              type="error"
              v-show="errors.has('exam-retry')"
              style="margin-top: -10px"
              >{{ errors.first("exam-retry") }}</v-alert
            >
          </transition>
        </div>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        xl="12"
        style="margin-top: -40px"
      >
        <v-row>
          <v-col>
            <div style="margin-bottom: 5px; color: gray">Grade Config</div>
          </v-col>
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  @click="ActionsDialog('create')"
                  style="cursor: pointer; float: right"
                  >mdi-plus-circle-outline</v-icon
                >
              </template>
              <span>Add Grade Config</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <div style="border-bottom: 1px solid rgb(216, 216, 216)"></div>
        <div
          style="text-align: center; color: gray; margin-top: 10px"
          v-if="exam.grade_config.length == 0"
        >
          No configuration
        </div>
        <v-data-table
          :headers="headers"
          :items="exam.grade_config"
          class="elevation-1"
          v-if="exam.grade_config.length > 0"
        >
          <template v-slot:item.action="{ item }">
            <v-icon small @click="DeleteGradeConfig(item.key)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <transition name="slide-fade">
          <v-alert
            dense
            type="error"
            style="margin-top: -10px"
            v-if="error_grobal != ''"
            >{{ error_grobal }}</v-alert
          >
        </transition>
      </v-col>
      <v-col cols="12">
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            width="120"
            @click="SaveExam"
            v-if="ExamData == null"
          >
            <v-icon style="margin-right: 5px">mdi-content-save</v-icon>save
          </v-btn>
          <v-btn
            color="success"
            width="120"
            @click="UpdateExam"
            v-if="ExamData != null"
          >
            <v-icon style="margin-right: 5px">mdi-content-save</v-icon>save
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
    <!-- dialog -->
    <v-dialog v-model="dialog_grade_config" max-width="600">
      <v-card>
        <v-card-title v-if="menu == 'create'">Add Grade Config</v-card-title>
        <v-card-title v-if="menu == 'update'">Update Grade Config</v-card-title>
        <v-card-text style="margin-top: 10px">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-select
                :items="grade_type"
                v-model="grade_config.range"
                label="Range"
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
              v-if="grade_config.type != 'between'"
            >
              <v-text-field
                label="Max"
                outlined
                type="number"
                v-model="grade_config.max"
                v-if="grade_config.range == 'less'"
              ></v-text-field>
              <v-text-field
                label="Min"
                outlined
                type="number"
                v-model="grade_config.min"
                v-if="grade_config.range == 'more'"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              style="margin-top: -30px"
              v-if="grade_config.range == 'between'"
            >
              <v-text-field
                label="Min"
                outlined
                type="number"
                v-model="grade_config.min"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              style="margin-top: -30px"
              v-if="grade_config.range == 'between'"
            >
              <v-text-field
                label="Max"
                outlined
                type="number"
                v-model="grade_config.max"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              v-if="
                grade_config.range != null || grade_config.range != undefined
              "
              style="margin-top: -30px"
            >
              <v-text-field
                v-model="grade_config.grade"
                label="Grade"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              v-if="
                grade_config.range != null || grade_config.range != undefined
              "
              style="margin-top: -30px"
            >
              <v-select
                :items="pass_list"
                v-model="grade_config.is_pass"
                item-text="text"
                item-value="value"
                label="Pass"
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <transition name="slide-fade">
                <v-alert
                  dense
                  type="error"
                  v-if="this.error != ''"
                  style="margin-top: -10px"
                  >{{ error }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions style="margin-top: -20px" v-if="menu == 'create'">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog_grade_config = false"
            >cancel</v-btn
          >
          <v-btn color="green darken-1" text @click="SaveGradeConfig"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../../../config";

export default {
  data() {
    return {
      loading: false,
      user: "",
      menu: "",
      exam: {
        grade_config: [],
      },
      error: "",
      error_grobal: "",
      dialog_grade_config: false,
      timing_type: ["limited", "unlimited"],
      grade_type: ["less", "more", "between"],
      pass_list: [
        {
          text: "Pass",
          value: true,
        },
        {
          text: "Fail",
          value: false,
        },
      ],
      grade_list: ["A", "B", "C", "D", "F"],
      grade_config: {},
      headers: [
        { text: "Range", value: "range" },
        { text: "Min", value: "min" },
        { text: "Max", value: "max" },
        { text: "Grade", value: "grade" },
        { text: "Pass", value: "is_pass" },
        { text: "Actions", value: "action", sortable: false },
      ],
      timing_interval: [],
      finish: false,
      timing_interval_disable: false,
    };
  },
  props: ["ExamData"],
  methods: {
    async SaveExam() {
      this.error_grobal = "";
      let array = [
        this.$validator.validate("exam-title"),
        this.$validator.validate("timing-type"),
        this.$validator.validate("exam-retry"),
      ];

      if (this.exam.timing_type == "limited") {
        array.push(this.$validator.validate("timing-interval"));
      }

      const results = Promise.all(array);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        if (this.exam.grade_config.length > 0) {
          for (var i = 0; i < this.exam.grade_config.length; i++) {
            delete this.exam.grade_config[i].key;
            if (this.exam.grade_config[i].min != undefined) {
              this.exam.grade_config[i].min = parseInt(
                this.exam.grade_config[i].min
              );
            }
            if (this.exam.grade_config[i].max != undefined) {
              this.exam.grade_config[i].max = parseInt(
                this.exam.grade_config[i].max
              );
            }
          }

          this.$swal
            .fire({
              title: "Are you sure ?",
              text: `Do you want to create this exam ?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            })
            .then(async (result) => {
              if (result.value) {
                this.loading = true;
                console.log("save: ", this.exam);
                this.exam.create_by = this.user.EmployeeEmail;
                axios({
                  method: "POST",
                  url: `${IP}/exam/create`,
                  headers: {
                    Authorization: AuthKey,
                  },
                  data: {
                    exam: this.exam,
                  },
                }).then(async (response) => {
                  this.loading = false;
                  this.$swal.fire("Create Exam Success!", "", "success");
                  await this.Clear();
                  await this.Start();
                });
              }
            });
        } else {
          this.error_grobal = "Please insert grade config.";
        }
      }
    },
    async UpdateExam() {
      this.error_grobal = "";
      let array = [
        this.$validator.validate("exam-title"),
        this.$validator.validate("timing-type"),
        this.$validator.validate("exam-retry"),
      ];

      if (this.exam.timing_type == "limited") {
        array.push(this.$validator.validate("timing-interval"));
      }

      const results = Promise.all(array);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        if (this.exam.grade_config.length > 0) {
          for (var i = 0; i < this.exam.grade_config.length; i++) {
            delete this.exam.grade_config[i].key;
            if (this.exam.grade_config[i].min != undefined) {
              this.exam.grade_config[i].min = parseInt(
                this.exam.grade_config[i].min
              );
            }
            if (this.exam.grade_config[i].max != undefined) {
              this.exam.grade_config[i].max = parseInt(
                this.exam.grade_config[i].max
              );
            }
          }

          this.$swal
            .fire({
              title: "Are you sure ?",
              text: `Do you want to update this exam ?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            })
            .then(async (result) => {
              if (result.value) {
                this.loading = true;
                console.log("save: ", this.exam);
                axios({
                  method: "POST",
                  url: `${IP}/exam/update`,
                  headers: {
                    Authorization: AuthKey,
                  },
                  data: {
                    exam: this.exam,
                  },
                }).then(async (response) => {
                  this.loading = false;
                  this.$swal.fire("Update Exam Success!", "", "success");
                  this.$emit("update-data");
                });
              }
            });
        } else {
          this.error_grobal = "Please insert grade config.";
        }
      }
    },
    CheckGradeConfig(grade_config) {
      return new Promise(async (resolve, reject) => {
        if (grade_config != undefined) {
          if (
            grade_config.grade != undefined &&
            grade_config.grade != null &&
            grade_config.is_pass != undefined &&
            grade_config.is_pass != null
          ) {
            if (grade_config.range == "less") {
              if (
                grade_config.max != undefined &&
                grade_config.max != null &&
                grade_config.max != ""
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else if (grade_config.range == "more") {
              if (
                grade_config.min != undefined &&
                grade_config.min != null &&
                grade_config.min != ""
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else if (grade_config.range == "between") {
              if (
                grade_config.min != undefined &&
                grade_config.min != null &&
                grade_config.min != "" &&
                grade_config.max != undefined &&
                grade_config.max != null &&
                grade_config.max != ""
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            }
          } else {
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
    },
    SaveGradeConfig() {
      this.error = "";
      this.CheckGradeConfig(this.grade_config).then((response) => {
        if (response == true) {
          this.grade_config.key = this.RandomKey(10);
          this.exam.grade_config.push(this.grade_config);
          this.grade_config = {};
          this.dialog_grade_config = false;
        } else {
          this.error = "Please complete all information.";
        }
      }, this);
    },
    ActionsDialog(menu, item) {
      this.menu = menu;
      if (this.menu == "update") {
        this.grade_config = item;
      } else if (this.menu == "create") {
        this.grade_config = {};
      }
      this.dialog_grade_config = true;
    },
    DeleteGradeConfig(key) {
      for (var i = 0; i < this.exam.grade_config.length; i++) {
        if (this.exam.grade_config[i].key == key) {
          this.exam.grade_config.splice(i, 1);
        }
      }
    },
    UpdateGradeConfig() {
      console.log(this.exam.grade_config);
    },

    ChangeInterval() {
      console.log("change interval!");
      if (this.exam.timing_type == "unlimited") {
        this.timing_interval_disable = true;
        this.exam.timing_interval = null;
      } else {
        this.timing_interval_disable = false;
        this.exam.timing_interval = 1;
      }
      console.log(this.exam.timing_type, this.exam.timing_interval);
    },
    Clear() {
      this.exam = {
        grade_config: [],
      };
      this.$validator.reset();
    },
    RandomKey(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    SetData() {
      console.log("ExamData: ", this.ExamData);
      this.exam.title = this.ExamData.ExamTitle;
      this.exam.timing_type = this.ExamData.TimingType;
      this.exam.timing_interval = this.ExamData.TimingInterval;
      this.exam.retry_count = this.ExamData.Retry;
      this.exam.grade_config = JSON.parse(this.ExamData.GradeConfig);
      this.exam.id = this.ExamData.ID;
      if (this.exam.timing_type == "unlimited") {
        this.timing_interval_disable = true;
      }
    },
    async Start() {
      this.user = this.$store.getters.currentUser;
      if (this.ExamData != null) {
        await this.SetData();
      }
    },
  },
  created() {
    this.Start();
  },
};
</script>