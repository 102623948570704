<template>
  <div class="test">
    <v-select
      :items="listMenu"
      item-text="name"
      solo
      v-model="selected"
      item-value="id"
      label="Select your menu"
      class="select-class"
    ></v-select>
    <br />
    <br />
    <br />
    <div class="show-content-test">
      <p style="text-align: center; font-size: 25px; color: rgb(129, 129, 129); margin-top: 100px;" v-if="selected == 0">ไม่ได้เลือก</p>
      <TestGraph v-if="selected == 1" />
      <DragObject v-if="selected == 2" />
      <Calendars v-if="selected == 3" />
      <FilterTest v-if="selected == 4" />
      <SaleOrders v-if="selected == 5" />
      <Binding v-if="selected == 6" />
      <Draggable v-if="selected == 7" />
      <Notification v-if="selected == 8" />
      <!-- <Dashboard v-if="selected == 9" /> -->
      <!-- <OCRTesseract v-if="selected == 10" /> -->
    </div>
  </div>
</template>

<script>
import TestGraph from "./Test/TestGraph";
import DragObject from './Test/DragObject';
import Calendars from './Test/Calendar';
import FilterTest from './Test/Filter/ShowData';
import SaleOrders from './SaleOrdersNew';
import Binding from './Test/Binding/Parent';
import Draggable from './Test/VueDraggable';
import Notification from './Test/Notification'
// import Dashboard from './Test/Dashboard'
// import OCRTesseract from './Test/OCRTesseract'

export default {
  data() {
    return {
      width: 0,
      height: 0,
      listMenu: [
        {
          name: "Graph",
          id: 1
        },
        {
          name: "Drag Object",
          id: 2
        },
        {
          name: "Calendars",
          id: 3
        },
        {
          name: "Filter",
          id: 4
        },
        {
          name: "SaleOrders",
          id: 5
        },
        {
          name: "Binding",
          id: 6
        },
        {
          name: "Draggable",
          id: 7
        },
        {
          name: "Notification",
          id: 8
        },
        // {
        //   name: "Dashboard",
        //   id: 9
        // },
        // {
        //   name: "OCR Tesseract.js",
        //   id: 10
        // }
      ],
      selected: 0
    };
  },
  components: {
    TestGraph,
    DragObject,
    Calendars,
    FilterTest,
    SaleOrders,
    Binding,
    Draggable,
    Notification,
    // Dashboard
    //OCRTesseract
  },
  methods: {
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
  }
};
</script>

<style scoped>
.test {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.select-class {
  max-width: 400px;
  float: right;
  z-index: 999;
}

.show-content-test{
  width: 100%;
  /* border: 4px solid rgb(129, 129, 129); */
  margin-bottom: 100px;
}
</style>