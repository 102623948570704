<template>
  <div class="alert-message">
    <div class="alert-content" v-for="(alert, index) in array" :key="index">
      <v-alert :type="alert.type">
        {{ alert.text }}
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      array: this.data || [],
    };
  },
  props: ["data"],
};
</script>

<style scoped lang="scss">
.alert-message {
  margin: 30px 0px 30px 0px;
}
</style>