<template>
  <div id="app">
    <v-app>
      <Header
        v-if="user != null && this.$router.currentRoute.name != 'maintenance'"
        style="width: 100%; position: fixed; z-index: 999"
      />
      <Sidebar />
      <v-main>
        <router-view />
      </v-main>
      <!-- <div style="min-height: calc(100vh - 74px)">
        <router-view />
      </div> -->
      <Footer
        v-if="
          this.$router.currentRoute.name != 'maintenance' &&
          this.$router.currentRoute.name != 'chat' &&
          this.$router.currentRoute.name != 'voice-assistant'
        "
      />
    </v-app>
  </div>
</template>

<script>
// import Header from "./components/Header";
import Header from "./components/Header_V2";
import Sidebar from "./components/Sidebar/EmployeeInformation";
import Footer from "./components/Footer";
import { IP, AuthKey } from "./config";
import axios from "axios";

export default {
  name: "app",
  data() {
    return {
      auth: null,
    };
  },
  components: {
    Header,
    Sidebar,
    Footer,
  },
  mounted() {
    setInterval(() => {
      this.Schedule();
    }, 60000);
    this.Schedule();
  },
  methods: {
    SelectUser(email) {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/select-user-data",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: email,
          },
        }).then((response) => {
          const data = response.data;
          resolve(data);
        });
      });
    },
    async Schedule() {
      if (this.user != null) {
        let date = new Date(localStorage.getItem("schedule_time"));
        let now = new Date();
        if (date.getTime() < now.getTime()) {
          // console.log('set new data!')
          const result = await this.SelectUser(this.user.EmployeeEmail);
          if (result != null) {
            if (result.EmployeeEmail) {
              this.$store.commit("loginSuccess", result);
              // console.log('new data: ', this.$store.getters.currentUser)
            }
          }
        }
      }
    },
  },
  created() {
    this.user = this.$store.getters.currentUser;
    // console.log('microsoft: ', JSON.parse(localStorage.getItem('microsoft')))
  },
};
</script>
