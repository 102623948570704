<template>
  <div id="predictive">
    <br>
    <PF/>
  </div>
</template>
<script>
import PF from '../../components/Predictive Form/Predictive_Form.vue'
export default {
  name: 'predictive',
  components: {
    PF
  }
}
</script>
<style scoped>
#predictive {
  position: relative;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #2884b8;
  background-image: linear-gradient(315deg, #2884b8 0%, #d1bad2 74%);
  height: 100%;
}
</style>