<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div v-for="(addFieldExp, k) in addFieldWorkExps.value" :key="k">
        <v-row>
          <v-col cols="12" style="margin-bottom: -20px">
            <v-radio-group style="text-align: right" v-model="addFieldWorkExps.have_exps" row>
              <v-radio label="มี (have)" class="md-6" :value="true"></v-radio>
              <v-radio
                label="ไม่มี (don't have)"
                class="md-6"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="addFieldExp.dateExp1"
              :rules="nameDateExp1"
              label="ตั้งแต่"
              prepend-icon="event"
              v-if="addFieldWorkExps.have_exps"
            ></v-text-field>
            <!-- <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="addFieldExp.dateExp1"
                  :rules="nameDateExp1"
                  label="ตั้งแต่"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="addFieldExp.dateExp1"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
              ></v-date-picker>
            </v-menu>-->
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="addFieldExp.dateExp2"
              :rules="nameDateExp2"
              label="สิ้นสุด"
              prepend-icon="event"
              v-if="addFieldWorkExps.have_exps"
            ></v-text-field>
            <!-- <v-menu
              ref="menu"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="addFieldExp.dateExp2"
                  :rules="nameDateExp2"
                  label="สิ้นสุด"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="addFieldExp.dateExp2"
                :max="new Date().toISOString().substr(0, 10)"
                min="1950-01-01"
              ></v-date-picker>
            </v-menu>-->
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="addFieldExp.companyExp"
              :rules="nameCompanyExp"
              label="ชื่อบริษัท"
              required
              v-if="addFieldWorkExps.have_exps"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="addFieldExp.positionExp"
              :rules="namePositionExp"
              label="ตำแหน่ง"
              required
              v-if="addFieldWorkExps.have_exps"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="addFieldExp.salaryExp"
              :rules="nameSalaryExp"
              label="เงินเดือน"
              required
              v-if="addFieldWorkExps.have_exps"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="addFieldExp.reason"
              :rules="nameReason"
              label="เหตุที่ออก"
              required
              v-if="addFieldWorkExps.have_exps"
            ></v-text-field>
          </v-col>
        </v-row>
        <div align="center">
          <v-btn
            small
            color="error"
            class="mx-2"
            @click="add(k)"
            v-show="addFieldWorkExps.value.length - 1 == k"
            v-if="addFieldWorkExps.have_exps"
            >เพิ่มประวัติการทำงาน</v-btn
          >
          <v-btn
            small
            color="primary"
            class="mx-2"
            @click="remove(k)"
            v-show="k || (!k && addFieldWorkExps.value.length > 1)"
            >ลบ</v-btn
          >
        </div>
      </div>
      <v-card-actions>
        <v-btn class="mx-2" @click="previousState(4)" text>
          <v-icon style="margin-right: 5px">mdi-arrow-left</v-icon>
          previous</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="validate(5)" text
          >Next
          <v-icon style="margin-left: 5px">mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
export default {
  updated() {
    this.$emit("workExp", this.$data.addFieldWorkExps);
  },
  data() {
    return {
      nextStep1: 4,
      valid: true,
      have_exps: true,
      addFieldWorkExps: {
        have_exps: true,
        value: [
          {
            dateExp1: "",
            dateExp2: "",
            companyExp: "",
            positionExp: "",
            salaryExp: "",
            reason: "",
          },
        ],
      },
      menu: false,
      menu1: false,
      educationLevel: [
        "ระดับชั้นมัธยมปลาย",
        "ปวช",
        "ปวส",
        "ปริญญาตรี",
        "ปริญญาโท",
        "ปริญญาเอก",
      ],
      nameDateExp1: [
        (v) => !!v || "Date is required",
        (v) =>
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
            v
          ) || "Please enter the date correctly, such as 20/02/2020.",
      ],
      nameDateExp2: [
        (v) => !!v || "Date is required",
        (v) =>
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
            v
          ) || "Please enter the date correctly, such as 20/02/2020.",
      ],
      nameCompanyExp: [
        (v) => !!v || "CompanyExperience is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "CompanyExperience must be in English and Thai only",
      ],
      nameMajor: [
        (v) => !!v || "Major is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Major must be in English and Thai only",
      ],
      namePositionExp: [
        (v) => !!v || "Position is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Position must be in English and Thai only",
      ],
      nameSalaryExp: [
        (v) => !!v || "Salary is required",
        (v) =>
          /^\d{1,6}(?:\.\d{0,2})?$/.test(v) || "Salary must be numbers only",
      ],
      nameReason: [
        (v) => !!v || "Reason is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Reason must be in English and Thai only",
      ],
    };
  },
  methods: {
    add(e) {
      this.addFieldWorkExps.value.push({
        dateExp1: "",
        dateExp2: "",
        companyExp: "",
        positionExp: "",
        salaryExp: "",
        reason: "",
      });
    },
    remove(index) {
      this.addFieldWorkExps.value.splice(index, 1);
    },
    previousState(e) {
      this.nextStep1 = e - 1;
      this.$emit("previousE1", parseInt(this.nextStep1));
    },
    validate(e) {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == true) {
        this.nextStep1 = e;
        this.$emit("nextE1", parseInt(this.nextStep1));
      }
    },
  },
};
</script>