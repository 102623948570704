<template>
  <div class="profile-introduce">
    <!-- loading -->
    <div
      v-if="loading"
      :style="`text-align: center; margin-top: ${this.height / 6}px;`"
    >
      <v-progress-circular
        :width="3"
        color="green"
        indeterminate
      ></v-progress-circular>
    </div>
    <!-- content area -->
    <div v-if="!loading">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            class="btn-update"
            v-if="user.EmployeeEmail == email"
          >
            <v-icon>mdi-cogs</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="DialogAdd">
            <v-list-item-title>Add Introduce</v-list-item-title>
          </v-list-item>
          <v-list-item @click="update_active = !update_active">
            <v-list-item-title>Update Introduce</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-row>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          v-for="n in introduce_data"
          :key="n.Placement"
        >
          <v-container>
            <v-card>
              <div class="btn-introduce-update" v-if="update_active == true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      style="z-index: 100"
                      color="primary"
                      v-on="on"
                      @click="UpdateIntroduceDialog(n)"
                    >
                      <v-icon dark>mdi-update</v-icon>
                    </v-btn>
                  </template>
                  <span>Update</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      style="z-index: 100"
                      color="red"
                      v-on="on"
                      @click="DeleteIntroduce(n.IntroduceID)"
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </div>
              <div class="content-area" v-if="n.ContentType == 'editor'">
                <v-card-title class="headline">{{
                  n.ContentTitle
                }}</v-card-title>
                <div class="output ql-snow">
                  <div class="ql-editor" v-html="n.ContentData"></div>
                </div>
                <!-- <div>{{ n.ContentData }}</div> -->
              </div>
              <div class="content-area" v-if="n.ContentType == 'radar'">
                <v-card-title class="headline">{{
                  n.ContentTitle
                }}</v-card-title>
                <br />
                <apexcharts
                  width="100%"
                  :options="n.ContentData.options"
                  :series="n.ContentData.series"
                />
              </div>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
      <div
        v-if="message != ''"
        style="
          text-align: center;
          margin-top: 100px;
          margin-bottom: 100px;
          font-size: 25px;
          color: grey;
        "
      >
        {{ message }}
      </div>

      <!-- dialog add introduce -->
      <v-dialog v-model="dialog_add" max-width="600">
        <v-card>
          <v-container>
            <v-card-title>Add Introduce</v-card-title>
            <v-container>
              <v-text-field
                outlined
                label="Title"
                v-model="title"
                v-validate="'required'"
                name="title"
                dense
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('title')"
                  style="margin-top: -20px"
                  >{{ errors.first("title") }}</v-alert
                >
              </transition>
              <v-tabs
                grow
                style="margin-top: -10px"
                v-if="dialog_update == false"
              >
                <v-tab @click="changeType('editor')">Editor</v-tab>
                <v-tab @click="changeType('radar')">Radar</v-tab>
              </v-tabs>
              <div v-if="add_type == 'editor'">
                <vue-editor
                  id="editor"
                  v-model="htmlEditor"
                  :editorToolbar="customToolbar"
                ></vue-editor>
              </div>
              <div v-if="add_type == 'radar'">
                <v-row>
                  <v-col cols="6">
                    <v-text-field label="Label" v-model="radar.label" />
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      label="Score"
                      type="number"
                      v-model="radar.score"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      color="primary"
                      block
                      style="margin-top: 12px"
                      @click="addRadar"
                      >ADD</v-btn
                    >
                  </v-col>
                </v-row>
                <div
                  style="
                    border-bottom: 1px solid rgb(224, 224, 224);
                    border-top: 1px solid rgb(224, 224, 224);
                  "
                >
                  <v-row>
                    <v-col cols="6">Label</v-col>
                    <v-col cols="3">Score</v-col>
                    <v-col cols="3">Delete</v-col>
                  </v-row>
                  <v-row
                    v-for="(n, index) in radar_data"
                    :key="index"
                    style="border-top: 1px solid rgb(224, 224, 224)"
                  >
                    <v-col cols="6">{{ n.label }}</v-col>
                    <v-col cols="3">{{ n.score }}</v-col>
                    <v-col cols="3">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="red"
                        @click="removeItems(n.label)"
                      >
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <br />
              <p style="text-align: center; color: red">{{ error }}</p>
              <v-card-actions class="justify-center">
                <v-btn
                  dark
                  color="green darken-3"
                  class="btn-save"
                  @click="saveContent"
                  v-if="dialog_update == false"
                >
                  <v-icon style="margin-right: 10px"
                    >mdi-content-save-edit</v-icon
                  >save
                </v-btn>
                <v-btn
                  dark
                  color="green darken-3"
                  class="btn-save"
                  @click="updateContent"
                  v-if="dialog_update == true"
                >
                  <v-icon style="margin-right: 10px"
                    >mdi-content-save-edit</v-icon
                  >save
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-container>
        </v-card>
      </v-dialog>
      <vue-editor
        id="editor"
        style="display: none"
        :editorToolbar="customToolbar"
      ></vue-editor>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import { IP, AuthKey } from "../../config";
import VueApexCharts from "vue-apexcharts";
import Swal from "sweetalert2";
import { WindowResize } from "../../function/WindowResize.js";

export default {
  data() {
    return {
      user: "",
      loading: true,
      width: 0,
      height: 0,
      introduce_data: [],
      introduceID: 0,
      dialog_add: false,
      dialog_update: false,
      update_active: false,
      add_type: "editor",
      htmlEditor: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "video"],
        ["clean"], // remove formatting button
      ],
      title: "",
      radar: {
        label: "",
        score: 0,
      },
      radar_data: [],
      save_data: "",
      error: "",
      message: "",
      chartOptions: {
        chart: {
          type: "radar",
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
          },
        },
      },
      series: [
        {
          name: "",
          data: [],
        },
      ],
    };
  },
  components: {
    VueEditor,
    apexcharts: VueApexCharts,
  },
  methods: {
    DialogAdd() {
      this.clear();
      this.dialog_add = !this.dialog_add;
    },
    changeType(type) {
      if (type == "editor") {
        this.radar = {
          label: "",
          score: 0,
        };
        this.radar_data = [];
      } else if (type == "radar") {
        this.htmlEditor = "";
      }
      this.add_type = type;
    },
    saveContent() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.add_type == "editor") {
            this.save_data = {
              title: this.title,
              type: this.add_type,
              content: this.htmlEditor,
            };
          } else if (this.add_type == "radar") {
            var str = JSON.stringify(this.radar_data);
            this.save_data = {
              title: this.title,
              type: this.add_type,
              content: str,
            };
          }
          this.Continue().then((response) => {
            if (response == "success") {
              this.clear();
              this.GetIntroductByEmail();
            }
          });
        }
      });
    },
    Continue() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/insert-introduce`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            data: this.save_data,
            email: this.email,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    updateContent() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.add_type == "editor") {
            this.save_data = {
              title: this.title,
              type: this.add_type,
              content: this.htmlEditor,
            };
          } else if (this.add_type == "radar") {
            var str = JSON.stringify(this.radar_data);
            this.save_data = {
              title: this.title,
              type: this.add_type,
              content: str,
            };
          }
          this.ContinueUpdate().then((response) => {
            if (response == "success") {
              this.clear();
              this.GetIntroductByEmail();
            }
          });
        }
      });
    },
    ContinueUpdate() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/update-introduce`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            data: this.save_data,
            email: this.email,
            introduceID: this.introduceID,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    addRadar() {
      this.error = "";
      this.radar.label = this.radar.label.trim();
      if (this.radar.label != "" && this.radar.score != null) {
        var check = false;

        for (var i = 0; i < this.radar_data.length; i++) {
          if (this.radar_data[i].label == this.radar.label) {
            check = true;
          }
        }

        if (!check) {
          this.radar_data.push(this.radar);
          this.radar = {
            label: "",
            score: 0,
          };
        } else {
          this.error = "Label is already.";
        }
      } else {
        this.error = "Please input your Information";
      }
      console.log(this.radar_data);
    },
    UpdateIntroduceDialog(data) {
      this.dialog_add = true;
      this.dialog_update = true;
      console.log(data);
      this.introduceID = data.IntroduceID;
      this.title = data.ContentTitle;
      this.add_type = data.ContentType;
      if (data.ContentType == "editor") {
        this.htmlEditor = data.ContentData;
      } else if (data.ContentType == "radar") {
        var options = data.ContentData.options.xaxis.categories;
        var series = data.ContentData.series[0].data;
        for (var i = 0; i < options.length; i++) {
          var radar = {
            label: options[i],
            score: series[i],
          };
          this.radar_data.push(radar);
        }
      }
    },
    DeleteIntroduce(id) {
      Swal.fire({
        title: "คุณต้องการดำเนินการต่อหรือไม่ ?",
        text: `คุณต้องการลบเนื้อหานี้หรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          axios({
            method: "post",
            url: `${IP}/delete-introduce`,
            headers: {
              Authorization: AuthKey,
            },
            data: {
              id: id,
            },
          }).then((response) => {
            if (response.data == "success") {
              this.GetIntroductByEmail();
            }
          });
        }
      });
    },
    removeItems(label) {
      for (var i = 0; i < this.radar_data.length; i++) {
        if (this.radar_data[i].label === label) {
          this.radar_data.splice(i, 1);
        }
      }
    },
    clear() {
      this.dialog_update = false;
      this.save_data = "";
      this.add_type = "editor";
      this.htmlEditor = "";
      this.title = "";
      this.radar = {
        label: "",
        score: 0,
      };
      this.radar_data = [];
      this.dialog_add = false;
    },
    GetIntroductByEmail() {
      this.loading = true;
      axios({
        method: "post",
        url: `${IP}/select-introduct-by-email`,
        headers: {
          Authorization: AuthKey,
        },
        data: {
          email: this.email,
        },
      }).then((response) => {
        this.introduce_data = [];
        this.message = "";
        this.introduce_data = response.data;
        console.log("res: ", response.data);
        // var introduce = response.data
        if (this.introduce_data.length > 0) {
          // have data
          this.introduce_data.forEach(async function (val, i) {
            if (val.ContentType == "editor") {
              // editor
              val.ContentData = await this.Decode(val.ContentData);
            } else if (val.ContentType == "radar") {
              //     // radar
              val.ContentData = await this.Decode(val.ContentData);
              var array = JSON.parse(val.ContentData);

              var chartOptions = {
                chart: {
                  type: "radar",
                },
                xaxis: {
                  categories: [],
                  labels: {
                    show: true,
                  },
                },
              };
              var series = [
                {
                  name: "",
                  data: [],
                },
              ];

              array.forEach(function (val_a, x) {
                chartOptions.xaxis.categories.push(val_a.label);
                series[0].name = val.ContentTitle;
                series[0].data.push(parseInt(val_a.score));
              }, this);

              val.ContentData = {
                options: chartOptions,
                series: series,
              };
            }
          }, this);
          this.loading = false;
        } else {
          this.message = "ไม่มีข้อมุล";
        }
      });
    },
    async Decode(data) {
      var result = Buffer.from(data, "base64").toString();
      return result;
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    start() {
      this.resize();
      this.user = this.$store.getters.currentUser;
      this.GetIntroductByEmail();
    },
  },
  props: ["email"],
  created() {
    this.start();
    console.log(this.email);
  },
};
</script>

<style scoped>
.profile-introduce {
  margin-top: 10px;
}

.content-area {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.btn-update {
  float: right;
  margin: 10px;
}

.btn-save {
  width: 100%;
  max-width: 300px;
}

.btn-introduce-update {
  float: right;
  margin: 10px 10px 0px 0px;
}
</style>