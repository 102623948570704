<template>
  <div class="sale-order-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <AdvanceFilter
      :headers="filterHeaders"
      :FilterData="FilterItems"
      @update-data="UpdateFilterData"
      v-if="ready == true"
    />
    <v-data-table
      :headers="headers"
      :items="listItems"
      :single-expand="true"
      :expanded.sync="expanded"
      :search="search"
      item-key="SOId"
      show-expand
      expand-icon="mdi-menu-down"
      class="elevation-1"
      white
    >
      <template v-slot:top>
        <v-toolbar flat color="grey lighten-3">
          <v-toolbar-title v-if="width >= mobileBreakPoint">
            <v-icon class="mb-1" left>mdi-cart</v-icon>Sale Order
          </v-toolbar-title>
          <v-divider
            v-if="width >= mobileBreakPoint"
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="success" right text dark @click="dowloadAsCSV">
            <v-icon left>mdi-arrow-down-bold-box-outline</v-icon>CSV
          </v-btn>
          <v-btn color="primary" right text dark @click="addDialog">
            <v-icon left>mdi-file-document-edit</v-icon>Add
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.SONumber="{ item }">
        <td :style="{ width: width + 'px' }">
          <div>{{ item.SONumber }}</div>
        </td>
      </template>
      <template v-slot:item.SOType="{ item }">
        <td :style="{ width: width + 'px' }">
          <div>{{ item.SOType }}</div>
        </td>
      </template>
      <template v-slot:item.CustomerPODate="{ item }">
        <td :style="{ width: width + 'px' }">
          <div>{{ item.CustomerPODate }}</div>
        </td>
      </template>
      <template v-slot:item.Customer="{ item }">
        <td :style="{ width: width + 'px' }">
          <div>{{ item.Customer }}</div>
        </td>
      </template>
      <template v-slot:item.EndUser="{ item }">
        <td :style="{ width: width + 'px' }">
          <div>{{ item.EndUser }}</div>
        </td>
      </template>
      <template v-slot:item.EndUserStatus="{ item }">
        <td :style="{ width: width + 'px' }">
          <v-chip :color="getEndUserStatusColor(item.EndUserStatus)" dark>{{
            item.EndUserStatus
          }}</v-chip>
        </td>
      </template>
      <template v-slot:item.ProductSold="{ item }">
        <td :style="{ width: width + 'px' }">
          <div>{{ item.ProductSold }}</div>
        </td>
      </template>
      <template v-slot:item.ProjectName="{ item }">
        <td :style="{ width: width + 'px' }">
          <div>{{ item.ProjectName }}</div>
        </td>
      </template>
      <template v-slot:item.POAmount="{ item }">
        <td :style="{ width: width + 'px' }">
          <div style="text-align: right">
            {{ ConvertCurrency(item.POAmount) }}
          </div>
        </td>
      </template>
      <template v-slot:item.StatusFile="{ item }">
        <td :style="{ width: width + 'px' }">
          <v-chip :color="getFileStatusColor(item.StatusFile)" dark>{{
            item.StatusFile
          }}</v-chip>
        </td>
      </template>

      <!-- Expanded table  -->
      <template v-slot:expanded-item="{ headers, item }">
        <td style="padding: 0" :colspan="headers.length" class="td-expand">
          <v-simple-table>
            <template v-slot:default>
              <thead v-if="width >= mobileBreakPoint">
                <tr>
                  <th
                    v-for="(row, rindex) in expandedHeader"
                    :key="rindex"
                    :style="{ width: width + 'px' }"
                    class="text-center d-block d-sm-table-cell"
                  >
                    {{ row.name }}
                  </th>
                  <th
                    :style="{ width: width + 'px' }"
                    class="text-center d-block d-sm-table-cell"
                  >
                    Comment
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    :style="{ width: width + 'px' }"
                    class="expaned-td d-block d-sm-table-cell"
                    v-for="(row, rindex) in expandedHeader"
                    :key="rindex"
                  >
                    <div v-if="width < mobileBreakPoint" class="div-in-expaned">
                      {{ row.name }}
                    </div>
                    {{ item[row.name.replace(/\s/g, "")] }}
                  </td>
                  <td
                    :style="{
                      'padding-bottom': calCommentPadding(item.Comment) + 'px',
                      width: width + 'px',
                    }"
                    class="expaned-td comment-td d-block d-sm-table-cell"
                  >
                    <div v-if="width < mobileBreakPoint" class="div-in-expaned">
                      Comment
                    </div>
                    {{ item.Comment }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon class="mr-1" small @click="watchHistory(item)">history</v-icon>
        <v-icon small @click="editItemSaleOrder(item)">edit</v-icon>
        <v-icon
          v-if="userlevel >= 97 || item.StatusFile == 'Booking'"
          class="ml-2"
          small
          @click="deleteItem(item)"
          >delete</v-icon
        >
      </template>
    </v-data-table>

    <v-dialog width="75%" persistent scrollable v-model="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text class="dialog-area">
          <AddDialog
            v-bind:items="items"
            v-bind:SaleInformation="SaleInformation"
            v-bind:RunningNumberItems="RunningNumberItems"
            v-bind:editedItem="editedItem"
            v-bind:editedIndex="editedIndex"
            v-bind:Email="this.user.EmployeeEmail"
            @update-data="UpdateData"
            @close="close"
            :key="AddDialogKey"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="historyDialog" scrollable max-width="920">
      <template>
        <v-card class="history-card">
          <v-card-text class="history-dialog-area">
            <v-card-title>Files History</v-card-title>
            <v-card class="d-flex flex-row" tile>
              <v-list dense>
                <v-list-item-group v-model="fileItem" color="primary">
                  <v-list-item
                    v-for="(item, i) in fileItems"
                    :key="i"
                    @click="fileItem === i"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>

              <div v-if="fileItem === 0">
                <v-data-table
                  :headers="POFileHeaders"
                  :items="POFileList"
                  class="elevation-1"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="ml-3 mr-3"
                      style="cursor: pointer"
                      @click="DownloadPOFile(item)"
                      >mdi-cloud-download</v-icon
                    >
                  </template>
                </v-data-table>
              </div>
              <div v-if="fileItem === 1">
                <v-data-table
                  :headers="ContractFileHeaders"
                  :items="ContractFileList"
                  class="elevation-1"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="ml-3 mr-3"
                      style="cursor: pointer"
                      @click="DownloadContractFile(item)"
                      >mdi-cloud-download</v-icon
                    >
                  </template>
                </v-data-table>
              </div>
              <div v-if="fileItem === 2">
                <v-data-table
                  :headers="CostSheetFileHeaders"
                  :items="CostSheetFileList"
                  class="elevation-1"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="ml-3 mr-3"
                      style="cursor: pointer"
                      @click="DownloadCostSheetFile(item)"
                      >mdi-cloud-download</v-icon
                    >
                  </template>
                </v-data-table>
              </div>
              <div v-if="fileItem === 3">
                <v-data-table
                  :headers="AttachFileHeaders"
                  :items="AttachFileList"
                  class="elevation-1"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="ml-3 mr-3"
                      style="cursor: pointer"
                      @click="DownloadAttactFile(item)"
                      >mdi-cloud-download</v-icon
                    >
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-card-text>
        </v-card>

        <!-- other -->
      </template>
    </v-dialog>
  </div>
</template>

<script>
import pretty from "prettysize";
import axios from "axios";
import { IP, AuthKey } from "../config";
import moment from "moment";
import Swal from "sweetalert2";
import download from "downloadjs";
import { AccessControl } from "../AccessControl";
import { Others } from "../function/Others";
import { WindowResize } from "../function/WindowResize.js";

const cardName = "SaleOrders";

// dialog
import AddDialog from "../components/SaleOrder/AddDialog";

export default {
  data() {
    return {
      AddDialogKey: null,
      mobileBreakPoint: 600,
      width: 0,
      employeeEmailAndName: [],
      ready: false,
      user: "",
      userlevel: 10,
      loading: false,
      dialog: false,
      historyDialog: false,
      listItems: [],
      RunningNumberItems: [],
      items: {
        Currency: ["THB", "USD"],
        PipeDriveFlagStatus: ["Yes", "No"],
        EndUserStatus: ["New", "Existing"],
      },
      expanded: [],
      expandedHeader: [
        { name: "Sale Name" },
        { name: "Company" },
        // { name: "Currency" },
        { name: "Create By" },
        { name: "Start License Date" },
        { name: "End License Date" },
        { name: "PipeDriveFlag Status" },
      ],
      search: "",
      headers: [
        { text: "SONumber", value: "SONumber" },
        { text: "SOType", value: "SOType" },
        { text: "CustomerPODate", value: "CustomerPODate" },
        { text: "Customer", value: "Customer" },
        { text: "EndUser", value: "EndUser" },
        { text: "EndUserStatus", value: "EndUserStatus" },
        { text: "ProductSold", value: "ProductSold" },
        { text: "ProjectName", value: "ProjectName" },
        { text: "POAmount", value: "POAmount" },
        { text: "StatusFile", value: "StatusFile" },
        { text: "Actions", value: "action", sortable: false },
      ],
      filterHeaders: [
        { text: "SONumber", value: "SONumber" },
        { text: "SOType", value: "SOType" },
        { text: "Company", value: "Company" },
        { text: "CustomerPODate", value: "CustomerPODate" },
        { text: "Customer", value: "Customer" },
        { text: "EndUser", value: "EndUser" },
        { text: "EndUserStatus", value: "EndUserStatus" },
        { text: "ProductSold", value: "ProductSold" },
        { text: "ProjectName", value: "ProjectName" },
        { text: "POAmount", value: "POAmount" },
        { text: "Currency", value: "Currency" },
        { text: "StatusFile", value: "StatusFile" },
        { text: "SaleName", value: "SaleName" },
        { text: "CreateBy", value: "CreateBy" },
        { text: "Actions", value: "action" },
      ],
      fileItem: 0,
      fileItems: [
        { text: "PO file" },
        { text: "Contract file" },
        { text: "CostSheet file" },
        { text: "Attact file" },
      ],
      POFileList: [],
      ContractFileList: [],
      CostSheetFileList: [],
      AttachFileList: [],
      POFileHeaders: [
        { text: "DateTime", value: "createDate" },
        { text: "FileName", value: "namePoFile" },
        { text: "Upload by", value: "uploadBy" },
        { text: "Actions", value: "action", sortable: false },
      ],
      ContractFileHeaders: [
        { text: "DateTime", value: "createDate" },
        { text: "FileName", value: "namecontractFile" },
        { text: "Upload by", value: "uploadBy" },
        { text: "Actions", value: "action", sortable: false },
      ],
      CostSheetFileHeaders: [
        { text: "DateTime", value: "createDate" },
        { text: "FileName", value: "namecostSheetFile" },
        { text: "Upload by", value: "uploadBy" },
        { text: "Actions", value: "action", sortable: false },
      ],
      AttachFileHeaders: [
        { text: "DateTime", value: "createDate" },
        { text: "FileName", value: "nameattachFile" },
        { text: "Upload by", value: "uploadBy" },
        { text: "Actions", value: "action", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {
        update: false,
        PipeDriveFlagStatus: "No",
        Currency: "THB",
        Company: "STelligence",
      },
      SaleInformation: [],
      FilterItems: [],
    };
  },
  components: {
    AddDialog,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Sale Order" : "Edit Sale Order";
    },
  },
  methods: {
    UpdateFilterData(result) {
      if (result.status == true) {
        this.listItems = JSON.parse(result.data);
      } else {
        this.listItems = JSON.parse(this.FilterItems);
      }
    },
    watchHistory(item) {
      this.historyDialog = true;
      this.GetFileSaleOrder(item);
    },
    GetFileSaleOrder(data) {
      this.POFileList = [];
      this.ContractFileList = [];
      this.CostSheetFileList = [];
      this.AttachFileList = [];
      let apiurl = IP + `/api/v1/saleOrder/${data.SOId}`;
      axios({
        method: "get",
        url: apiurl,
        headers: {
          Authorization: AuthKey,
        },
      }).then((resp) => {
        console.log('resp: ', resp.data)
        if (resp.data != null) {
          const {
            createDate,
            namePoFile,
            nameattachFile,
            namecontractFile,
            namecostSheetFile,
            oldFile,
            CreateBy,
            time,
            RowID,
            SOId,
          } = resp.data;
          let localDate = new Date(createDate).toLocaleDateString();
          let localTime = new Date(time).toLocaleTimeString();
          const data1 = {
            SOId: SOId,
            RowID: RowID,
            createDate: localDate + " " + localTime,
            namePoFile: namePoFile,
            uploadBy: CreateBy,
          };
          const data2 = {
            SOId: SOId,
            RowID: RowID,
            createDate: localDate + " " + localTime,
            namecontractFile: namecontractFile,
            uploadBy: CreateBy,
          };
          const data3 = {
            SOId: SOId,
            RowID: RowID,
            createDate: localDate + " " + localTime,
            namecostSheetFile: namecostSheetFile,
            uploadBy: CreateBy,
          };
          const data4 = {
            SOId: SOId,
            RowID: RowID,
            createDate: localDate + " " + localTime,
            nameattachFile: nameattachFile,
            uploadBy: CreateBy,
          };
          if (namePoFile != "") {
            this.POFileList.push(data1);
          }
          if (namecontractFile != "") {
            this.ContractFileList.push(data2);
          }
          if (namecostSheetFile != "") {
            this.CostSheetFileList.push(data3);
          }
          if (nameattachFile != "") {
            this.AttachFileList.push(data4);
          }
          // file.UpdateBy
          for (let i = 0; i < oldFile.length; i++) {
            const file = oldFile[i];
            if (file.PoFile != "" && file.PoFile != null) {
              this.POFileList.push(
                this.createTempToList(file, "PoFile", SOId, CreateBy)
              );
            }
            if (file.ContractFile != "" && file.ContractFile != null) {
              this.ContractFileList.push(
                this.createTempToList(file, "ContractFile", SOId, CreateBy)
              );
            }
            if (file.CostSheetFile != "" && file.CostSheetFile != null) {
              this.CostSheetFileList.push(
                this.createTempToList(file, "CostSheetFile", SOId, CreateBy)
              );
            }
            if (file.AttachFile != "" && file.AttachFile != null) {
              this.AttachFileList.push(
                this.createTempToList(file, "AttachFile", SOId, CreateBy)
              );
            }
          }
        }
      });
    },
    createTempToList(file, name, SOId, CreateBy) {
      let time = new Date(file.HistoryDate).toLocaleTimeString();
      let date = new Date(file.HistoryDate).toLocaleDateString();
      var tmp = {
        SOId: SOId,
        RowID: file.RowID,
        createDate: date + " " + time,
        uploadBy: CreateBy,
      };
      if (name == "PoFile") {
        tmp.namePoFile = file.PoFile;
      } else if (name == "ContractFile") {
        tmp.namecontractFile = file.ContractFile;
      } else if (name == "CostSheetFile") {
        tmp.namecostSheetFile = file.CostSheetFile;
      } else {
        tmp.nameattachFile = file.AttachFile;
      }
      return tmp;
    },

    async UpdateData(e) {
      this.dialog = e;
      await this.initialize();
    },
    ConvertCurrency(int) {
      return int.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      // create a view into the buffer
      var ia = new Uint8Array(ab);
      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    async createTempTagForDownloadFile(url, filename) {
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      this.loading = true;
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      await delay(1000);
      document.body.removeChild(link);
      this.loading = false;
    },
    async dowloadFile(data, file) {
      let apiurl = "";
      if (data.RowID == undefined) {
        apiurl = IP + `/api/v1/download-file/${file}/${data.SOId}/undefined`;
      } else {
        apiurl =
          IP + `/api/v1/download-file/${file}/${data.SOId}/${data.RowID}`;
      }
      let url = "";
      let filename = "";
      axios({
        method: "get",
        url: apiurl,
        headers: {
          Authorization: AuthKey,
        },
      }).then(async (response) => {
        url = response.data.url;
        filename = response.data.fileName;
        await this.createTempTagForDownloadFile(url, filename);
      });
    },
    async DownloadPOFile(data) {
      await this.dowloadFile(data, "poFile");
    },
    async DownloadContractFile(data) {
      await this.dowloadFile(data, "contractFile");
    },
    async DownloadCostSheetFile(data) {
      await this.dowloadFile(data, "costSheetFile");
    },
    async DownloadAttactFile(data) {
      await this.dowloadFile(data, "attachFile");
    },
    close(e) {
      this.dialog = e;
      this.editedItem.update = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    checkHasComment(comment) {
      if (comment === null || comment === "undefined" || comment === "") {
        return "No Comment";
      }
      return comment;
    },
    checkPipeDriveFlagStatus(status) {
      return status == 1 ? "Yes" : "No";
    },
    async editItemSaleOrder(item) {
      axios({
        method: "get",
        url: `${IP}/api/v1/saleOrder/detail/${item.SOId}`,
        headers: {
          Authorization: AuthKey,
        },
      }).then((e) => {
        e.data.update = true;
        this.editedIndex = this.listItems.indexOf(item);
        e.data.Comment = this.checkHasComment(e.data.Comment);
        e.data.PipeDriveFlagStatus = this.checkPipeDriveFlagStatus(
          e.data.PipeDriveFlagStatus
        );
        e.data.CustomerPODate = this.ConvertDate(e.data.CustomerPODate);
        e.data.StartLicenseDate = this.ConvertDate(e.data.StartLicenseDate);
        e.data.EndLicenseDate = this.ConvertDate(e.data.EndLicenseDate);
        if (e.data.SONumber.includes("-B")) {
          e.data.BOIFlag = true;
        } else {
          e.data.BOIFlag = false;
        }
        this.editedItem = Object.assign({}, e.data);
      });
      this.AddDialogKey = this.GenerateKeyID(10);
      this.dialog = true;
    },
    addDialog() {
      this.editedItem.PipeDriveFlagStatus =
        this.defaultItem.PipeDriveFlagStatus;
      this.editedItem.Company = this.defaultItem.Company;
      this.editedItem.Currency = this.defaultItem.Currency;
      this.editedItem.CustomerPODate = this.ConvertDate(Date.now());
      this.AddDialogKey = this.GenerateKeyID(10);
      this.dialog = true;
    },
    deleteItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "This record will still be save as the history data in our database, don't worry if you remove it from this table",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2E7D32",
        cancelButtonColor: "#C62828",
        confirmButtonText: "Yes, remove it!",
      }).then(async (result) => {
        if (result.value) {
          const index = await this.listItems.indexOf(item);
          await this.listItems.splice(index, 1);
          await axios.put(
            `${IP}/api/v1/saleOrder/updateActiveStatus/${item.SOId}`,
            this.user,
            {
              headers: {
                Authorization: AuthKey,
              },
            }
          );
          axios({
            method: "get",
            url: `${IP}/notification/active`,
            headers: {
              Authorization: AuthKey,
            },
          }).then(() => {
            Swal.fire("Removed!", "Sale Order has been removed.", "success");
          });
        }
      });
    },
    async dowloadAsCSV() {
      const accessLevel = AccessControl.getReadAccessLevel(
        cardName,
        this.user.AccessRightsJSON
      );
      if (accessLevel <= 89) {
        alert(
          "You don't have permission to access this section.\nPlease contact the administrator for more information."
        );
      } else {
        let apiUrl = `${IP}/GetSaleOrders/`;
        const headerDataJson = {
          SOId: "SOId",
          SONumber: "SONumber",
          Company: "Company",
          CustomerPODate: "CustomerPODate",
          Customer: "Customer",
          EndUser: "EndUser",
          EndUserStatus: "EndUserStatus",
          ProductSold: "ProductSold",
          ProjectName: "ProjectName",
          POAmount: "POAmount",
          SaleName: "SaleName",
          CreateBy: "CreateBy",
          Currency: "Currency",
          CreateDate: "CreateDate",
          UpdateDate: "UpdateDate",
          PoFile: "PoFile",
          ContractFile: "ContractFile",
          CostSheetFile: "CostSheetFile",
          AttachFile: "AttachFile",
          StatusFile: "StatusFile",
          PipeDriveFlagStatus: "PipeDriveFlagStatus",
          StartLicenseDate: "StartLicenseDate",
          EndLicenseDate: "EndLicenseDate",
          Comment: "Comment",
          ActiveStatusColumn: "ActiveStatusColumn",
          SOType: "SOType",
        };
        let prepareData = await axios
          .post(
            apiUrl,
            {
              EmployeeEmail: this.user.EmployeeEmail,
              Role: this.user.Role,
              Level: accessLevel,
            },
            {
              headers: {
                Authorization: AuthKey,
              },
            }
          )
          .then(async (resp) => {
            await Others.dowloadCSV(
              headerDataJson,
              resp.data,
              "SaleOrderRecord"
            );
          });
      }
    },
    getFileStatusColor(status) {
      if (status != null) {
        status = status.toLowerCase();
        return status != "booking" ? "success" : "primary";
      } else {
        return "primary";
      }
    },
    getEndUserStatusColor(status) {
      if (status != null) {
        status = status.toLowerCase();
        return status != "new" ? "brown darken-2" : "deep-orange lighten-1";
      } else {
        return "deep-orange lighten-1";
      }
    },
    ConvertDate(date) {
      if (date != null) {
        return moment(date).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    calCommentPadding(comment) {
      if (window.screen.width <= this.mobileBreakPoint) {
        var lengthStr = comment.trim().split(/\s+/g).length;
        if (lengthStr < 5) {
          lengthStr = 10;
        } else if (lengthStr < 10) {
          lengthStr = 50;
        } else if (lengthStr < 20) {
          lengthStr = 100;
        } else if (lengthStr < 60) {
          lengthStr = 150;
        } else if (lengthStr < 70) {
          lengthStr = 200;
        } else {
          lengthStr = 250;
        }
        return lengthStr;
      }
      return 0;
    },
    GenerateKeyID(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    //   started
    GetCompanyInformation() {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(
            `${IP}/GetCompanyInfomation`,
            {
              headers: {
                Authorization: AuthKey,
              },
            },
            {
              EmployeeEmail: this.user.EmployeeEmail,
              Role: this.user.Role,
            }
          )
          .then((response) => {
            this.items.Company = response.data;
            resolve(true);
          });
      });
    },
    GetProductInformation() {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(
            `${IP}/GetProductInfomation`,
            {
              headers: {
                Authorization: AuthKey,
              },
            },
            {
              EmployeeEmail: this.user.EmployeeEmail,
              Role: this.user.Role,
            }
          )
          .then((response) => {
            this.items.ProductSold = response.data;
            resolve(true);
          });
      });
    },
    GetSaleOrders() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: `${IP}/GetSaleOrders`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            EmployeeEmail: this.user.EmployeeEmail,
            Role: this.user.Role,
            Level: AccessControl.getReadAccessLevel(
              cardName,
              this.user.AccessRightsJSON
            ),
          },
        }).then((response) => {
          this.listItems = [];
          const result = response.data;
          // console.log("Result",result);
          for (var i = 0; i < result.length; i++) {
            var check = result[i].SONumber.includes("-B");
            var BOIFlag = false;
            var so_number = result[i].SONumber;
            if (check) {
              BOIFlag = true;
            }
            result[i].Comment = this.checkHasComment(result[i].Comment);
            result[i].PipeDriveFlagStatus = this.checkPipeDriveFlagStatus(
              result[i].PipeDriveFlagStatus
            );
            if (result[i].ActiveStatusColumn != 0) {
              result[i].SONumber = so_number;
              result[i].BOIFlag = check;

              result[i].CustomerPODate = this.ConvertDate(
                result[i].CustomerPODate
              );
              result[i].StartLicenseDate = this.ConvertDate(
                result[i].StartLicenseDate
              );
              result[i].EndLicenseDate = this.ConvertDate(
                result[i].EndLicenseDate
              );

              for (const key in this.employeeEmailAndName) {
                if (
                  this.employeeEmailAndName[key].EmployeeEmail ==
                  result[i].CreateBy
                ) {
                  result[i].CreateBy =
                    this.employeeEmailAndName[key].EmployeeName;
                }
                if (
                  this.employeeEmailAndName[key].EmployeeEmail ==
                  result[i].SaleName
                ) {
                  result[i].SaleName =
                    this.employeeEmailAndName[key].EmployeeName;
                }
              }
              this.listItems.push(result[i]);
            }
            // console.log("List item=",this.listItems);
          }
          this.FilterItems = JSON.stringify(this.listItems);
          this.ready = true;
          resolve(true);
        });
      });
    },
    GetRunningNumberControl() {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(`${IP}/GetRunningNumberControl`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            this.RunningNumberItems = response.data;
            resolve(true);
          });
      });
    },
    GetSaleInformation() {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(`${IP}/GetSaleInformation`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            if (response.data != null) {
              response.data.forEach(function (val, i) {
                var object = {
                  EmployeeName: val.EmployeeName,
                  EmployeeEmail: val.EmployeeEmail[0],
                };
                this.SaleInformation.push(object);
              }, this);
            }
            resolve(true);
          });
      });
    },
    resize() {
      this.width = window.innerWidth - 35;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth() - 35;
        },
        this
      );
    },
    async initialize() {
      this.loading = true;
      await axios
        .get(`${IP}/getAllUserEmailAndName`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then((res) => {
          for (const index in res.data) {
            this.employeeEmailAndName.push(res.data[index]);
          }
          this.GetCompanyInformation().then(() => {
            this.GetProductInformation().then(() => {
              this.GetSaleOrders().then(() => {
                this.GetRunningNumberControl().then(() => {
                  this.GetSaleInformation().then(() => {
                    this.loading = false;
                    this.userlevel = AccessControl.getReadAccessLevel(
                      cardName,
                      this.user.AccessRightsJSON
                    );
                  });
                });
              });
            });
          });
        });
    },
  },
  async created() {
    this.resize();
    this.user = this.$store.getters.currentUser;
    await this.initialize();
  },
};
</script>

<style scoped lang="scss">
@import "../css/transition.scss";
@import "../css/saleorders.scss";
</style>