<template>
  <div class="planning-content-area">
    <div>
      <v-row>
        <v-col cols="auto">
          <v-card-title>Task Planning</v-card-title>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div style="margin: 10px">
      <v-row>
        <v-col
          v-for="n in task_plan"
          :key="n.id"
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
        >
          <div>
            <v-card style="padding: 20px">
              <v-btn
                color="error"
                icon
                @click="removeCard(n.id)"
                style="float: right; margin-top: -36px; margin-right: -40px"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <v-text-field
                    label="Task Name"
                    outlined
                    v-model="n.name"
                    :name="`name-plan-task-${n.id}`"
                    v-validate="'required'"
                  />
                  <transition name="slide-fade">
                    <v-alert
                      type="error"
                      v-show="errors.has(`name-plan-task-${n.id}`)"
                      style="margin-top: -20px"
                      >{{ errors.first(`name-plan-task-${n.id}`) }}</v-alert
                    >
                  </transition>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-top: -30px"
                >
                  <v-autocomplete
                    v-model="n.assign_to"
                    :items="resultAssign"
                    outlined
                    label="Assign to"
                    item-text="name"
                    item-value="email"
                    multiple
                    :name="`plan-assign-${n.id}`"
                    v-validate="'required'"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove('assign', data.item, n.id)"
                        >{{ data.item.name }}</v-chip
                      >
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <transition name="slide-fade">
                    <v-alert
                      type="error"
                      v-show="errors.has(`plan-assign-${n.id}`)"
                      style="margin-top: -20px"
                      >{{ errors.first(`plan-assign-${n.id}`) }}</v-alert
                    >
                  </transition>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-top: -30px"
                >
                  <v-autocomplete
                    v-model="n.accountable"
                    :items="resultAccountable"
                    outlined
                    label="Accountable"
                    item-text="name"
                    item-value="email"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove('accountable', data.item, n.id)"
                        >{{ data.item.name }}</v-chip
                      >
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  style="margin-top: -30px"
                >
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="n.duedate"
                        label="Due Date"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        :name="`due-date-${n.id}`"
                        v-validate="'required'"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="n.duedate"></v-date-picker>
                  </v-menu>
                  <transition name="slide-fade">
                    <v-alert
                      type="error"
                      v-show="errors.has(`due-date-${n.id}`)"
                      style="margin-top: -20px"
                      >{{ errors.first(`due-date-${n.id}`) }}</v-alert
                    >
                  </transition>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  style="margin-top: -30px"
                >
                  <v-text-field
                    label="Hours"
                    type="number"
                    outlined
                    v-model="n.hours"
                    :name="`due-hours-${n.id}`"
                    v-validate="'required|min_value:1'"
                  />
                  <transition name="slide-fade">
                    <v-alert
                      type="error"
                      v-show="errors.has(`due-hours-${n.id}`)"
                      style="margin-top: -20px"
                      >{{ errors.first(`due-hours-${n.id}`) }}</v-alert
                    >
                  </transition>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
          <div>
            <v-card style="padding: 20px">
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <v-text-field
                    label="Task Name"
                    outlined
                    v-model="task.name"
                    name="name-plan-task"
                    v-validate="'required'"
                  />
                  <transition name="slide-fade">
                    <v-alert
                      type="error"
                      v-show="errors.has('name-plan-task')"
                      style="margin-top: -20px"
                      >{{ errors.first("name-plan-task") }}</v-alert
                    >
                  </transition>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-top: -30px"
                >
                  <v-autocomplete
                    v-model="task.assign_to"
                    :items="resultAssign"
                    outlined
                    label="Assign to"
                    item-text="name"
                    item-value="email"
                    name="plan-assign"
                    v-validate="'required'"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove('assign', data.item, null)"
                        >{{ data.item.name }}</v-chip
                      >
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <transition name="slide-fade">
                    <v-alert
                      type="error"
                      v-show="errors.has('plan-assign')"
                      style="margin-top: -20px"
                      >{{ errors.first("plan-assign") }}</v-alert
                    >
                  </transition>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-top: -30px"
                >
                  <v-autocomplete
                    v-model="task.accountable"
                    :items="resultAccountable"
                    outlined
                    label="Accountable"
                    item-text="name"
                    item-value="email"
                    name="accountable"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove('accountable', data.item, null)"
                        >{{ data.item.name }}</v-chip
                      >
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  style="margin-top: -30px"
                >
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="task.duedate"
                        label="Due Date"
                        append-icon="event"
                        readonly
                        v-on="on"
                        outlined
                        name="due-date"
                        v-validate="'required'"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="task.duedate"></v-date-picker>
                  </v-menu>
                  <transition name="slide-fade">
                    <v-alert
                      type="error"
                      v-show="errors.has('due-date')"
                      style="margin-top: -20px"
                      >{{ errors.first("due-date") }}</v-alert
                    >
                  </transition>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  style="margin-top: -30px"
                >
                  <v-text-field
                    label="Hours"
                    type="number"
                    outlined
                    v-model="task.hours"
                    name="due-hours"
                    v-validate="'required|min_value:1'"
                  />
                  <transition name="slide-fade">
                    <v-alert
                      type="error"
                      v-show="errors.has('due-hours')"
                      style="margin-top: -20px"
                      >{{ errors.first("due-hours") }}</v-alert
                    >
                  </transition>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-top: -30px"
                >
                  <v-btn color="success" @click="AddTaskPlanning" block>
                    <v-icon style="margin-right: 5px">mdi-plus-circle</v-icon
                    >ADD
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <v-card-actions class="justify-center">
      <v-btn
        color="success"
        @click="AssignTask"
        style="width: 200px; margin-top: 15px; margin-bottom: -20px"
      >
        <v-icon style="margin-right: 5px">mdi-clipboard-list-outline</v-icon
        >Assign
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../../config";

export default {
  data() {
    return {
      menu_duedate: false,
      user: "",
      IP: IP,
      task_plan: [],
      task: {
        accountable: [],
      },
    };
  },
  computed: {
    resultAssign() {
      const value = this.member;
      var array = [];
      for (var i = 0; i < value.length; i++) {
        // if (value[i].EmployeeEmail != this.user.EmployeeEmail) {
        var obj = {
          email: value[i].EmployeeEmail,
          name: value[i].EmployeeName,
        };
        array.push(obj);
        // }
      }
      return array;
    },
    resultAccountable() {
      const value = this.member;
      var array = [];
      for (var i = 0; i < value.length; i++) {
        if (value[i].EmployeeEmail != this.user.EmployeeEmail) {
          var obj = {
            email: value[i].EmployeeEmail,
            name: value[i].EmployeeName,
          };
          array.push(obj);
        }
      }
      return array;
    },
  },
  methods: {
    async AssignTask() {
      if (this.task_plan.length > 0) {
        var array = [];
        for (var i = 0; i < this.task_plan.length; i++) {
          console.log(this.task_plan[i].id);
          array.push(
            this.$validator.validate(`name-plan-task-${this.task_plan[i].id}`)
          );
          array.push(
            this.$validator.validate(`plan-assign-${this.task_plan[i].id}`)
          );
          array.push(
            this.$validator.validate(`due-date-${this.task_plan[i].id}`)
          );
          array.push(
            this.$validator.validate(`due-hours-${this.task_plan[i].id}`)
          );
        }

        const results = Promise.all(array);

        const areValid = (await results).every((isValid) => isValid);
        if (areValid) {
          Swal.fire({
            title: "Are you sure ?",
            text: `คุณต้องการแพลนงานเหล่านี้หรือไม่ !`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              axios({
                method: "post",
                url: IP + "/task/create-multi-task",
                headers: {
                  Authorization: AuthKey,
                },
                data: {
                  task: this.task_plan,
                },
              }).then((response) => {
                if (response.data == "success") {
                  this.Clear();
                  Swal.fire("มอบหมายงานเสร็จสิ้น !", "", "success");
                }
              });
            }
          });
        }
      }
    },
    removeCard(id) {
      for (var i = 0; i < this.task_plan.length; i++) {
        if (this.task_plan[i].id === id) {
          this.task_plan.splice(i, 1);
        }
      }
    },
    remove(action, item, id) {
      console.log(action, item, id);
      if (id == null) {
        if (action == "assign") {
          const index = this.task.assign_to.indexOf(item.email);
          if (index >= 0) this.task.assign_to.splice(index, 1);
        } else if (action == "accountable") {
          const index = this.task.accountable.indexOf(item.email);
          if (index >= 0) this.task.accountable.splice(index, 1);
        }
      } else {
        if (action == "assign") {
          for (var i = 0; i < this.task_plan.length; i++) {
            if (i == id) {
              const index = this.task_plan[i].assign_to.indexOf(item.email);
              if (index >= 0) this.task_plan[i].assign_to.splice(index, 1);
            }
          }
        } else if (action == "accountable") {
          for (var x = 0; x < this.task_plan.length; x++) {
            if (x == id) {
              const index = this.task_plan[x].accountable.indexOf(item.email);
              if (index >= 0) this.task_plan[x].accountable.splice(index, 1);
            }
          }
        }
      }
    },
    async AddTaskPlanning() {
      const results = Promise.all([
        this.$validator.validate("name-plan-task"),
        this.$validator.validate("plan-assign"),
        this.$validator.validate("due-date"),
        this.$validator.validate("due-hours"),
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        this.task.accountable.push(this.user.EmployeeEmail);
        this.task.id = this.task_plan.length;
        this.task.hours = parseInt(this.task.hours);
        this.task.create_by = this.user.EmployeeEmail;
        this.task.subtask_of = null;
        this.task.type = "maintask";
        this.task.status = "Pending";
        this.task.project_id = this.project.ID;
        this.task_plan.push(this.task);
        this.task = {
          accountable: [],
        };
      }
    },
    Clear() {
      this.task_plan = [];
      this.task = {
        accountable: [],
      };
      this.$emit("update-data");
    },
    async start() {
      this.user = this.$store.getters.currentUser;
    },
  },
  props: ["member", "project"],
  created() {
    this.start();
  },
};
</script>

<style scoped>
.planning-content-area {
  width: 100%;
  height: 100%;
}
</style>