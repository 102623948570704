<template>
  <div class="expansion-panels">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row style="margin-bottom: 0px">
        <v-col style="font-size: 30px">Certificates</v-col>
        <!-- <v-col cols="12" xs="12" sm="auto" md="auto">
          <v-btn color="success" block @click="Action('create', null)">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
        </v-col> -->
      </v-row>
    </div>
    <!-- <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px">
        ยังไม่มี Certificates
      </p>
    </div> -->
    <v-card-title>
      <v-text-field
        v-model="search"
        label="Search"
        append-icon="mdi-magnify"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="listItems"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:item.IssuedDate="{ item }">
        <div v-if="item.IssuedDate != null">
          {{ ConvertDate(item.IssuedDate) }}
        </div>
        <div v-else></div>
      </template>
      <template v-slot:item.ExpiredDate="{ item }">
        <div v-if="item.ExpiredDate != null">
          {{ ConvertDate(item.ExpiredDate) }}
        </div>
        <div v-else></div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              class="mr-2"
              v-on="on"
              :disabled="item.CertificateFile == 'null'"
              style="cursor: pointer"
              @click="DownloadFile(item.CertificateFile)"
              >mdi-cloud-download</v-icon
            >
          </template>
          <span>Download File</span>
        </v-tooltip>
        <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    small
                    v-on="on"
                    style="cursor: pointer;"
                    @click="DeleteCertificate(item)"
                  >delete</v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip> -->
      </template>
    </v-data-table>
    <!-- dialog -->
    <v-dialog v-model="dialog" scrollable presistent max-width="500">
      <v-card>
        <v-card-title v-if="action == 'create'"
          >Create Certificate</v-card-title
        >
        <v-card-title v-if="action == 'update'"
          >Update Certificate</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="card-text-area">
          <div class="information-area">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-select
                  :items="categoryType"
                  v-model="category_select_type"
                  label="Category Type"
                  outlined
                  name="category_type"
                  v-validate="'required'"
                  dense
                  hide-details
                ></v-select>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('category_type')"
                    >{{ errors.first("category_type") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-select
                  v-if="category_select_type == 'Technical'"
                  :items="categoryTech"
                  v-model="certificates.category"
                  label="Category"
                  outlined
                  name="category"
                  v-validate="'required'"
                  dense
                  hide-details
                ></v-select>
                <v-select
                  v-if="category_select_type == 'Non-Tech'"
                  :items="categoryNonTech"
                  v-model="certificates.category"
                  label="Category"
                  outlined
                  name="category"
                  v-validate="'required'"
                  dense
                  hide-details
                ></v-select>
                <v-select
                  v-if="category_select_type == ''"
                  label="Category"
                  outlined
                  name="category"
                  disabled
                  dense
                  hide-details
                ></v-select>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('category')"
                    >{{ errors.first("category") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                  label="Name"
                  outlined
                  v-model="certificates.name"
                  name="name"
                  v-validate="'required'"
                  dense
                  hide-details
                />
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('name')"
                    >{{ errors.first("name") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-textarea
                  label="Description"
                  outlined
                  v-model="certificates.description"
                  name="description"
                  dense
                  hide-details
                  rows="2"
                />
                <!-- <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px;"
                    v-show="errors.has('description')"
                    >{{ errors.first("description") }}</v-alert
                  >
                </transition> -->
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                  label="Issued By"
                  outlined
                  v-model="certificates.issuedby"
                  name="issuedby"
                  v-validate="'required'"
                  dense
                  hide-details
                />
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('issuedby')"
                    >{{ errors.first("issuedby") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-menu
                  v-model="menu_issueddate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="certificates.issueddate"
                      label="Issued Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                      outlined
                      v-validate="'required'"
                      name="issueddate"
                      dense
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="certificates.issueddate"
                    @input="menu_issueddate = false"
                  ></v-date-picker>
                </v-menu>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('issueddate')"
                    >{{ errors.first("issueddate") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-menu
                  v-model="menu_expiredate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="certificates.expiredate"
                      label="Expired Date"
                      append-icon="event"
                      readonly
                      v-on="on"
                      outlined
                      v-validate="'required'"
                      name="expiredate"
                      dense
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="certificates.expiredate"
                    @input="menu_expiredate = false"
                  ></v-date-picker>
                </v-menu>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    dense
                    style="margin-top: 10px; margin-bottom: -10px"
                    v-show="errors.has('expiredate')"
                    >{{ errors.first("expiredate") }}</v-alert
                  >
                </transition>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style="margin: 0px 0px 15px 0px"
              >
                <input
                  id="fileUpload"
                  type="file"
                  hidden
                  @change="handleFileUpload"
                />
                <v-btn tile color="indigo" dark block @click="chooseFiles">
                  <v-icon style="margin: 0px 5px 0px 0px"
                    >mdi-file-document</v-icon
                  >Upload File
                </v-btn>
                <transition name="slide-fade">
                  <v-alert
                    type="error"
                    v-show="error != ''"
                    style="margin-top: 10px"
                    >{{ error }}</v-alert
                  >
                </transition>
              </v-col>
            </v-row>
          </div>
          <div class="information-area" v-if="file != null">
            <v-divider></v-divider>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th class="text-right">Size</th>
                    <th class="text-center">Active</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ file.name }}</td>
                    <td class="text-right">{{ pretty(file.size) }}</td>
                    <td class="text-center">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        x-small
                        color="red"
                        @click="removeItems"
                        style="width: 25px; height: 25px; margin: 4px"
                      >
                        <v-icon dark size="15">mdi-close</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            v-if="action == 'create'"
            @click="CreateCertificate"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
          <v-btn
            color="warning"
            v-if="action == 'update'"
            @click="UpdateCertificate"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../../config";
import pretty from "prettysize";
import download from "downloadjs";
import moment from "moment";

export default {
  data() {
    return {
      IP: IP,
      user: "",
      headers: [
        { text: "Category", value: "Category" },
        { text: "Name", value: "CertificateName" },
        { text: "Description", value: "CertificateDescription" },
        { text: "Issued By", value: "IssuedBy" },
        { text: "Issued Date", value: "IssuedDate" },
        { text: "Expired Date", value: "ExpiredDate" },
        { text: "Actions", value: "action", sortable: false },
      ],
      listItems: [],
      search: "",
      dialog: false,
      action: "",
      certificates: {},
      file: null,
      menu_issueddate: false,
      menu_expiredate: false,
      error: "",
      loading: false,
      category_select_type: "",
      categoryType: ["Technical", "Non-Tech"],
      categoryTech: [
        "AA",
        "AWS",
        "Alteryx",
        "Microsoft",
        "Splunk Certification",
        "Tableau",
        "NetGain",
        "PM&BA",
        "Other ex. CompTIA",
      ],
      categoryNonTech: ["Business", "Accountant", "Others"],
    };
  },
  methods: {
    async CreateCertificate() {
      // if (this.file != null) {
      this.error = "";
      this.certificates.file = this.file;
      this.$validator.validateAll().then((result) => {
        if (result) {
          Swal.fire({
            title: "Are you sure ?",
            text: "คุณต้องการบันทึก Certificate นี้หรือไม่ !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then(async (result) => {
            if (result.value) {
              this.CreateProcess().then(async (response) => {
                if (response == "success") {
                  await this.Clear();
                  await this.GetCertificateByEmail();
                  Swal.fire("เพิ่มข้อมูลเสร็จสิ้น !", "", "success");
                }
              });
            }
          });
        }
      });
      // } else {
      //   this.error = "Please select your file.";
      // }
    },
    CreateProcess() {
      return new Promise(async (resolve, reject) => {
        const data = new FormData();
        data.append("file", this.certificates.file);
        data.append("category", this.certificates.category);
        data.append("name", this.certificates.name);
        data.append("description", this.certificates.description);
        data.append("issuedby", this.certificates.issuedby);
        data.append("issueddate", this.certificates.issueddate);
        data.append("expiredate", this.certificates.expiredate);
        data.append("email", this.email);

        axios
          .post(IP + "/user-management/create-certificate", data, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            resolve(response.data);
          });
      });
    },
    UpdateCertificate() {},
    UpdateProcess() {},
    DeleteCertificate(data) {
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบ Certificate นี่หรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          this.DeleteProcess(data).then(async (response) => {
            if (response == "success") {
              await this.Clear();
              await this.GetCertificateByEmail();
              Swal.fire("ลบข้อมูลเสร็จสิ้น !", "", "success");
            }
          });
        }
      });
    },
    DeleteProcess(data) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/delete-certificate",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            data: data,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    DownloadFile(filename) {
      fetch(`${IP}/user-management/download-certificate`, {
        method: "post",
        headers: {
          Authorization: AuthKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filename: filename,
        }),
      }).then((res) => {
        const blob = res.blob();
        blob.then(async (result) => {
          await download(result, filename, result.type);
        }, this);
      });
    },
    Action(action, data) {
      this.certificates = {};
      this.category_select_type = "";
      this.file = null;
      this.action = action;
      this.$validator.reset();
      this.dialog = !this.dialog;
    },
    Clear() {
      this.certificates = {};
      this.category_select_type = "";
      this.file = null;
      this.dialog = false;
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    async handleFileUpload(e) {
      const file = e.target.files;
      this.file = file[0];
    },
    ConvertDate(date) {
      const result = moment(date).format("L");
      return result;
    },
    removeItems() {
      this.file = null;
    },
    pretty(size) {
      return pretty(size);
    },
    GetCertificateByEmail() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/get-certificate-by-email",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.email,
          },
        }).then((response) => {
          this.listItems = response.data;
          resolve(true);
        });
      });
    },
    async start() {
      this.loading = true;
      await this.GetCertificateByEmail().then((response) => {
        var that = this;
        setTimeout(function () {
          that.loading = false;
        }, 1000);
      });
    },
  },
  props: ["email"],
  created() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";

.expansion-panels {
  margin-bottom: 30px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.card-text-area {
  width: 100%;
  max-height: 600px;
}

@media screen and (max-height: 920px) {
  .card-text-area {
    width: 100%;
    max-height: 480px;
  }
}
</style>