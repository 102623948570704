<template>
  <div class="voice-recognition">
    <v-btn
      depressed
      x-small
      @click="startVoiceRecognition"
      id="left-btn"
      v-if="!isRecording"
    >
      <v-icon>mdi-microphone</v-icon>
    </v-btn>
    <v-btn
      depressed
      x-small
      @click="stopVoiceRecognition"
      id="left-btn"
      :style="[isRecording ? { background: '#65a000' } : { background: '' }]"
      v-else
    >
      <VoiceAnimation />
    </v-btn>
  </div>
</template>

<script>
import VoiceAnimation from "./VoiceAnimation.vue";

export default {
  data() {
    return {
      transcript: "",
      isRecording: false,
      Recognition: window.SpeechRecognition || window.webkitSpeechRecognition,
      sr: new (window.SpeechRecognition || window.webkitSpeechRecognition)(),
      l: this.lang || "en",
    };
  },
  props: ["lang"],
  components: {
    VoiceAnimation,
  },
  methods: {
    startVoiceRecognition() {
      //   console.log("start recognition.");
      this.sr.continuous = true;
      this.sr.interimResults = true;
      console.log(this.lang);
      this.sr.lang = this.lang;
      console.log("sr: ", this.sr);

      this.sr.onstart = () => {
        // console.log("SR Started");
        this.isRecording = true;
      };

      this.sr.onend = () => {
        // console.log("SR Stopped");
        this.isRecording = false;
        // console.log("1");
        this.$emit("update-voice-recognition", "", this.isRecording);
      };

      this.sr.onresult = (evt) => {
        const t = Array.from(evt.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        this.transcript = t;
        this.$emit("update-voice-recognition", this.transcript, true);
      };
      this.sr.start();
    },
    stopVoiceRecognition() {
      this.isRecording = false;
      this.sr.stop();
      // console.log("2");
      // this.$emit("update-voice-recognition", "", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#left-btn {
  margin: 0px 0px 5px 5px;
  width: 35px;
  height: 35px;
  transition: 0.3s all;
  background: #212121;
  color: #FFF;
}

#left-btn:hover {
  background: #65a000;
  color: white;
}
</style>