<template>
  <div>
    <!-- content area -->
    <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 90px">
        ยังไม่มีงานที่มอบหมาย
      </p>
    </div>
    <div v-if="listItems.length > 0">
      <v-card-title v-if="listItems.length > 0">
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="width <= 769"
        :headers="headers"
        :items="listItems"
        :search="search"
        hide-default-header
        class="elevation-1"
      >
        <template v-slot:body="{ items }">
          <tbody v-for="item in items" :key="item.ID">
            <v-card
              style="
                padding: 20px;
                border-bottom: 1px solid rgb(216, 216, 216);
                background: rgb(241, 241, 241);
              "
            >
              <div @click="UpdateCollapse(item)" class="card-active">
                <v-row>
                  <v-col cols="4" xs="3" sm="3">
                    <b>Name</b>
                  </v-col>
                  <v-col cols="8" style="text-align: right">{{
                    item.TaskName
                  }}</v-col>
                  <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                    <b>Status</b>
                  </v-col>
                  <v-col cols="8" style="margin-top: -10px; text-align: right">
                    <v-chip color="warning" v-if="item.TaskStatus == 'Pending'"
                      >Pending</v-chip
                    >
                    <v-chip color="primary" v-if="item.TaskStatus == 'Doing'"
                      >Doing</v-chip
                    >
                    <v-chip color="success" v-if="item.TaskStatus == 'Finish'"
                      >Finish</v-chip
                    >
                  </v-col>
                  <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                    <b>Due Date</b>
                  </v-col>
                  <v-col
                    cols="8"
                    style="margin-top: -10px; text-align: right"
                    >{{ Moment(item.DueDate) }}</v-col
                  >
                  <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                    <b>Assign</b>
                  </v-col>
                  <v-col
                    cols="8"
                    style="margin-top: -10px; text-align: right"
                    >{{ Moment(item.AssignDate) }}</v-col
                  >
                  <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                    <b>Start</b>
                  </v-col>
                  <v-col
                    cols="8"
                    style="margin-top: -10px; text-align: right"
                    >{{ Moment(item.StartDate) }}</v-col
                  >
                  <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                    <b>Finish</b>
                  </v-col>
                  <v-col
                    cols="8"
                    style="margin-top: -10px; text-align: right"
                    >{{ Moment(item.FinishDate) }}</v-col
                  >
                  <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                    <b>Hours</b>
                  </v-col>
                  <v-col cols="8" style="margin-top: -10px; text-align: right"
                    >{{ item.TotalHours }} ชม.</v-col
                  >
                </v-row>
              </div>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    color="success"
                    block
                    @click="Action('row-add-subtask', item)"
                  >
                    <v-icon style="margin-right: 10px">mdi-plus</v-icon>Add
                    Subtask
                  </v-btn>
                </v-col>
                <v-col cols="12" style="margin-top: -10px">
                  <v-btn color="success" block @click="Action('checkin', item)">
                    <v-icon style="margin-right: 10px"
                      >mdi-calendar-check</v-icon
                    >TimeStamp
                  </v-btn>
                </v-col>
                <v-col cols="6" style="margin-top: -10px">
                  <v-btn
                    color="primary"
                    block
                    @click="Action('update-task', item)"
                    >Update</v-btn
                  >
                </v-col>
                <v-col cols="6" style="margin-top: -10px">
                  <v-btn
                    color="error"
                    block
                    @click="DeleteTask(item.ID, item.TaskType)"
                    >Delete</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
            <v-card v-show="collapse == item.ID && item.subtask.length == 0">
              <h4 style="text-align: center; padding: 20px">ไม่มีงานย่อย</h4>
            </v-card>
            <v-card
              style="padding: 20px; border-bottom: 1px solid rgb(216, 216, 216)"
              v-show="collapse == item.ID && item.subtask.length != 0"
              v-for="n in item.subtask"
              :key="n.ID"
            >
              <v-row>
                <v-col cols="4" xs="3" sm="3">
                  <b>Name</b>
                </v-col>
                <v-col cols="8" style="text-align: right">{{
                  n.TaskName
                }}</v-col>
                <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                  <b>Status</b>
                </v-col>
                <v-col cols="8" style="margin-top: -10px; text-align: right">
                  <v-chip color="warning" v-if="n.TaskStatus == 'Pending'"
                    >Pending</v-chip
                  >
                  <v-chip color="primary" v-if="n.TaskStatus == 'Doing'"
                    >Doing</v-chip
                  >
                  <v-chip color="success" v-if="n.TaskStatus == 'Finish'"
                    >Finish</v-chip
                  >
                </v-col>
                <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                  <b>Assign</b>
                </v-col>
                <v-col cols="8" style="margin-top: -10px; text-align: right">{{
                  Moment(n.AssignDate)
                }}</v-col>
                <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                  <b>Start</b>
                </v-col>
                <v-col cols="8" style="margin-top: -10px; text-align: right">{{
                  Moment(n.StartDate)
                }}</v-col>
                <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                  <b>Finish</b>
                </v-col>
                <v-col cols="8" style="margin-top: -10px; text-align: right">{{
                  Moment(n.FinishDate)
                }}</v-col>
                <v-col cols="4" xs="3" sm="3" style="margin-top: -10px">
                  <b>Hours</b>
                </v-col>
                <v-col cols="8" style="margin-top: -10px; text-align: right"
                  >{{ n.TotalHours }} ชม.</v-col
                >

                <v-col cols="12">
                  <v-btn color="success" block @click="Action('checkin', n)">
                    <v-icon style="margin-right: 10px"
                      >mdi-calendar-check</v-icon
                    >TimeStamp
                  </v-btn>
                </v-col>
                <v-col cols="6" small style="margin-top: -10px">
                  <v-btn
                    color="primary"
                    block
                    @click="Action('update-subtask', n)"
                    >Update</v-btn
                  >
                </v-col>
                <v-col cols="6" small style="margin-top: -10px">
                  <v-btn
                    color="error"
                    block
                    @click="DeleteTask(n.ID, n.TaskType)"
                    >Delete</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </tbody>
        </template>
      </v-data-table>
      <v-data-table
        v-if="width > 769"
        :headers="headers"
        :items="listItems"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:body="{ items }">
          <tbody v-for="item in items" :key="item.ID">
            <tr class="data-table-maintask">
              <td class="d-block d-sm-table-cell">
                <v-btn icon @click="UpdateCollapse(item)">
                  <v-icon color="error" v-if="collapse == item.ID"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon color="success" v-if="collapse != item.ID"
                    >mdi-arrow-down</v-icon
                  >
                </v-btn>
              </td>
              <td class="d-block d-sm-table-cell">{{ item.TaskName }}</td>
              <td class="d-block d-sm-table-cell">
                <v-chip
                  class="ma-2"
                  color="warning"
                  v-if="item.TaskStatus == 'Pending'"
                  >Pending</v-chip
                >
                <v-chip
                  class="ma-2"
                  color="primary"
                  v-if="item.TaskStatus == 'Doing'"
                  >Doing</v-chip
                >
                <v-chip
                  class="ma-2"
                  color="success"
                  v-if="item.TaskStatus == 'Finish'"
                  >Finish</v-chip
                >
              </td>
              <td class="d-block d-sm-table-cell">
                {{ Moment(item.DueDate) }}
              </td>
              <td class="d-block d-sm-table-cell">
                {{ Moment(item.AssignDate) }}
              </td>
              <td class="d-block d-sm-table-cell">
                {{ Moment(item.StartDate) }}
              </td>
              <td class="d-block d-sm-table-cell">
                {{ Moment(item.FinishDate) }}
              </td>
              <td class="d-block d-sm-table-cell">{{ item.TotalHours }} ชม.</td>
              <td class="d-block d-sm-table-cell" style="text-align: right">
                <!-- add subtask -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      color="teal"
                      style="cursor: pointer; margin-right: 10px"
                      @click="Action('row-add-subtask', item)"
                      >mdi-plus</v-icon
                    >
                  </template>
                  <span>Add Subtask</span>
                </v-tooltip>
                <!-- Timestamp -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      color="teal"
                      style="cursor: pointer"
                      @click="Action('checkin', item)"
                      >mdi-calendar-check</v-icon
                    >
                  </template>
                  <span>Timestamp</span>
                </v-tooltip>
                <!-- update task -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      v-show="item.SubtaskOf == null"
                      @click="Action('update-task', item)"
                      style="cursor: pointer; margin: 0px 10px 0px 10px"
                      >edit</v-icon
                    >
                  </template>
                  <span>Update Task</span>
                </v-tooltip>
                <!-- delete task -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      v-show="item.SubtaskOf == null"
                      @click="DeleteTask(item.ID, item.TaskType)"
                      style="cursor: pointer"
                      >delete</v-icon
                    >
                  </template>
                  <span>Delete Task</span>
                </v-tooltip>
              </td>
            </tr>
            <tr v-show="collapse == item.ID && item.subtask.length == 0">
              <td
                class="d-block d-sm-table-cell"
                :colspan="headers.length"
                style="text-align: center"
              >
                ไม่มีงานย่อย
              </td>
            </tr>
            <tr
              v-show="collapse == item.ID"
              v-for="n in item.subtask"
              :key="n.ID"
            >
              <td class="d-block d-sm-table-cell"></td>
              <td class="d-block d-sm-table-cell">{{ n.TaskName }}</td>
              <td class="d-block d-sm-table-cell">
                <v-chip
                  class="ma-2"
                  color="warning"
                  v-if="n.TaskStatus == 'Pending'"
                  >Pending</v-chip
                >
                <v-chip
                  class="ma-2"
                  color="primary"
                  v-if="n.TaskStatus == 'Doing'"
                  >Doing</v-chip
                >
                <v-chip
                  class="ma-2"
                  color="success"
                  v-if="n.TaskStatus == 'Finish'"
                  >Finish</v-chip
                >
              </td>
              <td class="d-block d-sm-table-cell"></td>
              <td class="d-block d-sm-table-cell">
                {{ Moment(n.AssignDate) }}
              </td>
              <td class="d-block d-sm-table-cell">{{ Moment(n.StartDate) }}</td>
              <td class="d-block d-sm-table-cell">
                {{ Moment(n.FinishDate) }}
              </td>
              <td class="d-block d-sm-table-cell">{{ n.TotalHours }} ชม.</td>
              <td class="d-block d-sm-table-cell" style="text-align: right">
                <!-- Timestamp -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      color="teal"
                      style="cursor: pointer"
                      @click="Action('checkin', n)"
                      >mdi-calendar-check</v-icon
                    >
                  </template>
                  <span>Timestamp</span>
                </v-tooltip>
                <!-- update subtask -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      v-show="n.SubtaskOf != null"
                      @click="Action('update-subtask', n)"
                      style="cursor: pointer; margin: 0px 10px 0px 10px"
                      >edit</v-icon
                    >
                  </template>
                  <span>Update Subtask</span>
                </v-tooltip>
                <!-- delete subtask -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                      v-show="n.SubtaskOf != null"
                      @click="DeleteTask(n.ID, n.TaskType)"
                      style="cursor: pointer"
                      >delete</v-icon
                    >
                  </template>
                  <span>Delete Subtask</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <!-- dialog checkin -->
    <v-dialog v-model="dialog_checkin" width="100%;" max-width="500">
      <v-card>
        <v-card-title>Timestamp</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-text-field
                label="Hours"
                type="number"
                outlined
                v-model="checkin.hours"
                name="accountable-checkin-hours"
                v-validate="'required|min_value:1'"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('accountable-checkin-hours')"
                  style="margin-top: -20px"
                  >{{ errors.first("accountable-checkin-hours") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <v-select
                :items="select_checkin"
                v-model="checkin.status"
                label="Status"
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" @click="CheckIn" block dark color="teal">
                    <v-icon style="margin-right: 10px"
                      >mdi-checkbox-marked</v-icon
                    >Timestamp
                  </v-btn>
                </template>
                <span>Timestamp</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div>
            <p
              style="text-align: center; padding: 20px"
              v-if="listCheckin.length == 0"
            >
              ไม่มีประวัติเช็คอิน
            </p>
            <div v-if="listCheckin.length > 0" style="padding-bottom: 20px">
              <v-data-table
                :headers="checkin_headers"
                :items="listCheckin"
                class="elevation-1"
              ></v-data-table>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- dialog subtask -->
    <v-dialog v-model="dialog_subtask" width="100%;" max-width="500">
      <v-card>
        <v-card-title v-if="action == 'create-subtask'"
          >Create Subtask</v-card-title
        >
        <v-card-title v-if="action == 'update-subtask'"
          >Update Subtask</v-card-title
        >
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Subtask Name"
                outlined
                v-model="subtask.name"
                name="accountable-name-subtask"
                v-validate="'required'"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('accountable-name-subtask')"
                  style="margin-top: -20px"
                  >{{ errors.first("accountable-name-subtask") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-select
                item-text="name"
                item-value="id"
                :items="resultSubtaskOf"
                v-model="subtask.subtask_of"
                label="Subtask Of"
                outlined
                name="accountalbe-subtask-of"
                v-validate="'required'"
              ></v-select>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('accountalbe-subtask-of')"
                  style="margin-top: -20px"
                  >{{ errors.first("accountalbe-subtask-of") }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="CreateSubtask"
            style="margin: 10px"
            v-if="action == 'create-subtask'"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
          <v-btn
            color="success"
            @click="UpdateSubtask"
            style="margin: 10px"
            v-if="action == 'update-subtask'"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-update</v-icon>Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog task -->
    <v-dialog v-model="dialog_task" width="100%;" max-width="500" key="uuid">
      <v-card>
        <v-card-title>Update Task</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Task Name"
                outlined
                v-model="task.name"
                name="update-name-task"
                v-validate="'required'"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('update-name-task')"
                  style="margin-top: -20px"
                  >{{ errors.first("update-name-task") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-autocomplete
                v-model="task.assign_to"
                :items="resultAssign"
                outlined
                label="Assign to"
                item-text="name"
                item-value="email"
                name="update-assign-to-task"
                v-validate="'required'"
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove('assign', data.item)"
                    >{{ data.item.name }}</v-chip
                  >
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('update-assign-to-task')"
                  style="margin-top: -20px"
                  >{{ errors.first("update-assign-to-task") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-autocomplete
                v-model="task.accountable"
                :items="resultAccountable"
                outlined
                label="Accountable"
                item-text="name"
                item-value="email"
                name="update-accountable-task"
                multiple
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove('accountable', data.item)"
                    >{{ data.item.name }}</v-chip
                  >
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('update-accountable-task')"
                  style="margin-top: -20px"
                  >{{ errors.first("update-accountable-task") }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn color="success" @click="UpdateTask" style="margin: 10px">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog multi subtask -->
    <v-dialog v-model="dialog_multi_subtask" width="100%;" max-width="500">
      <v-card>
        <v-card-title>Create Subtask</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
              <v-text-field
                label="Subtask Name"
                outlined
                v-model="subtask.name"
                name="accountable-name-subtask"
                v-validate="'required'"
                v-on:keyup.enter="AddMultiSubtask"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('accountable-name-subtask')"
                  style="margin-top: -20px"
                  >{{ errors.first("accountable-name-subtask") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
              <v-btn
                color="success"
                block
                style="height: 53px"
                @click="AddMultiSubtask"
                >ADD</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <div style="padding: 20px">
          <v-data-table
            :headers="multi_subtask_headers"
            :items="array_subtask"
            hide-default-header
            class="elevation-1"
          >
            <template v-slot:item.name="{ item }">
              <div style="text-align: center">{{ item.name }}</div>
            </template>
            <template v-slot:item.action="{ item }">
              <div style="text-align: center">
                <v-btn icon color="error" @click="RemoveSomeSubtask(item.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="CreateMultiSubtask"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../../config";
import moment from "moment";
import { WindowResize } from "../../function/WindowResize.js";

export default {
  data() {
    return {
      IP: IP,
      user: "",
      action: "",
      listCheckin: [],
      dialog_task: false,
      dialog_subtask: false,
      dialog_checkin: false,
      dialog_multi_subtask: false,
      expanded: [],
      task: {
        id: 0,
        name: "",
        assign_to: [],
        accountable: [],
      },
      subtask: {},
      array_subtask: [],
      checkin: {},
      search: "",
      multi_subtask_headers: [
        { text: "Name", value: "name" },
        { text: "Actions", value: "action", sortable: false },
      ],
      headers: [
        { text: "", value: "", sortable: false },
        { text: "Name", value: "TaskName", sortable: false },
        { text: "Status", value: "TaskStatus", sortable: false },
        { text: "Due Date", value: "DueDate", sortable: false },
        { text: "Assign", value: "AssignDate", sortable: false },
        { text: "Start", value: "StartDate", sortable: false },
        { text: "Finish", value: "FinishDate", sortable: false },
        { text: "Hours", value: "TotalHours", sortable: false },
        { text: "Actions", value: "action", sortable: false },
      ],
      checkin_headers: [
        { text: "Date", value: "CheckInDate", sortable: false },
        { text: "Hours", value: "WorkHours", sortable: false },
      ],
      pagination: { sortBy: "" },
      select_checkin: ["Doing", "Finish"],
      collapse: 0,
      width: 0,
      height: 0,
      subtask_of: 0,
      project_id: 0,
    };
  },
  computed: {
    resultAssign() {
      const value = this.member;
      var array = [];
      for (var i = 0; i < value.length; i++) {
        // if (value[i].EmployeeEmail != this.user.EmployeeEmail) {
        var obj = {
          email: value[i].EmployeeEmail,
          name: value[i].EmployeeName,
        };
        array.push(obj);
        // }
      }
      return array;
    },
    resultAccountable() {
      const value = this.member;
      var array = [];
      for (var i = 0; i < value.length; i++) {
        if (value[i].EmployeeEmail != this.user.EmployeeEmail) {
          var obj = {
            email: value[i].EmployeeEmail,
            name: value[i].EmployeeName,
          };
          array.push(obj);
        }
      }
      return array;
    },
    resultSubtaskOf() {
      const value = this.listItems;
      var array = [];
      for (var i = 0; i < value.length; i++) {
        if (value[i].SubtaskOf == null) {
          var obj = {
            id: value[i].ID,
            name: value[i].TaskName,
          };
          array.push(obj);
        }
      }
      return array;
    },
  },
  methods: {
    async RemoveSomeSubtask(id) {
      for (var i = 0; i < this.array_subtask.length; i++) {
        if (this.array_subtask[i].id === id) {
          this.array_subtask.splice(i, 1);
        }
      }
    },
    async AddMultiSubtask() {
      const results = Promise.all([
        this.$validator.validate("accountable-name-subtask"),
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        this.subtask.id = this.array_subtask.length;
        this.subtask.subtask_of = this.subtask_of;
        this.subtask.type = "subtask";
        this.subtask.status = "Pending";
        this.subtask.create_by = this.user.EmployeeEmail;
        this.subtask.project_id = this.project_id;
        this.array_subtask.push(this.subtask);
        this.subtask = {};
      }
    },
    async CreateMultiSubtask() {
      if (this.array_subtask.length > 0) {
        Swal.fire({
          title: "Are you sure ?",
          text: `คุณต้องการเพิ่มงานย่อยหรือไม่ !`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then(async (result) => {
          if (result.value) {
            axios({
              method: "post",
              url: IP + "/task/create-multi-task",
              headers: {
                Authorization: AuthKey,
              },
              data: {
                task: this.array_subtask,
              },
            }).then(async (response) => {
              this.Clear();
              await this.UpdateData();
            });
          }
        });
      }
    },
    UpdateData() {
      this.$emit("update-data");
    },
    async UpdateCollapse(item) {
      if (this.collapse == item.ID) {
        this.collapse = 0;
      } else {
        this.collapse = item.ID;
      }
    },
    async UpdateTask() {
      const results = Promise.all([
        this.$validator.validate("update-name-task"),
        this.$validator.validate("update-assign-to-task"),
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        this.task.accountable.push(this.user.EmployeeEmail);
        axios({
          method: "post",
          url: IP + "/task/update-main-task",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            task: this.task,
          },
        }).then(async (response) => {
          if (response.data == "success") {
            await this.Clear();
            await this.UpdateData();
          }
        });
      }
    },
    async UpdateSubtask() {
      const results = Promise.all([
        this.$validator.validate("accountable-name-subtask"),
        this.$validator.validate("accountalbe-subtask-of"),
      ]);

      const areValid = (await results).every((isValid) => isValid);
      if (areValid) {
        this.subtask.type = "subtask";
        axios({
          method: "post",
          url: IP + "/task/update-task",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            task: this.subtask,
          },
        }).then(async (response) => {
          if (response.data == "success") {
            await this.Clear();
            await this.UpdateData();
          }
        });
      }
    },
    DeleteTask(id, type) {
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบงานนี้หรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          axios({
            method: "post",
            url: IP + "/task/delete-task",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              id: id,
              type: type,
            },
          }).then(async (response) => {
            if (response.data == "success") {
              await this.Clear();
              await this.UpdateData();
              Swal.fire("ลบงานเสร็จสิ้น !", "", "success");
            }
          });
        }
      });
    },
    CheckIn() {
      this.$validator
        .validate("accountable-checkin-hours")
        .then(async (result) => {
          if (result) {
            Swal.fire({
              title: "Are you sure ?",
              text: `คุณต้องการเช็คอินงานนี้หรือไม่ !`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            }).then(async (result) => {
              if (result.value) {
                this.checkin.checkinby = this.user.EmployeeEmail;
                axios({
                  method: "post",
                  url: IP + "/task/checkin",
                  headers: {
                    Authorization: AuthKey,
                  },
                  data: {
                    checkin: this.checkin,
                  },
                }).then(async (response) => {
                  if (response.data == "success") {
                    await this.Clear();
                    await this.UpdateData();
                    Swal.fire("เช็คอินเสร็จสิ้น !", "", "success");
                  }
                });
              }
            });
          }
        });
    },
    GetTaskRelatedPerson(data) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/get-task-related-person",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            id: data.ID,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    GetCheckInHistory(id) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/task/get-checkin-history",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            id: id,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    remove(action, item) {
      if (action == "assign") {
        const index = this.task.assign_to.indexOf(item.email);
        if (index >= 0) this.task.assign_to.splice(index, 1);
      } else if (action == "accountable") {
        const index = this.task.accountable.indexOf(item.email);
        if (index >= 0) this.task.accountable.splice(index, 1);
      }
    },
    Clear() {
      this.errors.clear();
      this.dialog_task = false;
      this.dialog_subtask = false;
      this.dialog_checkin = false;
      this.dialog_multi_subtask = false;
      this.task = {};
      this.subtask = {};
      this.checkin = {};
      this.action = "";
      this.array_subtask = [];
    },
    Action(action, data) {
      this.action = action;
      if (action == "update-subtask") {
        this.subtask = {
          id: data.ID,
          name: data.TaskName,
          subtask_of: data.SubtaskOf,
        };
        this.dialog_subtask = true;
      } else if (action == "checkin") {
        this.checkin = {
          id: data.ID,
          type: data.TaskType,
          subtask_of: data.SubtaskOf,
          status: "Doing",
        };
        this.GetCheckInHistory(data.ID).then((response) => {
          this.listCheckin = response;
        });
        this.dialog_checkin = true;
      } else if (action == "update-task") {
        this.task = {
          id: 0,
          name: "",
          assign_to: [],
          accountable: [],
        };
        this.GetTaskRelatedPerson(data).then((response) => {
          var assign_to = [];
          var accountable = [];
          response.forEach(function (val, i) {
            if (val.Role == "Responsible") {
              this.task.assign_to.push(val.EmployeeEmail);
            } else if (
              val.Role == "Accountable" &&
              val.EmployeeEmail != this.user.EmployeeEmail
            ) {
              this.task.accountable.push(val.EmployeeEmail);
            }
          }, this);
          this.task.name = data.TaskName;
          this.task.id = data.ID;
          this.dialog_task = true;
        }, this);
      } else if (action == "row-add-subtask") {
        this.project_id = data.ProjectID;
        this.subtask_of = data.ID;
        this.subtask = {};
        this.array_subtask = [];
        this.dialog_multi_subtask = true;
      }
      this.action = action;
    },
    Moment(date) {
      if (date != null && date != "") {
        return moment(date).format("YYYY-MM-DD");
      } else {
        return date;
      }
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    start() {
      this.resize();
      this.user = this.$store.getters.currentUser;
      console.log(this.listItems);
    },
  },
  props: ["listItems", "member"],
  created() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
// @import "../../css/transition.scss";

.main-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}

.data-table-maintask {
  background: rgb(230, 230, 230);
  height: 60px;
  font-weight: bold;
}
</style>