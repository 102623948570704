<template>
  <div>
    <Header v-if="$store.getters.currentUser == null && login == true" :email="email" @update-data="Logout" />
    <Login @update-data="Login" v-if="!login" />
    <div v-if="login">
      <div v-if="width > 600" style="margin-top: 60px">
        <v-navigation-drawer
          permanent
          class="navigation"
          :style="`position: fixed; width: 250px; height: ${height - 74}px`"
        >
          <v-list style="margin-top: 80px">
            <v-list-item
              link
              v-for="(item, index) in menu"
              :key="index"
              :id="item.id === focus ? 'list-focus' : ''"
              @click="focus = item.id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon class="mr-2">{{ item.icon }}</v-icon> {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <br />
        <div
          class="content-area"
          :style="`width: ${width - 330}px; margin-left: 280px;`"
        >
          <v-card :style="`min-height: ${height - 200}px`">
            <div class="content-inside">
              <v-container>
                <!-- component -->
                <div
                  v-if="focus == null"
                  style="text-align: center; margin-top: 50px"
                >
                  Please select menu.
                </div>
                <Exam v-if="focus == 0" :email="email" />
                <ExamHistory v-if="focus == 1" :email="email" />
                <ExamSummary v-if="focus == 2" :email="email" />
              </v-container>
            </div>
          </v-card>
        </div>
      </div>
      <div class="mobile" style="width: 80%; margin: 0 auto; min-width: 300px; margin-top: 100px; text-align: center;" v-if="width <= 600">
        <!-- mobile -->
        <p>This feature doesn't support mobile platform (min width: 601px).</p>
      </div>
    </div>
  </div>
</template>

<script>
import Login from "../components/MainExam/Login";
import Header from "../components/MainExam/Header";
import Exam from '../components/MainExam/Exam';
import ExamHistory from '../components/MainExam/ExamHistory';
import ExamSummary from '../components/MainExam/ExamSummary';
import { WindowResize } from "../function/WindowResize";

export default {
  data() {
    return {
      width: 0,
      height: 0,
      user: "",
      login: false,
      email: "",
      focus: 0,
      menu: [
        {
          name: "Exam",
          icon: "mdi-book-open-variant",
          id: 0,
        },
        {
          name: "Exam History",
          icon: "mdi-book-open-variant",
          id: 1,
        },
        // {
        //   name: "Exam Summary",
        //   icon: "mdi-book-open-variant",
        //   id: 2,
        // },
      ],
    };
  },
  components: {
    Login,
    Header,
    Exam,
    ExamHistory,
    ExamSummary
  },
  methods: {
    Logout(value){
      localStorage.removeItem('ExamUser')
      this.email = null
      this.login = false
    },
    Login(result) {
      this.email = result;
      localStorage.setItem('ExamUser', result)
      this.login = true;
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    Start() {
      this.resize();
      this.user = this.$store.getters.currentUser;
      if (this.user != null) {
        this.email = this.user.EmployeeEmail;
        this.login = true;
      }else{
        this.email = localStorage.getItem('ExamUser')
        if(this.email != null){
          this.login = true;
        }
      }
    },
  },
  created() {
    this.Start();
  },
};
</script>