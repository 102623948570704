<template>
  <div class="login-area">
    <div
      :style="`width: 50%; margin: 0 auto; min-width: 300px; margin-top: ${
        height / 3
      }px`"
    >
      <v-text-field
        label="E-Mail"
        outlined
        name="email"
        v-validate="'email|required'"
        v-model="email"
      ></v-text-field>
      <transition name="slide-fade">
        <v-alert
          type="error"
          style="margin-top: -10px"
          dense
          v-show="errors.has('email')"
          v-if="message == ''"
          >{{ errors.first("email") }}</v-alert
        >
      </transition>
      <transition name="slide-fade">
        <v-alert
          type="error"
          style="margin-top: -10px"
          dense
          v-show="message != ''"
          >{{ message }}</v-alert
        >
      </transition>
      <v-card-actions class="justify-center">
        <v-btn color="primary" width="200" @click="Login">submit</v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import { WindowResize } from "../../function/WindowResize";

export default {
  data() {
    return {
      width: 0,
      height: 0,
      message: "",
      email: "",
    };
  },
  methods: {
    Login() {
      //   this.$emit("update-data", "login");
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.message = "";
          axios({
            method: "POST",
            url: `${IP}/main-exam/login`,
            headers: {
              Authorization: AuthKey,
            },
            data: {
              email: this.email,
            },
          })
            .then((response) => {
              if (response.data.result.length > 0) {
                this.$emit("update-data", this.email);
              }
            })
            .catch((err) => {
              if (err.response.data != null) {
                this.message = err.response.data.message;
              } else {
                this.message = err;
              }
            });
        }
      });
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
        },
        this
      );
    },
    Start() {
      this.resize();
    },
  },
  created() {
    this.resize();
  },
};
</script>

<style lang="scss" scoped>
.login-area {
  width: 80%;
  margin: 0 auto;
}
</style>