<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-container>
      <div class="content-area">
        <v-row style="border-bottom: 1px solid rgb(216, 216, 216)">
          <v-col cols="auto" style="font-size: 30px">Question Set</v-col>
          <v-col style="width: 100%; padding: 0px 20px 0px 20px">
            <v-text-field
              v-model="search"
              label="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <v-btn
              color="success"
              block
              style="float: right"
              @click="Actions('create')"
            >
              <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
            </v-btn>
          </v-col>
        </v-row>
        <!-- content -->
        <div v-if="listItems.length == 0">
          <p style="font-size: 20px; text-align: center; margin: 40px">
            {{ message }}
          </p>
        </div>
        <v-data-table
          :headers="headers"
          :items="listItems"
          :search="search"
          item-key="ID"
          class="elevation-1"
          style="margin-top: 30px"
          v-if="listItems.length > 0"
        >
          <template v-slot:item.QuestionSetName="{ item }">
            <v-row>
              <v-col style="max-width: 550px">{{ item.QuestionSetName }}</v-col>
            </v-row>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-cogs</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="Actions('update', item)">
                  <v-list-item-title>Update</v-list-item-title>
                </v-list-item>
                <v-list-item @click="Delete(item.ID)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </v-container>

    <!-- dialog -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card>
        <v-card-title v-if="action == 'create'">Add Question Set</v-card-title>
        <v-card-title v-if="action == 'update'"
          >Update Question Set</v-card-title
        >
        <v-card-text style="margin-top: 10px">
          <v-text-field
            outlined
            label="Question Set Name"
            v-model="question_set.QuestionSetName"
            name="question-set-name"
            v-validate="'required'"
          ></v-text-field>
          <transition name="slide-fade">
            <v-alert
              dense
              type="error"
              v-show="errors.has('question-set-name')"
              style="margin-top: -10px"
              v-if="error == ''"
              >{{ errors.first("question-set-name") }}</v-alert
            >
            <v-alert
              dense
              type="error"
              style="margin-top: -10px"
              v-if="error != ''"
              >{{ error }}</v-alert
            >
          </transition>
        </v-card-text>
        <v-card-actions style="margin-top: -20px">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false">cancel</v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="SaveQuestionSet"
            v-if="action == 'create'"
            >save</v-btn
          >
          <v-btn
            color="green darken-1"
            text
            @click="UpdateQuestionSet"
            v-if="action == 'update'"
            >update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../../config";

export default {
  data() {
    return {
      search: "",
      action: "",
      dialog: false,
      question_set: {},
      error: "",
      headers: [
        // { text: "ID", value: "ID" },
        { text: "Question Name", value: "QuestionSetName" },
        { text: "Actions", value: "action", sortable: false },
      ],
      listItems: [],
      message: "No Question Set yet.",
      loading: false,
    };
  },
  methods: {
    SaveQuestionSet() {
      this.error = "";
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$swal
            .fire({
              title: "Are you sure ?",
              text: "คุณต้องการสร้าง question set นี้หรือไม่ !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            })
            .then(async (result) => {
              if (result.value) {
                this.loading = true;
                console.log("create", this.question_set);
                axios({
                  method: "POST",
                  url: `${IP}/question-set/create`,
                  headers: {
                    Authorization: AuthKey,
                  },
                  data: {
                    QuestionSetName: this.question_set.QuestionSetName,
                    CreateBy: this.user.EmployeeEmail,
                  },
                }).then(async (response) => {
                  this.loading = false;
                  this.$swal.fire(
                    "Create Question Set Success!",
                    "",
                    "success"
                  );
                  await this.Clear();
                  await this.Start();
                });
              }
            });
        }
      });
    },
    Clear() {
      this.question_set = {};
      this.dialog = false;
    },
    UpdateQuestionSet() {
      this.error = "";
      this.$validator.validateAll().then((result) => {
        if (result) {
          console.log("update: ", this.question_set);
          axios({
            method: "POST",
            url: `${IP}/question-set/update`,
            headers: {
              Authorization: AuthKey,
            },
            data: {
              ID: this.question_set.ID,
              QuestionSetName: this.question_set.QuestionSetName,
            },
          }).then(async (response) => {
            this.$swal.fire("Update Question Set Success!", "", "success");
            await this.Clear();
            await this.Start();
          });
        }
      });
    },
    Delete(id) {
      this.$swal
        .fire({
          title: "Are you sure ?",
          text: `Do you want to delete this question set?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then(async (result) => {
          if (result.value) {
            this.loading = true;
            console.log("delete question set id: ", id);
            axios
              .delete(`${IP}/question-set/delete/${id}`, {
                headers: {
                  Authorization: AuthKey,
                },
              })
              .then(async (response) => {
                this.loading = false;
                this.$swal.fire("Delete Question Set Success!", "", "success");
                await this.Clear();
                await this.Start();
              });
          }
        });
    },
    Actions(action, item) {
      if (action == "create") {
        this.question_set = {};
        this.action = action;
        this.dialog = true;
      } else if (action == "update") {
        console.log("item: ", item);
        this.action = action;
        this.question_set = JSON.parse(JSON.stringify(item));
        this.dialog = true;
      }
    },
    GetAllQuestionSet() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/question-set/get-all`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            console.log("listItems: ", response.data);
            this.listItems = response.data;
            resolve(true);
          });
      });
    },
    async Start() {
      this.user = this.$store.getters.currentUser;
      this.message = "Loading...";
      await this.GetAllQuestionSet().then(() => {
        this.message = "No question set yet.";
      });
    },
  },
  created() {
    this.Start();
  },
};
</script>

<style lang="scss" scoped>
.content-area {
  width: 95%;
  margin: 0 auto;
}
</style>