<template>
    <div class="child">
        {{ value }}
        <v-text-field v-model="value[0].name" outlined @change="UpdateData"></v-text-field>
    </div>
</template>

<script>
export default {
    props: ['text'],
    data(){
        return {
            value: this.text
        }
    },
    methods: {
        UpdateData(){
            this.$emit("update-data", this.value);
        }
    }
}
</script>