<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12">
          <h4>ภาษาไทย (Thai)</h4>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group v-model="step5.languageThaiSpeaking" :rules="nameThaiS" :mandatory="false" row label="พูด :">
            <v-radio label="ดี" class="mx-4" value="ดี"></v-radio>
            <v-radio label="ปานกลาง" class="mx-4" value="ปานกลาง"></v-radio>
            <v-radio label="พอใช้" class="mx-4" value="พอใช้"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group v-model="step5.languageThaiWriting" :rules="nameThaiW" :mandatory="false" row label="เขียน :">
            <v-radio label="ดี" class="mx-4" value="ดี"></v-radio>
            <v-radio label="ปานกลาง" class="mx-4" value="ปานกลาง"></v-radio>
            <v-radio label="พอใช้" class="mx-4" value="พอใช้"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group v-model="step5.languageThaiReading" :rules="nameThaiR" :mandatory="false" row label="อ่าน :">
            <v-radio label="ดี" class="mx-4" value="ดี"></v-radio>
            <v-radio label="ปานกลาง" class="mx-4" value="ปานกลาง"></v-radio>
            <v-radio label="พอใช้" class="mx-4" value="พอใช้"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <h4>ภาษาอังกฤษ (English)</h4>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group v-model="step5.languageEngSpeaking" :rules="nameEngS" :mandatory="false" row label="พูด :">
            <v-radio label="ดี" class="mx-4" value="ดี"></v-radio>
            <v-radio label="ปานกลาง" class="mx-4" value="ปานกลาง"></v-radio>
            <v-radio label="พอใช้" class="mx-4" value="พอใช้"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group v-model="step5.languageEngWriting" :rules="nameEngW" :mandatory="false" row label="เขียน :">
            <v-radio label="ดี" class="mx-4" value="ดี"></v-radio>
            <v-radio label="ปานกลาง" class="mx-4" value="ปานกลาง"></v-radio>
            <v-radio label="พอใช้" class="mx-4" value="พอใช้"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group v-model="step5.languageEngReading" :rules="nameEngR" :mandatory="false" row label="อ่าน :">
            <v-radio label="ดี" class="mx-4" value="ดี"></v-radio>
            <v-radio label="ปานกลาง" class="mx-4" value="ปานกลาง"></v-radio>
            <v-radio label="พอใช้" class="mx-4" value="พอใช้"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12">
          <h4>ภาษาจีน (Chinese)</h4>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group
            v-model="step5.languageChineseSpeaking"
            :mandatory="false"
            :rules="nameChineseS"
            row
            label="พูด :"
          >
            <v-radio label="ดี" class="mx-4" value="ดี"></v-radio>
            <v-radio label="ปานกลาง" class="mx-4" value="ปานกลาง"></v-radio>
            <v-radio label="ไม่ได้" class="mx-4" value="ไม่ได้"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group
            v-model="step5.languageChineseWriting"
            :mandatory="false"
            :rules="nameChineseW"
            row
            label="เขียน :"
          >
            <v-radio label="ดี" class="mx-4" value="ดี"></v-radio>
            <v-radio label="ปานกลาง" class="mx-4" value="ปานกลาง"></v-radio>
            <v-radio label="ไม่ได้" class="mx-4" value="ไม่ได้"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group
            v-model="step5.languageChineseReading"
            :mandatory="false"
            :rules="nameChineseR"
            row
            label="อ่าน :"
          >
            <v-radio label="ดี" class="mx-4" value="ดี"></v-radio>
            <v-radio label="ปานกลาง" class="mx-4" value="ปานกลาง"></v-radio>
            <v-radio label="ไม่ได้" class="mx-4" value="ไม่ได้"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col class="d-flex" cols="12">
          <v-text-field v-model="step5.languageOtherTitle" label="อื่นๆ (Other)"></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn class="mx-2" @click="previousState(5)" text>
          <v-icon style="margin-right: 5px;">mdi-arrow-left</v-icon>
          previous</v-btn>
        <v-spacer></v-spacer>
      <v-btn color="primary" @click="validate(6)" text>Next
        <v-icon style="margin-left: 5px;">mdi-arrow-right</v-icon>
      </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
export default {
  updated() {
    this.$emit(
      "language",
      this.step5.languageThaiSpeaking,
      this.step5.languageThaiWriting,
      this.step5.languageThaiReading,
      this.step5.languageEngSpeaking,
      this.step5.languageEngWriting,
      this.step5.languageEngReading,
      this.step5.languageChineseSpeaking,
      this.step5.languageChineseWriting,
      this.step5.languageChineseReading,
      this.step5.languageOtherTitle
    );
  },
  data() {
    return {
      step5: {},
      nextStep1: 5,
      valid: true,
      nameThaiS: [v => !!v || "ThaiSpeaking is required"],
      nameThaiW: [v => !!v || "ThaiWriting is required"],
      nameThaiR: [v => !!v || "ThaiReading is required"],
      nameEngS: [v => !!v || "EnglishSpeaking is required"],
      nameEngW: [v => !!v || "EnglishWriting is required"],
      nameEngR: [v => !!v || "EnglishReading is required"],
      nameChineseS: [v => !!v || "ChineseSpeaking is required"],
      nameChineseW: [v => !!v || "ChineseWriting is required"],
      nameChineseR: [v => !!v || "ChineseReading is required"]
    };
  },
  methods: {
    previousState(e) {
      this.nextStep1 = e - 1;
      this.$emit("previousE1", parseInt(this.nextStep1));
    },
    validate(e) {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == true) {
        this.nextStep1 = e;
        this.$emit("nextE1", parseInt(this.nextStep1));
      }
    }
  }
};
</script>