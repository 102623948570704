<template>
  <div class="expansion-panels">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <div class="profile">
      <!-- don't have image -->
      <div
        v-if="
          (user_data.ProfileImage == null ||
            user_data.ProfileImage == '' ||
            user_data.ProfileImage == 'null') &&
          profile_preview == ''
        "
        @click="chooseFiles"
        class="click-profile-area"
      >
        <!-- btn -->
        <input
          id="fileUploadProfile"
          type="file"
          accept="image/*"
          hidden
          @change="handleFileUploadProfile"
        />
        <div class="profile-area">
          <v-icon class="icon-plus" x-large>mdi-plus</v-icon>
        </div>
      </div>

      <!-- have image -->
      <div
        v-if="
          (user_data.ProfileImage != null &&
            user_data.ProfileImage != '' &&
            user_data.ProfileImage != 'null') ||
          profile_preview != ''
        "
        @click="chooseFiles"
        class="click-profile-area"
      >
        <!-- btn -->
        <input
          id="fileUploadProfile"
          type="file"
          accept="image/*"
          hidden
          @change="handleFileUploadProfile"
        />
        <div class="btn-update-profile">
          <v-icon class="icon-update" large color="white">mdi-update</v-icon>
        </div>
        <div class="profile-area" style="opacity: 1; border: none">
          <v-img
            :src="`${IP}/profile_img/${user_data.ProfileImage}`"
            :lazy-src="`${IP}/profile_img/${user_data.ProfileImage}`"
            aspect-ratio="1"
            class="grey lighten-2"
            v-if="profile_preview == ''"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-img
            :src="profile_preview"
            :lazy-src="profile_preview"
            aspect-ratio="1"
            class="grey lighten-2"
            v-if="profile_preview != ''"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </div>
    </div>
    <div class="information">
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
          <v-text-field
            label="Email"
            outlined
            disabled
            v-model="user_update.EmployeeEmail"
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
          <v-text-field
            label="Name"
            outlined
            v-model="user_update.EmployeeName"
            v-validate="'required'"
            name="name"
            dense
            hide-details
          />
          <transition name="slide-fade">
            <v-alert
              type="error"
              dense
              style="margin-top: 10px; margin-bottom: -10px"
              v-show="errors.has('name')"
              >{{ errors.first("name") }}</v-alert
            >
          </transition>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="4">
          <v-text-field
            label="Nickname"
            outlined
            v-model="user_update.NickName"
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            label="Employee ID"
            type="number"
            outlined
            v-model="user_update.EmployeeID"
            dense
            hide-details
            :disabled="user.Role != 'Admin'"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            label="Position"
            outlined
            v-model="user_update.Position"
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            label="Department"
            outlined
            v-model="user_update.Department"
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            label="Telephone Number"
            outlined
            v-model="user_update.TelephoneNumber"
            name="phone"
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-menu
            v-model="menu_birthday"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="user_update.Birthday"
                label="Birthday"
                append-icon="event"
                readonly
                v-on="on"
                outlined
                name="birthday"
                dense
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="user_update.Birthday"
              @input="menu_birthday = false"
            ></v-date-picker>
          </v-menu>
          <!-- <transition name="slide-fade">
            <v-alert type="error" v-show="errors.has('birthday')">{{
              errors.first("birthday")
            }}</v-alert>
          </transition> -->
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-menu
            v-model="menu_startdate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="user_update.StartDateOfEmployment"
                label="Start Date"
                append-icon="event"
                readonly
                v-on="on"
                outlined
                disabled
                dense
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="user_update.StartDateOfEmployment"
              @input="menu_startdate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-menu
            v-model="menu_enddate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="user_update.EndDateOfEmployment"
                label="End Date"
                append-icon="event"
                readonly
                v-on="on"
                outlined
                dense
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="user_update.EndDateOfEmployment"
              @input="menu_enddate = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-select
            :items="status"
            label="Status"
            outlined
            v-model="user_update.Status"
            dense
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <v-text-field
            v-model="user_update.Facebook"
            label="Facebook"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <v-text-field
            v-model="user_update.Line"
            label="Line"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            label="Toeic Score"
            type="number"
            outlined
            v-model="user_update.ToeicScore"
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <v-menu
            v-model="menu_toeicexpire"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="user_update.ToeicExpireDate"
                label="Toeic Expire Date"
                append-icon="event"
                readonly
                v-on="on"
                outlined
                dense
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="user_update.ToeicExpireDate"
              @input="menu_toeicexpire = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <input
            id="fileUploadResume"
            type="file"
            hidden
            @change="handleFileUploadResume"
          />
          <v-btn
            tile
            color="indigo"
            dark
            block
            @click="chooseFilesResume"
            style="height: 45px"
            v-if="resume_name == null"
          >
            <v-icon style="margin-left: -5px; margin-right: 5px"
              >mdi-file-document</v-icon
            >Upload Resume
          </v-btn>
          <v-btn
            tile
            color="success"
            dark
            block
            @click="chooseFilesResume"
            style="height: 45px"
            v-if="resume_name != null"
          >
            <v-icon style="margin-left: -5px; margin-right: 5px"
              >mdi-file-document</v-icon
            >{{ resume_name }}
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <v-btn
            tile
            color="primary"
            dark
            block
            @click="DownloadFile(user_update.ResumeFile)"
            style="height: 45px"
          >
            <v-icon>mdi-download</v-icon>Download Resume
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <hr style="margin-top: 30px" />
    <v-card-actions class="justify-center" style="margin-top: 20px">
      <v-btn
        color="success"
        width="105%"
        max-width="300px"
        height="45px"
        @click="SaveContent"
      >
        <v-icon style="margin-right: 5px">mdi-content-save</v-icon>save
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../../config";
import download from "downloadjs";

export default {
  data() {
    return {
      IP: IP,
      user: null,
      user_data: "",
      user_update: {},
      profile_preview: "",
      menu_birthday: false,
      menu_startdate: false,
      menu_enddate: false,
      menu_toeicexpire: false,
      resume_name: null,
      old_resume_name: null,
      status: ["Active", "Inactive"],
      loading: false,
    };
  },
  methods: {
    DownloadFile(filename) {
      fetch(`${IP}/user-management/download-resume`, {
        method: "post",
        headers: {
          Authorization: AuthKey,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filename: filename,
        }),
      }).then((res) => {
        const blob = res.blob();
        blob.then(async (result) => {
          await download(result, filename, result.type);
        }, this);
      });
    },
    GetUser() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/user-management/get-user-by-email",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.email,
          },
        }).then(async (response) => {
          this.user_data = response.data;
          // console.log(this.user_data);
          await this.SetValue();
          resolve(true);
        });
      });
    },
    SaveContent() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // console.log("save: ", this.user_update);
          this.SaveProcess().then(async (response) => {
            if (response == "success") {
              this.resume_name = null;
              Swal.fire("แก้ไขข้อมูลเสร็จสิ้น !", "", "success");
              this.SetState().then((response) => {
                // console.log("success");
              });
            }
          });
        }
      });
    },
    SaveProcess() {
      return new Promise(async (resolve, reject) => {
        if (this.user_update.Facebook == "null") {
          this.user_update.Facebook = "";
        }
        if (this.user_update.Line == "null") {
          this.user_update.Line = "";
        }
        const data = new FormData();
        data.append("profile_file", this.user_update.ProfileImage);
        data.append("resume_file", this.user_update.UpdateResumeFile);
        data.append("EmployeeID", this.user_update.EmployeeID)
        data.append("EmployeeEmail", this.user_update.EmployeeEmail);
        data.append("EmployeeName", this.user_update.EmployeeName);
        data.append("NickName", this.user_update.NickName);
        data.append("Position", this.user_update.Position);
        data.append("Department", this.user_update.Department);
        data.append("TelephoneNumber", this.user_update.TelephoneNumber);
        data.append("Birthday", this.user_update.Birthday);
        data.append("Status", this.user_update.Status);
        data.append("Facebook", this.user_update.Facebook);
        data.append("Line", this.user_update.Line);
        data.append(
          "EndDateOfEmployment",
          this.user_update.EndDateOfEmployment
        );
        data.append("ToeicScore", this.user_update.ToeicScore);
        data.append("ToeicExpireDate", this.user_update.ToeicExpireDate);
        data.append("ResumeName", this.old_resume_name);
        data.append("ProfileName", this.user_data.ProfileImage);

        axios
          .post(IP + "/user-management/update-information", data, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            resolve(response.data);
          });
      });
    },
    async SetState() {
      return new Promise(async (resolve, reject) => {
        await this.GetUser();
        if (this.user.EmployeeEmail == this.user_data.EmployeeEmail) {
          this.SelectUser(this.user.EmployeeEmail).then((response) => {
            this.$store.commit("updateProfile", response);
          });
        }
      });
    },
    SelectUser(email) {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/select-user-data",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: email,
          },
        }).then((response) => {
          const data = response.data;
          resolve(data);
        });
      });
    },
    async SetValue() {
      this.user_update = {
        EmployeeID: this.user_data.EmployeeID,
        EmployeeEmail: this.user_data.EmployeeEmail,
        EmployeeName: this.user_data.EmployeeName,
        NickName: this.user_data.NickName,
        ProfileImage: this.user_data.ProfileImage,
        Position: this.user_data.Position,
        Department: this.user_data.Department,
        ManagerEmail: this.user_data.ManagerEmail,
        TelephoneNumber: this.user_data.TelephoneNumber,
        Birthday: this.user_data.Birthday,
        Status: this.user_data.Status,
        StartDateOfEmployment: this.user_data.StartDateOfEmployment,
        EndDateOfEmployment: this.user_data.EndDateOfEmployment,
        ToeicScore: this.user_data.ToeicScore,
        ToeicExpireDate: this.user_data.ToeicExpireDate,
        UpdateResumeFile: null,
        ResumeFile: this.user_data.ResumeFile,
        Facebook: this.user_data.Facebook,
        Line: this.user_data.Line,
      };

      this.old_resume_name = this.user_data.ResumeFile;
      // this.resume_name = this.user_data.ResumeFile;

      if (this.user_data.Birthday != null && this.user_data.Birthday != "") {
        this.user_update.Birthday = await this.Moment(this.user_data.Birthday);
      }
      if (
        this.user_data.StartDateOfEmployment != null &&
        this.user_data.StartDateOfEmployment != ""
      ) {
        this.user_update.StartDateOfEmployment = await this.Moment(
          this.user_data.StartDateOfEmployment
        );
      }
      if (
        this.user_data.EndDateOfEmployment != null &&
        this.user_data.EndDateOfEmployment != ""
      ) {
        this.user_update.EndDateOfEmployment = await this.Moment(
          this.user_data.EndDateOfEmployment
        );
      }
      if (
        this.user_data.ToeicExpireDate != null &&
        this.user_data.ToeicExpireDate != ""
      ) {
        this.user_update.ToeicExpireDate = await this.Moment(
          this.user_data.ToeicExpireDate
        );
      }
    },
    chooseFiles() {
      document.getElementById("fileUploadProfile").click();
    },
    async handleFileUploadProfile(e) {
      const file = e.target.files;
      this.user_update.ProfileImage = file[0];
      this.profile_preview = await this.toBase64(file[0]);
    },
    chooseFilesResume() {
      document.getElementById("fileUploadResume").click();
    },
    async handleFileUploadResume(e) {
      const file = e.target.files;
      this.user_update.UpdateResumeFile = file[0];
      this.resume_name = file[0].name;
    },
    toBase64(file) {
      return new Promise(async (resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    SubString(str, num) {
      var res = "";
      if (str.length > num) {
        res = str.substring(0, num) + "...";
      } else {
        res = str;
      }
      return res;
    },
    Moment(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    async start() {
      this.loading = true;
      this.user = this.$store.getters.currentUser;
      await this.GetUser().then((response) => {
        var that = this;
        setTimeout(function () {
          that.loading = false;
        }, 1000);
      });
    },
  },
  props: ["email"],
  created() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";

.expansion-panels {
  margin-bottom: 30px;
}

.profile {
  margin: 20px;
}

.click-profile-area {
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: relative;
  left: 50%;
  margin-left: -50px;
  margin-bottom: 50px;
}

.profile-area {
  width: 100px;
  height: 100px;
  border: 3px solid #757575;
  opacity: 0.5;
  transition: 0.3s all;
}

.profile-area:hover {
  opacity: 1;
}

.icon-plus {
  left: 29%;
  top: 29%;
}

.icon-update {
  left: 32%;
  top: 32%;
}

.btn-update-profile {
  width: 100px;
  height: 100px;
  background: black;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  z-index: 10;
  opacity: 0;
  transition: 0.3s all;
}

.btn-update-profile:hover {
  opacity: 0.3;
}
</style>