<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            :items="namePrefixThai"
            v-model="step1.prefixNameThai"
            @change="showInputThai"
            v-if="showAnotherThai"
            :rules="namePrefixThai1"
            required
            label="คำนำหน้าชื่อ (ไทย)"
          ></v-select>
          <v-text-field v-model="step1.prefixNameThai" :rules="namePrefixThai1" v-if="showAnotherThai == false" label="คำนำหน้าชื่อ" required></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field v-model="step1.nameThai" label="ชื่อ (ไทย)" :rules="nameRules1" required></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-select
            :items="namePrefixEng"
            v-model="step1.prefixNameEng"
            :rules="namePrefixEng2"
             @change="showInputEng"
            v-if="showAnotherEng"
            required
            label="คำนำหน้าชื่อ (Eng)"
          ></v-select>
          <v-text-field v-model="step1.prefixNameEng" :rules="namePrefixEng2" v-if="showAnotherEng == false" label="คำนำหน้าชื่อ (Eng)" required></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field v-model="step1.nameEng" label="Name (Eng) " :rules="nameRules2" required></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="step1.position"
            label="ตําแหน่งที่ต้องการ (Position) "
            :rules="positionRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="step1.salary"
            prefix="$"
            label="เงินเดือน (Salary) "
            :rules="salaryRules"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" xs="6" md="6" lg="6" xl="6">
          <v-radio-group
            v-model="step1.sex"
            :mandatory="false"
            :rules="sexRules"
            row
            label="เพศ (Sex) :"
          >
            <v-radio label="ชาย (Male)" class="md-6" value="Male"></v-radio>
            <v-radio label="หญิง (Famale)" class="md-6" value="Famale"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="validate(2)" text>Next
          <v-icon style="margin-left: 5px;">mdi-arrow-right</v-icon>
          </v-btn>        
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      step1: {},
      valid: true,
      
      showAnotherThai:true,
      showAnotherEng:true,
      nextStep1: 1,
      namePrefixThai: ["นาย", "นาง", "นางสาว","อื่นๆ"],
      namePrefixEng: ["Mr.", "Mrs.", "Miss.","อื่นๆ"],
      sexRules: [v => !!v || "Sex is required"],
      namePrefixThai1: [v => !!v || "Name Prefix thai is required"],
      namePrefixEng2: [v => !!v || "Name Prefix Eng is required"],
      nameRules1: [
        v => !!v || "Name thai is required",
        
        v => /^[ก-๏\s]+$/.test(v) || "Name must be in Thai only"
      ],
      nameRules2: [
        v => !!v || "Name eng is required",
        v => /^[a-zA-Z\s]+$/.test(v) || "Name must be in English only"
      ],
      positionRules: [
        v => !!v || "Position is required",
        v => (v && v.length <= 40) || "Position must be less than 40 characters"
      ],
      salaryRules: [
        v => !!v || "Salary is required",
        v => /^\d{1,6}(?:\.\d{0,2})?$/.test(v) || "Salary must be numbers only"
      ]
    };
  },
  updated() {
    this.$emit(
      "step1",
      this.step1.prefixNameThai+this.step1.nameThai,
      this.step1.prefixNameEng+this.step1.nameEng,
      this.step1.position,
      this.step1.salary,
      this.step1.sex
    );
  },
  methods: {
    showInputThai(e){
      if(e == 'อื่นๆ'){
        this.showAnotherThai = false
        this.step1.prefixNameThai = ''
      }
    },
    showInputEng(e){
      if(e == 'อื่นๆ'){
        this.showAnotherEng = false
        this.step1.prefixNameEng = ''
      }
    },
    validate(e) {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == true) {
        this.nextStep1 = e;
        this.$emit("nextE1", parseInt(this.nextStep1));
      }
    }
  }
};
</script>