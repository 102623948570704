<template>
  <div class="job">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <div style="margin-top: 50px; margin-bottom: 50px">
      <v-container>
        <v-card style="max-width: 1300px; margin: 0 auto">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <div class="header-text">สมัครงานกับเรา</div>
              <v-row style="padding: 0px 30px 30px 30px">
                <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                  <v-text-field
                    outlined
                    dense
                    label="คำนำหน้า (Prifix)"
                    v-model="applicant.prefix"
                    required
                    :rules="prefixRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
                  <v-text-field
                    outlined
                    dense
                    label="ชื่อ (Firstname)"
                    v-model="applicant.firstname"
                    required
                    :rules="nameRule"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="5"
                  md="5"
                  lg="5"
                  xl="5"
                  class="field-response-600"
                >
                  <v-text-field
                    outlined
                    dense
                    label="นามสกุล (Lastname)"
                    v-model="applicant.lastname"
                    required
                    :rules="surnameRule"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  style="margin-top: -20px"
                >
                  <v-text-field
                    outlined
                    dense
                    label="อีเมล (Email)"
                    v-model="applicant.email"
                    required
                    :rules="emailRule"
                    @keydown.space.prevent
                  ></v-text-field>
                  <div
                    style="
                      float: right;
                      margin-top: -24px;
                      margin-right: 5px;
                      color: gray;
                      font-size: 12px;
                    "
                  >
                    ตัวอย่าง: name@gmail.com
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  style="margin-top: -20px"
                >
                  <v-text-field
                    outlined
                    dense
                    label="เบอร์โทรศัพท์ (Tel.)"
                    v-model="applicant.tel"
                    required
                    :rules="telRule"
                    @keydown.space.prevent
                  ></v-text-field>
                  <div
                    style="
                      float: right;
                      margin-top: -24px;
                      margin-right: 5px;
                      color: gray;
                      font-size: 12px;
                    "
                  >
                    ตัวอย่าง: 0909199218
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  lg="4"
                  xl="4"
                  style="margin-top: -20px"
                >
                  <v-text-field
                    outlined
                    dense
                    label="ตำแหน่ง (Position)"
                    v-model="applicant.position"
                    required
                    :rules="positionRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div
                    style="
                      font-size: 20px;
                      padding: 0px 20px 20px 20px;
                      border-bottom: 1px solid rgb(240, 240, 240);
                    "
                  >
                    ประวัติการศึกษา (Educational History)
                  </div>
                </v-col>
                <v-col v-if="applicant.education.length > 0">
                  <v-row
                    v-for="(edu, index) in applicant.education"
                    :key="index"
                    style="
                      border: 1px solid rgb(240, 240, 240);
                      border-radius: 15px;
                      margin: 10px;
                    "
                  >
                    <v-col
                      cols="12"
                      style="
                        margin-top: -20px;
                        margin-bottom: -20px;
                        text-align: right;
                      "
                    >
                      <v-btn
                        icon
                        color="error"
                        @click="Remove('Education', edu.key)"
                        style="margin-right: -30px"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                      <v-autocomplete
                        v-model="edu.educationLevel"
                        :items="educationLevel"
                        outlined
                        dense
                        label="ระดับการศึกษา (Level)"
                        required
                        :rules="educationLevelRule"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                      class="field-response-600"
                    >
                      <v-text-field
                        outlined
                        dense
                        label="สถาบัน (Institution)"
                        v-model="edu.institution"
                        required
                        :rules="institutionRule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                      style="margin-top: -20px"
                    >
                      <v-text-field
                        outlined
                        dense
                        label="สาขาวิชา (Major)"
                        v-model="edu.major"
                        required
                        :rules="majorRule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                      style="margin-top: -20px"
                    >
                      <v-text-field
                        v-model="edu.studyResult"
                        label="ผลการศึกษา (Grade)"
                        dense
                        outlined
                        required
                        :rules="studyResultRule"
                        @keydown.space.prevent
                      ></v-text-field>
                      <div
                        style="
                          float: right;
                          margin-top: -24px;
                          margin-right: 5px;
                          color: gray;
                          font-size: 12px;
                        "
                      >
                        ตัวอย่าง: 3.5 , 3.25
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-card-actions class="justify-center">
                    <v-btn color="success" outlined @click="Add('Education')">
                      <v-icon style="margin-right: 5px">mdi-plus-circle</v-icon>
                      เพิ่มประวัติการศึกษา (Add)</v-btn
                    >
                  </v-card-actions>
                </v-col>
                <v-col cols="12">
                  <div
                    style="
                      font-size: 20px;
                      padding: 0px 20px 20px 20px;
                      border-bottom: 1px solid rgb(240, 240, 240);
                    "
                  >
                    <v-row style="margin-bottom: -25px">
                      <v-col cols="6"
                        >ประวัติการทำงานล่าสุด (Latest Experience)</v-col
                      >
                      <v-col cols="6" style="margin-top: -20px">
                        <v-radio-group
                          v-model="applicant.work_exp.have_exp"
                          row
                          style="float: right"
                        >
                          <v-radio label="มี (have)" :value="true"></v-radio>
                          <v-radio
                            label="ไม่มี (don't have)"
                            :value="false"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" v-if="!applicant.work_exp.have_exp">
                  <div style="text-align: center; padding: 20px">
                    ไม่มีประวัติการทำงาน
                  </div>
                </v-col>
                <v-col cols="12" v-if="applicant.work_exp.have_exp">
                  <v-row>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="applicant.work_exp.start_date"
                            label="วันเริ่มทำงาน (Start Date)"
                            append-icon="event"
                            v-on="on"
                            outlined
                            dense
                            required
                            :rules="start_dateRule"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="applicant.work_exp.start_date"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                      class="field-response-600"
                    >
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="applicant.work_exp.end_date"
                            label="วันสุดท้าย (End Date)"
                            append-icon="event"
                            v-on="on"
                            outlined
                            dense
                            required
                            :rules="end_dateRule"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="applicant.work_exp.end_date"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                      style="margin-top: -20px"
                    >
                      <v-text-field
                        label="บริษัท (Company)"
                        outlined
                        dense
                        v-model="applicant.work_exp.company"
                        required
                        :rules="companyRule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                      style="margin-top: -20px"
                    >
                      <v-text-field
                        label="ตำแหน่ง (Position)"
                        outlined
                        dense
                        v-model="applicant.work_exp.position"
                        required
                        :rules="old_positionRule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      xs="12"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                      style="margin-top: -20px"
                    >
                      <v-text-field
                        label="เงินเดือน (Salary)"
                        outlined
                        dense
                        v-model="applicant.work_exp.salary"
                        required
                        :rules="salaryRule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div
                    style="
                      font-size: 20px;
                      padding: 0px 20px 20px 20px;
                      border-bottom: 1px solid rgb(240, 240, 240);
                    "
                  >
                    Resume / CV
                  </div>
                </v-col>
                <v-col cols="12">
                  <div
                    v-if="applicant.resume != null"
                    style="width: 50%; margin: 0 auto; min-width: 350px"
                  >
                    <v-row>
                      <v-col cols="3">
                        <b>Filename </b>
                      </v-col>
                      <v-col cols="9" style="text-align: right">
                        {{ applicant.resume.name }}
                      </v-col>
                      <v-col cols="3" style="margin-top: -20px">
                        <b>Filesize </b>
                      </v-col>
                      <v-col
                        cols="9"
                        style="text-align: right; margin-top: -20px"
                      >
                        {{ pretty(applicant.resume.size) }}
                      </v-col>
                    </v-row>
                  </div>
                  <div
                    v-if="applicant.resume == null"
                    style="text-align: center; padding: 20px"
                  >
                    กรุณาเลือกไฟล์ (pdf, doc, docx)
                  </div>
                  <v-card-actions class="justify-center">
                    <input
                      id="fileUpload"
                      ref="fileUpload"
                      type="file"
                      hidden
                      @change="handleFileUpload"
                      @click="resetFile"
                      accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    <v-btn
                      outlined
                      color="success"
                      @click="chooseFiles"
                      v-if="applicant.resume == null"
                    >
                      <v-icon style="margin-right: 5px">mdi-plus-circle</v-icon>
                      Upload Your Resume / CV
                    </v-btn>
                    <v-btn
                      outlined
                      color="primary"
                      @click="chooseFiles"
                      v-if="applicant.resume != null"
                    >
                      <v-icon style="margin-right: 5px">mdi-update</v-icon>
                      Change Your Resume / CV
                    </v-btn>
                  </v-card-actions>
                  <!-- <div style="text-align: center; font-size: 14px; color: rgb(150, 150, 150)">** pdf, doc, docx **</div> -->
                </v-col>
              </v-row>
              <div style="border-top: 1px solid rgb(230, 230, 230)">
                <transition name="slide-fade">
                  <v-alert type="error" v-if="message != ''" dense>{{
                    message
                  }}</v-alert>
                </transition>
                <v-card-actions class="justify-center">
                  <v-btn
                    color="success"
                    style="width: 200px; margin: 20px 0px 20px 0px"
                    @click="Register"
                    >Submit</v-btn
                  >
                </v-card-actions>
              </div>
            </v-container>
          </v-form>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import pretty from "prettysize";
import axios from "axios";
import { IP, AuthKey } from "../../config";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      message: "",
      pretty: pretty,
      loading: false,
      valid: true,
      applicant: {
        education: [],
        work_exp: {
          have_exp: true,
        },
        resume: null,
      },
      educationLevel: [
        "ระดับชั้นมัธยมปลาย",
        "ปวช",
        "ปวส",
        "ปริญญาตรี",
        "ปริญญาโท",
        "ปริญญาเอก",
      ],
      prefixRule: [(v) => !!v || "Prefix is required"],
      nameRule: [(v) => !!v || "Firstname is required"],
      surnameRule: [(v) => !!v || "Surname is required"],
      emailRule: [
        (v) => !!v || "Email is required",
        (v) =>
          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
          "Email does not match the pattern",
      ],
      telRule: [
        (v) => !!v || "Tel is required",
        (v) => (v && v.length == 10) || "Tel must be less than 10 characters",
        (v) => (v && v.length == 10) || "Tel must be more than 10 characters",
        (v) =>
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) ||
          "Tel does not match the pattern",
      ],
      positionRule: [
        (v) => !!v || "Position is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Position must be in English and Thai only",
      ],
      educationLevelRule: [(v) => !!v || "Education level is required"],
      institutionRule: [
        (v) => !!v || "Institution is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Institution must be in English and Thai only",
      ],
      majorRule: [
        (v) => !!v || "Major is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Major must be in English and Thai only",
      ],
      studyResultRule: [
        (v) => !!v || "Grade is required",
        (v) => /^[0-4]\.\d{1,2}$/.test(v) || "Grade does not match the pattern",
      ],
      start_dateRule: [(v) => !!v || "Start Date is required"],
      end_dateRule: [(v) => !!v || "End Date is required"],
      companyRule: [
        (v) => !!v || "Company is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Company must be in English and Thai only",
      ],
      old_positionRule: [
        (v) => !!v || "Position is required",
        (v) =>
          /^([ก-๏\s]|[a-zA-Z\s])+$/.test(v) ||
          "Position must be in English and Thai only",
      ],
      salaryRule: [
        (v) => !!v || "Salary is required",
        (v) =>
          /^\d{1,7}(?:\.\d{0,2})?$/.test(v) || "Salary must be numbers only",
      ],
    };
  },
  methods: {
    async CutSpacer(value, item) {
      // console.log(value, item);
      this.applicant[value] = item.trim();
    },
    async Register() {
      this.message = "";
      this.$refs.form.validate();
      if (this.$refs.form.validate() == true) {
        if (this.applicant.education.length == 0) {
          this.message = "กรุณาเพิ่มประวัติการศึกษา";
        } else {
          if (this.applicant.resume == null) {
            this.message = "กรุณาอัพโหลด resume / cv";
          } else {
            Swal.fire({
              title: "Are you sure ?",
              text: "คุณต้องการสมัครงานกับเราใช่หรือไม่ !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            }).then(async (result) => {
              if (result.value) {
                this.loading = true;
                console.log("register: ", this.applicant);
                const data = new FormData();
                data.append("resume_file", this.applicant.resume);
                data.append("applicant", JSON.stringify(this.applicant));

                axios
                  .post(`${IP}/applicant/register`, data, {
                    headers: {
                      Authorization: AuthKey,
                    },
                  })
                  .then((response) => {
                    this.loading = false;
                    console.log("response: ", response.data);
                    Swal.fire("ขอบคุณที่สมัครงานกับเรา", "", "success");
                    this.Clear();
                  })
                  .catch((err) => {
                    this.loading = false;
                    console.log("register error: ", err);
                    this.message = err;
                  });
              }
            });
          }
        }
      }
    },
    async resetFile() {
      this.$refs.fileUpload.value = "";
    },

    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    async handleFileUpload(e) {
      const file = e.target.files;
      this.applicant.resume = file[0];
      console.log(this.applicant.resume);
    },
    Add(type) {
      let string = this.RandomString(5);
      if (type == "Education") {
        this.applicant.education.push({
          key: string,
          educationLevel: "",
          institution: "",
          major: "",
          studyResult: "",
        });
      }
    },
    Remove(type, key) {
      if (type == "Education") {
        for (var i = 0; i < this.applicant.education.length; i++) {
          if (this.applicant.education[i].key === key) {
            this.applicant.education.splice(i, 1);
          }
        }
      }
    },
    RandomString(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    Clear() {
      this.applicant = {
        education: [],
        work_exp: {
          have_exp: true,
        },
        resume: null,
      };
      this.$refs.form.reset();
    },
  },
  created() {
    if (this.$store.getters.currentUser != null) {
      this.$router.push("/");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/job.scss";
</style>