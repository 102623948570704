<template>
  <div class="qc-applicant">
    <v-container>
      <div
        v-for="(n, index) in applicants"
        :key="index"
        style="border: 1px solid rgba(240, 240, 240); padding: 20px"
      >
        <b>Name: </b> {{ n.Name }}
        <br />
        <b>Position: </b> {{ n.Position }}
        <br />
        <b>Analysis: </b>
        <ul>
          <li>Email: {{ n.AnalysisValue.email }}</li>
          <li>GPA: {{ n.AnalysisValue.gpa }}</li>
          <li>Toeic: {{ n.AnalysisValue.toeic }}</li>
          <li>
            Experience:
            <ul>
              <li>Final Date: {{ n.AnalysisValue.experience.final_date }}</li>
              <li>
                Without Final Date:
                {{ n.AnalysisValue.experience.without_final }}
              </li>
            </ul>
          </li>
          <li>
            Word Match:
            <span v-for="word in n.AnalysisValue.string_match" :key="word"
              >{{ word }},
            </span>
          </li>
        </ul>
        <b>String: </b> {{ n.ExtractString }}
      </div>
    </v-container>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row>
        <v-col style="font-size: 30px">QC Applicant</v-col>
        <v-col>
          <!-- <v-btn
            style="float: right"
            color="success"
            @click="Action('create', null)"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create Job
          </v-btn> -->
          <v-btn style="float: right" color="success" @click="ReAnalysis">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-chart-bar</v-icon
            >Analysis
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- all position -->
    <div style="padding: 30px; border-bottom: 1px solid rgb(216, 216, 216)">
      <b>Position from data : </b>
      <v-chip
        class="ma-2"
        color="primary"
        text-color="white"
        v-for="(n, index) in all_position"
        :key="index"
      >
        {{ n.Position }}
      </v-chip>
    </div>
    <!-- data table -->
    <div class="data-table">
      <div v-if="listItems.length == 0">
        <p style="font-size: 20px; text-align: center; margin: 40px">
          {{ message }}
        </p>
      </div>
      <br />
      <div v-if="listItems.length > 0">
        <v-text-field
          v-model="search"
          label="search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
        <br />
        <v-data-table
          :headers="headers"
          :items="listItems"
          :search="search"
          class="elevation-1"
          v-if="listItems.length > 0"
        >
          <template v-slot:item.gpa="{ item }">
            <v-chip
              class="ma-2"
              color="green"
              text-color="white"
              v-if="item.Condition.grade.require"
            >
              <v-avatar left>
                <v-icon>mdi-check-circle</v-icon>
              </v-avatar>
              {{ item.Condition.grade.min }}
            </v-chip>
            <v-chip
              class="ma-2"
              color="red"
              text-color="white"
              v-if="!item.Condition.grade.require"
            >
              <v-avatar left>
                <v-icon>mdi-close-circle</v-icon>
              </v-avatar>
              No Request
            </v-chip>
          </template>
          <template v-slot:item.toeic="{ item }">
            <v-chip
              class="ma-2"
              color="green"
              text-color="white"
              v-if="item.Condition.toeic.require"
            >
              <v-avatar left>
                <v-icon>mdi-check-circle</v-icon>
              </v-avatar>
              {{ item.Condition.toeic.min }}
            </v-chip>
            <v-chip
              class="ma-2"
              color="red"
              text-color="white"
              v-if="!item.Condition.toeic.require"
            >
              <v-avatar left>
                <v-icon>mdi-close-circle</v-icon>
              </v-avatar>
              No Request
            </v-chip>
          </template>
          <template v-slot:item.work_experience="{ item }">
            <v-chip
              class="ma-2"
              color="green"
              text-color="white"
              v-if="item.Condition.work_experience.require"
            >
              <v-avatar left>
                <v-icon>mdi-check-circle</v-icon>
              </v-avatar>
              {{ item.Condition.work_experience.min }} Years
            </v-chip>
            <v-chip
              class="ma-2"
              color="red"
              text-color="white"
              v-if="!item.Condition.work_experience.require"
            >
              <v-avatar left>
                <v-icon>mdi-close-circle</v-icon>
              </v-avatar>
              No Request
            </v-chip>
          </template>
          <template v-slot:item.keyword="{ item }">
            <v-chip
              small
              class="ma-2"
              color="primary"
              text-color="white"
              v-for="(n, index) in item.Condition.word"
              :key="index"
              >{{ n }}</v-chip
            >
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn icon @click="$router.push(`/qc-applicant/job/${item.ID}`)">
              <v-icon>mdi-align-vertical-bottom</v-icon>
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-cogs</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="Action('update', item)">
                  <v-list-item-title>Update</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item @click="Delete(item.ID)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </div>
    <!-- dialog -->
    <v-dialog v-model="dialog" width="100%" max-width="500">
      <v-card>
        <v-card-title v-if="action == 'create'">Create Job</v-card-title>
        <v-card-title v-if="action == 'update'">Update Job</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Position Name"
                outlined
                dense
                v-model="job.position"
                name="position_name"
                v-validate="'required'"
              ></v-text-field>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  dense
                  v-show="errors.has('position_name')"
                  style="margin-top: -20px"
                  >{{ errors.first("position_name") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col cols="6" style="margin-top: -40px">
              <v-switch
                v-model="job.grade.require"
                label="Grade"
                color="primary"
              ></v-switch>
            </v-col>
            <v-col cols="6" style="margin-top: -30px">
              <v-text-field
                label="Min"
                type="number"
                :disabled="!job.grade.require"
                outlined
                dense
                v-model="job.grade.min"
              ></v-text-field>
            </v-col>
            <v-col cols="6" style="margin-top: -40px">
              <v-switch
                v-model="job.toeic.require"
                label="Toeic"
                color="primary"
              ></v-switch>
            </v-col>
            <v-col cols="6" style="margin-top: -30px">
              <v-text-field
                label="Min"
                type="number"
                :disabled="!job.toeic.require"
                outlined
                dense
                v-model="job.toeic.min"
              ></v-text-field>
            </v-col>
            <v-col cols="6" style="margin-top: -40px">
              <v-switch
                v-model="job.work_experience.require"
                label="Work Experience"
                color="primary"
              ></v-switch>
            </v-col>
            <v-col cols="6" style="margin-top: -30px">
              <v-text-field
                label="Min (Year)"
                type="number"
                :disabled="!job.work_experience.require"
                outlined
                dense
                v-model="job.work_experience.min"
              ></v-text-field>
            </v-col>
            <v-col cols="12" style="margin-top: -30px">
              <v-combobox
                v-model="job.word"
                label="Keyword"
                multiple
                small-chips
                outlined
              ></v-combobox>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions style="padding: 20px" class="justify-center">
          <v-btn color="success" @click="CreateJob" v-if="action == 'create'">
            <v-icon class="mr-2">mdi-plus</v-icon>create</v-btn
          >
          <v-btn color="primary" @click="UpdateJob" v-if="action == 'update'"
            >update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { IP, AuthKey } from "../../config";
import axios from "axios";

export default {
  data() {
    return {
      user: "",
      search: "",
      message: "No Job yet.",
      raw: [],
      listItems: [],
      applicants: [],
      dialog: false,
      loading: false,
      action: "",
      job: {
        position: "",
        grade: {
          require: false,
          min: 0,
        },
        toeic: {
          require: false,
          min: 0,
        },
        work_experience: {
          require: false,
          min: 0,
        },
        word: [],
      },
      headers: [
        { text: "Position", value: "Position" },
        { text: "GPA", value: "gpa" },
        { text: "Toeic", value: "toeic" },
        { text: "Work Experience", value: "work_experience" },
        { text: "Keyword", value: "keyword" },
        { text: "Actions", align: "right", value: "action", sortable: false },
      ],
      all_position: [],
    };
  },
  methods: {
    Action(action, item) {
      if (action == "create") {
        this.action = action;
        this.job = {
          position: "",
          grade: {
            require: false,
            min: 0,
          },
          toeic: {
            require: false,
            min: 0,
          },
          work_experience: {
            require: false,
            min: 0,
          },
          word: [],
        };
        this.dialog = true;
      } else if (action == "update") {
        this.action = action;
        this.job = JSON.parse(JSON.stringify(item.Condition));
        this.job.position = item.Position;
        this.job.id = item.ID;
        this.dialog = true;
      }
    },
    ReAnalysis() {
      this.loading = true;
      axios
        .get(`${IP}/applicant/analysis`, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then(async (response) => {
          await this.Start();
        });
    },
    CreateJob() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$swal
            .fire({
              title: "Are you sure ?",
              text: "คุณต้องการบันทึกตำแหน่งนี้หรือไม่ !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            })
            .then(async (result) => {
              if (result.value) {
                console.log("job: ", this.job);
                axios({
                  method: "post",
                  url: `${IP}/qc-applicant/insert-job`,
                  headers: {
                    Authorization: AuthKey,
                  },
                  data: {
                    item: this.job,
                    create_by: this.user.EmployeeEmail,
                  },
                }).then(async (response) => {
                  await this.Clear();
                  await this.Start();
                  this.$swal.fire("เพิ่มข้อมูลเสร็จสิ้น !", "", "success");
                });
              }
            });
        }
      });
    },
    UpdateJob() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$swal
            .fire({
              title: "Are you sure ?",
              text: "คุณต้องการอัพเดตตำแหน่งนี้หรือไม่ !",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            })
            .then(async (result) => {
              if (result.value) {
                console.log("job: ", this.job);
                axios({
                  method: "post",
                  url: `${IP}/qc-applicant/update-job`,
                  headers: {
                    Authorization: AuthKey,
                  },
                  data: {
                    item: this.job,
                    ID: this.job.id,
                  },
                }).then(async (response) => {
                  await this.Clear();
                  await this.Start();
                  this.$swal.fire("อัพเดตข้อมูลเสร็จสิ้น !", "", "success");
                });
              }
            });
        }
      });
    },
    Delete(id) {
      this.$swal
        .fire({
          title: "Are you sure ?",
          text: "คุณต้องการลบตำแหน่งนี้หรือไม่ !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        })
        .then(async (result) => {
          if (result.value) {
            axios({
              method: "delete",
              url: `${IP}/qc-applicant/delete-job/${id}`,
              headers: {
                Authorization: AuthKey,
              },
            }).then(async (response) => {
              await this.Clear();
              await this.Start();
              this.$swal.fire("ลบข้อมูลเสร็จสิ้น !", "", "success");
            });
          }
        });
    },
    GetAllQCApplicant() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/qc-applicant/get-all-applicant`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then(async (response) => {
            let result = response.data;
            for (var i = 0; i < result.length; i++) {
              result[i].AnalysisValue = await this.ConvertBase64ToString(
                result[i].AnalysisValue
              );
              result[i].AnalysisValue = JSON.parse(result[i].AnalysisValue);
              result[i].ExtractString = await this.ConvertBase64ToString(
                result[i].ExtractString
              );
            }
            this.applicants = result;
            console.log("applicant: ", this.applicants);
            resolve(true);
          });
      });
    },
    GetAllJob() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/qc-applicant/get-all-job`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then(async (response) => {
            let result = response.data;
            for (var i = 0; i < result.length; i++) {
              result[i].Condition = await this.ConvertBase64ToString(
                result[i].Condition
              );
              result[i].Condition = JSON.parse(result[i].Condition);
            }
            this.listItems = result;
            console.log("job: ", this.listItems);
            resolve(true);
          });
      });
    },
    GetAllPositionFromData() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/qc-applicant/get-all-position-from-data`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then(async (response) => {
            console.log("all position: ", response.data);
            this.all_position = response.data;
            resolve(true);
          });
      });
    },
    Clear() {
      this.job = {
        position: "",
        grade: {
          require: false,
          min: 0,
        },
        toeic: {
          require: false,
          min: 0,
        },
        work_experience: {
          require: false,
          min: 0,
        },
        word: [],
      };
      this.dialog = false;
    },
    async ConvertBase64ToString(str) {
      if (str != null) {
        str = str.replace(/\s/g, "");
        return decodeURIComponent(escape(window.atob(str)));
      }
    },
    async Start() {
      this.loading = true;
      this.message = "Loading...";
      this.user = this.$store.getters.currentUser;
      await this.GetAllQCApplicant();
      await this.GetAllJob();
      await this.GetAllPositionFromData();
      this.loading = false;
      this.message = "No Job yet.";
    },
  },
  created() {
    this.Start();
  },
};
</script>

<style>
.qc-applicant {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>