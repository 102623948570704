<template>
  <div class="voice-recognition">
    <v-icon
      class="custom-btn"
      :style="[
        mic_hover
          ? { background: this.c, color: '#FFF' }
          : { background: '#e4e4e4', color: '#616161' },
      ]"
      :size="s"
      @click="startVoiceRecognition"
      @mouseenter="mic_hover = true"
      @mouseleave="mic_hover = false"
      v-if="!isRecording"
      >mdi-microphone</v-icon
    >
    <div
      v-else
      class="custom-btn"
      :style="[
        voice_hover
          ? {
              width: `${s + 40}px`,
              height: `${s + 40}px`,
              background: '#FFF',
              opacity: 0.6,
            }
          : {
              width: `${s + 40}px`,
              height: `${s + 40}px`,
              background: '#FFF',
            },
      ]"
      @click="stopVoiceRecognition"
      @mouseenter="voice_hover = true"
      @mouseleave="voice_hover = false"
    >
      <VoiceAnimation :scale="sc" :background="c" />
    </div>
  </div>
</template>

<script>
import VoiceAnimation from "./VoiceAnimation.vue";

export default {
  data() {
    return {
      transcript: "",
      isRecording: false,
      Recognition: window.SpeechRecognition || window.webkitSpeechRecognition,
      sr: new (window.SpeechRecognition || window.webkitSpeechRecognition)(),
      s: this.size || 56,
      sc: this.scale || 2,
      l: this.lang || "en",
      c: this.color || "#65a000",
      mic_hover: false,
      voice_hover: false,
      timerId: null,
    };
  },
  props: ["size", "scale", "lang", "color", "record"],
  components: {
    VoiceAnimation,
  },
  watch: {
    transcript(val) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.stopVoiceRecognition();
      }, 3000);
    },
  },
  methods: {
    startVoiceRecognition() {
      this.sr.continuous = true;
      this.sr.interimResults = true;
      this.sr.lang = this.l;

      this.sr.onstart = () => {
        this.isRecording = true;
      };

      this.sr.onend = () => {
        this.isRecording = false;
        this.$emit("update-voice-recognition", "", this.isRecording);
      };

      this.sr.onresult = (evt) => {
        const t = Array.from(evt.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        this.transcript = t;
        this.$emit("update-voice-recognition", this.transcript, true);
      };
      this.sr.start();
    },
    stopVoiceRecognition() {
      this.isRecording = false;
      this.sr.stop();
    },
  },
  created() {
    if (this.record) {
      this.startVoiceRecognition();
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-btn {
  padding: 20px;
  border-radius: 1000px;
  transition: 0.3s all;
  cursor: pointer;
}
</style>