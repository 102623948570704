<template>
  <div class="voice-animation" id="bars" :style="`transform: scale(${s})`">
    <v-container fill-height>
      <v-layout row justify-center align-center>
        <div class="bar" :style="`background: ${b}`"></div>
        <div class="bar" :style="`background: ${b}`"></div>
        <div class="bar" :style="`background: ${b}`"></div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      s: this.scale || 1,
      b: this.background || "#FFF",
    };
  },
  props: ["scale", "background"],
};
</script>

<style scoped>
.voice-animation {
  width: 100%;
  height: 100%;
  transition: 0.2s all;
}

#bars {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  bottom: 1px;
  height: 1px;
  width: 3px;
  margin: 0px 2px;
  border-radius: 5px;
  animation: sound 0ms -600ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 2px;
  }
  100% {
    opacity: 1;
    height: 15px;
  }
}

.bar:nth-child(1) {
  left: 1px;
  animation-duration: 400ms;
}
.bar:nth-child(2) {
  left: 50px;
  animation-duration: 450ms;
}
.bar:nth-child(3) {
  left: 100px;
  animation-duration: 350ms;
}
</style>