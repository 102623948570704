<template>
  <div class="main-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row>
        <v-col cols="auto">
          <v-btn icon color="teal" to="/digital-asset">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col style="font-size: 30px">{{ data_folder.FolderName }}</v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="2">
          <v-btn color="success" block @click="Action('create', null)">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="2">
          <v-btn color="primary" block @click="DialogFolder">
            <v-icon style="margin: 0px 10px 0px -5px">mdi-eye</v-icon>Folder
            Information
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px">
        ยังไม่มีเนื้อหาดิจิทัล
      </p>
    </div>
    <div v-if="listItems.length > 0">
      <br />
      <AdvanceFilter
        :headers="headers"
        :FilterData="FilterItems"
        @update-data="UpdateData"
      />
      <v-card-title v-if="listItems.length > 0">
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listItems"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.Description="{ item }">
          {{ CutAndPoint(item.Description, 60) }}
        </template>
        <template v-slot:item.CreatedDate="{ item }">{{
          Moment(item.CreatedDate)
        }}</template>
        <template v-slot:item.Tag="{ item }">
          <v-chip
            class="ma-2"
            small
            color="indigo"
            text-color="white"
            v-for="t in item.Tag"
            :key="t"
            >{{ t }}</v-chip
          >
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                @click="goToURL(item.Link)"
                style="cursor: pointer; margin-right: 10px"
                >mdi-link-variant</v-icon
              >
            </template>
            <span>Link</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                @click="ReadOnly(item)"
                style="cursor: pointer; margin-right: 10px"
                v-if="item.CreateBy != user.EmployeeEmail"
                >mdi-eye</v-icon
              >
            </template>
            <span>Read</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                @click="Action('update', item)"
                style="cursor: pointer; margin-right: 10px"
                v-if="item.CreateBy == user.EmployeeEmail"
                >edit</v-icon
              >
            </template>
            <span>Update</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                @click="DeleteDigitalAsset(item.ID)"
                style="cursor: pointer"
                :disabled="item.CreateBy != user.EmployeeEmail"
                >delete</v-icon
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <!-- folder dialog -->
    <v-dialog v-model="dialog_folder" width="100%;" max-width="500">
      <v-card>
        <v-card-title>Update Folder</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Folder Name"
                outlined
                v-model="folder.name"
                name="name-folder"
                v-validate="'required'"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('name-folder')"
                  style="margin-top: -20px"
                  >{{ errors.first("name-folder") }}</v-alert
                >
              </transition>
              <v-card-subtitle
                v-if="folder.name != null && folder.name != ''"
                style="margin-top: -40px"
                >{{ CreatePath(folder.name) }}</v-card-subtitle
              >
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <!-- <v-text-field label="Tag" outlined v-model="folder.tag" name="tag" /> -->
              <v-combobox
                v-model="folder.tag"
                label="Tag"
                outlined
                multiple
                chips
                name="tag"
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-textarea
                label="Description"
                outlined
                v-model="folder.description"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              style="margin-top: -30px"
            >
              <v-menu
                v-model="menu_create_date_folder"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="folder.created_date"
                    label="Created Date"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    disabled
                    name="created-date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="folder.created_date"
                  @input="menu_create_date_folder = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="6"
              lg="6"
              xl="6"
              style="margin-top: -30px"
            >
              <v-menu
                v-model="menu_update_date_folder"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="folder.updated_date"
                    label="Latest Update"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    disabled
                    name="updated-date"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="folder.updated_date"
                  @input="menu_update_date_folder = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -20px"
            >
              <transition v-if="error != ''" name="slide-fade">
                <v-alert type="error" style="margin-top: -20px">{{
                  this.error
                }}</v-alert>
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider
          v-if="
            data_folder.CreateBy == user.EmployeeEmail || user.Role == 'Admin'
          "
        ></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            @click="UpdateFolderDigitalAsset"
            style="margin: 10px"
            v-if="
              data_folder.CreateBy == user.EmployeeEmail || user.Role == 'Admin'
            "
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Update
          </v-btn>
          <v-btn
            color="error"
            @click="DeleteFolderDigitalAsset"
            style="margin: 10px"
            v-if="
              data_folder.CreateBy == user.EmployeeEmail || user.Role == 'Admin'
            "
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-delete</v-icon>Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog asset -->
    <v-dialog v-model="dialog_asset" width="100%" max-width="500">
      <v-card>
        <v-card-title v-if="action == 'create'"
          >Create Digital Asset</v-card-title
        >
        <v-card-title v-if="action == 'update'"
          >Update Digital Asset</v-card-title
        >
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Name"
                outlined
                v-model="asset.name"
                name="name-asset"
                v-validate="'required'"
                :readonly="readOnly"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('name-asset')"
                  style="margin-top: -20px"
                  >{{ errors.first("name-asset") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-combobox
                v-model="asset.tag"
                label="Tag"
                outlined
                multiple
                chips
                name="asset-tag"
                :readonly="readOnly"
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-textarea
                label="Description"
                outlined
                v-model="asset.description"
                name="description"
                :readonly="readOnly"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-text-field
                label="URL"
                outlined
                v-model="asset.url"
                name="url"
                v-validate="{ url: { require_protocol: true } }"
                @change="CheckURL"
                :readonly="readOnly"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('url')"
                  style="margin-top: -20px"
                  >{{ errors.first("url") }}</v-alert
                >
              </transition>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-if="error != ''"
                  style="margin-top: -20px"
                  >{{ error }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center" v-if="readOnly == false">
          <v-btn
            color="success"
            v-if="action == 'create'"
            @click="CreateDigitalAsset"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
          <v-btn
            color="warning"
            v-if="action == 'update'"
            @click="UpdateDigitalAsset"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../config";
import moment from "moment";

export default {
  data() {
    return {
      IP: IP,
      user: "",
      data_folder: "",
      folder: {
        name: "",
        tag: [],
        description: "",
        path: "",
      },
      listItems: [],
      FilterItems: [],
      dialog_folder: false,
      dialog_asset: false,
      loading: false,
      menu_create_date_folder: false,
      menu_update_date_folder: false,
      error: "",
      path: "",
      asset: {
        name: "",
        tag: [],
        description: "",
        url: "",
      },
      action: "",
      search: "",
      headers: [
        { text: "Name", value: "Name" },
        { text: "Description", value: "Description" },
        { text: "Create By", value: "EmployeeName" },
        { text: "Date", value: "CreatedDate" },
        { text: "Tag", value: "Tag" },
        { text: "Actions", value: "action", sortable: false },
      ],
      readOnly: false,
    };
  },
  methods: {
    UpdateData(result) {
      if (result.status == true) {
        this.listItems = JSON.parse(result.data);
      } else {
        this.listItems = JSON.parse(this.FilterItems);
      }
    },
    async CreateDigitalAsset() {
      this.error = "";
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (this.asset.url != "" && this.asset.url != null) {
            Swal.fire({
              title: "Are you sure ?",
              text: `คุณต้องการสร้างข้อมูลนี่หรือไม่ !`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            }).then(async (result) => {
              if (result.value) {
                this.CreateAssetProcess().then((response) => {
                  if (response == "success") {
                    this.Clear();
                    this.GetFolderInformationAndDigitalAsset();
                    Swal.fire("สร้างข้อมูลเสร็จสิ้น !", "", "success");
                  }
                });
              }
            });
          } else {
            this.error = "The URL field is required";
          }
        }
      });
    },
    async CreateAssetProcess() {
      return new Promise(async (resolve, reject) => {
        this.asset.folder = this.data_folder.FolderURL;
        this.asset.email = this.user.EmployeeEmail;
        this.asset.tag = JSON.stringify(this.asset.tag);
        axios({
          method: "post",
          url: IP + "/create-digital-asset",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            asset: this.asset,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    async UpdateDigitalAsset() {
      this.error = "";
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (this.asset.url != "" && this.asset.url != null) {
            this.asset.email = this.user.EmployeeEmail;
            this.asset.tag = JSON.stringify(this.asset.tag);
            axios({
              method: "post",
              url: IP + "/update-digital-asset",
              headers: {
                Authorization: AuthKey,
              },
              data: {
                asset: this.asset,
              },
            }).then((response) => {
              if (response.data == "success") {
                this.Clear();
                this.GetFolderInformationAndDigitalAsset();
                Swal.fire("อัพเดตข้อมูลเสร็จสิ้น !", "", "success");
              }
            });
          } else {
            this.error = "The URL field is required";
          }
        }
      });
    },
    async DeleteDigitalAsset(id) {
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบข้อมูลนี่หรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          axios({
            method: "post",
            url: IP + "/delete-digital-asset",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              ID: id,
            },
          }).then((response) => {
            if (response.data == "success") {
              this.Clear();
              this.GetFolderInformationAndDigitalAsset();
              Swal.fire("ลบข้อมูลเสร็จสิ้น !", "", "success");
            }
          });
        }
      });
    },
    async UpdateFolderDigitalAsset() {
      this.error = "";
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          var already = false;
          if (this.data_folder.FolderURL != this.folder.path) {
            await this.CheckFolderAlready(this.folder.path).then((response) => {
              already = response;
            });
          }
          if (!already) {
            this.UpdateFolderProcess().then((response) => {
              if (response == "success") {
                Swal.fire("อัพเดตเสร็จสิ้น !", "", "success");
                this.$router.push("/digital-asset");
              }
            });
          } else {
            this.error = `Folder name "${this.folder.name}" has been already.`;
          }
        }
      });
    },
    async UpdateFolderProcess() {
      return new Promise(async (resolve, reject) => {
        this.folder.email = this.user.EmployeeEmail;
        this.folder.old_path = this.data_folder.FolderURL;
        this.folder.tag = JSON.stringify(this.folder.tag);
        axios({
          method: "post",
          url: IP + "/update-folder-digital-asset",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            folder: this.folder,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    async DeleteFolderDigitalAsset() {
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบโฟลเดอร์และข้อมูลในโฟลเดอร์นี่หรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          axios({
            method: "post",
            url: IP + "/delete-folder-digital-asset",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              folder: this.data_folder.FolderURL,
            },
          }).then((response) => {
            if (response.data == "success") {
              Swal.fire("ลบข้อมูลเสร็จสิ้น !", "", "success");
              this.$router.push("/digital-asset");
            }
          });
        }
      });
    },
    GetFolderInformationAndDigitalAsset() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/get-folder-information-and-digital-asset",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            path: this.path,
          },
        }).then((response) => {
          this.listItems = [];
          const folder = response.data[0];
          this.data_folder = folder[0];
          this.data_folder.Tag = JSON.parse(this.data_folder.Tag);
          const data = response.data[1];
          data.forEach(function (val, i) {
            val.Tag = JSON.parse(val.Tag);
            this.listItems.push(val);
          }, this);
          this.FilterItems = JSON.stringify(this.listItems);
          resolve(true);
        });
      });
    },
    CheckURL() {
      if (this.asset.url == "") {
        this.error = "The URL field is required";
      } else {
        this.error = "";
      }
    },
    CheckFolderAlready(path) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/check-folder-digital-asset",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            path: path,
          },
        }).then((response) => {
          const result = response.data;
          if (result.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    async DialogFolder() {
      this.folder = {
        name: this.data_folder.FolderName,
        description: this.data_folder.FolderDescription,
        tag: this.data_folder.Tag,
        path: this.data_folder.FolderURL,
        created_date: await this.Moment(this.data_folder.CreatedDate),
        updated_date: await this.Moment(this.data_folder.UpdatedDate),
      };
      this.dialog_folder = true;
    },
    CreatePath(name) {
      var replaced = name.split(" ").join("-");
      var lower = replaced.toLowerCase();
      this.folder.path = lower;
      return this.folder.path;
    },
    goToURL(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    Moment(date) {
      if (date != null && date != "") {
        return moment(date).format("YYYY-MM-DD");
      } else {
        return date;
      }
    },
    Action(menu, data) {
      this.readOnly = false;
      this.asset = {};
      this.action = menu;
      if (this.action == "update") {
        this.asset = {
          ID: data.ID,
          name: data.Name,
          tag: data.Tag,
          description: data.Description,
          url: data.Link,
          folder: data.Folder,
        };
      }
      this.dialog_asset = true;
    },
    ReadOnly(data) {
      console.log("read only!");
      this.readOnly = true;
      this.asset = {
        ID: data.ID,
        name: data.Name,
        tag: data.Tag,
        description: data.Description,
        url: data.Link,
        folder: data.Folder,
      };
      this.dialog_asset = true;
    },
    CutAndPoint(data, num) {
      if (data.length > 0) {
        let res = data.slice(0, num);
        if (data.length >= num) {
          res = res + "...";
        }
        return res;
      }
    },
    Clear() {
      this.asset = {
        name: "",
        description: "",
        url: "",
      };
      this.dialog_asset = false;
    },
    start() {
      this.loading = true;
      this.user = this.$store.getters.currentUser;
      this.CheckFolderAlready(this.path).then((response) => {
        if (response) {
          this.GetFolderInformationAndDigitalAsset().then((response) => {
            var that = this;
            setTimeout(function () {
              that.loading = false;
            }, 1000);
          });
        } else {
          this.$router.push("/");
        }
      });
    },
  },
  created() {
    if (this.$route.params.path) {
      this.path = this.$route.params.path;
      this.start();
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style scoped lang="scss">
@import "../css/transition.scss";

.main-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
</style>