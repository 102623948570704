<template>
  <div class="inputText">
    <v-row
      @mouseover="hover = true"
      @mouseleave="hover = false"
      class="flex-row align-center "
      style="width:350px"
    >
      <v-text-field
        :label="label"
        :value="value"
        :rules="rules"
        v-model="localValue"
      ></v-text-field>
      <div v-if="!hover" class="ml-5 mr-5" style="width:24px"></div>
      <v-icon
        v-if="hover"
        @click="$refs.inputUpload.click()"
        class="ml-5 mr-5"
        >{{ fileImage }}</v-icon
      >
      <input
        v-show="false"
        ref="inputUpload"
        type="file"
        @change="handleFile"
      />
    </v-row>
  </div>
</template>

<script>
import { mdiFileImage } from "@mdi/js";

export default {
  name: "inputText",
  props: ["label", "value"],
  created() {
    this.localValue = this.value;
    this.$watch("localValue", (value) => {
      this.$emit("input", value);
    });
  },
  data() {
    return {
      hover: false,
      fileImage: mdiFileImage,
      localValue: "",
      rules: [(value) => !!value || "Required."],
    };
  },
  methods: {
    handleFile() {},
  },
};
</script>

<style></style>
