<template>
  <div class="persongroup">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <div class="persongroup-area">
      <v-card>
        <v-container>
          <div v-if="update == false">
            <v-btn
              icon
              text
              color="gray"
              style="float: right"
              @click="Training"
            >
              <v-icon>mdi-apple-finder</v-icon>
            </v-btn>
            <v-btn
              icon
              text
              color="gray"
              style="float: right"
              @click="DeletePersonGroup"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              icon
              text
              color="gray"
              style="float: right"
              @click="UpdateActive"
            >
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <h2>{{ this.prop_persongroup.name }}</h2>
            <p style="color: gray">
              ID : {{ this.prop_persongroup.personGroupId }}
            </p>
            <p v-if="prop_persongroup.userData != null">
              {{ this.prop_persongroup.userData }}
            </p>
            <p v-if="prop_persongroup.userData == null">None Description.</p>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header v-if="training.status == 'failed'">
                  Training Status :
                  <span style="margin-left: 5px; color: red">{{
                    this.training.status
                  }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-header v-if="training.status != 'failed'">
                  Training Status :
                  <span style="margin-left: 5px; color: green">{{
                    this.training.status
                  }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container grid-list-xl>
                    <v-layout wrap>
                      <v-flex xs12 md6>
                        <span>
                          <b>Create DateTime</b>
                          <p
                            style="
                              margin-top: 5px;
                              margin-left: 15px;
                              padding: 5px;
                              border-bottom: 1px solid #999999;
                            "
                          >
                            {{
                              this.ConvertDate(this.training.createdDateTime)
                            }}
                          </p>
                        </span>
                      </v-flex>
                      <v-flex xs12 md6>
                        <span>
                          <b>Last action DateTime</b>
                          <p
                            style="
                              margin-top: 5px;
                              margin-left: 15px;
                              padding: 5px;
                              border-bottom: 1px solid #999999;
                            "
                          >
                            {{
                              this.ConvertDate(this.training.lastActionDateTime)
                            }}
                          </p>
                        </span>
                      </v-flex>
                      <v-flex xs12 md12 v-if="this.training.message != null">
                        <span>
                          <b>Message</b>
                          <p
                            style="
                              margin-top: 5px;
                              margin-left: 15px;
                              padding: 5px;
                              border-bottom: 1px solid #999999;
                            "
                          >
                            {{ this.training.message }}
                          </p>
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div v-if="update == true">
            <v-btn
              icon
              text
              color="primary"
              style="float: right"
              @click="Training"
              disabled
            >
              <v-icon>mdi-apple-finder</v-icon>
            </v-btn>
            <v-btn
              icon
              text
              color="red"
              style="float: right"
              @click="DeletePersonGroup"
              disabled
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              icon
              text
              color="green"
              style="float: right"
              @click="UpdatePersonGroupDetail"
            >
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-text-field
              label="Name"
              style="width: 80%; font-size: 25px"
              v-model="new_persongroup.name"
              v-validate="'required'"
              name="name"
            />
            <transition name="slide-fade">
              <v-alert type="error" v-show="errors.has('name')">{{
                errors.first("name")
              }}</v-alert>
            </transition>
            <p style="color: gray">
              ID : {{ this.prop_persongroup.personGroupId }}
            </p>
            <v-text-field
              label="Description"
              style="width: 80%; margin-top: -10px"
              v-model="new_persongroup.userData"
              v-validate="'required'"
              name="userData"
            />
            <transition name="slide-fade">
              <v-alert type="error" v-show="errors.has('userData')">{{
                errors.first("userData")
              }}</v-alert>
            </transition>
          </div>
        </v-container>
      </v-card>
      <br />
      <v-row no-gutters>
        <v-col>
          <h2 style="padding-left: 10px">Person</h2>
        </v-col>
        <v-col>
          <v-btn text style="float: right" @click="create_person_dialog = true">
            <v-icon color="green" style="margin-right: 5px"
              >mdi-account-plus</v-icon
            >Add Person
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="data.length == 0" style="text-align: center">
        <p style="margin: 50px; font-size: 20px">Don't have Person.</p>
      </div>
      <div v-if="data.length != 0">
        <br />
        <v-expansion-panels accordion focusable>
          <v-expansion-panel v-for="n in data" :key="n.personId">
            <v-expansion-panel-header>{{ n.name }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container grid-list-xl>
                <v-layout wrap>
                  <v-flex xs12 md6>
                    <span>
                      <b>Person ID</b>
                      <p
                        style="
                          margin-top: 5px;
                          margin-left: 15px;
                          padding: 5px;
                          border-bottom: 1px solid #999999;
                        "
                      >
                        {{ n.personId }}
                      </p>
                    </span>
                  </v-flex>
                  <v-flex xs12 md6>
                    <span>
                      <b>Name</b>
                      <p
                        style="
                          margin-top: 5px;
                          margin-left: 15px;
                          padding: 5px;
                          border-bottom: 1px solid #999999;
                        "
                      >
                        {{ n.name }}
                      </p>
                    </span>
                  </v-flex>
                  <v-flex xs12 md12 style="margin-top: -20px">
                    <span>
                      <b>Description</b>
                      <p
                        style="
                          margin-top: 5px;
                          margin-left: 15px;
                          padding: 5px;
                          border-bottom: 1px solid #999999;
                        "
                      >
                        {{ n.userData }}
                      </p>
                    </span>
                  </v-flex>
                  <v-flex xs12 md12>
                    <v-btn
                      text
                      color="primary"
                      style="float: right"
                      @click="GoToDetail(n)"
                    >
                      <v-icon style="margin-right: 5px">mdi-account</v-icon
                      >Detail
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <!-- Dialog -->
    <v-dialog v-model="create_person_dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Create Person</v-card-title>
        <div style="width: 80%; margin: 0 auto">
          <v-text-field
            label="Name"
            v-model="create.name"
            v-validate="'required'"
            name="name"
          />
          <transition name="slide-fade">
            <v-alert type="error" v-show="errors.has('name')">{{
              errors.first("name")
            }}</v-alert>
          </transition>
          <v-text-field
            label="Description"
            v-model="create.userData"
            v-validate="'required'"
            name="user-data"
            style="margin-top: -10px"
          />
          <transition name="slide-fade">
            <v-alert type="error" v-show="errors.has('user-data')">{{
              errors.first("user-data")
            }}</v-alert>
          </transition>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="Cancel">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="CreatePerson"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <br />
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  data() {
    return {
      IP: IP,
      personGroupId: "",
      prop_persongroup: "",
      update: false,
      new_persongroup: {
        name: "",
        userData: "",
      },
      loading: false,
      data: "",
      create_person_dialog: false,
      create: {
        name: "",
        userData: "",
      },
      training: "",
    };
  },
  methods: {
    UpdateActive() {
      this.new_persongroup.name = this.prop_persongroup.name;
      if (this.prop_persongroup.userData != null) {
        this.new_persongroup.userData = this.prop_persongroup.userData;
      } else {
        this.new_persongroup.userData = "";
      }
      this.update = true;
    },
    UpdatePersonGroupDetail() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          axios({
            method: "patch",
            url: this.IP + "/person-group-update",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              PersonGroupId: this.prop_persongroup.personGroupId,
              name: this.new_persongroup.name,
              userData: this.new_persongroup.userData,
            },
          }).then(async (response) => {
            const data = await this.GetPersonGroup();
            this.prop_persongroup = data;
            this.loading = false;
            this.update = false;
          });
        }
      });
    },
    DeletePersonGroup() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          axios({
            method: "delete",
            url: this.IP + "/person-group-delete",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              PersonGroupId: this.prop_persongroup.personGroupId,
            },
          }).then((response) => {
            this.loading = false;
            Swal.fire("Deleted!", "Person Group has been deleted.", "success");
            this.$router.push("/admin");
          });
        }
      });
    },
    GetPersonGroup() {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: this.IP + "/person-group-get",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            PersonGroupId: this.personGroupId,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    GetPersonAll() {
      axios({
        method: "post",
        url: this.IP + "/person-group-person-get-all",
        headers: {
          Authorization: AuthKey,
        },
        data: {
          PersonGroupId: this.prop_persongroup.personGroupId,
        },
      }).then((response) => {
        this.data = response.data;
      });
    },
    GoToDetail(n) {
      const detail = {
        personGroupId: this.prop_persongroup.personGroupId,
        personId: n.personId,
        name: n.name,
        userData: n.userData,
        persistedFaceIds: n.persistedFaceIds,
      };

      this.$router.push({
        name: "admin-person",
        params: { person: detail },
      });
    },
    Cancel() {
      this.create = {
        name: "",
        userData: "",
      };
      this.errors.clear();
      this.create_person_dialog = false;
    },
    CreatePerson() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          axios({
            method: "post",
            url: this.IP + "/person-group-person-create",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              PersonGroupId: this.personGroupId,
              name: this.create.name,
              userData: this.create.userData,
            },
          }).then(async (response) => {
            await this.GetPersonAll();
            await this.Cancel();
            this.loading = false;
          });
        }
      });
    },
    Training() {
      this.loading = true;
      axios({
        method: "post",
        url: this.IP + "/person-group-train",
        headers: {
          Authorization: AuthKey,
        },
        data: {
          PersonGroupId: this.personGroupId,
        },
      }).then((response) => {
        this.loading = false;
        Swal.fire("Training!", "PersonGroup Training!", "success");
        console.log(response.data);
      });
    },
    GetTrainingStatus() {
      axios({
        method: "post",
        url: this.IP + "/person-group-get-training-status",
        headers: {
          Authorization: AuthKey,
        },
        data: {
          PersonGroupId: this.personGroupId,
        },
      }).then((response) => {
        this.training = response.data;
      });
    },
    ConvertDate(date) {
      return moment(date).calendar();
    },
  },
  props: ["persongroup"],
  async created() {
    this.personGroupId = this.$route.params.persongroup;
    if (this.personGroupId == null) {
      this.$router.push("/admin");
    } else {
      this.prop_persongroup = await this.GetPersonGroup();
      await this.GetPersonAll();
      await this.GetTrainingStatus();
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";
.persongroup-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}
</style>