<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-container>
      <div class="content-area">
        <v-row style="border-bottom: 1px solid rgb(216, 216, 216)">
          <v-col cols="auto" style="font-size: 30px" v-if="!crud">Exams</v-col>
          <v-col
            cols="auto"
            style="font-size: 30px"
            v-if="crud && action == 'create'"
            >Create New Exams</v-col
          >
          <v-col
            cols="auto"
            style="font-size: 30px"
            v-if="crud && action == 'update'"
            >Update Exams</v-col
          >
          <v-col style="width: 100%; padding: 0px 20px 0px 20px">
            <v-text-field
              v-model="search"
              label="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
              v-if="!crud"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
            <v-btn
              color="success"
              block
              style="float: right"
              v-if="!crud"
              @click="Actions('create')"
            >
              <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
            </v-btn>
            <v-btn
              color="primary"
              block
              style="float: right"
              v-if="crud"
              @click="Back"
            >
              <v-icon style="margin: 0px 5px 0px -5px">mdi-arrow-left</v-icon
              >Back
            </v-btn>
          </v-col>
        </v-row>
        <div v-if="!crud">
          <!-- data table -->
          <div v-if="listItems.length == 0">
            <p style="font-size: 20px; text-align: center; margin: 40px">
              {{ message }}
            </p>
          </div>
          <v-data-table
            :headers="headers"
            :items="listItems"
            :search="search"
            class="elevation-1"
            style="margin-top: 30px"
            v-if="listItems.length > 0"
          >
            <template v-slot:item.TimingType="{ item }">
              <div v-if="item.TimingType == 'unlimited'">Unlimited</div>
              <div v-if="item.TimingType == 'limited'">
                {{ item.TimingInterval }} Hr.
              </div>
            </template>
            <!-- <template v-slot:item.ExamPart="{ item }">
              <v-btn color="primary" @click="GoToExamPart(item.ID)"
                >exam part</v-btn
              >
            </template> -->
            <template v-slot:item.action="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon>mdi-cogs</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="Actions('update', item)">
                    <v-list-item-title>Update</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="Delete(item.ID)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
        <div v-if="crud">
          <Exam :ExamData="updateItem" @update-data="Back" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../../config";
import Exam from "./CRUDComponent/Exam";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Exam Name", value: "ExamTitle" },
        { text: "Duration", value: "TimingType" },
        // { text: "Exam Part", value: "ExamPart" },
        { text: "Actions", value: "action", sortable: false },
      ],
      listItems: [],
      message: "No exam yet.",
      loading: false,
      crud: false,
      action: "",
      updateItem: null,
    };
  },
  components: {
    Exam,
  },
  methods: {
    Back() {
      this.crud = false;
      this.Start();
    },
    Actions(action, item) {
      if (action == "create") {
        this.updateItem = null;
        this.action = action;
        this.crud = true;
      } else if (action == "update") {
        this.updateItem = item;
        this.action = action;
        this.crud = true;
      }
    },
    Delete(id) {
      return new Promise(async (resolve, reject) => {
        this.$swal
          .fire({
            title: "Are you sure ?",
            text: `Do you want to delete this exam ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          })
          .then(async (result) => {
            if (result.value) {
              this.loading = true;
              console.log("delete: ", id);
              axios({
                method: "DELETE",
                url: `${IP}/exam/delete/${id}`,
                headers: {
                  Authorization: AuthKey,
                },
              }).then((response) => {
                this.Start();
                this.loading = false;
                this.$swal.fire("Delete exam success !", "", "success");
              });
            }
          });
      });
    },
    GoToExamPart(id) {
      console.log("id: ", id);
    },
    GetAllExam() {
      return new Promise(async (resolve, reject) => {
        axios
          .get(`${IP}/exam/get-all`, {
            headers: {
              Authorization: AuthKey,
            },
          })
          .then((response) => {
            console.log("listItems: ", response.data);
            this.listItems = response.data;
            resolve(true);
          });
      });
    },
    async Start() {
      this.user = this.$store.getters.currentUser;
      this.message = "Loading...";
      await this.GetAllExam().then((response) => {
        this.message = "No exam yet.";
      });
    },
  },
  created() {
    this.Start();
  },
};
</script>

<style lang="scss" scoped>
.content-area {
  width: 95%;
  margin: 0 auto;
}
</style>