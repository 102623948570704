import Home from "./views/Home.vue";
import Admin from "./views/Admin.vue";
import AdminPersonGroup from "./views/Admin/Persongroup.vue";
import AdminPerson from "./views/Admin/Person.vue";
import SaleOrders from "./views/SaleOrders.vue";
import SaleOrdersNew from "./views/SaleOrdersNew.vue";
import Leave from "./views/Leave.vue";
import FaceManagement from "./views/Admin/FaceManagement.vue";
import EmployeeNew from "./views/Admin/EmployeeNew.vue";
import Holiday from "./views/Holiday/Holiday.vue";
import SlideNotification from "./components/Home/SlideNotification.vue";
import Blog from "./views/Blog.vue";
import CreateBlog from "./views/Blog/CreateBlog.vue";
import UpdateBlog from "./views/Blog/UpdateBlog.vue";
import ReadBlog from "./views/Blog/ReadBlog.vue";
import ManageSlides from "./views/Admin/ManageSlides.vue";
import Profile from "./views/Profile.vue";
import ProfileMe from "./views/ProfileMe.vue";
import UserManagement from "./views/UserManagement.vue";
import FolderDigitalAsset from "./views/FolderDigitalAsset.vue";
import DigitalAsset from "./views/DigitalAsset.vue";
import Certificates from "./views/Admin/Certificates.vue";
import Task from "./views/Task.vue";
import Project from "./views/Project.vue";
import Test from "./views/Test.vue";
import TrainingList from "./views/TrainingList/TrainingList.vue";
import Tableau from "./views/Tableau/TableauLogin.vue";
import QuizListPage from "@/views/POC/QuizListPage";
import QuizList from "./views/POC/QuizList.vue";
import QuizPage from "@/views/POC/QuizPage";
import Quiz from "@/views/POC/Quiz";
import FormPage from "@/views/POC/Form";
import RegisterJob from "./views/RegisJob/RegisterJob.vue";
import ListApplicant from "./views/RegisJob/ListApplicant.vue";
import AccessControl from "./views/AccessControl/AccessControlNew.vue";
import TeamManagement from "./views/Admin/TeamManagement.vue";
import ChooseRoomReservation from "./views/RoomReservation/ChooseRoomReservation.vue";
import RoomReservation from "./views/RoomReservation/RoomReservation.vue";
import Notification from "./views/Notification.vue";
import Job from "./views/RegisJob/Job.vue";
import ExamManagement from "./views/Admin/ExamManagement.vue";
import MainExam from "./views/MainExam.vue";
import TakeExam from "./views/TakeExam.vue";
import QCApplicant from "./views/Admin/QCApplicant.vue";
import QCApplicantJob from "./views/Admin/QCApplicant/QCApplicantJob.vue";
import HR_Performance_Menu from "./views/Human_Resource_and_Performance_System/HR_Performance_Menu.vue";
import Sale_Opportunity_Predictive from "./views/PocSalesOpportunityPredictive/Sale_Opportunity_Predictive.vue";
import PDF from "./components/Predictive Form/Predictive_Form.vue";
import Support from "./views/Support.vue";
import InternalSupport from "./components/Internal_Support.vue";
import Maintenance from "./views/Maintenance.vue";
import SaleOrderRead from "./views/SaleOrders/Read.vue";
import SaleOrderEdit from "./views/SaleOrders/Edit.vue";
import ChatGPT from "./views/ChatGPT.vue";
import CreateCompletion from "./views/ChatGPT/CreateCompletion.vue";
import CVJobValidation from "./views/ChatGPT/CVJobValidation.vue";
import CreateImage from "./views/ChatGPT/CreateImage.vue";
import ForceLogout from "./views/ForceLogout.vue";
import Chat from "./views/Chat.vue";
import VoiceAssistant from "./views/VoiceAssistant.vue";
import AdminManually from "./views/Admin/Manually.vue";

export const routes = [
  {
    path: "/",
    component: Home,
    meta: {
      title: "STelligence - Home",
      metaTags: [
        {
          name: "description",
          content: "Internal website",
        },
      ],
    },
    name: "home",
  },
  {
    path: "/old/job",
    component: RegisterJob,
    name: "old-job",
  },
  {
    path: "/job",
    component: Job,
    name: "job",
  },
  {
    path: "/admin/applicant",
    component: ListApplicant,
    name: "listApplicant",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/qc-applicant",
    component: QCApplicant,
    name: "QCApplicant",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/qc-applicant/job/:id",
    component: QCApplicantJob,
    name: "qc-applicant-job",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/admin/access-control",
    component: AccessControl,
    name: "accesscontrol",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/team-management",
    component: TeamManagement,
    name: "TeamManagement",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/holiday",
    component: Holiday,
    name: "holiday",
    meta: { requiresAuth: true, Permission: "LeaveRequest" },
  },
  // {
  //     path: '/exam',
  //     component: MainExam,
  //     name: 'main-exam',
  // },
  // {
  //     path: '/exam/take',
  //     component: TakeExam,
  //     name: 'exam-take',
  // },
  {
    path: "/notification",
    component: Notification,
    name: "notification",
  },
  // {
  //     path: '/sale-orders',
  //     component: SaleOrders,
  //     name: 'sale-orders',
  //     meta: { requiresAuth: true, Permission: 'SaleOrders' }
  // },
  // {
  //     path: '/sale-orders-new',
  //     component: SaleOrdersNew,
  //     name: 'sale-orders-new',
  //     meta: { requiresAuth: true, Permission: 'SaleOrders' }
  // },
  // {
  //     path: '/leave',
  //     component: Leave,
  //     name: 'leave',
  //     meta: {requiresAuth: true, Permission: 'LeaveRequest'}
  // },
  {
    path: "/blog",
    component: Blog,
    name: "blog",
    meta: { requiresAuth: true, Permission: "Blog" },
  },
  {
    path: "/blog/create",
    component: CreateBlog,
    name: "create-blog",
    meta: { requiresAuth: true, Permission: "Blog" },
  },
  {
    path: "/blog/update/:id",
    component: UpdateBlog,
    name: "update-blog",
    meta: { requiresAuth: true, Permission: "Blog" },
  },
  {
    path: "/blog/read/:id",
    component: ReadBlog,
    name: "read-blog",
  },
  {
    path: "/profile/:email",
    component: Profile,
    name: "profile",
    meta: { requiresAuth: true, Permission: "Profile" },
  },
  {
    path: "/my-profile",
    component: ProfileMe,
    name: "profile-me",
    meta: { requiresAuth: true, Permission: "Profile" },
  },
  {
    path: "/user-management/:email",
    component: UserManagement,
    name: "user-management",
    meta: { requiresAuth: true, Permission: "UserManagement" },
  },
  // {
  //     path: '/choose-room-reservation',
  //     component: ChooseRoomReservation,
  //     name: 'choose-room-reservation',
  //     meta: {requiresAuth: true, Permission: 'DigitalAsset'}
  // },
  // {
  //     path: '/room-reservation/:roomID',
  //     component: RoomReservation,
  //     name: 'room-reservation',
  //     meta: {requiresAuth: true, Permission: 'DigitalAsset'}
  // },
  // {
  //     path: '/digital-asset',
  //     component: FolderDigitalAsset,
  //     name: 'folder-digital-asset',
  //     meta: {requiresAuth: true, Permission: 'DigitalAsset'}
  // },
  // {
  //     path: '/digital-asset/:path',
  //     component: DigitalAsset,
  //     name: 'digital-asset',
  //     meta: {requiresAuth: true, Permission: 'DigitalAsset'}
  // },
  {
    path: "/project",
    component: Project,
    name: "project",
    meta: { requiresAuth: true, Permission: "Task" },
  },
  {
    path: "/project/:path",
    component: Task,
    name: "task",
    meta: { requiresAuth: true, Permission: "Task" },
  },
  // admin
  {
    path: "/admin",
    component: Admin,
    name: "admin",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/admin/exam",
    component: ExamManagement,
    name: "admin-exam",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/admin/face-management",
    component: FaceManagement,
    name: "admin-face-management",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/admin/persongroup",
    component: AdminPersonGroup,
    name: "admin-persongroup",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/admin/person",
    component: AdminPerson,
    name: "admin-person",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/admin/employee",
    component: EmployeeNew,
    name: "admin-employee",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/admin/slides",
    component: ManageSlides,
    name: "admin-slides",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/certificates",
    component: Certificates,
    name: "certificates",
    meta: { requiresAuth: true, AdminAuth: true || "SaleOrders" },
  },
  {
    path: "/admin/manually",
    component: AdminManually,
    name: "admin-manually",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  //route quiz
  {
    path: "/form-page",
    component: FormPage,
    name: "form-page",
  },
  {
    path: "/quiz-list",
    component: QuizList,
    name: "quiz-list",
  },
  {
    path: "/quiz-list-page",
    component: QuizListPage,
    name: "quiz-list-page",
  },
  {
    path: "/quiz-page",
    component: QuizPage,
    name: "quiz-page",
  },
  {
    path: "/quiz",
    component: Quiz,
    name: "quiz",
  },

  //route training list
  // {
  //     path: '/training-list',
  //     component: TrainingList,
  //     name: 'training-list',
  //     meta: {requiresAuth: true, Permission: 'TrainRequest'}
  // },
  // route support
  {
    path: "/support",
    component: Support,
    name: "support",
  },
  {
    path: "/internalsupport",
    component: InternalSupport,
    name: "internalsupport",
  },
  // route test
  {
    path: "/test",
    component: Test,
    name: "test",
    meta: { requiresAuth: true, AdminAuth: true },
  },
  {
    path: "/test/slide-notification",
    component: SlideNotification,
    name: "test-slide-notification",
  },
  // {
  //     path: '/training/statistics',
  //     component: Tableau,
  //     name: 'TrainingStatistics',
  // },
  // {
  //     path: '/hr-performance-menu',
  //     component: HR_Performance_Menu,
  //     name: 'hr-performance-menu'
  // },
  {
    path: "/sale-opportunity-predictive",
    component: Sale_Opportunity_Predictive,
    name: "sale-opportunity-predictive",
  },
  {
    path: "/PDF",
    component: PDF,
    name: "PDF",
  },
  // chat-gpt
  {
    path: "/chat-gpt",
    component: ChatGPT,
    name: "chat-gpt",
    meta: { requiresAuth: true },
  },
  {
    path: "/chat-gpt/create-completion",
    component: CreateCompletion,
    name: "chat-gpt-create-completion",
    meta: { requiresAuth: true },
  },
  {
    path: "/chat-gpt/cv-job-validation",
    component: CVJobValidation,
    name: "chat-gpt-cv-job-validation",
    meta: { requiresAuth: true },
  },
  {
    path: "/chat-gpt/create-image",
    component: CreateImage,
    name: "chat-gpt-create-image",
    meta: { requiresAuth: true },
  },
  // chat
  // {
  //   path: "/chat",
  //   component: Chat,
  //   name: "chat",
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/chat/:id",
  //   component: Chat,
  //   name: "chat",
  //   meta: { requiresAuth: true },
  // },
  // voice assistant
  {
    path: "/voice-assistant",
    component: VoiceAssistant,
    name: "voice-assistant",
    meta: { requiresAuth: true },
  },
  // maintenance
  {
    path: "/maintenance",
    component: Maintenance,
    name: "maintenance",
  },
  {
    path: "/sale-orders",
    component: SaleOrders,
    name: "sale-orders",
    meta: { requiresAuth: true, Permission: "SaleOrders" },
  },
  {
    path: "/sale-orders/:so_number/read",
    component: SaleOrderRead,
    name: "sale-orders-read",
    meta: { requiresAuth: true, Permission: "SaleOrders" },
  },
  {
    path: "/sale-orders/:so_number/edit",
    component: SaleOrderEdit,
    name: "sale-orders-edit",
    meta: { requiresAuth: true, Permission: "SaleOrders" },
  },
  {
    path: "/logout",
    component: ForceLogout,
    name: "logout",
  },
];
