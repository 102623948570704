<template>
  <div class="question">
    <v-row>{{ id }}. {{ subject }}</v-row>

    <v-row v-if="sectionType === 'Logical'">
      <img :src="src" max-height="125" contain />
    </v-row>

    <v-row>
      <v-radio-group v-model="radioGroup">
        <v-radio
          v-for="choice in choices"
          :key="choice.ChoiceId"
          :label="choice.ChoiceContent"
          :value="choice.ChoiceId"
        ></v-radio>
      </v-radio-group>
    </v-row>
  </div>
</template>

<script>
export default {
  updated() {
    this.$emit("check", this.radioGroup, this.id);
  },

  name: "question",
  props: ["id", "subject", "choices", "src", "sectionType"],
  data() {
    return {
      radioGroup: "",
    };
  },
};
</script>

<style></style>
