<template>
  <div>
    <!-- nav drawer -->
    <div class="dashboard-nav-drawer">
      <!-- desktop -->
      <v-navigation-drawer
        :style="[
          width >= 920
            ? { marginTop: '64px' }
            : { marginTop: '55px', zIndex: 900, height: 'calc(100vh - 55px)' },
        ]"
        :width="width >= 920 ? 260 : '100%'"
        v-model="drawer"
        :mini-variant.sync="$store.state.chatDrawerMini"
        :app="width >= 920"
        :permanent="width >= 920"
        :temporary="width < 920"
        :absolute="width < 920"
        class="navigation"
        color="#252525"
      >
        <v-list-item>
          <v-list-item-icon style="margin-left: -7px">
            <v-btn
              outlined
              x-small
              style="width: 30px; height: 36px"
              color="#FFF"
              @click.stop="
                $store.state.chatDrawerMini = !$store.state.chatDrawerMini
              "
              v-if="width >= 920"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
            <v-btn
              outlined
              x-small
              style="width: 30px; height: 36px"
              color="#FFF"
              @click.stop="Drawer"
              v-if="width < 920"
            >
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </v-list-item-icon>

          <v-list-item-title style="margin-left: -10px">
            <v-btn outlined block dense color="#FFF" @click="GoTo('/chat')"
              >New Chat</v-btn
            >
          </v-list-item-title>
        </v-list-item>

        <v-divider dark></v-divider>
        <ContentLoading
          v-if="loading"
          :width="36"
          :height="45"
          style="margin-top: 50px"
        />
        <div
          class="no-items"
          v-if="items.length == 0 && !$store.state.chatDrawerMini && !loading"
        >
          No Chat Available
        </div>
        <v-list v-if="items.length > 0 && !loading">
          <div v-for="(item, i) in items" :key="i">
            <v-list-item
              v-if="!item.sub_links"
              :disabled="item.disabled"
              link
              :style="[
                item.disabled == false
                  ? $route.path == item.to
                    ? {
                        color: '#FFF',
                        'border-right': '5px solid #71A714',
                      }
                    : { color: '#FFF' }
                  : { color: '#CFCFCF' },
              ]"
            >
              <v-tooltip bottom style="z-index: 9">
                <template v-slot:activator="{ on }">
                  <v-list-item-icon v-on="on" style="margin-left: -3px">
                    <v-icon dark v-if="$route.path != item.to">mdi-chat</v-icon>
                    <v-icon dark
                      v-if="$route.path == item.to"
                      @click="OpenDelete(item)"
                      class="delete-icon"
                      >mdi-delete</v-icon
                    >
                  </v-list-item-icon>

                  <v-list-item-content
                    @click="GoTo(item.to)"
                    v-on="on"
                    style="margin-left: -20px"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>
            </v-list-item>
            <v-list-group
              v-else
              :key="item.title"
              no-action
              :disabled="item.disabled"
            >
              <template v-slot:activator>
                <v-tooltip bottom style="z-index: 9">
                  <template v-slot:activator="{ on }">
                    <v-list-item-icon v-on="on">
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content v-on="on">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{ item.title }}</span>
                </v-tooltip>
              </template>
              <v-divider></v-divider>
              <v-list-item
                v-for="sublink in item.sub_links"
                link
                color="#B5B5B5"
                @click="GoToHash(item.to, sublink.to)"
                :key="sublink.title"
                :style="[
                  $route.hash == sublink.to
                    ? {
                        color: '#65a000',
                        'border-right': '5px solid #65a000',
                      }
                    : { color: '#65a000' },
                ]"
              >
                <v-tooltip bottom style="z-index: 9">
                  <template v-slot:activator="{ on }">
                    <v-list-item-content v-on="on">
                      <v-list-item-title>{{ sublink.title }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{ sublink.title }}</span>
                </v-tooltip>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </v-navigation-drawer>
      <!-- mobile -->
      <div class="mb-dv-btn" v-if="width < 920">
        <v-btn
          dark
          x-small
          @click="Drawer"
          style="width: 45px; height: 36px; border-radius: 0px 5px 5px 0px"
          color="#616161"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>

      <!-- dialog delete -->
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5"> Delete chat? </v-card-title>
          <v-card-text
            >This will delete <b>{{ row.title }}</b></v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#616161" text @click="CloseDelete"> Cancel </v-btn>
            <v-btn color="error" text @click="Delete"> Delete </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { WindowResize } from "../../function/WindowResize.js";
import ContentLoading from "../ContentLoading.vue";

export default {
  data() {
    return {
      drawer: this.width < 920 ? true : false,
      list_items: this.items,
      width: 0,
      height: 0,
      row: {},
      dialog: false,
      //   $store.state.chatDrawerMini: this.mini,
    };
  },
  props: ["items", "loading"],
  components: {
    ContentLoading,
  },
  methods: {
    OpenDelete(row) {
      this.row = row;
      this.dialog = true;
      if (this.width < 920) {
        this.Drawer();
      }
      // this.$emit("delete", id);
    },
    CloseDelete() {
      this.row = {};
      this.dialog = false;
    },
    Delete() {
      this.$emit("delete", this.row.id);
      this.dialog = false;
      this.row = {};
    },
    Drawer() {
      this.drawer = !this.drawer;
      this.$store.state.chatDrawerMini = false;
    },
    GoTo(link) {
      this.$router.push({ path: link }).catch((err) => {});
      this.$emit("update-link", true);
    },
    GoToHash(link, hash) {
      if (hash.includes("#")) {
        this.$router.push({ path: link, hash: hash });
        this.$emit("update-link", true);
      } else if (hash.includes("/")) {
        let path = link + hash;
        this.$router.push(path);
        this.$emit("update-link", true);
      }
    },
    autoMini() {
      if (this.width < 1100) {
        this.$store.state.chatDrawerMini = true;
      }
    },
    resize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      // this.autoMini();
      window.addEventListener(
        "resize",
        () => {
          this.width = WindowResize.windowWidth();
          this.height = WindowResize.windowHeight();
          // this.autoMini();
        },
        this
      );
    },
  },
  created() {
    // console.log("loading: ", this.loading);
    this.resize();
  },
};
</script>

<style scoped lang="scss">
.nav-hover {
  color: #707070;
  transition: 0.1s all;
}
.nav-hover:hover {
  color: #65a000;
}

.navigation {
  // margin-top: 65px;
}

.no-items {
  text-align: center;
  color: #FFF;
  margin: 20px;
  opacity: 0.6;
  transition: 0.2s all;
  overflow: hidden;
  white-space: nowrap;
}

.mb-dv-btn {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 70px;
  margin-left: -20px;
  transition: 0.2s all;
}

.mb-dv-btn:hover {
  margin-left: 0px;
}

.delete-icon {
  transition: 0.2s all;
}

.delete-icon:hover {
  color: red;
}
</style>
