<template>
  <div class="quizPage">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <div class="d-flex flex-column mb-10 align-center">
      <p class="text-center">{{ items[0]["QuizName"] }}</p>
      <div v-for="item in items" :key="item.sectionId">
        <v-card class="mb-3 pa-5" width="400" outlined>
          <div>
            <p class="headline mb-1">{{ item.QuizTypeName }}</p>
            <div class="text--primary">{{ item.QuizSectionDescription }}</div>

            <v-card-actions>
              <v-btn
                color="primary"
                block
                :disabled="item.status == true"
                @click="goTo(item)"
                >Test</v-btn
              >
            </v-card-actions>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
export default {
  name: "quiz-page",
  data() {
    return {
      loading: false,
      items: [{}],
    };
  },
  methods: {
    CheckDisable(item) {
      // console.log("item", item);
      // if (this.$store.state.readingEngFull == true) {
      //   // for (let i in this.items) {
      //   //   console.log("QuizSectionId3", this.items[i].QuizSectionId);
      //   //   if (this.items[i].QuizSectionId == item.QuizSectionId) {
      //   //     return (this.items[i].status = true);
      //   //   }
      //   // }
      //   return item.status = true
      // }
      // if (this.$store.state.writingEngFull == true) {
      //   // for (let i in this.items) {
      //   //   console.log("QuizSectionId2", this.items[i].QuizSectionId);
      //   //   if (this.items[i].QuizSectionId == item.QuizSectionId) {
      //   //     return (this.items[i].status = true);
      //   //   }
      //   // }
      //   return item.status = true
      // }
      // //  else if(this.$store.state.readingEngFull == true &&  this.$store.state.writingEngFull == true){
      // //    return this.$router.push({ name: "quiz-list"});
      // //  }
    },
    GetSection(e) {
      //console.log('this.$route.params.url:', this.$route.params.url)
      if (this.$route.params.url != undefined) {
        return new Promise(async (resolve, reject) => {
          axios({
            method: "get",
            url: IP + `/api/v1/quiz-section/${this.$route.params.url}`,
            headers: {
              Authorization: AuthKey,
            },
          }).then((response) => {
            this.items = response.data;
            // console.log(this.items);

            this.items.forEach(function (val, i) {
              val.status = false;
            });
            for (let i in this.items) {
              if (e.readingEngFull == true && this.$route.params.url == 1) {
                this.items[0].status = true;
              }
              if (e.writingEngFull == true && this.$route.params.url == 1) {
                this.items[1].status = true;
              }
              if (e.readingEngShort == true && this.$route.params.url == 3) {
                this.items[1].status = true;
              }
              if (e.writingEngShort == true && this.$route.params.url == 3) {
                this.items[0].status = true;
              }
              if (e.logical == true && this.$route.params.url == 2) {
                this.items[0].status = true;
              }
            }

            console.log(this.items);
          });
        });
      }
      this.$router.push("/quiz-list");
    },
    goTo(item) {
      const { Time, QuizName } = item;
      localStorage.setItem("time", Time);
      localStorage.setItem("QuizName", QuizName);
      let time = localStorage.getItem("time");
      this.$router.push({
        name: "quiz",
        params: { time: time, url: item.QuizSectionId },
      });
    },
  },
  created() {
    // if(this.readingEngFull == true && this.writingEngFull == true && this.readingEngShort == false && this.writingEngShort == false){
    //   this.$router.push({ name: "quiz-list"});
    // }
    // else{

    // }
    let dataStatus = {
      readingEngFull: this.$store.state.readingEngFull,
      writingEngFull: this.$store.state.writingEngFull,
      readingEngShort: this.$store.state.readingEngShort,
      writingEngShort: this.$store.state.writingEngShort,
      logical: this.$store.state.logical,
    };
    // if(dataStatus.readingEngFull == true && dataStatus.writingEngFull == true)
    this.GetSection(dataStatus);
  },
};
</script>

<style>
.quizPage {
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
}
</style>
