<template>
  <div class="chat-gpt">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <v-container>
      <div class="text-header-area">
        <div id="text-header">
          <!-- <v-chip
            class="ma-2"
            color="#11A281"
            dark
            style="position: absolute; transform: translate(-100px, -20px)"
          >
            <a
              href="https://openai.com/blog/chatgpt/"
              style="color: white; text-decoration: none"
              >Chat GPT</a
            >
          </v-chip> -->
          Create Completion
        </div>
        <div id="text-subheader">ระบบถามคำถาม Chat GPT.</div>
      </div>
      <div class="content-area">
        <v-row>
          <v-col cols="12" xs="8" sm="10" md="11">
            <v-text-field
              :label="question_label"
              outlined
              v-model="question"
              v-validate="'required'"
              dense
              hide-details
              @keyup.enter="CreateCompletion"
            />
          </v-col>
          <v-col cols="12" xs="4" sm="2" md="1">
            <v-btn block color="success" @click="CreateCompletion">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <hr />
          </v-col>
          <v-col cols="12">
            <div class="output ql-snow" v-if="answer != '' && ready">
              <div class="ql-editor" v-html="answer"></div>
            </div>
            <div v-if="answer == '' && ready" style="text-align: center">
              มีอะไรอยากถาม Chat GPT ลองพิมพ์ในช่อง
              <b>"{{ question_label }}"</b> และกด <b>ปุ่มเขียว (ส่ง)</b> ดูนะ
              <br><br>
              <b>ปล.</b> ถามเป็นภาษาอังกฤษจะเร็วกว่านะ
            </div>
            <div v-if="answer == '' && !ready" style="text-align: center">
              คิดก่อนนะ ...
            </div>
            <div v-if="error_message != ''">
                {{ error_message }}
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <vue-editor
      id="editor"
      style="display: none"
      :editorToolbar="customToolbar"
    ></vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
import { IP, AuthKey } from "../../config";

export default {
  data() {
    return {
      loading: false,
      ready: true,
      question: "How to make a sandwich",
      answer: "",
      raw_answer: "",
      error_message: "",
      question_label: "ถามอะไร Chat GPT ดี?",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "video"],
        ["clean"], // remove formatting button
      ],
    };
  },
  components: {
    VueEditor,
  },
  methods: {
    CreateCompletion() {
      this.loading = true;
      this.ready = false;
      this.answer = "";
      this.error_message = "";
      axios({
        method: "post",
        url: `${IP}/azure-openai/create-chat-completion`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${AuthKey}`,
        },
        data: {
          prompt: this.question,
        },
      }).then((response) => {
        console.log('res: ', response.data)
        this.raw_answer = JSON.parse(JSON.stringify(response.data));
        this.answer = JSON.parse(JSON.stringify(response.data.content));
        this.ready = true;
        this.loading = false;
      }).catch((error) => {
          this.error_message = error;
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-gpt {
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
}
.text-header-area {
  text-align: center;
  padding: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgb(220, 220, 220);
}
#text-header {
  font-size: 40px;
}
#text-subheader {
  font-size: 18px;
}

.content-area {
  max-width: 800px;
  margin: 0 auto;
}
</style>