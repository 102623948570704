<template>
  <div class="card">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-container>
      <v-row no-gutters>
        <v-col>
          <h2 style="padding-left: 10px">Person Group</h2>
        </v-col>
        <v-col>
          <v-btn
            text
            style="float: right"
            @click="create_person_group_dialog = true"
          >
            <v-icon color="green" style="margin-right: 5px"
              >mdi-folder-plus</v-icon
            >Create Person Group
          </v-btn>
        </v-col>
      </v-row>
      <br />
      <div v-if="data.length == 0" style="text-align: center">
        <p style="margin: 50px; font-size: 20px">Don't have Person Group.</p>
      </div>
      <v-row no-gutters v-if="data.length != 0">
        <v-col
          v-for="n in data"
          :key="n.personGroupId"
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
        >
          <v-container>
            <v-card
              color="#385F73"
              dark
              class="area-card"
              @click="GoToDetail(n)"
            >
              <v-container>
                <v-card-title class="headline">{{ n.name }}</v-card-title>
                <v-card-subtitle>ID : {{ n.personGroupId }}</v-card-subtitle>
                <v-card-text v-if="n.userData == null"
                  >None Description.</v-card-text
                >
                <v-card-text v-if="n.userData != null">{{
                  n.userData
                }}</v-card-text>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <!-- Dialog -->
    <v-dialog v-model="create_person_group_dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Create Person Group</v-card-title>
        <div style="width: 80%; margin: 0 auto">
          <v-text-field
            label="PersonGroupId"
            v-model="create.person_group_id"
            v-validate="'required'"
            name="person-group-id"
          />
          <transition name="slide-fade">
            <v-alert type="error" v-show="errors.has('person-group-id')">{{
              errors.first("person-group-id")
            }}</v-alert>
          </transition>
          <v-text-field
            label="Name"
            v-model="create.name"
            v-validate="'required'"
            name="name"
            style="margin-top: -10px"
          />
          <transition name="slide-fade">
            <v-alert type="error" v-show="errors.has('name')">{{
              errors.first("name")
            }}</v-alert>
          </transition>
          <v-text-field
            label="Description"
            v-model="create.userData"
            v-validate="'required'"
            name="user-data"
            style="margin-top: -10px"
          />
          <transition name="slide-fade">
            <v-alert type="error" v-show="errors.has('user-data')">{{
              errors.first("user-data")
            }}</v-alert>
          </transition>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="Cancel">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="CreatePersonGroup"
            >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";

export default {
  data() {
    return {
      IP: IP,
      data: "",
      create_person_group_dialog: false,
      create: {
        person_group_id: "",
        name: "",
        userData: "",
      },
      loading: false,
    };
  },
  methods: {
    GetPersonGroupAll() {
      axios({
        method: "get",
        url: this.IP + "/person-group-get-all",
        headers: {
          Authorization: AuthKey,
        },
      }).then((response) => {
        this.data = response.data;
      });
    },
    CreatePersonGroup() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          axios({
            method: "put",
            url: this.IP + "/person-group-create",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              PersonGroupId: this.create.person_group_id,
              name: this.create.name,
              userData: this.create.userData,
            },
          }).then(async (response) => {
            await this.GetPersonGroupAll();
            await this.Cancel();
            this.loading = false;
          });
        }
      });
    },
    Cancel() {
      this.create = {
        person_group_id: "",
        name: "",
        userData: "",
      };
      this.errors.clear();
      this.create_person_group_dialog = false;
    },
    GoToDetail(n) {
      this.$router.push({
        name: "admin-persongroup",
        params: { persongroup: n.personGroupId },
      });
    },
  },
  created() {
    this.GetPersonGroupAll();
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";
.area-card {
  transition: 0.5s all;
}

.area-card:hover {
  opacity: 0.8;
}

.card {
  margin-top: 80px;
}
</style>