<template>
  <div class="person">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <div class="person-area">
      <v-card>
        <v-container>
          <div v-if="update == false">
            <v-btn
              icon
              text
              color="gray"
              style="float: right"
              @click="DeletePerson"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              icon
              text
              color="gray"
              style="float: right"
              @click="UpdateActive"
            >
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <h2>{{ this.prop_person.name }}</h2>
            <p style="color: gray">ID : {{ this.prop_person.personId }}</p>
            <p v-if="prop_person.userData != null">
              {{ this.prop_person.userData }}
            </p>
            <p v-if="prop_person.userData == null">None Description.</p>
          </div>
          <div v-if="update == true">
            <v-btn icon text color="red" style="float: right" disabled>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              icon
              text
              color="green"
              style="float: right"
              @click="UpdatePersonDetail"
            >
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
            <v-text-field
              label="Name"
              style="width: 80%; font-size: 25px"
              v-model="new_person.name"
              v-validate="'required'"
              name="name"
            />
            <transition name="slide-fade">
              <v-alert type="error" v-show="errors.has('name')">{{
                errors.first("name")
              }}</v-alert>
            </transition>
            <p style="color: gray">ID : {{ this.prop_person.personId }}</p>
            <v-text-field
              label="Description"
              style="width: 80%; margin-top: -10px"
              v-model="new_person.userData"
              v-validate="'required'"
              name="userData"
            />
            <transition name="slide-fade">
              <v-alert type="error" v-show="errors.has('userData')">{{
                errors.first("userData")
              }}</v-alert>
            </transition>
          </div>
        </v-container>
      </v-card>
      <br />
      <v-row no-gutters>
        <v-col>
          <div class="select-file" style="float: right">
            <input
              id="fileUpload"
              type="file"
              multiple
              hidden
              @change="handleFileUpload"
            />
            <v-btn text @click="chooseFiles">
              <v-icon color="green" style="margin-right: 5px">mdi-face</v-icon
              >Add Face
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <br />
      <div
        v-if="prop_person.persistedFaceIds.length == 0"
        style="text-align: center"
      >
        <h4>Don't Face ID</h4>
      </div>
      <v-simple-table
        fixed-header
        height="300px"
        v-if="prop_person.persistedFaceIds.length != 0"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Face ID</th>
              <th class="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="n in prop_person.persistedFaceIds" :key="n">
              <td>{{ n }}</td>
              <td>
                <v-btn icon text @click="DeleteFace(n)">
                  <v-icon id="btn-delete">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <br />
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import Swal from "sweetalert2";
import FileReader from "filereader";

export default {
  data() {
    return {
      IP: IP,
      prop_person: "",
      new_person: {
        name: "",
        userData: "",
      },
      update: false,
      loading: false,
      file: "",
    };
  },
  methods: {
    UpdateActive() {
      this.new_person.name = this.prop_person.name;
      if (this.prop_person.userData != null) {
        this.new_person.userData = this.prop_person.userData;
      } else {
        this.new_person.userData = "";
      }
      this.update = true;
    },
    UpdatePersonDetail() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          axios({
            method: "patch",
            url: this.IP + "/person-group-person-update",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              PersonGroupId: this.prop_person.personGroupId,
              personId: this.prop_person.personId,
              name: this.new_person.name,
              userData: this.new_person.userData,
            },
          }).then(async (response) => {
            const data = await this.GetPerson();
            this.prop_person = data;
            this.loading = false;
            this.update = false;
          });
        }
      });
    },
    GetPerson() {
      return new Promise((resolve, reject) => {
        axios({
          method: "post",
          url: this.IP + "/person-group-person-get",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            PersonGroupId: this.prop_person.personGroupId,
            personId: this.prop_person.personId,
          },
        }).then((response) => {
          const result = response.data;
          console.log("result: ", result);
          const data = {
            personGroupId: this.prop_person.personGroupId,
            personId: result.personId,
            name: result.name,
            userData: result.userData,
            persistedFaceIds: result.persistedFaceIds,
          };
          resolve(data);
        });
      });
    },
    DeletePerson() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          axios({
            method: "delete",
            url: this.IP + "/person-group-person-delete",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              PersonGroupId: this.prop_person.personGroupId,
              personId: this.prop_person.personId,
            },
          }).then((response) => {
            this.loading = false;
            Swal.fire("Deleted!", "Person has been deleted.", "success");
            this.$router.push({
              name: "admin-persongroup",
              params: { persongroup: this.prop_person.personGroupId },
            });
          });
        }
      });
    },
    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    handleFileUpload(e) {
      const file = e.target.files;
      const data = new FormData();
      for (var i = 0; i < file.length; i++) {
        data.append("file", file[i]);
        data.append("size", file[i].size);
      }
      data.append("PersonGroupId", this.prop_person.personGroupId);
      data.append("personId", this.prop_person.personId);
      this.loading = true;
      axios
        .post(this.IP + "/person-group-person-add-face", data, {
          headers: {
            Authorization: AuthKey,
          },
        })
        .then(async (response) => {
          const self = this;
          setTimeout(async function () {
            const data = await self.GetPerson();
            self.prop_person = data;
            self.loading = false;
          }, 500);
        });
    },
    DeleteFace(n) {
      this.loading = true;
      axios({
        method: "delete",
        url: this.IP + "/person-group-person-delete-face",
        headers: {
          Authorization: AuthKey,
        },
        data: {
          personGroupId: this.prop_person.personGroupId,
          personId: this.prop_person.personId,
          persistedFaceId: n,
        },
      })
        .then((response) => {
          const self = this;
          setTimeout(async function () {
            const data = await self.GetPerson();
            self.prop_person = data;
            self.loading = false;
          }, 3000);
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
  props: ["person"],
  created() {
    this.prop_person = this.$route.params.person;
    console.log(this.prop_person);
    if (this.prop_person == null) {
      this.$router.push("/admin");
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../css/transition.scss";

.person-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

#btn-delete {
  transition: 0.2s all;
}

#btn-delete:hover {
  color: red;
}
</style>