<template>
  <div>
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content -->
    <v-container>
      <div class="content-area">
        <v-row style="border-bottom: 1px solid rgb(216, 216, 216)">
          <v-col cols="auto" style="font-size: 30px">Exams History</v-col>
          <v-col style="width: 100%; padding: 0px 20px 0px 20px">
            <v-text-field
              v-model="search"
              label="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <br />
        <!-- data table -->
        <div class="data-table">
          <div v-if="listItems.length == 0">
            <p style="font-size: 20px; text-align: center; margin: 40px">
              {{ message }}
            </p>
          </div>
          <v-data-table
            :headers="headers"
            :items="listItems"
            :search="search"
            class="elevation-1"
            v-if="listItems.length > 0"
          >
            <template v-slot:item.total_score="{ item }">
              {{ item.Score.score }} / {{ item.Score.total_score }}
            </template>
            <template v-slot:item.sub_score="{ item }">
              <div>
                <span v-for="(n, index) in item.Score.sub_score" :key="index"
                  >{{ n }} / {{ item.Score.sub_total_score[index] }}
                  <span v-show="index + 1 - item.Score.sub_score.length != 0">
                    ,
                  </span>
                </span>
              </div>
            </template>
            <template v-slot:item.IsPass="{ item }">
              <v-chip
                class="ma-2"
                color="green"
                text-color="white"
                v-if="item.IsPass"
              >
                Pass
              </v-chip>
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                v-if="!item.IsPass"
              >
                Fail
              </v-chip>
            </template>
            <template v-slot:item.writing="{ item }">
              <v-chip class="ma-2" v-if="!item.Score.writing_question.is_have">
                Don't have
              </v-chip>
              <v-chip
                class="ma-2"
                color="primary"
                text-color="white"
                v-if="
                  item.Score.writing_question.is_have &&
                  !item.Score.writing_question.is_progress
                "
              >
                Progress
              </v-chip>
              <v-chip
                class="ma-2"
                color="green"
                text-color="white"
                v-if="
                  item.Score.writing_question.is_have &&
                  item.Score.writing_question.is_progress &&
                  item.Score.writing_question.is_pass
                "
              >
                Pass
              </v-chip>
              <v-chip
                class="ma-2"
                color="red"
                text-color="white"
                v-if="
                  item.Score.writing_question.is_have &&
                  item.Score.writing_question.is_progress &&
                  !item.Score.writing_question.is_pass
                "
              >
                Fail
              </v-chip>
            </template>
            <template v-slot:item.ExamBegin="{ item }">
              {{ Moment(item.ExamBegin) }}
            </template>
            <template v-slot:item.CompleteDate="{ item }">
              {{ Moment(item.CompleteDate) }}
            </template>
          </v-data-table>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { IP, AuthKey } from "../../config";
import moment from "moment";

export default {
  data() {
    return {
      loading: false,
      search: "",
      listItems: [],
      headers: [
        { text: "Exam Name", value: "ExamTitle" },
        // { text: "Duration", value: "TimingType" },
        { text: "Total Score", value: "total_score", align: "center" },
        { text: "Sub Score", value: "sub_score", align: "center" },
        { text: "IsPass", value: "IsPass", align: "center" },
        { text: "Grade", value: "Grade", align: "center" },
        { text: "Writing", value: "writing", align: "center" },
        { text: "Begin", value: "ExamBegin", align: "center" },
        { text: "Complete", value: "CompleteDate", align: "center" },
        // { text: "Actions", align: "right", value: "action", sortable: false },
      ],
      message: "No exam yet.",
    };
  },
  props: ["email"],
  methods: {
    Moment(date) {
      if (date != null && date != "") {
        return moment(date).format("lll");
      } else {
        return date;
      }
    },
    GetExamHistoryByEmail() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "POST",
          url: `${IP}/exam/get-history-by-email`,
          headers: {
            Authorization: AuthKey,
          },
          data: {
            email: this.email,
          },
        }).then((response) => {
          this.listItems = response.data;
          console.log("listItems: ", this.listItems);
          for (var i = 0; i < this.listItems.length; i++) {
            this.listItems[i].Score = JSON.parse(this.listItems[i].Score);
          }
          resolve(true);
        });
      });
    },
    Start() {
      // console.log("email: ", this.email);
      this.GetExamHistoryByEmail().then(() => {});
    },
  },
  created() {
    this.Start();
  },
};
</script>

<style lang="scss" scoped>
.content-area {
  width: 95%;
  margin: 0 auto;
}
</style>