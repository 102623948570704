<template>
  <div class="main-area">
    <!-- loading -->
    <v-dialog v-model="loading" persistent fullscreen style="z-index: 999">
      <v-container fill-height>
        <v-layout row justify-center align-center style="z-index: 100">
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="teal"
          ></v-progress-circular>
        </v-layout>
      </v-container>
      <div id="loading-background"></div>
    </v-dialog>
    <!-- content area -->
    <div style="border-bottom: 1px solid rgb(216, 216, 216)">
      <v-row>
        <v-col style="font-size: 30px">Digital Asset</v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="2">
          <v-btn color="success" block @click="Action('create', null)">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
            Digital Asset
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="3" xl="2">
          <v-btn color="success" block @click="dialog = !dialog">
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
            Folder
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="listItems.length == 0">
      <p style="font-size: 20px; text-align: center; margin: 40px">
        ยังไม่มีโฟลเดอร์
      </p>
    </div>
    <div v-if="listItems.length > 0">
      <div style="margin: 20px">
        <v-tooltip bottom v-if="hide == false">
          <template v-slot:activator="{ on }">
            <v-btn
              color="error"
              v-on="on"
              icon
              @click="hide = true"
              style="float: right"
            >
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
          </template>
          <span>Hide folder</span>
        </v-tooltip>
        <v-tooltip bottom v-if="hide == true">
          <template v-slot:activator="{ on }">
            <v-btn
              color="teal"
              v-on="on"
              icon
              @click="hide = false"
              style="float: right"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </template>
          <span>Show folder</span>
        </v-tooltip>
      </div>
      <div
        v-show="hide == false"
        style="border-bottom: 1px solid rgb(216, 216, 216)"
      >
        <v-card-title v-if="listItems.length > 0">
          <v-text-field
            v-model="folder_search"
            label="Search"
            append-icon="mdi-magnify"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="4"
            lg="4"
            xl="3"
            v-for="n in resultQuery"
            :key="n.ID"
          >
            <v-container>
              <v-card class="card" @click="goToFolder(n.FolderURL)">
                <v-container>
                  <v-card-title class="headline">{{
                    n.FolderName
                  }}</v-card-title>
                  <v-card-subtitle>{{ n.FolderDescription }}</v-card-subtitle>
                  <v-divider></v-divider>
                  <v-card-subtitle>
                    <b>TAG :</b>
                    <v-chip
                      class="ma-2"
                      small
                      color="indigo"
                      text-color="white"
                      v-for="t in n.Tag"
                      :key="t"
                      >{{ t }}</v-chip
                    >
                    <br />
                    <b>Created :</b>
                    {{ Others.ConvertDate(n.CreatedDate) }}
                  </v-card-subtitle>
                </v-container>
              </v-card>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- dialog -->
    <v-dialog v-model="dialog" width="100%;" max-width="500">
      <v-card>
        <v-card-title>Create Folder</v-card-title>
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Folder Name"
                outlined
                v-model="folder.name"
                name="name"
                v-validate="'required'"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('name')"
                  style="margin-top: -20px"
                  >{{ errors.first("name") }}</v-alert
                >
              </transition>
              <v-card-subtitle
                v-if="folder.name != ''"
                style="margin-top: -40px"
                >{{ CreatePath(folder.name) }}</v-card-subtitle
              >
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-combobox
                v-model="folder.tag"
                label="Tag"
                outlined
                multiple
                chips
                name="tag"
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-textarea
                label="Description"
                outlined
                v-model="folder.description"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -20px"
            >
              <transition v-if="error != ''" name="slide-fade">
                <v-alert type="error" style="margin-top: -20px">{{
                  this.error
                }}</v-alert>
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn
            color="success"
            @click="CreateFolderDigitalAsset"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- digital asset -->
    <div v-if="listItemsAsset.length > 0">
      <br />
      <AdvanceFilter
        :headers="headers"
        :FilterData="FilterItemsAsset"
        @update-data="UpdateData"
      />
      <v-card-title v-if="listItemsAsset.length > 0">
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listItemsAsset"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.Description="{ item }">
          {{ CutAndPoint(item.Description, 60) }}
        </template>
        <template v-slot:item.CreatedDate="{ item }">{{
          Others.ConvertDate(item.CreatedDate)
        }}</template>
        <template v-slot:item.Tag="{ item }">
          <v-chip
            class="ma-2"
            small
            color="indigo"
            text-color="white"
            v-for="t in item.Tag"
            :key="t"
            >{{ t }}</v-chip
          >
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                @click="goToURL(item.Link)"
                style="cursor: pointer; margin-right: 10px"
                >mdi-link-variant</v-icon
              >
            </template>
            <span>Link</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                @click="ReadOnly(item)"
                style="cursor: pointer; margin-right: 10px"
                v-if="item.CreateBy != user.EmployeeEmail"
                >mdi-eye</v-icon
              >
            </template>
            <span>Read</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                @click="Action('update', item)"
                style="cursor: pointer; margin-right: 10px"
                v-if="item.CreateBy == user.EmployeeEmail"
                >edit</v-icon
              >
            </template>
            <span>Update</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                @click="DeleteDigitalAsset(item.ID)"
                style="cursor: pointer"
                :disabled="item.CreateBy != user.EmployeeEmail"
                >delete</v-icon
              >
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <!-- dialog -->
    <v-dialog v-model="dialog_asset" width="100%" max-width="500">
      <v-card>
        <v-card-title v-if="action == 'create'"
          >Create Digital Asset</v-card-title
        >
        <v-card-title v-if="action == 'update'"
          >Update Digital Asset</v-card-title
        >
        <v-divider></v-divider>
        <div class="information-area">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Name"
                outlined
                v-model="asset.name"
                name="name-asset"
                v-validate="'required'"
                :readonly="readOnly"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('name-asset')"
                  style="margin-top: -20px"
                  >{{ errors.first("name-asset") }}</v-alert
                >
              </transition>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-combobox
                v-model="asset.tag"
                label="Tag"
                outlined
                multiple
                chips
                name="asset-tag"
                :readonly="readOnly"
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-textarea
                label="Description"
                outlined
                v-model="asset.description"
                name="description"
                :readonly="readOnly"
              />
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: -30px"
            >
              <v-text-field
                label="URL"
                outlined
                v-model="asset.url"
                name="url"
                v-validate="{ url: { require_protocol: true } }"
                @change="CheckURL"
                :readonly="readOnly"
              />
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-show="errors.has('url')"
                  style="margin-top: -20px"
                  >{{ errors.first("url") }}</v-alert
                >
              </transition>
              <transition name="slide-fade">
                <v-alert
                  type="error"
                  v-if="error != ''"
                  style="margin-top: -20px"
                  >{{ error }}</v-alert
                >
              </transition>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-actions class="justify-center" v-if="readOnly == false">
          <v-btn
            color="success"
            v-if="action == 'create'"
            @click="CreateDigitalAsset"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Create
          </v-btn>
          <v-btn
            color="warning"
            v-if="action == 'update'"
            @click="UpdateDigitalAsset"
            style="margin: 10px"
          >
            <v-icon style="margin: 0px 5px 0px -5px">mdi-plus</v-icon>Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { IP, AuthKey } from "../config";
import { Others } from "../function/Others";

export default {
  data() {
    return {
      Others: Others,
      IP: IP,
      user: "",
      listItems: [],
      listItemsAsset: [],
      FilterItemsAsset: [],
      dialog: false,
      dialog_asset: false,
      folder: {
        name: "",
        tag: [],
        description: "",
        path: "",
      },
      loading: false,
      error: "",
      hide: false,
      search: "",
      folder_search: "",
      action: "",
      asset: {
        name: "",
        tag: [],
        description: "",
        url: "",
        folder: "",
      },
      headers: [
        { text: "Name", value: "Name" },
        { text: "Description", value: "Description" },
        { text: "Create By", value: "EmployeeName" },
        { text: "Date", value: "CreatedDate" },
        { text: "Tag", value: "Tag" },
        { text: "Actions", value: "action", sortable: false },
      ],
      readOnly: false,
    };
  },
  computed: {
    resultQuery() {
      const value = this.folder_search.toLowerCase();
      return this.listItems.filter(function (val) {
        var Tag = JSON.stringify(val.Tag);
        return (
          val.FolderName.toLowerCase().indexOf(value) > -1 ||
          Tag.toLowerCase().indexOf(value) > -1
        );
      });
    },
  },
  methods: {
    UpdateData(result) {
      if (result.status == true) {
        this.listItemsAsset = JSON.parse(result.data);
      } else {
        this.listItemsAsset = JSON.parse(this.FilterItemsAsset);
      }
    },
    async CreateDigitalAsset() {
      this.error = "";
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (this.asset.url != "" && this.asset.url != null) {
            this.asset.tag = JSON.stringify(this.asset.tag);
            Swal.fire({
              title: "Are you sure ?",
              text: `คุณต้องการสร้างข้อมูลนี่หรือไม่ !`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes",
            }).then(async (result) => {
              if (result.value) {
                this.CreateAssetProcess().then(async (response) => {
                  if (response == "success") {
                    this.Clear();
                    await this.GetDigitalAsset();
                    Swal.fire("สร้างข้อมูลเสร็จสิ้น !", "", "success");
                  }
                });
              }
            });
          } else {
            this.error = "The URL field is required";
          }
        }
      });
    },
    async CreateAssetProcess() {
      return new Promise(async (resolve, reject) => {
        this.asset.email = this.user.EmployeeEmail;
        this.asset.folder = "";
        axios({
          method: "post",
          url: IP + "/create-digital-asset",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            asset: this.asset,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    async UpdateDigitalAsset() {
      this.error = "";
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (this.asset.url != "" && this.asset.url != null) {
            this.asset.email = this.user.EmployeeEmail;
            this.asset.tag = JSON.stringify(this.asset.tag);
            axios({
              method: "post",
              url: IP + "/update-digital-asset",
              headers: {
                Authorization: AuthKey,
              },
              data: {
                asset: this.asset,
              },
            }).then(async (response) => {
              if (response.data == "success") {
                this.Clear();
                await this.GetDigitalAsset();
                Swal.fire("อัพเดตข้อมูลเสร็จสิ้น !", "", "success");
              }
            });
          } else {
            this.error = "The URL field is required";
          }
        }
      });
    },
    async DeleteDigitalAsset(id) {
      Swal.fire({
        title: "Are you sure ?",
        text: `คุณต้องการลบข้อมูลนี่หรือไม่ !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.value) {
          axios({
            method: "post",
            url: IP + "/delete-digital-asset",
            headers: {
              Authorization: AuthKey,
            },
            data: {
              ID: id,
            },
          }).then(async (response) => {
            if (response.data == "success") {
              this.Clear();
              await this.GetDigitalAsset();
              Swal.fire("ลบข้อมูลเสร็จสิ้น !", "", "success");
            }
          });
        }
      });
    },
    CreateFolderDigitalAsset() {
      this.error = "";
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.CheckFolderAlready(this.folder.path).then((response) => {
            if (!response) {
              if (result) {
                Swal.fire({
                  title: "Are you sure ?",
                  text: "คุณต้องการสร้างโฟลเดอร์หรือไม่ !",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                }).then(async (result) => {
                  if (result.value) {
                    this.CreateProcess().then(async (response) => {
                      if (response == "success") {
                        await this.GetFolderDigitalAsset();
                        await this.Clear();
                        Swal.fire("สร้างโฟลเดอร์เสร็จสิ้น !", "", "success");
                      }
                    });
                  }
                });
              }
            } else {
              this.error = `Folder name "${this.folder.name}" has been already.`;
            }
          });
        }
      });
    },
    CheckFolderAlready(name) {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/check-folder-digital-asset",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            path: name,
          },
        }).then((response) => {
          const result = response.data;
          if (result.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    CreateProcess() {
      return new Promise(async (resolve, reject) => {
        this.folder.email = this.user.EmployeeEmail;
        this.folder.tag = JSON.stringify(this.folder.tag);
        axios({
          method: "post",
          url: IP + "/create-folder-digital-asset",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            folder: this.folder,
          },
        }).then((response) => {
          resolve(response.data);
        });
      });
    },
    CreatePath(name) {
      var replaced = name.split(" ").join("-");
      var lower = replaced.toLowerCase();
      this.folder.path = lower;
      return this.folder.path;
    },
    Action(menu, data) {
      this.readOnly = false;
      this.asset = {};
      this.action = menu;
      if (this.action == "update") {
        this.asset = {
          ID: data.ID,
          name: data.Name,
          tag: data.Tag,
          description: data.Description,
          url: data.Link,
          folder: data.Folder,
        };
      }
      this.dialog_asset = true;
    },
    ReadOnly(data) {
      console.log("read only!");
      this.readOnly = true;
      this.asset = {
        ID: data.ID,
        name: data.Name,
        tag: data.Tag,
        description: data.Description,
        url: data.Link,
        folder: data.Folder,
      };
      this.dialog_asset = true;
    },
    CutAndPoint(data, num) {
      if (data.length > 0) {
        let res = data.slice(0, num);
        if (data.length >= num) {
          res = res + "...";
        }
        return res;
      }
    },
    CheckURL() {
      if (this.asset.url == "") {
        this.error = "The URL field is required";
      } else {
        this.error = "";
      }
    },
    Clear() {
      this.error = "";
      this.folder = { name: "", tag: "", description: "", path: "" };
      this.asset = { name: "", description: "", url: "", folder: "" };
      this.dialog = false;
      this.dialog_asset = false;
    },
    goToFolder(path) {
      this.$router.push({
        name: "digital-asset",
        params: {
          path: path,
        },
      });
    },
    goToURL(url) {
      var win = window.open(url, "_blank");
      win.focus();
    },
    GetFolderDigitalAsset() {
      return new Promise(async (resolve, reject) => {
        this.listItems = [];
        axios({
          method: "get",
          url: IP + "/get-folder-digital-asset",
          headers: {
            Authorization: AuthKey,
          },
        }).then((response) => {
          var data = response.data;
          data.forEach(function (val, i) {
            val.Tag = JSON.parse(val.Tag);
            this.listItems.push(val);
          }, this);
          resolve(true);
        });
      });
    },
    GetDigitalAsset() {
      return new Promise(async (resolve, reject) => {
        axios({
          method: "post",
          url: IP + "/get-digital-asset",
          headers: {
            Authorization: AuthKey,
          },
          data: {
            path: "",
          },
        }).then((response) => {
          this.listItemsAsset = [];
          const data = response.data;
          data.forEach(function (val, i) {
            val.Tag = JSON.parse(val.Tag);
            this.listItemsAsset.push(val);
          }, this);
          this.FilterItemsAsset = JSON.stringify(this.listItemsAsset);
          resolve(true);
        });
      });
    },
    async start() {
      this.loading = true;
      this.user = this.$store.getters.currentUser;
      await this.GetFolderDigitalAsset().then(async (response) => {
        await this.GetDigitalAsset().then(async (response) => {
          var that = this;
          setTimeout(function () {
            that.loading = false;
          }, 1000);
        }, this);
      }, this);
    },
  },
  created() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../css/transition.scss";

.main-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 100px;
}

.header-text {
  font-size: 30px;
}

.header {
  height: 60px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(216, 216, 216);
}

.information-area {
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}

.card {
  cursor: pointer;
  transition: 0.2s all;
}

.card:hover {
  margin-top: -10px;
  // -webkit-box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.75);
  // box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.75);
}

.expansion-panel-card {
  background: rgba(255, 255, 255, 0);
}
</style>