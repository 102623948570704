<template>
<!-- <div class="modal-wrap"> -->
    <!-- <button @click="openModal" class="open-modal">Predictive Page</button>
    <transition name="fade" v-on:enter="enableFocusTrap">
      <div
        role="dialog"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        aria-modal="true"
        v-show="open"
        class="modal"
        ref="modal"
      >
        <div v-scroll-lock="open" class="content-wrap">
          <div id="modal-description" class="modal-title">
            <Predictive/>
          </div>
        </div>
        <button
          @click="closeModal"
          class="close-modal"
          aria-label="Close Modal"
        >
          <img
            src="../../../public/closebutton.png"
            alt=""
            aria-hidden="true"
            width="150" height="200"
          /></button>
      </div>
    </transition> -->
    <div id="all">
        <div id="tableau">
            <div ref="tableau"></div>
        </div> 
    </div>
  <!-- </div> -->
  <!-- /.modal-wrap -->
</template>

<script>
import VScrollLock from 'v-scroll-lock'
import 'vue-tableau'
export default { 
    el: "#tableau",
    data() {
        return {
            url: "https://10az.online.tableau.com/t/stelligence/views/DataTeamIndicatorDashboardv3/Menumobile?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link",
            options: {
                hideTabs: true,
                width: '100%',
                height: null
            },
            width: 0,
            height: 0,
            open: false
        }
    },
    methods: {
        initViz: function () {
            this.options.height = this.height
            let viz = new tableau.Viz(this.$refs.tableau, this.url, this.options);
        },
        resize() {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
            window.addEventListener(
                "resize",
                () => {
                    this.width = WindowResize.windowWidth();
                    this.height = WindowResize.windowHeight();
                },
                this
            );
        },
        // openModal () {
        //     this.open = true
        // },
        // closeModal () {
        //     this.open = false
        // }
    },
    mounted: function () {
        this.resize()
        this.initViz()
    }
}

</script>

<style scoped>

    #tableau {
        position: relative;
        width: 100%;
        height: 100%;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
    }

    /* #box {
        position: relative;
        width: 100%;
        height: 35px;
        background-image: url("../../../public/menu.jpg");
        background-repeat: no-repeat;
        background-size: auto;
        text-align: center;
        font-family: "Times New Roman", Times, serif;
        font-size: 300;
        margin: auto;
        padding: 7px;
    }

    .modal-wrap {
        position: relative;
        text-align: center;
        justify-content: center;
    }
    
    .open-modal {
        text-align: center;
        justify-content: center;
        font-family: "Times New Roman", Times, serif;
        font-size: 300;
        width: 10%;
        height: 30px;
        padding: 0px;
        margin-bottom: 0px;
        margin-top: 12px;
        background-image: url("../../../public/predictive.jpg");
    } */

</style>